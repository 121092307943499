import React, { Component, SyntheticEvent } from "react";
import cx from "classnames";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import { AuthState } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { RouteChildrenProps } from "react-router";
import { Button, Table, Space, Select, notification, Form, Spin } from "antd";
import SynchronizingModal from "~/component/SynchornizingModal";
import SummaryTags from "~/component/SummaryTags";
import { format, getMonth, getYear } from "date-fns";
import {
  getProcessDropdown,
  ProcessDropdownItem,
  getProcessStepsTable,
  getImportCSVReportBlob,
} from "~/api/synchronize";

import styles from "./Synchronize.module.css";
import { registerEvent } from "~/analytics";
import { action } from "~/app/MainApp/store";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import GeneralAPIError from "~/api/api-errors/GeneralAPIError";
import SyncRowDetails from "./SyncRowDetails";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";
import { syncMonths } from "~/lib/constants";
import * as ga from "~/contants/gaConstants";

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  userId: state.mainAppSlice.user.id,
  saveEmpDetails: state.mainAppSlice.user.save_emp_details,
  isSyncing: state.modalSlice.isSyncing,
  syncProcessStatus: state.modalSlice.syncProcessStatus,
  updateSyncData: state.modalSlice.updateSyncData,
});

const mapDispatchToProps = {
  showSourceSelectionModal: action.modal.showSourceSelectionModal,
  hideSourceSelectionModal: action.modal.hideSourceSelectionModal,
  showSyncStatusModal: action.modal.showSyncStatusModal,
  hideSyncStatusModal: action.modal.hideSyncStatusModal,

  // checkStatusAndShowModal: action.modal.checkStatusAndShowModal,
};

type PathParams = {
  isSync?: string;
  month?: string;
  year?: string;
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux, RouteChildrenProps<PathParams> {}

interface State {
  visibleSync: boolean;
  dropDownItem: any;
  dropDownPlaceItem: any;
  tableValue: any;
  selectedValue: any;
  startDate: string;
  status: string;
  endDate: string;
  syncButtonLoading: boolean;
  syncModalView: boolean;
  syncModalIcon: string;
  syncModalTitle: string;
  syncModalStyle: string;
  showDetailPage: boolean;
  visibleDrw: boolean;
  selectedRow: any;
  loadData: boolean;
  loadRefresh: boolean;
  month: any;
  year: any;
}

class Synchronize extends Component<Props, State> {
  state: State = {
    visibleSync: false,
    selectedValue: 0,
    dropDownPlaceItem: [],
    dropDownItem: [],
    startDate: "",
    status: "",
    endDate: "",
    tableValue: [],
    syncButtonLoading: false,
    syncModalView: false,
    syncModalIcon: "",
    syncModalTitle: "",
    syncModalStyle: "",
    showDetailPage: false,
    visibleDrw: false,
    selectedRow: {},
    loadData: true,
    loadRefresh: false,
    month: "",
    year: "",
  };

  // static getDerivedStateFromProps(
  //   nextProps: Readonly<Props>,
  //   prevState: State
  // ):any {this.state.month

  // }

  async componentDidMount() {
    this.handleSyncNowDropDown();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.updateSyncData !== this.props.updateSyncData) {
      this.handleSyncNowDropDown();
    }
  }

  statusInterval: any = 0;

  handleSyncNowDropDown = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.synchronize243
    );
    this.setState({
      loadData: true,
    });

    const result = await getProcessDropdown();
    if (result.data) {
      const dropdownList: Array<ProcessDropdownItem> = [];
      result.data.forEach((item) => {
        item.label =
          item.label +
          " - " +
          format(new Date(item.start_time), "dd LLL, yyyy - hh:mm");
        item.value = item.process_id;
        dropdownList.push(item);
      });
      this.setState(
        {
          dropDownItem: [...this.state.dropDownPlaceItem, ...dropdownList],
          selectedValue: dropdownList.length > 0 ? dropdownList[0].value : "",
          loadData: false,
        },
        () => {
          this.getProcessSteps();
        }
      );
    } else {
      this.setState({
        loadData: false,
        dropDownItem: [],
        selectedValue: "",
      });
      notification.error({ message: result.message });
    }
    const { dropDownItem, selectedValue } = this.state;
    const selectedItem = dropDownItem.find(
      (it: any) => it.value === selectedValue
    );

    const selectedMonth = getMonth(new Date(selectedItem?.start_time));
    const selectedYear = getYear(new Date(selectedItem?.start_time));
    this.setState({ month: selectedMonth, year: selectedYear });
    if (this.state.month) {
      if (this.state.month > 3) {
        this.props.history.push(
          this.props.history.location.pathname +
            "?" +
            `month=${syncMonths[this.state.month]}` +
            "&" +
            `year=${this.state.year}-${this.state.year + 1}`
        );
      } else {
        this.props.history.push(
          this.props.history.location.pathname +
            "?" +
            `month=${syncMonths[this.state.month]}` +
            "&" +
            `year=${this.state.year - 1}-${this.state.year}`
        );
      }
    } else {
      this.props.history.push(this.props.history.location.pathname);
    }
  };

  handleSynceNowButton = (e: React.MouseEvent) => {
    // this.props.checkStatusAndShowModal();
    // Is used to open sync now button accordingly its  status on redux store
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.synchronize244
    );
    if (this.props.isSyncing) {
      this.props.showSyncStatusModal();
    } else {
      this.props.showSourceSelectionModal();
    }
  };

  handleRefresh = async (e: React.MouseEvent) => {
    this.setState({
      loadRefresh: true,
    });
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.synchronize245
    );
    await this.getProcessSteps().then(() => {
      this.setState({
        loadRefresh: false,
      });
    });
  };

  handleDropdownChange = (value: number) => {
    this.setState(
      {
        selectedValue: value,
        tableValue: [],
        status: "",
        startDate: "",
        endDate: "",
      },
      () => {
        this.getProcessSteps();
      }
    );
  };

  getProcessSteps = async () => {
    if (this.state.selectedValue > 0) {
      const result = await getProcessStepsTable({
        process_id: this.state.selectedValue,
      });

      if (result.data) {
        this.setState({
          tableValue: result.data[0].table_value,
          status: result.data[0].status,
          startDate: result.data[0].start_time,
          endDate: result.data[0].end_time,
        });
      } else {
        this.setState({
          tableValue: [],
          status: "",
          startDate: "",
          endDate: "",
        });
        notification.error({ message: result.message });
      }
    }
  };

  loadingSyncNowButton = () => {
    this.setState({ syncButtonLoading: true });
  };

  stopLoadingSyncNowButton = () => {
    this.setState({ syncButtonLoading: false });
  };

  downloadCSVReport = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.synchronize246
    );
    const { dropDownItem, selectedValue } = this.state;
    const selectedItem = dropDownItem.find(
      (it: any) => it.value === selectedValue
    );
    if (selectedItem) {
      try {
        const { blob, fileName } = await getImportCSVReportBlob(
          selectedItem.process_id
        );
        saveAs(blob, fileName);
      } catch (error) {
        console.warn(error);
        let message = "An error occured";
        if (error instanceof GeneralAPIError) {
          message = error.message;
        }
        notification.warn({
          message: `Could not download csv file`,
          description: message,
        });
      }
    }
  };
  closeDrwer = () => {
    this.handleVisibleErrorDrwer(false);
  };
  handleVisibleErrorDrwer = (visible: boolean) => {
    const queryParams = new URLSearchParams(this.props.history.location.search);
    if (visible) {
      queryParams.set("errorDetail", "1");
    } else {
      if (queryParams.has("errorDetail")) {
        queryParams.delete("errorDetail");
      }
    }
    this.props.history.push(
      this.props.history.location.pathname + "?" + queryParams.toString()
    );
  };

  handleRowClick = (record: any, _rowIndex?: number) => {
    // this.setState({ showDetailPage: true });
    // console.log(record);
    return {
      // onClick: (_event: SyntheticEvent) => {
      //   this.props.history.push(`/app/synchronize/true/${_rowIndex}`);
      // },

      onClick: (_event: React.MouseEvent) => {
        registerEvent(
          "Button click",
          "click",
          (_event.target as any).innerHTML
        );
        this.handleVisibleErrorDrwer(true);
        // const id = _rowIndex;
        this.setState({
          selectedRow: record,
        });
        // this.props.history.push(`/app/synchronize/true/252/true`, {
        //   search: this.props.location.search,
        // });
      },
    };
  };

  render() {
    const {
      syncModalView,
      syncModalIcon,
      syncModalTitle,
      syncModalStyle,
      dropDownItem,
      selectedValue,
      status,
    } = this.state;

    const selectedItem = dropDownItem.find(
      (it: any) => it.value === selectedValue
    );
    const isTallySelected = selectedItem?.label.includes("Tally");
    const isProcessStoped = ["SUCCESS", "ERROR"].includes(status);
    const showTallyCSVDownload = isTallySelected && isProcessStoped;
    const columns = [
      {
        title: "Type",
        dataIndex: "label",
        key: "label",
        render: (label: string) => (
          <div>
            <b>{label}</b>
          </div>
        ),
      },
      {
        title: () => (
          <Space className={styles.tableCell} size="middle">
            <div className={styles.normalDiv}>Status</div>
            <div
              className={styles.errorDiv}
              style={{ paddingLeft: "20px", fontWeight: 700 }}
            >
              {" "}
              Errors
            </div>
            <div
              className={styles.completeDiv}
              style={{
                color: "var(--success-color)",
                fontWeight: 700,
              }}
            >
              Completed
            </div>
            <div
              className={styles.completeDiv}
              style={{
                color: "var(--grey-2)",
                fontWeight: 700,
                paddingRight: "2rem",
              }}
            >
              Other
            </div>
          </Space>
        ),
        key: "action",
        render: (record: any) => (
          <Space className={styles.tableCell} size="middle">
            <div
              className={styles.errorDiv}
              style={{
                color:
                  record.process_step_status === "Skipped"
                    ? "var(--grey-2)"
                    : "var(--success-color)",
                fontWeight: 700,
              }}
            >
              {record.process_step_status}
              {/* {this.props.syncProcessStatus === "RUNNING"
                ? "Runing ..."
                : this.props.syncProcessStatus === "SUCCESS"
                ? "Success"
                : "Success"} */}
            </div>
            <div
              className={styles.normalDiv}
              style={{
                display: "flex",
                justifyContent: "space-around",
                fontSize: "1rem",
              }}
            >
              {record.error_count ? (
                <span style={{ color: "crimson", fontWeight: 700 }}>
                  {record.error_count}{" "}
                </span>
              ) : (
                "-"
              )}
            </div>
            <div
              className={styles.completeDiv}
              style={{
                fontWeight: 700,
                fontSize: "1rem",
                color:
                  record.completed === 0
                    ? "var(--grey-2)"
                    : "var(--success-color)",
              }}
            >
              {record.completed}
            </div>
            <div
              className={styles.completeDiv}
              style={{
                fontWeight: 700,
                color: "var(--grey-2)",
                paddingRight: "2rem",
                fontSize: "1rem",
              }}
            >
              {record?.warn_count || 0}
            </div>
          </Space>
        ),
      },
    ];
    const queryParams = new URLSearchParams(this.props.location.search);
    const visibleErrorDetail =
      Object.keys(this.state.selectedRow).length > 0
        ? queryParams.get("errorDetail") === "1"
        : false;
    return (
      <IMTContent withoutMargin={true}>
        <Helmet>
          <title>{titles.Synchronize}</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={["Synchronize"]}
          style={{ paddingLeft: "0" }}
          actions={
            <>
              <Button
                loading={this.state.syncButtonLoading}
                onClick={this.handleSynceNowButton}
                type="primary"
                icon={
                  this.state.syncButtonLoading || this.props.isSyncing ? (
                    <LoadingOutlined />
                  ) : null
                }
              >
                {this.state.syncButtonLoading || this.props.isSyncing
                  ? "Syncing"
                  : "Sync now"}
              </Button>
            </>
          }
        />

        <SynchronizingModal
          isOpen={syncModalView}
          title={syncModalTitle}
          logo={syncModalIcon}
          style={syncModalStyle}
          // tslint:disable-next-line: jsx-no-lambda
          onClose={() => {
            this.setState({ syncModalView: false });
          }}
        />

        {/* <h6>Select sync run</h6> */}
        <Spin spinning={this.state.loadData}>
          {this.state.dropDownItem.length > 0 ? (
            <>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <Form>
                  <Form.Item label="Select sync run">
                    <Select
                      value={this.state.selectedValue}
                      // onChange={this.handleDropdownChange}
                      onSelect={this.handleDropdownChange}
                      suffixIcon={
                        <svg
                          width="10"
                          height="8"
                          viewBox="0 0 20 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 16L19.5263 0.25H0.473721L10 16Z"
                            fill="#4F4F4F"
                          />
                        </svg>
                      }
                      style={{
                        width: "371px",
                        color: "black",
                        fontWeight: 700,
                      }}
                    >
                      {this.state.dropDownItem.map((item: any) => {
                        return (
                          <Select.Option key={item.value} value={item.value}>
                            {item.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Form>
                <div
                  style={{
                    marginLeft: "-80px",
                    justifyContent: "center",
                    marginTop: 32,
                  }}
                >
                  <div>
                    <Button
                      loading={this.state.loadRefresh}
                      onClick={this.handleRefresh}
                      // type="default"
                    >
                      Refresh
                    </Button>
                    {/* {showTallyCSVDownload && ( */}
                    {this.state.tableValue.length > 0 ? (
                      <Button
                        className={styles.csvDownloadBtn}
                        icon={<DownloadOutlined />}
                        type="text"
                        onClick={this.downloadCSVReport}
                      >
                        Download error report
                      </Button>
                    ) : null}
                    {/* )} */}
                  </div>
                </div>
              </div>
              <div className={styles.statusBar}>
                {this.state.tableValue && this.state.tableValue.length > 0 ? (
                  <div>
                    <SummaryTags
                      nameValuePairs={[
                        {
                          name: "Status",
                          value:
                            this.state.status === "FORCE_STOPPED"
                              ? "Force stopped"
                              : this.state.status,
                        },
                        {
                          name: "Start time:",
                          value: format(
                            new Date(this.state.startDate),
                            "hh:mm , dd LLL, yyyy"
                          ),
                        },
                        {
                          name: "End time",
                          value: format(
                            new Date(this.state.endDate),
                            " hh:mm , dd LLL, yyyy"
                          ),
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={styles.tableWrapper}>
                <Table
                  className={cx(styles.table)}
                  columns={columns}
                  dataSource={this.state.tableValue}
                  rowKey="step_id"
                  onRow={this.handleRowClick}
                  pagination={false}
                  // locale={{
                  //   emptyText: (
                  //     <span style={{ color: "black" }}>
                  //       No historical data found
                  //     </span>
                  //   ),
                  // }}
                />
              </div>
            </>
          ) : this.state.loadData ? (
            <></>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: "15%",
                  justifyContent: "center",
                }}
              >
                <div>
                  <h1>No data for past sync run</h1>
                  <span style={{ marginLeft: "25%" }}>
                    <Button
                      loading={this.state.syncButtonLoading}
                      onClick={this.handleSynceNowButton}
                      type="primary"
                      icon={
                        this.state.syncButtonLoading || this.props.isSyncing ? (
                          <LoadingOutlined />
                        ) : null
                      }
                    >
                      {this.state.syncButtonLoading || this.props.isSyncing
                        ? "Syncing"
                        : "Sync now"}
                    </Button>
                  </span>
                </div>
              </div>
            </>
          )}
        </Spin>

        {visibleErrorDetail ? (
          <SyncRowDetails
            isOpen={visibleErrorDetail}
            onClose={this.closeDrwer}
            selectedRow={this.state.selectedRow}
            processId={this.state.selectedValue}
          />
        ) : null}
      </IMTContent>
    );
  }
}

export default connector(Synchronize);
