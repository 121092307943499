// TODO: rename/merge(with AttachableField) this componet
import React, { PureComponent, ReactNode } from "react";
import { Row, message as antMessage, Button, Spin, Tooltip } from "antd";
import { Rule } from "antd/lib/form";

import { FilePdfOutlined } from "@ant-design/icons";

import { getSignedUploadURL, getSignedDownloadURL } from "~/api/common";
import FieldInfo from "~/component/FieldInfo";
import commonStyle from "~/component/common.module.css";
import styles from "./OnlyAttachField.module.css";
import Modal from "antd/lib/modal/Modal";
import { LoginRole } from "~/api/auth";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";

interface Props {
  className?: string;
  label: string;
  fieldName: string;
  hashFileName?: string;
  onHashFileChange: (
    newHashFile: string,
    documents_name: string,
    idx?: any,
    lineItemValues?: any
  ) => void;
  rules: Array<Rule>;
  readonly?: boolean;
  children?: ReactNode;
  idx?: any;
  disable?: boolean;
  activeRole?: number;
  documentName?: string;
  lineItemValues?: any;
  forCheackArray?: any;
  isNew?: boolean;
  opts_fy?: any;
  selectedFy?: any;
  from?: string;
  mimeTypes?: string;
}

interface State {
  previewURL?: string;
  documents_name?: string;
  loadDoc?: boolean;
  showDelete?: boolean;
  a?: any;
}

class RowAttachField extends PureComponent<Props, State> {
  state: State = {
    previewURL: undefined,
    documents_name: "",
    loadDoc: false,
    showDelete: false,
    a: 0,
  };

  componentDidMount() {
    this.setState({
      documents_name: this.props.documentName,
    });
  }
  componentDidUpdate() {
    this.setState({
      documents_name: this.props.documentName,
    });
  }

  // componentDidUpdate(_prevProps: Readonly<Props>, prevState: State) {
  //   console.log(this.props.idx )
  //   if (prevState.documents_name == this.props.documentName) {
  //     this.setState({
  //       documents_name: this.props.documentName,
  //     });
  //   }
  // }

  handleAttach = (_e: React.MouseEvent) => {
    // if (!this.props.readonly) {
    //   return;
    // }
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.rowAttachField268
    );

    const $i = document.createElement("input") as HTMLInputElement;
    $i.type = "file";
    if (this.props.mimeTypes) {
      $i.accept = this.props.mimeTypes;
    }
    $i.onchange = async (e) => {
      const file = $i.files?.length ? $i.files[0] : null;
      if (file) {
        this.setState({
          loadDoc: true,
        });
        const { ok, message, data } = await getSignedUploadURL(file.name);
        this.setState({
          documents_name: file.name,
        });
        if (!ok) {
          antMessage.warning({ message });
        } else {
          const { hashedFileName, url } = data!;
          // TODO: show progress
          const res = await fetch(url, {
            method: "PUT",
            headers: {
              "content-type": file.type,
            },
            body: file,
          });
          if (!res.ok) {
            antMessage.error({ message: "Failed to upload file" });
            this.setState({
              loadDoc: false,
            });
          } else {
            this.props.onHashFileChange(
              hashedFileName,
              file.name,
              this.props.idx,
              this.props.lineItemValues
            );
            this.setState(
              {
                loadDoc: false,
              },
              () => {
                //     if(this.props.lineItemValues.employee_investment){
                // let r : any = this.props.forCheackArray;
                //             console.log(r.employee_investment)
                //             const f = r.employee_investment?.find(
                //               (it:any) => it.grouping_id === this.props.lineItemValues.grouping_id);
                //             console.log(f);
                //             let v = f.employee_investment_data.find((index:any,e:any)=>{
                //              if(e[index]===this.props.idx){
                //                console.log(e)
                //              }
                //             }
                //             // {
                //             //   let avg =f.employee_investment_data.findIndex(e)
                //             //   console.log(avg)
                //             // }
                //               // console.log(this.props.lineItemValues.employee_investment_data.findIndex(el))
                //               // f.employee_investment_data.findIndex(e) === 1
                //               // console.log()
                //              )
                //              console.log(v)
                //     }
              }
            );
          }
        }
      }
    };
    $i.click();
  };

  handleDelete = async (_e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.rowAttachField269
    );

    this.props.onHashFileChange(
      "",
      "",
      this.props.idx,
      this.props.lineItemValues
    );
    this.setState({
      documents_name: "",
    });
  };

  handlePreview = async (_e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.rowAttachField270
    );

    const { ok, message, url } = await getSignedDownloadURL(
      this.props.hashFileName!
    );
    if (!ok) {
      antMessage.error({ message });
    } else {
      this.setState({
        previewURL: url,
      });
    }
  };

  handlePreviewCancel = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.rowAttachField271
    );

    this.setState({
      previewURL: undefined,
    });
  };

  renderAttachFile() {
    return !this.props.hashFileName && this.props.activeRole !== 3 ? (
      <b className={styles.attachLabel} onClick={this.handleAttach}>
        + Attach file
      </b>
    ) : null;
  }

  render() {
    const {
      activeRole,
      from,
      className,
      idx,
      opts_fy,
      selectedFy,
      hashFileName,
    } = this.props;
    return (
      <Spin spinning={this.state.loadDoc}>
        {" "}
        <Row align="bottom" className={className} key={idx}>
          {(from
            ? activeRole !== LoginRole.CA && activeRole !== LoginRole.EMPLOYEE
            : activeRole !== LoginRole.CA) && opts_fy[1] === selectedFy
            ? this.renderAttachFile()
            : !hashFileName
            ? "No file uploaded"
            : null}

          <div className={styles.mainDiv}>
            <div className={styles.attchDiv}>
              <div>
                <FilePdfOutlined
                  className={
                    hashFileName
                      ? styles.fileIconBoxforTerms
                      : commonStyle.hidden
                  }
                  role="button"
                  onClick={this.handlePreview}
                  style={
                    this.props.label === "Uploaded document"
                      ? !this.props.readonly
                        ? { color: "green" }
                        : { color: "black" }
                      : { color: "black" }
                  }
                />
                {/* {this.state.documents_name} */}
              </div>

              <Tooltip title={this.state.documents_name}>
                <div>
                  <FieldInfo
                    text={
                      this.state.documents_name
                        ? this.state.documents_name?.substring(0, 22) +
                          (this.state.documents_name?.length < 22 ? "" : "...")
                        : ""
                    }
                  />
                </div>
              </Tooltip>
            </div>
            <div style={{ marginTop: "-16px" }}>
              {!this.props.isNew &&
              this.props.documentName &&
              (from
                ? activeRole !== LoginRole.CA &&
                  activeRole !== LoginRole.EMPLOYEE
                : activeRole !== LoginRole.CA) ? (
                <b
                  className={styles.deleteBtnTerm}
                  onClick={this.handleDelete}
                  role="button"
                >
                  Delete
                </b>
              ) : null}
            </div>
          </div>

          {!!this.state.previewURL ? (
            <DraggableModal
              footer={<Button onClick={this.handlePreviewCancel}>Close</Button>}
              initialWidth={Math.floor(window.innerWidth * 0.9)}
              initialHeight={Math.floor(window.innerWidth * 0.9)}
              className={styles.previewModal}
              visible={true}
              onCancel={this.handlePreviewCancel}
              destroyOnClose
              zIndex={1200}
              // centered
            >
              <iframe
                title="Attatchment modal"
                className={styles.previewFrame}
                src={this.state.previewURL}
              />
            </DraggableModal>
          ) : null}
        </Row>
      </Spin>
    );
  }
}

export default RowAttachField;
