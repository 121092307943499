import { configureStore, combineReducers } from "@reduxjs/toolkit";

import { loginSlice, doLogin } from "./loginSlice";
import { signupSlice, doSignup } from "./signupSlice";

const rootReducer = combineReducers({
  login: loginSlice.reducer,
  signup: signupSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: {
    name: "LandingPageApp",
  },
});

export type StoreState = ReturnType<typeof rootReducer>;
export type StoreDispatch = typeof store.dispatch;

store.dispatch({ type: "imt/INIT" });

export const action = {
  login: {
    ...loginSlice.actions,
    doLogin,
  },
  signup: {
    ...signupSlice.actions,
    doSignup,
  },
};
