import React, { Component } from "react";
import OnboardingLayout from "~/layout/onboarding-layout/OnboardingLayout";
import { store } from "./store";
import { Provider } from "react-redux";

interface Props {
  history: any;
}
export default class OnboardCompany extends Component<Props, {}> {
  state = {};

  render() {
    return (
      <Provider store={store}>
        <div id="onboarding-company-page">
          <OnboardingLayout history={this.props.history} />
        </div>
      </Provider>
    );
  }
}
