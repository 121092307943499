import React from "react";

import { hydrate, render } from "react-dom";
import "./index.less";
import App from "~/app/App";
import * as serviceWorker from "./serviceWorker";
import "./legacy-css.css";
const rootElement = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
/* render(<App />, document.getElementById("root")); */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
