import {
  getCessRates,
  getExpenseCategories,
  getGSTRates,
  getUnits,
  GSTRate,
} from "~/api/expense";
import { getProductList, getInvoiceDocumentType } from "~/api/invoice";
import { getVendorList } from "~/api/vendor";
import { PickupItem } from "~/fragment/pickup-field/pickupSlice";
import useFetch from "../useFetch";

export function useGSTRateList() {
  const { apiState, data: gstRateList } = useFetch(
    "gst_rates",
    async () => {
      const { ok, message, data } = await getGSTRates();
      if (!ok || !data) {
        throw new Error(message);
      }
      return data;
    },
    [] as Array<GSTRate>
  );
  return { isGSTListLoading: apiState === "loading", gstRateList };
}

export function useExpenseCategoryList() {
  const { apiState, data: expenseCategoryList, forceFetch } = useFetch(
    "expense_cats",
    async () => {
      const { ok, message, data } = await getExpenseCategories();
      if (!ok || !data) {
        throw new Error(message);
      }
      return data;
    },
    [] as Array<PickupItem>
  );
  return {
    isExpenseCategoryLoading: apiState === "loading",
    expenseCategoryList,
    forceFetch,
  };
}

export function useCESSRateList() {
  const { apiState, data: cessRateList } = useFetch(
    "cess_rates",
    async () => {
      const { ok, message, data } = await getCessRates();
      if (!ok || !data) {
        throw new Error(message);
      }
      return data;
    },
    []
  );
  return { isCESSListLoading: apiState === "loading", cessRateList };
}

export function useUnitList() {
  const { apiState, data: unitList } = useFetch(
    "units",
    async () => {
      const { ok, message, data } = await getUnits();
      if (!ok || !data) {
        throw new Error(message);
      }
      data.push({
        id: null,
        quantity: "",
        quantity_type: "",
        uqc_code: "",
      });
      return data;
    },
    []
  );
  return { isUnitListLoading: apiState === "loading", unitList };
}

export function useProductList() {
  const { apiState, data: productList, forceFetch } = useFetch(
    "product_list",
    async () => {
      const { ok, message, data } = await getProductList();
      if (!ok || !data) {
        throw new Error(message);
      }
      return data;
    },
    []
  );
  return {
    isProductListLoading: apiState === "loading",
    productList,
    refreshProductList: forceFetch,
  };
}

export function useCustomerList() {
  const { apiState, data: customerList, forceFetch } = useFetch(
    "customer_list",
    async () => {
      const { ok, message, data } = await getVendorList();
      if (!ok || !data) {
        throw new Error(message);
      }
      const customerList = data; /* .map((it) => ({
        trade_name: it.trade_name,
        value: it.customer_idx,
        customer_idx: it.customer_idx,
        label: it.company_name,
        place_of_supply_id: it.place_of_supply_id,
        symbol_name: it.symbol_name,
        symbol: it.symbol,
        email_id: it.email_id,
        billing_address: it.billing_address,
        billing_address2: it.billing_address2,
        billing_address_city: it.billing_address_city,
        billing_address_country: it.billing_address_country,
        billing_address_pincode: it.billing_address_pincode,
        billing_address_state: it.billing_address_state,
        company_name: it.company_name,
        customer_currency: it.customer_currency,
        customer_id: it.customer_id,
        gst_registration_type_id: it.gst_registration_type_id,
        gstin: it.gstin,
        name: it.name,
        place_of_supply: it.place_of_supply,
        state_code: it.state_code,
        customer_gstin_id: it.customer_gstin_id,
      })); */
      return customerList;
    },
    []
  );
  return {
    isCustomerListLoading: apiState === "loading",
    customerList,
    forceFetch,
  };
}

export function useInvoiceDocumentTypeList() {
  const { apiState, data: invoiceDocumentTypeList } = useFetch(
    "invoice_document_type",
    async () => {
      const { ok, message, data } = await getInvoiceDocumentType();
      if (!ok || !data) {
        throw new Error(message);
      }
      return data;
    },
    []
  );

  return {
    isInvoiceDocumentTypeLoading: apiState === "loading",
    invoiceDocumentTypeList,
  };
}
