import React from "react";
import { Typography } from "antd";
import cx from "classnames";

import styles from "./NonIdealState.module.css";

const { Title } = Typography;

interface Props {
  className?: string;

  /** An action to resolve the non-ideal state which appears after `description`. */
  action?: JSX.Element;

  /**
   * Advanced usage: React `children` will appear last (after `action`).
   * Avoid passing raw strings as they will not receive margins and disrupt the layout flow.
   */
  children?: React.ReactNode;

  /**
   * A longer description of the non-ideal state.
   * A string or number value will be wrapped in a `<div>` to preserve margins.
   */
  description?: React.ReactChild;

  /** The name of a icon or a JSX Element (such as `<Spinner/>`) to render above the title. */
  icon?: React.ReactNode;

  /** The title of the non-ideal state. */
  title?: React.ReactNode;
}

const NonIdealState: React.FC<Props> = ({
  action,
  children,
  className,
  description,
  title,
  icon,
}: Props) => {
  return (
    <div className={cx(styles.base, className)}>
      {icon ? <div className={styles.visual}>{icon}</div> : null}
      {title ? <Title level={4}>{title}</Title> : null}
      {description}
      {action}
      {children}
    </div>
  );
};

export default NonIdealState;
