import React, { PureComponent } from "react";
import { Button, notification, Typography } from "antd";
import MeeshoLogo from "~/assets/Logo/Meesho.png";
import styles from "./Iconstyle.module.css";
import { registerEvent } from "~/analytics";
import OnlyAttachField from "~/fragment/attachable-field/OnlyAttachField";
import { fetchUtil } from "~/api/common";
import { AuthState } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { action } from "~/app/MainApp/store";

import * as ga from "~/contants/gaConstants";

interface Props {
  handleBackPress?: () => void;
  erroMsg?: string;
  talyStart?: boolean;
  closeModal: () => void;
  viewSyncModal?: (title: string, logo: string, style: string) => void;
  source: any;
}

interface State {
  showTallyStart: boolean;
  openVideoPlayer: boolean;
  tallyConnectorDownloadLink: string;
  dataFile: string;
}

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  userId: state.mainAppSlice.user.id,
  saveEmpDetails: state.mainAppSlice.user.save_emp_details,
});

const mapDispatchToProps = {
  hideStartSyncFromSourceModal: action.modal.hideStartSyncFromSourceModal,
  showSyncStatusModal: action.modal.showSyncStatusModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
// tslint:disable-next-line
interface Props extends PropsFromRedux {}

class MeeshoXlsxSync extends PureComponent<Props, State> {
  state: State = {
    showTallyStart: false,
    openVideoPlayer: false,
    tallyConnectorDownloadLink: "",
    dataFile: "",
  };

  // latestInterval: any = null;

  async componentDidMount() {
    // this.tallyAuth()
  }

  startSync = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.meeshoXlsxSync240
    );

    try {
      if (!this.state.dataFile) {
        notification.error({ message: "Please attach a document" });
        return;
      }
      const { ok } = await fetchUtil("POST", "/xls_start_sync", {
        filename: this.state.dataFile,
        type: "XLS",
        format: "MeeshoXls",
      });
      if (ok) {
        // If import started successfully
        this.props.showSyncStatusModal();
        this.props.hideStartSyncFromSourceModal();
      } else {
        notification.error({ message: "Failed to start sync" });
      }
    } catch (err) {
      notification.error({ message: "Failed to start sync" });
    }
  };

  goback = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.meeshoXlsxSync241
    );
    this.props.handleBackPress!();
  };

  handleDataFileChange = (newFileName: string) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.onlyAttachFieldMeeshoXlsxCsvSync
    );
    this.setState({ dataFile: newFileName });
  };

  render() {
    return (
      <>
        <div style={{ textAlign: "center", height: 300 }}>
          <>
            <img src={MeeshoLogo} alt="" className={styles.talyIcon} />

            <Typography.Title
              level={4}
              style={{
                textAlign: "center",
                marginBottom: "1.5rem",
              }}
            >
              <b className={styles.sourceheading}>{this.props.source.label}</b>
            </Typography.Title>
            <div>
              <OnlyAttachField
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                centered={true}
                fieldName="data_file"
                hashFileName={this.state.dataFile}
                onHashFileChange={this.handleDataFileChange}
                label="Upload data file (Max size: 50 MB)"
                readonly={true}
                className={styles.attach}
                rules={[
                  {
                    type: "string",
                    required: false,
                    message: "Please upload a valid data file",
                  },
                ]}
              />
            </div>

            <div className={styles.buttondiv}>
              <Button
                onClick={this.startSync}
                style={{ width: "156px" }}
                type="primary"
              >
                Start sync
              </Button>
            </div>
            <div className={styles.Tallyback}>
              <Button
                type="text"
                onClick={this.goback}
                disabled={this.props.talyStart}
              >
                Back
              </Button>
            </div>
          </>
        </div>
      </>
    );
  }
}

export default connector(MeeshoXlsxSync);
