import React, { Component } from "react";
import {
  Modal,
  Input,
  Radio,
  notification,
  Button,
  Row,
  Col,
  Space,
  Switch,
} from "antd";
import FieldWithAction from "~/component/FieldWithAction";
import { fetchUtil } from "~/api/common";
import { noop } from "~/lib";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import "ant-design-draggable-modal/dist/index.css";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

interface Props {
  visible: boolean;
  onVisibleChange: (
    visible: boolean,
    category_name?: any,
    catagory_id?: any
  ) => void;
  selectedCatgoryDetails?: any;
}

interface State {
  category_name: string;
  category_type: string;
  catagory_id: any;
  goods_or_services: string;
  is_compliance: boolean;
}

export default class AddCategory extends Component<Props, State> {
  state: State = {
    category_name: "",
    category_type: "",
    catagory_id: "",
    goods_or_services: "",
    is_compliance: true,
  };

  componentDidMount() {
    if (this.props.selectedCatgoryDetails) {
      this.setState({
        category_name: this.props.selectedCatgoryDetails.catagoryName,
        catagory_id: this.props.selectedCatgoryDetails.catagoryId,
        category_type: this.props.selectedCatgoryDetails.catagoryType,
        goods_or_services: this.props.selectedCatgoryDetails.is_goods
          ? "goods"
          : "services",
        is_compliance: !this.props.selectedCatgoryDetails.is_non_compliance,
      });
    }
  }

  // componentDidUpdate(prevProps: Props, prevState: State) {}

  handleCancel = () => {
    this.props.onVisibleChange(false);
    this.setState({
      category_name: "",
      category_type: "",
      catagory_id: "",
      goods_or_services: "",
      is_compliance: true,
    });
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.addCategory6
    );
  };

  handleCategoryName = (event: any) => {
    this.setState({
      category_name: event?.target.value,
    });
  };
  handleCategoryType = (event: any) => {
    this.setState({
      category_type: event?.target.value,
    });
  };

  handleGoodsOrServices = (event: any) => {
    this.setState({
      goods_or_services: event?.target.value,
    });
  };

  handleCategoryCompliance = (checked: any) => {
    this.setState({
      is_compliance: checked,
    });
  };

  handleSave = async () => {
    this.setState({
      category_name: "",
      category_type: "",
      catagory_id: "",
      goods_or_services: "",
      is_compliance: true,
    });
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.addCategory7
    );

    const { ok, json, message } = await fetchUtil(
      "POST",
      "/add_expense_category",
      {
        category_name: this.state.category_name,
        category_type: this.state.category_type,
        is_goods: this.state.goods_or_services === "goods" ? true : false,
        is_non_compliance: this.state.is_compliance ? false : true,
      }
    );
    if (!ok) {
      notification.warn({ message });
    } else {
      notification.success({ message });
      this.props.onVisibleChange(false, json[0]?.category_id);
    }
    // console.log("new");
  };
  handleEdit = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.addCategory8
    );

    // console.log("edit");
    const { ok, message } = await fetchUtil("POST", "/edit_expense_category", {
      id: this.state.catagory_id,
      category_name: this.state.category_name,
      category_type: this.state.category_type,
    });
    if (!ok) {
      notification.warn({ message });
    } else {
      notification.success({ message });
      this.props.onVisibleChange(false, this.state.catagory_id);
    }
  };

  handleDelete = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.addCategory9
    );

    const { ok, message } = await fetchUtil(
      "POST",
      "/delete_expense_category",
      {
        id: this.state.catagory_id,
      }
    );
    if (!ok) {
      notification.warn({ message });
    } else {
      notification.success({ message: "Deleted Successfully" });
      this.props.onVisibleChange(false, this.state.catagory_id);
      // this.props.history.push("/app/expense");
    }
  };

  render() {
    const { visible } = this.props;
    return (
      <>
        <DraggableModalProvider>
          <DraggableModal
            maskClosable={false}
            title={
              this.props.selectedCatgoryDetails
                ? "Edit category"
                : "Add category"
            }
            visible={visible}
            initialHeight={370}
            initialWidth={520}
            onCancel={this.handleCancel}
            footer={
              <>
                {this.props.selectedCatgoryDetails ? (
                  <Button onClick={this.handleDelete} type="default" danger>
                    Delete
                  </Button>
                ) : null}

                <Button onClick={this.handleCancel} type="ghost">
                  Cancel
                </Button>

                <Button
                  type="primary"
                  disabled={
                    !this.state.category_name ||
                    !this.state.category_type ||
                    !this.state.goods_or_services
                  }
                  onClick={
                    !this.props.selectedCatgoryDetails
                      ? this.handleSave
                      : this.handleEdit
                  }
                >
                  Save
                </Button>
              </>
            }
            // onCancel={this.handleCancel}
            destroyOnClose
            // centered
          >
            <>
              <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
                <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <FieldWithAction
                    label="Category name"
                    actionLabel=""
                    onAction={noop}
                  >
                    <Input
                      value={this.state.category_name}
                      onChange={this.handleCategoryName}
                    />
                  </FieldWithAction>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FieldWithAction
                      label="Category type"
                      actionLabel=""
                      onAction={noop}
                    >
                      {/* <Input
                    value={this.state.category_type}
                    onChange={this.handleCategoryType}
                  /> */}
                      <Radio.Group
                        onChange={this.handleCategoryType}
                        value={this.state.category_type}
                      >
                        <Radio value={"rent"}>Rent</Radio>
                        <Radio value={"property"}>Property</Radio>
                        <Radio value={"salary"}>Salary</Radio>
                        <Radio value={"general"}>General</Radio>
                      </Radio.Group>
                    </FieldWithAction>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "60px",
                      }}
                    >
                      <FieldWithAction label="" actionLabel="" onAction={noop}>
                        {/* <Input
                    value={this.state.category_type}
                    onChange={this.handleCategoryType}
                  /> */}
                        <Radio.Group
                          onChange={this.handleGoodsOrServices}
                          value={this.state.goods_or_services}
                        >
                          <Space direction="horizontal">
                            <Radio value={"goods"}>Goods</Radio>
                            <Radio value={"services"}>Services</Radio>
                          </Space>
                        </Radio.Group>
                      </FieldWithAction>
                      <Switch
                        style={{ width: "130px", marginLeft: "20px" }}
                        checkedChildren="Compliance"
                        unCheckedChildren="Non-compliance"
                        // defaultChecked={true}
                        onChange={this.handleCategoryCompliance}
                        checked={this.state.is_compliance}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          </DraggableModal>
        </DraggableModalProvider>
      </>
    );
  }
}
