export const titles = {
  LandingPage: "Cheqd - Get taxes and compliances done on time",
  dashboardPage: "Cheqd - Dashboard",
  AddClientModal: "Cheqd - Add client modal",
  CompanyList: "Cheqd - Add company list modal",
  employeePage: "Cheqd - Employee",
  EmployeeDetailsPage: "Cheqd - Employee details",
  ExpensePage: "Cheqd - Expense",
  ExpenseBulkCreatePage: "Cheqd - Create bulk expense",
  PurchaserDebitNotePage: "Cheqd - Supplier credit note",
  SellerDebitNotePage: "Cheqd - Supplier debit note",
  ExpenseDetailsPage: "Cheqd - Expense details",
  CreditNotePage: "Cheqd - Credit note",
  DebitNotePage: "Cheqd - Debit note",
  DebitNoteDetailsPage: "Cheqd - Debit note details",
  InvoicePage: "Cheqd - Invoice",
  InvoiceDetailsPage: "Cheqd - Invoice details",
  PayrollPage: "Cheqd - Payroll",
  PayrollSingleSalaryPage: "Cheqd - Payroll single salary",
  PayrollBulkSalaryPage: "Cheqd - Payroll bulk salary",
  PayrollSalaryDetailsPage: "Cheqd - Payroll salary details",
  ProfilePage: "Cheqd - Profile",
  SyncStatusPage: "Cheqd - Sync status",
  VendorDetailsPage: "Cheqd - Vendor details",
  VendorPage: "Cheqd - Vendor",
  MembershipPage: "Cheqd - Membership",
  ReimbursementPage: "Cheqd - Reimbursement",
  ReimbursementDetailsPage: "Cheqd - ReimbursementDetails",
  CompliancePage: "Cheqd - Compliance",
  Synchronize: "Cheqd - Synchronize",
  Unauthorized: "Cheqd - Unauthorized",
  ExpiredMembership: "Cheqd - Expired membership",
  SettingsRole: "Cheqd - Settings",
  SettingsUsers: "Cheqd - Settings",
  SalaryTemplate: "Cheqd - Salary template",
  TermsAndConditionsPage: "Cheqd - Terms and conditions",
  MembershipPlan: "Cheqd - Membership plan",
  SettingsMembership: "Cheqd - Settings membership plan",
  NotFoundPage: "Cheqd - Not found",
  WIPPage: "Cheqd - WIP",
};
