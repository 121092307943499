import React from "react";
import { IMTIconProps, IMTIconElement, __colors } from "./interface";

const NorEx: IMTIconElement = ({
  highlighted,
  ...props
}: IMTIconProps) => {
  const color = highlighted ? "#458FE7" : "black";
  return (

<svg  width="20"
    height="17"  viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M792 400H736C731.6 400 728 403.6 728 408V728H72V72H392C396.4 72 400 68.4 400 64V8C400 3.6 396.4 0 392 0H32C14.3 0 0 14.3 0 32V768C0 785.7 14.3 800 32 800H768C785.7 800 800 785.7 800 768V408C800 403.6 796.4 400 792 400Z" fill="black"/>
<path d="M506.005 221.303L505.001 283.523C504.948 288.18 508.754 292 513.459 292H513.67L576.051 290.482C577.108 290.43 578.166 290.011 578.906 289.279L798.771 72.1104C800.41 70.4882 800.41 67.8194 798.771 66.1971L733.06 1.20359C732.214 0.36631 731.157 0 730.046 0C728.936 0 727.879 0.41864 727.033 1.20359L507.221 218.372C506.457 219.162 506.023 220.209 506.005 221.303ZM539.574 233.652L730.046 45.5269L753.941 69.1276L563.363 257.358L539.204 257.933L539.574 233.652Z" fill="black"/>
<path d="M268.136 415.8H360.596C365.38 415.8 369.336 417.364 372.464 420.492C375.776 423.436 377.432 427.3 377.432 432.084C377.432 436.684 375.776 440.456 372.464 443.4C369.336 446.16 365.38 447.54 360.596 447.54H283.316L285.8 442.848V496.668L283.592 494.46H348.176C352.96 494.46 356.916 496.024 360.044 499.152C363.356 502.096 365.012 505.96 365.012 510.744C365.012 515.344 363.356 519.116 360.044 522.06C356.916 524.82 352.96 526.2 348.176 526.2H284.42L285.8 523.992V579.744L283.592 577.26H360.596C365.38 577.26 369.336 578.916 372.464 582.228C375.776 585.356 377.432 589.036 377.432 593.268C377.432 597.868 375.776 601.64 372.464 604.584C369.336 607.528 365.38 609 360.596 609H268.136C263.352 609 259.304 607.436 255.992 604.308C252.864 600.996 251.3 596.948 251.3 592.164V432.636C251.3 427.852 252.864 423.896 255.992 420.768C259.304 417.456 263.352 415.8 268.136 415.8ZM541.449 415.524C545.681 415.524 549.269 417.18 552.213 420.492C555.341 423.804 556.905 427.3 556.905 430.98C556.905 434.476 555.709 437.788 553.317 440.916L497.841 514.056L479.073 488.388L527.925 423.528C531.789 418.192 536.297 415.524 541.449 415.524ZM418.353 414.972C423.689 414.972 428.197 417.364 431.877 422.148L553.317 583.608C555.525 586.552 556.629 589.956 556.629 593.82C556.629 598.972 554.789 603.02 551.109 605.964C547.429 608.908 543.749 610.38 540.069 610.38C534.733 610.38 530.225 607.988 526.545 603.204L405.105 441.744C402.713 438.8 401.517 435.488 401.517 431.808C401.517 427.024 403.265 423.068 406.761 419.94C410.441 416.628 414.305 414.972 418.353 414.972ZM415.869 610.38C411.821 610.38 408.325 608.908 405.381 605.964C402.437 602.836 400.965 599.432 400.965 595.752C400.965 591.704 402.345 587.932 405.105 584.436L461.133 508.536L479.349 535.308L429.669 602.652C425.989 607.804 421.389 610.38 415.869 610.38Z" fill="black"/>
</svg>

  );
};

export default NorEx;
