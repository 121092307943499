import React from "react";
import { SettingFilled } from "@ant-design/icons";
import { IMTIconProps, IMTIconElement, __colors } from "./interface";

const Setting: IMTIconElement = ({ highlighted, className }: IMTIconProps) => {
  return (
    <SettingFilled
      className={className}
      style={{
        color: highlighted ? __colors.active : __colors.inactive,
      }}
    />
  );
};

export default Setting;
