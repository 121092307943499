// tslint-disable-file jsx-no-lambda
import React, { useState } from "react";
import cx from "classnames";
import { Row, Col, Space, Button, Drawer } from "antd";
import commonStyles from "~/component/common.module.css";
import Illustration from "./Illustration";
import styles from "./LandingPageLayout.module.css";
import IMTMediaQueries from "~/component/IMTMediaQueries";
import HubspotScheduler from "./CalendlyScheduler";
import strings from "../../i18n/en";
import VideoDrawer from "~/component/VideoDrawer";
import { registerEvent } from "~/analytics";
import Graphics1 from "~/assets/bg-graphics/graphics-1-1.png";

import * as ga from "~/contants/gaConstants";

const renderIllustration = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <div className={styles.illustrationSvg}>
      <Illustration />
      <div className={styles.heroBlockImg4} />
    </div>
  </div>
);

export default function HeroBlock({ className, showSignUpModal }: any) {
  const [showScheduler, setShowScheduler] = useState(false);
  const [openVideoPlayer, setOpenVideoPlayer] = useState(false);

  return (
    <IMTMediaQueries>
      {(matches) => (
        <Row className={cx(className, styles.heroblockRow)} justify="center">
          <img className={styles.heroGraphics1} src={Graphics1} alt="" />
          <Col
            /* span={matches.justWidePhone ? 24 : 24} */
            span={24}
            /* style={matches.md ? { display: "none" } : {}} */
            className={`${styles.illustrationCol} ${styles["illustrationCol-only-md-top"]}`}
          >
            {renderIllustration()}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Space
              direction="vertical"
              size={20}
              style={{ display: "flex", alignItems: "flex-start" }}
              className={styles.textCol}
            >
              <div
                /* level={matches.lg ? 1 : matches.md ? 2 : 3} */
                className={styles.HeadLine}
              >
                Integrated{" "}
                <span className={styles.primaryTextColor}> Finance, HRMS </span>
                and{" "}
                <span className={styles.primaryTextColor}>
                  {" "}
                  Compliance{" "}
                </span>{" "}
                for your business
              </div>
              <div className={styles.subLine}>
                The platform <b>streamlines</b> financial operations, HR
                processes, and compliance for small businesses. It's{" "}
                <b>simple</b>,<b> intuitive</b> and <b>automates</b> mundane
                operations so that you can focus on <b>growing</b> your
                business.
              </div>
              <Space
                /* style={{ marginTop: matches.md ? 30 : 5 }}
                size={
                  matches.md ? "small" : matches.xs || matches.sm ? 4 : "middle"
                }
                direction={matches.md ? "horizontal" : "vertical"} */
                className={styles.heroBlockSpace}
              >
                <Button
                  type="primary"
                  size="large"
                  className={`${commonStyles["text-color-processing"]} ${commonStyles["border-color-processing"]} ${styles.hoverTransparancy}`}
                  style={{ backgroundColor: "white" }}
                  onClick={() => {
                    setOpenVideoPlayer(true);
                    registerEvent(
                      ga.EVENT_CATEGORY_BUTTON_CLICK,
                      ga.EVENT_CLICK,
                      ga.events.heroBlock278
                    );
                  }}
                >
                  {strings.WATCH_VIDEO}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className={`${commonStyles["bg-processing"]} ${commonStyles["border-color-processing"]} ${styles.hoverTransparancy}`}
                  onClick={() => {
                    setShowScheduler(true);
                  }}
                >
                  {strings.SCHEDULE_DEMO}
                </Button>
              </Space>
            </Space>
            <br />
          </Col>
          <Col
            md={{ span: 11, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
            xl={{ span: 10, offset: 2 }}
            /* style={matches.md ? {} : { display: "none" }} */
            className={`${styles.illustrationCol} ${styles["illustrationCol-only-md-bottom"]}`}
          >
            {renderIllustration()}
          </Col>
          <VideoDrawer
            url="https://www.youtube.com/watch?v=xiHV5NJRDVg"
            title="How cheqd works"
            onClose={() => setOpenVideoPlayer(false)}
            openDrawer={openVideoPlayer}
            isMobile={!matches.lg && !matches.md}
          />
          <Drawer
            placement="right"
            visible={showScheduler}
            onClose={() => {
              setShowScheduler(false);
            }}
            width={matches.lg || matches.md ? "60%" : "100%"}
            destroyOnClose
            drawerStyle={{ backgroundColor: "rgb(245, 248, 250)" }}
          >
            <HubspotScheduler />
          </Drawer>
        </Row>
      )}
    </IMTMediaQueries>
  );
}
