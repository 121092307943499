import React from "react";
import { IMTIconProps, IMTIconElement, __colors } from "./interface";

const Expense: IMTIconElement = ({ highlighted, ...props }: IMTIconProps) => {
  const color = highlighted ? __colors.active : __colors.inactive;
  return (
    <svg x="0px" y="0px" viewBox="0 0 56 56" xmlSpace="preserve" {...props}>
      <style>{".st3{fill:#93989e}"}</style>
      <path
        d="M47 7.5H22.9c-4 0-7.2 3.2-7.2 7.2v14.7H4.9l6.3-6.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L3.5 28c-.5.5-.7 1-.9 1.5-.5.1-.8.5-.8 1s.4.9.8 1c.2.5.4 1 .8 1.4l6.3 6.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-6.3-6.3h10.8v9.8c0 4 3.2 7.2 7.2 7.2H47c4 0 7.2-3.2 7.2-7.2V14.8c0-4-3.2-7.3-7.2-7.3zm0 39H22.9c-2.9 0-5.2-2.3-5.2-5.2v-9.8h8.2c.6 0 1-.4 1-1s-.4-1-1-1h-8.2v-8.6c0-2.9 2.3-5.2 5.2-5.2H47c2.9 0 5.2 2.3 5.2 5.2v3.5H39.3c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5h12.9v3.8c0 2.9-2.3 5.3-5.2 5.3zm5.2-11H39.3c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5h12.9v9zm0-19.6c-1.3-1.4-3.2-2.2-5.2-2.2H22.9c-2 0-3.9.9-5.2 2.2v-1.2c0-2.9 2.3-5.2 5.2-5.2H47c2.9 0 5.2 2.3 5.2 5.2v1.2z"
        fill={color}
      />
      <path
        d="M38.6 31.1c0 .6.4 1 1 1h.7c.6 0 1-.4 1-1s-.4-1-1-1h-.7c-.5 0-1 .4-1 1z"
        fill={color}
      />
    </svg>
  );
};

export default Expense;
