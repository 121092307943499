import { SVGProps, FC } from "react";

export interface IMTIconProps extends SVGProps<SVGSVGElement> {
  highlighted?: boolean;
  isSyncing?: boolean;
}

export type IMTIconElement = FC<IMTIconProps>;

export const __colors = {
  inactive: "#333",
  active: "#000",
};
