import { roundNumber } from "~/lib";

export type TAX_INCLUSION = "ExclusiveOfTax" | "InclusiveOfTax" | "OutOfTax";

export const DEFAULT_TAX_INCLUSION: TAX_INCLUSION = "ExclusiveOfTax";

export enum TaxTeritoryType {
  SAME_STATE,
  INTRA_STATE,
  OTHER_TERITORY,
}

export const getGST = (
  amount: number,
  gstPercentage: number,
  taxTeritoryType: TaxTeritoryType,
  taxInclusion: TAX_INCLUSION,
  cessRate: number
): {
  // subTotal: number;
  // cgst: number;
  // sgst: number;
  // igst: number;
  // total: number;
  // cess: number;
  liCgst: number;
  liSgst: number;
  liIgst: number;
  liCess: number;
  liSubTotal: number;
  liTotal: number;
} => {
  // console.log(gstPercentage);
  if (
    taxInclusion === "OutOfTax" ||
    taxTeritoryType === TaxTeritoryType.OTHER_TERITORY
  ) {
    return {
      liSubTotal: amount,
      liCgst: 0,
      liSgst: 0,
      liIgst: 0,
      liCess: 0,
      liTotal: amount,
    };
  } else if (taxInclusion === "ExclusiveOfTax") {
    // console.log(amount);
    const cess = (amount * cessRate) / 100;
    const gst = (amount * gstPercentage) / 100;
    let cgst = 0;
    let sgst = 0;
    let igst = 0;
    if (taxTeritoryType === TaxTeritoryType.INTRA_STATE) {
      igst = gst;
    } else {
      cgst = sgst = gst / 2;
    }
    const subTotal = amount;
    const total = subTotal + gst + cess;
    return {
      liSubTotal: subTotal,
      liCgst: cgst,
      liSgst: sgst,
      liIgst: igst,
      liTotal: total,
      liCess: cess,
    };
  } else if (taxInclusion === "InclusiveOfTax") {
    const total = amount;
    const subTotal = (amount * 100) / (100 + gstPercentage + cessRate);
    const gst = subTotal * (gstPercentage / 100);
    const cess = subTotal * (cessRate / 100);
    let cgst = 0;
    let sgst = 0;
    let igst = 0;
    if (taxTeritoryType === TaxTeritoryType.INTRA_STATE) {
      igst = gst;
    } else {
      cgst = sgst = gst / 2;
    }
    return {
      liSubTotal: subTotal,
      liCgst: cgst,
      liSgst: sgst,
      liIgst: igst,
      liTotal: total,
      liCess: cess,
    };
  } else {
    throw new Error("Invalid taxInclusion type");
  }
};

export const getLineItemTotal = (
  tax_inclusion: any,
  lineItems: any,
  // net_total: any,
  // subTotal: any,
  // igst: any,
  // cgst: any,
  // sgst: any,
  taxTeritoryType: any,
  gstPickList: any,
  opts_cess: any,
  companyFlag: boolean
): {
  igstSum: any;
  cgstSum: any;
  sgstSum: any;
  subTotalVal: any;
  netTotal: any;
  cessAmount: any;
  amountSum: any;
  linetemsVal: any;
} => {
  // net_total = subTotal = igst = cgst = sgst = 0;
  let amount_sum = 0;
  let igstSum = 0;
  let cgstSum = 0;
  let sgstSum = 0;
  let cessAmount = 0;
  let sub_total = 0;
  let mainTotal = 0;
  for (const li of lineItems) {
    const liAmount = +li.quantity * +li.rate || 0;
    const ligstPercentageid = li.gst_rate_id;
    const cessate = li.cess_rate_id;
    const ligstPercentage = gstPickList.find(
      (it: any) => it.id === ligstPercentageid
    )?.rate;
    const cessRate = opts_cess.find((it: any) => it.id === cessate)
      ?.cessPercentage;
    amount_sum += roundNumber(liAmount ? liAmount : 0);
    const { liCgst, liSgst, liIgst, liCess, liSubTotal, liTotal } = getGST(
      +li.quantity * +li.rate,
      companyFlag === true ? (!ligstPercentage ? 0 : ligstPercentage!) : 0,
      companyFlag === true ? taxTeritoryType : 0,
      tax_inclusion,
      cessRate ? Number(cessRate) : 0
    );
    li.amount = roundNumber(+li.quantity * +li.rate);
    li.cgst = roundNumber(liCgst);
    li.sgst = roundNumber(liSgst);
    li.igst = roundNumber(liIgst);
    li.cess_amount = roundNumber(Number(liCess));
    li.total_amount = roundNumber(mainTotal + liTotal);

    if (liIgst !== 0) {
      igstSum += li.igst;
    } else {
      cgstSum += li.cgst;
      sgstSum += li.sgst;
    }
    cessAmount += liCess;
    sub_total += liSubTotal;
    mainTotal += liTotal;
  }
  return {
    igstSum: roundNumber(igstSum),
    cgstSum: roundNumber(cgstSum),
    sgstSum: roundNumber(sgstSum),
    subTotalVal: roundNumber(sub_total),
    netTotal: roundNumber(mainTotal),
    cessAmount: roundNumber(cessAmount),
    amountSum: amount_sum,
    linetemsVal: lineItems,
  };
};
