import { Form, Input, notification, Spin, Button, Tooltip } from "antd";
import { RcFile } from "antd/lib/upload";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RouteChildrenProps } from "react-router";
import {
  getExpenseByID,
  getCurrency,
  getPaymentDetais,
  editExpenseDetails,
  saveNewExpense,
} from "~/api/expense";
import { Expense } from "~/feature/expense/ExpenseModal";
import commonStyle from "~/component/common.module.css";
import DatePicker from "~/component/antd-overrides/DatePicker";
import { Required } from "~/lib/form_rules";
import { isFuture } from "date-fns";
import { Col, Row, Modal } from "antd";
import { FORMAT_DATE, roundNumber, formatDate } from "~/lib";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import IMTContent from "~/layout/main-layout/IMTContent";
import styles from "./ExpenseDetailsPage.module.css";
import ExpenseDetailsHeader from "./ExpenseDetailsHeader";
import useCqdForm from "~/lib/hook/useCqdForm";
import { AuthState } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { fetchUtil } from "~/api/common";
import PaymentDialog from "../modal/PaymentDialog";
import TdsPayment from "~/component/TdsPayment";
import ExpenseLineItem, {
  SKELETON_ROW,
  validateColumns,
} from "./expense-line-item/ExpenseLineItem";
import CategorySelect from "~/fragment/form-input/CategorySelect";
import VendorSelect from "~/fragment/form-input/VendorSelect";
import OcrSelect, { OCRData } from "~/fragment/form-input/OcrSelect";
import PlaceOfSupply from "~/fragment/form-input/PlaceOfSupply";
import AmountsAreSelect from "~/fragment/form-input/AmountsAreSelect";
import ApplyCess from "~/fragment/form-input/ApplyCess";

import { OTHER_TERITORY_ID } from "~/lib/constants";
import {
  DeletedExpenseLineItemRecord,
  ExpenseLineItemRecord,
} from "./expense-line-item/ExpenseLineItemTypes";
import { TaxTeritoryType, DEFAULT_TAX_INCLUSION } from "~/lib/taxes";
import { getTotalGST } from "~/lib/taxCalculation";
import usePersistantCallbackRef from "~/lib/hook/usePersistantCallbackRef";
import { useGSTRateList } from "~/lib/hook/api-hook/picklistHooks";
import { registerEvent } from "~/analytics";
import FieldInfo from "~/component/FieldInfo";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";
import * as ga from "~/contants/gaConstants";

type PathParams = {
  id?: string;
  slug?: "new" | "copy";
};

type LocationState = {
  data?: Expense;
  fileList?: Array<RcFile>;
  search?: string;
};

const mapStateToProps = (state: AuthState) => ({
  place_of_supply_id: state.mainAppSlice.user.place_of_supply,
  activeRole: state.mainAppSlice.user.activeRole,
  baseCompanyGstin: state.mainAppSlice.user.gstin,
});

/**
 * Connect to store
 */

const connector = connect(mapStateToProps);

const getCurrencyConversionList = async (
  symbol_name: string | undefined,
  receipt_date: Date
) => {
  const { ok, message, data } = await getCurrency(symbol_name!, receipt_date);
  if (ok) {
    return data!;
  } else {
    notification.error({ message });
  }
};

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props
  extends RouteChildrenProps<PathParams, LocationState>,
    PropsFromRedux {}

const ExpenseDetailsPage = (props: Props) => {
  const { TextArea } = Input;

  const expenseId = props.match?.params.id ? +props.match.params.id : null;
  const isNew = !expenseId;

  const [normalInfo, setInfo] = useState({
    editMode: false,
    apiState: "idle",
    rippleEffect: false,
    payDlgVisible: false,
    isFormDataLoading: false,
    tdsDlgVisible: false,
    tdsEditFlag: false,
    tdsInfo: {},
  });
  const [isDescriptionDirty, setDirty] = useState(false);
  const [deletedRows, setDeletedRows] = useState<
    Array<DeletedExpenseLineItemRecord>
  >([]);

  const { prevData, data, update, resetDirtyFlag, formProps } = useCqdForm<
    Expense
  >();
  const askForPayNotificationKey = useRef<string>();
  const loadExpenseData = useCallback(
    async (_expenseId: number) => {
      resetDirtyFlag();
      setInfo((oldInfo) => ({
        ...oldInfo,
        isFormDataLoading: true,
        apiState: "loading",
      }));
      try {
        const { ok, data: expenseData, message } = await getExpenseByID(
          _expenseId,
          props.match?.params.slug === "copy" ? true : false
        );
        if (!ok) {
          notification.error({
            message: "Failed to load details",
            description: message,
          });
        } else {
          setDirty(true);
          setInfo((oldInfo) => ({
            ...oldInfo,
            isFormDataLoading: true,
            apiState: "loading",
          }));
          update({
            ...expenseData,
            extra: {
              ...expenseData?.extra,
              apiData: {
                place_of_supply: expenseData!.place_of_supply!,
              },
              vendor: expenseData!.customer_id
                ? ({
                    __init__: {
                      customer_idx: expenseData?.customer_idx,
                      uid: Math.random(),
                    },
                  } as any)
                : "",
              hasCess: !!Number(expenseData?.cess_amount ?? 0),
            },
          });
        }
      } catch (error) {
        console.warn(error);
        notification.warn({ message: "Unable to load expense!" });
      } finally {
        setInfo((oldInfo) => ({
          ...oldInfo,
          isFormDataLoading: false,
          apiState: "idle",
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update, props.match?.params.slug === "copy"]
  );

  useEffect(() => {
    if (isNew) {
      setInfo((oldInfo) => ({
        ...oldInfo,
        editMode: true,
      }));
    }
  }, [isNew]);

  useEffect(() => {
    if (data.extra?.vendor?.customer_idx) {
      getCurrencyConversionList(
        data.extra.vendor.symbol_name,
        data.receipt_date
      ).then((res) => {
        update((oldData) => {
          return {
            extra: {
              ...oldData.extra,
              convertionRate: res[0].import_rate,
            },
          };
        });
      });
    }
  }, [data.receipt_date, update]);

  useEffect(() => {
    if (
      data.place_of_supply === OTHER_TERITORY_ID ||
      (data.extra?.vendor?.gstin === null &&
        !(prevData.extra?.vendor as any)?.__init__)
    ) {
      update((oldData) => {
        return {
          tax_inclusion: "OutOfTax",
          extra: {
            ...oldData.extra,
            hasCess: false,
          },
        };
      });
    } else if (
      data.place_of_supply !== OTHER_TERITORY_ID &&
      data.extra?.vendor?.gstin &&
      data.extra?.vendor?.customer_id !== data.customer_id &&
      !isNew
    ) {
      update((oldData) => {
        return {
          tax_inclusion: DEFAULT_TAX_INCLUSION,
          extra: {
            ...oldData.extra,
            hasCess: false,
          },
        };
      });
    }

    if (isNew && props.match?.params.slug !== "copy") {
      update(() => {
        const taxInclusion =
          data.place_of_supply === OTHER_TERITORY_ID ||
          data.extra?.vendor?.gstin === "" ||
          data.extra?.vendor?.gstin === null ||
          (prevData.extra?.vendor?.customer_id
            ? prevData.extra?.vendor?.customer_id === data.customer_id
            : false)
            ? "OutOfTax"
            : DEFAULT_TAX_INCLUSION;

        return {
          tax_inclusion: taxInclusion,
        };
      });
    }

    // We are actually not dependent on data.extra , so disabling eslint deps check, unless we introduces callback style update function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, data.place_of_supply!, data.extra?.vendor?.customer_idx]);

  /**
   * Side-Effect: mark hasCess=false when tax_inclusion changes to "OutOfTax"
   */
  useEffect(() => {
    if (data.tax_inclusion === "OutOfTax") {
      update((oldData) => ({
        extra: {
          ...oldData.extra,
          hasCess: false,
        },
      }));
    }
  }, [update, data.tax_inclusion]);
  useEffect(() => {
    if (data.description && !isDescriptionDirty) {
      handleRowDataChange(
        data.row_data.map((row, idx) =>
          idx === 0 ? { ...row, description: data.description } : row
        )
      );
    }
  }, [data.description]);

  useEffect(() => {
    const doWork = async () => {
      if (data.customer_id === data.extra?.vendor?.customer_id) {
        update({
          place_of_supply:
            prevData.extra?.vendor?.customer_idx &&
            prevData.extra?.vendor?.customer_idx !==
              data.extra?.vendor?.customer_idx
              ? data.extra?.vendor?.state_code
              : data.extra?.apiData?.place_of_supply,
        });
      } else {
        update({
          place_of_supply: data.extra?.vendor?.state_code,
        });
      }

      const convertionList = await getCurrencyConversionList(
        data.extra?.vendor?.symbol_name,
        data.receipt_date
      );
      if (data.extra?.vendor?.symbol_name) {
        const selectedConvertionRate = convertionList[0]!.import_rate;
        const effectiveDate = convertionList[0].effective_date;
        const sourceLink = convertionList[0].source_link;
        update((oldData) => ({
          extra: {
            ...oldData.extra,
            convertionRate:
              expenseId &&
              data.customer_idx === data.extra?.vendor?.customer_idx
                ? data.conversion_rate
                : selectedConvertionRate,
          },
          conversion_rate:
            expenseId && props.match?.params.slug !== "copy"
              ? data.conversion_rate
              : selectedConvertionRate,
          effective_date: effectiveDate,
          source_link: sourceLink,
        }));
      }
    };

    if (
      prevData.extra?.vendor !== data.extra?.vendor &&
      data.extra?.vendor?.state_code
    ) {
      doWork();
    }
    // We are actually not dependent on data.extra , so disabling eslint deps check, unless we introduces callback style update function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.extra?.vendor?.customer_id, update, data.receipt_date]);

  useEffect(() => {
    if (expenseId && !normalInfo.tdsEditFlag) {
      loadExpenseData(expenseId);
    } else if (props.match?.params.slug === "copy") {
      const urlQ = new URLSearchParams(props.location.search);
      const fromId = +(urlQ.get("from") ? urlQ.get("from")! : "");
      if (Number.isFinite(fromId)) {
        loadExpenseData(fromId);
      }
    } else {
      // unuseds
    }
  }, [expenseId, props.match, props.location.search, loadExpenseData]);

  const handleEdit = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.expenseDetailsPage91
    );
    setInfo((oldInfo) => ({
      ...oldInfo,
      editMode: true,
    }));
  };

  // this func is not used in expense detailpage

  // const disabledDate = (current: Date) => {
  //   const year = new Date().getFullYear();
  //   return (
  //     current.getTime() < new Date(`${year - 1}-04-01`).getTime() ||
  //     current.getTime() > new Date(`${year + 1}-04-01`).getTime()
  //   );
  // };

  const handleCancelEdit = () => {
    if (isNew) {
      props.history.goBack();
    } else {
      window.location.reload();
    }
  };

  const handleRowDataChange = usePersistantCallbackRef(
    (
      newRowData: Array<ExpenseLineItemRecord>,
      deletedRowData?: ExpenseLineItemRecord
    ) => {
      update({
        row_data: newRowData,
      });
      if (deletedRowData) {
        setDeletedRows((oldRows) => [
          ...oldRows,
          {
            ...deletedRowData,
            is_active: false,
          },
        ]);
      }
    }
  );

  const handlePayDlg = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.expenseDetailsPage92
    );
    const { paymentStatus } = data;

    if (paymentStatus === "Completed") {
      return notification.success({ message: "Already paid" });
    }

    setInfo((oldInfo) => ({
      ...oldInfo,
      payDlgVisible: true,
    }));
  };

  // We derive various values here

  const taxTeritoryType =
    data.place_of_supply === OTHER_TERITORY_ID
      ? TaxTeritoryType.OTHER_TERITORY
      : data.place_of_supply === props.place_of_supply_id
      ? TaxTeritoryType.SAME_STATE
      : TaxTeritoryType.INTRA_STATE;
  const isEditing = !!normalInfo.editMode;
  const isLoading = normalInfo.apiState !== "idle";
  const closeNotification = (newExpId: any) => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      rippleEffect: true,
    }));
    props.history.replace(`/app/expense/${newExpId}`);
  };

  const handleTdsPayDlgFromAsk = (target: HTMLButtonElement) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.expenseDetailsPage93
    );

    setInfo((oldInfo) => ({
      ...oldInfo,
      tdsDlgVisible: true,
    }));
  };

  const askForPayment = (newExpId: number) => {
    const key = `open1${Date.now()}`;
    const close = closeNotification(newExpId) as any;
    const btn = (
      <Button
        type="primary"
        size="small"
        // tslint:disable-next-line: jsx-no-lambda
        onClick={(e: React.MouseEvent) => {
          registerEvent(
            ga.EVENT_CATEGORY_BUTTON_CLICK,
            ga.EVENT_CLICK,
            ga.events.expenseDetailsPage94
          );
          notification.close(key);
          const expLoadPromise = loadExpenseData(newExpId);
          const target = e.target;
          expLoadPromise.then(() => {
            handleTdsPayDlgFromAsk(target as HTMLButtonElement);
          });
        }}
      >
        Apply now
      </Button>
    );
    notification.open({
      duration: 0,
      message: `Apply TDS? (You can change it later while payment logging`,
      description: "",
      btn,
      key,
      onClose: close,
    });
    askForPayNotificationKey.current = key;
  };

  useEffect(() => {
    return () => {
      notification.close(askForPayNotificationKey.current!);
    };
  }, []);

  const askForTdsPayment = (newExpId: number, newData: Expense) => {
    const key = `open${Date.now()}`;
    const btn = (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            type="primary"
            size="small"
            style={{ marginRight: "5px" }}
            // tslint:disable-next-line: jsx-no-lambda
            onClick={(e: React.MouseEvent) => {
              registerEvent(
                ga.EVENT_CATEGORY_BUTTON_CLICK,
                ga.EVENT_CLICK,
                ga.events.expenseDetailsPage95
              );
              notification.close(key);
              const target = e.target;
              handleTdsPayDlgFromAsk(target as HTMLButtonElement);
            }}
          >
            Update TDS rate{" "}
          </Button>
          <Button
            type="ghost"
            size="small"
            onClick={() => {
              notification.close(key);
              handleEditAction(newData);
            }}
          >
            {`Keep existing rate ( ${data.tds_rate ? data.tds_rate : 0}% )`}
          </Button>
        </div>
      </>
    );
    notification.open({
      duration: 100000,
      message: "Do you wish to update the TDS rate too?",
      description: "",
      btn,
      key,
      onClose: () => {
        notification.close(key);
        handleEditAction(newData);
      },
    });
  };

  const handleSave = usePersistantCallbackRef(async () => {
    // newData is same as apiData like invoice
    try {
      await formProps.form.validateFields();

      const newRowData = [...data.row_data];
      newRowData.forEach(validateColumns);
      const hasRowDataError = newRowData.some(
        (it: any) => it.extra?.errors.length > 0
      );
      // FIXME: Dont set deletedRows to row_data, as they will be shown in the table again.
      handleRowDataChange(newRowData);
      if (hasRowDataError) {
        notification.warning({
          message: "Please fill all required fields for the line items !",
        });
        return;
      }
      const newData: Expense = {
        ...data,
        row_data: [...data.row_data, ...deletedRows],
      };
      newData.customer_idx = data.extra?.vendor?.customer_idx;
      newData.customer_id = data.extra?.vendor?.customer_id;
      newData.customer_currency = data.extra?.vendor?.symbol;
      newData.symbol_name = data.extra?.vendor?.symbol_name;
      newData.customer_gstin_id = data.extra?.vendor?.customer_gstin_id!;
      newData.conversion_rate = data.extra?.convertionRate || 1;
      const taxes = getTotalGST(
        newData.row_data.map((row: any) => {
          return {
            amount: row.is_active ? row.amount : 0,
            gstPercentage: row.is_active ? row.extra?.gstRate?.rate ?? 0 : 0,
            cessPercentage: row.is_active
              ? row.extra?.cessRate?.cessPercentage ?? 0
              : 0,
          };
        }),
        {
          taxInclusion: newData.tax_inclusion ?? "OutOfTax",
          taxTeritoryType: taxTeritoryType ?? TaxTeritoryType.OTHER_TERITORY,
        }
      );
      newData.subTotal = taxes.subTotal;
      newData.cgst = taxes.cgst;
      newData.igst = taxes.igst;
      newData.sgst = taxes.sgst;
      newData.amount = taxes.total;
      newData.cess_amount = taxes.cess;

      delete newData.extra;
      if (isNew) {
        setInfo((oldInfo) => ({
          ...oldInfo,
          isFormDataLoading: true,
        }));
        const { ok, data, message } = await saveNewExpense(newData);
        if (!ok) {
          notification.error({
            message,
          });
          setInfo((oldInfo) => ({
            ...oldInfo,
            isFormDataLoading: false,
          }));
        } else {
          setInfo((oldInfo) => ({
            ...oldInfo,
            editMode: false,
            isFormDataLoading: false,
          }));
          notification.success({
            message: "Expense created successfully",
            duration: 1,
          });
          resetDirtyFlag();
          setTimeout(() => {
            askForPayment(data!.expense_id);
          }, 2000);
        }
      } else {
        resetDirtyFlag();
        setInfo((oldInfo) => ({
          ...oldInfo,
          tdsEditFlag: true,
          isFormDataLoading: true,
        }));
        await handleEditAction(newData);
        askForTdsPayment(expenseId!, newData);
      }
    } catch (error) {
      console.warn("Form validation failed", error);
    }
  });

  const handleEditAction = async (newData: Expense) => {
    try {
      setInfo((oldInfo) => ({
        ...oldInfo,
        isFormDataLoading: true,
      }));
      const { ok, message } = await editExpenseDetails(expenseId!, newData);
      if (!ok) {
        notification.error({
          message,
        });
        setInfo((oldInfo) => ({
          ...oldInfo,
          isFormDataLoading: false,
        }));
      } else {
        setInfo((oldInfo) => ({
          ...oldInfo,
          editMode: false,
          isFormDataLoading: false,
        }));
        notification.success({
          message,
        });
        loadExpenseData(data.id);
      }
    } catch (error) {
      console.warn("Form validation failed", error);
    }
  };

  const handleEdittds = async (tdsPayDetail: any) => {
    // here newData is used for api payload.
    try {
      await formProps.form.validateFields();
      const newRowData = [...data.row_data, ...deletedRows];
      newRowData.forEach(validateColumns);
      const newData: Expense = {
        ...data,
        row_data: newRowData,
      };
      newData.customer_idx = data.extra?.vendor?.customer_idx;
      newData.customer_id = data.extra?.vendor?.customer_id;
      newData.customer_currency = data.extra?.vendor?.symbol;
      newData.symbol_name = data.extra?.vendor?.symbol_name;
      newData.customer_gstin_id = data.extra?.vendor?.customer_gstin_id!;
      newData.conversion_rate = data.extra?.convertionRate || 1;
      newData.tds_amount = tdsPayDetail.tds_amount;
      newData.tds_rate = tdsPayDetail.tds_rate;
      const taxes = getTotalGST(
        newData.row_data.map((row: any) => {
          return {
            amount: row.is_active ? row.amount : 0,
            gstPercentage: row.is_active ? row.extra?.gstRate?.rate ?? 0 : 0,
            cessPercentage: row.is_active
              ? row.extra?.cessRate?.cessPercentage ?? 0
              : 0,
          };
        }),
        {
          taxInclusion: newData.tax_inclusion ?? "OutOfTax",
          taxTeritoryType: taxTeritoryType ?? TaxTeritoryType.OTHER_TERITORY,
        }
      );
      newData.subTotal = taxes.subTotal;
      newData.cgst = taxes.cgst;
      newData.igst = taxes.igst;
      newData.sgst = taxes.sgst;
      newData.amount = taxes.total;
      newData.cess_amount = taxes.cess;

      delete newData.extra;

      const { ok, message } = await editExpenseDetails(expenseId!, newData);
      if (!ok) {
        notification.error({
          message,
        });
        setInfo((oldInfo) => ({
          ...oldInfo,
          tdsDlgVisible: false,
        }));
      } else {
        setInfo((oldInfo) => ({
          ...oldInfo,
          editMode: false,
          tdsDlgVisible: false,
        }));
        notification.success({
          message,
        });
        loadExpenseData(data.id);
      }

      resetDirtyFlag();
    } catch (error) {
      console.warn("Form validation failed", error);
    }
  };

  const handleAfterPayment = () => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      apiState: "loading",
    }));
    loadExpenseData(data.id);
  };

  const handlePayDlgVisibleChange = (newVState: boolean) => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      payDlgVisible: false,
    }));
  };

  const handleTdsPayDlgVisibleChange = (newVState: boolean) => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      tdsDlgVisible: false,
    }));
    if (!normalInfo.tdsEditFlag) {
      loadExpenseData(data.id);
    } else {
      handleEdittds(data);
    }
  };

  const handleDelete = (e: React.MouseEvent) => {
    const expense = data;
    const title =
      data.compliance_status === "Complete" &&
      data.paymentStatus === "Completed" ? (
        `Do you really wish to delete this expense? 
        Note: Payment for this expense is already logged and one or more compliances are completed.`
      ) : data.paymentStatus === "Completed" ? (
        `Do you wish to delete this expense? 
              Note: Payment for this expense is already logged`
      ) : (
        <>
          <div>Do you wish to delete this expense?</div>

          <div className={styles.invoice}>
            Customer Name {expense?.extra?.vendor?.company_name ?? "N/A"},
            Invoice no. {expense.invoice_no ?? "N/A"}
            ,<br /> Date{" "}
            {expense.receipt_date ? formatDate(expense.receipt_date) : "N/A"}
          </div>
        </>
      );
    Modal.confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        const { ok, message } = await fetchUtil(
          "POST",
          "/update_expense/delete_expense",
          {
            receipt_id: data.receipt_id,
          }
        );
        if (!ok) {
          notification.warn({ message });
        } else {
          notification.success({ message });
          resetDirtyFlag();
          props.history.replace(
            `/app/expense${props.location.state?.search ?? ""}`
          );
        }
      },
    });
  };

  const handleRipple = () => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      rippleEffect: true,
    }));
  };

  const handleOCRLoading = (isLoading: boolean) => {
    setInfo((oldInfo) => ({
      ...oldInfo,
      isFormDataLoading: isLoading,
    }));
  };

  const handleDescriptionDirty = (dirty: boolean) => {
    setDirty(dirty);
  };

  const handleOcrDelete = () => {
    const ocrDeletedRow: any = data.row_data;

    if (data.row_data && (!isNew || props.match?.params.slug !== "copy")) {
      ocrDeletedRow.forEach((el: any) => (el.is_active = false));
      setDeletedRows(ocrDeletedRow);
    }
    update({
      documents: "",
      description: "",
      document_name: "",
      receipt_date: undefined,
      invoice_no: "",
      customer_idx: undefined,
      conversion_rate: 1,
      category_id: undefined,
      place_of_supply: undefined,
      row_data: [
        {
          ...SKELETON_ROW,
        } as any,
      ],
      extra: {
        vendor: undefined,
      },
    });
  };
  const { gstRateList } = useGSTRateList();
  const handleOcrData = (ocrData: OCRData) => {
    /**
     * TODO: use a separate modal component, & use that component
     */
    setInfo((oldInfo) => ({
      ...oldInfo,
      apiState: "loading",
    }));

    const rate = gstRateList.find((el) => el.rate === ocrData.taxRate);
    update({
      receipt_date: ocrData.receipt_date,
      invoice_no: ocrData.invoice_no,
      category_id: ocrData.category_id,
      // TODO: creation of new lineItem by using SKELETON_ROW is used in multiple places
      // its time to create a function which will do this & return the new row

      row_data: [
        {
          ...SKELETON_ROW,
          row_details_id: Math.random(),
          quantity: ocrData.amount ? 1 : 0,
          rate: ocrData.amount,
          gst_rate_id: rate?.id ? rate.id : 1,
          hsn_no: ocrData.hsn,
          extra: {
            errors: [],
          },
        } as any,
      ],
      extra: {
        ...data.extra,
        vendor: ocrData.gstin
          ? ({
              __init__: {
                gstin: ocrData.gstin,
                uid: Math.random(),
              },
            } as any)
          : "",
      },
    });

    setInfo((oldInfo) => ({
      ...oldInfo,
      apiState: "idle",
    }));
  };

  const handleOcrSelectedVendorDetail = (vendor: any) => {
    if (vendor.state_code === OTHER_TERITORY_ID) {
      update({
        tax_inclusion: "OutOfTax",
      });
    } else {
      update({
        tax_inclusion: "ExclusiveOfTax",
      });
    }
  };

  const renderUpperForm = () => {
    const dateView = editMode ? null : (
      <Form.Item className={commonStyle["w-100"]} label="Date">
        <input
          className="ant-input"
          defaultValue={data.receipt_date ? formatDate(data.receipt_date) : ""}
          readOnly
        />
      </Form.Item>
    );
    const breadcumTooltip = (
      <span>
        <h4 style={{ color: "white" }}>
          Eff. from:{" "}
          {data?.effective_date
            ? formatDate(new Date(data?.effective_date))
            : null}
        </h4>
        {data.source_link ? (
          <a
            href={data.source_link}
            style={{
              textDecoration: data.source_link ? "underline" : "none",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Source link
          </a>
        ) : (
          <h4 style={{ color: "white" }}>Source link: N/A</h4>
        )}
      </span>
    );
    return (
      <>
        <Helmet>
          <title>{titles.ExpenseDetailsPage}</title>
        </Helmet>
        <Row style={{ width: "100%" }} gutter={{ md: 8, lg: 8, xl: 16 }}>
          <Col style={{ width: "70%" }}>
            <Row style={{ width: "100%" }} gutter={{ md: 16, lg: 16, xl: 30 }}>
              <Col md={12} lg={12} xl={10} xxl={10}>
                <Row gutter={{ md: 8, lg: 16 }}>
                  {!isNew ? (
                    <Col sm={24} lg={12}>
                      <Form.Item
                        className={commonStyle["w-100"]}
                        name={isNew ? "" : "id"}
                        label="Expense id"
                      >
                        <Input bordered={false} readOnly />
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row gutter={{ md: 8, lg: 16 }}>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      className={commonStyle["w-100"]}
                      name="invoice_no"
                      label="Invoice no"
                      rules={Required("Invoice no")}
                    >
                      <Input readOnly={!editMode} autoFocus />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    {dateView || (
                      <Form.Item
                        className={commonStyle["w-100"]}
                        name="receipt_date"
                        label="Date"
                        rules={Required("Date")}
                      >
                        <DatePicker
                          disabledDate={isFuture}
                          // disabledDate={disabledDate}
                          format={FORMAT_DATE}
                          className={commonStyle["w-100"]}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Form.Item
                  name="description"
                  label="Narration"
                  rules={Required("Description")}
                >
                  <TextArea readOnly={!editMode} rows={2} />
                </Form.Item>
                <Form.Item name="category_id" rules={Required("Category")}>
                  <CategorySelect readOnly={!normalInfo.editMode} />
                </Form.Item>
                <Form.Item
                  name={["extra", "vendor"]}
                  rules={Required("vendor")}
                >
                  <VendorSelect
                    activeRole={props.activeRole}
                    readOnly={!normalInfo.editMode}
                    sendVendorDetail={handleOcrSelectedVendorDetail}
                  />
                </Form.Item>
                <Form.Item
                  label="Supplied from"
                  name="place_of_supply"
                  rules={Required("Supplied from")}
                >
                  <PlaceOfSupply readOnly={!normalInfo.editMode} />
                </Form.Item>
                {data.extra?.vendor &&
                data.extra?.vendor?.symbol_name &&
                data.extra?.convertionRate &&
                data.extra?.vendor?.symbol_name !== "INR" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FieldInfo
                      text={`Currency & conv. rate: 1 ${
                        data.extra?.vendor?.symbol_name
                      } = ${roundNumber(+data.extra?.convertionRate)} INR`}
                    />{" "}
                    <Tooltip
                      placement="right"
                      overlay={breadcumTooltip}
                      overlayStyle={{ width: "auto" }}
                    >
                      <span
                        style={{
                          marginTop: -12,
                          fontSize: 10,
                          paddingLeft: 5,
                        }}
                      >
                        <InfoCircleOutlined
                          style={{
                            color: "var(--primary-color)",
                          }}
                        />
                      </span>
                    </Tooltip>
                  </div>
                ) : null}

                <Row gutter={{ md: 8, lg: 16 }}>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      label="Amounts are"
                      name="tax_inclusion"
                      rules={Required("Amounts are")}
                    >
                      <AmountsAreSelect
                        readOnly={!normalInfo.editMode}
                        placeOfSupplyId={data.place_of_supply}
                        vendorUnregFlag={
                          data.extra?.vendor?.gstin !== null ? false : true
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item label="Apply cess" name={["extra", "hasCess"]}>
                      <ApplyCess
                        readOnly={
                          !normalInfo.editMode ||
                          data.place_of_supply === OTHER_TERITORY_ID
                        }
                        disabled={data.tax_inclusion === "OutOfTax"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col md={2} lg={2} xl={2} xxl={2} />
              <Col
                style={{
                  width: "50%",
                }}
                md={10}
                lg={10}
                xl={10}
                xxl={10}
              >
                <OcrSelect
                  value={data}
                  onFileUpload={update}
                  onOcrData={handleOcrData}
                  onLoading={handleOCRLoading}
                  readOnly={!normalInfo.editMode}
                  onOcrDelete={handleOcrDelete}
                  DragFile={props.location?.state?.fileList!}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const handlePaymentRefresh = async () => {
    const paymentDetails = await getPaymentDetais(expenseId!);
    const paidAmount = paymentDetails.payment_history.reduce(
      (sum: any, it: any) =>
        it.payment_status === "Cancelled" ? sum : sum + it.paid_amount,
      0
    );

    const { net_total, tds_amount } = data;
    const remaining = roundNumber(
      net_total ? +net_total : 0 - paidAmount - (tds_amount ? +tds_amount : 0)
    );

    update({
      paymentStatus:
        remaining === 0
          ? "Completed"
          : paidAmount > 0
          ? "Partially Paid"
          : paymentDetails.payment_history.length > 0
          ? "Pending"
          : remaining < 0
          ? "Over paid"
          : "Pending",
    });
    if (props.match?.params.id) {
      loadExpenseData(+props.match?.params.id);
    }
  };

  const {
    editMode,
    payDlgVisible,
    isFormDataLoading,
    tdsDlgVisible,
  } = normalInfo;

  const currencyInfo = data.extra?.vendor
    ? {
        name: data.extra.vendor.symbol_name,
        symbol: data.extra.vendor.symbol,
        conversionRate: data.extra.convertionRate || 1,
      }
    : undefined;
  const { match } = props;

  const paymentHisData = {
    netTotal: data.net_total,
    tdsAmont: data.tds_amount,
    paymentDetails: data.extra?.paymentDetails,
    expenseId,
    conversionRate: data.extra?.convertionRate,
    onRefresh: handlePaymentRefresh,
  };

  return (
    <IMTContent fullwidth withoutMargin={true}>
      <ExpenseDetailsHeader
        expenseId={expenseId!}
        activeRole={props.activeRole}
        isEditing={isEditing}
        rippleEffect={normalInfo.rippleEffect}
        paymentStatus={data.paymentStatus}
        handlePaymentDialog={handlePayDlg}
        apiState={normalInfo.apiState}
        slug={match?.params.slug!}
        onCancel={handleCancelEdit}
        onDelete={handleDelete}
        onSave={handleSave}
        onEdit={handleEdit}
      />
      <Spin spinning={isFormDataLoading}>
        <div className={styles.commonContent}>
          <Form style={{ width: "100%" }} {...formProps}>
            {renderUpperForm()}
            <ExpenseLineItem
              hasCess={data.extra?.hasCess}
              rowData={data.row_data}
              onRowDataChange={handleRowDataChange}
              readOnly={!isEditing}
              isLoading={isLoading}
              tax_inclusion={data.tax_inclusion}
              taxTeritoryType={taxTeritoryType}
              currency={currencyInfo}
              paymentDetails={data.extra?.paymentDetails}
              paymentHistoryData={paymentHisData}
              vendorUnregFlag={data.extra?.vendor?.gstin ? false : true}
              totalPayAmount={data.total_pay_amount}
              netTotal={data.net_total}
              tdsInfo={{
                tdsRate: data.tds_rate,
                tdsAmount: data.tds_amount,
              }}
              isCopy={props.match?.params.slug === "copy"}
              isDirtyDescription={handleDescriptionDirty}
              sameState={data.place_of_supply === props.place_of_supply_id}
            />
          </Form>
        </div>
      </Spin>
      {/* <div>
        <h3>Data</h3>
        <code>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </code>
      </div> */}
      {payDlgVisible === true ? (
        <PaymentDialog
          expense={data}
          fetchData={handleAfterPayment}
          visible={payDlgVisible}
          onVisibleChange={handlePayDlgVisibleChange}
          //  here vendorlist required to pass as props
          placeOfSupply={data.place_of_supply}
          handleRipple={handleRipple}
          history={props.history}
        />
      ) : null}
      {tdsDlgVisible === true ? (
        <TdsPayment
          expense={data}
          fetchData={handleAfterPayment}
          visible={tdsDlgVisible}
          onVisibleChange={handleTdsPayDlgVisibleChange}
          cheackFlag={normalInfo.tdsEditFlag}
          forEditSave={handleEdittds}
          taxApplicableType={data.tax_inclusion}
          conversionRate={data.extra?.convertionRate}
          symbol={data.extra?.vendor?.symbol}
        />
      ) : null}
    </IMTContent>
  );
};

export default connector(ExpenseDetailsPage);
