import React from "react";
import { TaxTeritoryType, TAX_INCLUSION } from "~/lib/taxes";
import { CurrencyInfo } from "~/fragment/line-items/LineItemTypes";

import { CreditNoteLineItemRecord } from "./CreditNoteLineItemTypes";
import CreditNoteLineItemFooter from "./CreditNoteLinteItemFooter";

interface HistoryData {
  netTotal: string | number;
  tdsAmont: string | number;
  paymentDetails: any;
  invoiceId: number | null;
  conversionRate: number | undefined;
  onRefresh: () => void;
}
export const CreditNoteLitMakeFooterRenderer = (
  currency?: CurrencyInfo,
  taxInclusion?: TAX_INCLUSION,
  taxTeritoryType?: TaxTeritoryType,
  hasCess?: boolean,
  isCopy?: boolean,
  totalPayAmount?: number,
  tdsInfo?: {
    tdsRate?: number;
    tdsAmount?: number;
  },
  paymentHistoryData?: HistoryData
) => {
  return (data: Array<CreditNoteLineItemRecord>) => (
    <CreditNoteLineItemFooter
      rowData={data}
      taxInclusion={taxInclusion}
      taxTeritoryType={taxTeritoryType}
      currency={currency}
      paymentHistoryData={paymentHistoryData}
      hasCess={hasCess}
      isCopy={isCopy!}
      totalPayAmount={totalPayAmount}
      tdsInfo={tdsInfo}
    />
  );
};
