import Select, { SelectProps, SelectValue } from "antd/lib/select";
import React from "react";
import { noop } from "~/lib";

export interface Props<T extends SelectValue> extends SelectProps<T> {
  readonly?: boolean;
}

const ReadOnlyableSelect = <T extends SelectValue>({
  readonly,
  ...props
}: Props<T>) => {
  if (readonly) {
    // Extract child Options
    let label: string | undefined = "";
    if (props.value || typeof props.value !== "undefined") {
      const selectedOptChild = React.Children.toArray(props.children).find(
        (it) => {
          if (React.isValidElement(it)) {
            return (it.props as any)?.value === props.value;
          } else {
            return false;
          }
        }
      );
      if (selectedOptChild) {
        label = (selectedOptChild as any).props.children;
      } else {
        // try options props
        const selectedOption = props.options?.find(
          (it) => it.value === props.value
        );
        if (selectedOption) {
          label = selectedOption.label?.toString();
        }
      }
    }
    return (
      <div
        className={"ant-select ant-select-single ant-select-show-arrow"}
        style={{ width: "100%" }}
      >
        <div className="ant-select-selector" style={props.style}>
          <span className="ant-select-selection-search">
            <input
              autoComplete="off"
              type="search"
              className="ant-select-selection-search-input"
              role="combobox"
              aria-haspopup="listbox"
              aria-owns="rc_select_4_list"
              aria-autocomplete="list"
              aria-controls="rc_select_4_list"
              aria-activedescendant="rc_select_4_list_1"
              readOnly
              unselectable="on"
              value={label}
              onChange={noop}
              style={{ opacity: 0 }}
              aria-expanded="false"
            />
          </span>
          <span className="ant-select-selection-item">
            {label ? label : " "}
          </span>
        </div>
        <span
          className="ant-select-arrow"
          unselectable="on"
          aria-hidden="true"
          style={{ userSelect: "none" }}
        >
          <span
            role="img"
            aria-label="down"
            className="anticon anticon-down ant-select-suffix"
          >
            {/* 
                <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="down"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                    >
                    <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z" />
                </svg> 
            */}
          </span>
        </span>
      </div>
    );
  } else {
    return <Select {...props} />;
  }
};

export default ReadOnlyableSelect;
