import React, { Component } from "react";
import { Modal, Button } from "antd";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

interface Props {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  selectedCatgoryDetails?: any;
  sendEmail: () => void;
  details: any;
  apiState: string;
}

class SendMailConfrmModal extends Component<Props> {
  // renderEmail = async () => {
  //   await this.props.details.customer_email?.forEach((el: any, idx: any) => {
  //     return <span>{el.email_id}</span>;
  //     // console.log(el)
  //   });
  // };
  render() {
    // let email =
    return (
      <Modal
        title="Send Invoice"
        visible={this.props.visible}
        onCancel={() => {
          registerEvent(
            ga.EVENT_CATEGORY_BUTTON_CLICK,
            ga.EVENT_CLICK,
            ga.events.sendMailConfrmModal318
          );
          this.props.onVisibleChange(false);
        }}
        maskClosable={false}
        footer={
          <>
            <Button danger onClick={() => this.props.onVisibleChange(false)}>
              Cancel
            </Button>
            <Button
              loading={this.props.apiState === "loading" ? true : false}
              type="primary"
              onClick={(e: any) => {
                registerEvent(
                  ga.EVENT_CATEGORY_BUTTON_CLICK,
                  ga.EVENT_CLICK,
                  ga.events.sendMailConfrmModal319
                );
                this.props.sendEmail();
              }}
            >
              Confirm
            </Button>
          </>
        }
      >
        <p>
          Are You Sure to send Mail to{" "}
          <b>
            {this.props.details.customer_name} ({" "}
            {this.props.details.customer_email.length > 0 ? (
              <span>
                {this.props.details.customer_email
                  .map((el: any) => el.email_id)
                  .join(", ")}
              </span>
            ) : null}
            ) ?
          </b>
        </p>
      </Modal>
    );
  }
}

export default SendMailConfrmModal;
