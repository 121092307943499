import React from "react";
import { IMTIconProps, IMTIconElement, __colors } from "./interface";

const Selldb: IMTIconElement = ({
  highlighted,
  ...props
}: IMTIconProps) => {
  const color = highlighted ? "#458FE7" : "black";
  return (
    
<svg  width="20"
    height="17"  viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M792 400H736C731.6 400 728 403.6 728 408V728H72V72H392C396.4 72 400 68.4 400 64V8C400 3.6 396.4 0 392 0H32C14.3 0 0 14.3 0 32V768C0 785.7 14.3 800 32 800H768C785.7 800 800 785.7 800 768V408C800 403.6 796.4 400 792 400Z" fill="black"/>
<path d="M506.005 221.303L505.001 283.523C504.948 288.18 508.754 292 513.459 292H513.67L576.051 290.482C577.108 290.43 578.166 290.011 578.906 289.279L798.771 72.1104C800.41 70.4882 800.41 67.8194 798.771 66.1971L733.06 1.20359C732.214 0.36631 731.157 0 730.046 0C728.936 0 727.879 0.41864 727.033 1.20359L507.221 218.372C506.457 219.162 506.023 220.209 506.005 221.303ZM539.574 233.652L730.046 45.5269L753.941 69.1276L563.363 257.358L539.204 257.933L539.574 233.652Z" fill="black"/>
<path d="M303.373 415.8C316.621 415.8 328.397 418.284 338.701 423.252C349.005 428.22 357.653 435.212 364.645 444.228C371.821 453.06 377.157 463.364 380.653 475.14C384.333 486.732 386.173 499.152 386.173 512.4C386.173 530.248 382.953 546.532 376.513 561.252C370.073 575.788 360.689 587.38 348.361 596.028C336.217 604.676 321.221 609 303.373 609H237.409C232.625 609 228.577 607.436 225.265 604.308C222.137 600.996 220.573 596.948 220.573 592.164V432.636C220.573 427.852 222.137 423.896 225.265 420.768C228.577 417.456 232.625 415.8 237.409 415.8H303.373ZM300.613 577.26C312.205 577.26 321.681 574.316 329.041 568.428C336.401 562.356 341.737 554.444 345.049 544.692C348.545 534.756 350.293 523.992 350.293 512.4C350.293 503.752 349.281 495.564 347.257 487.836C345.417 479.924 342.473 473.024 338.425 467.136C334.377 461.064 329.225 456.28 322.969 452.784C316.713 449.288 309.261 447.54 300.613 447.54H252.313L255.073 445.056V580.296L253.417 577.26H300.613ZM564.426 415.8C568.842 415.8 572.43 417.272 575.19 420.216C577.95 423.16 579.33 426.84 579.33 431.256V592.164C579.33 596.948 577.674 600.996 574.362 604.308C571.234 607.436 567.278 609 562.494 609C560.102 609 557.618 608.632 555.042 607.896C552.65 606.976 550.81 605.78 549.522 604.308L441.054 466.584L448.23 462.168V593.544C448.23 597.96 446.758 601.64 443.814 604.584C441.054 607.528 437.374 609 432.774 609C428.358 609 424.77 607.528 422.01 604.584C419.25 601.64 417.87 597.96 417.87 593.544V432.636C417.87 427.852 419.434 423.896 422.562 420.768C425.874 417.456 429.922 415.8 434.706 415.8C437.282 415.8 439.95 416.352 442.71 417.456C445.47 418.376 447.494 419.848 448.782 421.872L553.938 556.008L549.246 559.32V431.256C549.246 426.84 550.626 423.16 553.386 420.216C556.146 417.272 559.826 415.8 564.426 415.8Z" fill="black"/>
</svg>

  );
};

export default Selldb;
