import { Input, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import * as React from "react";
import Currency from "~/component/Currency";
import SummaryTags, { NameValuePair } from "~/component/SummaryTags";
import { formatDate } from "~/lib";
import { PurchaseVoucher } from "./tally-dirty-entity-type";

interface IProps {
  purchaseVoucherList: Array<PurchaseVoucher>;
  selectedIds: Array<number>;
  onSelectionChange: (idList: Array<number>) => void;
}

export default function PurchaseVoucherSelect({
  purchaseVoucherList,
  // selectedIds,
  onSelectionChange,
}: IProps) {
  const [searchValue, setSearchValue] = React.useState("");
  const columnDef = React.useMemo(() => {
    const definition: Array<ColumnType<PurchaseVoucher>> = [
      {
        title: "Exp. id",
        dataIndex: "id",
        filteredValue: [searchValue],
        onFilter: (value, record) => {
          return (
            String(record.id)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.description)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.catagory)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.vendor)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.receipt_date)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.net_total)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase())
          );
        },
      },
      {
        title: "Purpose",
        dataIndex: "description",
      },
      {
        title: "Category",
        dataIndex: "catagory",
      },
      {
        title: "Vendor",
        dataIndex: "vendor",
      },
      {
        title: "Date",
        dataIndex: "receipt_date",
        render(value, record: any) {
          if (value && !record._formatedCrtTime) {
            record._formatedCrtTime = formatDate(new Date(value));
          }
          return <span>{value ? record._formatedCrtTime : "-"}</span>;
        },
      },
      {
        title: "Amount",
        dataIndex: "net_total",
        render(netTotal /* , record */) {
          return (
            <Currency>{netTotal}</Currency>
            // <div>
            //   <Currency currencySymbol={record.customer_currency}>
            //     {roundNumber(+value)}
            //   </Currency>
            //   {record.symbol_name === "INR" ? null : (
            //     <span>
            //       (
            //       <Currency>
            //         {roundNumber(+record.balance_due * +record.conversion_rate)}
            //       </Currency>
            //       )
            //     </span>
            //   )}
            // </div>
          );
        },
      },
    ];

    return definition;
  }, [searchValue]);

  const rowSelection = React.useMemo(() => {
    const selection: TableRowSelection<PurchaseVoucher> = {
      onChange(_selectedRowKeys, selectedRows) {
        onSelectionChange(selectedRows.map((it) => it.id));
      },
      // selectedRowKeys: selectedIds
    };
    return selection;
  }, [onSelectionChange]);

  return (
    <>
      <Input
        placeholder="Search Here"
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          setSearchValue(e.target.value);
        }}
        style={{ maxWidth: "40%" }}
        allowClear
      />
      <Table
        rowKey="id"
        style={{ maxWidth: "80%", cursor: "pointer" }}
        columns={columnDef}
        rowSelection={rowSelection}
        dataSource={purchaseVoucherList}
        size="large"
      />
    </>
  );
}
