import { useEffect, useRef } from "react";

export default function usePrevProps<T>(props: T) {
  const prevPropRef = useRef<T>(props);
  const propRef = useRef(props);

  useEffect(() => {
    if (prevPropRef.current === null) {
      prevPropRef.current = props;
    } else {
      prevPropRef.current = propRef.current;
    }
    propRef.current = props;
  }, [props]);

  return prevPropRef.current;
}
