import React from "react";
import { Space, Button, Skeleton, Tag } from "antd";
import { LoginRole } from "~/api/auth";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import PaymentPending from "~/component/PaymentPending";
import { registerEvent } from "~/analytics";
import * as ga from "~/contants/gaConstants";
// import PaymentPending from "~/component/PaymentPending";
// import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";

interface Props {
  isNew: boolean;
  paidFlag: number;
  invoiceId?: number;
  activeRole: LoginRole;
  isEditing: boolean;
  rippleEffect: boolean;
  paymentStatus?: string;
  handlePaymentDialog: (e: React.MouseEvent) => void;
  apiState: string;
  slug: string;
  onCancel: (e: React.MouseEvent) => void;
  onDelete: (e: React.MouseEvent) => void;
  onSave: (e: React.MouseEvent) => void;
  onEdit: (e: React.MouseEvent) => void;
  invoiceComplianceFlag: boolean;
  onSendEmail: (e: React.MouseEvent) => void;
  onDownloadInvoice: (e: React.MouseEvent) => void;
  complianceStatus: string;
  arryOfTag?: any;
}
const InvoiceDetailPageHeader = (props: Props) => {
  const showTag = props.arryOfTag?.map((e: any, id: any) => {
    return e.tagName.length > 0 ? (
      <Tag style={{ position: "relative", top: "-4px" }} color={e.color}>
        {e.tagName}
      </Tag>
    ) : (
      <></>
    );
  });
  return (
    <IMTPageHeader
      breadcumTexts={[
        "Invoice",

        props.isNew ? (
          // "New"
          <>New {showTag}</>
        ) : (
          <>
            Invoice details ({props.invoiceId}) {showTag}
          </>
        ),
      ]}
      actions={
        !props.isNew && props.apiState === "loading" ? (
          <Space align="center">
            <Skeleton.Button />
            <Skeleton.Button />
            <Skeleton.Button />
            <Skeleton.Button />
          </Space>
        ) : (
          <Space align="center">
            {props.activeRole === LoginRole.ADMIN ||
            (props.activeRole === LoginRole.SUPERuSER && !props.isNew) ? (
              props.isEditing ? null : (
                <PaymentPending
                  width={35}
                  height={35}
                  showRedDot={false}
                  disabled={props.isEditing}
                  onClick={(e: React.MouseEvent) => {
                    registerEvent(
                      ga.EVENT_CATEGORY_BUTTON_CLICK,
                      ga.EVENT_CLICK,
                      `InvoiceDetails>Open payment dialog`
                    );
                    props.handlePaymentDialog(e);
                  }}
                  status={props.paidFlag === 1 ? "DONE" : "PENDING"}
                  style={{ display: "flex" }}
                />
              )
            ) : null}
            {props.invoiceComplianceFlag === true ? null : props.activeRole ===
              3 ? null : (
              <Button
                type="primary"
                style={{
                  marginRight: "0.5em",
                }}
                disabled={props.paidFlag === 1}
                // loading={apiState === "loading" && (!editMode || isNew)}
                onClick={(e: any) => {
                  const buttonName =
                    props.isEditing || props.isNew ? "Save" : "Edit";
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    `InvoiceDetails>${buttonName}`
                  );
                  if (props.isEditing) {
                    props.onSave(e);
                  } else {
                    props.onEdit(e);
                  }
                }}
              >
                {props.isEditing || props.isNew ? "Save" : "Edit"}
              </Button>
            )}

            {props.activeRole === LoginRole.ADMIN ||
            props.activeRole === LoginRole.SUPERuSER ? (
              props.isNew ? null : props.isEditing ? null : (
                <Button
                  type="primary"
                  style={{
                    marginRight: "0.5em",
                  }}
                  onClick={(e) => {
                    registerEvent(
                      ga.EVENT_CATEGORY_BUTTON_CLICK,
                      ga.EVENT_CLICK,
                      `InvoiceDetails>Send invoice`
                    );
                    props.onSendEmail(e);
                  }}
                >
                  Send invoice
                </Button>
              )
            ) : null}
            {props.activeRole === 1 ||
            props.activeRole === 3 ||
            props.activeRole === LoginRole.SUPERuSER ? (
              props.isNew ? null : props.isEditing ? null : (
                <Button
                  type="primary"
                  style={{
                    marginRight: "0.5em",
                  }}
                  onClick={(e) => {
                    registerEvent(
                      ga.EVENT_CATEGORY_BUTTON_CLICK,
                      ga.EVENT_CLICK,
                      `InvoiceDetails>Download`
                    );
                    props.onDownloadInvoice(e);
                  }}
                >
                  Download
                </Button>
              )
            ) : null}
            {props.isEditing && props.activeRole !== LoginRole.CA ? (
              <Button
                type="dashed"
                onClick={(e) => {
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    `InvoiceDetails>Cancel`
                  );
                  props.onCancel(e);
                }}
              >
                Cancel
              </Button>
            ) : null}

            {props.isNew ? null : props.activeRole ===
              3 ? null : props.isEditing ? null : (
              <Button
                danger
                onClick={(e) => {
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    `InvoiceDetails>Delete`
                  );
                  props.onDelete(e);
                }}
              >
                Delete
              </Button>
            )}
          </Space>
        )
      }
    />
  );
};

export default InvoiceDetailPageHeader;
