import React from "react";
import { Row, Col, Space } from "antd";
import localstyles from "./LandingPageLayout.module.css";
import Currency from "~/component/Currency";
import cx from "classnames";
import Graphics8 from "~/assets/bg-graphics/graphics-8.png";

const plans = [
  {
    name: "Small business",
    description: "When your business has presense in single state.",
    mrp_amount: 254,
    discount_rate: 22,
    discounted_amount: 389,
    features: [
      { feature: "Single GSTIN can be managed", is_active: true },
      { feature: "Gst billing", is_active: true },
      {
        feature: "AI based physical receipt scanning",
        is_active: true,
      },
      {
        feature: "Employee management, reimbursements & payroll",
        is_active: true,
      },
      {
        feature: "GST, TDS, PF, ESI, PTAX & MCA compliance dashboard",
        is_active: true,
      },
      { feature: "Unlimited number of users", is_active: true },
      { feature: "Email support", is_active: true },
    ],
    starts_from: false,
  },
  {
    name: "Professional",
    description: "For tax professionals and businesses having multiple GSTINs",
    mrp_amount: 590,
    discount_rate: 0,
    discounted_amount: 779,
    features: [
      {
        feature: 'All "Small business" features for 10+ GSTINs',
        is_active: true,
      },
      { feature: "Import from Tally", is_active: true },
      { feature: "GST filing", is_active: true },
      {
        feature: "GSTR 3B reconciliation & conflict resolution",
        is_active: true,
      },
      { feature: "Sync back to Tally", is_active: true },
      {
        feature:
          "Import Flipkart / Amazon / Meesho sales data and automatically draft GST",
        is_active: true,
      },
      { feature: "Client dashboard", is_active: true },
      { feature: "Phone & email support", is_active: true },
    ],
    starts_from: true,
  },
  {
    name: "Enterprise",
    description: "Businnesses of big size and pre-existing ERPs in place.",
    mrp_amount: 0,
    discount_rate: 0,
    discounted_amount: 0,
    features: [
      { feature: "Integration with ERP", is_active: true },
      { feature: "Self hosting", is_active: true },
      { feature: "Complete white labelling", is_active: true },
      {
        feature: "Feature customization",
        is_active: true,
      },
    ],
    starts_from: false,
  },
];

function PricingBlock({ className, showSignUpModal }: any) {
  return (
    <Row className={cx(className, localstyles.blockWrapPricing)} id="pricing">
      <img className={localstyles.featGraphics8} src={Graphics8} alt="" />
      <Col flex={1} sm={24} md={24} lg={24}>
        <div className={localstyles.HeadLineFeaturesBlock}>
          Affordability. Cheqd.
        </div>
        <Space
          direction="horizontal"
          align="start"
          className={localstyles.justifyCenter}
          style={{ flexWrap: "wrap" }}
        >
          {plans.map((plan: any) => {
            return (
              <div key={plan.name} className={localstyles.pricingBlockItem}>
                <div className={localstyles.pricingBlockItemHeader}>
                  <div className={localstyles.pricingBlockItemHeaderPlanName}>
                    {plan.name}
                  </div>
                  <div
                    className={
                      localstyles.pricingBlockItemHeaderPlanDescription
                    }
                  >
                    {plan.description}
                  </div>
                </div>
                <div className={localstyles.pricingBlockItemBody}>
                  <div>
                    {plan.features.map((feat: any) => {
                      return (
                        <div
                          key={feat.feature}
                          className={localstyles.standartBodyText}
                          style={{ lineHeight: "23px" }}
                        >
                          <span>[+] </span>
                          {feat.feature}
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <div className={localstyles.pricingBlockItemAmountSubTexts}>
                      {plan.starts_from ? "Starts from" : "---"}
                    </div>
                    {plan.discounted_amount !== 0 ? (
                      <div className={localstyles.pricingBlockItemAmount}>
                        <Currency
                          value={plan.discounted_amount}
                          currencySymbol="₹"
                          roundoff
                        />
                        / month
                      </div>
                    ) : (
                      <div className={localstyles.pricingBlockItemAmount}>
                        (Contact us)
                      </div>
                    )}
                    <div className={localstyles.pricingBlockItemAmountSubTexts}>
                      {plan.discounted_amount !== 0
                        ? "(Excl. gst, Billed annually)"
                        : "---"}
                    </div>
                  </div>
                </div>
                <div
                  className={localstyles.pricingBlockItemFooter}
                  onClick={showSignUpModal}
                >
                  <span>Activate Free Trial</span>
                </div>
              </div>
            );
          })}
        </Space>
      </Col>
    </Row>
  );
}

/* export default connector(PricingBlock); */
export default PricingBlock;
