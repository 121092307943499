import React, { useState } from "react";
import LIFooter, { LIFooterAmountRow } from "~/fragment/line-items/LIFooter";

import { CurrencyInfo } from "~/fragment/line-items/LineItemTypes";
import { TaxTeritoryType, TAX_INCLUSION } from "~/lib/taxes";
import { getTotalGST } from "~/lib/taxCalculation";
import { CreditNoteLineItemRecord } from "./CreditNoteLineItemTypes";
import PaymentHistoryDialog from "~/feature/expense/modal/PaymentHistoryDialog";
// interface InvoiceLineItemFooter {
//   rowData: Array<InvoiceLineItemRecord>;
// }

// const InvoiceLineItemFooter = (props: InvoiceLineItemFooter) => {
// };
interface HistoryData {
  netTotal: string | number;
  tdsAmont: string | number;
  paymentDetails: any;
  invoiceId: number | null;
  conversionRate: number | undefined;
  onRefresh: () => void;
}
interface CreditNoteLineItemFooter {
  rowData: Array<CreditNoteLineItemRecord>;
  currency?: CurrencyInfo;
  taxInclusion?: TAX_INCLUSION;
  taxTeritoryType?: TaxTeritoryType;
  conversionRate?: number;
  paymentHistoryData?: HistoryData;
  hasCess?: boolean;
  isCopy: boolean;
  totalPayAmount?: number;
  tdsInfo?: {
    tdsRate?: number;
    tdsAmount?: number;
  };
}

const CreditNoteLineItemFooter = (props: CreditNoteLineItemFooter) => {
  const [footerInfo, setFooterInfo] = useState({
    paymentHistoryDlg: false,
  });
  const taxes = getTotalGST(
    props.rowData.map((row) => {
      return {
        amount: row.amount,
        gstPercentage: row.extra?.gstRate?.rate ?? 0,
        cessPercentage: row.extra?.cessRate?.cessPercentage ?? 0,
      };
    }),
    {
      taxInclusion: props.taxInclusion ?? "OutOfTax",
      taxTeritoryType: props.taxTeritoryType ?? TaxTeritoryType.OTHER_TERITORY,
    }
  );

  const hasNoTax = props.taxInclusion === "OutOfTax";
  const hasTDS = !!props.tdsInfo?.tdsAmount!;
  const isIGST =
    !hasNoTax && props.taxTeritoryType === TaxTeritoryType.INTRA_STATE;
  const isC_S_GST = !hasNoTax && !isIGST;
  const hasCess = props.hasCess;
  const conversionRate = props.currency?.conversionRate ?? 1;
  const symbol = props.currency?.symbol;
  let discountAmount = 0;
  props.rowData.forEach((row) => {
    discountAmount += (row.quantity ? row.quantity : 1) * row.rate - row.amount;
  });
  const handleHstryDlgVisibleChange = (visible: boolean) => {
    setFooterInfo({
      paymentHistoryDlg: visible,
    });
  };
  return (
    <>
      <LIFooter>
        <LIFooterAmountRow
          label="Subtotal"
          value={taxes.subTotal}
          conversionRate={conversionRate}
          currencySymbol={symbol}
        />
        {isIGST && (
          <LIFooterAmountRow
            label="IGST"
            value={taxes.igst}
            conversionRate={conversionRate}
            currencySymbol={symbol}
          />
        )}
        {isC_S_GST && (
          <LIFooterAmountRow
            label="CGST"
            value={taxes.cgst}
            conversionRate={conversionRate}
            currencySymbol={symbol}
          />
        )}
        {isC_S_GST && (
          <LIFooterAmountRow
            label="SGST"
            value={taxes.sgst}
            conversionRate={conversionRate}
            currencySymbol={symbol}
          />
        )}
        {hasCess && (
          <LIFooterAmountRow
            label="CESS"
            value={taxes.cess}
            conversionRate={conversionRate}
            currencySymbol={symbol}
          />
        )}
        <LIFooterAmountRow
          label="Total discount"
          value={discountAmount}
          conversionRate={conversionRate}
          currencySymbol={symbol}
        />

        <LIFooterAmountRow
          label="Net total"
          value={taxes.total}
          conversionRate={conversionRate}
          currencySymbol={symbol}
        />
        {props.totalPayAmount! > 0 && !props.isCopy ? (
          <LIFooterAmountRow
            label="Paid"
            value={+props.totalPayAmount! / conversionRate}
            conversionRate={conversionRate}
            currencySymbol={symbol}
            color="#4C985A"
          />
        ) : null}
        {hasTDS && !props.isCopy ? (
          <LIFooterAmountRow
            label={`TDS Withheld (${props.tdsInfo?.tdsRate} %)`}
            value={+props.tdsInfo?.tdsAmount!}
            conversionRate={conversionRate}
            currencySymbol={symbol}
            color="#828282"
          />
        ) : null}
      </LIFooter>
      <PaymentHistoryDialog
        paymentDetails={props.paymentHistoryData?.paymentDetails!}
        net_amount={props.paymentHistoryData?.netTotal!}
        tds_amount={props.paymentHistoryData?.tdsAmont! || 0}
        visible={footerInfo.paymentHistoryDlg}
        onVisibleChange={handleHstryDlgVisibleChange}
        onRefresh={props.paymentHistoryData?.onRefresh!}
        conversionRate1={props.paymentHistoryData?.conversionRate}
        expenseId={props.paymentHistoryData?.invoiceId}
        totalPayAmount={props.totalPayAmount}
      />
    </>
  );
};
export default CreditNoteLineItemFooter;
