import React, { PureComponent } from "react";
import { Table, Button, notification } from "antd";
import { ColumnType } from "antd/lib/table/interface";
import Currency from "~/component/Currency";
import { fetchBlob, fetchUtil } from "~/api/common";
import styles from "./VendorTransactions.module.css";
import { format, addYears, getYear, subYears } from "date-fns";
import { registerEvent } from "~/analytics";
import SummaryTags from "~/component/SummaryTags";
import FySelect from "~/component/FySelect";

import * as ga from "~/contants/gaConstants";

interface Props {
  customerid: number;
  customerName?: string;
  vendorPageFy: string;
}

export interface VendorTransacionTableCol {
  id: number;
  date: Date;
  type: any;
  amount: any;
  payment_date: Date;
  tds_withheld: any;
}
export interface VendorTransacionTotal {
  total_amount: number;
  total_tds: number;
  payable: number;
  paid: number;
  receivable: number;
  received: number;
  balance: number;
  total_transactions: number;
  tds_on_expense: number;
  tds_on_invoice: number;
}

interface State {
  exportloading: boolean;
  selectedFy: string | null;
  noData: boolean;
  transactionData: Array<VendorTransacionTableCol> | [];
  transactionsTotal: VendorTransacionTotal | [];
  apiStateLoading: boolean;
}

export default class VendorTransactions extends PureComponent<Props, State> {
  state = {
    exportloading: false,
    selectedFy: "",
    noData: true,
    transactionData: [],
    transactionsTotal: {
      total_amount: 0,
      total_tds: 0,
      payable: 0,
      paid: 0,
      receivable: 0,
      received: 0,
      balance: 0,
      total_transactions: 0,
      tds_on_expense: 0,
      tds_on_invoice: 0,
    },
    apiStateLoading: true,
  };

  async componentDidMount() {
    const current = new Date();
    const year = new Date().getFullYear();
    const eligible =
      current.getTime() <= new Date(`${year}-04-01`).getTime() &&
      current.getTime() > new Date(`${year - 1}-04-01`).getTime();
    this.setState(
      {
        // selectedFy: eligible
        //   ? `${getYear(subYears(current, 1))}-${getYear(current)}`
        //   : `${getYear(current)}-${getYear(addYears(current, 1))}`,
        selectedFy: this.props.vendorPageFy,
      },
      async () => {
        await this.getVendorTransactions(
          this.props.customerid,
          this.state.selectedFy
        );
      }
    );
  }

  handleFyChange = async (value: any) => {
    await this.getVendorTransactions(this.props.customerid, value);
    this.setState({
      selectedFy: value,
    });
  };

  getVendorTransactions = async (customerId: number, selectedFy: string) => {
    this.setState({ apiStateLoading: true });
    const { ok, json, total, message } = await fetchUtil(
      "POST",
      `/get_customer_transactions`,
      {
        customer_id: customerId,
        start_year: selectedFy.slice(0, 4),
        end_year: selectedFy.slice(5),
      }
    );
    this.setState({ apiStateLoading: false });
    if (ok) {
      if (json.length > 0) {
        this.setState({
          noData: false,
          transactionData: json,
          transactionsTotal: total,
        });
      } else {
        this.setState({
          noData: true,
          transactionData: [],
          transactionsTotal: [],
        });
      }
    } else {
      notification.error({ message });
    }
  };

  exportTransactions = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.vendorTransactions259
    );
    try {
      this.setState({
        exportloading: true,
      });
      await fetchBlob("/get_customer_transaction_csv", {
        customer_id: this.props.customerid,
        start_year: this.state.selectedFy.slice(0, 4),
        end_year: this.state.selectedFy.slice(5),
      }).then((res) => {
        this.setState({
          exportloading: false,
        });
        if (res.blob) {
          res.blob.then((val) => {
            const blob = new Blob([val], { type: "text/csv" });
            saveAs(blob, "vendor-transactions.csv");
          });
        } else {
          this.setState({
            exportloading: false,
          });
          notification.info({
            message: res.message,
          });
        }
      });
    } catch (err) {
      notification.error({
        message: "Error",
      });
    }
  };

  render() {
    const columnsDef: Array<ColumnType<VendorTransacionTableCol>> = [
      {
        title: "Id",
        dataIndex: "id",
        align: "left",
        render: (id, record) => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              record.type === "DEBIT"
                ? `/app/expense/${id}`
                : `/app/invoice/${id}`
            }
          >
            {id}
          </a>
        ),
      },
      {
        title: "Date ",
        dataIndex: "date",
        align: "center",
        render: (date) => (date ? format(new Date(date), "dd/MM/yyyy") : "--"),
      },
      {
        title: "Type",
        dataIndex: "type",
        align: "center",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        align: "right",
        render: (text: any, record: any) => ({
          children: <Currency>{Number(record.amount)}</Currency>,
        }),
      },
      {
        title: "Payment date",
        dataIndex: "payment_date",
        align: "center",
        render: (payment_date) =>
          payment_date ? format(new Date(payment_date), "dd/MM/yyyy") : "--",
      },
      {
        title: "TDS withheld",
        dataIndex: "tds_withheld",
        align: "right",
        render: (text: any, record: any) => ({
          children: <Currency>{Number(record.tds_withheld)}</Currency>,
        }),
      },
    ];
    return (
      <div>
        <div className={styles.headerVd}>
          <h3 className={styles.headerVt}>
            Ledger of {this.props.customerName}
          </h3>

          <div style={{ display: "flex" }}>
            <FySelect
              style={{ marginRight: 20 }}
              selectedFy={this.state.selectedFy}
              onFyChange={this.handleFyChange}
              isVenTn={true}
            />
            <Button
              type="primary"
              loading={this.state.exportloading}
              onClick={this.exportTransactions}
              disabled={this.state.noData}
            >
              Export Csv
            </Button>
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <SummaryTags
            nameValuePairs={[
              ["total_transactions", "Count", false],
              ["paid", "Paid", true],
              ["payable", "Payable", true],
              ["received", "Received", true],
              ["receivable", "Receivable", true],
              ["tds_on_expense", "tds o/ expense", true],
              ["tds_on_invoice", "tds o/ invoice", true],
            ].map((v: Array<any>) => {
              const summVal = this.state.transactionsTotal[
                v[0] as keyof VendorTransacionTotal
              ];
              return {
                name: v[1],
                value: summVal,
                isCurrency: v[2],
              };
            })}
          />
        </div>
        <div className="beforetable-com" style={{ maxWidth: "100%" }}>
          <Table
            style={{
              width: "100%",
              marginBottom: "20px",
              height: "100%",
            }}
            pagination={{
              position: ["topRight"],
              showSizeChanger: false,
              pageSize: 10,
              size: "small",
            }}
            columns={columnsDef}
            dataSource={this.state.transactionData}
            loading={this.state.apiStateLoading}
          />
        </div>
      </div>
    );
  }
}
