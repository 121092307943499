import React from "react";
import { CESSRateItem } from "~/api/expense";
import { useCESSRateList } from "~/lib/hook/api-hook/picklistHooks";
import usePersistantCallbackRef from "~/lib/hook/usePersistantCallbackRef";
import ReadOnlyableSelect from "./ReadOnlyableSelect";

interface Props {
  readOnly?: boolean;
  value?: number;
  onChange?: (cessRateId: number, record?: CESSRateItem) => void;
}

const CESSRateSelect = (props: Props) => {
  const { isCESSListLoading, cessRateList } = useCESSRateList();
  const handleOnChange = usePersistantCallbackRef((cessRateId: number) => {
    const cessRate = cessRateList.find((it) => it.id === cessRateId);
    props.onChange?.(cessRateId, cessRate);
  });
  return (
    <ReadOnlyableSelect
      loading={isCESSListLoading}
      readonly={props.readOnly}
      value={props.value}
      onChange={handleOnChange}
      style={{ width: "100%" }}
      options={cessRateList.map((it) => ({
        value: it.id,
        label: it.rate,
      }))}
    />
  );
};

export default CESSRateSelect;
