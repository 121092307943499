import React, { Component } from "react";
import { RouteChildrenProps } from "react-router";
import { FileExcelOutlined } from "@ant-design/icons";

import NonIdealState from "./NonIdealState";

import commonStyles from "~/component/common.module.css";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";

interface Props extends RouteChildrenProps {
  doNotUseMe?: never;
}

export default class NotFoundPage extends Component<Props, {}> {
  state = {};

  render() {
    return (
      <>
        <Helmet>
          <title>{titles.NotFoundPage}</title>
        </Helmet>
        <NonIdealState
          className={commonStyles["min-h-full"]}
          icon={<FileExcelOutlined />}
          title="Not found"
          description="The page doesn't exists"
        />
      </>
    );
  }
}
