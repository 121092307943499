import React, { Component } from "react";
import {
  Drawer,
  Typography,
  notification,
  Button,
  Input,
  Form,
  Row,
  Col,
  Select,
  AutoComplete,
  Spin,
  Divider,
  Modal,
} from "antd";
import { FormInstance } from "antd/lib/form";
import { isFuture } from "date-fns";
import DatePicker from "~/component/antd-overrides/DatePicker";
import commonStyle from "~/component/common.module.css";
import FieldInfo from "~/component/FieldInfo";
import { PAN_VALIDATION_REGEX } from "~/lib/constants";
import { fetchUtil, getBankList } from "~/api/common";
import { FORMAT_DATE } from "~/lib";
import {
  ExclamationCircleOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import { withRouter, RouteComponentProps } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { Required } from "~/lib/form_rules";
import { registerEvent } from "~/analytics";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";

import * as ga from "~/contants/gaConstants";

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  setCompanyCount: (a: any) => dispatch(action.auth.setCompanyCount(a)),
  handleUpdateCompanyList: (val: boolean) => {
    dispatch(action.auth.handleUpdateCompanyList(val));
  },
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends RouteComponentProps, PropsFromRedux {
  isOpen: boolean;
  onClose: (visible: boolean) => void;
  updateCompanyList?: () => void;
}

type FormData = {
  legal_name?: string;
  name: string;
  establishment_type: number;
  cin?: string;
  industry?: number;
  address?: string;
  company_name?: string;
  company_city?: string;
  company_state?: string | number;
  company_state_name?: string;
  company_country?: string;
  company_pincode?: string;
  company_website?: string;
  currency?: string | number;
  gst_registration_type?: string | number;
  gstin?: string;
  gst_registration_state?: string | any;
  place_of_supply?: number;
  pan?: string;
  tan?: string;
  tan_verified?: boolean;
  ptax_registration_no?: string;
  pf_registration_no?: string;
  esi_registration_no?: string;
  udyog_aadhaar_no?: string;
  gstin_registration_date: Date;
  bank_type_id: any;
  account_number: any;
  ifsc_code: any;
  swift_code: any;
};

type Designations = {
  id: number;
  role_name: string;
};

interface State {
  loadSave: boolean;
  formData: any;
  establishmentTypes: Array<any>;
  industryList: Array<{ id: number; name: string }>;
  cinRequired: boolean;
  cityList: Array<{ id: number; city: string; state: string; country: string }>;
  loadDetail: boolean;
  gstinTypeList: Array<{
    id: number;
    gst_type: string;
  }>;
  disable?: boolean;
  stateList: Array<{
    id: number;
    state_name: string;
  }>;
  isLoading: boolean;
  bankList: any;
  gstinNo: any;
  loadingGst: boolean;
  errorMessage: string;
  cinData?: any;
  isDirty: boolean;
}

interface GSTData {
  natureOfBusinesses: Array<string>;
  regDate: string;
  regType: any;
  active: boolean;
  activeStr: string;
  addressLine: string;
  city: any;
  state: any;
  country: any;
  pinCode: string;
  entityType: any;
  tradeName: string;
  legalName: string;
  industryType: number;
  name: string;
  gstin: string;
  pan: string;
  cin: string;
}

class AddClientDrawer extends Component<Props, State> {
  state: State = {
    loadSave: false,
    formData: {},
    establishmentTypes: [],
    cinRequired: true,
    industryList: [],
    cityList: [],
    loadDetail: false,
    gstinTypeList: [],
    disable: false,
    stateList: [],
    isLoading: false,
    bankList: [],
    gstinNo: "",
    loadingGst: false,
    errorMessage: "",
    cinData: {},
    isDirty: false,
  };
  _formRef = React.createRef<FormInstance<Partial<FormData>>>();

  componentDidMount() {
    this.getEstablishment();
    this.getIndustryList();
    this.getGstinType();
    this.getStateList();
    this.loadBankList();
    // this.getExecutiveDetail().then(() => this.getDesignation());
  }

  loadBankList = async () => {
    const response = await getBankList();
    if (!response.ok) {
      notification.error({
        message: "Failed to load Bank List",
        description: response.message,
      });
    } else {
      this.setState({
        bankList: response.data!,
      });
    }
  };

  async getGstinType() {
    const { ok, json, message } = await fetchUtil(
      "GET",
      `/get_gst_registration_types`
    );

    if (ok) {
      const data = json;
      this.setState({
        gstinTypeList: data,
      });
    } else {
      notification.error({ message });
    }
  }

  async getStateList() {
    const { ok, json, message } = await fetchUtil("GET", `/get_states`);
    this.setState({
      isLoading: true,
    });
    if (ok) {
      const data = json;
      this.setState({
        stateList: data,
        isLoading: false,
      });
    } else {
      notification.error({ message });
    }
  }

  async getEstablishment() {
    const { ok, message, json } = await fetchUtil(
      "GET",
      "/get_establishment_types"
    );
    if (!ok) {
      notification.error({ message });
    } else {
      this.setState({
        establishmentTypes: json,
      });
    }
  }

  async getIndustryList() {
    const { ok, message, json } = await fetchUtil("GET", "/get_industry");
    if (!ok) {
      notification.error({ message });
    } else {
      this.setState({
        industryList: json,
      });
    }
  }

  loadPickupListsForCity = async (city: string) => {
    if (city) {
      this.setState({
        loadDetail: true,
      });
      const { ok, message, json } = await fetchUtil(
        "GET",
        "/get_city_name?city=" + city
      );
      if (ok) {
        this.setState({ cityList: json, loadDetail: false });
      } else {
        notification.error({ message });
      }
    }
  };

  handleGSTDataReceived = (gstData: GSTData) => {
    if (this._formRef.current) {
      this._formRef.current.setFieldsValue({
        pan: gstData.pan,
        company_pincode: gstData.pinCode,
        gst_registration_type: gstData.regType.id,
        gstin: gstData.gstin,
        gstin_registration_date: new Date(gstData.regDate),
        company_state_name: gstData.state.name,
        company_state: gstData.state.code,
        company_country: gstData.country.name,
        company_city: gstData.city.name,
        address: gstData.addressLine,
        company_name: gstData.tradeName,
        industry: gstData.industryType === 0 ? undefined : gstData.industryType,
        legal_name: gstData.legalName,
        cin: gstData.cin,
        establishment_type: gstData.entityType.id,
      });
    }
    this.setState(
      {
        formData: {
          pan: gstData.pan,
          company_pincode: gstData.pinCode,
          gst_registration_type: gstData.regType.id,
          gstin: gstData.gstin,
          gstin_registration_date: new Date(gstData.regDate),
          company_state_name: gstData.state.name,
          company_state: gstData.state.code,
          company_country: gstData.country.name,
          company_city: gstData.city.name,
          address: gstData.addressLine,
          industry:
            gstData.industryType === 0 ? undefined : gstData.industryType,
          legal_name: gstData.legalName,
          cin: gstData.cin,
          establishment_type: gstData.entityType.id,
        },
      },
      () => {
        if (this.state.formData.cin) {
          this.getDataFromCin(this.state.formData.cin);
        }
        this.setState({ cinRequired: !!this.state.formData.cin });
      }
    );
  };

  handleValuesChange = (changedValues: FormData, values: FormData) => {
    this.setState({
      isDirty: true,
    });
    if (changedValues.company_city) {
      if (changedValues.company_city.length >= 4) {
        this.setState({ cityList: [] });
        this.loadPickupListsForCity(changedValues.company_city);
      }
    }
    if (changedValues.establishment_type) {
      const cinRequired =
        [1, 2, 3, 4, 8].indexOf(changedValues.establishment_type) === -1;
      this.setState({ cinRequired });
    }

    if (changedValues?.cin?.length === 21) {
      this.getDataFromCin(changedValues.cin);
    }

    if (changedValues.gst_registration_type === 3) {
      this.setState({
        disable: true,
      });
      if (this._formRef.current) {
        this._formRef.current.setFieldsValue({
          gstin: "",
        });
      }
    } else if (changedValues.gst_registration_type) {
      if (changedValues.gst_registration_type !== 3) {
        this.setState({
          disable: false,
        });
      }
    }

    if (changedValues.pan) {
      if (this._formRef.current) {
        const panNumber = String(changedValues.pan).toUpperCase();

        this._formRef.current.setFieldsValue({
          pan: panNumber,
        });
      }
    }

    if (changedValues.gstin) {
      // console.log(changedValues.gstin);
      if (String(changedValues.gstin).length === 15) {
        const stateCode = String(changedValues.gstin).substring(0, 2);

        const panNumber = String(changedValues.gstin).substring(2, 12);

        //   this.getPtRegistartionNo(panNumber);

        if (this._formRef.current) {
          this._formRef.current.setFieldsValue({
            pan: panNumber,
            place_of_supply: Number(stateCode),
          });
        }
      } else {
        if (this._formRef.current) {
          this._formRef.current.setFieldsValue({
            pan: "",
            place_of_supply: 0,
          });
        }
      }
    }
  };

  handleCityData = (cityData: any) => {
    this.setState({
      formData: {
        ...this.state.formData,
        company_state: cityData.state_id,
        company_city: cityData.city,
        company_state_name: cityData.state,
        company_country: cityData.country,
      },
    });
  };

  getDataFromCin = async (cin: any) => {
    this.setState({
      loadDetail: true,
    });
    await fetchUtil("POST", "/get_company_details_from_cin", {
      cin,
    }).then(({ ok, message, json }) => {
      if (!ok) {
        this.setState({
          loadDetail: false,
        });
        notification.error({ message });
        return { ok, message };
      } else {
        if (json?.length > 0) {
          this.setState({
            cinData: json[0],
          });
        }
        this.setState({
          loadDetail: false,
        });
        // return { ok, message, data: json };
      }
    });
  };

  handleSave = async (values: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.addClientDrawer10
    );
    // const data = this.state.formData;
    // this.props.updateCompanyList!();
    this.setState({
      loadSave: true,
    });
    const { ok, message, json } = await fetchUtil("POST", "/add_client", {
      pan: values.pan,
      company_pincode: values.company_pincode,
      gst_registration_type: values.gst_registration_type,
      gstin: values.gstin,
      gstin_registration_date: new Date(values.gstin_registration_date),
      company_name: values.company_name,
      company_state_name: values.company_state_name,
      company_state: this.state.formData.company_state,
      company_country: this.state.formData.company_country,
      company_city: values.company_city,
      address: values.address,
      industry: values.industry,
      legal_name: values.legal_name,
      cin: values.cin,
      establishment_type: values.establishment_type,
      bank_type_id: values.bank_type_id,
      account_number: values.account_number,
      ifsc_code: values.ifsc_code,
      swift_code: values.swift_cod,
      cin_data: this.state.cinData,
    });
    if (ok) {
      notification.success({ message });
      this.props.handleUpdateCompanyList(true);
      this.props.updateCompanyList!();
      this.props.setCompanyCount(json[0]);
      // this.props.onClose(false)
      this.setState({
        loadSave: false,
      });
    } else {
      notification.error({ message });
      this.setState({
        loadSave: false,
      });
    }
  };

  handleGSTIN = (e: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.addClientDrawer11
    );

    const gstinNo = e.target.value?.toUpperCase();
    this.setState({ gstinNo, isDirty: true });
    this._formRef.current?.setFieldsValue({
      gstin: gstinNo,
    });
  };

  handleExitModal = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.addClientDrawer12
    );

    if (this.state.isDirty) {
      Modal.confirm({
        title:
          "You have unsaved details. Do you still want to leave this page?",
        icon: <ExclamationCircleOutlined />,

        style: {
          position: "absolute",
          top: "calc(50vh - 72px)",
          left: "calc(80vw - 208px)",
          height: "145px",
          width: "416px",
        },
        onOk: () => {
          this.props.onClose(false);
        },
      });
    } else {
      this.props.onClose(false);
    }
  };

  // this is used for fetch data from gstin
  fetchGstData = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.addClientDrawer13
    );
    this.setState({ loadingGst: true, errorMessage: "" });
    if (!this.state.gstinNo) {
      this.setState({ errorMessage: "Please enter a valid GSTIN" });
      this.setState({ loadingGst: false });
      return;
    } else {
      const { ok, json } = await fetchUtil("POST", "/get_details_from_gstin", {
        gstin: this.state.gstinNo,
      });
      if (ok) {
        if (json[0]?.active) {
          this.handleGSTDataReceived(json[0]);
        } else {
          this.setState({
            errorMessage: "Some error occured, Try again later ",
          });
        }
      } else {
        this.setState({ errorMessage: "Some error occured, Try again later " });
      }
    }

    this.setState({ loadingGst: false });
  };

  renderFormItems() {
    const { Option } = Select;
    return (
      <>
        <Helmet>
          <title>{titles.AddClientModal}</title>
        </Helmet>
        <div
          style={{
            marginBottom: "8px",
          }}
        >
          <Typography.Title level={3}>Add client</Typography.Title>
        </div>
        <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
          <Col
            sm={6}
            md={6}
            lg={6}
            xl={12}
            xxl={12}
            style={{
              alignSelf: "left",
            }}
          >
            <Form.Item
              name="GSTIN"
              label="GSTIN"
              rules={[
                {
                  required: true,
                  message: "Please enter valid GSTIN",
                  pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}[Z]{1}[A-Z0-9]{1}$/,
                },
              ]}
            >
              <input
                name="gstin"
                value={this.state.gstinNo}
                onChange={this.handleGSTIN}
                className="ant-input"
              />
            </Form.Item>
            <Button
              loading={this.state.loadingGst}
              type="primary"
              ghost
              onClick={this.fetchGstData}
            >
              Fetch details
            </Button>
          </Col>
        </Row>
        <Divider>Basic details</Divider>
        <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="establishment_type"
              label="Establishment type"
              rules={[
                {
                  required: true,
                  message: "Please select valid establishment type",
                },
              ]}
            >
              <Select showSearch optionFilterProp="children">
                {this.state.establishmentTypes.map((it: any) => (
                  // console.log(it)
                  <Option key={it.id} value={it.id}>
                    {it.establishment_type}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            {this.state.cinRequired ? (
              <Form.Item
                name="cin"
                label="CIN/LLPIN #"
                rules={[
                  {
                    //   required: this.state.cinRequired,
                    message: "Please enter valid cin",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
            ) : null}
          </Col>
        </Row>
        <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="company_name"
              label="Entity name"
              /* getValueFromEvent={this.getUppercaseVal} */
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Please select a company name !",
                },
              ]}
            >
              <input className="ant-input" />
            </Form.Item>
            <Form.Item name="legal_name" hidden />
            {this._formRef?.current?.getFieldValue("company_name") !==
            this._formRef?.current?.getFieldValue("legal_name") ? (
              <FieldInfo
                text={this._formRef?.current?.getFieldValue("legal_name")}
              />
            ) : null}
          </Col>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="industry"
              label="Industry"
              rules={[
                { required: true, message: "Please select valid industry" },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                //   filterOption={filterOption}
              >
                {this.state.industryList.map((it: any) => (
                  <Option key={it.id} value={it.id}>
                    {it.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Address"
              name="address"
              rules={[
                { required: true, message: "Please enter valid address" },
              ]}
            >
              <input className="ant-input" />
            </Form.Item>
          </Col>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="City"
              name="company_city"
              rules={[{ required: true, message: "Please select valid city" }]}
            >
              <AutoComplete
                // tslint:disable-next-line: jsx-no-lambda
                onSelect={(val: any) => {
                  const obj: any = this.state.cityList.find(
                    (l: {
                      id: number;
                      city: string;
                      state: string;
                      country: string;
                      // tslint:disable-next-line: triple-equals |  | Double equal is intentional, please don't correct it
                    }) => l.id === val
                  );
                  this.handleCityData(obj);
                  if (this._formRef.current) {
                    // console.log(obj);
                    this._formRef.current.setFieldsValue({
                      company_state: obj.state_id,
                      company_city: obj.city,
                      company_state_name: obj.state,
                      company_country: obj.country,
                    });
                  }
                }}
                options={this.state.cityList.map((v: any, idx: number) => ({
                  key: v.id,
                  value: `${v.id}`,
                  label: (
                    <div>
                      <span>{v.city}</span>{" "}
                      <span
                        style={{
                          fontSize: 10,
                          color: "var(--primary-color)",
                        }}
                      >
                        {v.state}, {v.country}
                      </span>
                    </div>
                  ),
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="State"
              name="company_state_name"
              rules={[{ required: true, message: "Please enter valid state" }]}
            >
              <input className="ant-input" readOnly />
            </Form.Item>
            <FieldInfo
              text={this._formRef?.current?.getFieldValue("company_country")}
            />
          </Col>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Pincode"
              name="company_pincode"
              rules={[
                { required: true, message: "Please enter valid pincode" },
              ]}
            >
              <input className="ant-input" />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row justify="end" gutter={16}>
        <Col>
          <Button type="primary" htmlType="submit" size="middle">
            Next
          </Button>
        </Col>
      </Row> */}
        <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="gst_registration_type"
              label="GST registration type"
              rules={Required("GST registration type")}
            >
              <Select showSearch optionFilterProp="children">
                {this.state.gstinTypeList.map(
                  (gstin: { id: number; gst_type: string }) => (
                    <Select.Option key={gstin.id} value={gstin.id}>
                      {gstin.gst_type}
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            {this.state.disable === false ? (
              <>
                <Form.Item
                  name="gstin"
                  label="GSTIN"
                  rules={[
                    {
                      required: true,
                      type: "string",
                      message: "Please enter valid GSTIN",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <FieldInfo
                  // tslint:disable-next-line: jsx-no-lambda
                  text={() => {
                    for (
                      let i: number = 0;
                      i < this.state.stateList.length;
                      i++
                    ) {
                      const l: { id: number; state_name: string } = this.state
                        .stateList[i];
                    }
                    return "";
                  }}
                />
              </>
            ) : null}
          </Col>
        </Row>
        <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="pan"
              label="PAN"
              rules={[
                {
                  required: true,
                  type: "string",
                  pattern: PAN_VALIDATION_REGEX,
                  message: "Please enter valid PAN",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            {this.state.disable === false ? (
              <Form.Item
                className={commonStyle["w-100"]}
                name="gstin_registration_date"
                label="Gst registration Date"
                rules={[
                  {
                    required: true,
                    message: "Please enter a date",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={isFuture}
                  format={FORMAT_DATE}
                  className={commonStyle["w-100"]}
                />
              </Form.Item>
            ) : null}
          </Col>
        </Row>
        <Divider>Bank details</Divider>
        <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="bank_type_id"
              label="Bank name"
              rules={[
                {
                  required: false,
                  message: "Please select Bank name",
                },
              ]}
            >
              <Select showSearch>
                {this.state.bankList?.map((bank: any, indx: any) => (
                  <Select.Option key={indx} value={bank.id}>
                    {bank.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            <>
              <Form.Item
                name="account_number"
                label="Account number"
                rules={[
                  {
                    type: "string",
                    pattern: /^[0-9 ]+$/,
                    // required: true,
                    message: "Please enter Account number",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          </Col>
        </Row>
        <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="ifsc_code"
              label="IFSC code"
              rules={[
                {
                  type: "string",
                  pattern: /^[A-Z]{4}[0][A-Z0-9]{6,7}$/,
                  // required: true,
                  message: "Please enter valid IFSC code",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="swift_code"
              label="SWIFT code"
              rules={[
                {
                  type: "string",
                  pattern: /^[A-Z]{4}[A-Z0-9]{4,7}$/,
                  message: "Please enter valid Swift code",
                  whitespace: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ height: "50px", width: "100%" }}>
          <Row justify="start" gutter={16}>
            <Col>
              <Button
                style={{ position: "absolute", top: "12px" }}
                type="primary"
                htmlType="submit"
                size="middle"
                loading={this.state.loadSave}
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  render() {
    return (
      <Drawer
        width={"40%"}
        placement="right"
        closable={true}
        closeIcon={
          <VerticalAlignTopOutlined
            style={{ fontSize: "var(--heading-3-size)" }}
            rotate={90}
          />
        }
        onClose={() => this.handleExitModal()}
        visible={this.props.isOpen}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Spin spinning={this.state.loadSave || this.state.loadDetail}>
          <Form
            ref={this._formRef}
            initialValues={this.state.formData}
            defaultValue={this.state.formData}
            onValuesChange={this.handleValuesChange}
            onFinish={this.handleSave}
          >
            {this.renderFormItems()}
          </Form>
        </Spin>
      </Drawer>
    );
  }
}
export default withRouter(connector(AddClientDrawer));
