import { Select } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import FieldWithAction from "~/component/FieldWithAction";
import { getExpenseCategories } from "~/api/expense";
import AddCategory from "~/component/AddCategory";
import ReadOnlyableSelect from "~/component/field-input/ReadOnlyableSelect";
import FieldInfo from "~/component/FieldInfo";

import commonStyles from "~/component/common.module.css";
import { useExpenseCategoryList } from "~/lib/hook/api-hook/picklistHooks";

interface Props {
  value?: string;
  onChange?: (value: string, record?: any) => void;
  readOnly?: boolean;
  required?: boolean;
  isBare?: boolean;
  error?: boolean;
}
export default function SelectCategory({
  value,
  onChange,
  readOnly,
  required,
  isBare,
  error,
}: Props): ReactElement {
  const {
    expenseCategoryList: categoryList,
    forceFetch,
  } = useExpenseCategoryList();
  const [isCategoryVisible, setIsCategoryVisible] = useState(false);

  const showCategoryModal = () => {
    setIsCategoryVisible(true);
  };

  const getCategoryTypeText = (id: string) => {
    const selectedCategory = categoryList.find((it: any) => it.value === +id)
      ?.category_type!;
    const modifyCategoryType = `Category type: ${
      selectedCategory?.charAt(0).toUpperCase() + selectedCategory?.slice(1)
    }`;
    return modifyCategoryType;
  };

  const handleCategoryrModal = async (
    visible: boolean,
    catagory_id?: string
  ) => {
    setIsCategoryVisible(visible);
    const data = await forceFetch(true);

    if (catagory_id)
      onChange?.(
        catagory_id,
        data?.find((it: any) => it.value === catagory_id)
      );
  };

  const handleCategoryFilterOption = (input?: string, option?: any): any => {
    return input && option.children.toLowerCase().includes(input.toLowerCase());
  };

  const selectComponent = (
    <ReadOnlyableSelect
      value={value}
      onChange={(categoryId) => {
        onChange?.(
          categoryId,
          categoryList.find((it: any) => it.value === categoryId)
        );
      }}
      readonly={readOnly}
      className={commonStyles["w-100"]}
      showArrow
      showSearch
      filterOption={handleCategoryFilterOption}
      id={"category_id"}
      style={
        isBare && required && error && !value
          ? {
              border: "0.5px solid red",
            }
          : undefined
      }
    >
      {categoryList.map((obj: any) => (
        <Select.Option key={obj.value} value={obj.value}>
          {obj.label}
        </Select.Option>
      ))}
    </ReadOnlyableSelect>
  );

  const fieldInfoComponent = (
    <FieldInfo
      style={
        !isBare
          ? undefined
          : {
              marginTop: "initial",
              position: "absolute",
              bottom: "-4px",
              left: "8px",
            }
      }
      text={value ? getCategoryTypeText(value) : null}
      subtle
    />
  );

  if (isBare) {
    return (
      <div>
        <div>{selectComponent}</div>
        {fieldInfoComponent}
      </div>
    );
  }

  return (
    <>
      <FieldWithAction
        label="Category"
        actionLabel="+ Add new"
        onAction={showCategoryModal}
        readonly={readOnly}
        required={required}
      >
        {selectComponent}
        <AddCategory
          visible={isCategoryVisible}
          onVisibleChange={handleCategoryrModal}
          selectedCatgoryDetails={null}
        />
      </FieldWithAction>
      {fieldInfoComponent}
    </>
  );
}
