import React, { Component } from "react";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import { notification } from "antd";
import { HourglassOutlined } from "@ant-design/icons";

import CurrentTabPane from "./CurrentTabPane";
import FySelect from "~/component/FySelect";
import commonStyle from "~/component/common.module.css";
import commonPaneStyle from "./common-tab-pane.module.css";
import getYear from "date-fns/fp/getYear";
import { subYears } from "date-fns/esm";
import { addYears } from "date-fns";
import { RouteComponentProps } from "react-router";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";
import IndicatorTag from "~/component/IndicatorTag";

type RouteParams = {
  fyYear?: string;
  activeList?: string;
};
interface Props extends RouteComponentProps<RouteParams> {
  doNotUseMe?: never;
}
interface State {
  selectedTab: "current" | "upcoming";
  selectedFy: any;
}
const year = new Date().getFullYear();
const current = new Date();
const eligbleDate =
  current.getTime() <= new Date(`${year}-04-01`).getTime() &&
  current.getTime() > new Date(`${year - 1}-04-01`).getTime();
export default class CompliancePage extends Component<Props, State> {
  state: State = {
    selectedTab: "current",
    selectedFy: eligbleDate
      ? `${getYear(subYears(new Date(), 1))}-${getYear(new Date())}`
      : `${getYear(new Date())}-${getYear(addYears(new Date(), 1))}`,
  };

  componentDidMount() {
    if (!this.props.match.params.fyYear) {
      this.props.history.replace(
        `/app/compliance-new/${this.state.selectedFy}`
      );
    } else {
      this.setState({
        selectedFy: this.props.match.params.fyYear,
      });
    }
  }

  handleWIP = () => {
    notification.success({
      icon: <HourglassOutlined className={commonStyle["wip-icon"]} />,
      message: "This Feature is coming soon. Stay tuned.",
    });
  };

  handleFyChange = (value: any) => {
    this.setState({
      selectedFy: value,
    });
    console.log(this.props.match.params.activeList)
    this.props.history.push(
      `/app/compliance-new/${value}/${this.props.match.params.activeList}`
    );
  };

  render() {
    return (
      <IMTContent withoutMargin={true}>
        <Helmet>
          <title>{titles.CompliancePage}</title>
        </Helmet>
        <div className={commonPaneStyle.tab}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              overflow: "auto",
            }}
          >
            <span>
              <IMTPageHeader breadcumTexts={["Compliance"]}           style ={{paddingLeft:"0"}}
/>
            </span>
            <span>
              <FySelect
                selectedFy={this.state.selectedFy}
                onFyChange={this.handleFyChange}
              />
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          {/* <IndicatorTag
            tagSet={[
              {
                tagName: "DN",
                color: "#287DF0",
              },
              {
                tagName: "CN",
                color: "#B2B2B2",
              },
              {
                tagName: "A",
                color: "#F3CA3E",
              },
              {
                tagName: "DN",
                color: "#287DF0",
              },
              {
                tagName: "CN",
                color: "#B2B2B2",
              },
              {
                tagName: "A",
                color: "#F3CA3E",
              },
              {
                tagName: "DN",
                color: "#287DF0",
              },
              {
                tagName: "CN",
                color: "green",
              },
              {
                tagName: "A",
                color: "#F3CA3E",
              },
              {
                tagName: "DN",
                color: "#287DF0",
              },
              {
                tagName: "CN",
                color: "#B2B2B2",
              },
              {
                tagName: "A",
                color: "#F3CA3E",
              },
              {
                tagName: "DN",
                color: "red",
              },
              {
                tagName: "CN",
                color: "#B2B2B2",
              },
              {
                tagName: "A",
                color: "#F3CA3E",
              },
            ]}
          /> */}
        </div>
        <div className={commonPaneStyle.tab}>
          <CurrentTabPane selectedFy={this.state.selectedFy} />
        </div>
      </IMTContent>
    );
  }
}
