import { fetchUtil } from "./common";
export const editUser = (id: number, role_id: number) => {
  return fetchUtil("POST", "/update_role/edit_role", {
    id,
    role_id,
  }).then(({ ok, json, message }) => {
    return { ok, message };
  });
};
export const deleteUser = (id: number) => {
  return fetchUtil("POST", "/update_role/delete_role", {
    id,
  }).then(({ ok, json, message }) => {
    return { ok, message };
  });
};
