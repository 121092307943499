import React from "react";
import cx from "classnames";
import { Row, Space } from "antd";
import styles from "./LandingPageLayout.module.css";
import IMTMediaQueries from "~/component/IMTMediaQueries";
import strings from "../../i18n/en";

import DS1 from "~/assets/data-safety-icons/ds-1.png";
import DS2 from "~/assets/data-safety-icons/ds-2.png";
import DS3 from "~/assets/data-safety-icons/ds-3.png";
import DS4 from "~/assets/data-safety-icons/ds-4.png";

const dsItems = [
  {
    title: "In-transit & at-rest encryption",
    icon: DS1,
  },
  {
    title: "Frequent data backup",
    icon: DS2,
  },
  {
    title: "Strict access control",
    icon: DS3,
  },
  {
    title: "Touchless deployment",
    icon: DS4,
  },
];
export default function Testimonials({ className, showSignupModal }: any) {
  return (
    <IMTMediaQueries>
      {(matches) => (
        <Row className={cx(className, styles.dataSafetyWrap)} id="testimonials">
          <Space direction="vertical" align="center">
            <div className={styles.dataSafetyHeaderText}>
              {strings.DATA_SAFETY}
            </div>
            <Space
              direction="horizontal"
              align="center"
              size={matches.xs || matches.justWidePhone ? 5 : 80}
              style={{
                marginTop: 20,
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {dsItems.map((ds: any, idx: number) => {
                return (
                  <Space
                    direction="vertical"
                    align="center"
                    key={`space-datasafety-${idx}`}
                  >
                    <img
                      className={styles.dataSafetyItemImg}
                      src={ds.icon}
                      alt={ds.title}
                    />
                    <div className={styles.dataSafetyItemText}>{ds.title}</div>
                  </Space>
                );
              })}
            </Space>
          </Space>
        </Row>
      )}
    </IMTMediaQueries>
  );
}
