type MapFunction<T, R> = (it: T, idx: number) => R;

export function iterMap<T, F extends MapFunction<T, ReturnType<F>>>(
  iter?: IterableIterator<T>,
  fn?: F
) {
  let idx = 0;
  const array: Array<ReturnType<F>> = [];

  if (!iter || !iter[Symbol.iterator]) {
    return array;
  }
  const it = iter[Symbol.iterator]();

  if (!it || !fn) {
    return array;
  }
  while (true) {
    const ret = it.next();
    if (ret.done) {
      return array;
    }
    array.push(fn(ret.value as T, idx++));
  }
}

export function iterToArray<T>(iter?: IterableIterator<T>) {
  const array: Array<T> = [];
  if (!iter || !iter[Symbol.iterator]) {
    return array;
  }
  const it = iter[Symbol.iterator]();
  while (true) {
    const ret = it.next();
    if (ret.done) {
      return array;
    }
    array.push(ret.value);
  }
}
