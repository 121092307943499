import React from "react";
import { checkIfArrayItemsAreAllObjects } from "../../helpers/helpers";
import JSONViewer from "../jsonviewer";
import { Button } from "antd";
import styles from "~/component/JsonDownloadPreview.module.css";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

type Props = {
  arrayToView: any;
  currentLayer: string;
  setDataOfALayer: any;
  previousLayers?: string;
};
function ArrayViewer({ arrayToView, currentLayer, setDataOfALayer }: Props) {
  const result = checkIfArrayItemsAreAllObjects;

  if (result(arrayToView)[0]) {
    // Render Keys
    return (
      <>
        <thead>
          <tr>
            <td>&nbsp;</td>
            {result(arrayToView)[1].map((oneKey) => {
              return (
                <td key={oneKey} style={{ textAlign: "center" }}>
                  {oneKey.trim()}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles.tbody1}>
          {arrayToView.map((item: any, index: number) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return (
              <tr key={currentLayer + "_" + index} className={styles.arrayTr}>
                <td>&nbsp;&nbsp;{index + 1}&nbsp;&nbsp;</td>
                {result(arrayToView)[1].map((oneKey: any, ind: number) => {
                  return result(arrayToView)[1].length - 1 === ind ? (
                    <td key={index + oneKey} className={styles.tdata1}>
                      <div style={{ display: "flex" }}>
                        <JSONViewer
                          JSONToView={item[oneKey]}
                          ind={ind}
                          currentLayer={[currentLayer, index + "", oneKey]
                            .filter((a) => a)
                            .join(",")}
                          setDataOfALayer={setDataOfALayer}
                          extraButton={
                            <>
                              &nbsp;
                              <div>
                                <Button
                                  className={`${styles.ArrayButton} ${styles.greenColor}`}
                                  // tslint:disable-next-line: jsx-no-lambda
                                  onClick={() => {
                                    setDataOfALayer(
                                      currentLayer,
                                      index,
                                      "plus"
                                    );
                                  }}
                                >
                                  <PlusOutlined />
                                </Button>
                              </div>
                              &nbsp;
                              <div>
                                <Button
                                  className={styles.ArrayButton}
                                  danger
                                  // tslint:disable-next-line: jsx-no-lambda
                                  onClick={() => {
                                    setDataOfALayer(
                                      currentLayer,
                                      index,
                                      "minus"
                                    );
                                  }}
                                >
                                  <DeleteOutlined />
                                </Button>
                              </div>
                            </>
                          }
                        />
                      </div>
                    </td>
                  ) : (
                    <td key={index + oneKey} className={styles.tdata1}>
                      <JSONViewer
                        ind={ind}
                        JSONToView={item[oneKey]}
                        currentLayer={[currentLayer, index + "", oneKey]
                          .filter((a) => a)
                          .join(",")}
                        setDataOfALayer={setDataOfALayer}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </>
    );
  } else {
    return arrayToView.map((row: any, index: any) => {
      return (
        <tbody key={index + "_key"} className={styles.tbody1}>
          <tr
            style={{ border: "1px solid black", display: "block" }}
            className={styles.trow1}
          >
            <td className={styles.trowD1}>{index}</td>
            <td style={{ textAlign: "left" }} className={styles.trowD1}>
              {
                <JSONViewer
                  JSONToView={row}
                  currentLayer={[currentLayer, index + ""]
                    .filter((a) => a)
                    .join(",")}
                  previousLayers={currentLayer}
                  setDataOfALayer={setDataOfALayer}
                />
              }
            </td>
          </tr>
        </tbody>
      );
    });
  }
}

export default ArrayViewer;
