import * as React from "react";

const OtherFeature1 = (props: any) => (
  <svg
    width={62}
    height={62}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M33.066 6.2v10.333H43.4L33.066 6.2Z" fill="#9B51E0" />
    <path
      d="M29.966 19.634V6.2H6.2v49.6h21.7V39.266H10.334v-3.1H28.96c.942-1.247 2.426-2.066 4.106-2.066H34.1v-1.034a5.171 5.171 0 0 1 5.166-5.166H43.4v-8.266H29.967Zm-6.2 32.032H10.335v-3.1h13.433v3.1Zm0-9.3v3.1H10.335v-3.1h13.433Zm-7.232-32.032a3.1 3.1 0 1 1-.002 6.2 3.1 3.1 0 0 1 .002-6.2Zm-6.2 10.332c0-1.134.928-2.066 2.066-2.066h.719a6.16 6.16 0 0 0 6.83 0h.718c1.134 0 2.066.932 2.066 2.066V24.8h-12.4v-4.134Zm16.532 12.4H10.334v-3.1h16.533v3.1Z"
      fill="#9B51E0"
    />
    <path
      d="M44.95 47.44v3.194h-3.1V47.44c-3.867-.186-7.627-1.078-10.85-2.668v8.96c0 1.136.93 2.067 2.066 2.067h20.667c1.136 0 2.067-.93 2.067-2.066v-8.961c-3.225 1.59-6.983 2.482-10.85 2.668Z"
      fill="#9B51E0"
    />
    <path
      d="M55.8 41.325v-2.059c0-1.135-.93-2.066-2.066-2.066H49.6v-4.134c0-1.135-.93-2.066-2.067-2.066h-8.267c-1.135 0-2.066.93-2.066 2.066V37.2h-4.134c-1.135 0-2.066.93-2.066 2.066v2.059c6.848 4.133 17.95 4.133 24.8 0ZM40.3 34.1h6.2v3.1h-6.2v-3.1Z"
      fill="#9B51E0"
    />
  </svg>
);

export default OtherFeature1;
