import React, { Component } from "react";
import { Modal, Spin, Button, message as antMessage, message } from "antd";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import { fetchUtil } from "~/api/common";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { connect, ConnectedProps } from "react-redux";
import { AuthState } from "~/app/MainApp/store";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styles from "./EmployeePage.module.css";
import texts from "~/contants/texts";
import { LoginRole } from "~/api/auth";
import { RouteChildrenProps } from "react-router";
import { registerEvent } from "~/analytics";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";

import * as ga from "~/contants/gaConstants";

const mapStateToProps = (
  state: AuthState,
  ownProps: { employeeID: number }
) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  empname:
    state.mainAppSlice.user.first_name +
    " " +
    state.mainAppSlice.user.last_name,
  employeeID: state.mainAppSlice.user.id,
  termsAndCondAgree: state.mainAppSlice.user.terms_conditions_agree,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends RouteChildrenProps, PropsFromRedux {
  employeeID: number;
}

interface State {
  editorState: any;
  content: any;
  loadData: boolean;
  isDraftSaving: boolean;
  draft: string;
  publish: boolean;
}

class TermsAndCondition extends Component<Props, State> {
  state: State = {
    editorState: EditorState.createEmpty(),
    content: "<p>Write something here .... </p>",
    loadData: false,
    isDraftSaving: false,
    draft: "",
    publish: true,
  };
  setDraftInterval: any;

  componentDidMount() {
    if (this.props.activeRole === LoginRole.EMPLOYEE) {
      this.props.history.push(
        `/app/employee/${this.props.employeeID}/${this.props.empname}`
      );
    }
    this.getContent();
  }

  componentDidUpdate(prevProps: Props, _prevState: {}) {
    if (prevProps.activeRole !== this.props.activeRole) {
      if (this.props.activeRole === LoginRole.CA) {
        this.props.history.push(`/app/employee/`);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.setDraftInterval);
  }

  async getContent() {
    this.setState({
      loadData: true,
    });
    const { content } = this.state;
    try {
      const { ok, json, message } = await fetchUtil(
        "GET",
        `/get_admin_terms_conditions`
      );
      if (ok) {
        if (json.length > 0) {
          this.setState(
            {
              content:
                json[0].draft_terms_conditions === null
                  ? json[0].last_published_terms_conditions === null
                    ? "<p>Write something here .... </p>"
                    : json[0].last_published_terms_conditions
                  : json[0].draft_terms_conditions,
            },
            () => {
              const blocksFromHtml = htmlToDraft(this.state.content);
              const { contentBlocks, entityMap } = blocksFromHtml;
              const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
              );
              const editorState = EditorState.createWithContent(contentState);
              this.setState({
                editorState,
                loadData: false,
              });
            }
          );
        } else {
          const blocksFromHtml = htmlToDraft(content);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          const editorState = EditorState.createWithContent(contentState);
          this.setState({
            editorState,
            loadData: false,
          });
        }
      } else {
        if (json?.length !== 0) {
          antMessage.error(message);
        }
        this.setState({
          loadData: false,
        });
      }
    } catch (error) {
      antMessage.error(message);
      this.setState({
        loadData: false,
      });
    }
  }

  onEditorStateChange = (editorState: any) => {
    this.setState({
      editorState,
      publish: false,
    });
  };

  saveDraft = () => {
    this.setState({ isDraftSaving: true });
    const { editorState } = this.state;
    fetchUtil("POST", `/sync_terms_and_conditions`, {
      draft_tc: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    }).then(() => {
      this.setState({ isDraftSaving: false, draft: texts.draftSaved });
    });
  };

  publishTerms = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.termsAndConditions68
    );
    const { confirm } = Modal;
    const { editorState } = this.state;
    if (editorState.getCurrentContent().getPlainText().length) {
      confirm({
        title: texts.warningTandC,
        icon: <ExclamationCircleOutlined />,
        content: texts.warningTandC2,
        okText: "Yes",
        onOk: async () => {
          const { ok, message } = await fetchUtil(
            "POST",
            `/save_company_terms_conditions`,
            {
              terms_conditions: draftToHtml(
                convertToRaw(editorState.getCurrentContent())
              ),
            }
          );
          if (ok) {
            antMessage.success(message);
          } else {
            antMessage.error(message);
          }
        },
        onCancel: () => {
          // unuseds
        },
      });
    } else {
      message.error(texts.errorTandC);
    }
  };

  restoreTerms = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.termsAndConditions69
    );
    const { ok, message } = await fetchUtil(
      "POST",
      `/restore_terms_and_conditions`
    );
    if (ok) {
      antMessage.success(message ? message : texts.errorNoSaveData);
      this.getContent();
    } else {
      antMessage.error(message);
    }
  };

  verifyLoadTerms = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.termsAndConditions70
    );
    const { editorState } = this.state;
    if (editorState.getCurrentContent().getPlainText().length) {
      const { confirm } = Modal;
      confirm({
        title: texts.warningTandCLoad,
        icon: <ExclamationCircleOutlined />,
        okText: "Yes",
        onOk: () => {
          this.loadSampleTerm();
        },
        onCancel: () => {
          // unuseds
        },
      });
    } else {
      this.loadSampleTerm();
    }
  };

  loadSampleTerm = async () => {
    this.setState({
      loadData: true,
    });
    const { ok, json, message } = await fetchUtil(
      "GET",
      `/get_sample_terms_conditions`
    );
    if (ok) {
      if (json.length > 0) {
        this.setState(
          {
            content: json[0],
          },
          () => {
            const blocksFromHtml = htmlToDraft(this.state.content);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
              contentBlocks,
              entityMap
            );
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
              editorState,
              loadData: false,
              publish: false,
            });
          }
        );
      }
    } else {
      antMessage.error(message);
    }
  };

  render() {
    const { editorState, loadData, isDraftSaving, draft, publish } = this.state;
    const { activeRole } = this.props;
    return (
      <IMTContent fullwidth withoutMargin={true}>
        <Helmet>
          <title>{titles.TermsAndConditionsPage}</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={["Employee", `Terms And Conditions`]}
          actions={
            <Button
              className={styles.publish}
              type="primary"
              onClick={this.publishTerms}
              disabled={publish}
            >
              {texts.publish}
            </Button>
          }
        />
        <div className={styles.commonContent}>
          <Spin spinning={loadData}>
            <div className={styles.draft_and_restore}>
              {!isDraftSaving ? (
                <>
                  <Button
                    size={"small"}
                    className={`${styles.reset_button} ${styles.draft_restore_font}`}
                    type="link"
                    onClick={this.restoreTerms}
                  >
                    {texts.restore}
                  </Button>
                  <p className={styles.draft_restore_font}>
                    {" "}
                    {texts.previousVersion}
                  </p>
                </>
              ) : null}
            </div>
            <p className={styles.alert_message}>* {texts.warningTandC3}</p>
            <Button
              className={styles.sample_button}
              type="link"
              onClick={this.verifyLoadTerms}
            >
              {texts.loadASample}
            </Button>
            <div className={styles.editor}>
              <div className={styles.draft}>
                {isDraftSaving ? <LoadingOutlined /> : null}
                <p className={styles.draft_restore_font}>
                  {isDraftSaving ? texts.saving : draft}
                </p>
              </div>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName_admin"
                onEditorStateChange={this.onEditorStateChange}
                // tslint:disable-next-line: jsx-no-lambda
                onFocus={() => {
                  this.setDraftInterval = setInterval(() => {
                    this.saveDraft();
                  }, 10000);
                }}
                // tslint:disable-next-line: jsx-no-lambda
                onBlur={() => {
                  clearInterval(this.setDraftInterval);
                }}
                readOnly={
                  !(
                    activeRole === LoginRole.ADMIN ||
                    activeRole === LoginRole.SUPERuSER
                  )
                }
                toolbarHidden={
                  !(
                    activeRole === LoginRole.ADMIN ||
                    activeRole === LoginRole.SUPERuSER
                  )
                }
              />
            </div>
          </Spin>
        </div>
      </IMTContent>
    );
  }
}

export default connector(TermsAndCondition);
