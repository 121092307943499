import React, { Component } from "react";
import { Table, notification, Row, Tooltip, Input, Col } from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { RouteChildrenProps } from "react-router";
import { formatDate, parseNumber, roundNumber } from "~/lib";

import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import Currency from "~/component/Currency";
import { fetchUtil } from "~/api/common";

import styles from "~/feature/payment/PaymentPage.module.css";
import { subMonths, getMonth, addYears, getYear, subYears } from "date-fns";
import SummaryTags from "~/component/SummaryTags";
// import PayInvoiceModal from "./modal/PayInvoiceModal";
import YearMonthDropdown from "~/fragment/year-month-dropdown/YearMonthDropdown";
import { PaymentAPIData } from "~/api/payment";
import { months } from "~/lib/constants";

import * as ga from "~/contants/gaConstants";
import { debounce } from "lodash";
import { Helmet } from "react-helmet";
import { registerEvent } from "~/analytics";

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  sortOrderInfo: state.mainAppSlice.user.sort_order,
  sorterData: state.mainAppSlice.user.sorter,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  setSortOrder: (sortOrderInfo: any) =>
    dispatch(action.auth.setSortOrder(sortOrderInfo)),
  setSorter: (sorter: any) => dispatch(action.auth.setSorter(sorter)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends RouteChildrenProps, PropsFromRedux {}

interface PaymentSummary {
  total_count: number;
  debit_count: number;
  credit_count: number;
  debit_amount: string;
  credit_amount: string;
  reconcile: string;
}

interface State {
  rowData: Array<PaymentAPIData>;
  paymentSummary: PaymentSummary;
  pagination: {
    pageSize: number;
    pageNo: number;
    pageCount: number;
  };
  apiState: "idle" | "loading" | "error";
  apiSummaryState: "idle" | "loading" | "error";
  monthSelect: number;
  yearSelect: any;
  activePage: number;
  noOfRows: number;
  totalItemCount: number;
  sortedInfo: any;
  filteredInfo: any;
  sortOrder: string;
  search: string;
  highlightText: string;
}
class PaymentPage extends Component<Props, {}> {
  state: State = {
    rowData: [],
    paymentSummary: {
      total_count: 0,
      debit_count: 0,
      credit_amount: "0",
      credit_count: 0,
      debit_amount: "0",
      reconcile: "0",
    },
    pagination: {
      pageSize: 10,
      pageNo: 1,
      pageCount: 1,
    },
    apiState: "idle",
    apiSummaryState: "idle",
    yearSelect: "",
    monthSelect:
      new Date().getDate() < 25
        ? getMonth(subMonths(new Date(), 1))
        : getMonth(new Date()),
    activePage: 1,
    noOfRows: 12,
    totalItemCount: 0,
    sortedInfo: {},
    filteredInfo: {},
    sortOrder: "",
    search: "",
    highlightText: "",
  };

  static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State
  ): Partial<State> | null {
    const query = new URLSearchParams(nextProps.location.search);
    let currentPage = 1;
    const today = new Date();
    const isBefore25th = today.getDate() < 25;
    const selectedDate = isBefore25th ? subMonths(today, 1) : today;
    const current = new Date();
    const yearCurrent = new Date().getFullYear();
    const eligible =
      current.getTime() <= new Date(`${yearCurrent}-04-25`).getTime() &&
      current.getTime() > new Date(`${yearCurrent - 1}-04-25`).getTime();
    let year: any = eligible
      ? `${getYear(subYears(current, 1))}-${getYear(current)}`
      : `${getYear(current)}-${getYear(addYears(current, 1))}`;

    let month = getMonth(selectedDate);

    if (query.has("year")) {
      const _yearStr = query.get("year") + "";
      if (/\d{4}/.test(_yearStr)) {
        year = _yearStr;
      }
    }
    if (query.has("month")) {
      const _monthNum = +months.indexOf(query.get("month") + "");

      // console.log(_monthNum, "--------------------");
      if (Number.isFinite(_monthNum)) {
        month = _monthNum;
      }
    }
    if (query.has("page")) {
      const pageNo = +query.get("page")!;
      if (Number.isFinite(pageNo)) {
        currentPage = pageNo;
      }
    }

    let search = "";
    let highlightText = "";
    if (query.has("search_item") && query.get("search_item")) {
      const searchItem = query.get("search_item");
      if (searchItem) {
        search = searchItem;
        highlightText = searchItem;
      }
    }
    const newState: Partial<State> = {
      yearSelect: year,
      monthSelect: month,
      activePage: currentPage,
      sortOrder: query.get("sort_order") || "",
      search,
      highlightText,
    };
    if (
      prevState.yearSelect !== newState.yearSelect ||
      prevState.monthSelect !== newState.monthSelect ||
      prevState.activePage !== newState.activePage ||
      prevState.sortOrder !== newState.sortOrder ||
      prevState.search !== newState.search
    ) {
      return newState;
    }
    return null;
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    query.set("sort_order", "");
    this.props.history.push("?" + query);
    if (this.props.sorterData) {
      this.setState({
        sortedInfo: this.props.sorterData,
      });
    }
    this.getPaymentSummary();
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>) {
    if (
      _prevState.yearSelect !== this.state.yearSelect ||
      _prevState.monthSelect !== this.state.monthSelect
    ) {
      this.loadData();
      this.getPaymentSummary();
    }
  }

  loadData = async () => {
    this.setState({
      apiState: "loading",
    });
    const { ok, message, json } = await fetchUtil(
      "POST",
      "/get_all_payment_details",
      {
        page_no: this.state.activePage,
        no_of_rows: this.state.noOfRows,
        fy: this.state.yearSelect + "",
        month:
          this.state.monthSelect + 1 + "" === "13"
            ? "ALL"
            : this.state.monthSelect + 1 + "",
        time_zone_offset: 330,
        sort_param: this.props.sortOrderInfo || "DATE_DESC",
        search: this.state.search,
      }
    );
    if (!ok) {
      notification.error({ message });
      this.setState({
        apiState: "error",
      });
    } else {
      const data = json as Array<PaymentAPIData>;
      this.setState({
        rowData: data,
        apiState: "idle",
      });
      // this.getPaymentSummary();
    }
  };

  async getPaymentSummary() {
    this.setState({
      apiSummaryState: "loading",
    });
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_payment_summary`,
      {
        fy: this.state.yearSelect + "",
        month:
          this.state.monthSelect + 1 + "" === "13"
            ? "ALL"
            : this.state.monthSelect + 1 + "",
        search: this.state.search,
      }
    );

    if (ok) {
      this.setState({
        apiSummaryState: "idle",
        paymentSummary: json,
      });
    } else {
      notification.error({ message });
      this.setState({
        apiSummaryState: "error",
      });
    }
  }

  handlePageChange = (pageNumber: number) => {
    const query = new URLSearchParams(this.props.location.search);
    const prevPage = query.get("page");
    if (prevPage === pageNumber + "") return;
    query.set("page", pageNumber + "");
    this.props.history.push("?" + query);
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.loadData();
      }
    );
  };

  handleTableChange = (_pagination: any, filters: any, sorter: any) => {
    const query = new URLSearchParams(this.props.location.search);
    let sortOrder = "";

    if (sorter.columnKey === "id") {
      if (sorter.order === "descend") {
        sortOrder = "ID_DESC";
        this.props.setSortOrder(sortOrder);
      } else if (sorter.order === "ascend") {
        sortOrder = "ID_ASC";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "customerEle") {
      if (sorter.order === "descend") {
        sortOrder = "CUST_DESC";
        this.props.setSortOrder(sortOrder);
      } else if (sorter.order === "ascend") {
        sortOrder = "CUST_ASC";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "instrumentEle") {
      if (sorter.order === "descend") {
        sortOrder = "INSTRUMENT_DESC";
        this.props.setSortOrder(sortOrder);
      } else if (sorter.order === "ascend") {
        sortOrder = "INSTRUMENT_ASC";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "payment_mode") {
      if (sorter.order === "descend") {
        sortOrder = "MODE_DESC";
        this.props.setSortOrder(sortOrder);
      } else if (sorter.order === "ascend") {
        sortOrder = "MODE_ASC";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "pay_date") {
      if (sorter.order === "descend") {
        sortOrder = "DATE_DESC";
        this.props.setSortOrder(sortOrder);
      } else if (sorter.order === "ascend") {
        sortOrder = "DATE_ASC";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "amount") {
      if (sorter.order === "descend") {
        sortOrder = "AMT_DESC";
        this.props.setSortOrder(sortOrder);
      } else if (sorter.order === "ascend") {
        sortOrder = "AMT_ASC";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "nature") {
      if (sorter.order === "descend") {
        sortOrder = "NATURE_DESC";
        this.props.setSortOrder(sortOrder);
      } else if (sorter.order === "ascend") {
        sortOrder = "NATURE_ASC";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "referenceEle") {
      if (sorter.order === "descend") {
        sortOrder = "REFERENCE_DESC";
        this.props.setSortOrder(sortOrder);
      } else if (sorter.order === "ascend") {
        sortOrder = "REFERENCE_ASC";
        this.props.setSortOrder(sortOrder);
      }
    }
    if (!sorter.order) {
      sortOrder = "";
      this.props.setSortOrder(sortOrder);
    }

    const prev = query.get("sort_order");
    if (prev === sortOrder) return;

    query.set("sort_order", sortOrder);

    this.props.history.push("?" + query);
    this.props.setSorter(sorter);
    this.setState(
      {
        filteredInfo: filters,
        sortedInfo: sorter ? sorter : this.props.sorterData,
      },
      () => this.loadData()
    );
  };

  handleInstrumentClick = (payment: PaymentAPIData) => {
    if (!payment.instrument_id || payment.instrument_id === "N/A") return;

    if (payment.invoice_id || payment.expense_receipt_id) {
      const baseUrl = window.location.origin;
      if (payment.invoice_id) {
        window.open(`${baseUrl}/app/invoice/${payment.invoice_id}`, "_blank");
      } else {
        window.open(
          `${baseUrl}/app/expense/${payment.expense_receipt_id}`,
          "_blank"
        );
      }
    }
  };

  debouncedGetPaymentList = debounce(this.loadData, 500);
  debouncedGetPaymentSummary = debounce(this.getPaymentSummary, 500);

  handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchItem = e.target.value;
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", 1 + "");
    query.set("search_item", searchItem);
    this.props.history.push("?" + query);
    this.setState(
      { search: e.target.value, highlightText: e.target.value },
      () => {
        this.debouncedGetPaymentList();
        this.debouncedGetPaymentSummary();
      }
    );
  };

  highlightText(text: string): JSX.Element {
    const { highlightText } = this.state;
    if (text) {
      const parts = text.split(new RegExp(`(${highlightText})`, "gi"));
      return (
        <>
          {parts.map((part: any, index: any) =>
            part.toLowerCase() === highlightText.toLowerCase() ? (
              <span key={index} className={styles["highlighted-text"]}>
                {part}
              </span>
            ) : (
              <span key={index}>{part}</span>
            )
          )}
        </>
      );
    } else {
      return <></>;
    }
  }

  render() {
    const summaryData = this.state.paymentSummary;
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const reconcile =
      summaryData.reconcile === "0" ? "N/A" : summaryData.reconcile + "%";
    const columnDef: Array<ColumnType<PaymentAPIData>> = [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        align: "left",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "id" && sortedInfo.order,
        defaultSortOrder: "descend",
        width: "5%",
        render: (id) => {
          return (
            <span>
              <Tooltip title={id}>
                <p className={styles.tableDataEllipsis}>{id}</p>
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: "Reference no.",
        dataIndex: "referenceEle",
        key: "referenceEle",
        align: "left",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "referenceEle" && sortedInfo.order,
        width: "20%",
        ellipsis: true,
        render: (referenceEle, record) => {
          return (
            <span>
              <Tooltip title={record.reference_no}>
                <p className={styles.tableDataEllipsis}>{referenceEle}</p>
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: "Customer / vendor name",
        dataIndex: "customerEle",
        key: "customerEle",
        align: "left",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "customerEle" && sortedInfo.order,
        ellipsis: true,
        render: (customerEle, record) => {
          return (
            <span>
              <Tooltip title={record.customer}>
                <p className={styles.tableDataEllipsis}>{customerEle}</p>
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: "Nature ",
        dataIndex: "nature",
        key: "nature",
        sorter: true,
        align: "center",
        width: "12%",
        sortOrder: sortedInfo.columnKey === "nature" && sortedInfo.order,
        render: (nature, row) => {
          return <span style={{ textTransform: "capitalize" }}>{nature}</span>;
        },
      },
      {
        title: "Instrument id",
        dataIndex: "instrumentEle",
        key: "instrumentEle",
        sorter: true,
        align: "center",
        width: "12%",
        ellipsis: true,
        sortOrder: sortedInfo.columnKey === "instrumentEle" && sortedInfo.order,
        render: (instrumentEle, record) => {
          return (
            <span
              onClick={() => this.handleInstrumentClick(record)}
              className={
                record.instrument_id !== "N/A" ? styles.instrumentId : ""
              }
            >
              {instrumentEle}
            </span>
          );
        },
      },
      {
        title: "Date",
        dataIndex: "pay_date",
        key: "pay_date",
        sorter: true,
        align: "right",
        width: "9%",
        sortOrder: sortedInfo.columnKey === "pay_date" && sortedInfo.order,
        render: (date) => {
          return <span>{formatDate(new Date(date))}</span>;
        },
      },

      {
        title: "Mode",
        dataIndex: "payment_mode",
        key: "payment_mode",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "payment_mode" && sortedInfo.order,
        align: "right",
        width: "9%",
        render: (mode) => {
          return <span style={{ textTransform: "capitalize" }}> {mode}</span>;
        },
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        align: "center",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "amount" && sortedInfo.order,
        render: (amount) => {
          return (
            <span>
              <div>
                <span style={{ whiteSpace: "nowrap" }}>
                  <Currency currencySymbol={`₹`}>{amount}</Currency>
                </span>
              </div>
            </span>
          );
        },
      },
    ];

    return (
      <IMTContent withoutMargin={true}>
        <Helmet>
          <title>Cheqd - Payment</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={["Payment"]}
          style={{ paddingLeft: "0" }}
        />

        <Row>
          <Col>
            <Input
              placeholder="Search in customer name / reference no. / instrument id "
              onChange={this.handleInputChange}
              value={this.state.search}
              style={{
                minWidth: "300px",
                height: "32px",
                marginBottom: "5px",
                marginRight: "20px",
              }}
              allowClear
            />
          </Col>
          <Col style={{ marginLeft: "10px" }}>
            <YearMonthDropdown
              month={this.state.monthSelect}
              year={this.state.yearSelect}
              marginLeft={"0px"}
              payrollFlag={false}
            />
          </Col>
        </Row>
        <Row className={styles.summaryDiv}>
          {this.state.paymentSummary ? (
            <SummaryTags
              nameValuePairs={[
                { name: "Count", value: summaryData.total_count },
                {
                  name: "Debit Count",
                  value: summaryData.debit_count,
                },
                {
                  name: "Debit amount",
                  value: +summaryData.debit_amount,
                  isCurrency: true,
                },
                {
                  name: "Credit Count",
                  value: summaryData.credit_count,
                },
                {
                  name: "Credit amount",
                  value: +summaryData.credit_amount,
                  isCurrency: true,
                },
                { name: "Reconcile", value: reconcile },
              ]}
            />
          ) : null}
        </Row>

        <Table
          rowKey="id"
          className={styles.paymentTable}
          columns={columnDef}
          dataSource={this.state.rowData.map((payment) => ({
            ...payment,
            customerEle: this.highlightText(payment.customer),
            referenceEle: this.highlightText(payment.reference_no),
            instrumentEle: this.highlightText(payment.instrument_id),
            // Add other columns and highlight the text if necessary
          }))}
          loading={
            this.state.apiState === "loading" ||
            this.state.apiSummaryState === "loading"
          }
          onChange={this.handleTableChange}
          size="large"
          pagination={{
            size: "small",
            current: this.state.activePage,
            pageSize: this.state.noOfRows,
            showSizeChanger: false,
            onChange: this.handlePageChange,
            total: this.state.paymentSummary.total_count,
            position: ["topRight"],
            style: { margin: "0px" },
          }}
        />
      </IMTContent>
    );
  }
}

export default connector(PaymentPage);
