import React, { Component, createRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { editUser, deleteUser } from "~/api/user";

import {
  notification,
  Button,
  Table,
  Tooltip,
  Space,
  Form,
  Input,
  Select,
  Modal,
  Popconfirm,
} from "antd";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import styles from "./SettingsUsers.module.css";
import { ColumnType } from "antd/lib/table/interface";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import { LoginRole } from "~/api/auth";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import AddUserModal from "./Modal/AddUserModal";
import { fetchUtil } from "~/api/common";
import commonStyle from "~/component/common.module.css";
import { registerEvent } from "~/analytics";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";

import * as ga from "~/contants/gaConstants";

interface State {
  userList: Array<{
    email: string;
    emp_id: number;
    id: number;
    role_id: number;
    role_name: string;
  }>;
  rolesList: Array<{
    id: number;
    role_name: string;
  }>;

  addUserModalVisible: boolean;
  userEditModal: boolean;
  recordRowDetail: any;
  a: any;
  activePage: number;
  noOfRows: number;
}

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  userDetails: state.mainAppSlice.user,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  setUserRole: (role_ids: any) => dispatch(action.auth.setUserRole(role_ids)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  history: any;
  location: any;
}

class SettingsUsers extends Component<Props, State> {
  state: State = {
    userList: [],
    rolesList: [],
    addUserModalVisible: false,
    userEditModal: false,
    recordRowDetail: [],
    a: 1,
    activePage: 1,
    noOfRows: 12,
  };
  formRef = createRef<FormInstance>();

  columnDef: Array<ColumnType<any>> = [
    {
      title: "User Email",
      dataIndex: "email",
    },
    {
      title: "User Role",
      dataIndex: "role_name",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id, record) => {
        const isPrincipalAdminOrSuperUser =
          this.props.activeRole === LoginRole.ADMIN ||
          this.props.activeRole === LoginRole.SUPERuSER;

        const isAdmin = record.role_name === "Admin";
        const isSameUser = record.email === this.props.userDetails.email;

        const isEditDeleteDisabled = isAdmin || isSameUser;

        return isPrincipalAdminOrSuperUser ? (
          <Space align="center">
            {isPrincipalAdminOrSuperUser ? (
              <Tooltip placement="left" title={"Edit"}>
                {/* <EditOutlined /> */}
                <Button
                  type="ghost"
                  shape="circle-outline"
                  size="small"
                  style={{ marginBottom: "0.5em" }}
                  icon={<EditOutlined />}
                  disabled={isEditDeleteDisabled}
                  onClick={(e: any) => {
                    registerEvent(
                      ga.EVENT_CATEGORY_BUTTON_CLICK,
                      ga.EVENT_CLICK,
                      ga.events.settingsUsers229
                    );
                    this.handleEditUser(e);
                    this.setState({
                      recordRowDetail: record,
                    });
                  }}
                  data-id={id}
                />
              </Tooltip>
            ) : null}
            {isPrincipalAdminOrSuperUser ? (
              <Popconfirm
                title="Are you sure to delete this user?"
                onConfirm={() => {
                  this.deleteUserConfirm(record);
                }}
                okText="Yes"
                cancelText="No"
                disabled={isEditDeleteDisabled}
              >
                <Tooltip placement="right" title="Delete">
                  <Button
                    type="ghost"
                    shape="circle-outline"
                    size="small"
                    style={{ marginBottom: "0.5em" }}
                    icon={<DeleteOutlined />}
                    disabled={isEditDeleteDisabled}
                    data-id={id}
                  />
                </Tooltip>
              </Popconfirm>
            ) : null}
          </Space>
        ) : null;
      },
    },
  ];

  componentDidMount() {
    this.getUserList();
    this.getRoleList();
  }

  deleteUserConfirm = async (record: any) => {
    if (record.email === this.props.userDetails.email) {
      notification.error({
        message: "Cannot delete currently logged in user",
      });
    } else {
      try {
        const { ok, message } = await deleteUser(record.id);
        if (ok) {
          this.setState({
            userEditModal: false,
          });
          this.getUserList();
          // this.handleCancel();

          notification.success({
            message,
          });
        } else {
          notification.error({
            message,
          });
        }
      } catch (err) {
        notification.error({
          message: "Error",
        });
      }
    }
  };

  async getUserList() {
    const { ok, json, message } = await fetchUtil("GET", `/get_user_roles`);
    if (ok) {
      const data = json;

      this.setState({
        userList: data,
      });
    } else {
      notification.error({ message });
    }
  }

  async getRoleList() {
    const { ok, json, message } = await fetchUtil("GET", `/get_roles`);
    if (ok) {
      const data = json;
      this.setState({
        rolesList: data,
      });
    } else {
      notification.error({ message });
    }
  }

  handleEditUser = (e: any) => {
    this.setState({
      userEditModal: true,
    });
  };

  handleAddNewUser = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.settingsUsers230
    );
    this.setState({
      addUserModalVisible: true,
    });
  };

  handleAddUserVisibleChange = (requestedVisibility: boolean) => {
    this.setState({
      addUserModalVisible: requestedVisibility,
    });
    this.getUserList();
  };

  handleCancel = () => {
    this.setState({
      userEditModal: false,
    });
  };

  handleOk = async () => {
    // this.setState({
    //   userEditModal: true,
    // });

    try {
      const { ok, message } = await editUser(
        this.state.recordRowDetail.id,
        this.state.recordRowDetail.role_id
      );
      if (ok) {
        this.setState({
          userEditModal: false,
        });
        this.getUserList();
        this.handleCancel();

        notification.success({
          message,
        });
      } else {
        notification.error({
          message,
        });
      }
    } catch (err) {
      notification.error({
        message: "Error",
      });
    }
  };

  onValuesChange = (changedValues: any, values: any) => {
    // console.log(values);
    if (changedValues.role_id) {
      // this.setState(
      //   {
      //     ...this.state.recordRowDetail,
      //     role_id: changedValues.role_id,
      //   },
      //   () => {
      //     const vendor = this.state.rolesList.find(
      //       (v) => v.id === changedValues.role_id
      //     )?.role_name;
      //     this.setState({
      //       ...this.state.recordRowDetail,
      //       role_name: vendor,
      //     });
      //   }
      // );
      this.setState({
        recordRowDetail: {
          ...this.state.recordRowDetail,
          role_id: changedValues.role_id,
        },
      });
      // this.state.recordRowDetail.role_id = changedValues.role_id;
      // this.setState({
      //   a: 1,
      // });
    }
  };

  handlePageChange = (pageNumber: number) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", pageNumber + "");
    this.props.history.push("?" + query);

    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.getUserList();
      }
    );
  };

  render() {
    return (
      <IMTContent withoutMargin={true}>
        <Helmet>
          <title>{titles.SettingsUsers}</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={["Users"]}
          style ={{paddingLeft:"0"}}

          actions={
            <Button type="primary" onClick={this.handleAddNewUser}>
              + Add user
            </Button>
          }
        />

        <Table
          id="table-emplist"
          style={{ maxWidth: "80%", cursor: "pointer" }}
          columns={this.columnDef}
          dataSource={this.state.userList}
          size="large"
          pagination={{
            size: "small",
            current: this.state.activePage,
            pageSize: this.state.noOfRows,
            showSizeChanger: false,
            onChange: this.handlePageChange,
            total: this.state.userList.length,
            position: ["topRight"],
          }}
        />
        <AddUserModal
          visible={this.state.addUserModalVisible}
          onVisibleChange={this.handleAddUserVisibleChange}
          rolesList={this.state.rolesList}
          getUser={this.getUserList}
        />
        <Modal
          title="Edit User"
          visible={this.state.userEditModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className={styles.modalWidth}
          destroyOnClose
          maskClosable={false}
        >
          <Form
            ref={this.formRef}
            onValuesChange={this.onValuesChange}
            initialValues={this.state.recordRowDetail}
          >
            <Form.Item
              className={commonStyle["w-100"]}
              name="email"
              label="Email"
            >
              <Input
                type="email"
                placeholder={"e.g. youremail@domain.com"}
                readOnly
              />
            </Form.Item>

            <Form.Item
              className={commonStyle["w-100"]}
              name="role_id"
              label="Role"
              rules={[
                {
                  required: true,
                  message: "Please enter valid Role!",
                },
              ]}
            >
              <Select showSearch optionFilterProp="children">
                {this.state.rolesList.map((role) => (
                  <Select.Option
                    key={role.id}
                    value={role.id}
                    disabled={role.id === 1 || role.id === 2}
                  >
                    {role.role_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </IMTContent>
    );
  }
}

export default connector(SettingsUsers);
