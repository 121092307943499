export const OPTS_AMOUNTS = [
  { value: "ExclusiveOfTax", label: "Exclusive of tax" },
  { value: "InclusiveOfTax", label: "Inclusive of tax" },
  { value: "OutOfTax", label: "Out of tax" },
];

export const OPTS_INTERVAL = [
  { value: 0, label: "Select Interval" },
  { value: 30, label: "Monthly" },
  { value: 90, label: " Quarterly" },
  { value: 180, label: "Half yearly" },
  { value: 365, label: "Annually" },
];

export const CHEQD_LOGO_URL = "https://beta.cheqd.in/logo@2x.png";
