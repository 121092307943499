import React from "react";

function Illustration2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="108"
      fill="none"
      viewBox="0 0 150 108"
    >
      <g opacity="0.81">
        <path
          stroke="#333"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M130.831 142.348s-7.745-28.145 1.452-48.86c3.86-8.697 5.144-18.456 3.359-27.89a78.746 78.746 0 00-3.701-13.097"
        ></path>
        <path
          fill="#9B51E0"
          d="M137.681 39.427c0 3.448-5.84 13.454-5.84 13.454s-5.836-10.018-5.836-13.454c.047-1.622.683-3.161 1.772-4.29 1.09-1.13 2.547-1.762 4.064-1.762s2.975.632 4.064 1.761c1.09 1.13 1.725 2.669 1.773 4.291h.003zM147.33 58.52c-1.751 2.896-11.736 7.898-11.736 7.898s.182-11.791 1.933-14.688a6.106 6.106 0 011.576-1.813 5.663 5.663 0 012.111-1.016 5.463 5.463 0 012.313-.06c.767.147 1.5.455 2.154.908a6.041 6.041 0 011.654 1.731c.438.69.743 1.466.897 2.283.153.817.152 1.66-.003 2.476a6.513 6.513 0 01-.902 2.28h.003zM146.908 91.172c-2.878 1.56-13.857.482-13.857.482s5.72-10.082 8.599-11.633a5.49 5.49 0 014.401-.27c1.444.528 2.637 1.64 3.324 3.098a6.642 6.642 0 01.351 4.702c-.461 1.556-1.474 2.857-2.821 3.624l.003-.003zM123.337 67.085c2.312 2.403 13.097 4.895 13.097 4.895s-2.64-11.45-4.951-13.854a5.84 5.84 0 00-1.914-1.359 5.51 5.51 0 00-2.256-.464 5.515 5.515 0 00-2.249.503 5.844 5.844 0 00-1.893 1.391 6.304 6.304 0 00-1.245 2.065 6.625 6.625 0 00-.405 2.42c.016.827.186 1.642.499 2.398a6.256 6.256 0 001.326 2.008l-.009-.003zM117.224 95.976c2.879 1.553 13.857.481 13.857.481s-5.717-10.082-8.601-11.644a5.563 5.563 0 00-2.214-.708 5.48 5.48 0 00-2.297.267 5.743 5.743 0 00-2.02 1.199 6.188 6.188 0 00-1.428 1.944 6.657 6.657 0 00-.311 4.835 6.372 6.372 0 001.166 2.133 5.899 5.899 0 001.848 1.484v.009z"
          opacity="0.25"
        ></path>
        <path
          stroke="#333"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M18.313 108.988s-7.75-28.148 1.452-48.863c3.861-8.697 5.145-18.456 3.36-27.89a78.707 78.707 0 00-3.701-13.097"
        ></path>
        <path
          fill="#9B51E0"
          d="M25.172 6.052c0 3.448-5.84 13.454-5.84 13.454S13.494 9.503 13.494 6.052c.047-1.622.683-3.161 1.773-4.29C16.355.631 17.813 0 19.33 0s2.974.632 4.064 1.761c1.09 1.13 1.725 2.669 1.772 4.291h.006zM34.826 25.16c-1.75 2.896-11.735 7.898-11.735 7.898s.182-11.791 1.933-14.688a6.091 6.091 0 011.58-1.787 5.657 5.657 0 012.104-.995 5.466 5.466 0 012.299-.05 5.624 5.624 0 012.139.905c.65.45 1.21 1.034 1.646 1.717.437.684.743 1.454.9 2.265.156.811.16 1.647.011 2.46a6.493 6.493 0 01-.877 2.275zM34.402 57.81c-2.878 1.553-13.857.48-13.857.48s5.72-10.081 8.599-11.632a5.562 5.562 0 012.214-.707 5.48 5.48 0 012.296.267c.742.251 1.429.66 2.021 1.199.592.54 1.078 1.2 1.428 1.943a6.653 6.653 0 01.31 4.835 6.36 6.36 0 01-1.166 2.134 5.893 5.893 0 01-1.848 1.484l.003-.003zM28.882 82.44C26.29 84.49 15.3 85.419 15.3 85.419s4.046-10.966 6.637-13.022c1.245-.985 2.806-1.4 4.338-1.155 1.532.246 2.91 1.133 3.831 2.466a6.584 6.584 0 011.08 4.642c-.23 1.64-1.06 3.114-2.305 4.099v-.01zM10.831 33.725c2.312 2.403 13.097 4.895 13.097 4.895s-2.64-11.45-4.951-13.853c-1.116-1.13-2.602-1.743-4.137-1.709-1.535.035-2.996.715-4.066 1.893-1.07 1.178-1.663 2.76-1.65 4.403.012 1.643.628 3.214 1.716 4.374l-.009-.003zM4.718 62.616c2.879 1.554 13.857.482 13.857.482s-5.72-10.083-8.598-11.633a5.562 5.562 0 00-2.214-.707 5.479 5.479 0 00-2.297.266 5.743 5.743 0 00-2.02 1.2 6.205 6.205 0 00-1.428 1.943 6.654 6.654 0 00-.31 4.835 6.36 6.36 0 001.165 2.134 5.893 5.893 0 001.848 1.483l-.003-.003zM2.37 91.568c2.59 2.05 13.58 2.98 13.58 2.98s-4.046-10.966-6.636-13.022a5.681 5.681 0 00-2.062-1.04 5.47 5.47 0 00-2.277-.115 5.6 5.6 0 00-2.145.826c-.657.423-1.23.98-1.686 1.64a6.452 6.452 0 00-.971 2.206 6.675 6.675 0 00-.108 2.436c.113.811.376 1.591.771 2.294a6.12 6.12 0 001.533 1.805v-.01z"
          opacity="0.25"
        ></path>
        <path
          stroke="#333"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M47.174 117.752s-4.194-15.23.786-26.455c2.118-4.69 2.756-9.988 1.82-15.099a42.61 42.61 0 00-1.994-7.088"
        ></path>
        <path
          fill="#BB6BD9"
          d="M50.881 62.022c0 1.867-3.16 7.283-3.16 7.283s-3.16-5.416-3.16-7.283c0-.896.333-1.756.925-2.39a3.06 3.06 0 012.235-.99 3.06 3.06 0 012.235.99c.592.634.925 1.494.925 2.39zM56.106 72.367c-.948 1.568-6.352 4.264-6.352 4.264s.097-6.397 1.047-7.95c.226-.373.518-.694.86-.947a3.06 3.06 0 011.133-.522 2.96 2.96 0 011.233-.02c.408.081.798.247 1.146.488.349.242.65.554.885.92.236.366.401.777.488 1.212.087.434.093.882.018 1.32-.075.436-.23.853-.456 1.225l-.002.01zM55.878 90.046c-1.557.84-7.502.258-7.502.258s3.097-5.458 4.655-6.299a2.975 2.975 0 012.413-.192c.796.28 1.456.888 1.833 1.69.378.8.442 1.729.18 2.581a3.328 3.328 0 01-1.58 1.962zM52.891 103.393c-1.403 1.112-7.354 1.611-7.354 1.611s2.19-5.94 3.593-7.048a3.007 3.007 0 012.348-.627c.829.133 1.575.613 2.074 1.334.498.72.71 1.624.585 2.511a3.43 3.43 0 01-1.246 2.219zM43.117 77.005c1.253 1.301 7.087 2.65 7.087 2.65s-1.424-6.198-2.682-7.499a3.054 3.054 0 00-2.249-.955 3.067 3.067 0 00-2.221 1.025 3.512 3.512 0 00-.893 2.406c.012.897.357 1.752.958 2.377v-.004zM39.809 92.647c1.557.84 7.502.259 7.502.259s-3.09-5.459-4.658-6.3a2.969 2.969 0 00-2.413-.192c-.394.14-.76.36-1.074.65-.314.29-.572.643-.76 1.04a3.602 3.602 0 00-.18 2.582c.13.421.337.811.608 1.148.271.337.601.613.972.813h.003zM38.528 108.321c1.403 1.112 7.354 1.612 7.354 1.612s-2.19-5.94-3.593-7.049a3.086 3.086 0 00-1.116-.563 2.962 2.962 0 00-1.232-.063c-.41.066-.805.217-1.16.446-.357.228-.667.53-.914.887a3.499 3.499 0 00-.526 1.193c-.1.431-.12.879-.06 1.319.062.439.204.861.417 1.242.214.38.496.712.83.976z"
          opacity="0.5"
        ></path>
      </g>
    </svg>
  );
}

export default Illustration2;
