import React from "react";
import { TaxTeritoryType, TAX_INCLUSION } from "~/lib/taxes";
import { CurrencyInfo } from "~/fragment/line-items/LineItemTypes";

import { DebitNoteLineItemRecord } from "./DebitNoteLineItemTypes";
import DebitNoteLineItemFooter from "./DebitNoteLinteItemFooter";

export const DebitNoteLitMakeFooterRenderer = (
  currency?: CurrencyInfo,
  taxInclusion?: TAX_INCLUSION,
  taxTeritoryType?: TaxTeritoryType,
  hasCess?: boolean,
  isCopy?: boolean,
  totalPayAmount?: number,
  tdsInfo?: {
    tdsRate?: number;
    tdsAmount?: number;
  }
) => {
  return (data: Array<DebitNoteLineItemRecord>) => (
    <DebitNoteLineItemFooter
      rowData={data}
      taxInclusion={taxInclusion}
      taxTeritoryType={taxTeritoryType}
      currency={currency}
      hasCess={hasCess}
      isCopy={isCopy!}
      totalPayAmount={totalPayAmount}
      tdsInfo={tdsInfo}
    />
  );
};
