import React, { ReactElement } from "react";
import AttachFieldWithView from "~/fragment/attachable-field/AttachFieldWithView";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { doOCRAPI, doOCRLineItem } from "~/api/invoice_ocr";
import { getExpenseCategories } from "~/api/expense";

export type OCRData = {
  receipt_date: Date | undefined;
  cgst: number;
  sgst: number;
  igst: number;
  amount: number;
  invoice_no: string;
  gstin: string;
  taxRate: number;
  category_id: any;
  hsn?: any;
  creditDebitNo?: any;
};

interface Props {
  value?: any;
  onFileUpload?: (data: any) => void; // Todo: refactor
  onOcrData: (ocrParsedData: OCRData, rawOcrData?: any) => void;
  onLoading?: (isLoading: boolean) => void;
  readOnly?: boolean;
  onOcrDelete: () => void;
  DragFile?: any;
}

export default function OcrSelect({
  value,
  onFileUpload,
  onOcrData,
  readOnly,
  onOcrDelete,
  onLoading,
  DragFile,
}: Props): ReactElement {
  const handlePanDocChange = (newFileName: string, documentName: string) => {
    onFileUpload?.({
      documents: newFileName,
      document_name: documentName,
    });
  };
  const doOCRAndEmit = async (file: any) => {
    onLoading?.(true);
    try {
      const { data: categoryList } = await getExpenseCategories();

      Promise.all([
        doOCRAPI(file),
        process.env.REACT_APP_OCR_LINEITEMS === "enabled"
          ? await doOCRLineItem(file)
          : Promise.resolve(null),
      ])
        .then(([ocrData, _lineItemResponse]) => {
          // Modified for parent component
          const modifiedOCRData = {
            receipt_date: ocrData.parsed.invoiceDate,
            cgst: ocrData.parsed.cgst,
            sgst: ocrData.parsed.sgst,
            igst: ocrData.parsed.igst,
            invoice_no: ocrData.parsed.invoiceNo,
            // - customer_idx: "CID644312909",
            // - category_id: 3,
            gstin: ocrData.parsed.gstin,
            // amount: ocrData.parsed.amount,

            amount: ocrData.parsed.totalAmount,
            taxRate: ocrData.parsed.taxRate,
            hsn: ocrData.parsed.hsn,
            creditDebitNo: ocrData.parsed.creditDebitNo,

            // - category: ocrData.parsed.category,
            category_id: categoryList.find((it: any) => {
              return it.label === ocrData.parsed?.category;
            })?.value,
          };
          onOcrData(modifiedOCRData, ocrData);
        })
        .catch((err) => {
          console.warn("OCR API Error", err);
        })
        .finally(() => {
          // test
          onLoading?.(false);
        });
    } catch (error) {
      console.warn(error);
    } finally {
      // test
    }
  };

  const checkNewFile = () => {
    return new Promise<boolean>((resolve, reject) => {
      Modal.confirm({
        title: "Do you want to continue? Continuing will clear the description",
        icon: <ExclamationCircleOutlined />,
        onOk: () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
      });
    });
  };

  // console.log("89898989898------", DragFile ? DragFile[0] : "ppppp");
  return (
    <>
      <AttachFieldWithView
        fieldName="documents"
        lineitems={
          value.sd_row_data
            ? value.sd_row_data
            : value.pd_row_data
            ? value.pd_row_data
            : value.row_data
        }
        description={value.description}
        hashFileName={value.documents}
        flag={true}
        onHashFileChange={handlePanDocChange}
        readonly={readOnly}
        onNewFile={doOCRAndEmit}
        beforeAction={checkNewFile}
        handleAttachDelete={onOcrDelete}
        isExpense={true}
        label="Document"
        t={DragFile ? DragFile[0] : null}
        rules={[
          {
            type: "string",
            required: false,
            message: "Please upload docs!",
          },
        ]}
      />
    </>
  );
}
