import React, { PureComponent } from "react";
import { Modal, Form, Input, notification, Row, Button } from "antd";
import { fetchUtil } from "~/api/common";
import { FormInstance } from "antd/lib/form";
import commonStyle from "~/component/common.module.css";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

interface Props {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  reimbursement: any;
  modalTitle: string;
  loadReimburseData: (receipt_id: number) => void;
}

interface State {
  apiState: string;
}

export default class ApproveModal extends PureComponent<Props, State> {
  // formRef = createRef<FormInstance>();
  _formRef = React.createRef<FormInstance<any>>();

  state: State = {
    apiState: "idle",
  };

  handleCancel = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.approveModal208
    );
    this.props.onVisibleChange(false);
  };

  // handleOk = (e: React.MouseEvent<HTMLElement>) => {
  //   if (!this.formRef.current) {
  //     return;
  //   }
  //   // this.props.empSaveHandeler(true);
  //   this.formRef.current
  //     .validateFields()
  //     .then(async (value) => {
  //       const { ok, message } = await fetchUtil("POST", "/assign_role", {
  //         ...value,
  //         // role_id: LoginRole.EMPLOYEE,
  //       });
  //       if (ok) {
  //         // this.props.empSaveHandeler(false);
  //         notification.success({
  //           message,
  //         });
  //       } else {
  //         // this.props.empSaveHandeler(false);
  //         notification.error({
  //           message,
  //         });
  //       }
  //       // this.props.getUser();
  //       this.props.onVisibleChange(false);
  //     })
  //     .catch((reason) => {
  //       console.warn("Form error", reason);
  //     });
  // };

  formHandleSubmit = async (values: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.approveModal209
    );

    this.setState({
      apiState: "loading",
    });
    const { receipt_id, total_amount } = this.props.reimbursement;
    if (
      this.props.modalTitle === "Approve" &&
      Number(values.paid_amount) > Number(total_amount)
    ) {
      notification.error({ message: "Paid amount should be less or equal" });
      return;
    }
    const url =
      this.props.modalTitle === "Approve"
        ? "/update_receipt/accept_receipt"
        : "/update_receipt/reject_receipt";
    const { ok, message } = await fetchUtil("POST", `${url}`, {
      receipt_id,
      ...values,
    });

    if (ok) {
      notification.success({ message });
      this.props.loadReimburseData(this.props.reimbursement.id);
      this.handleCancel();

      // this.fetchData();
      // this.getReimbursementSummary();
      this.setState({ apiState: "success" });
    } else {
      notification.error({ message });
      this.setState({ apiState: "error" });
    }
  };

  render() {
    const { visible } = this.props;
    return (
      <Modal
        width={420}
        title={this.props.modalTitle}
        onCancel={this.handleCancel}
        visible={visible}
        destroyOnClose={true}
        footer={null}
        centered
        maskClosable={false}
      >
        <Form
          initialValues={this.props.reimbursement}
          onFinish={this.formHandleSubmit}
          ref={this._formRef}
        >
          <Row>
            {this.props.modalTitle === "Approve" ? (
              <Form.Item
                className={commonStyle["w-100"]}
                name="paid_amount"
                label="Paid amount"
                rules={[
                  {
                    // type: "string",
                    required: true,
                    pattern: /^[0-9]{1,}.*$/,
                    message: "Please enter valid Paid amount!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            ) : (
              ""
            )}
            {this.props.modalTitle === "Rejection" ? (
              <Form.Item
                className={commonStyle["w-100"]}
                name="receipt_id"
                label="Reimbursement id"
              >
                <Input disabled={true} />
              </Form.Item>
            ) : (
              ""
            )}

            <Form.Item
              className={commonStyle["w-100"]}
              name="comment"
              label="Comment"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Please enter valid Comment!",
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Row>

          <Row>
            <Button
              type="primary"
              id="yes-btn"
              htmlType="submit"
              style={{ marginRight: "10px" }}
              loading={this.state.apiState === "loading"}
            >
              Submit
            </Button>
            <Button type="dashed" onClick={this.handleCancel}>
              Cancel
            </Button>
          </Row>
        </Form>
      </Modal>
    );
  }
}
