import React, { Component, Fragment } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  notification,
  /*   Radio, */
  AutoComplete,
  Space,
  Spin,
} from "antd";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import commonStyle from "~/component/common.module.css";
import { APIState, fetchUtil } from "~/api/common";
import { FormInstance } from "antd/lib/form";
import { Prompt, RouteComponentProps } from "react-router";
import styles from "./VendorDetailsPage.module.css";
import { AuthState } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { LoginRole } from "~/api/auth";
import { noop } from "~/lib";
import { getCity } from "~/api/vendor";
import { MinusCircleOutlined } from "@ant-design/icons";
import FieldInfo from "~/component/FieldInfo";
import {
  CONSUMER_GST_REG_TYPE,
  CURRENCY_ID_INR,
  OVERSEAS_GST_REG_TYPE,
  PAN_VALIDATION_REGEX,
  UNREGISTERED_GST_REG_TYPE,
} from "~/lib/constants";
import { registerEvent } from "~/analytics";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";

import * as ga from "~/contants/gaConstants";

type RouteParams = {
  id?: string;
};

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
});

const createCachedGetDetailsFromGSTINFetcher = () => {
  const resultCache = new Map<string, any>();
  return async (gstin: string) => {
    if (resultCache.has(gstin)) {
      // console.log(resultCache.get(gstin));
      return Promise.resolve(resultCache.get(gstin));
    }
    const gstinUrl = "/get_details_from_gstin";
    // console.log(gstin);
    const { ok, message, json } = await fetchUtil("POST", `${gstinUrl}`, {
      gstin,
    });
    if (!ok) {
      throw Error(message);
    } else {
      resultCache.set(gstin, json[0]);
      return json[0];
    }
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux, RouteComponentProps<RouteParams> {}

interface VendorType {
  customer_id?: number;
  name?: string;
  company_name?: string;
  billing_address?: string;
  billing_address2?: string;
  billing_address_city?: string;
  billing_address_country?: string;
  billing_address_pincode?: string;
  billing_address_state?: string;
  shipping_address?: string;
  shipping_address2?: string;
  shipping_address_city?: string;
  shipping_address_country?: string;
  shipping_address_pincode?: string;
  shipping_address_state?: string;
  email_id: Array<
    | {
        email_id: string;
      }
    | undefined
  >;
  gst_registration_type_id: number | null;
  place_of_supply_id: number | string | null;
  place_of_supply: string | null;
  gstin: string | null;
  customer_currency: number | null;
  currency_id: number | null;
  /* symbol: string | null;
  symbol_name: string | null; */
  company_type?: string;
  customer_transactions: boolean | undefined;
  customer_details: Array<{
    gstin: string;
    customer_gstin_id?: number | string;
    billing_address?: string;
    billing_address_city?: string;
    billing_address_country?: string;
    billing_address_pincode?: string;
    billing_address_state?: string;
    company_name?: string;
    currency_id?: number;
    gst_registration_type_id?: number;
    pan_number?: string;
    place_of_supply_id?: number | string;
    active_customer_gstin_no?: boolean;
  }>;
  pan_number?: string;
  trade_name?: string;
}
interface State {
  addEditModeDataForUIBinding: VendorType;
  gstinTypeList: Array<{
    id: number;
    gst_type: string;
  }>;
  currencyList: Array<{
    id: number;
    currency_name: string;
    symbol_name: string;
  }>;
  stateList: Array<{
    id: number;
    state_name: string;
  }>;
  is_disabled_address_fields: boolean;
  isDisable: boolean;
  is_edit_disable: boolean;
  hideGstin: boolean;
  apiState: APIState;
  cityList: any;
  rowCityList: any;
  country: any;
  list: any;
  stateId: any;
  stateName: any;
  finalState: any;
  isDirty: boolean;
  /* requireFlag: boolean; */
  gstin: string;
  fetchLoad: boolean;
  /* gstDetail: any; */
  checkData?: any;
  checkEmailData?: any;
  editHereClicked?: boolean;
  shipCountry: any;
  shipStateName: any;
  isGstRegistered: boolean;
}

class VendorDetailsPage extends Component<Props, State> {
  state: State = {
    addEditModeDataForUIBinding: {
      customer_id: 0,
      name: "",
      company_name: "",
      billing_address: "",
      billing_address2: "",
      billing_address_city: "",
      billing_address_country: "",
      billing_address_pincode: "",
      billing_address_state: "",
      shipping_address: "",
      shipping_address2: "",
      shipping_address_city: "",
      shipping_address_country: "",
      shipping_address_pincode: "",
      shipping_address_state: "",
      email_id: [
        {
          email_id: "",
        },
      ],
      gst_registration_type_id: null,
      place_of_supply_id: null,
      place_of_supply: "",
      gstin: "",
      customer_currency: null,
      currency_id: null,
      /* symbol: "",
      symbol_name: "", */
      company_type: "",
      pan_number: "",
      trade_name: "",
      customer_transactions: undefined,
      customer_details: [
        {
          gstin: "",
        },
      ],
    },
    gstinTypeList: [],
    currencyList: [],
    stateList: [],
    is_disabled_address_fields: true,
    isDisable: true,
    is_edit_disable: false,
    hideGstin: false,
    apiState: "idle",
    cityList: [],
    rowCityList: [],
    country: "",
    list: [],
    stateId: 0,
    stateName: "",
    finalState: "",
    isDirty: false,
    /* requireFlag: true, */
    checkData: [],
    checkEmailData: [],
    gstin: "",
    fetchLoad: false,
    /* gstDetail: [], */
    editHereClicked: false,
    shipCountry: "",
    shipStateName: "",
    isGstRegistered: false,
  };

  _formRef = React.createRef<FormInstance<any>>();

  componentDidMount() {
    this.getStateList();
    this.getGstinType();
    this.getCurrencyList();
    if (this.props.match.params.id) {
      this.getVendorDetails();
    }

    if (!this.props.match.params.id) {
      this.setState({
        is_disabled_address_fields: false,
      });
    }
    if (
      this.props.activeRole === LoginRole.ADMIN ||
      this.props.activeRole === LoginRole.SUPERuSER
    ) {
      this.setState({ isDisable: false });
    }
    setTimeout(() => {
      document.querySelectorAll("input").forEach((el) => {
        el.setAttribute("autocomplete", "chrome-off");
      });
      document.querySelectorAll("textarea").forEach((el) => {
        el.setAttribute("autocomplete", "chrome-off");
      });
    }, 1200);
  }
  async getStateList() {
    const { ok, json, message } = await fetchUtil("GET", `/get_states`);

    if (ok) {
      const data = json;
      this.setState({
        stateList: data,
      });
    } else {
      notification.error({ message });
    }
  }
  async getGstinType() {
    const { ok, json, message } = await fetchUtil(
      "GET",
      `/get_gst_registration_types`
    );

    if (ok) {
      const data = json;
      this.setState({
        gstinTypeList: data,
      });
    } else {
      notification.error({ message });
    }
  }
  async getCurrencyList() {
    const { ok, json, message } = await fetchUtil("GET", `/get_currency`);

    if (ok) {
      const data = json;
      this.setState({
        currencyList: data,
      });
    } else {
      notification.error({ message });
    }
  }
  async getVendorDetails() {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_customer_details`,
      {
        customer_id: this.props.match.params.id,
        no_of_rows: null,
        page_no: null,
      }
    );

    if (ok) {
      const data = json[0];
      if (data) {
        if (data.shipping_address !== data.billing_address) {
          this.setState({
            editHereClicked: true,
          });
        }
        if (typeof data.email_id === "string") {
          data.email_id = [{ email_id: "" }];
        }

        if (data.email_id === null || data.email_id.length === 0) {
          data.email_id = [{ email_id: "" }];
        }
        if (data.contact_person_number === "0") {
          data.contact_person_number = undefined;
        }

        this.setState(
          {
            addEditModeDataForUIBinding: data,
            checkData: data.customer_details,
            checkEmailData: data.email_id,
            isGstRegistered: data.gstin ? true : false,
            apiState: "idle",
          }
          /* () => {
            if (
              this.state.addEditModeDataForUIBinding?.place_of_supply_id ===
              OTHER_TERITORY_ID
            ) {
              this.setState({
                requireFlag: false,
              });
            }
          } */
        );
        if (this._formRef.current) {
          if (
            data.gst_registration_type_id === UNREGISTERED_GST_REG_TYPE ||
            data.gst_registration_type_id === CONSUMER_GST_REG_TYPE ||
            data.gst_registration_type_id === OVERSEAS_GST_REG_TYPE
          ) {
            this.setState({ hideGstin: true });
          }
          this._formRef.current.setFieldsValue(data);
        }
      }
    } else {
      notification.error({ message });
    }
  }
  onFinish = async (values: any) => {
    try {
      await this._formRef.current?.validateFields();
      this.setState({
        apiState: "loading",
      });

      const { id } = this.props.match.params;
      let apiUrl = "";

      if (!id) {
        // create vendor
        apiUrl = "/save_customer_details";
      } else {
        // edit vendor
        apiUrl = "/edit_customer_details";
      }
      const state = this.state.stateList.find(
        (it: any) =>
          it.state_name === values.billing_address_state?.toUpperCase()
      );

      if (this.state.hideGstin) {
        values.company_name = values.trade_name;
      }

      if (values.email_id) {
        if (values.email_id.length === 0) {
          values.email_id = null;
        } else {
          values.email_id.forEach((el: any, idx: any) => {
            if (values.email_id.length === 1 && !el.email_id) {
              values.email_id = null;
            }
          });
        }
      }

      const { ok, message } = await fetchUtil("POST", `${apiUrl}`, {
        customer_id: +this.props.match.params.id!,
        ...values,
        customer_name: values.company_name,
        mobile_no: null,
        phone_no: null,
        other_no: null,
        email_id: values.email_id
          ? values.email_id !== null
            ? values.email_id.filter((el: any, idx: any) => {
                return !!el;
              })
            : null
          : null,
        shipping_address: values.shipping_address
          ? values.shipping_address
          : values.billing_address,
        shipping_address2: values.shipping_address2
          ? values.shipping_address2
          : values.billing_address2,
        shipping_address_city: values.shipping_address_city
          ? values.shipping_address_city
          : values.billing_address_city,
        shipping_address_state: values.shipping_address_state
          ? values.shipping_address_state
          : values.billing_address_state,
        shipping_address_country: values.shipping_address_country
          ? values.shipping_address_country
          : values.billing_address_country,
        shipping_address_pincode: values.shipping_address_pincode
          ? values.shipping_address_pincode
          : values.billing_address_pincode,
        tan_number: null,
        is_active: true,

        contact_person_number: +values.contact_person_number,
        gst_registration_type_id: +values.gst_registration_type_id,
        state_id: state?.id,
        customer_details: this.state.addEditModeDataForUIBinding
          .customer_details,
        source_metadata: {
          type_id: 2,
          uid: "",
          extra_source_json: {},
        },
      });
      if (ok) {
        notification.success({
          message,
        });
        this.setState({ apiState: "success", isDirty: false });
        this.props.history.goBack();
      } else {
        if (values.email_id === null) {
          values.email_id = [{ email_id: "" }];
        }
        this.setState({ apiState: "error" });
        notification.error({
          message,
        });
      }
    } catch (error) {
      console.warn(error);
    }
  };
  loadPickupLists = async (value: string) => {
    const [cityListRes] = await Promise.all([
      getCity(value as string).then(({ ok, message, data }) => ({
        ok,
        message,
        data,
      })),
    ]);

    if (!cityListRes.ok) {
      notification.error({
        message: "Failed to load city list.",
        description: cityListRes.message,
      });
    } else {
      this.setState({
        cityList: cityListRes.data,
        rowCityList: cityListRes.data,
      });
    }
  };

  handleClickHere = () => {
    const key = `open${Date.now()}`;
    const close = this.close(key) as any;
    const btnOnClickHandler = (e: React.MouseEvent) => {
      registerEvent(
        ga.EVENT_CATEGORY_BUTTON_CLICK,
        ga.EVENT_CLICK,
        ga.events.vendorDetailsPage253
      );
      notification.close(key);
      if (this._formRef.current) {
        this._formRef.current.setFieldsValue({
          shipping_address_city: this._formRef.current?.getFieldValue(
            "billing_address_city"
          ),
          shipping_address_country: this._formRef.current?.getFieldValue(
            "billing_address_country"
          ),
          shipping_address2: this._formRef.current?.getFieldValue(
            "billing_address2"
          ),
          shipping_address: this._formRef.current?.getFieldValue(
            "billing_address"
          ),
          shipping_address_state: this._formRef.current?.getFieldValue(
            "billing_address_state"
          ),
          shipping_address_pincode: this._formRef.current?.getFieldValue(
            "billing_address_pincode"
          ),
        });
      }
      this.setState({
        editHereClicked: false,
      });
    };
    const btn = (
      <Button type="primary" size="small" onClick={btnOnClickHandler}>
        Confirm
      </Button>
    );
    if (
      (this._formRef.current?.getFieldValue("shipping_address") &&
        this._formRef.current?.getFieldValue("shipping_address") !==
          this._formRef.current?.getFieldValue("billing_address")) ||
      (this._formRef.current?.getFieldValue("shipping_address_city") &&
        this._formRef.current?.getFieldValue("shipping_address_city") !==
          this._formRef.current?.getFieldValue("billing_address_city")) ||
      (this._formRef.current?.getFieldValue("shipping_address_pincode") &&
        this._formRef.current?.getFieldValue("shipping_address_pincode") !==
          this._formRef.current?.getFieldValue("billing_address_pincode"))
    ) {
      notification.open({
        duration: 0,
        message: "",
        description:
          "Shipping address data would be lost, are you sure to cancel?",
        btn,
        key,
        onClose: close,
        closeIcon: false,
      });
    } else {
      this.setState({
        editHereClicked: false,
      });
    }
  };

  close = (key: any) => {
    notification.close(key);
  };

  handleEditHere = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.vendorDetailsPage254
    );
    this.setState({
      editHereClicked: true,
    });
  };

  handleCitySelect = (_value: string, opt: any) => {
    const id = opt.key;
    // tslint:disable-next-line: triple-equals | Double equal is intentional, please don't correct it
    const cityData = this.state.rowCityList.find((it: any) => it.id === id)!;
    this.setState(
      {
        country: cityData.country,
        stateName: cityData.state,
      },
      () => {
        if (this._formRef.current) {
          this.setState({
            addEditModeDataForUIBinding: {
              ...this.state.addEditModeDataForUIBinding!,
              billing_address_country: this.state.country,
              billing_address_state: this.state.stateName,
              billing_address_city: cityData.city,
              shipping_address_city: cityData.city,
              shipping_address_country: this.state.country,
              shipping_address_state: this.state.stateName,
            },
          });

          this._formRef.current.setFieldsValue({
            billing_address_country: this.state.country,
            billing_address_state: this.state.stateName,
          });
        }
      }
    );
    this._formRef.current?.setFieldsValue({
      billing_address_city: `${cityData.city}`,
    });
    // HACK:: since value of auto complete is city id but not the city name,
    // we are deliberately updating it after an option is selected (previous line)
    // Now, before force update loadPickupList is called once and returns empty array.
    // So, we are forcefully loading the state.cityList by loading the pickup list based on the city name.

    // TODO: remove this hack by doing everythnig here instead of handleCityBlue()
    // ------this.loadPickupLists(cityData.city);
  };
  handleCityShippingSelect = (_value: string, opt: any) => {
    const id = opt.key;
    // tslint:disable-next-line: triple-equals | Double equal is intentional, please don't correct it
    const cityData = this.state.rowCityList.find((it: any) => it.id === id)!;
    this.setState(
      {
        shipCountry: cityData.country,
        shipStateName: cityData.state,
      },
      () => {
        if (this._formRef.current) {
          this._formRef.current.setFieldsValue({
            shipping_address_country: cityData.country,
            shipping_address_state: cityData.state,
          });
        }
      }
    );
    this._formRef.current?.setFieldsValue({
      shipping_address_city: `${cityData.city}`,
    });
    // HACK:: since value of auto complete is city id but not the city name,
    // we are deliberately updating it after an option is selected (previous line)
    // Now, before force update loadPickupList is called once and returns empty array.
    // So, we are forcefully loading the state.cityList by loading the pickup list based on the city name.

    // TODO: remove this hack by doing everythnig here instead of handleCityBlue()
  };

  handleOnValueCHange = (changedValues: any, val: any) => {
    if (!this.state.isDirty) {
      this.setState({ isDirty: true });
    }

    this.setState({
      addEditModeDataForUIBinding: val,
    });
    if ((changedValues?.billing_address_city?.length || 0) >= 3) {
      this.loadPickupLists(changedValues.billing_address_city);
    } else if (changedValues?.billing_address_city?.length === 0) {
      this.setState(
        {
          stateId: null,
          finalState: "",
          country: "",
          stateName: "",
        },
        () => {
          this._formRef.current?.setFieldsValue({
            billing_address_city: "",
            billing_address_country: "",
            billing_address_state: "",
          });
        }
      );
    }
    if ((changedValues?.shipping_address_city?.length || 0) >= 3) {
      this.loadPickupLists(changedValues.shipping_address_city);
    } else if (changedValues?.shipping_address_city?.length === 0) {
      this.setState(
        {
          stateId: null,
          finalState: "",
          country: "",
          stateName: "",
        },
        () => {
          this._formRef.current?.setFieldsValue({
            shipping_address_city: "",
            shipping_address_country: "",
            shipping_address_state: "",
          });
        }
      );
    }

    if (changedValues.pan_number) {
      if (this._formRef.current) {
        const panNumber = String(changedValues.pan_number).toUpperCase();

        this._formRef.current.setFieldsValue({
          pan_number: panNumber,
        });
      }
    }
    if (changedValues.gstin) {
      if (this._formRef.current) {
        const gstin = String(changedValues.gstin).toUpperCase();
        this._formRef.current.setFieldsValue({
          gstin,
        });
      }
    }
    /* if (changedValues.place_of_supply_id) {
      if (changedValues.place_of_supply_id === OTHER_TERITORY_ID) {
        this.setState({
          requireFlag: false,
        });
      } else if (changedValues.place_of_supply_id !== OTHER_TERITORY_ID) {
        this.setState({
          requireFlag: true,
        });
      }
    } */
    if (changedValues.customer_details) {
      const cdArray = changedValues.customer_details;
      cdArray.forEach((item: any, index: number) => {
        if (item) {
          if (item.gstin) {
            if (item.gstin.includes(val.pan_number) === false) {
              notification.error({
                message:
                  "This GSTIN does not seems to be associated with the current company.",
              });
              return;
            }
            if (item.customer_gstin_id) {
              // Meaning: this is an existing row hit during edit.
              return;
            }
            if (item.gstin.length === 15) {
              this.fetchGstinfo(item.gstin, val).then(
                (vendorfromData: VendorType | null) => {
                  if (!vendorfromData) {
                    return;
                  }

                  let existingRowIdx = -1;
                  cdArray.forEach(
                    (cd: VendorType["customer_details"][0], idx: number) => {
                      if (cd?.gstin === item.gstin) {
                        existingRowIdx = idx;
                        return false;
                      }
                    }
                  );
                  const custDetails = this.state.addEditModeDataForUIBinding
                    .customer_details;
                  vendorfromData.customer_details[0].customer_gstin_id =
                    custDetails[existingRowIdx].customer_gstin_id;
                  custDetails[existingRowIdx] =
                    vendorfromData.customer_details[0];

                  this._formRef.current?.setFieldsValue({
                    ...vendorfromData,
                    customer_details: custDetails,
                  });
                  this.setState({
                    fetchLoad: false,
                    addEditModeDataForUIBinding: {
                      ...this.state.addEditModeDataForUIBinding,
                      ...vendorfromData,
                      customer_details: custDetails,
                    },
                  });

                  /* } else {
                      const newCustomerDetails = this.state.addEditModeDataForUIBinding.customer_details.concat(
                    vendorfromData.customer_details
                  );
                  this._formRef.current?.setFieldsValue(vendorfromData);
                  this.setState({
                    fetchLoad: false,
                    addEditModeDataForUIBinding: vendorfromData,
                  });
                } */
                }
              );
            }
          }
        } else {
          const oldStateData = this.state.addEditModeDataForUIBinding;
          const newCustomerDetails = oldStateData.customer_details.concat({
            gstin: "",
          });
          this.setState({
            addEditModeDataForUIBinding: {
              ...oldStateData,
              customer_details: newCustomerDetails,
            },
          });
        }
      });
      /* this.getGstDetails(val); */
    }
    if (changedValues.gst_registration_type_id === UNREGISTERED_GST_REG_TYPE) {
      this._formRef.current!.setFields([
        {
          name: "pan_number",
          errors: [],
        },
      ]);
    }
  };
  onEdit = (_e: React.MouseEvent<HTMLButtonElement>) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.vendorDetailsPage254
    );
    this.setState({
      is_disabled_address_fields: false,
      is_edit_disable: true,
    });
  };

  fetchGstinfo = async (
    gstin: string,
    val?: any
  ): Promise<VendorType | null> => {
    const GSTIN_REGEX = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}[Z]{1}[A-Z0-9]{1}$/;
    if (!GSTIN_REGEX.test(gstin)) {
      notification.error({ message: "Please enter a valid GSTIN no !" });
      return null;
    }

    this.setState({
      fetchLoad: true,
    });

    try {
      const data = await this.cachedGetDetailsFromGSTIN(gstin);
      const pos = data.gstin.substring(0, 2);
      const toStr = (other: any) => (other ? other + "" : "");

      const vendorfromData: VendorType = {
        company_name: toStr(data.legalName),
        trade_name: toStr(data.tradeName),
        billing_address: toStr(data.addressLine),
        billing_address_country: toStr(data.country.name),
        billing_address_state: toStr(data.state.name),
        billing_address_pincode: toStr(data.pinCode),
        billing_address_city: toStr(data.city.name),
        pan_number: toStr(data.pan),
        gst_registration_type_id: Number(data.regType.id),
        place_of_supply_id: Number(pos),
        currency_id: 1,
        email_id: [{ email_id: "" }],
        customer_details: [
          {
            company_name: toStr(data.legalName),
            billing_address: toStr(data.addressLine),
            billing_address_country: toStr(data.country.name),
            billing_address_state: toStr(data.state.name),
            billing_address_pincode: toStr(data.pinCode),
            billing_address_city: toStr(data.city.name),
            pan_number: toStr(data.pan),
            gst_registration_type_id: Number(data.regType.id),
            place_of_supply_id: Number(pos),
            currency_id: CURRENCY_ID_INR,
            gstin: toStr(gstin),
            active_customer_gstin_no: !!data.active,
          },
        ],
        place_of_supply: toStr(data.state.name),
        gstin: toStr(gstin),
        customer_currency: CURRENCY_ID_INR,
        /* symbol: this.state.currencyList,
          symbol_name: 'INR', */
        customer_transactions: undefined,
      };

      return vendorfromData;
    } catch (err) {
      const error = err as any;
      console.error(error);
      notification.error({
        message: error.message,
      });
      return null;
    } finally {
      this.setState({
        fetchLoad: false,
      });
    }
  };

  /* getGstDetails = async (val: any) => {
    const taskList = val.customer_details.map(async (el: any) => {
      if (el) {
        if (el.gstin.length === 15) {
          try {
            const data =
              val.customer_details.length > 1 &&
              el.gstin.includes(val.pan_number) !== false
                ? await this.cachedGetDetailsFromGSTIN(el.gstin)
                : val.customer_details.length === 1
                ? await this.cachedGetDetailsFromGSTIN(el.gstin)
                : undefined;
            const pos = data.gstin.substring(0, 2);
            const gstinObj = this.state.addEditModeDataForUIBinding?.customer_details.find(
              (it: any) => el.gstin === it.gstin
            );

            const obj = {
              company_name: data.legalName,
              trade_name: data.tradeName,
              billing_address: data.addressLine,
              billing_address_country: data.country.name,
              billing_address_state: data.state.name,
              billing_address_pincode: data.pinCode,
              billing_address_city: data.city.name,
              pan_number: data.pan,
              gst_registration_type_id: data.regType.id,
              place_of_supply_id: Number(pos),
              customer_gstin_id: gstinObj?.customer_gstin_id || -1,
              currency_id: 1,
              gstin: el.gstin,
              active_customer_gstin_no: el.active_customer_gstin_no,
            };
            return obj;
          } catch (error) {
            console.error(error);
          }
        }
      }
    });
    const hollow_gstin_details = await Promise.all(taskList);

    const gstinDetails = hollow_gstin_details.filter((el) => {
      return !!el;
    });
    this.setState({
      gstDetail: gstinDetails,
    });
  };
 */
  cachedGetDetailsFromGSTIN = createCachedGetDetailsFromGSTINFetcher();

  onDelete = async (_e: React.MouseEvent<HTMLButtonElement>) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.vendorDetailsPage255
    );
    const { ok, message } = await fetchUtil("POST", `/delete_customer`, {
      customer_id: this.props.match.params.id,
    });

    if (ok) {
      this.props.history.goBack();
      notification.success({ message });
    } else {
      notification.error({ message });
    }
  };

  gstRegTypeHandleChange = (val: any) => {
    if (
      val === UNREGISTERED_GST_REG_TYPE ||
      val === CONSUMER_GST_REG_TYPE ||
      val === OVERSEAS_GST_REG_TYPE
    ) {
      this.setState({ hideGstin: true });
    } else {
      this.setState({ hideGstin: false });
    }
  };

  render() {
    const { id } = this.props.match.params;
    const {
      customer_details: cdArray,
    } = this.state.addEditModeDataForUIBinding;

    const stateListView = !this.state.is_disabled_address_fields ? null : (
      <Form.Item label="State">
        <input
          className="ant-input"
          value={this.state.addEditModeDataForUIBinding?.billing_address_state}
          onChange={noop}
          readOnly
        />
      </Form.Item>
    );
    const countryView = !this.state.is_disabled_address_fields ? null : (
      <Form.Item label="Country">
        <input
          className="ant-input"
          value={
            this.state.addEditModeDataForUIBinding?.billing_address_country
          }
          onChange={noop}
          readOnly
        />
      </Form.Item>
    );

    const currencyView = !this.state.is_disabled_address_fields ? null : (
      <Form.Item label="Currency">
        <input
          className="ant-input"
          defaultValue={
            this.state.currencyList.find(
              (it) =>
                it.id === this.state.addEditModeDataForUIBinding?.currency_id
            )?.currency_name
          }
          readOnly
        />
      </Form.Item>
    );

    const cityView = !this.state.is_disabled_address_fields ? null : (
      <Form.Item label="City">
        <input
          className="ant-input"
          value={this.state.addEditModeDataForUIBinding?.billing_address_city}
          onChange={noop}
          readOnly
        />
      </Form.Item>
    );

    const GSTINRegistrationTypeView = !this.state
      .is_disabled_address_fields ? null : (
      <Form.Item label="GSTIN registration type">
        <input
          className="ant-input"
          defaultValue={
            this.state.gstinTypeList.find(
              (it) =>
                it.id ===
                this.state.addEditModeDataForUIBinding?.gst_registration_type_id
            )?.gst_type
          }
          readOnly
        />
      </Form.Item>
    );

    const shipcountryView = !this.state.is_disabled_address_fields ? null : (
      <Form.Item label="Country">
        <input
          className="ant-input"
          value={
            this.state.addEditModeDataForUIBinding?.shipping_address_country +
            ""
          }
          onChange={noop}
          readOnly
        />
      </Form.Item>
    );
    const shipcityView = !this.state.is_disabled_address_fields ? null : (
      <>
        <Form.Item label="Shipping Address City">
          <input
            className="ant-input"
            value={
              this.state.addEditModeDataForUIBinding?.shipping_address_city + ""
            }
            onChange={noop}
            readOnly
          />
        </Form.Item>
      </>
    );
    const shipState = !this.state.is_disabled_address_fields ? null : (
      <Form.Item label="Shipping Address State">
        <input
          className="ant-input"
          value={
            this.state.addEditModeDataForUIBinding?.shipping_address_state + ""
          }
          onChange={noop}
          readOnly
        />
      </Form.Item>
    );
    const panView = !this.state.is_disabled_address_fields ? null : (
      <Form.Item label="PAN">
        <input
          className="ant-input"
          value={this.state.addEditModeDataForUIBinding?.pan_number}
          onChange={noop}
          readOnly
        />
      </Form.Item>
    );
    return (
      <IMTContent fullwidth withoutMargin={true}>
        <Helmet>
          <title>{titles.VendorDetailsPage}</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={[
            "Vendor / Customer",
            !id ? "New" : "Details ( " + id + ")",
          ]}
          actions={
            id && !this.state.isDisable ? (
              <Space>
                {this.props.activeRole !== 3 ? (
                  !this.state.is_edit_disable ? (
                    <Button type="primary" onClick={this.onEdit}>
                      Edit
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.apiState === "loading"}
                      // tslint:disable-next-line: jsx-no-lambda
                      onClick={(e: React.MouseEvent) => {
                        registerEvent(
                          ga.EVENT_CATEGORY_BUTTON_CLICK,
                          ga.EVENT_CLICK,
                          ga.events.vendorDetailsPage256
                        );
                        this.onFinish(this.state.addEditModeDataForUIBinding);
                      }}
                    >
                      Save
                    </Button>
                  )
                ) : null}

                {this.state.is_edit_disable ? (
                  <Button
                    type="dashed"
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={(e: React.MouseEvent) => {
                      registerEvent(
                        ga.EVENT_CATEGORY_BUTTON_CLICK,
                        ga.EVENT_CLICK,
                        ga.events.vendorDetailsPage257
                      );
                      window.location.reload();
                    }}
                  >
                    Cancel
                  </Button>
                ) : null}
                {this.state.is_edit_disable ? null : this.props.activeRole !==
                  3 ? (
                  <Button type="default" onClick={this.onDelete}>
                    Delete
                  </Button>
                ) : null}
              </Space>
            ) : this.props.activeRole !== 3 ? (
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.apiState === "loading"}
                // tslint:disable-next-line: jsx-no-lambda
                onClick={(e: React.MouseEvent) => {
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    ga.events.vendorDetailsPage256
                  );
                  this.onFinish(this.state.addEditModeDataForUIBinding);
                }}
              >
                Save
              </Button>
            ) : null
          }
        />
        <Spin
          spinning={this.state.apiState === "loading" || this.state.fetchLoad}
          delay={500}
        >
          <div className={styles.commonContent}>
            <Form
              initialValues={this.state.addEditModeDataForUIBinding || {}}
              onValuesChange={this.handleOnValueCHange}
              ref={this._formRef}
            >
              <Prompt
                message="You have unsaved details. Do you still want to leave this page?"
                when={this.state.isDirty}
              />
              <Row
                style={{ width: "72%" }}
                gutter={{ md: 30, lg: 32, xl: 100 }}
              >
                <Col md={16} lg={14} xl={12} xxl={10}>
                  <Form.Item name="company_name" hidden />
                  <Form.Item
                    className={commonStyle["w-100"]}
                    name="trade_name"
                    label="Name"
                    rules={[
                      {
                        type: "string",
                        required: !this.state.is_disabled_address_fields
                          ? true
                          : false,
                        message: "Please enter valid name!",
                      },
                    ]}
                  >
                    <Input
                      autoFocus
                      readOnly={this.state.is_disabled_address_fields}
                    />
                  </Form.Item>

                  <FieldInfo
                    style={{ marginTop: -15 }}
                    text={this.state.addEditModeDataForUIBinding?.company_name}
                  />
                  <Form.Item
                    className={commonStyle["w-100"]}
                    name="contact_person_name"
                    label="Contact person name (optional)"
                    rules={[
                      {
                        type: "string",
                        required: false,
                        pattern: /^[a-zA-Z0-9]{2,}.*$/,
                        message: "Please enter valid Contact person name !",
                      },
                    ]}
                  >
                    <Input readOnly={this.state.is_disabled_address_fields} />
                  </Form.Item>
                  <Form.Item
                    className={commonStyle["w-100"]}
                    name="contact_person_number"
                    label="Phone number"
                    rules={[
                      {
                        type: "string",
                        required: false,
                        pattern: /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[1-9]\d{9}$/,
                        message: "Please enter valid Phone number !",
                      },
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      readOnly={this.state.is_disabled_address_fields}
                    />
                  </Form.Item>

                  <Form.List name="email_id">
                    {(fields, { add, remove }) => (
                      <>
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "12px",
                              marginTop: "5px",
                              color: "grey",
                            }}
                            // className={
                            //   !this.state.is_disabled_address_fields ? styles.a : undefined
                            // }
                          >
                            Email
                          </p>
                        </span>

                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Fragment key={key}>
                            <span style={{ display: "flex" }}>
                              <Form.Item
                                style={{ flexBasis: "10000px" }}
                                {...restField}
                                name={[name, "email_id"]}
                                fieldKey={[fieldKey, `email_${key}`]}
                                rules={[
                                  {
                                    required: false,
                                    type: "email",
                                    message: "Please enter valid email!",
                                  },
                                ]}
                                // rules={[{ required: false }]}
                              >
                                <Input
                                  readOnly={
                                    this.state.is_disabled_address_fields
                                  }
                                  placeholder={"email"}
                                />
                              </Form.Item>
                              {this.props.match.params.id ? (
                                name + 1 > this.state.checkData.length &&
                                !this.state.is_disabled_address_fields ? (
                                  <MinusCircleOutlined
                                    style={{
                                      marginLeft: "8px",
                                      marginTop: "10px",
                                    }}
                                    className="dynamic-delete-button"
                                    onClick={() => remove(name)}
                                  />
                                ) : null
                              ) : fields.length > 1 ? (
                                <MinusCircleOutlined
                                  style={{
                                    marginLeft: "8px",
                                    marginTop: "10px",
                                  }}
                                  className="dynamic-delete-button"
                                  onClick={() => remove(name)}
                                />
                              ) : null}
                            </span>
                          </Fragment>
                        ))}
                        {!this.state.is_disabled_address_fields ? (
                          <div
                            onClick={() => add()}
                            style={{ marginLeft: "70%", cursor: "pointer" }}
                          >
                            <FieldInfo text={" + Add more email"} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </Form.List>
                  <Form.Item
                    className={commonStyle["w-100"]}
                    name="billing_address"
                    label="Address line "
                    rules={[
                      {
                        type: "string",
                        required: !this.state.is_disabled_address_fields
                          ? true
                          : false,
                        message: "Please enter valid Address line !",
                      },
                    ]}
                  >
                    <Input readOnly={this.state.is_disabled_address_fields} />
                  </Form.Item>
                  <Form.Item
                    className={commonStyle["w-100"]}
                    name="billing_address2"
                    label="Address line 2"
                    rules={[
                      {
                        type: "string",
                        required: false,
                        message: "Please enter valid Address line 2 !",
                      },
                    ]}
                  >
                    <Input readOnly={this.state.is_disabled_address_fields} />
                  </Form.Item>
                  {cityView || (
                    <>
                      <Form.Item
                        className={commonStyle["w-100"]}
                        name="billing_address_city"
                        label="City"
                        rules={[
                          {
                            type: "string",
                            required: true,
                            message: "Please enter valid City !",
                          },
                        ]}
                      >
                        <AutoComplete
                          id="chrome-off"
                          onSelect={this.handleCitySelect}
                        >
                          {this.state.cityList.map((v: any, idx: number) => {
                            return (
                              <AutoComplete.Option key={v.id} value={`${v.id}`}>
                                <div>
                                  <span>{v.city}</span>{" "}
                                  <span
                                    style={{
                                      fontSize: 10,
                                      color: "var(--primary-color)",
                                    }}
                                  >
                                    {v.state}, {v.country}
                                  </span>
                                </div>
                              </AutoComplete.Option>
                            );
                          })}
                        </AutoComplete>
                      </Form.Item>
                    </>
                  )}

                  <Row gutter={{ md: 8, lg: 16 }}>
                    <Col sm={24} lg={12}>
                      {stateListView || (
                        <Form.Item
                          className={commonStyle["w-100"]}
                          name="billing_address_state"
                          label="State"
                          rules={[
                            {
                              required: true,
                              message: "Please enter valid State!",
                            },
                          ]}
                        >
                          <Input autoComplete="off" />
                        </Form.Item>
                      )}
                    </Col>
                    <Col sm={24} lg={12}>
                      {countryView || (
                        <Form.Item
                          className={commonStyle["w-100"]}
                          name="billing_address_country"
                          label="Country"
                          rules={[
                            {
                              type: "string",
                              required: true,
                              message: "Please enter valid Country !",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>

                  <Form.Item
                    className={commonStyle["w-100"]}
                    name="billing_address_pincode"
                    label="Zipcode"
                    rules={[
                      {
                        type: "string",
                        required: !this.state.is_disabled_address_fields
                          ? true
                          : false,
                        pattern: /^[0-9,a-z,A-Z]{1}[0-9,a-z,A-Z]{3,}$/,
                        message: "Please enter valid Zipcode !",
                      },
                    ]}
                  >
                    <Input
                      minLength={4}
                      maxLength={7}
                      readOnly={this.state.is_disabled_address_fields}
                    />
                  </Form.Item>
                  <Form.Item
                    id="chrome-off"
                    className={commonStyle["w-100"]}
                    name="website"
                    label="Website"
                    rules={[
                      {
                        type: "string",
                        required: false,
                        pattern: /^[a-zA-Z0-9]{2,}.*$/,
                        message: "Please enter valid Website !",
                      },
                    ]}
                  >
                    <Input readOnly={this.state.is_disabled_address_fields} />
                  </Form.Item>
                </Col>

                <Col md={12}>
                  {!this.state.hideGstin ? (
                    <Form.List name="customer_details">
                      {(fields, { add, remove }) => (
                        <>
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "12px",
                                marginTop: "5px",
                                color: "grey",
                              }}
                              className={
                                !this.state.is_disabled_address_fields
                                  ? styles.a
                                  : undefined
                              }
                            >
                              GSTIN
                            </p>
                          </span>

                          {fields.map(
                            ({ key, name, fieldKey, ...restField }) => {
                              return (
                                <Fragment key={key}>
                                  {cdArray[name]?.active_customer_gstin_no ===
                                  false ? (
                                    <div
                                      style={{
                                        fontSize: "10px",
                                        lineHeight: "7px",
                                        fontWeight: 800,
                                        marginTop: -4,
                                        color: "var(--error-color)",
                                        padding: "3px",
                                      }}
                                    >
                                      INACTIVE
                                    </div>
                                  ) : null}
                                  <span style={{ display: "flex" }}>
                                    <Form.Item
                                      getValueFromEvent={(e) =>
                                        e.target.value?.toUpperCase()
                                      }
                                      style={{ flexBasis: "10000px" }}
                                      {...restField}
                                      name={[name, "gstin"]}
                                      fieldKey={[fieldKey, `gstin_${key}`]}
                                      rules={[
                                        {
                                          required: !this.state
                                            .is_disabled_address_fields
                                            ? true
                                            : false,
                                          pattern: /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
                                          message: "Please enter valid GSTIN!",
                                        },
                                      ]}
                                    >
                                      <Input
                                        readOnly={
                                          this.state.is_disabled_address_fields
                                        }
                                        placeholder={"gstin"}
                                      />
                                    </Form.Item>{" "}
                                    {this.props.match.params.id ? (
                                      name + 1 > this.state.checkData.length ? (
                                        <MinusCircleOutlined
                                          style={{
                                            marginLeft: "8px",
                                            marginTop: "10px",
                                          }}
                                          className="dynamic-delete-button"
                                          onClick={() => remove(name)}
                                        />
                                      ) : null
                                    ) : fields.length > 1 ? (
                                      <MinusCircleOutlined
                                        style={{
                                          marginLeft: "8px",
                                          marginTop: "10px",
                                        }}
                                        className="dynamic-delete-button"
                                        onClick={() => remove(name)}
                                      />
                                    ) : null}
                                  </span>
                                </Fragment>
                              );
                            }
                          )}
                          {!this.state.is_disabled_address_fields ? (
                            <p
                              onClick={() => add()}
                              style={{
                                color: "var(--primary-color)",
                                cursor: "pointer",
                                fontSize: "10px",
                                fontWeight: 600,
                                marginTop: "-12px",
                                textAlign: "right",
                                marginBottom: "0",
                              }}
                            >
                              + Add more GST numbers
                            </p>
                          ) : null}
                        </>
                      )}
                    </Form.List>
                  ) : null}
                  {GSTINRegistrationTypeView || (
                    <>
                      <Form.Item
                        className={commonStyle["w-100"]}
                        name="gst_registration_type_id"
                        label="GSTIN registration type"
                        rules={[
                          {
                            required: true,
                            message: "Please enter GSTIN registration type!",
                          },
                        ]}
                      >
                        <Select
                          id="chrome-off"
                          showSearch
                          optionFilterProp="children"
                          disabled={
                            this.state.is_disabled_address_fields ||
                            this.state.isGstRegistered
                          }
                          onChange={this.gstRegTypeHandleChange}
                        >
                          {this.state.gstinTypeList.map(
                            (gstin: { id: number; gst_type: string }) => (
                              <Select.Option key={gstin.id} value={gstin.id}>
                                {gstin.gst_type}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                      <FieldInfo
                        text="Choose 'GST unregistered' for Indian businesses not having a GSTIN yet or the business is located outside India. Choose 'SEZ' if the business operates from a special economic zone."
                        subtle
                        tip
                      />
                    </>
                  )}

                  {currencyView || (
                    <Form.Item
                      className={commonStyle["w-100"]}
                      name="currency_id"
                      label="Currency"
                      rules={[
                        {
                          required: true,
                          message: "Please select Currency!",
                        },
                      ]}
                    >
                      <Select
                        id="chrome-off"
                        showSearch
                        optionFilterProp="children"
                        disabled={
                          this.state.is_disabled_address_fields ||
                          this.state.addEditModeDataForUIBinding
                            ?.customer_transactions
                        }
                      >
                        {this.state.currencyList.map(
                          (currency: {
                            id: number;
                            currency_name: string;
                            symbol_name: string;
                          }) => (
                            <Select.Option
                              key={currency.id}
                              value={currency.id}
                            >
                              {`${currency.currency_name} (${currency.symbol_name})`}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                  )}
                  {panView || (
                    <Form.Item
                      name="pan_number"
                      label="PAN"
                      rules={[
                        {
                          type: "string",
                          required: this.state.hideGstin ? false : true,
                          pattern: PAN_VALIDATION_REGEX,
                          message: "Please enter valid PAN!",
                        },
                      ]}
                    >
                      <Input readOnly={this.state.is_disabled_address_fields} />
                    </Form.Item>
                  )}

                  {!this.state.is_disabled_address_fields ? (
                    !this.state.editHereClicked ? (
                      <Form.Item>
                        If shipping address is relevant to this vendor / client
                        and and it is different than billing address, please{" "}
                        <p
                          style={{
                            color: "var(--primary-color)",
                            cursor: "pointer",
                          }}
                          onClick={this.handleEditHere}
                        >
                          Edit here
                        </p>
                      </Form.Item>
                    ) : (
                      <Form.Item>
                        If shipping address is same as billing address, please{" "}
                        <p
                          style={{ color: "#3593F3" }}
                          onClick={this.handleClickHere}
                        >
                          click here
                        </p>
                      </Form.Item>
                    )
                  ) : null}

                  {this.state.editHereClicked ? (
                    <>
                      <Form.Item
                        className={commonStyle["w-100"]}
                        name="shipping_address"
                        label="Shipping Address line "
                        rules={[
                          {
                            type: "string",
                            required: !this.state.is_disabled_address_fields
                              ? true
                              : false,
                            message: "Please enter valid Address line !",
                          },
                        ]}
                      >
                        <Input
                          readOnly={this.state.is_disabled_address_fields}
                        />
                      </Form.Item>
                      <Form.Item
                        className={commonStyle["w-100"]}
                        name="shipping_address2"
                        label="Shipping Address line 2"
                        rules={[
                          {
                            type: "string",
                            required: false,
                            message: "Please enter valid Address line 2 !",
                          },
                        ]}
                      >
                        <Input
                          readOnly={this.state.is_disabled_address_fields}
                        />
                      </Form.Item>
                      {shipcityView || (
                        <>
                          <Form.Item
                            className={commonStyle["w-100"]}
                            name="shipping_address_city"
                            label="Shipping Address City"
                            rules={[
                              {
                                type: "string",
                                required: true,
                                message: "Please enter valid City !",
                              },
                            ]}
                          >
                            <AutoComplete
                              id="chrome-off"
                              onSelect={this.handleCityShippingSelect}
                              // onBlur={this.handleCityBlur}
                            >
                              {this.state.cityList.map(
                                (v: any, idx: number) => {
                                  return (
                                    <AutoComplete.Option
                                      key={v.id}
                                      value={`${v.id}`}
                                    >
                                      <div>
                                        <span>{v.city}</span>{" "}
                                        <span
                                          style={{
                                            fontSize: 10,
                                            color: "var(--primary-color)",
                                          }}
                                        >
                                          {v.state}, {v.country}
                                        </span>
                                      </div>
                                    </AutoComplete.Option>
                                  );
                                }
                              )}
                            </AutoComplete>
                          </Form.Item>
                        </>
                      )}

                      <Row gutter={{ md: 8, lg: 16 }}>
                        <Col sm={24} lg={12}>
                          {shipState || (
                            <Form.Item
                              className={commonStyle["w-100"]}
                              name="shipping_address_state"
                              label="Shipping Address State"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter valid State!",
                                },
                              ]}
                            >
                              <Input autoComplete="off" />
                            </Form.Item>
                          )}
                        </Col>
                        <Col sm={24} lg={12}>
                          {shipcountryView || (
                            <Form.Item
                              className={commonStyle["w-100"]}
                              name="shipping_address_country"
                              label="Shipping Address Country"
                              rules={[
                                {
                                  type: "string",
                                  required: true,
                                  message: "Please enter valid Country !",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          )}
                        </Col>
                      </Row>

                      <Form.Item
                        className={commonStyle["w-100"]}
                        name="shipping_address_pincode"
                        label="Shipping Address Zipcode"
                        rules={[
                          {
                            type: "string",
                            required: !this.state.is_disabled_address_fields
                              ? true
                              : false,
                            // pattern: /^[1-9]{1}[0-9 ]{3,}$/,
                            pattern: /^[1-9,a-z,A-Z]{1}[0-9,a-z,A-Z]{3,}$/,

                            message: "Please enter valid Zipcode !",
                          },
                        ]}
                      >
                        <Input
                          minLength={4}
                          maxLength={7}
                          readOnly={this.state.is_disabled_address_fields}
                        />
                      </Form.Item>
                    </>
                  ) : null}
                </Col>
              </Row>
            </Form>
          </div>
        </Spin>
      </IMTContent>
    );
  }
}
export default connector(VendorDetailsPage);
