/**
 * General Drawer for Level 3 Compliance View
 * Please create specalized components to display different types of compliance
 */
import React, { Component } from "react";
import {
  Drawer,
  Table,
  Typography,
  notification,
  Button,
  Space,
  Tooltip,
  Modal,
  Input,
  Dropdown,
  Menu,
  Spin,
  Skeleton,
} from "antd";
import { DrawerProps } from "antd/lib/drawer";
import Currency from "~/component/Currency";
import { APIState, fetchUtil } from "~/api/common";
import { format } from "date-fns";
import { formatNumber, FORMAT_DATE } from "~/lib";
import { ColumnsType } from "antd/lib/table";
import JsonDownload from "~/component/JsonDownloadPreview";
import {
  VerticalAlignTopOutlined,
  MoreOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
  PlusCircleOutlined,
  BugOutlined,
  StopOutlined,
  /* CheckCircleTwoTone, */
  CheckCircleOutlined,
  /* FastForwardOutlined, */
} from "@ant-design/icons";
import SummaryTags from "~/component/SummaryTags";
import {
  CompliancePaymentStatus,
  generateOTP,
  verifyOTP,
  reconciliation,
  // getExpTime,
  checkGstr1FillingStatus,
  reconciliationOutput,
  moveToNextTaxPeriod,
  markAsIneligible,
  markAsEligible,
} from "~/api/compliance";
import { fetchBlob } from "~/api/common";
import { withRouter, RouteComponentProps } from "react-router";
import commonPaneStyle from "../common-tab-pane.module.css";
import ComplianceDetailsModal from "../modal/ComplianceDetailsModal";
import ConflictModal from "~/component/ConflictModal";
import CompliancePayIcon from "~/component/CompliancePayIcon";
import { RenderedCell } from "rc-table/lib/interface";
import { quaterFind } from "~/utils";
import { registerEvent } from "~/analytics";
import OtpForm from "~/component/OtpForm";
import { AdminDetail } from "~/api/profile";
import { Switch } from "antd";
import { action } from "~/app/MainApp/store";

import * as ga from "~/contants/gaConstants";
import IndicatorTag from "~/component/IndicatorTag";
import texts from "~/contants/texts";
import { connect } from "react-redux";
import VanillaButton from "~/component/VanillaButton";

interface Props extends RouteComponentProps {
  doNotUseMe?: never;
}

interface Props {
  isOpen: boolean;
  onClose: DrawerProps["onClose"];
  onPayOpen: (levelTwoClickedData: any) => void;
  levelTwoClickedData: any;
  dispatch: (action: any) => void;
}

type GSTRowData = {
  _level: number;
  _parent_type: string;
  type: string;
  log_id: number;
  entity: string;
  eligible_for_calculation: boolean;
  tax_amount: number;
  cgst: number;
  sgst: number;
  igst: number;
  cess?: number;
  cgst_sgst?: any;
  payment_status: CompliancePaymentStatus;
  children?: Array<GSTRowData>;
  details?: Array<any>;
  reconciliation_status_description: string;
  reconciliation_status: boolean;
  expense_reconciliation: boolean;
  invoice_reconciliation: boolean;
  compliance_list: any;
  is_reconciled: boolean;
  reconcile_status: string;
  conflicts: number;
  taxable_amount?: number;
  // dueDate: Date;
};

type SchemaType = {
  label: string;
  key: string;
  type: string;
  extra: null;
};

interface State {
  downloadModalVisible: boolean;
  modalKeyObject: any;
  exportModalOn: boolean;
  jsonDataShow: string | ArrayBuffer | null;
  filename: string;
  rowData: Array<GSTRowData>;
  columnsDef: ColumnsType<GSTRowData>;
  apiState: APIState;
  detailsModalVisible: boolean;
  refreshModalVisible: boolean;
  /* verifyOtpModalVisible: boolean; */
  loading: boolean;
  reconciled: boolean;
  invoiceReconciled: boolean;
  refereshLoading: boolean;
  showGenerateOTP: boolean;
  refereshLoadingOutput: boolean;
  emableGstr1RefreshButton: boolean;
  gstr1FinancialYear: string;
  gstr1TaxPeriod: string;
  otp: any;
  clickedRowData: Partial<GSTRowData>;
  gstUserName: string;
  verificationStage: any;
  isRpuDownload: boolean;
  conflictModalVisible: boolean;
  conflicts: number;
  otpVerifyMsg: any;
  loadingForVerify: boolean;
  otpVerifyModalVisible: boolean;
  mcaData: any;
  inputTaxSum: any;
  creditNoteSum: any;
  debitNoteSum: any;
  outputTaxSum: any;
  tcsCredit: any;
  cnFromSupplier: any;
  dnFromSupplier: any;
  totalPaybleSum: any;
  isPertialOrNoMatchAvailable: any;
  selectedDownType: string;
  reconStatusModal: any;
  activeRole: number;
  summarize_b2cs: boolean;
  isTableLoading: boolean;
}

const { modal } = action;

/**
 * WARNING: this mutates the param data
 * @param data
 * @param level
 */
function addLevel(
  data: Array<GSTRowData>,
  level: number = 0,
  parent_type: string = ""
) {
  for (const row of data) {
    if (!row._level) {
      row._level = level;
      row._parent_type = parent_type;
    }
    if (row.children?.length) {
      row.children = addLevel(row.children, level + 1, row.type);
    }
  }
  return data;
}

const DEFAULT_CELL_RENDERER = (value: any, row: any, index: any) =>
  value || "-";

const OVERRIDDEN_TYPES: any = {
  cgst: "currency",
  sgst: "currency",
  igst: "currency",
  tax_amount: "currency",
};

const ALIGNMENT_CONFIG: any = {
  currency: "right",
  number: "right",
};

class ComplianceDrawer extends Component<Props, State> {
  state: State = {
    downloadModalVisible: false,
    modalKeyObject: {},
    exportModalOn: false,
    jsonDataShow: "",
    filename: "",
    rowData: [],
    columnsDef: [],
    apiState: "loading",
    detailsModalVisible: false,
    refreshModalVisible: false,
    /* verifyOtpModalVisible: false, */
    loading: false,
    refereshLoadingOutput: false,
    showGenerateOTP: false,
    emableGstr1RefreshButton: false,
    gstr1FinancialYear: "",
    gstr1TaxPeriod: "",
    reconciled: false,

    invoiceReconciled: false,
    refereshLoading: false,
    otp: null,
    clickedRowData: { details: [], taxable_amount: 0 },
    gstUserName: "",
    verificationStage: "",
    isRpuDownload: false,
    conflictModalVisible: false,
    conflicts: 0,
    otpVerifyMsg: "",
    loadingForVerify: false,
    otpVerifyModalVisible: false,
    mcaData: {},
    inputTaxSum: 0,
    creditNoteSum: 0,
    debitNoteSum: 0,
    outputTaxSum: 0,
    tcsCredit: 0,
    cnFromSupplier: 0,
    dnFromSupplier: 0,
    totalPaybleSum: 0,
    selectedDownType: "",
    isPertialOrNoMatchAvailable: false,
    reconStatusModal: null,
    activeRole: 0,
    summarize_b2cs: true,
    isTableLoading: false,
  };

  async componentDidMount() {
    const userDataJSON = localStorage.getItem("imt__user") ?? "";
    if (userDataJSON) {
      this.setState({ activeRole: JSON.parse(userDataJSON)?.activeRole });
    }
    const userData: AdminDetail = JSON.parse(userDataJSON);

    this.setState({
      gstUserName: userData.gst_user_name,
    });
    setTimeout(() => {
      if (this.props.levelTwoClickedData.group_name! !== "MCA") {
        this.loadData();
      }
      if (this.props.levelTwoClickedData.group_name! === "TDS") {
        this.isDownLoadRPUfile();
      }
      if (this.props.levelTwoClickedData.group_name! === "MCA") {
        this.loadMca();
      }

      if (this.props.levelTwoClickedData.compliance_name === "GSTR 3B") {
        // this.checkGstr1FillingStatus();
        // this.handlegstinFrom();
      }
    }, 500);
    if (this.props.levelTwoClickedData.compliance_name === "GSTR 3B") {
      // this.checkGstr1FillingStatus();
      // this.handlegstinFrom();
    }
  }
  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.summarize_b2cs !== prevState.summarize_b2cs) {
      this.loadData();
    }
    if (this.state.rowData !== prevState.rowData) {
      this.setState({ isTableLoading: false });
    }
  }
  loadMca = async () => {
    this.setState({ apiState: "idle" });
    const { ok, message, json } = await fetchUtil(
      "POST",
      "/fetch_compliance_mca_level_three",
      {
        compliance_master_id: this.props.levelTwoClickedData
          .compliance_mapping_id,
      }
    );
    if (!ok) {
      notification.error({ message });
    } else {
      if (json) {
        this.setState({
          mcaData: json[0],
        });
      }
      this.setState({ apiState: "success" });
    }
  };
  onChangeSummarize = () => {
    this.setState({ isTableLoading: true });
    this.setState({ summarize_b2cs: !this.state.summarize_b2cs });
  };
  isDownLoadRPUfile = async () => {
    const { ok, message, json } = await fetchUtil(
      "POST",
      "/is_rpu_file_downloadable",
      {
        log_id: this.props.levelTwoClickedData.log_id,
        group_name: this.props.levelTwoClickedData.group_name!,
      }
    );
    if (!ok) {
      notification.error({ message });
      this.setState({
        isRpuDownload: false,
      });
    } else {
      if (json) {
        this.setState({
          isRpuDownload: true,
        });
      } else {
        this.setState({
          isRpuDownload: false,
        });
      }
    }
  };

  handlegstinFrom = async () => {
    try {
      const {
        ok,
        json: stageAsString,
        message /* , schema */,
      } = await fetchUtil("GET", "/reconciliation/get_otp_verification_stage");
      if (ok) {
        this.setState({
          verificationStage: stageAsString,
        });
      } else {
        notification.error({ message });
      }
    } catch (error) {
      notification.error({ message: (error as any)?.message });
    }
  };

  handleMoveToNextTaxPeriod = async (info: any, row: GSTRowData) => {
    info.domEvent.stopPropagation();
    info.domEvent.preventDefault();
    if (row.details) {
      const rowDetailsData = row.details.filter(
        (item: any) => item.label === "Compliance Id"
      );

      let instrId = [];
      if (row.type === "Invoice") {
        instrId = row.details.filter(
          (item: any) => item.label === "Invoice Id"
        );
      }
      if (row.type === "Credit Note") {
        instrId = row.details.filter(
          (item: any) => item.label === "Credit Note Id"
        );
      }
      if (row.type === "Debit Note") {
        instrId = row.details.filter(
          (item: any) => item.label === "Debit Note Id"
        );
      }
      try {
        const moveToNextTaxPeriodRes = await moveToNextTaxPeriod(
          rowDetailsData[0].value,
          row.type,
          instrId[0]?.value || null
        );
        if (moveToNextTaxPeriodRes.ok) {
          notification.success({ message: "Successfully Moved" });
          this.loadData();
        } else {
          notification.error({
            message: moveToNextTaxPeriodRes.message,
          });
        }
      } catch (err) {
        this.setState({ loading: false });
        console.error(err);
        notification.error({ message: "Something went wrong" });
      }
    }
  };

  handleMarkAsIneligible = async (info: any, row: GSTRowData) => {
    info.domEvent.stopPropagation();
    info.domEvent.preventDefault();
    if (row.details) {
      const rowDetailsData = row.details.filter(
        (item: any) => item.label === "Compliance Id"
      );

      try {
        const markAsIneligibleRes = await markAsIneligible(
          rowDetailsData[0].value
        );
        if (markAsIneligibleRes.ok) {
          notification.success({
            message: "Successfully marked as ineligible",
          });
          this.loadData();
        } else {
          notification.error({
            message: markAsIneligibleRes.message,
          });
        }
      } catch (err) {
        this.setState({ loading: false });
        notification.error({ message: "Something went wrong" });
      }
    }
  };

  handleMarkAsEligible = async (info: any, row: GSTRowData) => {
    info.domEvent.stopPropagation();
    info.domEvent.preventDefault();
    if (row.details) {
      const rowDetailsData = row.details.filter(
        (item: any) => item.label === "Compliance Id"
      );

      try {
        const markAseligibleRes = await markAsEligible(rowDetailsData[0].value);
        if (markAseligibleRes.ok) {
          notification.success({
            message: "Successfully marked as eligible",
          });
          this.loadData();
        } else {
          notification.error({
            message: markAseligibleRes.message,
          });
        }
      } catch (err) {
        this.setState({ loading: false });
        notification.error({ message: "Something went wrong" });
      }
    }
  };

  checkGstr1FillingStatus = async () => {
    try {
      const res = await checkGstr1FillingStatus(
        this.props.levelTwoClickedData.financial_year,
        this.props.levelTwoClickedData.for_period
      );
      if (res.ok) {
        // @ts-ignore
        if (res.json.emableGstr1RefreshButton) {
          this.setState({
            emableGstr1RefreshButton: true,
            // @ts-ignore
            gstr1FinancialYear: res.json.taxPeriod.financial_year,
            // @ts-ignore
            gstr1TaxPeriod: res.json.taxPeriod.tax_period,
          });
        } else {
          this.setState({ emableGstr1RefreshButton: false });
          notification.error({ message: "GSTR1 filling status pending" });
        }
      }
    } catch (err) {
      notification.error({ message: "Something went wrong" });
    }
  };

  loadData = async () => {
    const { ok, message, json, schema, total } = await fetchUtil(
      "POST",
      "/fetch_compliances_level_three",
      {
        log_id: this.props.levelTwoClickedData.log_id,
        due_date: this.props.levelTwoClickedData.due_date,
        group_name: this.props.levelTwoClickedData.group_name!,
        is_summarize: this.state.summarize_b2cs,
      }
    );
    if (!ok) {
      notification.error({ message });
      this.setState({
        apiState: "error",
        rowData: [],
      });
    } else {
      if (!json) {
        this.setState({ rowData: [], apiState: "success" });
        return;
      }

      const data = addLevel(json);

      this.setState({
        conflicts: total.conflicts,
      });

      this.setState({ reconciled: total.reconciliation_status });
      this.setColumnDef(schema);
      this.setState({ rowData: data, apiState: "success" }, () => {
        this.state.rowData.forEach((el) => {
          // if (
          //  (el.type === "GST input (ITC)" ||
          //   el.type==="Tax collected at source (TCS)")||
          //   el.type === "Outward supplies & reverse charges"
          // ) {
          if (el.type === "GST input (ITC)") {
            const inputTax = +el.cgst_sgst! + +el.igst! + +el.cess!;
            this.setState({
              inputTaxSum: inputTax,
            });
          }
          if (el.type === "Credit notes") {
            const cnTax = +el.cgst_sgst! + +el.igst! + +el.cess!;
            this.setState({
              creditNoteSum: cnTax,
            });
          }
          if (el.type === "Debit notes") {
            const dnTax = +el.cgst_sgst! + +el.igst! + +el.cess!;
            this.setState({
              debitNoteSum: dnTax,
            });
          }
          if (el.type === "Outward supplies & reverse charges") {
            const outputTax = +el.cgst_sgst! + +el.igst! + +el.cess!;
            this.setState({
              outputTaxSum: outputTax,
            });
          }
          if (el.type === "Credit note by supplier") {
            const cnSTax = +el.cgst_sgst! + +el.igst! + +el.cess!;
            this.setState({
              cnFromSupplier: cnSTax,
            });
          }
          if (el.type === "Debit note by supplier") {
            const dnSTax = +el.cgst_sgst! + +el.igst! + +el.cess!;
            this.setState({
              dnFromSupplier: dnSTax,
            });
          }
          if (el.type === "Tax collected at source (TCS)") {
            const tcs = +el.cgst_sgst! + +el.igst! + +el.cess!;
            this.setState({
              tcsCredit: tcs,
            });
          }
          // }
        });
      });
      let isPertialOrNoMatchAvailable = false;
      data.forEach((el: any) => {
        el.children?.forEach((item: any) => {
          if (
            item.reconciliation_status_description === "NOT_MATCH" ||
            item.reconciliation_status_description === "PERTIAL_MATCH"
          ) {
            isPertialOrNoMatchAvailable = true;
          }
        });
      });
      this.setState({
        isPertialOrNoMatchAvailable,
      });
    }
  };

  handleSyncNow = () => {
    if (this.state.reconStatusModal) {
      this.state.reconStatusModal.destroy();
      this.setState({ reconStatusModal: null });
    }
    this.props.dispatch(modal.showSourceSelectionModal());
  };

  handleReconcile = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.complianceDrawer161
    );
    this.setState({ refereshLoading: true });
    this.handlegstinFrom().then(() => {
      // if (this.state.verificationStage === "Request OTP") {
      this.onModalVisibleChange(true);
      this.setState({
        refereshLoading: false,
      });

      // }
      /* else {
          this.startRecocile();
        } */
    });
  };

  startRecocile = async () => {
    this.setState({ refereshLoading: true });
    const { ok, message, json } = await fetchUtil(
      "POST",
      "/reconciliation/trigger_gstr2a",
      {
        tax_period: this.props.levelTwoClickedData?.for_period,
        financial_year: this.props.levelTwoClickedData?.financial_year,
        due_date: this.props.levelTwoClickedData.due_date,
      }
    );
    this.setState({ refereshLoading: false });
    this.onModalVisibleChange(false);
    if (!ok) {
      notification.error({ message });
    } else {
      const data = json[0];
      if (data.stat.partialMatchLen >= 1 || data.stat.unmatchLen >= 1) {
        this.setState({
          isPertialOrNoMatchAvailable: true,
        });
      }
      const { info } = Modal;
      const reconStatusModal = info({
        title: `Reconciliation status`,
        // icon: <ExclamationCircleOutlined />,
        content: (
          <div style={{ marginTop: "30px", gap: "10px" }}>
            <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <CheckCircleOutlined
                style={{
                  color: "#219653",
                  fontSize: "24px",
                  marginRight: "6px",
                }}
              />{" "}
              <span>{data.stat.matchLen} Matched</span>
            </p>
            <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <PlusCircleOutlined
                style={{
                  color: "#2F80ED",
                  fontSize: "24px",
                  marginRight: "6px",
                }}
              />
              <span>
                {data.stat.newRecsLen} New
                {data.stat.newRecsLen > 0 && this.state.activeRole !== 3 ? (
                  <VanillaButton
                    onClick={this.handleSyncNow}
                    text=" - Run GST sync to import"
                  />
                ) : null}
              </span>
            </p>
            <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <BugOutlined
                style={{
                  color: "#ebab34",
                  fontSize: "24px",
                  marginRight: "6px",
                }}
              />
              <span>{data.stat.partialMatchLen} Partial match / conflict</span>
            </p>
            <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <StopOutlined
                style={{
                  color: "#EB5757",
                  fontSize: "24px",
                  marginRight: "6px",
                }}
              />
              <span>{data.stat.unmatchLen} Not matched</span>
            </p>
          </div>
        ),
      });
      this.setState({ reconStatusModal });
      notification.success({ message });
      this.loadData();
    }
  };

  setColumnDef = (data: Array<SchemaType>) => {
    const columnsDef = data.map((colSchema: SchemaType) => {
      const title = colSchema.label;
      const dataIndex = colSchema.key;
      const type = OVERRIDDEN_TYPES[dataIndex] || colSchema.type;
      const align = ALIGNMENT_CONFIG[type] || "left";
      // let colSpan = 5;
      let widthVal = null;
      let render = DEFAULT_CELL_RENDERER;

      if (type === "currency") {
        // widthVal = "51%";
        render = (curr: string | number, row: any) => {
          const result: RenderedCell<any> = {
            props: {},
            children: (
              <Currency>
                {row._parent_type === "GST input (ITC)" &&
                !row.eligible_for_calculation
                  ? "0"
                  : curr}
              </Currency>
            ),
          };
          if (row.emptyRow) {
            result.props!.colSpan = 0;
          }
          return result;
        };
      } else if (type === "date") {
        // widthVal = "51%";
        render = (dateStr: string, row: any) => {
          const result: RenderedCell<any> = {
            props: {},
            children: dateStr ? format(new Date(dateStr), FORMAT_DATE) : "-",
          };
          if (row.emptyRow) {
            result.props!.colSpan = 0;
          }
          return result;
        };
      } else if (dataIndex === "entity") {
        widthVal = "31%";
        render = (entity: string, row: any) => {
          const result: RenderedCell<any> = {
            props: {},
            children: (
              <Tooltip title={entity} color={"#575757"}>
                <div className={commonPaneStyle.typeParentDiv}>
                  {!row?.emptyRow ? (
                    <div className={commonPaneStyle.typeDivWidth}>
                      {row.gstin ? row.gstin : "GST unregistered"}
                    </div>
                  ) : null}

                  {
                    <div className={commonPaneStyle.gstin}>
                      {row.state_name ? `${row.state_name}` : null}{" "}
                      {row.state_name && entity ? "| " : null}
                      {entity?.length > 20
                        ? `${entity.substr(0, 20)}...`
                        : entity}
                    </div>
                  }
                </div>
              </Tooltip>
            ),
          };
          if (
            row.emptyRow ||
            (row._level === 0 &&
              ["GSTR 3B", texts.gstr1ff].includes(
                this.props.levelTwoClickedData.compliance_name
              ))
          ) {
            result.props!.colSpan = 0;
          }

          return result;
        };
      } else if (dataIndex === "log_id") {
        render = (log_id: number, row: any) => {
          const result: RenderedCell<any> = {
            props: {},
            children: (
              <Typography.Link
                href={`/app/${row.type}/${row.log_id}`}
                onClick={this.handleEntityClick}
              >
                {row.log_id}
              </Typography.Link>
            ),
          };
          if (row.emptyRow) {
            result.props!.colSpan = 0;
          }
          return result;
        };
      }
      if (dataIndex === "type") {
        widthVal = "14%";
        // tslint:disable-next-line: no-shadowed-variable
        render = (type: string, row: any) => {
          // let receiptDate = format(new Date(row.receipt_date), "MM/dd/yyyy");
          const result: RenderedCell<any> = {
            props: {},
            children: (
              <div className={commonPaneStyle.typeParentDiv}>
                <div className={commonPaneStyle.typeDivWidth}>
                  <div
                    style={{
                      textOverflow: "ellipsis",

                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {row.receipt_date
                      ? format(new Date(row.receipt_date), "dd/MM/yyyy")
                      : type
                      ? type
                      : "Tax Rate"}
                    <div
                      style={{
                        textOverflow: "ellipsis",
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      {!row.eligible_for_calculation &&
                      row._parent_type === "GST input (ITC)" ? (
                        <IndicatorTag
                          tagSet={[
                            {
                              tagName: "IE",
                              color: "#bdbdbd",
                              title: "Ineligible",
                            },
                          ]}
                        />
                      ) : row.p_debit_note_number ? (
                        <IndicatorTag
                          tagSet={[
                            {
                              tagName: "CN",
                              color: "#287DF0",
                              title: "Credit note",
                            },
                          ]}
                        />
                      ) : row.s_debit_note_number ? (
                        <IndicatorTag
                          tagSet={[
                            {
                              tagName: "DN",
                              color: "#287DF0",
                              title: "Credit note",
                            },
                          ]}
                        />
                      ) : row.credit_note_number ? (
                        <IndicatorTag
                          tagSet={[
                            {
                              tagName: "CN",
                              color: "#287DF0",
                              title: "Credit note",
                            },
                          ]}
                        />
                      ) : row.debit_note_number ? (
                        <IndicatorTag
                          tagSet={[
                            {
                              tagName: "DN",
                              color: "#287DF0",
                              title: "Credit note",
                            },
                          ]}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className={commonPaneStyle.gstin}>
                    {row.invoice_no
                      ? row.invoice_no
                      : row.p_debit_note_number
                      ? `${row.p_debit_note_number}`
                      : row.s_debit_note_number
                      ? ` ${row.s_debit_note_number}`
                      : row.credit_note_number
                      ? `${row.credit_note_number}`
                      : row.debit_note_number
                      ? `${row.debit_note_number}`
                      : row.gst_rate
                      ? `${row.gst_rate}`
                      : ""}
                  </div>
                </div>
              </div>
            ),
          };
          if (row.emptyRow) {
            result.props!.colSpan = 0;
          } else if (
            row._level === 0 &&
            ["GSTR 3B", texts.gstr1ff].includes(
              this.props.levelTwoClickedData.compliance_name
            )
          ) {
            result.props!.colSpan = 2;
          }
          return result;
        };
      }
      if (title === "Employee Name") {
        render = (employee_name: string, row: any) => {
          return (
            <div className={commonPaneStyle.typeParentDiv}>
              <span className={commonPaneStyle.typeDivWidth}>
                {employee_name}
              </span>
              {row.employee_code ? (
                <div className={commonPaneStyle.gstin}>{row.employee_code}</div>
              ) : null}
            </div>
          );
        };
      }
      if (widthVal) {
        return { title, dataIndex, align, width: widthVal, render };
      } else {
        return { title, dataIndex, align, render };
      }
    });
    // Add a special column to show message, spanning all columns
    // Add to the beginning of the columnsDef array (prepend)

    columnsDef.push({
      title: "",
      align: "center",
      dataIndex: "emptyRow",
      render: (isEmptyRow: boolean) => {
        const result: RenderedCell<any> = {
          props: {
            colSpan: isEmptyRow ? data.length : 0,
          },
          children: isEmptyRow ? "No data exists!" : "",
        };
        return result;
      },
    });
    columnsDef.push({
      align: "left",
      dataIndex: "",
      title: "",
      width: "10px",
      render: (employee_name: string, row: any) => {
        const menuItemNotAvailable =
          row._parent_type === "Tax collected at source (TCS)";

        if (menuItemNotAvailable) {
          return null;
        }
        if (this.state.summarize_b2cs) {
          const b2csMenuItemNotAvailable =
            row._parent_type === "B2C (Small) Invoices";
          const creditNoteUnItemNotAvailable =
            row._parent_type === "Credit notes (Unregistered)";
          const debitNoteUnItemNotAvailable =
            row._parent_type === "Debit notes (Unregistered)";

          if (
            b2csMenuItemNotAvailable ||
            creditNoteUnItemNotAvailable ||
            debitNoteUnItemNotAvailable
          ) {
            return null;
          }
        }
        const menu = (
          <Menu>
            {row._parent_type !== "Tax collected at source (TCS)" ? (
              <Menu.Item
                key="0"
                // tslint:disable-next-line: jsx-no-lambda
                onClick={(info: any) =>
                  this.handleMoveToNextTaxPeriod(info, row)
                }
              >
                Move to next tax period
              </Menu.Item>
            ) : null}

            {row._parent_type === "GST input (ITC)" ? (
              row.eligible_for_calculation ? (
                <>
                  <Menu.Item
                    key="1"
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={(info) => this.handleMarkAsIneligible(info, row)}
                  >
                    Mark as Ineligible
                  </Menu.Item>
                </>
              ) : null
            ) : null}

            {row._parent_type === "GST input (ITC)" &&
            row.eligible_for_calculation === false ? (
              <Menu.Item
                key="1"
                // tslint:disable-next-line: jsx-no-lambda
                onClick={(info) => this.handleMarkAsEligible(info, row)}
              >
                Mark as eligible
              </Menu.Item>
            ) : null}
          </Menu>
        );
        return (
          <div className={commonPaneStyle.actionCompliance}>
            {(row._level === 0 &&
              !["GSTR 3B", texts.gstr1ff].includes(
                this.props.levelTwoClickedData.compliance_name
              )) ||
            (row._level !== 0 &&
              ["GSTR 3B", texts.gstr1ff].includes(
                this.props.levelTwoClickedData.compliance_name
              )) ? (
              !row?.emptyRow ? (
                <Space align="center">
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    // tslint:disable-next-line: jsx-no-lambda
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                  >
                    <span
                      className="ant-dropdown-link"
                      // tslint:disable-next-line: jsx-no-lambda
                      onClick={(e) => e.stopPropagation()}
                    >
                      <MoreOutlined />
                    </span>
                  </Dropdown>
                </Space>
              ) : null
            ) : null}
          </div>
        );
      },
    });
    this.setState({
      columnsDef,
    });
  };

  handleGstUserNameChang = (e: any) => {
    this.setState({ gstUserName: e.target.value });
  };

  handleOTPChange = (value: any) => {
    this.setState({ otp: value });
  };

  handleEntityClick = (evt: React.SyntheticEvent<HTMLAnchorElement>) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.complianceDrawer162
    );
    const pathname = evt.currentTarget.pathname;
    evt.preventDefault();
    evt.stopPropagation();
    this.props.history.push(pathname);
  };

  doOpenPayModal = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.complianceDrawer163
    );
    if (this.props.levelTwoClickedData?.payment_status !== "complete") {
      this.props.onPayOpen(this.props.levelTwoClickedData);
    }
  };

  handleConflictModal = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.complianceDrawer164
    );
    this.setState({
      conflictModalVisible: true,
    });
  };

  conflictModlVisibleChange = (visible: boolean) => {
    this.setState(
      {
        conflictModalVisible: visible,
      },
      () => {
        this.loadData();
      }
    );
  };

  doOpenDetailsModal = () => {
    this.setState({
      detailsModalVisible: true,
    });
  };

  doCloseDetailsModal = () => {
    this.setState({
      detailsModalVisible: false,
    });
  };

  onModalVisibleChange = (val: boolean) => {
    this.setState({
      otpVerifyModalVisible: val,
    });
  };

  handleRPUFileDownload = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.complianceDrawer165
    );
    const rpu_24q_url = "/24q_rpu_file";
    const rpu_26q_url = "/26q_rpu_file";
    try {
      await fetchBlob(
        this.props.levelTwoClickedData?.compliance_name === "TDS - 24Q Filing"
          ? `${rpu_24q_url}`
          : `${rpu_26q_url}`,

        {
          log_id: this.props.levelTwoClickedData.log_id,
        }
      ).then((res: any) => {
        if (res.blob) {
          res.blob.then((val: any) => {
            const filename = res.header
              .get("content-disposition")
              .split(";")
              .find((n: any) => n.includes("filename="))
              .replace("filename=", "")
              .trim();
            const modifyFileName = filename.slice(1, -1);
            const contentType = res.header.get("content-type");
            const blob = new Blob([val], { type: contentType });
            saveAs(blob, modifyFileName);
          });
        } else {
          notification.info({
            message: res.message,
          });
        }
      });
    } catch (err) {
      notification.error({
        message: "Error",
      });
    }
  };

  handleGstr3bDownload = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.complianceDrawer166
    );
    try {
      const url =
        this.props.levelTwoClickedData?.compliance_name === "GSTR 3B"
          ? "/gstr3b_download_json"
          : this.props.levelTwoClickedData?.compliance_name === texts.gstr1ff
          ? "/gstr1_export"
          : null;
      await fetchBlob(`${url}`, {
        log_id: this.props.levelTwoClickedData.log_id,
        group_name: this.props.levelTwoClickedData.group_name,
      }).then((res: any) => {
        if (res.blob) {
          res.blob.then((val: any) => {
            const filename = res.header
              .get("content-disposition")
              .split(";")
              .find((n: any) => n.includes("filename="))
              .replace("filename=", "")
              .trim();
            const modifyFileName = filename.slice(1, -1);
            const blob = new Blob([val], { type: "application/json" });
            saveAs(blob, modifyFileName);
          });
        } else {
          notification.info({
            message: res.message,
          });
        }
      });
    } catch (err) {
      notification.error({
        message: "Error",
      });
    }
  };

  doOpenRefreshModal = () => {
    this.setState({
      refreshModalVisible: true,
    });
  };

  doCloseRefreshModal = () => {
    this.setState({
      refreshModalVisible: false,
    });
  };

  /* doOpenVerifyOTPModal = () => {
      registerEvent(
        ga.EVENT_CATEGORY_BUTTON_CLICK,
        ga.EVENT_CLICK,
        ga.events.complianceDrawer167
      );
  
      this.setState({
        verifyOtpModalVisible: true,
      });
    }; */

  /* doCloseVerifyOTPModal = () => {
      registerEvent(
        ga.EVENT_CATEGORY_BUTTON_CLICK,
        ga.EVENT_CLICK,
        ga.events.complianceDrawer168
      );
      this.setState({
        verifyOtpModalVisible: false,
      });
    }; */

  handleExport = (e: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.complianceDrawer169
    );

    if (this.props.levelTwoClickedData?.compliance_name === "GSTR 3B") {
      if (this.state.isPertialOrNoMatchAvailable) {
        if (e.key === "json") {
          Modal.confirm({
            // title: "Confirm",
            icon: <ExclamationCircleOutlined />,
            content:
              "There are still some unreconciled entries. Are you sure you want to download the JSON for filing ?",
            okText: "ok",
            cancelText: "cancel",
            onOk: () => {
              console.warn(e);
              this.handleExportDataJson(e);
            },
            onCancel: () => {
              this.setState(
                {
                  isPertialOrNoMatchAvailable: false,
                },
                () => {
                  this.setState({
                    isPertialOrNoMatchAvailable: true,
                  });
                }
              );
            },
          });
        } else {
          this.handleExportDataJson(e);
        }
      } else {
        if (this.state.rowData.length > 0) {
          const hasNotMatched = this.state.rowData[0].children?.some(
            (it) => it.reconciliation_status_description === "NOT_MATCH"
          );
          if (hasNotMatched) {
            Modal.confirm({
              // title: "Confirm",
              icon: <ExclamationCircleOutlined />,
              content:
                "There are still some unmatched entries. Are you sure you want to download the JSON for filing ?",
              okText: "ok",
              cancelText: "cancel",
              onOk: () => {
                this.handleExportDataJson(e);
              },
              onCancel: () => {
                // do nothing
              },
            });
          }
        }
        // AddModalHere
        // this.setState({ exportModalOn: true });
        // if (this.state.exportModalOn) {
        //   this.setState({ exportModalOn: false });
        // }
        this.handleExportDataJson(e);
      }
    } else if (
      this.props.levelTwoClickedData?.compliance_name === "GSTR1 / IFF"
    ) {
      {
        this.setState({ downloadModalVisible: true, modalKeyObject: e });
      }
    } else {
      this.handleExportDataJson(e);
    }
  };
  handleExportDataJson = (e: any, iff?: boolean) => {
    this.setState(
      {
        selectedDownType: e.key,
        isPertialOrNoMatchAvailable: false,
      },

      async () => {
        try {
          const url =
            this.props.levelTwoClickedData?.compliance_name === "GSTR 3B"
              ? "/gstr3b_download_json"
              : this.props.levelTwoClickedData?.compliance_name ===
                texts.gstr1ff
              ? "/gstr1_export"
              : null;

          if (
            (this.props.levelTwoClickedData?.compliance_name === "GSTR 3B" ||
              this.props.levelTwoClickedData?.compliance_name ===
                texts.gstr1ff) &&
            this.state.selectedDownType === "json"
          ) {
            this.setState({ exportModalOn: true });
            await fetchBlob(
              `${url}`,
              this.props.levelTwoClickedData?.compliance_name === texts.gstr1ff
                ? {
                    log_id: this.props.levelTwoClickedData.log_id,
                    group_name: this.props.levelTwoClickedData.group_name,
                    export_format: this.state.selectedDownType.toUpperCase(),
                    is_iff: iff,
                  }
                : {
                    log_id: this.props.levelTwoClickedData.log_id,
                    group_name: this.props.levelTwoClickedData.group_name,
                  }
            ).then((res: any) => {
              if (res.blob) {
                res.blob.then((val: any) => {
                  const filename = res.header
                    .get("content-disposition")
                    .split(";")
                    .find((n: any) => n.includes("filename="))
                    .replace("filename=", "")
                    .trim();
                  const modifyFileName = filename.slice(1, -1);
                  this.setState({ filename: modifyFileName });
                  const blob = new Blob([val], { type: "text/xml" });
                  const myReader = new FileReader();
                  myReader.onload = (event) => {
                    this.setState({
                      jsonDataShow: JSON.parse(myReader.result + ""),
                    });
                  };
                  myReader.readAsText(blob);
                });
              } else {
                notification.info({
                  message: res.message,
                });
              }
            });
          }
          if (
            this.props.levelTwoClickedData?.compliance_name === texts.gstr1ff &&
            this.state.selectedDownType === "excel"
          ) {
            await fetchBlob(
              `${url}`,
              this.props.levelTwoClickedData?.compliance_name === texts.gstr1ff
                ? {
                    log_id: this.props.levelTwoClickedData.log_id,
                    group_name: this.props.levelTwoClickedData.group_name,
                    export_format: this.state.selectedDownType.toUpperCase(),
                    is_iff: iff,
                  }
                : {
                    log_id: this.props.levelTwoClickedData.log_id,
                    group_name: this.props.levelTwoClickedData.group_name,
                  }
            ).then((res: any) => {
              if (res.blob) {
                res.blob.then((val: any) => {
                  const filename = res.header
                    .get("content-disposition")
                    .split(";")
                    .find((n: any) => n.includes("filename="))
                    .replace("filename=", "")
                    .trim();
                  const modifyFileName = filename.slice(1, -1);
                  const blob = new Blob([val], { type: "application/json" });
                  saveAs(blob, `${modifyFileName}.xlsx`);
                });
              } else {
                notification.info({
                  message: res.message,
                });
              }
            });
          }
          if (
            this.props.levelTwoClickedData?.compliance_name === "GSTR 3B" &&
            this.state.selectedDownType === "excel"
          ) {
            await fetchBlob("/gstr3b_excel_export", {
              log_id: this.props.levelTwoClickedData.log_id,
              group_name: this.props.levelTwoClickedData.group_name,
              export_format: this.state.selectedDownType.toUpperCase(),
            }).then((res: any) => {
              if (res.blob) {
                res.blob.then((val: any) => {
                  const filename = res.header
                    .get("content-disposition")
                    .split(";")
                    .find((n: any) => n.includes("filename="))
                    .replace("filename=", "")
                    .trim();
                  const modifyFileName = filename.slice(1, -1);
                  const blob = new Blob([val], { type: "application/json" });
                  saveAs(blob, `${modifyFileName}.xlsx`);
                });
              } else {
                notification.info({
                  message: res.message,
                });
              }
            });
          }
          if (this.state.selectedDownType === "json") {
            this.setState({ exportModalOn: true });
            this.state.rowData.forEach((el: any) => {
              el.children.forEach((item: any) => {
                if (
                  item.reconciliation_status_description === "NOT_MATCH" ||
                  item.reconciliation_status_description === "PERTIAL_MATCH"
                ) {
                  this.setState({
                    isPertialOrNoMatchAvailable: true,
                  });
                }
              });
            });
          }
        } catch (err) {
          console.error(err);
          notification.error({
            message: "Error, failed to download excel file",
          });
        }
      }
    );
    this.setState({ downloadModalVisible: false });
  };
  handleCancel = (e: any) => {
    this.setState({ downloadModalVisible: false });
  };
  renderRowAttrs = (row: GSTRowData, index?: number) => {
    const attr: any = {
      onClick: (e: React.SyntheticEvent) => {
        e.stopPropagation();
        if (row.details !== undefined && row.details!.length !== 0) {
          this.setState({ clickedRowData: row });
          this.doOpenDetailsModal();
        }
      },
      className: commonPaneStyle.levelOneRow,
    };
    if (row.reconciliation_status_description === "MATCH") {
      attr.className = commonPaneStyle.matchColor;
    } else if (row.reconciliation_status_description === "NOT_MATCH") {
      attr.className = commonPaneStyle.notMatchColor;
    } else if (row.reconciliation_status_description === "PARTIAL_MATCH") {
      attr.className = commonPaneStyle.percialColor;
    }
    return attr;
  };

  generateOTP = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.complianceDrawer170
    );
    this.setState({ loading: true });
    try {
      const res = await generateOTP(this.state.gstUserName);
      if (res.ok) {
        notification.success({ message: "OTP sent to your mobile number" });
      } else {
        notification.error({ message: res.message });
      }
      this.doCloseRefreshModal();
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      notification.error({ message: "Something went wrong" });
    }
  };

  verifyOTP = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.complianceDrawer171
    );
    this.setState({ loadingForVerify: true });
    try {
      const otp = parseInt(this.state.otp, 10);
      if (!isNaN(otp)) {
        const verifyOTPRes = await verifyOTP(
          this.state.gstUserName,
          this.state.otp
        );
        if (verifyOTPRes.ok) {
          this.setState({
            loadingForVerify: false,
            //  showGenerateOTP: true,
            // verifyOtpModalVisible:true,
            otpVerifyMsg: "OTP verified",
          });
          notification.success({ message: "OTP verified" });
        } else {
          this.setState({ loadingForVerify: false, showGenerateOTP: true });
          notification.error({ message: verifyOTPRes.message });
        }
        // this.doCloseVerifyOTPModal();
        this.handlegstinFrom();
        this.setState({ gstUserName: "", otp: "" });
      } else {
        this.setState({ loadingForVerify: false });
        notification.error({ message: "OTP must be an integer" });
      }
    } catch (err) {
      this.setState({ loadingForVerify: false });
      notification.error({ message: "Something went wrong" });
    }
  };

  refeshReconciliation = async () => {
    this.setState({ refereshLoading: true });
    try {
      const year = this.props.levelTwoClickedData?.due_date
        ? new Date(this.props.levelTwoClickedData.due_date).getFullYear()
        : null;
      const month = this.props.levelTwoClickedData?.due_date
        ? new Date(this.props.levelTwoClickedData.due_date).getMonth() + 1
        : null;

      if (year && month) {
        const res = await reconciliation(year, month);
        this.loadData();

        if (res.ok) {
          this.setState({ refereshLoading: false });
          notification.success({ message: "Reconciled sucessfully" });
        } else {
          this.setState({ refereshLoading: false });
          notification.error({ message: res.message });
        }
      } else {
        this.setState({ refereshLoading: false });
        notification.error({ message: "Due on required" });
      }
    } catch (err) {
      this.setState({ refereshLoading: false });
      notification.error({ message: "Something went wrong" });
    }
  };

  refeshOutputReconciliation = async () => {
    this.setState({ refereshLoadingOutput: true });
    try {
      const res = await reconciliationOutput(
        this.props.levelTwoClickedData.financial_year,
        this.state.gstr1FinancialYear,
        this.state.gstr1TaxPeriod,
        this.props.levelTwoClickedData.for_period
      );
      this.loadData();

      if (res.ok) {
        this.setState({ refereshLoadingOutput: false });
        notification.success({ message: "Reconciled sucessfully" });
      } else {
        this.setState({ refereshLoadingOutput: false });
        notification.error({ message: res.message });
      }
    } catch (err) {
      this.setState({ refereshLoadingOutput: false });
      notification.error({ message: "Something went wrong" });
    }
  };

  downloadRpuError = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.complianceDrawer172
    );
    notification.error({
      message: ` 
         RPU file can only be downloaded for ${quaterFind(
           this.props.levelTwoClickedData?.for_period
         )} after challan details are added for individual months
        ( ${this.props.levelTwoClickedData?.for_period})
         `,
    });
  };

  render() {
    const { apiState, rowData, columnsDef } = this.state;
    const isLoading = apiState === "loading";
    const menu = (
      <Menu onClick={this.handleExport}>
        <Menu.Item key="json">Json</Menu.Item>
        <Menu.Item key="excel">Excel</Menu.Item>
      </Menu>
    );
    return (
      <>
        <Drawer
          width={"83%"}
          placement="right"
          closable={true}
          closeIcon={
            <VerticalAlignTopOutlined
              style={{ fontSize: "var(--heading-3-size)" }}
              rotate={90}
            />
          }
          onClose={this.props.onClose}
          visible={this.props.isOpen}
          destroyOnClose={true}
        >
          <Space className={commonPaneStyle.drawerHeaderAlign}>
            <Typography.Title level={3}>
              Compliance details (
              {this.props.levelTwoClickedData?.compliance_name})
            </Typography.Title>
            {this.props.levelTwoClickedData.group_name! !== "MCA" ? (
              <Tooltip
                placement="right"
                title={
                  this.props.levelTwoClickedData?.payment_status === "complete"
                    ? "Completed"
                    : "Mark as complete"
                }
              >
                <Button
                  icon={
                    <CompliancePayIcon
                      style={{ marginTop: "2px" }}
                      type={
                        this.props.levelTwoClickedData?.payment_status ===
                        "complete"
                          ? "done"
                          : "pending"
                      }
                    />
                  }
                  onClick={this.doOpenPayModal}
                  type="link"
                >
                  {/* Complete Task */}
                </Button>
              </Tooltip>
            ) : null}

            {this.props.levelTwoClickedData.tax_type === "Filing" &&
              (this.props.levelTwoClickedData?.compliance_name ===
                "TDS - 24Q Filing" ||
                this.props.levelTwoClickedData?.compliance_name ===
                  "TDS - 26Q Filing") &&
              this.state.rowData.length > 0 && (
                <div
                  style={{
                    marginBottom: 20,
                    marginLeft: 2,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="small"
                    style={{ marginLeft: "20%", marginTop: "5px" }}
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={
                      this.state.isRpuDownload
                        ? this.handleRPUFileDownload
                        : this.downloadRpuError
                    }
                  >
                    Download RPU
                  </Button>
                </div>
              )}

            {(this.props.levelTwoClickedData?.compliance_name === "GSTR 3B" ||
              this.props.levelTwoClickedData?.compliance_name ===
                texts.gstr1ff) &&
              this.state.rowData.length > 0 && (
                <div
                  style={{
                    // marginBottom: 20,
                    marginLeft: 2,
                    display: "flex",
                    flexDirection: "row",
                    // alignItems: "center",
                  }}
                >
                  {this.props.levelTwoClickedData.compliance_name ===
                    "GSTR 3B" &&
                    this.state.rowData.length > 0 && (
                      <div
                        style={{
                          // marginBottom: 10,
                          marginLeft: 16,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          type="ghost"
                          size="small"
                          onClick={this.handleReconcile}
                          loading={this.state.refereshLoading}
                        >
                          {/* Reconciled */}
                          {this.state.refereshLoading
                            ? "Reconciling with 2B..."
                            : "Reconcile with 2B"}
                        </Button>

                        <Button
                          type="link"
                          style={{ color: "red", marginLeft: "2px" }}
                          size="small"
                          onClick={this.handleConflictModal}
                        >
                          {`${this.state.conflicts}`} conflicts
                        </Button>
                      </div>
                    )}
                  <Dropdown overlay={menu}>
                    <Button
                      size="small"
                      style={{ marginLeft: "20%" }}
                      type="primary"
                      icon={<DownloadOutlined />}
                    >
                      Export <DownOutlined />
                    </Button>
                  </Dropdown>
                  {this.props.levelTwoClickedData.compliance_name ===
                    "GSTR1 / IFF" &&
                    this.state.rowData.length > 0 && (
                      <>
                        <Switch
                          checked={this.state.summarize_b2cs}
                          onClick={this.onChangeSummarize}
                          style={{ marginLeft: "20px" }}
                        />
                        <Typography.Text
                          style={{
                            color: "black",
                            marginLeft: "10px",
                            cursor: "context-menu",
                            width: "200px",
                            fontWeight: 600,
                          }}
                        >
                          Show summarized
                        </Typography.Text>
                      </>
                    )}
                  {this.state.exportModalOn ? (
                    <Modal
                      title="Json Preview"
                      footer={null}
                      bodyStyle={{ height: "500px", overflow: "hidden" }}
                      width="80%"
                      centered
                      visible={this.state.exportModalOn}
                      // tslint:disable-next-line: jsx-no-lambda
                      onCancel={() => {
                        this.setState({ exportModalOn: false });
                      }}
                      maskClosable={false}
                    >
                      <Skeleton
                        active
                        loading={this.state.jsonDataShow === "" ? true : false}
                      >
                        <JsonDownload
                          name={this.state.filename}
                          jsonDataShow={this.state.jsonDataShow}
                        />
                      </Skeleton>
                    </Modal>
                  ) : null}
                </div>
              )}
          </Space>

          <SummaryTags
            nameValuePairs={
              this.props.levelTwoClickedData.compliance_name === "GSTR 3B"
                ? [
                    {
                      name: "Tax period",
                      value: this.props.levelTwoClickedData?.for_period,
                    },
                    {
                      name: "FY",
                      value: this.props.levelTwoClickedData?.financial_year,
                    },
                    {
                      name: "Due on",
                      value: this.props.levelTwoClickedData?.due_date
                        ? format(
                            new Date(this.props.levelTwoClickedData.due_date),
                            FORMAT_DATE
                          )
                        : "-",
                    },
                    {
                      name: "Output",
                      value: formatNumber(
                        this.state.outputTaxSum +
                          this.state.debitNoteSum -
                          this.state.creditNoteSum
                      ),
                    },
                    {
                      name: "Input",
                      value: formatNumber(
                        this.state.inputTaxSum +
                          this.state.dnFromSupplier -
                          this.state.cnFromSupplier
                      ),
                    },
                    {
                      name: "TCS credit",
                      value: formatNumber(this.state.tcsCredit),
                    },
                    {
                      name: "Net payable",
                      value: formatNumber(
                        this.state.outputTaxSum +
                          this.state.debitNoteSum -
                          this.state.creditNoteSum -
                          (this.state.inputTaxSum +
                            this.state.dnFromSupplier -
                            this.state.cnFromSupplier) -
                          this.state.tcsCredit <
                          0
                          ? 0
                          : this.state.outputTaxSum +
                              this.state.debitNoteSum -
                              this.state.creditNoteSum -
                              (this.state.inputTaxSum +
                                this.state.dnFromSupplier -
                                this.state.cnFromSupplier) -
                              this.state.tcsCredit
                      ),
                    },
                  ]
                : [
                    {
                      name: "Tax period",
                      value: this.props.levelTwoClickedData?.for_period,
                    },
                    {
                      name: "FY",
                      value: this.props.levelTwoClickedData?.financial_year,
                    },
                    {
                      name: "Due on",
                      value: this.props.levelTwoClickedData?.due_date
                        ? format(
                            new Date(this.props.levelTwoClickedData.due_date),
                            FORMAT_DATE
                          )
                        : "-",
                    },
                  ]
            }
          />
          {this.props.levelTwoClickedData.compliance_name === "GSTR 3B" ? (
            <div className={commonPaneStyle.legendContainer}>
              <div className={commonPaneStyle.notMatchLegend} />
              <div className={commonPaneStyle.legendText}>Not match</div>
              <div className={commonPaneStyle.matchLegend} />
              <div className={commonPaneStyle.legendText}>Match</div>
              <div className={commonPaneStyle.pertialMatchLegend} />
              <div className={commonPaneStyle.legendText}>
                Partial match/Conflict
              </div>
              <div className={commonPaneStyle.percialMatchLegend} />
              <div className={commonPaneStyle.legendText}>
                Reconcile pending
              </div>
            </div>
          ) : null}
          {this.props.levelTwoClickedData.group_name! !== "MCA" &&
          !this.state.isTableLoading ? (
            <Table
              // tslint:disable-next-line: jsx-no-lambda
              rowKey={(row) => row.log_id || row.type}
              expandRowByClick
              className={commonPaneStyle.lvl2DrawerTbl}
              // tslint:disable-next-line: jsx-no-lambda
              rowClassName={(record) =>
                record._parent_type === "GST input (ITC)" &&
                !record.eligible_for_calculation
                  ? commonPaneStyle.darker_background
                  : ""
              }
              bordered={false}
              onRow={this.renderRowAttrs}
              expandable={{
                indentSize: 7,
              }}
              columns={columnsDef}
              dataSource={rowData}
              loading={isLoading}
              pagination={false}
            />
          ) : (
            <Spin spinning={isLoading}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <br />
                <span>{this.state.mcaData?.description}</span>
                <br />
                {this.state.mcaData?.form_url ? (
                  <span>
                    <b>Form Link :</b>
                    <a
                      href={this.state.mcaData.form_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginLeft: "20px" }}
                    >
                      {this.state.mcaData?.form_url}
                    </a>
                  </span>
                ) : null}
              </div>
            </Spin>
          )}
          {this.state.isTableLoading && (
            <Spin spinning={this.state.isTableLoading}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <br />
                <span>{this.state.mcaData?.description}</span>
                <br />
                {this.state.mcaData?.form_url ? (
                  <span>
                    <b>Form Link :</b>
                    <a
                      href={this.state.mcaData.form_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginLeft: "20px" }}
                    >
                      {this.state.mcaData?.form_url}
                    </a>
                  </span>
                ) : null}
              </div>
            </Spin>
          )}

          {/* level 4 */}
          <ComplianceDetailsModal
            isOpen={this.state.detailsModalVisible}
            onClose={this.doCloseDetailsModal}
            clickedRowData={this.state.clickedRowData}
            closeDrawer={this.props.onClose}
            year={this.props.levelTwoClickedData?.financial_year}
          />
          {this.state.conflictModalVisible && (
            <ConflictModal
              visible={this.state.conflictModalVisible}
              onVisibleChange={this.conflictModlVisibleChange}
              levelTwoClickedData={this.props.levelTwoClickedData}
              startRecocile={this.startRecocile}
              loadData={this.loadData}
            />
          )}
          <Modal
            title="Request OTP"
            footer={null}
            zIndex={1100}
            width="25%"
            centered
            visible={this.state.refreshModalVisible}
            onCancel={this.doCloseRefreshModal}
            maskClosable={false}
          >
            <Input
              placeholder="GST username"
              value={this.state.gstUserName}
              onChange={this.handleGstUserNameChang}
              autoFocus={true}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Button
                loading={this.state.loading}
                onClick={this.generateOTP}
                type="primary"
              >
                Generate OTP
              </Button>
            </div>
          </Modal>
          <OtpForm
            visible={this.state.otpVerifyModalVisible}
            onModalVisibleChange={this.onModalVisibleChange}
            handleStartRecocile={this.startRecocile}
            refereshLoading={this.state.refereshLoading}
            otpAlreadyVerified={
              this.state.verificationStage === "Authenticated"
            }
          />
        </Drawer>

        <Modal
          title="Choose an option"
          visible={this.state.downloadModalVisible}
          footer={
            <>
              <Button
                type="primary"
                onClick={() => {
                  this.handleExportDataJson(this.state.modalKeyObject, true);
                }}
              >
                Download IFF (QRMP)
              </Button>
              <Button
                onClick={() => {
                  this.handleExportDataJson(this.state.modalKeyObject, false);
                }}
              >
                Download GSTR1
              </Button>
            </>
          }
          onCancel={this.handleCancel}
        >
          <p>Select the applicable option to download</p>
        </Modal>
      </>
    );
  }
}
export default connect()(withRouter(ComplianceDrawer));
