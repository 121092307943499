import React, { Component } from "react";
import {
  notification,
  Drawer,
  Space,
  Typography,
  Divider,
  Tag,
  Collapse,
  Pagination,
  Spin,
  Form,
  Select,
} from "antd";
import { fetchUtil } from "~/api/common";
import SummaryTags from "~/component/SummaryTags";
import styles from "./Synchronize.module.css";

interface Props {
  isOpen: boolean;
  onClose: any;
  selectedRow?: any;
  processId?: any;
}

interface State {
  mainData: any;
  visible: boolean;
  // pageSize: number;
  totalRows: number;
  noOfRows: number;
  activePage: number;
  errLoad?: boolean;
  filter: string;
  filterItems: Array<FilterItem>;
  totalCount: number;
}

interface FilterItem {
  name: string;
  count: number;
}

interface IETLErrorLog {
  total_rows: number;
  step: string;
  log_message: string;
  extra_data: any;
  error_message: string;
  severity: string;
  created_time: Date;
  error_code: string;
  source_master_id: number;
  advice_text: string;
  count: number;
}

const { Panel } = Collapse;
class SyncRowDetails extends Component<Props, State> {
  state: State = {
    mainData: [],
    visible: false,
    totalRows: 0,
    activePage: 1,
    errLoad: true,
    noOfRows: 10,
    filter: "",
    filterItems: [],
    totalCount: 0,
  };

  componentDidMount() {
    this.getEstimateTime();
    this.getFilterItems();
  }

  getEstimateTime = async () => {
    this.setState({
      errLoad: true,
    });
    const { ok, json, message } = await fetchUtil(
      "POST",
      "/get_sync_error_logs",
      {
        process_id: this.props.processId,
        no_of_rows: this.state.noOfRows,
        page_no: this.state.activePage,
        step_name: this.props.selectedRow.label,
        filter: this.state.filter,
      }
    );
    if (!ok) {
      this.setState({
        errLoad: false,
      });
      notification.error({ message });
    } else {
      this.setState({
        mainData: json,
        totalRows: json[0] ? json[0].total_rows : 0,
        errLoad: false,
      });
    }
  };

  getFilterItems = async () => {
    const { ok, json } = await fetchUtil(
      "POST",
      "/get_sync_error_log_messages",
      {
        process_id: this.props.processId,
        step_name: this.props.selectedRow.label,
      }
    );
    if (!ok) {
      notification.error({
        message: "Something went wrong while fetching filter items",
      });
    } else {
      let count = 0;
      const list = json.map((val) => {
        count += +val.error_count;
        return { name: val.log_message, count: val.error_count };
      });
      this.setState({
        filterItems: [{ name: "All", count }, ...list],
        totalCount: count,
      });
    }
  };

  // componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>) {}

  handleDropdownChange = (value: string) => {
    const temp = value === "All" ? "" : value;
    if (temp === this.state.filter) return;
    this.setState(
      {
        filter: temp,
        noOfRows: 10,
        activePage: 1,
      },
      () => {
        this.getEstimateTime();
      }
    );
  };

  pageChange = (pageNumber: number) => {
    this.setState(
      {
        activePage: Number.isFinite(pageNumber) ? pageNumber : 1,
      },
      () => {
        this.getEstimateTime();
      }
    );
  };

  renderSection = () => {
    return this.state.mainData.map((logItem: IETLErrorLog, idx: number) => {
      const date = new Date(logItem.created_time);
      const errKeys = Object.keys(logItem.extra_data ? logItem.extra_data : {});

      return (
        <>
          <div
            style={{ display: "flex", flexDirection: "column", padding: "0" }}
            key={idx}
          >
            <div key={idx}>
              {idx === 0 ? (
                <Divider
                  style={{
                    backgroundColor: "black",
                    position: "relative",
                    top: "24px",
                  }}
                />
              ) : null}
              <Collapse ghost key={Math.random()}>
                <Panel
                  key={idx}
                  style={{
                    borderBottom: "1px solid var(--grey-3)",
                  }}
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className={styles.tableDataEllipsis}>
                        <Tag
                          style={{
                            backgroundColor:
                              logItem.severity === "ERROR"
                                ? "red"
                                : logItem.severity === "INFO"
                                ? "green"
                                : logItem.severity === "WARN"
                                ? "#F2CA4A"
                                : "white",
                            borderColor:
                              logItem.severity === "ERROR"
                                ? "red"
                                : logItem.severity === "INFO"
                                ? "green"
                                : logItem.severity === "WARN"
                                ? "#F2CA4A"
                                : "white",
                            // minWidth:'90px'
                          }}
                        >
                          {" "}
                          <b style={{ color: "white" }}>
                            {`${
                              logItem.severity === "ERROR"
                                ? "Error"
                                : logItem.severity === "INFO"
                                ? "Info"
                                : logItem.severity === "WARN"
                                ? "warn"
                                : ""
                            }`}{" "}
                          </b>{" "}
                        </Tag>
                        <span
                          style={{
                            fontSize: "13px",
                            color: "1px solid var(--grey-3)",
                            fontWeight: 500,
                          }}
                        >
                          {/* <b> */}
                          {date.toLocaleString("en-IN")}
                          {/* </b> */}
                        </span>
                        <span style={{ marginLeft: "10px" }}>
                          <b>{`${logItem.log_message}`}</b>
                        </span>
                      </div>
                      <div>
                        <Tag style={{ backgroundColor: "#BABABA" }}>
                          <b style={{ color: "white" }}> {logItem.count}</b>
                        </Tag>
                      </div>
                    </div>
                  }
                  showArrow={false}
                >
                  <div>
                    <b>
                      <div>{logItem.log_message}</div>
                      {logItem.advice_text ? (
                        <div>
                          <span>Suggestion: </span>
                          <i>{logItem.advice_text}</i>
                        </div>
                      ) : null}
                    </b>
                  </div>

                  {errKeys.length > 0 ? (
                    errKeys.map((k, keyIdx) => {
                      return (
                        <div
                          key={keyIdx}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "13px",
                            // marginTop: keyIdx === 0 ? "-10px" : "4px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              color: "#626262",
                            }}
                          >
                            <span>
                              <b>
                                {k}: {`${logItem.extra_data[k]}`}
                              </b>
                            </span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      No details found !
                    </div>
                  )}
                </Panel>
              </Collapse>
            </div>
          </div>
        </>
      );
    });
  };

  render() {
    return (
      <Drawer
        width={"56%"}
        placement="right"
        closable={true}
        visible={this.props.isOpen}
        onClose={this.props.onClose}
        bodyStyle={{ paddingLeft: "0px", paddingRight: "0px" }}
        destroyOnClose={true}
      >
        <Spin spinning={this.state.errLoad}>
          <Space style={{ marginBottom: 5, paddingLeft: "24px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography.Title level={3}>
                <p className={styles.drwerHeading}>Error logs</p>
              </Typography.Title>
              <div style={{ marginTop: "-20px" }}>
                <SummaryTags
                  nameValuePairs={[
                    {
                      name: "Log count",
                      value: this.state.totalRows,
                    },
                    {
                      name: "Step name",
                      value: this.props.selectedRow.label,
                    },
                  ]}
                />
              </div>
            </div>
          </Space>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              width: "100%",
              position: "relative",
              top: "30px",
              marginTop: "-30px",
            }}
          >
            <div>
              {this.state.filterItems?.length ? (
                <Form style={{ paddingLeft: "10px" }}>
                  <Form.Item
                    style={{
                      marginBottom: 0,
                    }}
                    label="Filter logs"
                  >
                    <Select
                      value={
                        this.state.filter
                          ? this.state.filter
                          : `All (${this.state.totalCount})`
                      }
                      onSelect={this.handleDropdownChange}
                      suffixIcon={
                        <svg
                          width="10"
                          height="8"
                          viewBox="0 0 20 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 16L19.5263 0.25H0.473721L10 16Z"
                            fill="#4F4F4F"
                          />
                        </svg>
                      }
                      style={{
                        color: "black",
                        fontWeight: 700,
                        width: "300px",
                      }}
                    >
                      {this.state.filterItems.map((item) => {
                        return (
                          <Select.Option key={item.name} value={item.name}>
                            {item.name} {item.count ? `(${item.count})` : ""}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Form>
              ) : (
                <></>
              )}
            </div>
            {this.state.totalRows !== 0 ? (
              <Pagination
                current={this.state.activePage}
                total={this.state.totalRows}
                size={"small"}
                showSizeChanger={false}
                onChange={this.pageChange}
                pageSize={this.state.noOfRows}
              />
            ) : (
              <></>
            )}
          </div>
          {this.state.mainData.length > 0 ? (
            this.renderSection()
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "30%",
                }}
              >
                <h3>
                  <b>No Error log found!</b>
                </h3>
              </div>
            </>
          )}
          {/* {} */}
        </Spin>
      </Drawer>
    );
  }
}
export default SyncRowDetails;
