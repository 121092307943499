import React, { Component } from "react";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import classes from "./SalaryTemplate.module.css";
import { fetchUtil } from "~/api/common";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import { Button, Table, notification, Select, Space, Modal, Input } from "antd";
import ReadOnlyableSelect from "~/component/field-input/ReadOnlyableSelect";
import ConfirmTemplateModal, { ActionType } from "./ConfirmTemplateModal";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

interface Props {
  visible: boolean;
}

interface State {
  isEditing: boolean;
  activeBtn: boolean;
  tableData: Array<any>;
  deletedRows: Array<any>;
  categoriesList: Array<any>;
  categoriesById: any;
  templateList: Array<any>;
  activeTemplateId?: number;

  // confirmModal states
  cfmVisible: boolean;
  cfmAction: ActionType;
  isNewTemplate: boolean;
}

const RELATIVE_ABSOLUTE_PICKLIST = [
  {
    id: "actual",
    value: "On actual",
  },
  {
    id: "percentage",
    value: "Percentage",
  },
];

export default class SalaryTemplate extends Component<Props, State> {
  state: State = {
    isEditing: false,
    activeBtn: true,
    deletedRows: [],
    tableData: [],
    templateList: [],
    categoriesList: [],
    categoriesById: {},

    // confirmModal states
    cfmVisible: false,
    cfmAction: "EDIT_EXISTING",
    isNewTemplate: false,
  };

  handleAddNewTemplateBtn = () => {
    // TODO: Set a fresh new initial tableData
    const newTemplate = {
      id: Math.random(),
      name: "Custom Template",
      tableData: [
        {
          id: Math.random(),
          category_id: 1,
          field_type: "actual",
          reference_id: 0,
          amount: "",
        },
        // {
        //   id: Math.random(),
        //   category_id: 2,
        //   field_type: "percentage",
        //   reference_id: 1,
        //   amount: "",
        // },
      ],
    };
    this.setState({
      isEditing: true,
      activeBtn: false,
      templateList: [...this.state.templateList, newTemplate],
      tableData: newTemplate.tableData,
      activeTemplateId: newTemplate.id,
      isNewTemplate: true,
    });
  };

  handleCancelBtn = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.salaryTemplate199
    );

    // TODO: Reset the value to initial if we are editing existing template
    // else if we discards a new template, select & view the "Basic Template"
    const { isNewTemplate, templateList } = this.state;
    if (isNewTemplate) {
      this.setState({ activeTemplateId: templateList[0].id });
      await this.loadTemplateData();
    }
    this.setState({
      isEditing: false,
      isNewTemplate: false,
    });
  };

  handleEditBtn = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.salaryTemplate200
    );

    this.setState({
      isEditing: true,
    });
  };

  handleCfmCancel = async () => {
    this.setState({
      cfmVisible: false,
    });
  };

  handleCfmNew = async (templateName: string) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.salaryTemplate201
    );

    const { ok, message } = await fetchUtil("POST", `/save_salary_template`, {
      is_new: true,
      template_name: templateName,
      categories: this.state.tableData.map((it) => {
        const categoryItem = this.state.categoriesList.find(
          (cIt) => cIt.id === it.category_id
        );
        const referenceItem = this.state.categoriesList.find(
          (rIt) => rIt.id === it.reference_id
        );
        return {
          category_id: it.category_id,
          category_name: categoryItem?.short_name,
          field_type: it.field_type,
          reference_id: it.reference_id,
          reference: referenceItem?.short_name ?? "na",
          amount: it.amount,
          isNew: true,
          isEdited: false,
        };
      }),
    });

    if (ok) {
      notification.success({
        message,
      });
      this.setState({
        isEditing: false,
        cfmVisible: false,
        isNewTemplate: false,
      });
      await this.loadTemplateData();
    } else {
      notification.error({ message });
    }
  };

  handleCfmEdit = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.salaryTemplate202
    );

    const categories = this.state.tableData.map((it) => {
      const categoryItem = this.state.categoriesList.find(
        (cIt) => cIt.id === it.category_id
      );
      const referenceItem = this.state.categoriesList.find(
        (rIt) => rIt.id === it.reference_id
      );
      const id = it.id >= 1 ? it.id : undefined;
      const isNew = !id;
      const isEdited = !isNew;
      return {
        id,
        category_id: it.category_id,
        category_name: categoryItem?.short_name,
        field_type: it.field_type,
        reference_id: it.reference_id,
        reference: referenceItem?.short_name ?? "na",
        amount: it.amount,
        isNew,
        isEdited,
      };
    });
    //
    const { ok, message } = await fetchUtil("POST", `/save_salary_template`, {
      is_new: false,
      id: this.state.activeTemplateId,
      template_name: this.state.templateList.find(
        (it) => it.id === this.state.activeTemplateId
      )?.name,
      categories: categories.concat(this.state.deletedRows),
    });

    if (ok) {
      notification.success({
        message,
      });
      this.setState({
        isEditing: false,
        cfmVisible: false,
      });
      await this.loadTemplateData();
    } else {
      notification.error({ message });
    }
  };

  handleCfmDelete = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.salaryTemplate203
    );

    const { ok, message } = await fetchUtil("POST", `/delete_salary_template`, {
      template_id: this.state.activeTemplateId,
    });

    if (ok) {
      this.setState({
        cfmVisible: false,
      });
      notification.success({ message });
      await this.loadTemplateData();
    } else {
      notification.error({ message });
    }
  };

  handleSaveBtn = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.salaryTemplate204
    );

    const isExisting =
      this.state.activeTemplateId && this.state.activeTemplateId >= 1;
    this.setState({
      cfmVisible: true,
      cfmAction: !isExisting ? "SAVE_NEW" : "EDIT_EXISTING",
    });
  };

  handleDeleteBtn = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.salaryTemplate205
    );

    this.setState({
      cfmVisible: true,
      cfmAction: "DELETE_EXISTING",
    });
  };

  handleTemplateChange = (newTemplateId: number) => {
    this.setState({
      activeTemplateId: newTemplateId,
      tableData: this.state.templateList.find((it) => it.id === newTemplateId)
        .tableData,
    });
  };

  async getCategoryList() {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_salary_categories`
    );

    if (ok) {
      const categoriesById: any = {};
      json.forEach((it) => {
        categoriesById[it.id] = it.display_name;
      });

      this.setState({
        categoriesList: json,
        categoriesById,
      });
    } else {
      notification.error({ message });
    }
  }

  async loadTemplateData() {
    const { ok, json, message } = await fetchUtil(
      "POST",
      "/get_salary_templates"
    );
    if (!ok) {
      console.warn(message, json);
      notification.error({ message: "Failed to load templates." });
    } else {
      try {
        json.sort((a, b) => a.id - b.id);
        const detailsList = await Promise.all(
          json.map(async (it: any) => {
            const { ok: dOk, json: dJson, message: dMessage } = await fetchUtil(
              "POST",
              "/get_salary_templates_fields",
              {
                template_id: it.id,
              }
            );
            if (!dOk) {
              throw new Error(
                `Failed to load details for template: ${it.id} (${it.template_name}). Message: ${dMessage}`
              );
            }
            dJson.forEach((dIt) => {
              dIt.reference_id = +dIt.reference_id;
            });
            return dJson;
          })
        );
        const combinedTemplateList = json.map((tIt, index) => {
          const dIt = detailsList[index];
          return {
            id: tIt.id,
            name: tIt.template_name,
            company_id: tIt.company_id,
            tableData: dIt,
          };
        });
        this.setState({
          deletedRows: [],
          tableData: combinedTemplateList[0].tableData,
          templateList: combinedTemplateList,
          activeTemplateId: combinedTemplateList[0].id,
        });
      } catch (error) {
        console.warn(error);
        notification.error({ message: "Failed to load template details." });
      }
    }
  }

  async loadData() {
    await this.getCategoryList();
    await this.loadTemplateData();
  }

  handleAddRow = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.salaryTemplate206
    );
    const nextCategoryItem = this.state.categoriesList.find((it) => {
      const tableDataItem = this.state.tableData.find((_tableDataItem: any) => {
        return _tableDataItem.category_id === it.id;
      });
      return !tableDataItem;
    });
    const lastCategoryItem = this.state.categoriesList[
      this.state.categoriesList.length - 1
    ];
    const categoryItem = nextCategoryItem ?? lastCategoryItem;

    this.setState({
      tableData: [
        ...this.state.tableData,
        {
          id: Math.random(),
          category_id: categoryItem.id,
          field_type: "actual",
          reference_id: 0,
          amount: 0,
        },
      ],
    });
  };

  handleCellChange = (
    fieldName: string,
    index: number,
    changedValue: string | number
  ) => {
    const changedRow = this.state.tableData[index];
    const updatedRow = {
      ...changedRow,
      [fieldName]: changedValue,
    };
    if (fieldName === "category_id") {
      const selectedCategory = this.state.categoriesList.find(
        (it) => it.id === changedValue
      );
      if (selectedCategory?.short_name === "basic") {
        updatedRow.field_type = "actual";
        updatedRow.reference_id = 0;
      }
    }

    if (fieldName === "field_type") {
      if (changedValue === "actual") {
        updatedRow.reference_id = 0;
        updatedRow.amount = 0;
      } else if (updatedRow.reference_id === 0) {
        updatedRow.reference_id = 1;
      }
    }

    this.setState({
      tableData: [
        ...this.state.tableData.slice(0, index),
        updatedRow,
        ...this.state.tableData.slice(index + 1),
      ],
    });
  };

  handleRemoveRow = (index: number) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.salaryTemplate207
    );

    const row = this.state.tableData[index];
    if (row.id >= 1) {
      this.setState({
        deletedRows: [
          ...this.state.deletedRows,
          {
            ...row,
            isNew: false,
            isEdited: true,
            isDeleted: true,
          },
        ],
      });
    }
    this.setState({
      tableData: [
        ...this.state.tableData.slice(0, index),
        ...this.state.tableData.slice(index + 1),
      ],
    });
  };

  componentDidMount() {
    this.loadData();
  }

  getColumnDef() {
    const lastRowIndex = this.state.tableData.length - 1;
    // const isEditEnabled = true;
    const isEditing = this.state.isEditing;
    // const isDeletable = true;
    // const canAddNew = true;

    const columnDef: any = [
      {
        title: "",
        dataIndex: "id",
        render: (_value: any, _row: any, idx: number) => {
          if (idx === lastRowIndex) {
            return (
              <div className={classes.plusBtn}>
                {isEditing && (
                  <Button
                    size="small"
                    icon={
                      <PlusOutlined style={{ color: "var(--success-color)" }} />
                    }
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => this.handleAddRow()}
                  />
                )}
              </div>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: "Category",
        dataIndex: "category_id",
        // width: "25%",

        render: (value: number, row: any, index: number) => {
          const selectedCategory = this.state.categoriesList.find(
            (it) => it.id === row.category_id
          );
          const isBasic = selectedCategory?.short_name === "basic";
          return (
            <ReadOnlyableSelect
              readonly={isBasic ? isEditing : !isEditing}
              style={{ width: "200px" }}
              value={value}
              // tslint:disable-next-line: jsx-no-lambda
              onChange={(newValue) =>
                this.handleCellChange("category_id", index, newValue)
              }
            >
              {this.state.categoriesList
                .filter((it) => {
                  if (it.id === row.category_id) {
                    return true;
                  }
                  const tableDataItem = this.state.tableData.find(
                    (_tableDataItem: any) => {
                      return _tableDataItem.category_id === it.id;
                    }
                  );
                  return !tableDataItem;
                })
                .map((it) => (
                  <Select.Option key={it.id} value={it.id}>
                    {it.display_name}
                  </Select.Option>
                ))}
            </ReadOnlyableSelect>
          );
        },
      },

      {
        title: "Relative/Absolute",
        dataIndex: "field_type",
        // width: "25%",

        render: (value: any, row: any, index: number) => {
          const selectedCategory = this.state.categoriesList.find(
            (it) => it.id === row.category_id
          );
          const isBasic = selectedCategory?.short_name === "basic";

          return (
            <ReadOnlyableSelect
              readonly={isBasic ? isEditing : !isEditing}
              style={{ width: "200px" }}
              value={value}
              // tslint:disable-next-line: jsx-no-lambda
              onChange={(newValue) =>
                this.handleCellChange("field_type", index, newValue)
              }
            >
              {RELATIVE_ABSOLUTE_PICKLIST.map((it) => (
                <Select.Option
                  key={it.id}
                  value={it.id}
                  disabled={isBasic && it.id === "percentage"}
                >
                  {it.value}
                </Select.Option>
              ))}
            </ReadOnlyableSelect>
          );
        },
      },

      {
        title: "Reference",
        dataIndex: "reference_id",
        // width: "25%",

        render: (value: any, row: any, rowIndex: number) => {
          // const selectedCategory = this.state.categoriesList.find(
          //   (it) => it.id === row.category_id
          // );
          // const isBasic = selectedCategory?.short_name === "basic";
          const isOnActual = row.field_type === "actual";
          return (
            <ReadOnlyableSelect
              readonly={!isEditing || isOnActual}
              style={{ width: "200px" }}
              value={value}
              // tslint:disable-next-line: jsx-no-lambda
              onChange={(newValue: any) =>
                this.handleCellChange("reference_id", rowIndex, newValue)
              }
            >
              {isOnActual && (
                <Select.Option key="na" value={0}>
                  N/A
                </Select.Option>
              )}
              {!isOnActual &&
                this.state.categoriesList
                  .filter((it: any) => {
                    // if (it.id === row.category_id) {
                    //   return false;
                    // }
                    const tableDataItem = this.state.tableData.find(
                      (_tableDataItem: any, tdIndex: number) => {
                        return (
                          tdIndex < rowIndex &&
                          _tableDataItem.category_id === it.id
                        );
                      }
                    );
                    return !!tableDataItem;
                  })
                  .map((it) => (
                    <Select.Option key={it.id} value={it.id}>
                      {it.display_name}
                    </Select.Option>
                  ))}
            </ReadOnlyableSelect>
          );
        },
      },

      {
        title: "Amount/Percentage",
        dataIndex: "amount",
        render: (value: any, row: any, index: number) => {
          const isOnActual = row.field_type === "actual";

          return (
            <Input
              readOnly={isOnActual || !isEditing}
              value={isOnActual && !isEditing ? "" : value}
              // tslint:disable-next-line: jsx-no-lambda
              onChange={(event: any) => {
                this.handleCellChange("amount", index, event.target.value);
              }}
            />
          );
        },
      },
      {
        title: "",
        // dataIndex: "id",
        key: "delete_action",
        render: (_1: any, _2: any, index: number) => {
          if (index === 0) {
            return null;
          } else {
            return (
              <div>
                {isEditing && (
                  <Button
                    size="small"
                    danger
                    title="Remove line item"
                    icon={<MinusOutlined />}
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => this.handleRemoveRow(index)}
                  />
                )}
              </div>
            );
          }
        },
      },
    ];

    return columnDef;
  }

  render() {
    const templateItem = this.state.templateList.find(
      (it: any) => it.id === this.state.activeTemplateId
    );
    const isStandardTemplate = templateItem?.company_id === null;

    const isEditEnabled = !isStandardTemplate;
    const isEditing = this.state.isEditing;
    const { isNewTemplate } = this.state;
    const isDeletable = !isStandardTemplate;
    const canAddNew = true;

    const columnDef = this.getColumnDef();

    return (
      <IMTContent withoutMargin={true}>
        <Helmet>
          <title>{titles.SalaryTemplate}</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={["Salary Template"]}
          style ={{paddingLeft:"0"}}

          actions={
            <Space align="center">
              {canAddNew && !isEditing && (
                <Button type="primary" onClick={this.handleAddNewTemplateBtn}>
                  + Add new
                </Button>
              )}

              {isEditEnabled && !isEditing && (
                <Button onClick={this.handleEditBtn}>Edit </Button>
              )}

              {isEditing && (
                <Button type="primary" onClick={this.handleSaveBtn}>
                  Save
                </Button>
              )}
              {isEditing && (
                <Button type="dashed" onClick={this.handleCancelBtn}>
                  Cancel
                </Button>
              )}
              {isDeletable && !isNewTemplate && (
                <Button danger onClick={this.handleDeleteBtn}>
                  Delete{" "}
                </Button>
              )}
            </Space>
          }
        />
        <div className={classes.tableWrapper}>
          <div className={classes.dropdownWrap}>
            <Select
              value={this.state.activeTemplateId}
              onChange={this.handleTemplateChange}
            >
              {this.state.templateList.map((it) => (
                <Select.Option key={it.id} value={it.id}>
                  {it.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Table
            rowKey="id"
            className={classes.table}
            columns={columnDef}
            dataSource={this.state.tableData}
            pagination={false}
          />

          <ConfirmTemplateModal
            visible={this.state.cfmVisible}
            action={this.state.cfmAction}
            onCancel={this.handleCfmCancel}
            onConfirmNew={this.handleCfmNew}
            onConfirmEdit={this.handleCfmEdit}
            onConfirmDelete={this.handleCfmDelete}
          />
        </div>
      </IMTContent>
    );
  }
}
