import React from "react";
import { GSTRate } from "~/api/expense";
import { useGSTRateList } from "~/lib/hook/api-hook/picklistHooks";
import usePersistantCallbackRef from "~/lib/hook/usePersistantCallbackRef";
import ReadOnlyableSelect from "./ReadOnlyableSelect";

interface Props {
  readOnly?: boolean;
  disabled?: boolean;
  value?: number;
  onChange?: (gstRateId: number, record?: GSTRate) => void;
}

const GSTRateSelect = (props: Props) => {
  const { isGSTListLoading, gstRateList } = useGSTRateList();
  const handleOnChange = usePersistantCallbackRef((gstRateId: number) => {
    const gstRate = gstRateList.find((it) => it.id === gstRateId);
    props.onChange?.(gstRateId, gstRate);
  });
  return (
    <ReadOnlyableSelect
      loading={isGSTListLoading}
      readonly={props.readOnly}
      disabled={props.disabled}
      value={props.value}
      style={{
        width: "100%",
      }}
      onChange={handleOnChange}
      options={gstRateList.map((it) => ({
        value: it.id,
        label: it.description,
      }))}
    />
  );
};

export default GSTRateSelect;
