export function getDefaultCompRole(companyDetails: any, companyId: number) {
  const selectedCompanyDetails = companyDetails.find(
    (e: any) => e.id === companyId
  );
  const filteredComps = selectedCompanyDetails
    ? [selectedCompanyDetails]
    : companyDetails;

  const _priority = [2, 1, 5, 3, 4];

  let preArr = filteredComps.reduce((acc: any, comp: any, idx: number) => {
    const objs = comp.roles.map((roleId: any) => ({
      id: comp.id,
      role: roleId,
      idx,
    }));
    return acc.concat(objs);
  }, []);
  preArr = preArr.sort((a: any, b: any) => a.id - b.id);
  const activeIndex = preArr.reduce(
    (acc: any, preArrObj: any) => {
      const ind = _priority.indexOf(preArrObj.role);
      if (ind < acc.role)
        return { id: preArrObj.id, role: ind, idx: preArrObj.idx };
      else return acc;
    },
    { id: null, role: 99, idx: -1 }
  );

  return {
    companyId: activeIndex.id,
    company_name: filteredComps[activeIndex.idx].name,
    activeRole: _priority[activeIndex.role],
    role_ids: filteredComps[activeIndex.idx].roles,
  };
}
