import React, { Component } from "react";
import { RouteChildrenProps } from "react-router";
import { connect, ConnectedProps } from "react-redux";

import { ColumnType } from "antd/lib/table/interface";
import {
  Table,
  Modal,
  Button,
  Row,
  Space,
  notification,
  Input,
  Form,
} from "antd";
import { FormInstance } from "antd/lib/form";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import {
  format,
  subMonths,
  getMonth,
  // parse,
  getYear,
  addYears,
  subYears,
} from "date-fns";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import { APIState, fetchUtil } from "~/api/common";
import {
  LoginRole,
  AdminApprovalStatus,
  EmployeeApprovalStatus,
} from "~/api/auth";
import { getReceiptList, getTableExportBlob } from "~/api/reimburse";
import { ApiRowResponse } from "~/feature/reimbursement/ReimbursementModel";
import commonStyle from "~/component/common.module.css";
import styles from "./ReimbursementPage.module.css";
import SummaryTags from "~/component/SummaryTags";
import Currency from "~/component/Currency";
import { parseNumber } from "~/lib";
import YearMonthDropdown from "~/fragment/year-month-dropdown/YearMonthDropdown";
import ReactDOM from "react-dom";
import { months, SUPPORTED_EXT_LIST } from "~/lib/constants";
import { registerEvent } from "~/analytics";
import ExportButton from "~/fragment/table-export/ExportButton";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";

import * as ga from "~/contants/gaConstants";

const { TextArea } = Input;

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  saveEmpDetails: state.mainAppSlice.user.save_emp_details,
  sortOrderInfo: state.mainAppSlice.user.sort_order,
  sorterData: state.mainAppSlice.user.sorter,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  setSortOrder: (sortOrderInfo: any) =>
    dispatch(action.auth.setSortOrder(sortOrderInfo)),
  setSorter: (sorter: any) => dispatch(action.auth.setSorter(sorter)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends RouteChildrenProps, PropsFromRedux {}

interface State {
  receiptList: Array<ApiRowResponse>;
  sortedInfo: any;
  filteredInfo: any;
  tableLoading: boolean;
  activePage: number;
  totalItemCount: number;
  noOfRows: number;
  itemLength: number;
  timeZoneOfset: number;
  payModal: boolean;
  selectDetail?: ApiRowResponse;
  selectedYear: any;
  selectedMonth: number;
  emp_id: any;
  currentRole: number;
  openApproveModal: boolean;
  summary: Partial<{
    reimbursement_count: number;
    reimbursement_amount: number;
    pending: number;
  }>;
  reimburseFormData: any;
  modalTitle: string;
  apiState: APIState;
  isDragOver: boolean;
  sortOrder: string;
}

// const dtNOW = new Date();

class ReimbursementPage extends Component<Props, State> {
  state: State = {
    receiptList: [],
    selectDetail: undefined,
    itemLength: 1,
    activePage: 1,
    totalItemCount: 0,
    noOfRows: 12,
    sortedInfo: {},
    filteredInfo: {},
    tableLoading: false,
    timeZoneOfset: new Date().getTimezoneOffset(),
    payModal: false,
    selectedYear: -1,
    selectedMonth: -1,
    emp_id: null,
    currentRole: 0,
    openApproveModal: false,
    summary: {},
    reimburseFormData: {},
    modalTitle: "",
    apiState: "idle",
    isDragOver: false,
    sortOrder: "",
  };

  _formRef = React.createRef<FormInstance<any>>();
  _dndDivRef = React.createRef<any>();

  static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State
  ): Partial<State> | null {
    // set year according to quary param
    const query = new URLSearchParams(nextProps.location.search);
    const today = new Date();
    const isBefore25th = today.getDate() < 25;
    const selectedDate = isBefore25th ? subMonths(today, 1) : today;
    const current = new Date();
    const yearCurrent = new Date().getFullYear();
    const eligible =
      current.getTime() <= new Date(`${yearCurrent}-04-25`).getTime() &&
      current.getTime() > new Date(`${yearCurrent - 1}-04-25`).getTime();
    let year: any = eligible
      ? `${getYear(subYears(current, 1))}-${getYear(current)}`
      : `${getYear(current)}-${getYear(addYears(current, 1))}`;
    let currentPage = 1;
    let month = getMonth(selectedDate);
    if (query.has("year")) {
      const _yearStr = query.get("year") + "";
      if (/\d{4}/.test(_yearStr)) {
        year = _yearStr;
      }
    }

    if (query.has("month")) {
      const _monthNum = +months.indexOf(query.get("month") + "");
      if (Number.isFinite(_monthNum)) {
        month = _monthNum;
      }
    }
    if (query.has("page")) {
      const pageNo = +query.get("page")!;
      if (Number.isFinite(pageNo)) {
        currentPage = pageNo;
      }
    }
    const newState: Partial<State> = {
      selectedYear: year,
      selectedMonth: month,
      activePage: currentPage,
      sortOrder: query.get("sort_order") || "",
    };
    if (
      prevState.selectedYear !== newState.selectedYear ||
      prevState.selectedMonth !== newState.selectedMonth ||
      prevState.activePage !== newState.activePage ||
      prevState.sortOrder !== newState.sortOrder
    ) {
      return newState;
    }
    return null;
  }

  componentDidMount() {
    if (this.props.activeRole === LoginRole.EMPLOYEE) {
      const $el = ReactDOM.findDOMNode(
        this._dndDivRef.current
      ) as HTMLDivElement;

      $el?.addEventListener("dragenter", this.handleDragEnter);
      $el?.addEventListener("dragover", this.handleDragOver);
      $el?.addEventListener("drop", this.handleDrop);
      $el?.addEventListener("dragexit", this.handleDragExit);
      $el?.addEventListener("dragend", this.handleDragExit);
      $el?.addEventListener("dragleave", this.handleDragExit);
    }
    this.setState({ currentRole: this.props.activeRole });
    this.fetchData();
    if (
      this.props.activeRole === LoginRole.ADMIN ||
      this.props.activeRole === LoginRole.CA ||
      this.props.activeRole === LoginRole.SUPERuSER
    ) {
      this.getReimbursementSummary();
    }
    if (this.props.sorterData) {
      this.setState({
        sortedInfo: this.props.sorterData,
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetchData();
      if (
        this.props.activeRole === LoginRole.ADMIN ||
        this.props.activeRole === LoginRole.CA ||
        this.props.activeRole === LoginRole.SUPERuSER
      ) {
        this.getReimbursementSummary();
      }
    }
    if (_prevState.receiptList !== this.state.receiptList) {
      if (this.state.receiptList.length) {
        this.placeButton();
      }
    }
  }

  placeButton = () => {
    // setTimeout(() => {
    const element = document.getElementsByClassName(
      "ant-pagination-item-link"
    )[0];
    if (element) {
      const { left, top } = element.getBoundingClientRect(); // , bottom , right,
      const btn = document.getElementById("exportBtn");
      if (btn) {
        btn.style.position = "absolute";
        btn.style.left = left - 30 + "px";
        btn.style.top = top + "px";
        btn.style.margin = "0";
        btn.style.display = "inline";
      }
    }
    // }, 1000);
  };

  async getReimbursementSummary() {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_reimbursement_summary`,
      {
        month:
          this.state.selectedMonth + 1 + "" === "13"
            ? "ALL"
            : this.state.selectedMonth + 1 + "",
        fy: this.state.selectedYear + "",
      }
    );

    if (ok) {
      const data = json[0] || {
        reimbursement_amount: 0,
        reimbursement_count: 0,
        pending: 0,
      };
      data.reimbursement_amount = parseNumber(data.reimbursement_amount);
      this.setState({
        summary: data,
      });
    } else {
      notification.error({ message });
    }
  }

  handleDragEnter = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({
      isDragOver: true,
    });
    evt.currentTarget.classList.add(commonStyle.dndContainerDragOver);
  };
  handleDragOver = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
  };
  handleDrop = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({
      isDragOver: false,
    });
    const fileList = evt.dataTransfer.files;
    if (fileList.length > 0) {
      const firstFile = fileList[0];
      const fileParts = firstFile.name.split(".");
      if (fileParts.length > 0) {
        const ext = fileParts[fileParts.length - 1].toLowerCase();
        if (!SUPPORTED_EXT_LIST.includes(ext)) {
          notification.warn({
            message: "Unsupported file",
            description: "Please upload a pdf or a image file",
          });
          return;
        }
      }
    }
    this.props.history.push(`/app/reimbursement/new`, {
      fileList,
    });
    evt.currentTarget.classList.remove(commonStyle.dndContainerDragOver);
  };
  handleDragExit = (evt: any) => {
    if (
      evt.type === "dragleave" &&
      evt.target.className !== evt.currentTarget.className
    ) {
      return;
    }

    this.setState({
      isDragOver: false,
    });
    evt.currentTarget.classList.remove(commonStyle.dndContainerDragOver);
  };

  statusCalculate(value: number) {
    if (
      this.props.activeRole === LoginRole.ADMIN ||
      this.props.activeRole === LoginRole.SUPERuSER
    ) {
      if (AdminApprovalStatus.APPROVE === value) {
        return <span className={styles["approve-color"]}>Approved</span>;
      } else if (AdminApprovalStatus.PENDING === value) {
        return <span className={styles["pending-color"]}>Pending</span>;
      } else if (AdminApprovalStatus.REJECT === value) {
        return <span className={styles["rejected-color"]}>Rejected</span>;
      }
    } else if (this.props.activeRole === LoginRole.EMPLOYEE) {
      if (EmployeeApprovalStatus.APPLIED === value) {
        return <span className={styles["applied-color"]}>Applied</span>;
      } else if (EmployeeApprovalStatus.APPROVE === value) {
        return <span className={styles["approve-color"]}>Approved</span>;
      } else if (EmployeeApprovalStatus.REJECT === value) {
        return <span className={styles["rejected-color"]}>Rejected</span>;
      }
    } else if (this.props.activeRole === LoginRole.CA) {
      if (EmployeeApprovalStatus.APPLIED === value) {
        return <span className={styles["pending-color"]}>Pending</span>;
      } else if (EmployeeApprovalStatus.APPROVE === value) {
        return <span className={styles["approve-color"]}>Approved</span>;
      } else if (EmployeeApprovalStatus.REJECT === value) {
        return <span className={styles["rejected-color"]}>Rejected</span>;
      }
    }
  }

  actionCalculate = (value: number, record: any) => {
    if (
      this.props.activeRole === LoginRole.ADMIN ||
      this.props.activeRole === LoginRole.SUPERuSER
    ) {
      if (AdminApprovalStatus.APPROVE === value) {
        return <span className={styles["done-clor"]}>Done</span>;
      } else if (AdminApprovalStatus.PENDING === value) {
        return (
          <div>
            <span
              className={styles.approveAction} // tslint:disable-next-line jsx-no-lambda
              onClick={(e: any) => {
                registerEvent(
                  ga.EVENT_CATEGORY_BUTTON_CLICK,
                  ga.EVENT_CLICK,
                  ga.events.reimbursementPage220
                );
                this.approveClick(record);
                e.stopPropagation();
              }}
            >
              Approve
            </span>
            <span
              className={styles.rejectAction}
              onClick={(e: any) => {
                registerEvent(
                  ga.EVENT_CATEGORY_BUTTON_CLICK,
                  ga.EVENT_CLICK,
                  ga.events.reimbursementPage221
                );
                this.rejectClick(record);
                e.stopPropagation();
              }}
            >
              Reject
            </span>
          </div>
        );
      } else if (AdminApprovalStatus.REJECT === value) {
        return <span className={styles["done-clor"]}>Done</span>;
      }
    }
  };

  approveClick = (record: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.reimbursementPage222
    );
    const selectedRecord = { ...record };
    selectedRecord.paid_amount = selectedRecord.total_amount;
    this.setState(
      {
        openApproveModal: true,
        reimburseFormData: selectedRecord,
        modalTitle: "Approve",
      },
      () => {
        if (this._formRef.current) {
          this._formRef.current.setFieldsValue(selectedRecord);
        }
      }
    );
  };

  rejectClick = (record: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.reimbursementPage223
    );

    this.setState(
      {
        openApproveModal: true,
        reimburseFormData: record,
        modalTitle: "Rejection",
      },
      () => {
        if (this._formRef.current) {
          this._formRef.current.setFieldsValue(record);
        }
      }
    );
  };

  formHandleSubmit = async (values: any) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.reimbursementPage224
    );

    this.setState({
      apiState: "loading",
    });
    const { receipt_id, total_amount } = this.state.reimburseFormData;
    if (
      this.state.modalTitle === "Approve" &&
      Number(values.paid_amount) > Number(total_amount)
    ) {
      notification.error({ message: "Paid amount should be less or equal" });
      return;
    }
    const url =
      this.state.modalTitle === "Approve"
        ? "/update_receipt/accept_receipt"
        : "/update_receipt/reject_receipt";
    const { ok, message } = await fetchUtil("POST", `${url}`, {
      receipt_id,
      ...values,
    });

    if (ok) {
      notification.success({ message });
      this.handleCancel();
      this.fetchData();
      this.getReimbursementSummary();
      this.setState({ apiState: "success" });
    } else {
      notification.error({ message });
      this.setState({ apiState: "error" });
    }
  };

  appAndClaimAmountCalculate = (record: any) => {
    return (
      <>
        <span>
          <Currency fallback="--">{record.paid_amount}</Currency>
        </span>
        <span> / </span>
        <span>
          <Currency>{record.total_amount}</Currency>
        </span>
      </>
    );
  };

  handleCancel = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.reimbursementPage225
    );
    this.setState({ openApproveModal: false, modalTitle: "" });
  };

  fetchData = async () => {
    const { ok, data, message } = await getReceiptList(
      this.props.activeRole,
      this.state.activePage,
      this.state.timeZoneOfset,
      this.state.noOfRows,
      this.state.selectedMonth + 1 + "" === "13"
        ? "ALL"
        : this.state.selectedMonth + 1 + "",
      this.state.selectedYear + "",
      this.props.sortOrderInfo || "DATE_DESC"
    );
    if (ok) {
      let totalArrayLength = 0;
      if (data && data.length > 0) {
        totalArrayLength = data[0].total_rows;
      }
      this.setState({
        receiptList: data as Array<ApiRowResponse>,
        totalItemCount: totalArrayLength,
        apiState: "idle",
      });
    } else {
      this.setState(
        {
          receiptList: [],
          apiState: "idle",
          totalItemCount: 0,
        },
        () => {
          notification.error({
            message,
          });
        }
      );
    }
  };
  sortedData = async (order: string) => {
    const { ok, data } = await getReceiptList(
      this.props.activeRole,
      this.state.activePage,
      this.state.timeZoneOfset,
      this.state.noOfRows,
      this.state.selectedMonth + 1 + "" === "13"
        ? "ALL"
        : this.state.selectedMonth + 1 + "",
      this.state.selectedYear + "",
      order
    );
    if (ok) {
      let totalArrayLength = 0;
      if (data && data.length > 0) {
        totalArrayLength = data[0].total_rows;
      }
      this.setState({
        receiptList: data as Array<ApiRowResponse>,
        totalItemCount: totalArrayLength,
        apiState: "idle",
      });
    }
  };

  handleAddNew = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.reimbursementPage226
    );
    this.props.history.push(`/app/reimbursement/new`);
  };
  handleeChange = (_pagination: any, filters: any, sorter: any) => {
    // console.log(sorter);

    // // console.log(filters);
    // // console.log(Pagination);
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", _pagination.current + "");
    let sortOrder = "";

    if (sorter.columnKey === "receipt_id") {
      if (sorter.order === "descend") {
        sortOrder = "ID_DESC";
        this.props.setSortOrder(sortOrder);
        // this.props.setSorter(sorter);
      }
      if (sorter.order === "ascend") {
        sortOrder = "ID_ASC";
        this.props.setSortOrder(sortOrder);
        // this.props.setSorter(sorter);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
        // this.props.setSorter(sorter);
      }
    } else if (sorter.columnKey === "emp_name") {
      if (sorter.order === "descend") {
        sortOrder = "APPL_BY_DESC";
        this.props.setSortOrder(sortOrder);
        // this.props.setSorter(sorter);
      }
      if (sorter.order === "ascend") {
        sortOrder = "APPL_BY_ASC";
        this.props.setSortOrder(sortOrder);
        // this.props.setSorter(sorter);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
        // this.props.setSorter(sorter);
      }
    } else if (sorter.columnKey === "receipt_date") {
      // console.log(sorter);
      if (sorter.order === "descend") {
        sortOrder = "DATE_DESC";

        this.props.setSortOrder(sortOrder);
        // this.props.setSorter(sorter);
      }
      if (sorter.order === "ascend") {
        sortOrder = "DATE_ASC";
        this.props.setSortOrder(sortOrder);
        // this.props.setSorter(sorter);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
        // this.props.setSorter(sorter);
      }
    }
    query.set("sort_order", sortOrder);

    this.props.history.push("?" + query);

    this.props.setSorter(sorter);

    // const promiseA = new Promise((resolutionFunc, rejectionFunc) => {
    //   resolutionFunc(this.props.setSorter(sorter));
    // });

    // promiseA.then(() => {
    // console.log(this.props.sorterData);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter ? sorter : this.props.sorterData,
    });
    // });
  };

  handlePageChange = (pageNumber: number) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", pageNumber + "");
    this.props.history.push("?" + query);
  };

  handleFetchData = () => {
    return getReceiptList(
      1,
      this.state.activePage,
      this.state.timeZoneOfset,
      1,
      this.state.selectedMonth + 1 + "",
      this.state.selectedYear + "",
      this.props.sortOrderInfo || "DATE_DESC"
    );
  };

  handleDownload = async (acceptType: string, selectedFields: string) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.reimbursementPage227
    );

    return getTableExportBlob(
      1,
      this.state.timeZoneOfset,
      999999,
      this.state.selectedMonth + 1 + "" === "13"
        ? "ALL"
        : this.state.selectedMonth + 1 + "",
      this.state.selectedYear + "",
      this.props.sortOrderInfo || "DATE_DESC",
      acceptType,
      selectedFields,
      this.props.activeRole
    );
  };

  render() {
    const summaryData: any = this.state.summary;

    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    const actionColumn = {
      title: "Actions",
      dataIndex: "status",
      render: this.actionCalculate,
      width: 174,
    };
    const columns: Array<ColumnType<ApiRowResponse>> = [
      {
        title: "Receipt id",
        dataIndex: "receipt_id",
        key: "receipt_id",
        width: 120,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "receipt_id" && sortedInfo.order,
        // ellipsis: true,
        defaultSortOrder: "descend",
      },
      {
        title: "Purpose",
        dataIndex: "description",
        key: "description",
        width: 180,

        ellipsis: true,
      },
      {
        title: "Applied on",
        dataIndex: "reimbursement_date",
        key: "reimbursement_date",
        width: 120,
        filteredValue: filteredInfo.reimbursement_date || null,
        render: (reimbursement_date: string) => ({
          children: reimbursement_date
            ? format(new Date(reimbursement_date), "dd/MM/yyyy")
            : "",
        }),
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}> Receipt Date </span>,
        dataIndex: "receipt_date",
        key: "receipt_date",
        filteredValue: filteredInfo.receipt_date || null,
        width: 120,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "receipt_date" && sortedInfo.order,
        render: (receipt_date: string) => ({
          children: receipt_date
            ? format(new Date(receipt_date), "dd/MM/yyyy")
            : "",
        }),
      },
      {
        title: "Applied by",
        dataIndex: "emp_name",
        key: "emp_name",
        filteredValue: filteredInfo.net_total || null,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "emp_name" && sortedInfo.order,
        ellipsis: true,
        width: 144,
      },
      {
        title: "Approve / Claim amount",
        dataIndex: "amount",
        key: "amount",
        width: 184,
        align: "right",
        render: (_text, record) => this.appAndClaimAmountCalculate(record),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 104,
        filteredValue: filteredInfo.address || null,
        ellipsis: true,
        render: (text, record) => ({
          children: this.statusCalculate(record.status),
        }),
      },
    ];
    if (
      this.props.activeRole === LoginRole.ADMIN ||
      this.props.activeRole === LoginRole.SUPERuSER
    ) {
      columns.push(actionColumn);
    }
    // console.log(this.props.sortOrderInfo);
    return (
      <IMTContent layoutRef={this._dndDivRef} withoutMargin={true}>
        <Helmet>
          <title>{titles.ReimbursementPage}</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={["Reimbursement"]}
          style={{ paddingLeft: "0" }}
          actions={
            <Space>
              {this.props.activeRole === LoginRole.EMPLOYEE &&
              this.props.saveEmpDetails ? (
                <Button type="primary" onClick={this.handleAddNew}>
                  + Add new
                </Button>
              ) : (
                <>
                  {!this.props.saveEmpDetails &&
                  this.props.activeRole === LoginRole.EMPLOYEE ? (
                    <span style={{ color: "red" }}>
                      Profile is incomplete. Reimbursement cannot be added
                    </span>
                  ) : (
                    ""
                  )}
                </>
              )}
            </Space>
          }
        />
        <div
          className={commonStyle.dragOverLabel}
          style={{ display: this.state.isDragOver ? "flex" : "none" }}
        >
          Drop here
        </div>

        <YearMonthDropdown
          month={this.state.selectedMonth}
          year={this.state.selectedYear}
          marginLeft="0px"
          payrollFlag={false}
        />
        <Row className={styles.summaryDiv} style={{ marginTop: "20px" }}>
          {this.props.activeRole === LoginRole.ADMIN ||
          this.props.activeRole === LoginRole.CA ||
          this.props.activeRole === LoginRole.SUPERuSER ? (
            <SummaryTags
              nameValuePairs={[
                { name: "Count", value: summaryData.reimbursement_count },
                {
                  name: "Total amount",
                  value: summaryData.reimbursement_amount,
                  isCurrency: true,
                },
                { name: "Approvals pending", value: summaryData.pending },
              ]}
            />
          ) : (
            ""
          )}
        </Row>
        <Table
          rowKey="id"
          style={{ maxWidth: "80%", cursor: "pointer" }}
          columns={columns}
          dataSource={this.state.receiptList}
          onChange={this.handleeChange} // tslint:disable-next-line jsx-no-lambda
          onRow={(record, _rowIndex) => {
            return {
              onClick: (_event) => {
                registerEvent(
                  ga.EVENT_CATEGORY_BUTTON_CLICK,
                  ga.EVENT_CLICK,
                  ga.events.reimbursementPage228
                );
                const id = record.id;
                this.props.history.push(`/app/reimbursement/${id}`, {
                  search: this.props.location.search,
                });
              },
            };
          }}
          loading={this.state.tableLoading}
          size="large"
          pagination={{
            size: "small",
            current: this.state.activePage,
            pageSize: this.state.noOfRows,
            showSizeChanger: false,
            onChange: this.handlePageChange,
            total: this.state.totalItemCount,
            position: ["topRight"],
            style: { margin: "0px" },
          }}
        />
        {this.props.activeRole !== LoginRole.EMPLOYEE &&
          this.state.receiptList.length > 0 && (
            <ExportButton
              onDownload={this.handleDownload}
              onFetchData={this.handleFetchData}
              selectedList={[
                "Customer GSTIN Id",
                "Company Type",
                "Amount",
                "Cess Rate ID",
                "Total Amount",
                "Comment",
                "Is Active",
                "CGST",
                "Customer Name",
                "Paid Amount",
                "Is Active",
                "Amount",
                "Transaction ID",
                "Cess Amount",
                "Row ID",
                "Description",
                "Category ID",
                "Status",
                "Customer Id",
                "Category",
                "HSN No",
                "GST Rate ID",
              ]}
            />
          )}
        {/* {this.placeButton()} */}
        <Modal
          width={420}
          title={this.state.modalTitle}
          onCancel={this.handleCancel}
          visible={this.state.openApproveModal}
          destroyOnClose={true}
          footer={null}
          centered
          maskClosable={false}
        >
          <Form
            initialValues={this.state.reimburseFormData}
            onFinish={this.formHandleSubmit}
            ref={this._formRef}
          >
            <Row>
              {this.state.modalTitle === "Approve" ? (
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="paid_amount"
                  label="Paid amount"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      pattern: /^[0-9]{1,}.*$/,
                      message: "Please enter valid Paid amount!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : (
                ""
              )}
              {this.state.modalTitle === "Rejection" ? (
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="receipt_id"
                  label="Reimbursement id"
                >
                  <Input disabled={true} />
                </Form.Item>
              ) : (
                ""
              )}

              <Form.Item
                className={commonStyle["w-100"]}
                name="comment"
                label="Comment"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please enter valid Comment!",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Row>
            <Row>
              <Button
                type="primary"
                id="yes-btn"
                htmlType="submit"
                loading={this.state.apiState === "loading"}
              >
                Submit
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                // type="primary"
                type="ghost"
                onClick={this.handleCancel}
              >
                Cancel
              </Button>
            </Row>
          </Form>
        </Modal>
      </IMTContent>
    );
  }
}

export default connector(ReimbursementPage);
