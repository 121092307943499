import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as api from "~/api/auth";
import { LoginRole } from "~/api/auth";
import { APIState } from "~/api/common";
// import MembershipPlan from "~/feature/membership/MembershipPlan";
import { getDefaultCompRole } from "~/lib/companyRole";

export interface LoginState {
  showModal: boolean;
  apiState: APIState;
  errorMsg?: string;
  user?: api.AuthUser;
}

const initialState: LoginState = {
  showModal: false,
  apiState: "idle",
};

export const doLogin = createAsyncThunk(
  "landing/user/login",
  async (reqArgs: api.LoginRequest) => {
    return api.login(reqArgs);
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    showModal(state) {
      state.showModal = true;
    },
    hideModal(state) {
      state.showModal = false;
      state.errorMsg = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase("imt/INIT", (_state, _action) => {
        if (
          localStorage.getItem("imt__token") &&
          localStorage.getItem("imt__user") &&
          !window.location.pathname.startsWith("/app") &&
          !window.location.pathname.startsWith("/onboard") &&
          !window.location.pathname.startsWith("/membership-plan")
        ) {
          const user = JSON.parse(
            localStorage.getItem("imt__user")!
          ) as api.AuthUser;
          setTimeout(() => {
            // window.location.href = user.onboarding_status ? "/app" : "/onboard";
            window.location.href =
              user.onboarding_status &&
              !user.plan_purchased &&
              user.activeRole === LoginRole.EMPLOYEE
                ? "/app/expiredMembership"
                : user.onboarding_status && !user.plan_purchased
                ? "/app/membership-plan"
                : user.onboarding_status && user.plan_purchased
                ? "/app"
                : "/onboard";
          }, 0);
        }
      })
      .addCase(doLogin.pending, (state, _action) => {
        state.apiState = "loading";
      })
      .addCase(doLogin.rejected, (state, action) => {
        state.apiState = "error";
        state.errorMsg = action.error.message;
      })
      .addCase(doLogin.fulfilled, (state, action) => {
        const userData = {
          ...action.payload.user,
          ...getDefaultCompRole(
            action.payload.user.company_details!,
            action.payload.user.company_id
          ),
          ...action.payload.user.primary_associated_company_id,
        };
        // userData.membership_plan = false;
        const value = userData.company_details;
        userData.company_details = value;
        // ***************************** remove when megha di send key name *******************
        // userData.membership_plan = true;

        const value1 = userData.gstin;
        userData.gstin = value1;

        localStorage.setItem("imt__token", action.payload.token);
        const firstName = userData.first_name;
        const lastName = userData.last_name;
        const terms_conditions_agree = userData.terms_conditions_agree;
        userData.first_name = firstName;
        userData.last_name = lastName;
        userData.terms_conditions_agree = terms_conditions_agree;

        state.apiState = "success";
        state.user = userData;
        state.showModal = false;
        localStorage.setItem("imt__user", JSON.stringify(userData));

        setTimeout(() => {
          // window.location.href = userData.onboarding_status && userData.membership_plan
          //   ? "/app":"/membershipPlan"
          //   : "/onboard";
          window.location.href =
            userData.onboarding_status &&
            !userData.plan_purchased &&
            userData.activeRole !== LoginRole.EMPLOYEE &&
            userData.activeRole !== LoginRole.CA
              ? "/app/membership-plan"
              : userData.onboarding_status && userData.plan_purchased
              ? "/app"
              : (userData.onboarding_status &&
                  !userData.plan_purchased &&
                  userData.activeRole === LoginRole.EMPLOYEE) ||
                userData.activeRole === LoginRole.CA
              ? "/app/expiredMembership"
              : "/onboard";
        }, 100);
      });
  },
});
