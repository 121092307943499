import { ConsoleSqlOutlined } from "@ant-design/icons";
import { Input, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import * as React from "react";
import { Party } from "./tally-dirty-entity-type";

interface IProps {
  partyList: Array<Party>;
  selectedIds: Array<string | number>;
  onSelectionChange: (idList: Array<string | number>) => void;
}

export default function PartySelect({
  partyList,
  // selectedIds,
  onSelectionChange,
}: IProps) {
  const [searchValue, setSearchValue] = React.useState("");
  const columnDef = React.useMemo(() => {
    const definition: Array<ColumnType<Party>> = [
      {
        title: "Name",
        dataIndex: "company_name",
        filteredValue: [searchValue],
        onFilter: (value, record) => {
          return (
            String(record.company_name)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.gstin)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.billing_address_state)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.billing_address_country)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase()) ||
            String(record.symbol_name)
              .toLowerCase()
              .includes(String(searchValue).toLowerCase())
          );
        },
      },
      {
        title: "GSTIN",
        dataIndex: "gstin",
        render: (_, record) => {
          return <>{record.gstin ? record.gstin : "N/A"}</>;
        },
      },
      {
        title: "State",
        dataIndex: "billing_address_state",
      },
      {
        title: "Country",
        dataIndex: "billing_address_country",
      },
      {
        title: "Currency",
        // dataIndex: "symbol_name",
        dataIndex: "symbol_name",
      },
    ];

    return definition;
  }, [searchValue]);

  const rowSelection = React.useMemo(() => {
    const selection: TableRowSelection<Party> = {
      onChange(_selectedRowKeys, selectedRows) {
        onSelectionChange(
          selectedRows.map((it) => {
            if (it.customer_gstin_id) {
              return it.customer_gstin_id + "-" + it.customer_id;
            } else {
              return "n-" + it.customer_id;
            }
          })
        );
      },
      // selectedRowKeys: selectedIds
    };
    return selection;
  }, [onSelectionChange]);

  return (
    <>
      <Input
        placeholder="Search Here"
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          setSearchValue(e.target.value);
        }}
        style={{ maxWidth: "40%" }}
        allowClear
      />
      <Table
        rowKey={(record) =>
          record.customer_gstin_id
            ? record.customer_gstin_id + "" + record.customer_id
            : "n" + record.customer_id
        }
        style={{ maxWidth: "80%", cursor: "pointer" }}
        columns={columnDef}
        rowSelection={rowSelection}
        dataSource={partyList}
        size="large"
      />
    </>
  );
}
