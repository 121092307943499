import React, { Component } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  notification,
  AutoComplete,
  Spin,
} from "antd";
import commonStyle from "~/component/common.module.css";
import { AdminDetail } from "~/api/profile";
import { FormInstance } from "antd/lib/form";
import FieldInfo from "~/component/FieldInfo";
import ProfilePicUpload from "~/fragment/upload/ProfilePicUpload";
import { APIState, fetchUtil } from "~/api/common";
import { LoginRole } from "~/api/auth";
import { AuthState } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  apiState: APIState;
  data: AdminDetail;
  onDataChange: (newData: AdminDetail) => void;
  handlesubmit: (newData: AdminDetail) => void;
  stateList: any;
  onDirty: () => void;
}
interface State {
  industryList: Array<{ id: number; name: string }>;
  currencyList: Array<CurrencyItem>;
  companyList: Array<any>;
  rowCompanyList: any;
  cityList: Array<any>;
  rowCityList: any;
  loadDetail: boolean;
}

type CurrencyItem = {
  id: number;
  currency_name: string;
  symbol: string;
  symbol_name: string;
};

class PrimaryDetailsPane extends Component<Props, State> {
  state: State = {
    industryList: [],
    currencyList: [],
    companyList: [],
    rowCompanyList: [],
    cityList: [],
    rowCityList: [],
    loadDetail: false,
  };

  _formRef = React.createRef<FormInstance<any>>();

  componentDidMount() {
    this.getIndustryList();
    this.getCurrencyList();
  }

  componentDidUpdate(prevProps: Props, _prevState: State) {
    if (prevProps.data !== this.props.data) {
      this._formRef.current?.setFieldsValue(this.props.data);
    }
  }

  // this.setState({
  //   industryList,
  // });
  async getIndustryList() {
    const { ok, message, json } = await fetchUtil("GET", "/get_industry");
    if (!ok) {
      notification.error({ message });
    } else {
      this.setState({
        industryList: json,
      });
    }
  }

  async getCurrencyList() {
    const { ok, message, json } = await fetchUtil("GET", "/get_currency");
    if (!ok) {
      notification.error({ message });
    } else {
      this.setState({
        currencyList: json,
      });
    }
  }
  loadPickupCityLists = async (value: string) => {
    fetchUtil("GET", `/get_city_name?city=${value}`).then(
      ({ ok, message, json }) => {
        if (!ok) {
          return { ok, message };
        } else {
          const a: any = [];
          const obj: any = json;
          obj.forEach((el: any) => {
            const objj = el.city;
            const k = {
              value: objj,
            };
            a.push(k);
          });
          this.setState({
            cityList: a,
            rowCityList: obj,
          });
        }
      }
    );
  };
  loadPickupLists = async (value: string) => {
    fetchUtil("POST", "/get_company_details", {
      company_name: value,
    }).then(({ ok, message, json }) => {
      if (!ok) {
        return { ok, message };
      } else {
        const a: any = [];
        const obj: any = json;
        obj.forEach((el: any) => {
          const objj = el.company_name;
          const k = {
            value: objj,
          };
          a.push(k);
        });
        this.setState(
          {
            companyList: a,
            rowCompanyList: obj,
          },
          () => {
            obj.forEach((el: any) => {
              if (el.company_name === value) {
                if (this.state.rowCompanyList.length === 1) {
                  if (this._formRef.current) {
                    this._formRef.current.setFieldsValue({
                      cin: obj[0].cin,
                    });
                    this.handleCINChange(obj[0].cin);
                  }
                }
              }
            });
          }
        );
      }
    });
  };

  handleLogoChange = (newLogo: string) => {
    if (this._formRef.current) {
      const values = this._formRef.current.getFieldsValue() as AdminDetail;
      values.company_logo = newLogo;
      this.props.onDataChange(values);
      this.props.onDirty();
    }
  };

  handleFinish = (values: any) => {
    delete values.company_logo;
    this.props.onDataChange(values);
  };
  handleCINChange = (cinNumber: string) => {
    this.setState({
      loadDetail: true,
    });
    fetchUtil("POST", "/get_company_details_from_cin", {
      cin: cinNumber,
    }).then(({ ok, message, json }) => {
      if (!ok) {
        this.setState({
          loadDetail: false,
        });
        notification.error({ message: "No result found for this CIN" });
        return { ok, message };
      } else {
        if (json.length > 0) {
          if (this._formRef.current) {
            this._formRef.current.setFieldsValue({
              address: json[0].address,
              company_city: json[0].company_city,
              company_state_name: json[0].company_state_name,
              company_state: json[0].company_state,
              company_country: json[0].company_country,
              company_pincode: json[0].company_pincode,
              authorised_capital: json[0].authorised_capital,
              paid_up_capital: json[0].paid_up_capital,
              pending_charges: json[0].pending_charges,
              directors: json[0].directors,
              company_status: json[0].company_status,
            });
          }
          this.setState({
            loadDetail: false,
          });
        } else {
          notification.error({ message: "No result found for this CIN" });
          if (this._formRef.current) {
            this._formRef.current.setFieldsValue({
              address: "",
              company_city: "",
              company_state_name: "",
              company_state: 0,
              company_country: "",
              company_pincode: "",
              authorised_capital: "",
              paid_up_capital: "",
              pending_charges: 0,
              directors: 0,
              company_status: "",
            });
          }
          this.setState({
            loadDetail: false,
          });
        }
        return { ok, message, data: json };
      }
    });
  };
  handleCitySelect = (value: string, opt: any) => {
    const id = opt.key;
    const cityData = this.state.rowCityList.find((it: any) => it.id === id);

    if (cityData) {
      const { city, state, country, state_id } = cityData;

      // Update the form fields and component state with selected city data
      if (this._formRef.current) {
        this._formRef.current.setFieldsValue({
          company_city: city,
          company_state_name: state,
          company_country: country,
          company_state: state_id,
        });
      }
    }
  };

  handleOnValueChange = (changedValues: any, _values: any) => {
    this.props.onDirty();
    if (changedValues.company_name && changedValues.company_name.length > 4) {
      this.setState({ companyList: [] });
      this.loadPickupLists(changedValues.company_name);
    } else if (
      changedValues.legal_name &&
      changedValues.legal_name.length < 4
    ) {
      this._formRef.current!.setFieldsValue({
        cin: "",
        address: "",
        company_city: "",
        company_state: 0,
        company_state_name: "",
        company_country: "",
        company_pincode: "",
        authorised_capital: "",
        paid_up_capital: "",
        pending_charges: 0,
        directors: 0,
        company_status: "",
      });
    }
    if (changedValues.company_city && changedValues.company_city.length > 3) {
      this.setState({ cityList: [] });
      this.loadPickupCityLists(changedValues.company_city);
    }
  };

  getUppercaseVal = (newInput: string) => {
    return newInput && newInput.toUpperCase();
  };

  render() {
    const loading = this.props.apiState === "loading";

    const industryView = (
      <Form.Item label="Industry">
        <input
          className="ant-input"
          defaultValue={
            this.state.industryList.find(
              (it) => it.id === this.props.data.industry
            )?.name
          }
          readOnly
        />
      </Form.Item>
    );
    const currencyView = (
      <Form.Item label="Currency">
        <input
          className="ant-input"
          defaultValue={
            this.state.currencyList.find(
              (it) => it.id === this.props.data.currency
            )?.currency_name
          }
          readOnly
        />
      </Form.Item>
    );
    return (
      <Spin spinning={this.state.loadDetail}>
        <Form
          initialValues={this.props.data}
          ref={this._formRef}
          onValuesChange={this.handleOnValueChange}
          onFinish={this.props.handlesubmit}
        >
          <Form.Item name="company_state" hidden />
          <Form.Item name="authorised_capital" hidden />
          <Form.Item name="paid_up_capital" hidden />
          <Form.Item name="pending_charges" hidden />
          <Form.Item name="directors" hidden />
          <Form.Item name="company_status" hidden />
          <Row gutter={{ sm: 36, md: 56, lg: 64, xl: 100 }}>
            <Col md={8} lg={8} xl={8} xxl={6}>
              <Form.Item
                // required
                className={commonStyle["w-100"]}
                name="company_logo"
                label="Logo"
                rules={[
                  {
                    // required: true,
                    message: "Please upload image/logo!",
                  },
                ]}
              >
                <ProfilePicUpload
                  hashedFileName={this.props.data.company_logo}
                  onChange={this.handleLogoChange}
                  is_disable={
                    this.props.activeRole === LoginRole.SUPERuSER ? true : false
                  }
                  square
                  companyLogo
                />
              </Form.Item>
              <Form.Item
                className={commonStyle["w-100"]}
                name="company_name"
                label="Entity name"
                /* getValueFromEvent={this.getUppercaseVal} */
                rules={[
                  {
                    type: "string",
                    required: true,
                    // pattern: /^[a-zA-Z0-9]{2,}.*$/,
                    message: "Please enter company name!",
                  },
                ]}
              >
                {this.props.activeRole === LoginRole.SUPERuSER ? (
                  <Input readOnly />
                ) : (
                  <AutoComplete
                    options={this.state.companyList}
                    // tslint:disable-next-line: jsx-no-lambda
                    filterOption={(inputValue, option: any) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                )}
              </Form.Item>
              {this.props.data.company_name !== this.props.data.legal_name ? (
                <FieldInfo text={this.props.data.legal_name} />
              ) : null}
              {this.props.data.establishment_type !== 1 &&
              this.props.data.establishment_type !== 2 &&
              this.props.data.establishment_type !== 3 &&
              this.props.data.establishment_type !== 4 &&
              this.props.data.establishment_type !== 8 ? (
                <Form.Item
                  name="cin"
                  label="CIN / LLPIN"
                  rules={[
                    {
                      required:
                        this.props.data.establishment_type === 3 ||
                        this.props.data.establishment_type === 2 ||
                        this.props.data.establishment_type === 4 ||
                        this.props.data.establishment_type === 8 ||
                        this.props.data.establishment_type === 1
                          ? false
                          : true,
                      pattern: /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/,
                      message: "Please enter valid CIN#",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              ) : null}

              {this.props.activeRole === LoginRole.SUPERuSER ? (
                industryView
              ) : (
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="industry"
                  label="Industry"
                  rules={[
                    {
                      required: true,
                      message: "Please enter valid industry!",
                    },
                  ]}
                >
                  <Select showSearch>
                    {this.state.industryList.map((industry) => (
                      <Select.Option key={industry.id} value={industry.id}>
                        {industry.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                className={commonStyle["w-100"]}
                name="address"
                label="Address"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please enter valid address!",
                  },
                ]}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <div role="separator" />
            <Col md={8} lg={8} xl={8} xxl={6}>
              <Form.Item
                className={commonStyle["w-100"]}
                name="company_city"
                label="City"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please enter city!",
                  },
                ]}
              >
                {this.props.activeRole === LoginRole.SUPERuSER ? (
                  <Input readOnly />
                ) : (
                  <AutoComplete
                    id="chrome-off"
                    onSelect={this.handleCitySelect}
                  >
                    {this.state.rowCityList.map((v: any, idx: number) => {
                      return (
                        <AutoComplete.Option key={v.id} value={`${v.id}`}>
                          <div>
                            <span>{v.city}</span>{" "}
                            <span
                              style={{
                                fontSize: 10,
                                color: "var(--primary-color)",
                              }}
                            >
                              {v.state}, {v.country}
                            </span>
                          </div>
                        </AutoComplete.Option>
                      );
                    })}
                  </AutoComplete>
                )}
              </Form.Item>

              <Form.Item
                className={commonStyle["w-100"]}
                label="State"
                name="company_state_name"
                rules={[
                  { required: true, message: "Please enter valid state" },
                ]}
              >
                <input className="ant-input" readOnly />
              </Form.Item>
              <Form.Item
                className={commonStyle["w-100"]}
                name="company_country"
                label="Country"
                rules={[
                  {
                    type: "string",
                    required: false,
                    message: "Please enter country!",
                  },
                ]}
              >
                <Input readOnly />
              </Form.Item>

              <Form.Item
                className={commonStyle["w-100"]}
                name="company_pincode"
                label="Pincode"
                rules={[
                  {
                    type: "string",
                    required: true,
                    pattern: /^[1-9]{1}[0-9 ]{4,}$/,
                    message: "Please enter valid pincode !",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className={commonStyle["w-100"]}
                name="company_website"
                label="Company website"
                rules={[
                  {
                    type: "url",
                    required: false,
                    message: "Please enter valid company website!",
                  },
                ]}
              >
                <Input
                  type="url"
                  readOnly={this.props.activeRole === LoginRole.SUPERuSER}
                />
              </Form.Item>
              {this.props.activeRole === LoginRole.SUPERuSER ? (
                currencyView
              ) : (
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="currency"
                  label="Currency"
                  rules={[
                    {
                      required: false,
                      message: "Please enter valid currency!",
                    },
                  ]}
                >
                  <Select showSearch>
                    {this.state.currencyList.map((opt) => (
                      <Select.Option key={opt.id} value={opt.id}>
                        {opt.currency_name} ({opt.symbol})
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
          </Row>
          {this.props.activeRole === LoginRole.SUPERuSER ? (
            <></>
          ) : (
            <div>
              <Button loading={loading} type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          )}
        </Form>
      </Spin>
    );
  }
}

export default connector(PrimaryDetailsPane);
