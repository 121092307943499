import {
  AutoComplete,
  Input,
  notification,
  Skeleton,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { invLitMakeFooterRenderer } from "./invLitTableRenderers";
import { TaxTeritoryType, TAX_INCLUSION } from "~/lib/taxes";
import { PaymentDetails } from "~/feature/expense/ExpenseModal";
import usePersistantCallbackRef from "~/lib/hook/usePersistantCallbackRef";
import { InvoiceLineItemRecord } from "./InvoiceLineItemTypes";
import {
  CurrencyInfo,
  LineItemError,
} from "~/fragment/line-items/LineItemTypes";
import {
  litAddNewButton,
  litPercentageInput,
  litCurrencyInput,
  litDeleteNewButton,
  litGSTRateInput,
  litInput,
  litTextArea,
  /* litProductRenderer, */
  litCESSRateInput,
  litUnitInput,
} from "~/fragment/line-items/litTableRenderers";
import { set /* , toString, values */ } from "lodash";
import classes from "./InvoiceLineItem.module.css";
import ProductColumnHeader from "./ProductColumnHeader";
import usePrevProps from "~/lib/hook/usePrevProps";
import { GST_OUT_OF_SCOPE } from "~/lib/constants";
import {
  useCESSRateList,
  useGSTRateList,
} from "~/lib/hook/api-hook/picklistHooks";
import { CESSRateItem, GSTRate } from "~/api/expense";
import { getGST, TaxInfo } from "~/lib/taxCalculation";
import { getProductList } from "~/api/vendor";
/* import { string } from "random-js"; */
import FieldInfo from "~/component/FieldInfo";
import debounce from "lodash/debounce";

// Set this as key of column def if you want to remove for a condition
const SPCL_REMOVED_COLUMN = "__spcl_removed";
const DEFAULT_GST_RATE_PERCENTAGE = 18;

interface HistoryData {
  netTotal: string | number;
  // FIXME: fix spelling error
  tdsAmont: string | number;
  // FIXME: remove any, use properly defined type
  paymentDetails: any;
  invoiceId: number | null;
  conversionRate: number | undefined;
  onRefresh: () => void;
}
type setDataType = {
  id: number;
  product_name: string;
  description: string;
  hsn_sac: string;
  rate: string;
  unit_id: number;
  gst_rate_id: number;
  is_taxable: boolean;
  category_id: number;
  product_service_type: string;
  is_reverse_charge_applicable: boolean;
  is_non_gst_supply: boolean;
  category_name: string;
  category_path: string;
};
interface Props {
  readOnly?: boolean;
  hasCess?: boolean;
  Data?: setDataType;
  rowData?: Array<InvoiceLineItemRecord>;
  isLoading?: boolean;
  onRowDataChange?: (
    newRowData: Array<InvoiceLineItemRecord>,
    deletedRow?: InvoiceLineItemRecord
  ) => void;
  tax_inclusion?: TAX_INCLUSION;
  taxTeritoryType?: TaxTeritoryType;
  currency?: CurrencyInfo;
  paymentDetails?: PaymentDetails;
  onAddProduct: () => void;
  isCopy: boolean;
  totalPayAmount?: number;
  tdsInfo?: {
    tdsRate?: number;
    tdsAmount?: number;
  };
  sameState?: boolean;
  getTotal: (data: any) => void;
  exportType: any;
  paymentHistoryData?: HistoryData;
}
export const SKELETON_ROW: InvoiceLineItemRecord = {
  row_id: Math.random(),
  product_id: null,
  product_name: "",
  description: "",
  hsn_sac: "",
  quantity: 0,
  rate: 0,
  amount: 0,
  discount_rate: 0,
  cgst: 0,
  sgst: 0,
  igst: 0,
  cess_amount: 0,
  gst_rate_id: 0,
  cess_rate_id: 0,
  unit_id: null,
  is_active: true,
  extra: {
    gstRate: undefined,
    cessRate: undefined,
  },
};

const fillRelatedColumns = (
  oldRow: InvoiceLineItemRecord,
  row: InvoiceLineItemRecord,
  taxInclusion: TAX_INCLUSION,
  taxTeritoryType: TaxTeritoryType
) => {
  const taxInfo: TaxInfo = {
    cessPercentage: row.extra?.cessRate?.cessPercentage || 0,
    gstPercentage: row.extra?.gstRate?.rate || 0,
    taxInclusion,
    taxTeritoryType,
  };
  // This is a way of how we can use oldRow to add business logic which is defined on column change
  if (oldRow.extra?.product !== row.extra?.product && row.extra?.product) {
    row.hsn_sac = row.extra?.product.hsn_sac;
    row.unit_id =
      row.extra?.product?.product_service_type === "SERVICES"
        ? null
        : row.extra?.product.unit_id
        ? row.extra?.product.unit_id
        : null;
    row.gst_rate_id = row.extra?.product.gst_rate_id! ?? 9;
    row.category_name = row.extra?.product.category_name;
    row.full_path = row.extra.product.category_path;
    row.description = row.extra?.product.description;
    row.product_service_type = row.extra?.product?.product_service_type;
    row.quantity =
      row.extra?.product?.product_service_type === "SERVICES" ? null : 1;
  }

  if (row.extra?.product && oldRow.extra?.product !== row.extra.product) {
    row.rate = +Number(row.extra.product.rate);
  }

  row.amount = (row.rate ?? 0) * (row.quantity ?? 1);

  if (typeof row.discount_rate === "number") {
    row.amount = ((100 - row.discount_rate) / 100) * row.amount;
  }

  const taxAmounts = getGST(row.amount, taxInfo);
  row.igst = taxAmounts.igst;
  row.sgst = taxAmounts.sgst;
  row.cgst = taxAmounts.cgst;
  return row;
};

/**
 * Clones row object, sharing objects inside extra as we know the objects in extra are not mutated
 * So this is Little-optimized than lodash.deepclone
 * Caution: if we clones row.extra.{vendor,product} objects fillInterdependentColumns will break as it use ref eq (===) check to figure out if they have changed!
 */
const cloneRow = (row: InvoiceLineItemRecord) => {
  const newRow: InvoiceLineItemRecord = { ...row, extra: { ...row.extra } };
  return newRow;
};

export const validateColumns = (
  row: InvoiceLineItemRecord
  // allLiProps: Readonly<Props | Expense>
) => {
  const errors: Array<LineItemError> = [];
  // cess is required if hasCess is true
  if (!row.product_name) {
    errors.push({
      dataIndex: "product_name",
      message: "Please enter a product",
    });
  }
  if (row.discount_rate !== 100) {
    if (!row.amount) {
      errors.push({
        dataIndex: "amount",
        message: "Please enter a positive amount",
      });
    }
    if (!row.gst_rate_id) {
      errors.push({
        dataIndex: "gst_rate_id",
        message: "Please enter a tax rate",
      });
    }
  }
  if (!row.extra) {
    row.extra = { errors };
  }
  row.extra.errors = errors;
  return row;
};

const InvoiceLineItem = (props: Props) => {
  // Fake loading flag, to be used later, maintained for consistency
  // needed for showing plus & delete row buttons
  const [productData, setproducts] = useState({
    productlist: [] as any,
  });
  const [lastRowIdx, setLastRowIdx] = useState(0);
  const [isProductListLoading, setIsProductListLoading] = useState(false);
  const [noProductFound, setNoProductFound] = useState(false);
  const lastRowIndex = (props.rowData?.length ?? 0) - 1;

  const prevProps = usePrevProps(props);
  const { isGSTListLoading, gstRateList } = useGSTRateList();
  const { isCESSListLoading, cessRateList } = useCESSRateList();
  const isLoading = isGSTListLoading || isCESSListLoading || props.isLoading;

  const cloneRow = (row: InvoiceLineItemRecord) => {
    let newRow: InvoiceLineItemRecord;
    newRow = { ...row, extra: { ...row.extra } };
    return newRow;
  };

  const handleRowIndex = () => {
    setLastRowIdx(lastRowIdx + 1);
    handleAddRow();
  };

  const handleRemoveRow = usePersistantCallbackRef((index: number) => {
    setLastRowIdx(lastRowIdx - 1);
    if (props.rowData) {
      const rowTobeDeleted = props.rowData[index];
      props.onRowDataChange?.(
        props.rowData.filter((_, indx) => indx !== index),
        rowTobeDeleted.row_id >= 1 ? rowTobeDeleted : undefined
      );
    }
  });

  const handleAddRow = usePersistantCallbackRef(() => {
    const newRow: InvoiceLineItemRecord = {
      ...SKELETON_ROW,
      row_id: Math.random(),
    };
    let defaultGst: GSTRate | undefined;
    setproducts((oldInfo) => ({
      ...oldInfo,
      productlist: [],
    }));
    if (props.tax_inclusion === "OutOfTax") {
      defaultGst = gstRateList.find((it) => it.id === GST_OUT_OF_SCOPE);
    } else {
      defaultGst = gstRateList.find(
        (it) => it.rate === DEFAULT_GST_RATE_PERCENTAGE
      );
    }
    if (!defaultGst) {
      return;
    }
    newRow.gst_rate_id = defaultGst?.id ?? 0;
    newRow.extra!.gstRate = defaultGst;
    props.onRowDataChange?.([...(props.rowData ?? []), newRow]);
  });

  const handleCellChange = usePersistantCallbackRef(
    (propertyPath: string, index: number, value: unknown, record?: any) => {
      if (props.rowData && props.rowData.length > index) {
        const rowToBeChanged = props.rowData![index];
        const firstHalf = props.rowData!.slice(0, index);
        const secondHalf = props.rowData!.slice(index + 1);

        const newRow = set(cloneRow(rowToBeChanged), propertyPath, value);
        if (record) {
          if (propertyPath === "gst_rate_id") {
            set(newRow, "extra.gstRate", record);
          } else if (propertyPath === "cess_rate_id") {
            set(newRow, "extra.cessRate", record);
          } else if (propertyPath === "product_id") {
            set(newRow, "extra.product", record);
          } else if (propertyPath === "unit_id") {
            set(newRow, "extra.unitItem", record);
          }
        }

        const updatedRow = fillRelatedColumns(
          rowToBeChanged,
          newRow,
          props.tax_inclusion ?? "OutOfTax",
          props.taxTeritoryType ?? TaxTeritoryType.OTHER_TERITORY
        );

        const newRowData = [...firstHalf, updatedRow, ...secondHalf];
        props.onRowDataChange?.(newRowData);
      }
    }
  );

  const handleTaxInfoChange = usePersistantCallbackRef(() => {
    if (props.rowData) {
      const newRowData = props.rowData.map((row) => {
        const newRow = cloneRow(row);
        if (!newRow.extra) {
          newRow.extra = { errors: [] };
        }
        let gstRateRecord: GSTRate | undefined;
        let cessRateRecord: CESSRateItem | undefined;
        if (props.tax_inclusion === "OutOfTax") {
          gstRateRecord = gstRateList?.find((it) => it.id === GST_OUT_OF_SCOPE);
          newRow.gst_rate_id = gstRateRecord!.id;
        } else {
          if (
            newRow.gst_rate_id === GST_OUT_OF_SCOPE &&
            prevProps.tax_inclusion === "OutOfTax"
          ) {
            gstRateRecord = gstRateList.find(
              (it) => it.rate === DEFAULT_GST_RATE_PERCENTAGE
            );
            newRow.gst_rate_id = gstRateRecord!.id;
          } else {
            gstRateRecord = gstRateList.find(
              (it) => it.id === newRow.gst_rate_id
            );
          }
        }
        if (
          props.tax_inclusion === "OutOfTax" ||
          !props.hasCess ||
          newRow.cess_rate_id === 0
        ) {
          cessRateRecord = cessRateList.find((it) => it.cessPercentage === 0);
          newRow.cess_rate_id = cessRateRecord?.id!;
        } else {
          cessRateRecord = cessRateList.find(
            (it) => it.id === newRow.cess_rate_id
          );
        }
        newRow.extra!.gstRate = gstRateRecord;
        newRow.extra!.cessRate = cessRateRecord;
        return fillRelatedColumns(
          row,
          newRow,
          props.tax_inclusion ?? "OutOfTax",
          props.taxTeritoryType ?? TaxTeritoryType.OTHER_TERITORY
        );
      });
      // console.log('handleTaxInfoChange...props.onRowDataChange', props.onRowDataChange, newRowData);

      props.onRowDataChange?.(newRowData);
    }
  });

  useEffect(() => {
    if (lastRowIndex < 0 && !isLoading) {
      handleAddRow();
    }
  }, [lastRowIndex, isLoading, handleAddRow]);

  useEffect(() => {
    if (!isLoading) {
      handleTaxInfoChange();
    }

    // these deps are handled in handleTaxInfoChange
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.tax_inclusion,
    props.taxTeritoryType,
    props.hasCess,
    isLoading,
    productData.productlist,
  ]);

  useEffect(() => {
    setTimeout(() => {
      handleCellChange("product_name", lastRowIdx, props.Data?.product_name);
    }, 100);
    handleCellChange("product_id", lastRowIdx, props.Data?.id, props.Data);
  }, [props.Data]);

  const handleProductSelectFromDD = (
    _value: any,
    id: any,
    fieldName: string
  ) => {
    const product: any = productData.productlist.find(
      (it: any) => it.product_name === _value
    );
    if (_value) {
      setTimeout(() => {
        handleCellChange("product_name", id, _value);
      }, 100);
    }

    handleCellChange(fieldName, id, +product?.id, product);
  };

  /**
   * Improved search UX through debounce
   */
  const debouncedSearch = useCallback(
    debounce(
      (nextValue: any, idx: number, rec?: any) =>
        fetchProduct(nextValue, idx, rec),
      500
    ),
    []
  );

  const handleSearch = (nextValue: any, idx: number, rec?: any) => {
    handleCellChange("product_name", idx, nextValue);
    debouncedSearch(nextValue, idx, rec);
  };

  const fetchProduct = (e: any, idx: number, rec?: any) => {
    /* handleCellChange("product_name", idx, e); */
    loadProductList(e);
    /* if (e?.length >= 1) {
      loadProductList(e);
    } else {
      resetProductList(rec, idx);
    } */
  };

  const loadProductList = async (value?: string) => {
    setIsProductListLoading(true);
    setNoProductFound(false);
    setproducts({
      productlist: [] as any,
    });
    const [productListRes] = await Promise.all([
      getProductList(value ? (value as string) : "").then(
        ({ ok, message, data }) => ({
          ok,
          message,
          data,
        })
      ),
    ]);

    setIsProductListLoading(false);
    if (!productListRes.ok) {
      setNoProductFound(true);
      notification.error({
        message: "Failed to load product ",
        description: productListRes.message,
      });
    } else {
      if (productListRes.data.length === 0) {
        setNoProductFound(true);
      }
      setproducts((oldInfo) => ({
        ...oldInfo,
        productlist: productListRes.data,
        loadedData: productListRes.data[0],
      }));
    }
  };

  const resetProductList = (rec?: any, idx?: number) => {
    let productFind = "";
    productFind = productData.productlist?.find((el: any) =>
      el.product_name === rec.product_name ? rec.product_name : ""
    );
    if (!productFind) {
      handleCellChange("product_name", idx, "");
      // handleCellChange("product_id", idx, null);
    }

    setproducts((oldInfo) => ({
      ...oldInfo,
      productlist: [],
    }));
  };

  const columnDef = useMemo(() => {
    // console.log("calculating columnDef");
    const _calculatedColumnDef: ColumnsType<InvoiceLineItemRecord> = [
      {
        title: "",
        dataIndex: "row_details_id",
        width: 20,
        render: litAddNewButton({
          lastRowIndex,
          readOnly: props.readOnly,
          onNewRow: handleRowIndex,
        }),
      },

      {
        title: (
          <ProductColumnHeader
            readOnly={props.readOnly}
            onAddProductRequest={props.onAddProduct}
          />
        ),

        dataIndex: "product_id",

        render: (value, _rec, idx) =>
          !props.readOnly ? (
            <>
              <AutoComplete
                id="chrome-off"
                onSelect={(value) =>
                  handleProductSelectFromDD(value, idx, "product_id")
                }
                /* onChange={(selectedValue: string) =>
                  fetchProduct(selectedValue, idx, _rec)
                } */
                onSearch={debounce(
                  (selectedValue: string) =>
                    handleSearch(selectedValue, idx, _rec),
                  100
                )}
                style={
                  _rec.extra?.errors?.length! > 0 && !_rec.product_name
                    ? {
                        minWidth: "100%",
                        top: _rec.category_name || !value ? 16 : 10,
                        border: "0.5px solid red",
                        borderRadius: "8px",
                      }
                    : {
                        minWidth: "100%",
                        top: _rec.category_name || !value ? 16 : 10,
                      }
                }
                value={_rec.product_name}
                onFocus={() => fetchProduct(_rec.product_name, idx, _rec)}
                onBlur={() => resetProductList(_rec, idx)}
                notFoundContent={
                  noProductFound ? (
                    <span>
                      <i>No product / service found</i>
                    </span>
                  ) : (
                    <Spin />
                  )
                }
              >
                {productData.productlist.map((v: any, idx: number) => {
                  return (
                    <AutoComplete.Option key={v.id} value={`${v.product_name}`}>
                      <div>
                        <div>{v.product_name}</div>{" "}
                        <div
                          style={{
                            fontSize: 10,
                            color: "var(--primary-color)",
                          }}
                        >
                          {v.hsn_sac || "no-hsn"} | {v.category_path}
                        </div>
                      </div>
                    </AutoComplete.Option>
                  );
                })}
              </AutoComplete>
              {_rec.product_name && _rec.category_name ? (
                <Tooltip title={_rec.full_path}>
                  <div
                    style={{
                      fontSize: "10px",
                      color: "var(--grey-2)",
                      paddingTop: 15,
                    }}
                  >
                    {_rec.category_name}
                  </div>
                </Tooltip>
              ) : (
                <div style={{ paddingTop: 22, marginTop: 10 }}>
                  <FieldInfo text={"Type keywords to search item"} tip={true} />
                </div>
              )}
            </>
          ) : (
            <>
              <Input
                readOnly
                value={_rec.product_name}
                style={{
                  top: _rec.category_name ? 16 : _rec.product_name ? 14 : 8,
                }}
              />
              {_rec.product_name ? (
                <Tooltip title={_rec.full_path}>
                  <div
                    style={{
                      fontSize: "10px",
                      color: "var(--grey-2)",
                      paddingTop: 15,
                      position: "relative",
                      top: "4px",
                    }}
                  >
                    {_rec.category_name}
                  </div>
                </Tooltip>
              ) : (
                <div style={{ paddingTop: 25, marginTop: 4 }}>
                  <FieldInfo text={"-"} />
                </div>
              )}
            </>
          ),
      },
      {
        title: "HSN / SAC",
        dataIndex: "hsn_sac",
        width: 95,
        render: litInput({
          fieldName: "hsn_sac",
          readOnly: props.readOnly,
          onChange: handleCellChange,
        }),
      },

      {
        title: "Description",
        dataIndex: "description",
        width: 80,
        render: litTextArea({
          fieldName: "description",
          readOnly: props.readOnly,
          onChange: handleCellChange,
        }),
      },

      {
        title: (
          <span>
            <span style={{ color: "#EB5757" }}>*</span> Qty
          </span>
        ),
        dataIndex: "quantity",
        align: "right",
        width: 60,
        render: litCurrencyInput({
          fieldName: "quantity",
          disabled: false,
          readOnly: props.readOnly,
          onChange: handleCellChange,
        }),
      },

      {
        title: (
          <span>
            <span style={{ color: "#EB5757" }}>*</span> Rate
          </span>
        ),
        dataIndex: "rate",
        align: "right",
        width: 110,
        render: litCurrencyInput({
          fieldName: "rate",
          disabled: false,
          readOnly: props.readOnly,
          onChange: handleCellChange,
        }),
      },
      {
        title: "Unit",
        dataIndex: "unit_id",
        width: 60,
        render: litUnitInput({
          fieldName: "unit_id",
          readOnly: props.readOnly,
          onChange: handleCellChange,
        }),
      },
      {
        title: "Disc(%)",
        dataIndex: "discount_rate",
        align: "right",
        width: 60,
        render: litPercentageInput({
          fieldName: "discount_rate",
          readOnly: props.readOnly,
          onChange: handleCellChange,
        }),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        align: "right",
        width: 120,
        render: litCurrencyInput({
          fieldName: "amount",
          disabled: false,
          readOnly: true,
          onChange: handleCellChange,
        }),
      },
      {
        title: "CGST/SGST",
        dataIndex: "cgst",
        align: "right",
        key: "cgst",
        width: 100,
        render: litCurrencyInput({
          fieldName: "cgst",
          disabled: false,
          readOnly: true,
          onChange: handleCellChange,
        }),
      },
      // {
      //   title: "SGST",
      //   dataIndex: "sgst",
      //   align: "right",
      //   width: 100,
      //   key: "sgst",
      //   render: litCurrencyInput({
      //     fieldName: "sgst",
      //     disabled: false,
      //     readOnly: true,
      //     onChange: handleCellChange,
      //   }),
      // },
      {
        title: "IGST",
        dataIndex: "igst",
        align: "right",
        key: "igst",
        width: 100,
        render: litCurrencyInput({
          fieldName: "igst",
          disabled: false,
          readOnly: true,
          onChange: handleCellChange,
        }),
      },
      {
        title: "Tax rate",
        dataIndex: "gst_rate_id",
        render: litGSTRateInput({
          fieldName: "gst_rate_id",
          readOnly: props.readOnly,
          disabled: props.tax_inclusion === "OutOfTax",
          onChange: handleCellChange,
        }),
      },
      {
        title: "CESS",
        dataIndex: "cess_rate_id",
        render: litCESSRateInput({
          fieldName: "cess_rate_id",
          readOnly: props.readOnly,
          onChange: handleCellChange,
        }),
        key: !props.hasCess ? SPCL_REMOVED_COLUMN : "cess_rate_id",
      },
      {
        title: "",
        // dataIndex: "id",
        key: "delete_action",
        width: 20,
        render: litDeleteNewButton({
          lastRowIndex,
          readOnly: props.readOnly,
          onDeleteRow: handleRemoveRow,
        }),
      },
    ];
    // console.log("_calculatedColumnDef = ", _calculatedColumnDef);
    return _calculatedColumnDef.filter(
      (col) =>
        col.key !== SPCL_REMOVED_COLUMN &&
        (props.sameState ? col.key !== "igst" : col.key !== "cgst")
    );
  }, [
    lastRowIndex,
    props.readOnly,
    props.hasCess,
    props.tax_inclusion,
    props.sameState,
    props.onAddProduct,
    handleAddRow,
    handleCellChange,
    handleRemoveRow,
    fetchProduct,
  ]);

  return (
    <Skeleton
      loading={isLoading}
      paragraph={{
        rows: 3,
        width: "70%",
      }}
    >
      <Table
        rowKey="row_id"
        loading={isLoading}
        size="small"
        className={classes.table}
        columns={columnDef}
        dataSource={props.rowData}
        footer={invLitMakeFooterRenderer(
          props.getTotal,
          props.currency,
          props.tax_inclusion,
          props.taxTeritoryType,
          props.hasCess,
          props.isCopy,
          props.totalPayAmount,
          props.tdsInfo,
          props.exportType,
          props.paymentHistoryData
        )}
        pagination={false}
      />
    </Skeleton>
  );
};

export default InvoiceLineItem;
