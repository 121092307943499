import React from "react";

function Illustration3() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 328 259"
    >
      <path
        fill="#E6E6E6"
        d="M151.998 259c83.947 0 151.999-2.287 151.999-5.107 0-2.821-68.052-5.108-151.999-5.108C68.052 248.785 0 251.072 0 253.893 0 256.713 68.052 259 151.998 259z"
      ></path>
      <path
        fill="#9B51E0"
        d="M149.716 83.026c10.336 0 18.715-8.522 18.715-19.036 0-10.514-8.379-19.037-18.715-19.037s-18.714 8.523-18.714 19.037 8.378 19.036 18.714 19.036z"
      ></path>
      <path
        fill="#FFB8B8"
        d="M144.766 108.006l-8.468-6.592-30.318 55.857a4.899 4.899 0 00-2.53 1.314 5.04 5.04 0 00-1.398 2.517 5.102 5.102 0 00.201 2.884c.344.92.946 1.717 1.732 2.291a4.866 4.866 0 005.438.213 4.983 4.983 0 001.9-2.15c.412-.89.557-1.885.417-2.859l33.026-53.475zM222.064 139.709a5.011 5.011 0 00-1.882-2.505 4.873 4.873 0 00-2.979-.87l-48.848-38.977-7.326 7.882 51.458 36.484c.053.711.254 1.403.59 2.029a4.985 4.985 0 001.359 1.601 4.859 4.859 0 005.868.051 4.987 4.987 0 001.386-1.577 5.096 5.096 0 00.374-4.118zM165.372 248.421h5.755l2.739-22.579h-8.494v22.579z"
      ></path>
      <path
        fill="#2F2E41"
        d="M182.226 253.857h-18.087v-7.109h11.099a6.93 6.93 0 014.941 2.082 7.173 7.173 0 012.047 5.027z"
      ></path>
      <path
        fill="#FFB8B8"
        d="M119.773 243.195l5.287 2.311 11.281-19.644-7.803-3.412-8.765 20.745z"
      ></path>
      <path
        fill="#2F2E41"
        d="M133.147 254.958l-16.616-7.265 2.759-6.53 10.196 4.457a7.058 7.058 0 013.732 3.898 7.223 7.223 0 01-.071 5.44zM137.711 140.159l-27.376 87.725 35.93 3.916 3.451-51.268 4.677 51.268 29.086-3.481s-5.254-81.198-14.818-86.419c-9.563-5.221-30.95-1.741-30.95-1.741z"
      ></path>
      <path
        fill="#FFB8B8"
        d="M152.554 87.615c6.614 0 11.977-5.454 11.977-12.182 0-6.729-5.363-12.183-11.977-12.183-6.615 0-11.977 5.454-11.977 12.183 0 6.728 5.362 12.182 11.977 12.182z"
      ></path>
      <path
        fill="#2F2E41"
        d="M152.17 144.092c-8.316 0-16.319-.375-16.732-1.102a.312.312 0 01.059-.384c.572-.857-.771-14.787-3.6-37.255a11.806 11.806 0 011.622-7.55 11.483 11.483 0 015.913-4.852l.053-.012 25.066-2.518.281.08a11.34 11.34 0 016.156 4.363 11.675 11.675 0 012.132 7.318c-.647 14.332-1.731 38.607-1.731 40.155 0 .421-.576.632-1.078.769-2.441.665-10.425.988-18.141.988z"
      ></path>
      <path
        fill="#F2F2F2"
        d="M50.963 42.818L23.015 78.345l34.926 28.43L85.89 71.247l-34.927-28.43z"
      ></path>
      <path
        fill="#3F3D56"
        d="M57.699 114.752a9.975 9.975 0 01-5.26-2.557 10.251 10.251 0 01-2.978-5.099 10.38 10.38 0 01.31-5.922 10.201 10.201 0 013.491-4.75 9.935 9.935 0 015.497-1.975 9.91 9.91 0 015.65 1.464 10.159 10.159 0 013.898 4.41 10.375 10.375 0 01-.503 9.617 10.157 10.157 0 01-2.643 2.943 9.966 9.966 0 01-3.548 1.689 9.874 9.874 0 01-3.914.18zm2.889-19.278a8.99 8.99 0 00-5.246.77 9.185 9.185 0 00-3.941 3.604 9.41 9.41 0 00-1.309 5.224 9.385 9.385 0 001.766 5.083 9.138 9.138 0 004.244 3.229 8.981 8.981 0 005.292.287 9.107 9.107 0 004.557-2.754 9.348 9.348 0 002.286-4.864 9.42 9.42 0 00-1.674-6.899 9.116 9.116 0 00-5.976-3.68z"
      ></path>
      <path
        fill="#E6E6E6"
        d="M36.323 229.928c-3.428 11.648 1.893 23.556 1.893 23.556s10.877-6.976 14.305-18.624c3.428-11.648-1.892-23.556-1.892-23.556s-10.878 6.976-14.306 18.624z"
      ></path>
      <path
        fill="#E6E6E6"
        d="M41.528 230.243c2.885 11.799-2.98 23.44-2.98 23.44s-10.543-7.49-13.428-19.289c-2.885-11.799 2.98-23.44 2.98-23.44s10.543 7.49 13.428 19.289z"
      ></path>
      <path
        fill="#9B51E0"
        d="M135.711 67.363a40.797 40.797 0 0020.788 5.11c2.777-.074 5.836-.588 7.595-2.775 1.984-2.468 1.488-6.324-.426-8.848-1.915-2.525-4.909-3.948-7.896-4.913-2.827-.914-5.855-1.502-8.753-.863a10.81 10.81 0 00-4.223 1.958 11.018 11.018 0 00-3.039 3.569 11.224 11.224 0 00-1.305 4.53 11.27 11.27 0 00.668 4.672"
      ></path>
      <path
        fill="#9B51E0"
        d="M136.479 61.204c5.798 0 10.498-4.781 10.498-10.68 0-5.897-4.7-10.678-10.498-10.678-5.798 0-10.498 4.78-10.498 10.679 0 5.898 4.7 10.679 10.498 10.679z"
      ></path>
      <path
        fill="#3F3D56"
        d="M326.187 51.709l-41.211-24.433 5.287-9.226a2.358 2.358 0 00-.156-2.575 2.288 2.288 0 00-1.509-.894 2.245 2.245 0 00-1.689.453 2.304 2.304 0 00-.59.678l-5.286 9.226L239.822.506a3.597 3.597 0 00-2.769-.383 3.66 3.66 0 00-2.225 1.722l-32.178 56.16a3.768 3.768 0 00-.376 2.817 3.707 3.707 0 001.692 2.263l41.21 24.432-36.085 62.981a2.358 2.358 0 00.155 2.575 2.275 2.275 0 001.509.895c.297.041.599.022.889-.056a2.274 2.274 0 001.39-1.076l36.086-62.98 41.211 24.432a3.59 3.59 0 002.769.383 3.673 3.673 0 002.224-1.722l32.179-56.16a3.772 3.772 0 00-.249-4.12 3.67 3.67 0 00-1.067-.96z"
      ></path>
      <path
        fill="#000"
        d="M269.029 85.537c1.27-2.216-4.411-7.99-12.687-12.896-8.276-4.907-16.015-7.089-17.284-4.873-1.269 2.215 4.411 7.989 12.687 12.896 8.276 4.906 16.015 7.088 17.284 4.873z"
        opacity="0.1"
      ></path>
      <path
        fill="#9B51E0"
        d="M265.076 79.684c12.101 0 21.91-9.978 21.91-22.287s-9.809-22.287-21.91-22.287c-12.1 0-21.909 9.978-21.909 22.287s9.809 22.287 21.909 22.287z"
      ></path>
      <path
        fill="#F2F2F2"
        d="M274.609 68.422c-1.27 2.216-10.223 3.613-16.757-.26-6.534-3.874-8.174-11.552-6.905-13.768 1.269-2.215 4.968 1.87 11.502 5.745 6.534 3.873 13.429 6.068 12.16 8.283zM260.308 50.077c2.521 0 4.564-2.079 4.564-4.643 0-2.565-2.043-4.643-4.564-4.643s-4.565 2.078-4.565 4.643c0 2.564 2.044 4.643 4.565 4.643zM276.871 59.897c2.521 0 4.564-2.08 4.564-4.643 0-2.565-2.043-4.644-4.564-4.644s-4.565 2.08-4.565 4.644 2.044 4.643 4.565 4.643z"
      ></path>
    </svg>
  );
}

export default Illustration3;
