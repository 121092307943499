import React from "react";
import { Tooltip } from "antd";
import { Select } from "antd";
import { CESSRateItem } from "~/api/expense";
import { useUnitList } from "~/lib/hook/api-hook/picklistHooks";
import usePersistantCallbackRef from "~/lib/hook/usePersistantCallbackRef";
import ReadOnlyableSelect from "./ReadOnlyableSelect";

interface Props {
  readOnly?: boolean;
  value?: number;
  onChange?: (cessRateId: number, record?: any) => void;
  disabled?: boolean;
}

const UnitSelect = (props: Props) => {
  const { isUnitListLoading, unitList } = useUnitList();
  const handleOnChange = usePersistantCallbackRef((unitId: number) => {
    const unit = unitList.find((it: any) => it.id === unitId);
    props.onChange?.(unitId, unit);
  });
  const handleUnitSearch = (input?: string, option?: any): any => {
    return input && option.children.toLowerCase().includes(input.toLowerCase());
  };
  const unitSelected = unitList.find((it) => it.id === props.value);
  return (
    <Tooltip title={unitSelected?.quantity}>
      <ReadOnlyableSelect
        loading={isUnitListLoading}
        readonly={props.readOnly}
        value={props.value}
        disabled={props.disabled}
        onChange={handleOnChange}
        filterOption={handleUnitSearch}
        style={{
          width: "100%",
        }}
        showArrow
        showSearch
        id={"unit_id"}
      >
        {unitList.map((obj: any) => (
          <Select.Option key={obj.id} value={obj.id}>
            {obj.uqc_code}
          </Select.Option>
        ))}
      </ReadOnlyableSelect>
    </Tooltip>
  );
};

export default UnitSelect;
