import React, { Component, SyntheticEvent } from "react";
import { RouteComponentProps } from "react-router";
import { ColumnType } from "antd/lib/table/interface";
import { Button, Table, Row, notification, Drawer, Tooltip, Input } from "antd";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import { fetchUtil } from "~/api/common";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import styles from "./VendorDetailsPage.module.css";
import { format, endOfMonth, getYear, addYears, subYears } from "date-fns";
import SummaryTags from "~/component/SummaryTags";
import { AuditOutlined } from "@ant-design/icons";
import VendorTransactions from "./VendorTransactions";
import { withWriteAccess } from "~/utils";
import { registerEvent } from "~/analytics";
import ExportButton from "~/fragment/table-export/ExportButton";
import { getFullVendorList, getTableExportBlob } from "~/api/vendor";
import Currency from "~/component/Currency";
import FySelect from "~/component/FySelect";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";
import { debounce } from "lodash";
import * as ga from "~/contants/gaConstants";

const mapStateToProps = (
  state: AuthState,
  ownProps: { employeeID: number }
) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  employeeID: ownProps.employeeID,
  sortOrderInfo: state.mainAppSlice.user.sort_order,
  sorterData: state.mainAppSlice.user.sorter,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  setSortOrder: (sortOrderInfo: any) =>
    dispatch(action.auth.setSortOrder(sortOrderInfo)),
  setSorter: (sorter: any) => dispatch(action.auth.setSorter(sorter)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PathParams = {
  customerId?: string;
};

interface Props extends RouteComponentProps<PathParams>, PropsFromRedux {}
interface State {
  vendorList: Array<{
    customer_id: number;
    trade_name: any;
    name: string;
    company_name: null | string;
    billing_address: null | string;
    billing_address2: null | string;
    billing_address_city: null | string;
    billing_address_country: null | string;
    billing_address_pincode: null | string;
    billing_address_state: null | string;
    email_id: string;
    gst_registration_type_id: number | null;
    place_of_supply_id: number;
    place_of_supply: string;
    gstin: string;
    customer_currency: number;
    symbol: string;
    symbol_name: string;
  }>;
  vendorSummaryData: Partial<{
    vendor_count: string;
  }>;
  activePage: number;
  noOfRows: number;
  totalItemCount: number;
  showVendorTransactions: boolean;
  selectedCustomerId: number;
  selectedCustomer: string;
  fySet: any;
  selectedFy: any;
  loadTable: boolean;
  searchItem: string;
  highlightedText: string;
  sortedInfo: any;
  sortOrder: string;
}

const year = new Date().getFullYear();
const current = new Date();
const eligible =
  current.getTime() <= new Date(`${year}-04-01`).getTime() &&
  current.getTime() > new Date(`${year - 1}-04-01`).getTime();
class VendorPage extends Component<Props, State> {
  state: State = {
    vendorList: [],
    searchItem: "",
    highlightedText: "",
    sortedInfo: {},
    sortOrder: "",
    vendorSummaryData: {},
    activePage: 1,
    noOfRows: 12,
    totalItemCount: 0,
    showVendorTransactions: false,
    selectedCustomerId: 0,
    selectedCustomer: "",
    fySet: [],
    selectedFy: eligible
      ? `${getYear(subYears(current, 1))}-${getYear(current)}`
      : `${getYear(current)}-${getYear(addYears(current, 1))}`,
    loadTable: false,
  };

  columnDef: Array<ColumnType<any>> = [
    {
      title: "Name",
      dataIndex: "trade_name",
      key: "id",
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      render: (id, record) => {
        return <>{record.gstin ? record.gstin : "N/A"}</>;
      },
      key: "gstin",
    },
    {
      title: "State",
      dataIndex: "billing_address_state",
      key: "billing_address_state",
      sorter: true,
    },
    {
      title: "Country",
      dataIndex: "billing_address_country",
      key: "billing_address_country",
      sorter: true,
    },
    {
      title: "Currency",
      dataIndex: "symbol_name",
      key: "symbol_name",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>Total sale</span>,
      dataIndex: "total_sales",
      align: "right",
      key: "total_sales",
      render: (total_sales: any, record: any) => ({
        children: (
          <Currency currencySymbol={record.currency_symbol}>
            {Number(total_sales)}
          </Currency>
        ),
      }),
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>Total Purchase</span>,
      dataIndex: "total_purchases",
      align: "right",
      key: "total_purchases",
      render: (total_purchases: any, record: any) => ({
        children: (
          <Currency currencySymbol={record.currency_symbol}>
            {Number(total_purchases)}
          </Currency>
        ),
      }),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id, record) => {
        return (
          <Tooltip placement="right" title="Show transactions">
            <Button
              type="ghost"
              shape="circle-outline"
              size="small"
              // style={{ marginBottom: "0.5em" }}
              icon={<AuditOutlined />}
              onClick={this.showVendorsDrawer(record)}
              // data-id={invoice_id}
            />
          </Tooltip>
        );
      },
    },
  ];

  static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State
  ): Partial<State> | null {
    const query = new URLSearchParams(nextProps.location.search);
    const newState: Partial<State> = {
      sortOrder: query.get("sort_order") || "",
    };
    if (query.has("page")) {
      const pageNo = +query.get("page")!;
      if (Number.isFinite(pageNo) && prevState.activePage !== pageNo) {
        newState.activePage = pageNo;
      }
    } else {
      const pageNo = 1;
      if (Number.isFinite(pageNo) && prevState.activePage !== pageNo) {
        newState.activePage = pageNo;
      }
    }
    // const query = new URLSearchParams(this.props.location.search);
    if (query.has("fy") && query.get("fy")) {
      const fyselect = query.get("fy");
      newState.selectedFy = fyselect;
    }
    if (nextProps.match.params.customerId && prevState.vendorList?.length > 0) {
      const customerId = +nextProps.match.params.customerId;
      const record: any = prevState.vendorList.find(
        (it: any) => it.id === customerId
      );
      if (record) {
        newState.showVendorTransactions = true;
        newState.selectedCustomerId = record.id;
        newState.selectedCustomer = record.trade_name;
      }
    }
    if (prevState.sortOrder !== newState.sortOrder) {
      return newState;
    }
    if (Object.keys(newState).length > 0) {
      return newState;
    }
    return null;
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    if (query.has("fy") && query.get("fy")) {
      const fyselect = query.get("fy");
      this.setState({
        selectedFy: fyselect,
      });
      if (this.props.sorterData) {
        this.setState({
          sortedInfo: this.props.sorterData,
        });
      }
      // return{
      //   selectedFy: fyselect
      // }
    }
    if (query.has("search_item") && query.get("search_item")) {
      const searchItem = query.get("search_item");
      if (searchItem) {
        this.setState({ searchItem, highlightedText: searchItem });
      }
    }
    this.setFyList()
      .then(() => {
        this.getVendorList();
        this.getVendorSummary();
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>) {
    if (_prevState.vendorList !== this.state.vendorList) {
      if (this.state.vendorList.length) {
        this.placeButton();
      }
    }
  }

  setFyList = () => {
    return new Promise<void>((resolve, reject) => {
      const error = false;
      if (!error) {
        const today = new Date();
        const offset = endOfMonth(today);
        const YEAR_FORMAT = "yyyy";
        const items = [];
        for (let i = 1; i < 2; i++) {
          const fy_year_current =
            format(addYears(offset, i - 1), YEAR_FORMAT) +
            "-" +
            format(addYears(offset, i), YEAR_FORMAT);
          items.push(fy_year_current);
        }
        for (let i = 0; i < 2; i++) {
          const fy_year =
            format(subYears(offset, i + 1), YEAR_FORMAT) +
            "-" +
            format(subYears(offset, i), YEAR_FORMAT);
          items.push(fy_year);
        }
        this.setState({
          fySet: items,
        });
        resolve();
      } else {
        reject(error);
      }
    });
  };

  placeButton = () => {
    // setTimeout(() => {
    const element = document.getElementsByClassName(
      "ant-pagination-item-link"
    )[0];
    if (element) {
      const { left, top } = element.getBoundingClientRect();
      const btn = document.getElementById("exportBtn");
      if (btn) {
        btn.style.position = "absolute";
        btn.style.left = left - 30 + "px";
        btn.style.top = top + "px";
        btn.style.margin = "0";
        btn.style.display = "inline";
      }
    }
    // }, 1000);
  };

  async getVendorSummary() {
    this.setState({
      loadTable: true,
    });
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_vendor_summary`,
      {
        fy: this.state.selectedFy,
        search_text: this.state.searchItem,
      }
    );
    if (ok) {
      const data = json[0];
      this.setState({
        vendorSummaryData: data,
      });
    } else {
      notification.error({ message });
    }
  }
  async getVendorList() {
    this.setState({
      loadTable: true,
    });
    const { ok, message, json } = await fetchUtil(
      "POST",
      "/get_customer_details",
      this.state.searchItem === ""
        ? {
            page_no: this.state.activePage,
            no_of_rows: this.state.noOfRows,
            fy: this.state.selectedFy,
            search_text: null,
            sort_item: this.state.sortOrder,
          }
        : {
            page_no: this.state.activePage,
            no_of_rows: this.state.noOfRows,
            fy: this.state.selectedFy,
            search_text: this.state.searchItem,
            sort_item: this.state.sortOrder,
          }
    );

    if (ok) {
      const data = json;
      let totalArrayLength = 0;
      if (data && data.length > 0) {
        totalArrayLength = data[0].total_rows;
      }
      this.setState({
        vendorList: data,
        totalItemCount: totalArrayLength,
        loadTable: false,
      });
    } else {
      this.setState({
        loadTable: false,
      });
      notification.error({ message });
    }
  }
  debouncedGetVendorList = debounce(this.getVendorList, 300);
  debouncedGetVendorSummary = debounce(this.getVendorSummary, 300);
  showVendorsDrawer = (record: any) => (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      `Vendor Page > Show vendor transactions`
    );
    e.preventDefault();
    e.stopPropagation();
    this.props.history.push(`/app/masters/vendor/transactions/${record.id}`);
  };

  onVendorDrawerClose = () => {
    this.props.history.push(`/app/masters/vendor`);
    this.setState({
      showVendorTransactions: false,
    });
  };

  handleAddNew = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.vendorPage258
    );
    this.props.history.push(`/app/masters/vendor/new`);
  };
  handleRowEvent = (record: any, _rowIndex?: number) => {
    return {
      onClick: (_event: SyntheticEvent) => {
        this.props.history.push(`/app/masters/vendor/${record.id}`, {
          name: this.state.searchItem,
        });
      },
    };
  };
  handlePageChange = (pageNumber: number) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", pageNumber + "");
    this.props.history.push("?" + query);

    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.getVendorList();
      }
    );
  };

  handleFetchData = () => {
    return getFullVendorList(this.state.activePage, this.state.selectedFy);
  };

  handleDownload = async (acceptType: string, selectedFields: string) => {
    return getTableExportBlob(
      1,
      9999999,
      acceptType,
      selectedFields,
      this.state.selectedFy
    );
  };

  handleSetFy = (value: any) => {
    this.setState(
      {
        selectedFy: value,
      },
      () => {
        this.getVendorList();
        this.getVendorSummary();
      }
    );

    const nextQuery = new URLSearchParams(this.props.location.search);
    nextQuery.set("fy", value + "");
    // resetPage(nextQuery);
    this.props.history.push("?" + nextQuery);
  };
  handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchItem = e.target.value;
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", 1 + "");
    query.set("search_item", searchItem);
    this.props.history.push("?" + query);
    this.setState(
      { searchItem: e.target.value, highlightedText: e.target.value },
      () => {
        this.debouncedGetVendorList();
        this.debouncedGetVendorSummary();
      }
    );
  };
  highlightText(text: string): JSX.Element {
    const { highlightedText } = this.state;
    if (text) {
      const parts = text.split(new RegExp(`(${highlightedText})`, "gi"));
      return (
        <>
          {parts.map((part: any, index: any) =>
            part.toLowerCase() === highlightedText.toLowerCase() ? (
              <span key={index} className={styles["highlighted-text"]}>
                {part}
              </span>
            ) : (
              <span key={index}>{part}</span>
            )
          )}
        </>
      );
    } else {
      return <></>;
    }
  }
  handleTableChange = (_pagination: any, filters: any, sorter: any) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", _pagination.current + "");
    let sortOrder = "";
    if (sorter.columnKey === "billing_address_state") {
      if (sorter.order === "descend") {
        sortOrder = "STATE_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "STATE_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "billing_address_country") {
      if (sorter.order === "descend") {
        sortOrder = "COUNTRY_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "COUNTRY_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    }

    query.set("sort_order", sortOrder);

    this.props.history.push("?" + query);
    this.props.setSorter(sorter);
    this.setState(
      {
        sortedInfo: sorter ? sorter : this.props.sorterData,
      },
      () => {
        this.getVendorList();
      }
    );
  };
  render() {
    const vendorData: any = this.state.vendorSummaryData;

    return (
      <IMTContent withoutMargin={true}>
        <Helmet>
          <title>{titles.VendorPage}</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={["Vendor / Customer"]}
          style={{ paddingLeft: "0" }}
          actions={
            withWriteAccess(this.props.activeRole) ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span style={{ marginRight: "10px" }}>
                  {" "}
                  <FySelect
                    style={{ marginRight: 20 }}
                    selectedFy={this.state.selectedFy}
                    onFyChange={this.handleSetFy}
                    isVenTn={true}
                  />
                </span>
                <Button type="primary" onClick={this.handleAddNew}>
                  + Add new
                </Button>
              </div>
            ) : null
          }
        />
        <Input
          placeholder="Search in vendor name / gstin"
          onChange={this.handleInputChange}
          value={this.state.searchItem}
          style={{ maxWidth: "40%", marginBottom: "10px", height: "30px" }}
          allowClear
        />
        <Row className={styles.summaryDiv}>
          <SummaryTags
            nameValuePairs={[{ name: "Count", value: vendorData.vendor_count }]}
          />
        </Row>
        <Table
          rowKey={"customer_idx"}
          style={{ maxWidth: "90%", cursor: "pointer" }}
          columns={this.columnDef}
          dataSource={this.state.vendorList.map((vendor) => ({
            ...vendor,
            trade_name: this.highlightText(vendor.trade_name),
            gstin: this.highlightText(vendor.gstin ? vendor.gstin : "N/A"),
            // Add other columns and highlight the text if necessary
          }))}
          loading={this.state.loadTable}
          onRow={this.handleRowEvent}
          onChange={this.handleTableChange}
          size="large"
          pagination={{
            size: "small",
            current: this.state.activePage,
            pageSize: this.state.noOfRows,
            showSizeChanger: false,
            onChange: this.handlePageChange,
            total: this.state.totalItemCount,
            position: ["topRight"],
            style: { margin: "0px" },
          }}
        />
        {this.state.vendorList.length > 0 && (
          <ExportButton
            onDownload={this.handleDownload}
            onFetchData={this.handleFetchData}
            selectedList={[
              "Billing Address City",
              "Currency Symbol",
              "Billing Address Pincode",
              "Symbol Name",
              "State ID",
            ]}
          />
        )}
        {this.state.showVendorTransactions ? (
          <Drawer
            width={"75%"}
            placement="right"
            closable={false}
            onClose={this.onVendorDrawerClose}
            visible={this.state.showVendorTransactions}
            destroyOnClose={true}
          >
            <VendorTransactions
              customerid={this.state.selectedCustomerId}
              customerName={this.state.selectedCustomer}
              vendorPageFy={this.state.selectedFy}
            />
          </Drawer>
        ) : (
          <></>
        )}
      </IMTContent>
    );
  }
}
export default connector(VendorPage);
