import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { LoginRole } from "~/api/auth";
import * as api from "~/api/auth";
import { notification } from "antd";
import { getDefaultCompRole } from "~/lib/companyRole";

const initialState: any = {
  token: "",
  user: {
    id: 0,
    email: "",
    place_of_supply: -1,
    save_emp_details: false,
    activeRole: 1 as LoginRole,
    role_ids: [0] as Array<LoginRole>,
    session_kv: {
      import_modal_viewed: {
        value: true,
        expiry: null,
        create_time: "2021-05-06T08:45:21.",
      },
    },
  },
  showSyncButtonTour: false,
  currentTourStep: 0,
  connectSocket: true,
  updateCompanyList: false,
  steps: [
    {
      key: "sync",
      selector: '[data-tour="my-first-sidebar-step"]',
      content:
        "Check this menu if you want to import data from other platforms such as Tally, GSTN, Quickbooks etc",
    },
    // {
    //   key: "dashboard",
    //   selector: '[data-tour="my-first-dashboard-step"]',
    //   content: "see critical insights about your business",
    // },
    {
      key: "invoice",
      selector: '[data-tour="my-first-invoice-step"]',
      content: "Create and send tax invoices to your customers",
    },

    {
      key: "expense",
      selector: '[data-tour="my-first-expense-step"]',
      content: "Create expense by just drag and drop physical bills",
    },
    {
      key: "masters",
      selector: '[data-tour="my-first-masters-step"]',
      content: "See all vendors, products and expense categories",
    },

    {
      key: "employee",
      selector: '[data-tour="my-first-employee-step"]',
      content: "Manage employees, their personal details and salary structures",
    },
    // {
    //   selector: '[data-tour="my-first-vendor-step"]',
    //   content: "This is my first vendor Step",
    // },
    {
      key: "payroll",
      selector: '[data-tour="my-first-payroll-step"]',
      content: "Process payroll and generate payslips",
    },
    {
      key: "reimbursement",
      selector: '[data-tour="my-first-reimbursement-step"]',
      content: "Approve / manage reimbursement requests made by your employees",
    },
    {
      key: "compliance",
      selector: '[data-tour="my-first-compliance-step"]',
      content: "See all compliance, deadlines and filing options",
    },
    {
      key: "profile",
      selector: ".my-first-profile-step",
      content:
        "Get options for view / update company profile, change user role etc. Click on company list to see all the companies you have access to.",
    },

    {
      key: "settings",
      selector: '[data-tour="my-first-settings-step"]',
      content:
        "View / add / remove users and access privileges. Also manage your subscription from `Membership` menu",
    },
  ],
};

export const doChangeCompany = createAsyncThunk(
  "afterlogin/user/dochangecompany",
  async (reqArgs: api.companyChangeRequest, thunkAPI) => {
    const response = await api.changeCompany(reqArgs);

    if (response) {
      return response;
    } else {
      notification.error({
        message: "invalid credential",
      });
      return;
    }
  }
);

export const mainAppSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    empDetailsSaved: (state, _action) => {
      state.user.save_emp_details = _action.payload;
    },
    setPlaceOfSupply(state, _action: PayloadAction<number>) {
      state.user.place_of_supply = _action.payload;
    },
    setSortOrder(state, _action: PayloadAction<string>) {
      state.user.sort_order = _action.payload;
    },
    setSorter(state, _action: PayloadAction<any>) {
      state.user.sorter = _action.payload;
    },

    setUserName(state, _action: PayloadAction<any>) {
      state.user.first_name = _action.payload.first_name;
      state.user.last_name = _action.payload.last_name;
      state.user.gstin = _action.payload.gstin;
    },

    setActiveRole(state, action: PayloadAction<{ role: api.LoginRole }>) {
      const userData = localStorage.getItem("imt__user");
      const userParseData = JSON.parse(userData!);
      state.user.company_details = userParseData.company_details;
      state.user!.activeRole = action.payload.role;
      state.user!.session_kv = userParseData.session_kv;
      state.user.onboarding_status = true;
      localStorage.setItem("imt__user", JSON.stringify(state.user));
      const user = localStorage.getItem("imt__user");
      const userDetailAfterRoleChange = JSON.parse(user!);

      if (
        userDetailAfterRoleChange.activeRole === LoginRole.ADMIN &&
        !userDetailAfterRoleChange.plan_purchased
      ) {
        window.location.pathname = "/app/membership-plan";
      }
    },
    setUserRole(state, _action: PayloadAction<any>) {
      state.user!.role_ids = _action.payload.role_ids;

      const userData = localStorage.getItem("imt__user");
      const userParseData = JSON.parse(userData!);
      state.user.company_details = userParseData.company_details;
      const SelectedRole = _action.payload.role_ids.find(
        (it: any) => it === userParseData.activeRole
      );
      state.user!.activeRole = SelectedRole;
      state.user!.session_kv = userParseData.session_kv;
      state.user.onboarding_status = true;
      localStorage.setItem("imt__user", JSON.stringify(state.user));
    },

    handleReconnectSocket(state) {
      state.connectSocket = false;
    },
    handleUpdateCompanyList(state, _action: PayloadAction<any>) {
      state.updateCompanyList = _action.payload;
    },

    handleTermsModal(state, _action: PayloadAction<any>) {
      state.user!.terms_conditions_agree = _action.payload;
      const userData = localStorage.getItem("imt__user");
      const userParseData = JSON.parse(userData!);
      state.user.company_details = userParseData.company_details;

      state.user!.session_kv = userParseData.session_kv;
      state.user.onboarding_status = true;
      localStorage.setItem("imt__user", JSON.stringify(state.user));
    },
    createCompanyDetails(state, _action: PayloadAction<any>) {
      const userData = localStorage.getItem("imt__user");

      const userParseData = JSON.parse(userData!);
      // console.log(userParseData.gstin);
      state.user.company_details = [
        {
          name: _action.payload,
          id: userParseData.company_id,
        },
      ];
      state.user!.company_name = _action.payload;
      state.user!.session_kv = userParseData.session_kv;
      state.user!.gst_user_name = userParseData.gst_user_name;

      state.user!.gstin = userParseData.gstin;
      localStorage.setItem("imt__user", JSON.stringify(state.user));
    },
    controlAppTour(state, _action: PayloadAction<any>) {
      state.showSyncButtonTour = _action.payload;
      state.currentTourStep = 0;
    },
    setTourStep(state, action: PayloadAction<number>) {
      state.currentTourStep = action.payload;
    },
    setCompanyCount(state, action: PayloadAction<any>) {
      state.user.added_clients = action.payload.added_clients;
      localStorage.setItem("imt__user", JSON.stringify(state.user));
    },
    updateGstUserName(state, action: PayloadAction<any>) {
      state.user.gst_user_name = action.payload;
      localStorage.setItem("imt__user", JSON.stringify(state.user));
    },
    updateFirstLastName(state, _action: PayloadAction<any>) {
      const userFullName = _action.payload;
      const firstName = userFullName.first_name;
      const lastName = userFullName.last_name;

      const userData = localStorage.getItem("imt__user");
      const userParseData = JSON.parse(userData!);
      state.user.company_details = userParseData.company_details;
      state.user.first_name = firstName;
      state.user.last_name = lastName;

      state.user!.session_kv = userParseData.session_kv;
      state.user!.company_id = userParseData.company_id;
      state.user!.designation_id = userParseData.designation_id;
      state.user!.first_sync = userParseData.first_sync;

      state.user.onboarding_status = true;
      localStorage.setItem("imt__user", JSON.stringify(state.user));
    },

    updateStore(state) {
      const userData = localStorage.getItem("imt__user");

      const imtToken = localStorage.getItem("imt__token");
      const userParseData = JSON.parse(userData!);
      if (userParseData.company_name) {
        state.user.company_details = [
          {
            name: userParseData.company_name,
            id: userParseData.company_id,
          },
        ];
        state.user!.company_name = userParseData.company_name;

        state.user!.session_kv = userParseData.session_kv;
        state.token = imtToken;
        localStorage.setItem("imt__user", JSON.stringify(state.user));
      } else {
        const onboardName = localStorage.getItem("onboardName");
        const onboardNameParsed = JSON.parse(onboardName!);
        state.user.company_details = [
          {
            name: onboardNameParsed.company_name,
            id: userParseData.company_id,
          },
        ];
        state.user!.company_name = onboardNameParsed.company_name;

        state.user!.session_kv = userParseData.session_kv;
        state.token = imtToken;
        localStorage.setItem("imt__user", JSON.stringify(state.user));
      }
    },
  },

  extraReducers(builder) {
    builder
      .addCase("imt/INIT", (state) => {
        const imtToken = localStorage.getItem("imt__token");
        const userData = localStorage.getItem("imt__user");
        const user = JSON.parse(
          localStorage.getItem("imt__user")!
        ) as api.AuthUser;
        if (
          user &&
          user.onboarding_status &&
          !user.plan_purchased &&
          !window.location.pathname.includes("membership-plan") &&
          user.activeRole !== LoginRole.EMPLOYEE &&
          user.activeRole !== LoginRole.CA
        ) {
          window.location.pathname = "/app/membership-plan";

          return;
        }
        if (!imtToken && window.location.pathname.startsWith("/app")) {
          window.location.href = "/";
          return;
        }
        if (!userData) {
          // console.log(klkl===)
        } else {
          try {
            const {
              id,
              email,
              role_ids,
              activeRole,
              save_emp_details,
              company_name,
              company_details,
              place_of_supply,
              gstin,
              first_name,
              last_name,
              terms_conditions_agree,
              membership_plan,
              plan_id,
              plan_name,
              fe_payment_status,
              plan_purchased,
              added_clients,
              primary_associated_company_id,
              company_id,
            } = JSON.parse(userData);
            setTimeout(() => {
              if (
                window.location.pathname === "/app/dashboard" &&
                activeRole === LoginRole.EMPLOYEE
              ) {
                if (!user.plan_purchased) {
                  window.location.pathname = `/app/expiredMembership`;
                } else {
                  window.location.pathname = "/app/employee/me";
                }
              } else if (
                window.location.pathname === "/app/employee/me" &&
                activeRole !== LoginRole.EMPLOYEE
              ) {
                if (!user.plan_purchased) {
                  window.location.pathname = `/app/expiredMembership`;
                } else {
                  if (!user.plan_purchased) {
                    window.location.pathname = `/app/expiredMembership`;
                  } else {
                    window.location.pathname = "/app";
                  }
                }
                // if(!membership_plan){
                //   window.location.pathname = "/membershipPlan";
                // }else{

                // }
              }
            }, 100);
            if (!Array.isArray(role_ids)) {
              throw Error("role_ids is not an array");
            }
            state.user = {
              id: +id,
              email,
              activeRole,
              role_ids,
              save_emp_details,
              company_name,
              company_details,
              place_of_supply,
              gstin,
              first_name,
              last_name,
              terms_conditions_agree,
              membership_plan,
              plan_id,
              plan_name,
              fe_payment_status,
              plan_purchased,
              added_clients,
              primary_associated_company_id,
              company_id,
            };
            state.steps = initialState.steps;
          } catch (err) {
            console.warn(err);
          }
        }
        state.token = imtToken as string;
      })
      .addCase(doChangeCompany.pending, (state, _action) => {
        state.apiState = "loading";
      })
      .addCase(doChangeCompany.rejected, (state, action) => {
        state.apiState = "error";
        state.errorMsg = action.error.message;
      })
      .addCase(doChangeCompany.fulfilled, (state, action) => {
        if (action.payload) {
          const user = {
            ...action.payload!.json[0].user,
            ...getDefaultCompRole(
              action.payload!.json[0].user.company_details!,
              action.payload!.json[0].user.company_id
            ),
            ...action.payload!.json[0].user.primary_associated_company_id,
          };
          const token = action.payload?.json[0].token;

          user.activeRole = action.payload.json[0].user.role_ids;
          // user.membership_plan=true;

          state.user = user;
          state.showModal = false;
          localStorage.setItem("imt__token", token);
          localStorage.setItem("imt__user", JSON.stringify(user));
          if (!user.onboarding_status) {
            setTimeout(() => {
              window.location.href = "/onboard";
            }, 100);
          }

          if (user.activeRole === LoginRole.EMPLOYEE) {
            if (user.activeRole === LoginRole.EMPLOYEE && user.plan_purchased) {
              window.location.pathname = "/app/employee/me";
            } else {
              window.location.replace("/app/expiredMembership");
            }
          } else {
            if (user.plan_purchased) {
              window.location.replace("/app");
            } else {
              if (
                user.activeRole !== LoginRole.CA &&
                user.activeRole !== LoginRole.EMPLOYEE
              ) {
                window.location.replace("/app/membership-plan");
              } else {
                window.location.replace("/app/expiredMembership");
              }
            }
          }
        }
      });
  },
});
