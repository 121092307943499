import { Form } from "antd";
import { useCallback, useState, useRef } from "react";
import usePersistantCallbackRef from "./usePersistantCallbackRef";
import usePrompt from "./usePrompt";

export type UpdateFunc<T> = (oldState: T) => Partial<T>;

const PROMPT_MSG =
  "You have unsaved details. Do you still want to leave this page?";

// let Iupdate = 0;
// let IhandleFormChange = 0;

/**
 * @param isDirtyChecking Pass false, to disable prompt when there are unsaved changes
 */
export default function useCqdForm<T>(isDirtyChecking = true) {
  const [form] = Form.useForm<T>();
  const [data, setData] = useState({} as T);
  const [isDirty, setDirty] = useState(false);
  const previousRef = useRef(null as any);

  usePrompt(PROMPT_MSG, isDirtyChecking && isDirty);

  const update = useCallback(
    (newDataOrFunc: Partial<T> | UpdateFunc<T>) => {
      setData((oldData) => {
        let newData: Partial<T>;
        if (typeof newDataOrFunc === "function") {
          newData = newDataOrFunc(oldData);
        } else {
          newData = newDataOrFunc;
        }

        if ((newData as any).row_data) {
          // Case 1. Ignore where there is no extra in any row_data of incoming newData
          if (!(newData as any).row_data?.every((it: any) => !!it.extra)) {
            // IGNORE
            // Case 2. Ignore when we are setting extra, i.e. in previous data extra was not there, but in newData it is there
          } else if (
            !(oldData as any)?.row_data?.every((it: any) => !!it.extra) &&
            (newData as any).row_data?.some((it: any) => !!it.extra)
          ) {
            // IGNORE
            // console.log("update row_data Case 2. IGNORE"); // KEEP-ME
          } else if (
            // TODO: Remove this JSON.stringify hack, used to detect unchanged row_data
            JSON.stringify((oldData as any).row_data) ===
            JSON.stringify((newData as any).row_data)
          ) {
            // IGNORE
          } else {
            // console.log("update setDirty check", {
            //   current: (newData as any).row_data,
            //   previous: (oldData as any)?.row_data,
            //   conditional:
            //     !(oldData as any)?.row_data?.every((it: any) => !!it.extra) &&
            //     (newData as any).row_data?.some((it: any) => !!it.extra),
            //   isEqual: (newData as any).row_data === (oldData as any)?.row_data,
            // });
            // console.log("[1] set isDirty = true"); // KEEP-ME
            setDirty(true);
          }
        }
        // cannot import type RecursivePartial so used any as an workaround
        form.setFieldsValue(newData as any);
        if (previousRef.current === null) {
          previousRef.current = newData;
        } else {
          previousRef.current = oldData;
        }
        return {
          ...oldData,
          ...newData,
        };
      });
    },
    [form]
  );

  const handleFormChange = usePersistantCallbackRef(
    (currentChange: any, values: T) => {
      // console.log()
      if (currentChange.extra) {
        // Case 1. Ignore when we are setting vendor with __init__ field
        // if (_.extra?.vendor.__init!) {
        //   // IGNORE
        //   // Case 2. Ignore when we are initializing vendor by replacing its __init__ property
        // } else
        if (
          currentChange.extra?.vendor &&
          (previousRef.current as any)?.extra?.vendor?.__init__
        ) {
          // IGNORE
        } else if (
          (currentChange as any).extra?.vendor ===
          (previousRef.current as any)?.extra?.vendor
        ) {
          // IGNORE
        } else {
          // console.log("handleFormChange setDirty check", {
          //   current: currentChange.extra?.vendor,
          //   previous: previousRef.current?.extra?.vendor,
          //   isEqual:
          //     currentChange.extra?.vendor ===
          //     previousRef.current?.extra?.vendor,
          // });
          // console.log("[2] set isDirty = true"); // KEEP-ME
          setDirty(true);
        }
      } else {
        // console.log("[3] set isDirty = true"); // KEEP-ME
        setDirty(true);
      }
      setData(values);
    }
  );

  const handleResetDirty: () => void = usePersistantCallbackRef(() => {
    // console.log("[100] set isDirty = false"); // KEEP-ME
    setDirty(false);
  });

  const readOnlyData = data as Readonly<T>;
  const readOnlyPrevData = (previousRef.current || {}) as Readonly<T>;

  return {
    update,
    prevData: readOnlyPrevData,
    data: readOnlyData,
    resetDirtyFlag: handleResetDirty,
    formProps: {
      form,
      onValuesChange: handleFormChange,
    },
  };
}
