import React from "react";
import { IMTIconProps, IMTIconElement, __colors } from "./interface";

const SalesDebit: IMTIconElement = ({
  highlighted,
  ...props
}: IMTIconProps) => {
  const color = highlighted ? __colors.active : __colors.inactive;
  return (

    <svg     width="20"
    height="17" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M792 400H736C731.6 400 728 403.6 728 408V728H72V72H392C396.4 72 400 68.4 400 64V8C400 3.6 396.4 0 392 0H32C14.3 0 0 14.3 0 32V768C0 785.7 14.3 800 32 800H768C785.7 800 800 785.7 800 768V408C800 403.6 796.4 400 792 400Z" fill="black"/>
<path d="M798.995 70.6975L799.999 8.47742C800.052 3.82007 796.246 0 791.541 0H791.33L728.949 1.51758C727.892 1.56992 726.834 1.98853 726.094 2.72116L506.229 219.89C504.59 221.512 504.59 224.181 506.229 225.803L571.94 290.796C572.786 291.634 573.843 292 574.954 292C576.064 292 577.121 291.581 577.967 290.796L797.779 73.628C798.543 72.8375 798.977 71.791 798.995 70.6975ZM765.426 58.3477L574.954 246.473L551.059 222.872L741.637 34.6423L765.796 34.0667L765.426 58.3477Z" fill="black"/>
<path d="M303.373 415.8C316.621 415.8 328.397 418.284 338.701 423.252C349.005 428.22 357.653 435.212 364.645 444.228C371.821 453.06 377.157 463.364 380.653 475.14C384.333 486.732 386.173 499.152 386.173 512.4C386.173 530.248 382.953 546.532 376.513 561.252C370.073 575.788 360.689 587.38 348.361 596.028C336.217 604.676 321.221 609 303.373 609H237.409C232.625 609 228.577 607.436 225.265 604.308C222.137 600.996 220.573 596.948 220.573 592.164V432.636C220.573 427.852 222.137 423.896 225.265 420.768C228.577 417.456 232.625 415.8 237.409 415.8H303.373ZM300.613 577.26C312.205 577.26 321.681 574.316 329.041 568.428C336.401 562.356 341.737 554.444 345.049 544.692C348.545 534.756 350.293 523.992 350.293 512.4C350.293 503.752 349.281 495.564 347.257 487.836C345.417 479.924 342.473 473.024 338.425 467.136C334.377 461.064 329.225 456.28 322.969 452.784C316.713 449.288 309.261 447.54 300.613 447.54H252.313L255.073 445.056V580.296L253.417 577.26H300.613ZM564.426 415.8C568.842 415.8 572.43 417.272 575.19 420.216C577.95 423.16 579.33 426.84 579.33 431.256V592.164C579.33 596.948 577.674 600.996 574.362 604.308C571.234 607.436 567.278 609 562.494 609C560.102 609 557.618 608.632 555.042 607.896C552.65 606.976 550.81 605.78 549.522 604.308L441.054 466.584L448.23 462.168V593.544C448.23 597.96 446.758 601.64 443.814 604.584C441.054 607.528 437.374 609 432.774 609C428.358 609 424.77 607.528 422.01 604.584C419.25 601.64 417.87 597.96 417.87 593.544V432.636C417.87 427.852 419.434 423.896 422.562 420.768C425.874 417.456 429.922 415.8 434.706 415.8C437.282 415.8 439.95 416.352 442.71 417.456C445.47 418.376 447.494 419.848 448.782 421.872L553.938 556.008L549.246 559.32V431.256C549.246 426.84 550.626 423.16 553.386 420.216C556.146 417.272 559.826 415.8 564.426 415.8Z" fill="black"/>
</svg>

    // <svg
    //   width="80"
    //   height="30"
    //   viewBox="0 0 63 76"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <mask id="path-1-inside-1_0_1" fill="white">
    //     <rect width="63" height="76" rx="3" />
    //   </mask>
    //   <rect
    //     width="63"
    //     height="76"
    //     rx="3"
    //     fill="white"
    //     stroke="black"
    //     stroke-width="8"
    //     mask="url(#path-1-inside-1_0_1)"
    //   />
    //   <path
    //     d="M19.2466 38.5C20.9266 38.5 22.4199 38.815 23.7266 39.445C25.0332 40.075 26.1299 40.9617 27.0166 42.105C27.9266 43.225 28.6032 44.5317 29.0466 46.025C29.5132 47.495 29.7466 49.07 29.7466 50.75C29.7466 53.0133 29.3382 55.0783 28.5216 56.945C27.7049 58.7883 26.5149 60.2583 24.9516 61.355C23.4116 62.4517 21.5099 63 19.2466 63H10.8816C10.2749 63 9.76158 62.8017 9.34158 62.405C8.94492 61.985 8.74658 61.4717 8.74658 60.865V40.635C8.74658 40.0283 8.94492 39.5267 9.34158 39.13C9.76158 38.71 10.2749 38.5 10.8816 38.5H19.2466ZM18.8966 58.975C20.3666 58.975 21.5682 58.6017 22.5016 57.855C23.4349 57.085 24.1116 56.0817 24.5316 54.845C24.9749 53.585 25.1966 52.22 25.1966 50.75C25.1966 49.6533 25.0682 48.615 24.8116 47.635C24.5783 46.6317 24.2049 45.7567 23.6916 45.01C23.1782 44.24 22.5249 43.6333 21.7316 43.19C20.9382 42.7467 19.9932 42.525 18.8966 42.525H12.7716L13.1216 42.21V59.36L12.9116 58.975H18.8966ZM52.3511 38.5C52.9111 38.5 53.3661 38.6867 53.7161 39.06C54.0661 39.4333 54.2411 39.9 54.2411 40.46V60.865C54.2411 61.4717 54.0311 61.985 53.6111 62.405C53.2144 62.8017 52.7128 63 52.1061 63C51.8028 63 51.4878 62.9533 51.1611 62.86C50.8578 62.7433 50.6244 62.5917 50.4611 62.405L36.7061 44.94L37.6161 44.38V61.04C37.6161 61.6 37.4294 62.0667 37.0561 62.44C36.7061 62.8133 36.2394 63 35.6561 63C35.0961 63 34.6411 62.8133 34.2911 62.44C33.9411 62.0667 33.7661 61.6 33.7661 61.04V40.635C33.7661 40.0283 33.9644 39.5267 34.3611 39.13C34.7811 38.71 35.2944 38.5 35.9011 38.5C36.2278 38.5 36.5661 38.57 36.9161 38.71C37.2661 38.8267 37.5228 39.0133 37.6861 39.27L51.0211 56.28L50.4261 56.7V40.46C50.4261 39.9 50.6011 39.4333 50.9511 39.06C51.3011 38.6867 51.7678 38.5 52.3511 38.5Z"
    //     fill="black"
    //   />
    //   <line
    //     x1="8"
    //     y1="14.5"
    //     x2="46"
    //     y2="14.5"
    //     stroke="#4F4F4F"
    //     stroke-width="3"
    //   />
    //   <line
    //     x1="8"
    //     y1="22.5"
    //     x2="27"
    //     y2="22.5"
    //     stroke="#4F4F4F"
    //     stroke-width="3"
    //   />
    // </svg>
  );
};

export default SalesDebit;
