import * as React from "react";
import { ColumnsType } from "antd/lib/table";
import { notification, Table, Tooltip } from "antd";
// import DatePicker from "~/component/antd-overrides/DatePicker";

import {
  litCategoryRenderer,
  litCurrencyInput,
  litDatePicker,
  litDeleteNewButton,
  litGSTRateInput,
  litInput,
  litPlaceOfSupply,
  litTextArea,
  litUnitInput,
  litVendorRenderer,
} from "~/fragment/line-items/litTableRenderers";
import AmountsAreSelect from "~/fragment/form-input/AmountsAreSelect";
// import RowAttachField from "~/fragment/attachable-field/RowAttachField";

import {
  BulkExpenseError,
  ExpenseAttachment,
  PartialBulkExpenseRecord,
} from "./bulkExpenseTypes";
import { EMPTY_ARRAY, roundNumber } from "~/lib";
import {
  DEFAULT_TAX_INCLUSION,
  TaxTeritoryType,
  TAX_INCLUSION,
} from "~/lib/taxes";
import { TaxInfo, getGST } from "~/lib/taxCalculation";
import {
  GST_OUT_OF_SCOPE,
  OTHER_TERITORY_ID,
  VENDOR_UNREGISTERED_TYPE_ID,
} from "~/lib/constants";
import texts from "~/contants/texts";

import { VendorItem } from "~/api/vendor";
// import { LoginRole } from "~/api/auth";

import commonStyles from "~/component/common.module.css";
import classes from "./ExpenseBulkCreatePage.module.less";
import { ExpenseAttachmentCell } from "./ExpenseAttachmentCell";
import { doOCRAPI } from "~/api/invoice_ocr";
import {
  useCustomerList,
  useExpenseCategoryList,
  useGSTRateList,
} from "~/lib/hook/api-hook/picklistHooks";
import usePersistantCallbackRef from "~/lib/hook/usePersistantCallbackRef";
import { sleep } from "~/utils";
import cx from "classnames";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { iterMap } from "~/lib/iterUtils";
import LICLeftButton from "~/fragment/line-items/LICLeftButton";
import { isFuture } from "date-fns";

// import set from "lodash/set";

export type CellChangeHandler = (
  propertyPath: string,
  index: number,
  value: unknown,
  record?: unknown
) => void;

interface IProps {
  isLoading: boolean;
  onNewGstin: (gstin: string, index: number) => void;
  myPlaceOfSupplyId: number;
  rows: Array<PartialBulkExpenseRecord>;
  onChange: (
    row: React.SetStateAction<Array<PartialBulkExpenseRecord>>
  ) => void;
  errors: BulkExpenseError;
  onAddVendor: () => void;
  onAddCategory: () => void;
  setChangeHandle: (fn: CellChangeHandler) => void;
}

function getNewRecord(
  defaults: PartialBulkExpenseRecord
): PartialBulkExpenseRecord {
  const obj: PartialBulkExpenseRecord = {
    id: Math.random() * -1,
    invoiceNo: "",
    attatchment: {},
    // attatchment: {
    //   fileKey: "f810e2787831a5e15b2fec0e0a64f6b8.pdf",
    //   fileName: "Invoice_846957077.pdf",
    // },
    date: undefined,
    description: "",
    category: undefined,
    vendor: undefined,
    placeOfSupplyId: undefined,

    hsnCode: "",
    quantity: 0,
    unit: undefined,
    rate: 0,
    amount: 0,
    taxRate: undefined,
    amountsAre: "ExclusiveOfTax",
    igst: 0,
    cgstSgst: 0,
    total: 0,
    ...defaults,
  };
  return obj;
}

const BulkExpenseTable: React.FC<IProps> = (props) => {
  const ocrJobRef = React.useRef<Map<number, AbortController>>();

  const lastRowIndex = React.useMemo(() => {
    return props.rows.length - 1;
  }, [props.rows]);

  const { gstRateList } = useGSTRateList();
  const { expenseCategoryList } = useExpenseCategoryList();
  const { customerList } = useCustomerList();

  const defaultTaxRate = React.useMemo(() => {
    return gstRateList.find((it) => it.rate === 18);
  }, [gstRateList]);

  const handleNewRow = React.useCallback(
    (e?: React.MouseEvent<HTMLElement>) => {
      if (props.rows.length > 9) {
        notification.warn({
          message: `You may create maximum 10 rows at a time.`,
        });
        return;
      }

      /**
       * If ctrl key is pressed duplicate last row else add new row from template
       */
      if (e?.ctrlKey) {
        const lastRow = props.rows[props.rows.length - 1];
        props.onChange([
          ...props.rows,
          {
            ...lastRow,
            extra: {
              ...lastRow.extra,
              errors: [],
            },
          },
        ]);
      } else {
        props.onChange([
          ...props.rows,
          getNewRecord({
            taxRate: defaultTaxRate,
            placeOfSupplyId: 19,
          }),
        ]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultTaxRate, props.rows, props.onChange]
  );

  const handleDeleteRow = React.useCallback(
    (index: number) => {
      props.onChange(props.rows.filter((_, rowIndex) => rowIndex !== index));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.rows, props.onChange]
  );

  const handleCellChange = usePersistantCallbackRef(
    (propertyPath: string, index: number, value: unknown, record?: unknown) => {
      // debugger;
      const oldRow = props.rows[index];
      const firstHalf = props.rows.slice(0, index);
      const secondHalf = props.rows.slice(index + 1);

      const newRow = { ...oldRow };
      if (record) {
        const r = record as any;
        switch (propertyPath) {
          case "taxRate.id": {
            newRow.taxRate = r;
            break;
          }
          case "unit.id": {
            newRow.unit = r;
            break;
          }
          case "category.value": {
            newRow.category = r;
            break;
          }
          case "vendor": {
            const vendor = r as VendorItem;
            if (vendor && vendor.state_code === OTHER_TERITORY_ID) {
              notification.warn({
                message: texts.blkWarnOutSideVendorMsg,
                description: texts.blkWarnOutSideVendorDesp,
              });
              return;
            } else if (
              vendor &&
              vendor.gst_registration_type_id === VENDOR_UNREGISTERED_TYPE_ID
            ) {
              notification.warn({
                message: texts.blkWarnUnRegVendorMsg,
                description: texts.blkWarnUnRegVendorDesp,
              });
              return;
            } else {
              newRow.vendor = vendor;
            }
            break;
          }
          case "attachment": {
            newRow.attatchment = r;
            // #region OCR
            if (r.file) {
              const at = r as ExpenseAttachment;
              at.isProcessing = true;
              const abc = new AbortController();
              ocrJobRef.current!.set(index, abc);
              // use .then-.catch instead of await so the result of the function can run
              //  still we can have the current data in closure
              doOCRAPI(at.file!, abc.signal)
                .then((ocrData) => {
                  ocrJobRef.current!.delete(index);
                  if (
                    ocrData.parsed.gstin &&
                    !customerList.find(
                      (it) => it.gstin === ocrData.parsed.gstin
                    )
                  ) {
                    props.onNewGstin(ocrData.parsed.gstin, index);
                  }
                  sleep(0).then(() =>
                    props.onChange((cRows) => {
                      const vendor = customerList.find(
                        (it) => it.gstin === ocrData.parsed.gstin
                      );
                      const amount =
                        ocrData.parsed.amount || ocrData.parsed.totalAmount;

                      const nNewRow: PartialBulkExpenseRecord = {
                        ...cRows[index],
                        invoiceNo: ocrData.parsed.invoiceNo,
                        taxRate: gstRateList.find(
                          (el) => el.rate === ocrData.parsed.taxRate
                        )!,
                        date: ocrData.parsed.invoiceDate!,
                        category: expenseCategoryList.find(
                          (it) => it.label === ocrData.parsed.category
                        ) as any,
                        quantity: amount ? 1 : 1,
                        rate: amount,
                        hsnCode: ocrData.parsed.hsn,
                        vendor,
                        placeOfSupplyId: vendor?.state_code,
                        extra: {
                          ...cRows[index].extra,
                          vendor: ocrData.parsed.gstin
                            ? {
                                __init__: {
                                  gstin: ocrData.parsed.gstin,
                                  uid: Math.random(),
                                },
                              }
                            : "",
                        },
                      };
                      if (
                        vendor?.gst_registration_type_id ===
                        VENDOR_UNREGISTERED_TYPE_ID
                      ) {
                        nNewRow.taxRate = undefined;
                        nNewRow.amountsAre = "OutOfTax";
                        nNewRow.taxRate = gstRateList.find(
                          (it) => it.id === GST_OUT_OF_SCOPE
                        );
                      }
                      calculateTotal(nNewRow);
                      return [
                        ...cRows.slice(0, index),
                        nNewRow,
                        ...cRows.slice(index + 1),
                      ];
                    })
                  );
                })
                .catch((err) => {
                  console.error(err);
                  notification.warn({
                    message: `OCR failed on file ${at.fileName}.`,
                  });
                })
                .finally(() => {
                  props.onChange((cRows) => {
                    cRows[index].attatchment = {
                      ...cRows[index].attatchment,
                      isProcessing: false,
                    };
                    return cRows;
                  });
                });
            }
            // #endregion OCR
            break;
          }
        }
      } else {
        // newRow = set(newRow, propertyPath, value);
        (newRow as any)[propertyPath] = value;
      }
      // #region fill related columns

      if (propertyPath === "amountsAre") {
        const v = (value as any) as TAX_INCLUSION;
        if (v === "OutOfTax") {
          newRow.taxRate = undefined;
          newRow.taxRate = gstRateList.find((it) => it.id === GST_OUT_OF_SCOPE);
        }
      } else {
        if (!newRow.amountsAre) {
          if (!newRow.vendor || !!newRow.vendor.gstin) {
            newRow.amountsAre = DEFAULT_TAX_INCLUSION;
          }
        }
      }

      if (propertyPath === "vendor") {
        const vendor = (record as any) as VendorItem;
        if (vendor && vendor.state_code !== OTHER_TERITORY_ID) {
          newRow.placeOfSupplyId = vendor.state_code;
          if (!newRow.taxRate) {
            newRow.taxRate = gstRateList.find((it) => it.rate === 18);
          }
        } else {
          newRow.placeOfSupplyId = undefined;
        }

        if (vendor?.gst_registration_type_id === VENDOR_UNREGISTERED_TYPE_ID) {
          newRow.taxRate = undefined;
          newRow.taxRate = gstRateList.find((it) => it.id === GST_OUT_OF_SCOPE);
          newRow.amountsAre = "OutOfTax";
        }
      }

      calculateTotal(newRow);

      // #endregion fill related columns
      // console.log("[0] newRows: ", [...firstHalf, newRow, ...secondHalf]);
      props.onChange([...firstHalf, newRow, ...secondHalf]);

      function calculateTotal(xRow: PartialBulkExpenseRecord) {
        if (typeof (xRow.rate! * xRow.quantity!) === "number") {
          if (Number.isFinite(xRow.rate!) && Number.isFinite(xRow.quantity!)) {
            xRow.amount = roundNumber(xRow.rate! * xRow.quantity!);
          }

          const taxTeritoryType =
            !xRow.placeOfSupplyId || xRow.placeOfSupplyId === OTHER_TERITORY_ID
              ? TaxTeritoryType.OTHER_TERITORY
              : xRow.placeOfSupplyId === props.myPlaceOfSupplyId
              ? TaxTeritoryType.SAME_STATE
              : TaxTeritoryType.INTRA_STATE;

          const taxInfo: TaxInfo = {
            cessPercentage: 0,
            gstPercentage: xRow.taxRate?.rate ?? 0,
            taxInclusion: xRow.amountsAre ?? "OutOfTax",
            taxTeritoryType,
          };

          const tAmounts = getGST(xRow.amount ?? 0, taxInfo);

          xRow.igst = tAmounts.igst;
          xRow.cgstSgst = tAmounts.cgst + tAmounts.sgst;
          xRow.amount = tAmounts.subTotal;
          xRow.total = tAmounts.total;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // [props.rows, props.onChange]
  );

  const errorsRef = React.useRef<BulkExpenseError>(props.errors);

  errorsRef.current = props.errors;

  const columnDef = React.useMemo(() => {
    // const addNewBtnRenderer = litAddNewButton({
    //   lastRowIndex,
    //   readOnly: false,
    //   onNewRow: handleNewRow,
    // });
    const definitions: ColumnsType<PartialBulkExpenseRecord> = [
      {
        title: "",
        key: "errors",
        width: 24,
        fixed: "left",
        render(_v, _r, index) {
          const error = props.errors.get(index);

          if (!error) {
            return null;
          } else {
            return (
              <Tooltip
                title={() => (
                  <ul className={classes.toolTipErrorUL}>
                    {iterMap(error.values(), (msg, idx) => (
                      <li key={idx}>{msg}</li>
                    ))}
                  </ul>
                )}
              >
                <ExclamationCircleOutlined className={classes.warnIcon} />
              </Tooltip>
            );
          }
        },
      },
      {
        title: "",
        dataIndex: "id",
        width: 36,
        fixed: "left",
        render(_value, _record, index) {
          // if (errorsRef.current?.has(index)) {
          //   const error = errorsRef.current!.get(index)!;
          //   return
          // }
          // return addNewBtnRenderer(value, record, index);
          // const error = props.errors.get(index);
          return (
            <div>
              <LICLeftButton
                isLastRow={index === lastRowIndex}
                readOnly={false}
                onAddNew={handleNewRow}
                errors={EMPTY_ARRAY}
                // errors={iterMap(error?.entries(), ([dataIndex, message]) => ({
                //   dataIndex,
                //   message,
                // }))}
              />
            </div>
          );
        },
      },
      {
        title: "",
        // dataIndex: "id",
        key: "delete_action",
        width: 36,
        fixed: "left",
        render: litDeleteNewButton({
          lastRowIndex,
          readOnly: false,
          onDeleteRow: handleDeleteRow,
        }),
      },
      {
        title: "",
        key: "slno",
        width: 32,
        fixed: "left",
        align: "center",
        render(_0, _1, index) {
          return <b>{index + 1}.</b>;
        },
      },
      {
        title: (
          <span>
            <span style={{ color: "#EB5757" }}>*</span> Invoice no.
          </span>
        ),
        dataIndex: "invoiceNo",
        width: 120,
        fixed: "left",
        render: litInput({
          fieldName: "invoiceNo",
          readOnly: false,
          inputType: "text",
          onChange: handleCellChange,
        }),
      },
      {
        title: "Attachment",
        key: "attachment",
        width: 152,
        fixed: "left",
        align: "center",
        render(_, record, index) {
          // const handleChange = (
          //   newHashFile: string,
          //   documentName: string
          //   // idx: numbers
          // ) => {
          //   const attachment: ExpenseAttachment = {
          //     fileKey: newHashFile,
          //     fileName: documentName,
          //   };
          //   handleCellChange("attachment", index, newHashFile, attachment);
          // };

          const handleChange = (
            fileKey: string,
            fileName: string,
            file: File
          ) => {
            const attachment: ExpenseAttachment = {
              file,
              fileKey,
              fileName,
            };
            handleCellChange("attachment", index, fileKey, attachment);
          };

          const handleDelete = () => {
            handleCellChange("attachment", index, "", {});
          };

          const handleOCRCancel = () => {
            if (!ocrJobRef.current?.has(index)) {
              console.error("No Job created for row idx:", index);
              return;
            }
            const abc = ocrJobRef.current.get(index)!;
            abc.abort();
            ocrJobRef.current.delete(index);
            handleCellChange("attachment", index, "", {});
          };

          const previewTitle = record.attatchment?.fileName
            ? `${record.invoiceNo ?? ""} | File: ${
                record.attatchment?.fileName
              }`
            : undefined;
          return (
            <ExpenseAttachmentCell
              fileKey={record.attatchment?.fileKey}
              fileName={record.attatchment?.fileName}
              isProcessing={record.attatchment?.isProcessing}
              previewTitle={previewTitle}
              onChange={handleChange}
              onDelete={handleDelete}
              onCancelProcess={handleOCRCancel}
            />
            // <RowAttachField
            //   fieldName="attachment"
            //   hashFileName={record.attatchment?.fileKey}
            //   disable={false}
            //   activeRole={LoginRole.ADMIN}
            //   isNew={false}
            //   idx={index}
            //   documentName={record.attatchment?.fileName}
            //   readonly={false}
            //   label="Document"
            //   selectedFy={"NA"}
            //   opts_fy={["NA", "NA"]}
            //   from="Bulk expense"
            //   onHashFileChange={handleChange}
            //   rules={[]}
            //   mimeTypes=".pdf,image/*"
            // />
          );
        },
      },
      {
        title: (
          <span>
            <span style={{ color: "#EB5757" }}>*</span> Date.
          </span>
        ),
        dataIndex: "date",
        width: 138,
        render: litDatePicker({
          fieldName: "date",
          readOnly: false,
          disabledDate: isFuture,
          onChange: handleCellChange,
        }),
      },
      {
        title: (
          <span>
            <span style={{ color: "#EB5757" }}>*</span> Narration
          </span>
        ),
        dataIndex: "description",
        width: 180,
        render: litTextArea({
          fieldName: "description",
          readOnly: false,
          onChange: handleCellChange,
        }),
      },
      {
        title: (
          <div className={classes.addableColumnHead}>
            <span className={cx(classes.addableColTitle, classes.mandatoryDot)}>
              Category
            </span>
            <div
              className={classes.addBtn}
              role="button"
              onClick={props.onAddCategory}
            >
              + Add new
            </div>
          </div>
        ),
        dataIndex: ["category", "value"],
        width: 176,
        render: litCategoryRenderer({
          fieldName: "category.value",
          readOnly: false,
          onChange: handleCellChange,
        }),
      },
      {
        title: (
          <div className={classes.addableColumnHead}>
            <span className={cx(classes.addableColTitle, classes.mandatoryDot)}>
              Vendor
            </span>
            <div
              className={classes.addBtn}
              role="button"
              onClick={props.onAddVendor}
            >
              + Add new
            </div>
          </div>
        ),
        dataIndex: "vendor",
        width: 240,
        render: litVendorRenderer({
          fieldName: "vendor",
          readOnly: false,
          onChange: handleCellChange,
        }),
      },
      {
        title: (
          <span>
            <span style={{ color: "#EB5757" }}>*</span> Supplied from
          </span>
        ),
        dataIndex: "placeOfSupplyId",
        width: 170,
        render: litPlaceOfSupply({
          fieldName: "placeOfSupplyId",
          readOnly: false,
          onChange: handleCellChange,
        }),
      },
      {
        title: "HSN",
        dataIndex: "hsnCode",
        width: 110,
        render: litInput({
          fieldName: "hsnCode",
          inputType: "text",
          readOnly: false,
          onChange: handleCellChange,
        }),
      },
      {
        title: (
          <span>
            <span style={{ color: "#EB5757" }}>*</span> Quantity
          </span>
        ),
        align: "right",
        width: 120,
        dataIndex: "quantity",
        render: litCurrencyInput({
          fieldName: "quantity",
          disabled: false,
          readOnly: false,
          onChange: handleCellChange,
        }),
      },
      {
        title: "Unit",
        dataIndex: ["unit", "id"],
        width: 100,
        render: litUnitInput({
          fieldName: "unit.id",
          readOnly: false,
          onChange: handleCellChange,
        }),
        // render(value: string, record, index) {
        //   return <Input value={value} onChange={console.log} />;
        // },
      },
      {
        title: (
          <span>
            <span style={{ color: "#EB5757" }}>*</span> Rate
          </span>
        ),
        dataIndex: "rate",
        align: "right",
        width: 120,
        render: litCurrencyInput({
          fieldName: "rate",
          disabled: false,
          readOnly: false,
          onChange: handleCellChange,
        }),
        // render(value: string, record, index) {
        //   return <Input value={value} onChange={console.log} />;
        // },
      },
      {
        title: "Tax rate",
        dataIndex: ["taxRate", "id"],
        width: 140,
        // render(value: string, record, index) {
        //   return <Input value={value} onChange={console.log} />;
        // },
        render: litGSTRateInput({
          fieldName: "taxRate.id",
          readOnly: false,
          disabled: (
            _value: any,
            row: PartialBulkExpenseRecord,
            _index: number
          ) => row.amountsAre === "OutOfTax",
          onChange: handleCellChange,
        }),
      },
      {
        title: (
          <span>
            <span style={{ color: "#EB5757" }}>*</span> Amounts are
          </span>
        ),
        dataIndex: "amountsAre",
        width: 148,
        render: (
          value: TAX_INCLUSION,
          row: PartialBulkExpenseRecord,
          index: number
        ) => {
          const handleChange = (value?: string) => {
            handleCellChange("amountsAre", index, value);
          };

          return (
            <AmountsAreSelect
              className={
                row.extra?.errors?.length > 0 && !value
                  ? classes.selectError
                  : commonStyles["w-100"]
              }
              readOnly={false}
              placeOfSupplyId={row.placeOfSupplyId}
              vendorUnregFlag={row.vendor?.gstin === null}
              value={value}
              onChange={handleChange}
            />
          );
        },
      },
      {
        title: "IGST",
        dataIndex: "igst",
        align: "right",
        width: 110,
        render: litCurrencyInput({
          fieldName: "igst",
          disabled: false,
          readOnly: true,
          onChange: handleCellChange,
        }),
      },
      {
        title: "CGST/SGST",
        dataIndex: "cgstSgst",
        align: "right",
        width: 110,
        render: litCurrencyInput({
          fieldName: "sgst",
          disabled: false,
          readOnly: true,
          onChange: handleCellChange,
        }),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        align: "right",
        width: 120,
        render: litCurrencyInput({
          fieldName: "amountx",
          disabled: false,
          readOnly: true,
          onChange: handleCellChange,
        }),
      },
      {
        title: <span className={classes.totalTitle}>Total</span>,
        dataIndex: "total",
        align: "right",
        width: 110,
        render: litCurrencyInput({
          fieldName: "totalx",
          disabled: false,
          readOnly: true,
          onChange: handleCellChange,
        }),
      },
    ];
    return definitions;
  }, [
    lastRowIndex,
    props.errors,
    handleNewRow,
    handleDeleteRow,
    handleCellChange,
    props.onAddVendor,
    props.onAddCategory,
  ]);

  React.useEffect(() => {
    if (props.rows.length === 0) {
      handleNewRow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rows, handleNewRow]);

  const getRowProps = React.useCallback(
    (
      _data: PartialBulkExpenseRecord,
      index?: number
    ): React.HTMLAttributes<HTMLElement> => {
      if (props.errors.has(index!)) {
        return {
          className: classes.rowValidationError,
        };
      }
      return {};
    },
    [props.errors]
  );

  React.useEffect(() => {
    props.setChangeHandle(handleCellChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setChangeHandle, handleCellChange]);

  React.useEffect(() => {
    ocrJobRef.current = new Map();
  }, []);

  return (
    <Table
      className={classes.table}
      rowKey="id"
      size="small"
      loading={props.isLoading}
      onRow={getRowProps}
      dataSource={props.rows}
      columns={columnDef}
      pagination={false}
      scroll={{ x: 440 }}
    />
  );
};

export default BulkExpenseTable;
