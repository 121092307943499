import React, { PureComponent } from "react";
import {
  CheckSquareOutlined,
  LoadingOutlined,
  CloseOutlined,
  LineOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { fetchUtil } from "~/api/common";
import { Button, notification, Spin, Tooltip, Typography } from "antd";
import TALLY_ICON from "~/assets/Tally-Erp-9-with-GST-Logo.png";
import GSTIN_LOGO from "~/assets/GSTN-19-12-16.png";
import EXCL_CSV_ICON from "~/assets/excel-logo.png";
import styles from "./Iconstyle.module.css";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";

interface Props {
  icon: string;
  sourceLabel: string;
  sourceName: any;
  syncProcessStatus?: any;
  syncStatusDetail: any;
  setInterValStore: (data: any) => void;
  intervalData: (intervalId: any) => void;
  syncInterval: any;
  sumOfError: number;
  sumOfTotal: number;
  sumTotalLoad: number;
  processId?: any;
  elapsedTime?: any;
}

interface State {
  setInterValObj: any;
  forceStopLoading: boolean;
  elapseTimeCount?: any;
  elapsCount?: any;
}

export default class SyncStatusPage extends PureComponent<Props> {
  state: State = {
    setInterValObj: {},
    forceStopLoading: false,
    elapseTimeCount: 0,
    elapsCount: 0,
  };

  statusInterval: any = 0;

  componentDidMount() {
    // console.log(this.props.processId)
    this.getStatus(); // this code is duplicte need to delete
    // this.convertMsToHM(30000);
    this.handleElapseTime();
    // const interval = setInterval(this.getStatus, 4000);
    // this.props.intervalData(interval);
  }

  // x = ;

  // static getDerivedStateFromProps(
  //   nextProps: Readonly<Props>,
  //   prevState: State
  // ) {
  //   if (nextProps.syncProcessStatus !== "RUNNING") {
  //     clearInterval(this.statusInterval);
  //   }
  // }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.syncProcessStatus !== "RUNNING") {
      clearInterval(this.statusInterval);
    }
  }

  handleElapseTime = () => {
    clearInterval(this.statusInterval);
    this.statusInterval = setInterval(() => {
      return this.setState(
        {
          elapseTimeCount: this.state.elapseTimeCount + 1,
        },
        () => {
          const time = this.convertMsToHM(this.state.elapseTimeCount * 1000);

          this.setState({
            elapsCount: time,
          });
        }
      );
    }, 1000);
  };

  convertMsToHM = (milliseconds: any) => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    // if seconds are greater than 30, round minutes up (optional)
    // minutes = seconds >= 30 ? minutes + 1 : minutes;

    minutes = minutes % 60;
    // console.log (typeof seconds,typeof minutes,typeof hours,"PPPPPppppppppppppp")
    // If you don't want to roll hours over, e.g. 24 to 00
    // comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    // hours = hours % 24;

    let h;
    if (hours !== 0) h = `${hours}h `;
    else h = ``;

    let m;
    if (minutes !== 0) m = `${minutes}m `;
    else m = ``;

    let s;
    if (seconds !== 0) s = `${seconds}s`;
    else s = ``;

    // console.log(h,m,s,"++++++++++++")

    const res = h + m + s;
    // console.log(res)
    return res;
  };

  // getEstimateTime = async () => {
  //   const { ok, json, message } = await fetchUtil(
  //     "POST",
  //     "/get_sync_elapsed_time",
  //     {
  //       process_id: this.props.processId,
  //     }
  //   );
  //   if (!ok) {
  //     notification.error({ message });
  //   } else {
  //     console.log(json);
  //   }
  // };

  getSourceIconAndLabel = (sourceName: any) => {
    let icon = GSTIN_LOGO;
    let sourceLabel = "";
    switch (sourceName.toLowerCase()) {
      case "gstin":
        icon = GSTIN_LOGO;
        sourceLabel = "GSTIN";
        break;
      case "tally":
        icon = TALLY_ICON;
        sourceLabel = "Tally";
        break;
      case "amazoncsv":
        icon = EXCL_CSV_ICON;
        sourceLabel = "Amazon seller CSV";
        break;
      default:
        icon = GSTIN_LOGO;
        break;
    }
    return { icon, sourceLabel };
  };

  getStatus = async () => {
    // data pull api
    try {
      const { ok, json, message } = await fetchUtil("GET", "/get_sync_status");
      if (!ok) {
        notification.error({ message });
        return { ok, message };
      } else {
        // console.log(json)
        this.setState(
          {
            elapseTimeCount: 0,
          },
          async () => {
            await this.handleElapseTime();
          }
        );
        this.props.setInterValStore(json);
      }
    } catch (error) {
      notification.error({ message: error.message });
    }
  };

  handleForceTop = async () => {
    this.setState({
      forceStopLoading: true,
    });
    const userData = localStorage.getItem("imt__user");
    const userParseData = JSON.parse(userData!);
    try {
      const { ok, json, message } = await fetchUtil("POST", "/etl_force_stop", {
        companyId: userParseData.company_id,
        process_id: this.props.processId,
      });
      if (!ok) {
        notification.error({ message });
        this.setState({
          forceStopLoading: false,
        });
        return { ok, message };
      } else {
        this.setState({
          forceStopLoading: false,
        });
        notification.success({ message });
        // console.log(json)
        // this.props.setInterValStore(json);
      }
    } catch (error) {
      this.setState({
        forceStopLoading: false,
      });
      notification.error({ message: error.message });
    }
  };

  render() {
    let done = 0;
    let failed = 0;
    let skipped = 0;
    // console.log(this.state.elapseTimeCount, "eeeeeeeeeeeeee");

    const renderList = this.props.syncStatusDetail?.map(
      (item: any, indx: number) => {
        if (item.status === "Success") {
          done += 1;
        } else if (item.status === "Skipped") {
          skipped += 1;
        } else if (item.status === "Error") {
          failed += 1;
        }

        const showCount =
          item.status === "Success" || item.status === "Processing";

        return (
          <div key={indx}>
            <Helmet>
              <title>{titles.SyncStatusPage}</title>
            </Helmet>
            <span>
              {item.status === "Success" ? (
                <CheckSquareOutlined
                  style={{ color: "green", fontSize: "15px" }}
                />
              ) : (item.status === "Waiting" || item.status === "Processing") &&
                this.props.syncProcessStatus !== "FORCE_STOPPED" &&
                this.props.syncProcessStatus !== "ERROR" ? (
                <LoadingOutlined />
              ) : item.status === "Error" ? (
                <CloseOutlined style={{ color: "red", fontSize: "15px" }} />
              ) : item.status === "Skipped" ||
                item.status === "Not Started" ||
                this.props.syncProcessStatus === "FORCE_STOPPED" ? (
                <LineOutlined />
              ) : (
                <></>
              )}{" "}
            </span>
            <span
              style={
                item.status === "Skipped" || item.status === "Not Started"
                  ? {
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "#828282",
                      marginLeft: "5px",
                    }
                  : { fontSize: "15px", fontWeight: "bold", marginLeft: "5px" }
              }
            >
              {item.status_text} ...
            </span>
            <span
              style={
                item.status === "Skipped" || item.status === "Not Started"
                  ? {
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "#828282",

                      marginLeft: "5px",
                    }
                  : { fontSize: "15px", fontWeight: "bold", marginLeft: "5px" }
              }
            >
              {this.props.syncProcessStatus === "ERROR" &&
              item.status === "Waiting"
                ? "Error"
                : item.status}{" "}
              {showCount
                ? `( ${item.success_count} / ${item.total_count} )`
                : ""}
              {item.error_count > 0 && (
                <Tooltip title={`${item.error_count} error`}>
                  {" "}
                  <span style={{ fontSize: "14px" }}>
                    <strong>
                      <WarningOutlined style={{ color: "#F0792E" }} />{" "}
                      {item.error_count}
                    </strong>
                  </span>
                </Tooltip>
              )}
            </span>
          </div>
        );
      }
    );

    return (
      <div>
        <div style={{ textAlign: "center" }}>
          {this.props.icon ? (
            <img alt="" src={this.props.icon} className={styles.talyIcon} />
          ) : (
            <img
              alt=""
              src={this.getSourceIconAndLabel(this.props.sourceName).icon}
              className={styles.talyIcon}
            />
          )}

          <Typography.Title
            level={4}
            style={{
              textAlign: "center",
            }}
          >
            <b className={styles.sourceheading}>
              {this.props.sourceLabel
                ? this.props.sourceLabel
                : this.getSourceIconAndLabel(this.props.sourceName)
                    .sourceLabel}{" "}
              |{" "}
              {this.props.syncProcessStatus === "RUNNING"
                ? "Syncing..."
                : this.props.syncProcessStatus === "SUCCESS"
                ? "Syncing completed"
                : this.props.syncProcessStatus === "FORCE_STOPPED"
                ? "Force stopped"
                : this.props.syncProcessStatus === "ERROR"
                ? "Error"
                : ""}
            </b>
          </Typography.Title>

          {/* <div style={{ fontWeight: "bold" }}>
            {this.state.syncProcessStatus // true
              ? "Syncing..."
              : "Syncing Completed"}
          </div> */}

          <div style={{ color: "#2F80ED", fontWeight: "bold" }}>
            [ {done} Done ] [ {skipped} Skipped ] [ {failed} Failed ]
            <div style={{ color: "#8C8C8C" }}>
              {this.props.syncProcessStatus === "RUNNING"
                ? "Running since"
                : "Ran for"}{" "}
              {this.state.elapsCount}
            </div>
          </div>
          {/* <span>
            <b>
              Running since{" "}
              {this.props.elapsedTime ? this.props.elapsedTime : "0s"}
            </b>{" "}
          </span> */}
        </div>
        <Spin size="small" spinning={this.state.forceStopLoading}>
          <div
            style={{
              marginTop: "20px",
              minHeight: "126px",
              paddingLeft: "19px",
              paddingRight: "19px",
              marginBottom: "30px",
            }}
          >
            {renderList}
          </div>
          {this.props.syncProcessStatus === "RUNNING" ? (
            <div
              style={{
                justifyContent: "flex-end",
                display: "flex",
                marginTop: "-30px",
              }}
            >
              <Button
                type="link"
                style={{ color: "red" }}
                onClick={this.handleForceTop}
              >
                Force stop
              </Button>
            </div>
          ) : (
            <></>
          )}
        </Spin>
      </div>
    );
  }
}
