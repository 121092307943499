import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as api from "~/api/auth";
import { notification } from "antd";
import { action } from "./store";
import { getDefaultCompRole } from "~/lib/companyRole";

export interface SignupState {
  showModal: boolean;
  apiState: "idle" | "loading" | "success" | "error";
  apiMessage?: string;
}

const initialState: SignupState = {
  showModal: false,
  apiState: "idle",
};

export const doSignup = createAsyncThunk(
  "landing/user/signup",
  async (reqArgs: api.SignupRequest, _thunkAPI) => {
    const response = await api.signUpCompany(reqArgs);
    if (!response.ok) {
      throw notification.error({ message: "User Already Exist " });
    } else {
      if (reqArgs.google_login) {
        if (!response.data.user) {
          const loginpayLoad: api.LoginRequest = {
            password: null,
            email: reqArgs.admin_email,
            google_login: true,
            google_secret_key: reqArgs.google_secret_key,
          };
          _thunkAPI.dispatch(action.login.doLogin(loginpayLoad));
          _thunkAPI.dispatch(signupSlice.actions.hideModal());
        }
        if (Object.keys(response.data.user).length > 0 && response.data.token) {
          const userData = {
            ...response.data.user,
            ...getDefaultCompRole(
              response.data.user.company_details!,
              response.data.user.company_id
            ),
          };
          const value = userData.company_details;
          userData.company_details = value;
          const value1 = userData.gstin;
          userData.gstin = value1;

          localStorage.setItem("imt__token", response.data.token);
          const firstName = userData.first_name;
          const lastName = userData.last_name;
          const terms_conditions_agree = userData.terms_conditions_agree;
          userData.first_name = firstName;
          userData.last_name = lastName;
          userData.terms_conditions_agree = terms_conditions_agree;
          localStorage.setItem("imt__user", JSON.stringify(userData));
          setTimeout(() => {
            window.location.href = userData.onboarding_status
              ? "/app"
              : "/onboard";
          }, 100);
          _thunkAPI.dispatch(signupSlice.actions.hideModal());
        }
      }
      if (!reqArgs.google_login) {
        setTimeout(() => {
          notification.success({ message: response.userMessage });
        }, 1000);
      }

      return response;
    }
  }
);

export const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    showModal(state) {
      state.showModal = true;
    },
    hideModal(state) {
      state.showModal = false;
      state.apiMessage = "";
      state.apiState = "idle";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(doSignup.pending, (state, _action) => {
        state.apiState = "loading";
        state.apiMessage = "";
      })
      .addCase(doSignup.rejected, (state, _action) => {
        state.apiState = "error";
        state.apiMessage = _action.error.message;
      })
      .addCase(doSignup.fulfilled, (state, action) => {
        state.apiState = "success";
        state.apiMessage = action.payload.userMessage;
      });
  },
});
