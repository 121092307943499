import { fetchUtil, fetchTableExportBlob } from "./common";
import pick from "lodash/pick";
import { parseDate } from "~/lib";

export type APIEmployee = {
  emp_id: number;
  engagement_type: string;
  company_emp_id?: string;
  joining_date?: Date | null;
  leaving_date?: Date | null;
  designation_id: number;
  designation: string;
  first_name?: string;
  last_name: null;
  emp_name: string;
  profile_pic_url: string;
  reports_to_name: string;
  save_emp_details: boolean;
  is_active: boolean;
};

type APIEmployeeListRawType = APIEmployee & {
  total_rows: number;
};

export type EmployeeResponse = {
  pageSize: number;
  pageNo: number;
  pageCount: number;
  totalArrayLength: number;
  employees: Array<APIEmployee>;
};

export const getEmployeeList = (
  pageNo: number,
  pageSize: number,
  isActive: boolean
) => {
  return fetchUtil("POST", "/get_employee_list", {
    page_no: pageNo,
    no_of_rows: pageSize,
    is_active: isActive,
  }).then(({ ok, json }) => {
    const emloyeeList = json as Array<APIEmployeeListRawType>;
    const response: EmployeeResponse = {
      pageNo,
      pageSize,
      pageCount:
        emloyeeList.length > 0
          ? Math.ceil(emloyeeList[0].total_rows / pageSize)
          : 0,
      totalArrayLength: emloyeeList.length > 0 ? emloyeeList[0].total_rows : 0,
      employees: [],
    };
    for (const rawEmployee of emloyeeList) {
      const obj = pick(rawEmployee, [
        "emp_id",
        "engagement_type",
        "company_emp_id",
        "joining_date",
        "leaving_date",
        "designation_id",
        "designation",
        "first_name",
        "last_name",
        "profile_pic_url",
        "save_emp_details",
        "emp_name",
        "reports_to_name",
        "is_active",
      ]);
      obj.joining_date = obj.joining_date
        ? new Date(obj.joining_date as any)
        : null;
      obj.leaving_date = obj.leaving_date
        ? new Date(obj.leaving_date as any)
        : null;
      response.employees.push(obj);
    }
    return response;
  });
};

export const getTableExportBlob = async (
  pageNo: number,
  pageSize: number,
  isActive: boolean,
  acceptType: string,
  selectedFields: string
): Promise<{
  ok: boolean;
  blob: Blob | null | undefined;
  message: string;
}> => {
  const { ok, blob, message } = await fetchTableExportBlob(
    "POST",
    "/get_employee_list",
    {
      page_no: pageNo,
      no_of_rows: pageSize,
      is_active: isActive,
    },
    acceptType,
    selectedFields
  );
  if (!ok) {
    return { ok, blob: null, message };
  } else {
    return {
      ok,
      blob,
      message,
    };
  }
};

export type APIEmployeeDetailsRaw = {
  emp_id: number;
  company_emp_id: string;
  email: string;
  profile_pic_url: string;
  first_name: string;
  last_name: any;
  father_name: string;
  dob: string;
  address: string;
  mobile_no: string;
  emergency_no: string;
  engagement_type: any;
  gender: any;
  is_active: boolean;
  crt_time: string;
  update_time: string;
  designation: number;
  designation_name: string;
  primary_email: string;
  joining_date: string;
  leaving_date: string;
  reports_to_name: string;
};

export type EmployeeDetails = {
  emp_id: number;
  company_emp_id: string;
  email: string;
  profile_pic_url?: string;
  first_name?: string;
  last_name?: string;
  father_name?: string;
  dob?: Date;
  address?: string;
  mobile_no?: string;
  emergency_no?: string;
  engagement_type?: string;
  engagement_type_id?: number;
  gender?: string;
  reports_to_name?: string;
  is_active: boolean;
  crt_time: Date;
  update_time: Date | null;
  designation?: number;
  designation_name?: string;
  primary_email?: string;
  joining_date?: Date | null;
  leaving_date?: Date | null;
};

export type EmployeeDetailsResponse = {
  ok: boolean;
  message: string;
  data?: EmployeeDetails;
};
export const getEmployeeDetails = async (
  emp_id: number
): Promise<EmployeeDetailsResponse> => {
  const { ok, json, message } = await fetchUtil(
    "POST",
    `/get_employee_details`,
    {
      emp_id,
    }
  );

  if (!ok) {
    return {
      ok,
      message,
    };
  } else {
    const _data = json[0] as APIEmployeeDetailsRaw;
    const data: EmployeeDetails = {
      ..._data,
      dob:
        (_data.dob?.length || 0) <= 10
          ? parseDate(_data.dob, "yyyy-MM-dd")
          : new Date(_data.dob),
      crt_time: new Date(_data.crt_time),
      update_time: _data.update_time ? new Date(_data.update_time) : null,
      joining_date: _data.joining_date ? new Date(_data.joining_date) : null,
      leaving_date: _data.leaving_date ? new Date(_data.leaving_date) : null,
    };
    return {
      ok,
      data,
      message,
    };
  }
};
export const getEmployeeEditAccess = async (
  emp_id: number
): Promise<{ ok: boolean; message: string }> => {
  const { ok, json, message } = await fetchUtil(
    "POST",
    `/getEmployeeEditAccess`,
    {
      emp_id,
    }
  );

  if (!ok) {
    return {
      ok,
      message,
    };
  } else {
    const data = json[0];
  }
  return {
    ok,
    // data,
    message,
  };
};

export type IdentityDetails = {
  emp_id: number;
  pan_number: string;
  pan_document: string;
  aadhar_number: string;
  aadhar_document: string;
  passport_number: string;
  blood_group: string;
  mental_illness: string;
  criminal_records: string;
  engagement_type_id: number;
  engagement_type: string;
  is_active: boolean;
  crt_time: Date;
  update_time: Date;
  disability_document: string;
  physical_disability: string;
  esic_number: string;
};

export const getIdentityDetails = async (
  emp_id: number
): Promise<{ ok: boolean; message: string; data?: IdentityDetails }> => {
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/get_employee_identity_details",
    { emp_id }
  );
  if (!ok) {
    return { ok, message };
  } else {
    const data = json[0];
    data.crt_time = data.crt_time ? new Date(data.crt_time) : new Date();
    data.update_time = data.update_time
      ? new Date(data.update_time)
      : new Date();
    data.pan_document = data.pan_document === "none" ? "" : data.pan_document;
    data.aadhar_document =
      data.aadhar_document === "none" ? "" : data.aadhar_document;
    return { ok, message, data: data as IdentityDetails };
  }
};

export const saveIdentityDetails = async (
  data: IdentityDetails
): Promise<{ ok: boolean; message: string }> => {
  data.pan_document = !data.pan_document ? "none" : data.pan_document;
  data.aadhar_document = !data.aadhar_document ? "none" : data.aadhar_document;
  data.mental_illness = data.mental_illness ? data.mental_illness : "N/A";

  const { ok, message } = await fetchUtil(
    "POST",
    "/save_employee_identity_details",
    data
  );
  return { ok, message };
};
