import { Select } from "antd";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { fetchUtil } from "~/api/common";
import ReadOnlyableSelect from "~/component/field-input/ReadOnlyableSelect";
import { VendorItem } from "~/api/vendor";
import { LoginRole } from "~/api/auth";
import BasicVendorModal, {
  State as BasicVendorModalState,
} from "~/component/BasicVendorModal";
import FieldInfo from "~/component/FieldInfo";
import FieldWithAction from "~/component/FieldWithAction";
import { useSelector } from "react-redux";
import { AuthState } from "~/app/MainApp/store";

import commonStyles from "~/component/common.module.css";
import { useCustomerList } from "~/lib/hook/api-hook/picklistHooks";

interface Props {
  // Props injected by parent <Form.Item />
  // id?: string;
  value?: VendorItem;
  onChange?: (value?: VendorItem) => void;
  readOnly?: boolean;
  activeRole?: LoginRole;
  sendVendorDetail?: (value?: VendorItem) => void;
  isInvoice?: boolean;
  required?: boolean;
  showGstUnregistered?: boolean;
  isBare?: boolean;
  error?: boolean;
}

const ZERO_PROMISE = Promise.resolve([] as Array<VendorItem> | null);

const handleVendorFilterOption = (input?: string, option?: any): any => {
  const extra = option.extra as VendorItem;
  return (
    input &&
    (extra.trade_name?.toLowerCase().includes(input.toLowerCase()) ||
      (extra.gstin
        ? extra.gstin.toLowerCase().includes(input.toLowerCase())
        : ""))
  );
};

export default function VendorSelect({
  value,
  onChange,
  readOnly,
  sendVendorDetail,
  isInvoice,
  required,
  showGstUnregistered,
  isBare,
  error,
  activeRole,
}: Props): ReactElement {
  const handleClick1 = () => {
    window.open(`/app/masters/vendor/${value?.customer_id}`, "_blank");
    // history.push(`/app/masters/vendor/${value?.customer_id}`);
  };
  const baseCompanyGstin = useSelector(
    (state: AuthState) => state.mainAppSlice.user.gstin
  );

  const lastVendorFetchPromiseRef = useRef(ZERO_PROMISE);
  const {
    /* isCustomerListLoading, */ customerList,
    forceFetch,
  } = useCustomerList();
  // const [vendorList, setVendorList] = useState([] as Array<VendorItem>);
  const [addVendorModal, setAddVendorModal] = useState({
    visible: false,
    __init__: {} as Partial<BasicVendorModalState>,
  });
  const [gstinTypeList, setGstinTypeList] = useState({} as any);

  const loadVendor = useCallback(() => {
    lastVendorFetchPromiseRef.current = forceFetch(true);
    // getVendorList().then(
    //   ({ data: newVendorList }) => {
    //     setVendorList(newVendorList);
    //     return newVendorList;
    //   }
    // );
    return lastVendorFetchPromiseRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showVendorModal = (gstin?: string) => {
    setAddVendorModal({
      visible: true,
      __init__: {
        gstin,
      },
    });
  };

  const hideVendorModal = () => {
    setAddVendorModal({
      visible: false,
      __init__: {},
    });
  };

  const handleAddNewAction = () => {
    showVendorModal();
  };

  const handleVendorAdd = async (customer_idx: string) => {
    const newVendorList = await loadVendor();
    onChange?.(newVendorList?.find((it) => it.customer_idx === customer_idx));
  };

  const handleChange = (customer_idx: string) => {
    const vendor = customerList.find((it) => it.customer_idx === customer_idx);
    onChange?.(vendor);
  };

  const getCustomerGSTStatusText = (vendor?: VendorItem) => {
    const gstin =
      vendor?.gstin ||
      gstinTypeList[
        vendor?.gst_registration_type_id || 3 // 3: GST unregistered
      ] ||
      "GST unregistered";
    const separator = " • ";
    const gstRegType =
      vendor?.gst_registration_type_id && vendor?.gst_registration_type_id > 3
        ? `${separator}${gstinTypeList[vendor?.gst_registration_type_id]}`
        : "";
    const activeStr = !vendor?.active_customer_gstin_no
      ? `${separator}INACTIVE`
      : "";
    const vendorAdd = `${gstin}${separator}${
      vendor?.billing_address_state || "No state"
    }${gstRegType}${activeStr}`;
    return vendorAdd;
  };

  /**
   * **WHEN WE WANT TO FORCE A NEW VENDOR BY NOT USING FULL VENDOR OBJECT!**
   * When we pass anything with a new __init__ with partial vendor information
   * This effect updateds its own vendor or triggers the AddNewVendor modal
   */
  const [valueName, setValueName] = useState(value?.trade_name);

  useEffect(() => {
    setValueName(value?.trade_name);
  }, [value]);
  useEffect(() => {
    const forcedNewVendor = (value as any)?.__init__ as Partial<VendorItem>;

    if (!forcedNewVendor) {
      return;
    }

    if (forcedNewVendor.customer_idx) {
      const customerIdx = forcedNewVendor.customer_idx as string;
      lastVendorFetchPromiseRef.current.then((assuredVendorList) => {
        onChange?.(
          assuredVendorList?.find((it) => it.customer_idx === customerIdx)
        );
      });
    } else if (forcedNewVendor.gstin) {
      const gstin = forcedNewVendor.gstin as string;
      // ignore gstin if it is same as baseCompanyGstin
      if (gstin === baseCompanyGstin) {
        return;
      }

      lastVendorFetchPromiseRef.current.then((assuredVendorList) => {
        const vendor = assuredVendorList?.find((it) => it.gstin === gstin);
        if (vendor) {
          onChange?.(vendor);
          if (value as any) {
            sendVendorDetail!(vendor);
          }
        } else {
          showVendorModal(gstin);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(value as any)?.__init__?.uid]);

  useEffect(() => {
    const getGstinTypeList = async () => {
      const { ok, json } = await fetchUtil(
        "GET",
        `/get_gst_registration_types`
      );

      if (ok) {
        const data = json.reduce((o: any, v: any) => {
          o[v.id] = v.gst_type;
          return o;
        }, {});
        setGstinTypeList(data);
      } else {
        // notification.error({ message });
      }
    };
    getGstinTypeList();
  }, []);

  useEffect(() => {
    loadVendor();
  }, [loadVendor]);

  const selectComponent = (
    <ReadOnlyableSelect
      className={commonStyles["w-100"]}
      value={value?.customer_idx}
      onChange={handleChange}
      readonly={readOnly}
      style={
        isBare && required && error && !value
          ? {
              border: "0.5px solid red",
            }
          : undefined
      }
      showArrow
      showSearch
      filterOption={handleVendorFilterOption}
      id={"customer_id"}
      allowClear={isBare ? true : false}
    >
      {customerList.map((opt) => (
        <Select.Option
          key={opt.customer_idx}
          value={opt.customer_idx}
          extra={opt}
        >
          <div>
            <div>{opt.trade_name}</div>
            <div
              style={{
                fontSize: 10,
                marginTop: -3,
                marginBottom: 5,
                color: "var(--primary-color)",
              }}
            >
              ( {opt.gstin ? `${opt.gstin},` : ""} {opt.billing_address_state},{" "}
              {opt.billing_address_country})
            </div>
          </div>
        </Select.Option>
      ))}
    </ReadOnlyableSelect>
  );

  const fieldInfoComponent = (
    <FieldInfo
      style={
        !isBare
          ? undefined
          : {
              marginTop: "initial",
              position: "absolute",
              bottom: "-4px",
              left: "8px",
            }
      }
      text={
        value?.gstin || showGstUnregistered
          ? getCustomerGSTStatusText(value)
          : value?.customer_idx && !value.gstin
          ? value.billing_address_state
            ? `GST unregistered (${value?.billing_address_state})`
            : null
          : null
      }
      subtle
    />
  );
  const DetailInfoComponent =
    activeRole === LoginRole.ADMIN ||
    activeRole === LoginRole.SUPERuSER ||
    activeRole === LoginRole.CA ? (
      valueName !== undefined ? (
        <label onClick={handleClick1}>
          <FieldInfo
            style={{
              cursor: "pointer",
              color: "var(--grey-2)",
              marginTop: "none",
              position: "absolute",
              bottom: "0px",
              right: "6px",
            }}
            text={"Details"}
            subtle
          />
        </label>
      ) : undefined
    ) : null;

  if (isBare) {
    return (
      <div>
        <div>{selectComponent}</div>
        {fieldInfoComponent}
        {DetailInfoComponent}
      </div>
    );
  }

  return (
    <div>
      <FieldWithAction
        label={isInvoice ? "Customer" : "Vendor"}
        actionLabel="+ Add new"
        onAction={handleAddNewAction}
        readonly={readOnly}
        required={required ? required : false}
      >
        {selectComponent}
      </FieldWithAction>
      {fieldInfoComponent}
      {DetailInfoComponent}
      <BasicVendorModal
        visible={addVendorModal.visible}
        __init__={addVendorModal.__init__}
        onVisibleChange={hideVendorModal}
        onAddVendor={handleVendorAdd} // {this.onAddVendor}
      />
    </div>
  );
}
