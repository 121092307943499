import React, { Component } from "react";
import { Table, notification, Row, Tooltip, Input, Col, Button } from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { RouteChildrenProps } from "react-router";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { fetchUtil } from "~/api/common";
import styles from "~/feature/expenseCategory/ExpenseCategory.module.css";
import { subMonths, getMonth, addYears, getYear, subYears } from "date-fns";
import { PaymentAPIData } from "~/api/payment";
import { months } from "~/lib/constants";
import * as ga from "~/contants/gaConstants";
import { debounce } from "lodash";
import { Helmet } from "react-helmet";
import { registerEvent } from "~/analytics";
import { PickupItem } from "~/fragment/pickup-field/pickupSlice";
import { LoginRole } from "~/api/auth";
import AddCategory from "~/component/AddCategory";
const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  sortOrderInfo: state.mainAppSlice.user.sort_order,
  sorterData: state.mainAppSlice.user.sorter,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  setSortOrder: (sortOrderInfo: any) =>
    dispatch(action.auth.setSortOrder(sortOrderInfo)),
  setSorter: (sorter: any) => dispatch(action.auth.setSorter(sorter)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends RouteChildrenProps, PropsFromRedux {}

interface State {
  categoryPickList: Array<PickupItem>;
  rowData: Array<PaymentAPIData>;
  pagination: {
    pageSize: number;
    pageNo: number;
    pageCount: number;
  };
  apiState: "idle" | "loading" | "error";
  apiSummaryState: "idle" | "loading" | "error";
  monthSelect: number;
  yearSelect: any;
  activePage: number;
  noOfRows: number;
  totalItemCount: number;
  sortedInfo: any;
  filteredInfo: any;
  sortOrder: string;
  search: string;
  highlightText: string;
  addExpenseItem: boolean;
}
class PaymentPage extends Component<Props, {}> {
  state: State = {
    categoryPickList: [],
    rowData: [],
    pagination: {
      pageSize: 10,
      pageNo: 1,
      pageCount: 1,
    },
    apiState: "idle",
    apiSummaryState: "idle",
    yearSelect: "",
    monthSelect:
      new Date().getDate() < 25
        ? getMonth(subMonths(new Date(), 1))
        : getMonth(new Date()),
    activePage: 1,
    noOfRows: 12,
    totalItemCount: 0,
    sortedInfo: {},
    filteredInfo: {},
    sortOrder: "",
    search: "",
    highlightText: "",
    addExpenseItem: false,
  };

  static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State
  ): Partial<State> | null {
    const query = new URLSearchParams(nextProps.location.search);
    let currentPage = 1;
    const today = new Date();
    const isBefore25th = today.getDate() < 25;
    const selectedDate = isBefore25th ? subMonths(today, 1) : today;
    const current = new Date();
    const yearCurrent = new Date().getFullYear();
    const eligible =
      current.getTime() <= new Date(`${yearCurrent}-04-25`).getTime() &&
      current.getTime() > new Date(`${yearCurrent - 1}-04-25`).getTime();
    let year: any = eligible
      ? `${getYear(subYears(current, 1))}-${getYear(current)}`
      : `${getYear(current)}-${getYear(addYears(current, 1))}`;

    let month = getMonth(selectedDate);

    if (query.has("year")) {
      const _yearStr = query.get("year") + "";
      if (/\d{4}/.test(_yearStr)) {
        year = _yearStr;
      }
    }
    if (query.has("month")) {
      const _monthNum = +months.indexOf(query.get("month") + "");

      // console.log(_monthNum, "--------------------");
      if (Number.isFinite(_monthNum)) {
        month = _monthNum;
      }
    }
    if (query.has("page")) {
      const pageNo = +query.get("page")!;
      if (Number.isFinite(pageNo)) {
        currentPage = pageNo;
      }
    }

    let search = "";
    let highlightText = "";
    if (query.has("search_item") && query.get("search_item")) {
      const searchItem = query.get("search_item");
      if (searchItem) {
        search = searchItem;
        highlightText = searchItem;
      }
    }
    const newState: Partial<State> = {
      yearSelect: year,
      monthSelect: month,
      activePage: currentPage,
      sortOrder: query.get("sort_order") || "",
      search,
      highlightText,
    };
    if (
      prevState.yearSelect !== newState.yearSelect ||
      prevState.monthSelect !== newState.monthSelect ||
      prevState.activePage !== newState.activePage ||
      prevState.sortOrder !== newState.sortOrder ||
      prevState.search !== newState.search
    ) {
      return newState;
    }
    return null;
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    query.set("sort_order", "");
    this.props.history.push("?" + query);
    if (this.props.sorterData) {
      this.setState({
        sortedInfo: this.props.sorterData,
      });
    }

    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>) {
    if (
      _prevState.yearSelect !== this.state.yearSelect ||
      _prevState.monthSelect !== this.state.monthSelect
    ) {
      this.loadData();
    }
    if (
      _prevState.addExpenseItem === true &&
      this.state.addExpenseItem === false
    ) {
      this.loadData();
    }
  }

  loadData = async () => {
    this.setState({
      apiState: "loading",
    });
    // /get_expense_category
    const { ok, message, json } = await fetchUtil(
      "POST",
      "/get_expense_category",
      {
        page_no: this.state.activePage,
        no_of_rows: 10,
        search_str: this.state.search,
        sort_param: this.props.sortOrderInfo || "ID_ASC",
      }
    );
    if (!ok) {
      notification.error({ message });
      this.setState({
        apiState: "error",
      });
    } else {
      const data = json as Array<PickupItem>;
      this.setState({
        categoryPickList: data,
        apiState: "idle",
      });
    }
  };

  handlePageChange = (pageNumber: number) => {
    const query = new URLSearchParams(this.props.location.search);
    const prevPage = query.get("page");
    if (prevPage === pageNumber + "") return;
    query.set("page", pageNumber + "");
    this.props.history.push("?" + query);
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.loadData();
      }
    );
  };

  handleTableChange = (_pagination: any, filters: any, sorter: any) => {
    const query = new URLSearchParams(this.props.location.search);
    let sortOrder = "";

    if (sorter.columnKey === "id") {
      if (sorter.order === "descend") {
        sortOrder = "ID_DESC";
        this.props.setSortOrder(sortOrder);
      } else if (sorter.order === "ascend") {
        sortOrder = "ID_ASC";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "category") {
      if (sorter.order === "descend") {
        sortOrder = "ECNAME_DESC";
        this.props.setSortOrder(sortOrder);
      } else if (sorter.order === "ascend") {
        sortOrder = "ECNAME_ASC";
        this.props.setSortOrder(sortOrder);
      }
    }
    if (!sorter.order) {
      sortOrder = "";
      this.props.setSortOrder(sortOrder);
    }

    const prev = query.get("sort_order");
    if (prev === sortOrder) return;

    query.set("sort_order", sortOrder);

    this.props.history.push("?" + query);
    this.props.setSorter(sorter);
    this.setState(
      {
        filteredInfo: filters,
        sortedInfo: sorter ? sorter : this.props.sorterData,
      },
      () => this.loadData()
    );
  };

  handleInstrumentClick = (payment: PaymentAPIData) => {
    if (!payment.instrument_id || payment.instrument_id === "N/A") return;

    if (payment.invoice_id || payment.expense_receipt_id) {
      const baseUrl = window.location.origin;
      if (payment.invoice_id) {
        window.open(`${baseUrl}/app/invoice/${payment.invoice_id}`, "_blank");
      } else {
        window.open(
          `${baseUrl}/app/expense/${payment.expense_receipt_id}`,
          "_blank"
        );
      }
    }
  };

  debouncedGetPaymentList = debounce(this.loadData, 500);
  handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchItem = e.target.value;
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", 1 + "");
    query.set("search_item", searchItem);
    this.props.history.push("?" + query);
    this.setState(
      { search: e.target.value, highlightText: e.target.value },
      () => {
        this.debouncedGetPaymentList();
      }
    );
  };

  highlightText(text: string): JSX.Element {
    const { highlightText } = this.state;
    if (text) {
      const parts = text.split(new RegExp(`(${highlightText})`, "gi"));
      return (
        <>
          {parts.map((part: any, index: any) =>
            part.toLowerCase() === highlightText.toLowerCase() ? (
              <span key={index} className={styles["highlighted-text"]}>
                {part}
              </span>
            ) : (
              <span key={index}>{part}</span>
            )
          )}
        </>
      );
    } else {
      return <></>;
    }
  }
  handleCategoryrModal = (visible: boolean, catagory_id?: string) => {
    this.setState({ addExpenseItem: visible });
  };

  render() {
    let { sortedInfo } = this.state;
    const { categoryPickList } = this.state;
    const totalRows = categoryPickList[0]?.total_rows;
    sortedInfo = sortedInfo || {};

    const columnDef: Array<ColumnType<PickupItem>> = [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        align: "left",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "id" && sortedInfo.order,
        defaultSortOrder: "descend",
        width: "20%",
        render: (id) => {
          return (
            <span>
              <Tooltip title={id}>
                <p className={styles.tableDataEllipsis}>{id}</p>
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        align: "left",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "category" && sortedInfo.order,
        ellipsis: true,
        render: (label, record) => {
          return (
            <span>
              {label && (
                <p className={styles.tableDataEllipsis}>
                  {this.highlightText(label)}
                </p>
              )}
            </span>
          );
        },
      },
      {
        title: "Category type",
        dataIndex: "category_type",
        key: "category_type",
        align: "left",
        ellipsis: true,
        render: (category_type, record) => {
          return (
            <span>
              <p className={styles.tableDataEllipsis}>{category_type}</p>
            </span>
          );
        },
      },
    ];

    return (
      <IMTContent withoutMargin={true}>
        <Helmet>
          <title>Cheqd - Expense category</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={["Expense category"]}
          style={{ paddingLeft: "0" }}
          actions={
            <>
              {this.props.activeRole === 1 ||
              this.props.activeRole === LoginRole.SUPERuSER ? (
                <>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.setState({ addExpenseItem: true });
                    }}
                  >
                    + Add new
                  </Button>
                </>
              ) : (
                ""
              )}
            </>
          }
        />

        <Row>
          <Col>
            <Input
              placeholder="Search in Id / Category "
              onChange={this.handleInputChange}
              value={this.state.search}
              style={{
                minWidth: "300px",
                height: "32px",
                marginBottom: "5px",
                marginRight: "20px",
              }}
              allowClear
            />
          </Col>
        </Row>
        <Row style={{ width: "750px" }}>
          <Table
            rowKey="id"
            className={styles.paymentTable}
            columns={columnDef}
            // dataSource={this.state.categoryPickList}
            dataSource={this.state.categoryPickList}
            loading={
              this.state.apiState === "loading" ||
              this.state.apiSummaryState === "loading"
            }
            onChange={this.handleTableChange}
            size="large"
            pagination={{
              size: "small",
              current: this.state.activePage,
              pageSize: 10,
              showSizeChanger: false,
              onChange: this.handlePageChange,
              total: totalRows,
              position: ["topRight"],
            }}
          />
        </Row>
        <AddCategory
          visible={this.state.addExpenseItem}
          onVisibleChange={this.handleCategoryrModal}
          selectedCatgoryDetails={null}
        />
      </IMTContent>
    );
  }
}

export default connector(PaymentPage);
