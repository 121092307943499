import { parse, isValid } from "date-fns";
import { baseURL } from "~/api/common";

const userData = localStorage.getItem("imt__user");
const userParseData = JSON.parse(userData!);

const __API_FIELDS = [
  "GST",
  "CGST",
  "SGST",
  "IGST",
  "INTEGRATED_GST",
  "TAXES",
  "AMOUNT",
  "TOTAL_AMOUNT",
  "GSTIN",
  "INVOICE_NUMBER",
  "INVOICE_DATE",
  "AMOUNT_DUE",
  "BALANCE_DUE",
];

type OCRRawResponse = {
  GST: Array<number | string>;
  CGST: Array<number | string>;
  SGST: Array<number | string>;
  IGST: Array<number | string>;
  INTEGRATED_GST: Array<number | string>;
  TAXES: Array<number | string>;
  TOTAL_AMOUNT: Array<number | string>;
  GSTIN: Array<number | string>;
  HSN: Array<number | string>;
  INVOICE_NUMBER: Array<number | string>;
  INVOICE_DATE: Array<number | string>;
  AMOUNT_DUE: Array<number | string>;
  BALANCE_DUE: Array<number | string>;
  aws_image_link: string;
  ITEM: Array<number | string>;
  AMOUNT: Array<number | string>;
  QTY: Array<number | string>;
  TAX_PER: string;
  detail?: string;
  CATEGORY?: string;
  CREDIT_DEBIT_NUMBER_QR?: any;
};

type OCRParseResponse = {
  invoiceDate: Date | undefined;
  cgst: number;
  sgst: number;
  igst: number;
  gstin: string;
  hsn: string;
  totalAmount: number;
  invoiceNo: string;
  item: number;
  amount: number;
  qty: number;
  taxRate: number;
  category: string;
  creditDebitNo?: any;
};

export type OCRResponse = {
  raw: OCRRawResponse;
  parsed: OCRParseResponse;
  success: boolean;
};

export async function doOCRAPI(
  file: File,
  signal?: AbortSignal
): Promise<OCRResponse> {
  const formData = new FormData();
  formData.append("file_name", file);
  const response = await fetch(baseURL + "/ocr_endpoint/ocr_v2", {
    method: "POST",
    signal,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("imt__token"),
    },
    body: formData,
    mode: "cors",
  });
  const ok = response.ok;
  const json = await response.json();
  // Normalize the fields
  for (const key of __API_FIELDS) {
    if (!Array.isArray(json[key])) {
      json[key] = [];
    }
  }
  const rawResponse = json as OCRRawResponse;
  const parsedData = {
    invoiceDate: new Date(),
    cgst: 0,
    sgst: 0,
    igst: 0,
    totalAmount: 0,
    amount: 0,
    hsn: "",
    gstin: "",
    invoiceNo: "",
    taxRate: rawResponse.TAX_PER.length > 0 ? +rawResponse.TAX_PER[0] : 18,
    category: "",
    creditDebitNo: "",
  } as OCRParseResponse;
  if (rawResponse.INVOICE_DATE.length > 0) {
    const now = new Date();
    let invoiceDate = now;
    invoiceDate = parse(
      rawResponse.INVOICE_DATE[0] + " 12:00:00",
      "yyyy-MM-dd HH:mm:ss",
      now
    ); // time value bypass
    parsedData.invoiceDate = isValid(invoiceDate) ? invoiceDate : now;
  } else {
    parsedData.invoiceDate = undefined;
  }
  if (rawResponse.CGST.length > 0) {
    const value = Number(rawResponse.CGST[0]);
    parsedData.cgst = Number.isFinite(value) ? value : 0;
  }
  if (rawResponse.SGST.length > 0) {
    const value = Number(rawResponse.SGST[0]);
    parsedData.sgst = Number.isFinite(value) ? value : 0;
  }
  if (rawResponse.IGST.length > 0) {
    const value = Number(rawResponse.IGST[0]);
    parsedData.igst = Number.isFinite(value) ? value : 0;
  }

  if (rawResponse.AMOUNT.length > 0) {
    const value = Number(rawResponse.AMOUNT[0]);
    parsedData.amount = Number.isFinite(value) ? value : 0;
  }

  if (rawResponse.TOTAL_AMOUNT.length > 0) {
    const value = Number(rawResponse.TOTAL_AMOUNT[0]);
    parsedData.totalAmount = Number.isFinite(value) ? value : 0;
  }

  if (rawResponse.GSTIN.length > 0) {
    const gstinNo = rawResponse.GSTIN.find((el) => {
      return userParseData.gstin !== el;
    });
    if (gstinNo) {
      parsedData.gstin = String(gstinNo);
    } else {
      parsedData.gstin = "";
    }
  }

  if (rawResponse.HSN && rawResponse.HSN.length > 0) {
    const value = (rawResponse.HSN[0] + "").trim();
    parsedData.hsn = value;
  }
  if (rawResponse.INVOICE_NUMBER.length > 0) {
    const value = (rawResponse.INVOICE_NUMBER[0] + "").trim();
    parsedData.invoiceNo = value;
  }
  if (rawResponse.CATEGORY!?.length > 0) {
    const value = rawResponse.CATEGORY;
    // console.log(value);
    parsedData.category = value!;
  }
  if (rawResponse.CREDIT_DEBIT_NUMBER_QR?.length > 0) {
    const value = (rawResponse.CREDIT_DEBIT_NUMBER_QR[0] + "").trim();
    // console.log(value);
    parsedData.creditDebitNo = value!;
  }

  return {
    raw: rawResponse,
    parsed: parsedData,
    success: ok,
  };
}

export async function doOCRLineItem(file: File): Promise<any> {
  const formData = new FormData();
  formData.append("file_name", file);
  const response = await fetch(baseURL + "/ocr_endpoint/line_items", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("imt__token"),
    },
    body: formData,
    mode: "cors",
  });

  const json = await response.json();
  return json;
}
