import React, { useCallback, useEffect, useState } from "react";
import { Modal, Button, Tabs, notification, Skeleton } from "antd";
import TALLY_ICON from "~/assets/Tally-Erp-9-with-GST-Logo.png";
import styles from "./Iconstyle.module.css";
import { submitTallyBackward, getTallyBackwardData } from "~/api/synchronize";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";
import {
  Party,
  PurchaseLedger,
  PurchaseVoucher,
  SalesTaxable,
  SalesVoucher,
  StockGroup,
  StockItem,
  Units,
  TaxLedger,
  Consumer,
} from "./tally-syncback-selector/tally-dirty-entity-type";
import PartySelect from "./tally-syncback-selector/PartySelect";
import PurchaseLedgerSelect from "./tally-syncback-selector/PurchaseLedgerSelect";
import SalesLedgerSelect from "./tally-syncback-selector/SalesLedgerSelect";
import StockItemSelect from "./tally-syncback-selector/StockItemSelect";
import StockGroupSelect from "./tally-syncback-selector/StockGroupSelect";
import UnitSelect from "./tally-syncback-selector/UnitSelect";
import usePersistantCallbackRef from "~/lib/hook/usePersistantCallbackRef";
import SalesVoucherSelect from "./tally-syncback-selector/SalesVoucherSelect";
import PurchaseVoucherSelect from "./tally-syncback-selector/PurchaseVoucherSelect";
import TaxLedgerSelect from "./tally-syncback-selector/TaxLedger";
import ConsumerSelect from "./tally-syncback-selector/ConsumerSelect";

const { TabPane } = Tabs;

interface Props {
  isOpen: boolean;
  onOk: () => void;
  onClose: () => void;
  onCloseNew: () => void;
  viewSyncModal?: (title: string, logo: string, style: string) => void;
}

const SyncBackwardTallyModal = (props: Props) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const [partyList, setPartyList] = useState<Array<Party>>([]);
  const [selectedPartyIdList, setSelectedPartyIdList] = useState<
    Array<string | number>
  >([]);
  const [consumerList, setConsumerList] = useState<Array<Consumer>>([]);
  const [selectedConsumerIdList, setSelectedConsumerIdList] = useState<
    Array<number>
  >([]);

  const [stockGroupList, setStockGroupList] = useState<Array<StockGroup>>([]);
  const [selectedStockGroupIdList, setSelectedStockGroupIdList] = useState<
    Array<number>
  >([]);
  const [unitsList, setUnitList] = useState<Array<Units>>([]);
  const [selectedUnitsIdList, setSelectedUnitsIdList] = useState<Array<number>>(
    []
  );

  const [stockItemList, setStockItemList] = useState<Array<StockItem>>([]);
  const [selectedStockItemIdList, setSelectedStockItemIdList] = useState<
    Array<number>
  >([]);
  const [taxLedgerList, setTaxLedgerList] = useState<Array<TaxLedger>>([]);
  const [selectedTaxLedgerIdList, setSelectedTaxLedgerIdList] = useState<
    Array<number>
  >([]);

  const [salesLedgerList, setSalesLedgerList] = useState<Array<SalesTaxable>>(
    []
  );
  const [selectedSalesLedgerIdList, setSelectedSalesLedgerIdList] = useState<
    Array<number>
  >([]);

  const [purchaseLedgerList, setPurchaseLedgerList] = useState<
    Array<PurchaseLedger>
  >([]);
  const [
    selectedPurchaseLedgerIdList,
    setSelectedPurchaseLedgerIdList,
  ] = useState<Array<number>>([]);

  const [salesVoucherList, setSalesVoucherList] = useState<Array<SalesVoucher>>(
    []
  );
  const [selectedSalesVoucherIdList, setSelectedSalesVoucherIdList] = useState<
    Array<number>
  >([]);

  const [purchaseVoucherList, setPurchaseVoucherList] = useState<
    Array<PurchaseVoucher>
  >([]);
  const [
    selectedPurchaseVoucherIdList,
    setSelectedPurchaseVoucherIdList,
  ] = useState<Array<number>>([]);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);

      const res = await getTallyBackwardData();
      if (res.ok) {
        const data = res.data[0];
        setPartyList(data.party ?? []);
        setConsumerList(data.consumer ?? []);
        setStockGroupList(data.product_category ?? []);
        setTaxLedgerList(data.tax_ledger ?? []);
        setUnitList(data.units ?? []);
        setStockItemList(data.stock_items ?? []);
        setSalesLedgerList(data.sales_taxable ?? []);
        setPurchaseLedgerList(data.purchase_ledger ?? []);
        setSalesVoucherList(data.sales_voucher ?? []);
        setPurchaseVoucherList(data.purchase_voucher ?? []);
      }
    } catch (err) {
      console.error(err);
      notification.warn({
        message: `Failed to load data, please try again after sometime.`,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleStartBackwardTally = usePersistantCallbackRef(async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.syncBackwardTallyModal242
    );

    try {
      const res = await submitTallyBackward({
        customer: selectedPartyIdList.map((id) => {
          const party = partyList.find((it) => {
            // id = "it.gstin-it-customer_id"
            const myArray = String(id).split("-");
            return myArray[0] === "n"
              ? it.customer_id === Number(myArray[1])
              : it.customer_gstin_id === Number(myArray[0]);
          })!;
          return {
            customer_id: party.customer_id,
            gstin: party.gstin,
          };
        }),
        product: [...selectedStockItemIdList, ...selectedSalesLedgerIdList],
        product_category: selectedStockGroupIdList,
        units: selectedUnitsIdList,
        tax_ledger: selectedTaxLedgerIdList,
        consumer: selectedConsumerIdList,
        expense_category: selectedPurchaseLedgerIdList,
        sales_voucher: selectedSalesVoucherIdList,
        purchase_voucher: selectedPurchaseVoucherIdList,
      });

      if (res.ok) {
        notification.success({ message: res.message });
        if (props.viewSyncModal) {
          props.viewSyncModal(
            "Sync back in progress ...",
            TALLY_ICON,
            styles.talyIcon
          );
        }
        props.onOk();
      } else {
        notification.warn({
          message: "Unable to start sync back",
          description: res.message?.replace("Error: ", ""),
        });
      }
    } catch (err) {
      const error = err as Error;
      console.error(error);
      notification.warn({
        message: `An error occured, please try after sometime.`,
      });
    }
  });
  const closeNewFun = () => {
    props.onClose();
    props.onCloseNew();
  };

  return (
    <Modal
      visible={props.isOpen}
      maskClosable={false}
      title="Sync back items"
      width={1400}
      onCancel={closeNewFun}
      footer={[
        <Button key="cancel" onClick={closeNewFun}>
          Cancel
        </Button>,
        <Button
          key="start"
          type="primary"
          onClick={handleStartBackwardTally}
          disabled={
            selectedPartyIdList.length === 0 &&
            selectedStockGroupIdList.length === 0 &&
            selectedUnitsIdList.length === 0 &&
            selectedStockItemIdList.length === 0 &&
            selectedTaxLedgerIdList.length === 0 &&
            selectedConsumerIdList.length === 0 &&
            selectedSalesLedgerIdList.length === 0 &&
            selectedPurchaseLedgerIdList.length === 0 &&
            selectedSalesVoucherIdList.length === 0 &&
            selectedPurchaseVoucherIdList.length === 0
          }
        >
          Start
        </Button>,
      ]}
    >
      <Skeleton loading={isLoading}>
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={`Party (${selectedPartyIdList.length}/${partyList.length})`}
            key="3"
          >
            <PartySelect
              partyList={partyList}
              selectedIds={selectedPartyIdList}
              onSelectionChange={setSelectedPartyIdList}
            />
          </TabPane>

          <TabPane
            tab={`Stock group (${selectedStockGroupIdList.length}/${stockGroupList.length})`}
            key="4"
          >
            <StockGroupSelect
              stockGroupList={stockGroupList}
              selectedIds={selectedStockGroupIdList}
              onSelectionChange={setSelectedStockGroupIdList}
            />
          </TabPane>

          <TabPane
            tab={`Units (${selectedUnitsIdList.length}/${unitsList.length})`}
            key="10"
          >
            <UnitSelect
              unitsList={unitsList}
              selectedIds={selectedUnitsIdList}
              onSelectionChange={setSelectedUnitsIdList}
            />
          </TabPane>

          <TabPane
            tab={`Tax Ledgers (${selectedTaxLedgerIdList.length}/${taxLedgerList.length})`}
            key="11"
          >
            <TaxLedgerSelect
              taxLedgerList={taxLedgerList}
              selectedIds={selectedTaxLedgerIdList}
              onSelectionChange={setSelectedTaxLedgerIdList}
            />
          </TabPane>
          {/* <TabPane
            tab={`Consumer (${selectedConsumerIdList.length}/${consumerList.length})`}
            key="12"
          >
            <ConsumerSelect
              consumerList={consumerList}
              selectedIds={selectedConsumerIdList}
              onSelectionChange={setSelectedConsumerIdList}
            />
          </TabPane> */}

          <TabPane
            tab={`Stock item (${selectedStockItemIdList.length}/${stockItemList.length})`}
            key="5"
          >
            <StockItemSelect
              stockItemList={stockItemList}
              selectedIds={selectedStockItemIdList}
              onSelectionChange={setSelectedStockItemIdList}
            />
          </TabPane>

          <TabPane
            tab={`Sales ledger (${selectedSalesLedgerIdList.length}/${salesLedgerList.length})`}
            key="6"
          >
            <SalesLedgerSelect
              salesLedgerList={salesLedgerList}
              selectedIds={selectedSalesLedgerIdList}
              onSelectionChange={setSelectedSalesLedgerIdList}
            />
          </TabPane>

          <TabPane
            tab={`Purchase ledger (${selectedPurchaseLedgerIdList.length}/${purchaseLedgerList.length})`}
            key="7"
          >
            <PurchaseLedgerSelect
              purchaseLedgerList={purchaseLedgerList}
              selectedIds={selectedPurchaseLedgerIdList}
              onSelectionChange={setSelectedPurchaseLedgerIdList}
            />
          </TabPane>

          <TabPane
            tab={`Sales voucher (${selectedSalesVoucherIdList.length}/${salesVoucherList.length})`}
            key="8"
          >
            <SalesVoucherSelect
              salesVoucherList={salesVoucherList}
              selectedIds={selectedSalesVoucherIdList}
              onSelectionChange={setSelectedSalesVoucherIdList}
            />
          </TabPane>

          <TabPane
            tab={`Purchase voucher (${selectedPurchaseVoucherIdList.length}/${purchaseVoucherList.length})`}
            key="9"
          >
            <PurchaseVoucherSelect
              purchaseVoucherList={purchaseVoucherList}
              selectedIds={selectedPurchaseVoucherIdList}
              onSelectionChange={setSelectedPurchaseVoucherIdList}
            />
          </TabPane>
        </Tabs>
      </Skeleton>
    </Modal>
  );
};

export default SyncBackwardTallyModal;
