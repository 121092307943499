import React from "react";
import ArrayViewer from "./arrayviewer";
import ObjectViewer from "./objectviewer";
import styles from "~/component/JsonDownloadPreview.module.css";
type Props = {
  JSONToView: any;
  currentLayer: string;
  previousLayers: string;
  setDataOfALayer: any;
  editable?: boolean;
};
function DataToShow({
  JSONToView,
  currentLayer,
  previousLayers,
  setDataOfALayer,
}: Props) {
  if (Array.isArray(JSONToView) && JSONToView.length > 0) {
    return (
      <ArrayViewer
        arrayToView={JSONToView}
        currentLayer={currentLayer}
        previousLayers={previousLayers}
        setDataOfALayer={setDataOfALayer}
      />
    );
  }
  if (Array.isArray(JSONToView) && JSONToView.length === 0) {
    return (
      <tbody className={styles.tbody1}>
        <tr className={styles.trow1}>
          <td className={styles.trowD1} />
        </tr>
      </tbody>
    );
  }
  if (!Array.isArray(JSONToView) && typeof JSONToView === "object") {
    return (
      <ObjectViewer
        objectToView={JSONToView}
        currentLayer={currentLayer}
        previousLayers={previousLayers}
        setDataOfALayer={setDataOfALayer}
      />
    );
  } else {
    return <></>;
  }
}

export default DataToShow;
