import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
// import { getSyncStatus } from "~/api/synchronize";
// import socketIo from "socket.io-client";
// import { baseURL } from "~/api/common";

export interface ModalState {
  firstTimeLoginModal: {
    isVisible: boolean;
  };
  sourceSelectionModal: {
    isVisible: boolean;
  };
  startSyncFromSourceModal: {
    isVisible: boolean;
  };
  syncStatusModal: {
    isVisible: boolean;
  };
  tallyBackSyncSelectModal: {
    isVisible: boolean;
  };
  tallyBackSyncSelectModalNew: {
    isVisible: boolean;
  };
  isSyncing: boolean;
  syncInterval: ReturnType<typeof setInterval>;
  sourceName: string;
  syncStatusDetail: any;
  syncProcessStatus: string;
  sumOfError: number;
  sumOfTotal: number;
  sumTotalLoad: number;
  processId: any;
  elapsedTime: any;
}

const initialState = {
  firstTimeLoginModal: {
    isVisible: false,
  },
  sourceSelectionModal: {
    isVisible: false,
  },
  startSyncFromSourceModal: {
    // This will be true once a source is selected
    isVisible: false,
  },
  syncStatusModal: {
    isVisible: false,
  },
  tallyBackSyncSelectModal: {
    isVisible: false,
  },
  tallyBackSyncSelectModalNew: {
    isVisible: false,
  },
  isSyncing: false,
  syncInterval: 0,
  sourceName: null,
  syncStatusDetail: null,
  syncProcessStatus: "",
  sumOfError: 0,
  sumOfTotal: 0,
  sumTotalLoad: 0,
  processId: null,
  elapsedTime: "",
  updateSyncData: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  /* 
    @note : Avoiding the use of {switch()} just to improve code readability 
  */
  reducers: {
    hideFirstTimeLoginModal: (state) => {
      state.firstTimeLoginModal.isVisible = false;
    },
    hideSourceSelectionModal: (state) => {
      state.sourceSelectionModal.isVisible = false;
      // state.updateSyncData = true;
    },
    hideStartSyncFromSourceModal: (state) => {
      state.startSyncFromSourceModal.isVisible = false;
      // state.updateSyncData = true;
    },
    hideSyncStatusModal: (state) => {
      state.syncStatusModal.isVisible = false;
      state.updateSyncData = true;
    },
    showFirstTimeLoginModal: (state) => {
      state.firstTimeLoginModal.isVisible = true;
    },
    showSourceSelectionModal: (state) => {
      state.sourceSelectionModal.isVisible = true;
      state.updateSyncData = false;
    },
    showStartSyncFromSourceModal: (state) => {
      state.startSyncFromSourceModal.isVisible = true;
      state.updateSyncData = false;
    },
    showSyncStatusModal: (state) => {
      state.syncStatusModal.isVisible = true;
      state.updateSyncData = false;
    },
    setTallyBackSyncSelectModal: (
      state,
      action: PayloadAction<{ isVisible: boolean }>
    ) => {
      state.tallyBackSyncSelectModal.isVisible = action.payload.isVisible;
    },
    setTallyBackSyncSelectModalNew: (
      state,
      action: PayloadAction<{ isVisible: boolean }>
    ) => {
      state.tallyBackSyncSelectModalNew.isVisible = action.payload.isVisible;
    },
    setSocketData: (state, action: PayloadAction<any>) => {
      // console.log(action.payload);

      const data = action.payload;
      // console.log(data.isArray())
      if (Array.isArray(data)) {
        if (data.length > 0) {
          state.processId = data[0]?.processId;
          state.sourceName = data[0]?.sourceName;
          state.syncStatusDetail = data[0]?.process_steps;
          state.syncProcessStatus = data[0]?.processState;

          state.isSyncing = data[0]?.processState === "RUNNING" ? true : false;
          state.sumOfError = data[0]?.sumOfError;
          state.sumOfTotal = data[0]?.sumOfTotal;
          state.sumTotalLoad = data[0]?.sumTotalLoad;
          state.elapsedTime = data[0]?.elapsedTime;
        }
      } else {
        state.sourceName = data?.source;
        state.syncProcessStatus = data?.processState;
        state.processId = data?.processId;
        state.isSyncing = data?.processState === "RUNNING" ? true : false;
        if (data?.processState === "SUCCESS") {
          notification.success({
            message: "Sync process completed successfully",
          });
        } else if (data?.processState === "ERROR") {
          notification.info({ message: "Some error occured" });
        }
      }
    },

    // Old process (before websocket), part of logic for polling get_sync_status API
    setSyncStatus: (state, action: PayloadAction<any>) => {
      // const data = action.payload;
      // if (data.length > 0) {
      //   state.sourceName = data[0]?.source_name;
      //   state.syncStatusDetail = data[0]?.process_steps;
      //   state.syncProcessStatus = data[0]?.process_status.is_syncing;
      //   state.isSyncing = data[0]?.process_status.is_syncing;
      //   if (!data[0]?.process_status.is_syncing) {
      //     notification.success({
      //       message: "Sync process completed successfully",
      //     });
      //     clearInterval(state.syncInterval);
      //     state.syncInterval = 0;
      //   }
      // } else {
      //   notification.success({
      //     message: "Sync process completed successfully",
      //   });
      //   clearInterval(state.syncInterval);
      //   state.syncInterval = 0;
      // }
    },
    startInterval: (state, action: PayloadAction<any>) => {
      state.syncInterval = action.payload;
    },
  },

  // extraReducers(builder) {
  //   builder.addCase(checkStatusAndShowModal.fulfilled, (state, action) => {
  //     if (action.payload?.ok) {
  //       const data = action.payload.json;
  //       // console.log(data)
  //       if (data.length > 0) {
  //         state.isSyncing = data[0]?.process_status.is_syncing;
  //       }
  //       // If sycing then trigerring status modal otherwise displaying sourceSelectionModal
  //       if (state.isSyncing) {
  //         state.sourceName = data[0]?.source_name;
  //         state.syncStatusDetail = data[0]?.process_steps;
  //         state.syncProcessStatus = data[0]?.process_status.is_syncing;
  //         state.sourceSelectionModal.isVisible = false;
  //         state.syncStatusModal.isVisible = true;
  //       } else {
  //         state.sourceSelectionModal.isVisible = true;
  //         state.syncStatusModal.isVisible = false;
  //       }
  //     }
  //   });
  // },
});
