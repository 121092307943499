import React from "react";
import { Spin } from "antd";

const FullpageSpinner = () => (
  <div
    style={{
      minWidth: "100vw",
      minHeight: "90vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spin />
  </div>
);

export default FullpageSpinner;
