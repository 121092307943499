/**
 * Renderers for  LINE ITEMS
 */

import React from "react";
import { Input } from "antd";
import LICDescription from "./LICDescription";
import LICLeftButton from "./LICLeftButton";
import LIFooter from "./LIFooter";
import { InputProps } from "antd/lib/input";
import { formatNumber, noop, parseInputNumber } from "~/lib";
import GSTRateSelect from "~/component/field-input/GSTRateSelect";
import ProductSelect from "~/component/field-input/ProductSelect";
import CESSRateSelect from "~/component/field-input/CESSRateSelect";
import LICRightButton from "./LICRightButton";

import classes from "./LineItem.module.css";
import { LineItemRecord } from "./LineItemTypes";
import { CESSRateItem, GSTRate } from "~/api/expense";
import { ProductItem } from "~/api/invoice";
import UnitSelect from "~/component/field-input/UnitSelect";
import DatePicker from "~/component/antd-overrides/DatePicker";
import SelectCategory from "../form-input/CategorySelect";
import { VendorItem } from "~/api/vendor";
import VendorSelect from "../form-input/VendorSelect";
import PlaceOfSupply from "../form-input/PlaceOfSupply";
// import { getProductList } from "~/api/vendor";

/////////////////////////////////
//////// COLUMN RENDERERS ///////
/////////////////////////////////

export const litAddNewButton = ({
  lastRowIndex,
  readOnly,
  onNewRow,
}: {
  lastRowIndex: number;
  readOnly?: boolean;
  onNewRow: () => void;
}) => {
  return (_0: any, row: any, index: number) => {
    return (
      <div>
        <LICLeftButton
          isLastRow={index === lastRowIndex}
          readOnly={readOnly}
          onAddNew={onNewRow}
          errors={row.extra?.errors}
        />
      </div>
    );
  };
};

export const litDeleteNewButton = ({
  lastRowIndex,
  readOnly,
  onDeleteRow,
}: {
  lastRowIndex: number;
  readOnly?: boolean;
  onDeleteRow: (index: number) => void;
}) => {
  return (_0: any, _1: any, index: number) => {
    const handleClick = () => {
      onDeleteRow(index);
    };
    return (
      <LICRightButton
        isLastRow={index === lastRowIndex}
        onClick={handleClick}
        readOnly={readOnly}
      />
    );
  };
};

export const litTextArea = ({
  fieldName,
  readOnly,
  onChange,
}: {
  fieldName: string;
  readOnly?: boolean;
  onChange?: (fieldName: string, index: number, text: string) => void;
}) => {
  return (value: string, _: any, index: number) => {
    const handleChange = (val: string) => {
      onChange?.(fieldName, index, val);
    };
    return (
      <LICDescription
        value={value}
        readOnly={readOnly}
        onChange={handleChange}
        rowData={_}
      />
    );
  };
};

export const litDatePicker = ({
  fieldName,
  readOnly,
  disabledDate,
  onChange,
}: {
  fieldName: string;
  readOnly?: boolean;
  disabledDate?: (date: Date) => boolean;
  onChange?: (fieldName: string, index: number, date: Date | null) => void;
}) => {
  return (value: Date, row: any, index: number) => {
    const handleChange = (val: Date | null) => {
      onChange?.(fieldName, index, val);
    };
    return (
      <DatePicker
        value={value}
        inputReadOnly={readOnly}
        onChange={handleChange}
        disabledDate={disabledDate}
        style={
          row.extra?.errors?.length > 0 && !value
            ? {
                border: "0.5px solid red",
              }
            : undefined
        }
      />
    );
  };
};

export const litInput = ({
  fieldName,
  readOnly,
  inputType,
  onChange,
}: {
  fieldName: string;
  readOnly?: boolean;
  inputType?: InputProps["type"];
  onChange?: (fieldName: string, index: number, text: string) => void;
}) => {
  return (value: string, row: any, index: number) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(fieldName, index, e.target.value);
    };
    return (
      <Input
        type={inputType}
        value={value}
        readOnly={readOnly}
        onChange={handleChange}
        style={
          row.extra?.errors?.length > 0 && !value && fieldName !== "hsnCode"
            ? {
                border: "0.5px solid red",
              }
            : undefined
        }
      />
    );
  };
};

export const litCurrencyInput = ({
  fieldName,
  readOnly,
  disabled,
  onChange,
}: {
  fieldName: string;
  readOnly?: boolean;
  disabled?:
    | boolean
    | ((value: string | number, row: any, index: number) => boolean);
  onChange?: (fieldName: string, index: number, text: string | number) => void;
}) => {
  return (value: string | number, row: any, index: number) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(fieldName, index, parseInputNumber(e.target.value, value));
    };

    return (
      <Input
        className={
          fieldName !== "quantity" &&
          fieldName !== "igst" &&
          fieldName !== "cgst" &&
          fieldName !== "sgst" &&
          !fieldName.endsWith("x")
            ? classes.currencyEditor
            : classes.currencyEditorWithBorder
        }
        value={
          value !== undefined && readOnly
            ? fieldName === "cgst"
              ? formatNumber((+value * 2) as number)
              : value
              ? formatNumber((value as number) ?? 0)
              : fieldName.endsWith("x")
              ? value
              : undefined
            : fieldName === "cgst"
            ? +value * 2
            : value
        }
        disabled={
          fieldName === "quantity"
            ? row.product_service_type === "SERVICES"
            : typeof disabled === "function"
            ? disabled(value, row, index)
            : disabled
        }
        // disabled={
        //   fieldName === "quantity"
        //     ? !row.product_service_type
        //       ? disabled
        //       : row.product_service_type === "SERVICES"
        //     : disabled
        // }
        style={
          ((row.product_service_type !== "SERVICES" &&
            fieldName === "quantity") ||
            fieldName === "rate") &&
          row.extra?.errors?.length > 0 &&
          !value
            ? { border: "0.5px solid red" }
            : undefined
        }
        readOnly={readOnly}
        bordered={true}
        onChange={handleChange}
      />
    );
  };
};

export const litPercentageInput = ({
  fieldName,
  readOnly,
  onChange,
}: {
  fieldName: string;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: (fieldName: string, index: number, text: string | number) => void;
}) => {
  return (value: string | number, _: any, index: number) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let parsedNumber = parseInputNumber(e.target.value, value);
      if (typeof parsedNumber === "number") {
        if (parsedNumber > 100) {
          parsedNumber = 100;
        }
        if (parsedNumber < 0) {
          parsedNumber = 0;
        }
      }
      onChange?.(fieldName, index, parsedNumber);
    };
    return (
      <Input
        value={value}
        readOnly={readOnly}
        bordered={true}
        onChange={handleChange}
      />
    );
  };
};

export const litCurrencyDisplay = ({
  fieldName,
  disabled,
}: {
  fieldName: string;
  disabled?: boolean;
}) => {
  return (value: number, _: any, index: number) => {
    return (
      <Input
        className={classes.currencyEditor}
        value={formatNumber(value)}
        disabled={disabled}
        readOnly={true}
        bordered={false}
        onChange={noop}
      />
    );
  };
};

export const litGSTRateInput = ({
  fieldName: propertyPath,
  readOnly,
  disabled,
  onChange,
}: {
  fieldName: string;
  readOnly?: boolean;
  disabled?:
    | boolean
    | ((value: string | number, row: any, index: number) => boolean);
  onChange?: (
    fieldName: string,
    index: number,
    rateId: number,
    record?: GSTRate
  ) => void;
}) => {
  return (gstRateId: number, row: any, index: number) => {
    const handleChange = (rateId: number, record?: GSTRate) => {
      onChange?.(propertyPath, index, rateId, record);
    };
    return (
      <GSTRateSelect
        readOnly={readOnly}
        disabled={
          typeof disabled === "function"
            ? disabled(gstRateId, row, index)
            : disabled
        }
        value={gstRateId}
        onChange={handleChange}
      />
    );
  };
};

export const litCESSRateInput = ({
  fieldName,
  readOnly,
  onChange,
}: {
  fieldName: string;
  readOnly?: boolean;
  onChange?: (
    fieldName: string,
    index: number,
    rateId: number,
    record?: CESSRateItem
  ) => void;
}) => {
  return (cessRateId: number, _: any, index: number) => {
    const handleChange = (rateId: number, record?: CESSRateItem) => {
      onChange?.(fieldName, index, rateId, record);
    };
    return (
      <CESSRateSelect
        readOnly={readOnly}
        value={cessRateId}
        onChange={handleChange}
      />
    );
  };
};

export const litUnitInput = ({
  fieldName,
  readOnly,
  onChange,
}: {
  fieldName: string;
  readOnly?: boolean;
  onChange?: (
    fieldName: string,
    index: number,
    unitId: number,
    record?: CESSRateItem
  ) => void;
}) => {
  return (unitId: number, _: any, index: number) => {
    const handleChange = (unitId: number, record?: CESSRateItem) => {
      onChange?.(fieldName, index, unitId, record);
    };
    return (
      <UnitSelect
        readOnly={readOnly}
        value={unitId}
        disabled={_.product_service_type === "SERVICES"}
        // disabled={
        //   !_.product_service_type
        //     ? false
        //     : _.product_service_type === "SERVICES"
        // }
        onChange={handleChange}
      />
    );
  };
};

export const litProductRenderer = ({
  fieldName: propertyPath,
  readOnly,
  onChange,
}: {
  fieldName: string;
  readOnly?: boolean;
  onChange?: (
    fieldName: string,
    index: number,
    productId: number,
    record?: ProductItem
  ) => void;
}) => {
  return (productId: number, _: any, index: number) => {
    const handleChange = (newProductId: number, record?: ProductItem) => {
      onChange?.(propertyPath, index, newProductId, record);
    };
    return (
      <ProductSelect
        readOnly={readOnly}
        value={productId}
        onChange={handleChange}
      />
    );
  };
};

export const litCategoryRenderer = ({
  fieldName: propertyPath,
  readOnly,
  onChange,
}: {
  fieldName: string;
  readOnly?: boolean;
  onChange?: (
    fieldName: string,
    index: number,
    categoryId: number | string,
    record?: ProductItem
  ) => void;
}) => {
  return (categoryId: number | string, record: any, index: number) => {
    const handleChange = (
      newCategoryId: number | string,
      record?: ProductItem
    ) => {
      onChange?.(propertyPath, index, newCategoryId, record);
    };
    return (
      <SelectCategory
        readOnly={readOnly}
        value={categoryId as any}
        onChange={handleChange}
        required={true}
        isBare={true}
        error={record.extra?.errors?.length > 0}
      />
    );
  };
};

export const litVendorRenderer = ({
  fieldName: propertyPath,
  readOnly,
  onChange,
}: {
  fieldName: string;
  readOnly?: boolean;
  onChange?: (
    fieldName: string,
    index: number,
    vendorId?: string,
    record?: VendorItem
  ) => void;
}) => {
  return (vendor: VendorItem, record: any, index: number) => {
    const handleChange = (newVendor?: VendorItem) => {
      onChange?.(propertyPath, index, newVendor?.customer_idx, newVendor);
    };

    return (
      <VendorSelect
        value={vendor}
        isInvoice={false}
        readOnly={readOnly}
        required={true}
        error={record.extra?.errors?.length > 0}
        onChange={handleChange}
        isBare={true}
      />
    );
  };
};

export const litPlaceOfSupply = ({
  fieldName: propertyPath,
  readOnly,
  onChange,
}: {
  fieldName: string;
  readOnly?: boolean;
  onChange?: (
    fieldName: string,
    index: number,
    placeOfSupplyId?: number
  ) => void;
}) => {
  return (placeOfSupplyId: number, row: any, index: number) => {
    const handleChange = (placeOfSupplyId?: number) => {
      onChange?.(propertyPath, index, placeOfSupplyId);
    };

    return (
      <PlaceOfSupply
        readOnly={readOnly}
        value={placeOfSupplyId}
        onChange={handleChange}
        error={row.extra?.errors?.length > 0 && !placeOfSupplyId}
      />
    );
  };
};

/////////////////////////////////
//////// FOOTER RENDERERS ///////
/////////////////////////////////

export const litMakeFooterRenderer = () => {
  return (data: Array<LineItemRecord>) => <LIFooter>{""}</LIFooter>;
};
