import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import { FormInstance } from "antd/lib/form";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { registerEvent } from "~/analytics";
import { BankNameList, getBankList } from "~/api/common";
import {
  action,
  BankDetails,
  doSaveOnboardData,
  OnboardStoreState,
} from "~/app/OnboardCompany/store";

import * as ga from "~/contants/gaConstants";

const mapStateToProps = (state: OnboardStoreState) => ({
  loading: state.onboard.apiState === "loading",
  apiMessage: state.onboard.apiMessage,
  initialValues: state.onboard.bankDetails,
  apiState: state.onboard.apiState,
  directorlist: state.onboard.directorList,
});
const mapDispatchToProps = {
  onBack: action.previousStep,
  finishStep: action.finishStep,
  doSaveOnboardData,
  modifyDirectorList: action.modifyDirectorList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  doNotUseMe?: never;
}
interface State {
  message?: string;
  bankList?: Array<BankNameList>;
}

class StepBank extends Component<Props, State> {
  state: State = {
    bankList: [],
  };

  _formRef = React.createRef<FormInstance<Partial<BankDetails>>>();

  componentDidMount() {
    this.loadBankList();
  }

  loadBankList = async () => {
    const response = await getBankList();
    if (!response.ok) {
      notification.error({
        message: "Failed to load Bank List",
        description: response.message,
      });
    } else {
      this.setState({
        bankList: response.data!,
      });
    }
  };

  onFinish = (values: BankDetails) => {
    this.props.finishStep({
      step: "bank",
      data: values,
    });
    const loginData = JSON.parse(localStorage.getItem("imt__user")!);
    const onboardCommunicationData = JSON.parse(
      localStorage.getItem("imt__data_onboarding")!
    );

    const findDir = this.props.directorlist.find(
      (it: any) =>
        it.name === onboardCommunicationData.communicationDetails.admin_name
    );
    if (!findDir) {
      const newAdmin: any = {
        email: loginData.email,
        begin_date: "",
        end_date: "",
        "din/pan": null,
        name: onboardCommunicationData.communicationDetails.admin_name,
      };
      this.props.modifyDirectorList(newAdmin);
      setTimeout(() => {
        this.props.doSaveOnboardData(0);
      }, 300);
      return;
    } else {
      const newAdmin: any = {
        email: loginData.email,
        begin_date: findDir.begin_date,
        end_date: findDir.end_date,
        "din/pan": findDir["din/pan"],
        name: onboardCommunicationData.communicationDetails.admin_name,
      };
      this.props.modifyDirectorList(newAdmin);
      setTimeout(() => {
        this.props.doSaveOnboardData(0);
      }, 300);
      return;
    }
  };

  handlePrev = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.stepBank176
    );
    const formValues = this._formRef.current?.getFieldsValue();
    this.props.onBack({
      step: "bank",
      data: formValues,
    });
  };

  handleBankFilterOption = (input?: string, option?: any): any => {
    return input && option.children.toLowerCase().includes(input.toLowerCase());
  };

  render() {
    let userMessage = "";
    if (/already registered/.test(this.props.apiMessage)) {
      userMessage =
        "Company is already registered. Please reset your password or continue with a different company.";
    } else {
      userMessage = this.props.apiMessage;
    }
    return (
      <Spin spinning={this.props.loading}>
        <Form
          initialValues={this.props.initialValues}
          ref={this._formRef}
          onFinish={this.onFinish}
        >
          <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="bank_type_id"
                label="Bank name"
                rules={[
                  {
                    required: false,
                    message: "Please select Bank name",
                  },
                ]}
              >
                <Select showSearch filterOption={this.handleBankFilterOption}>
                  {this.state.bankList?.map((bank: any, indx: any) => (
                    <Select.Option key={indx} value={bank.id}>
                      {bank.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="account_number"
                label="Account number"
                rules={[
                  {
                    type: "string",
                    pattern: /^[0-9 ]+$/,
                    // required: true,
                    message: "Please enter Account number",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ sm: 10, md: 32, lg: 32, xl: 32 }}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="ifsc_code"
                label="IFSC code"
                rules={[
                  {
                    type: "string",
                    pattern: /^[A-Z]{4}[0][A-Z0-9]{6,7}$/,
                    // required: true,
                    message: "Please enter valid IFSC code",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="swift_code"
                label="SWIFT code"
                rules={[
                  {
                    type: "string",
                    pattern: /^[A-Z]{4}[A-Z0-9]{4,7}$/,
                    message: "Please enter valid Swift code",
                    whitespace: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <hr />
          <Row justify="end" gutter={16}>
            <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="ca_or_sme"
                label="How many GSTINs to be managed ?"
                rules={[
                  {
                    required: true,
                    message: "Please select a value",
                  },
                ]}
              >
                <Select>
                  <Select.Option key={"indx--1"} value={"multiple"}>
                    Multiple GSTINs
                  </Select.Option>
                  <Select.Option key={"indx--2"} value={"single"}>
                    Single
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              fontSize: "14px",
              width: "100%",

              color:
                this.props.apiState === "success"
                  ? "var(--success-color)"
                  : "var(--error-color)",
            }}
          >
            {userMessage}
          </div>
          <Row justify="end" gutter={16}>
            <Col>
              <Button
                type="ghost"
                size="middle"
                htmlType="submit"
                onClick={this.handlePrev}
              >
                Back
              </Button>
            </Col>
            <Col>
              <Button
                loading={this.props.loading}
                type="primary"
                htmlType="submit"
                size="middle"
              >
                Start 30 days free trial
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  }
}

export default connector(StepBank);
