import * as React from "react";
import { Button, message as antMessage } from "antd";
import { FilePdfOutlined, UploadOutlined } from "@ant-design/icons";
import { DraggableModal } from "ant-design-draggable-modal";
import { getSignedDownloadURL, getSignedUploadURL } from "~/api/common";

import classes from "./ExpenseAttachmentCell.module.less";
import usePersistantCallbackRef from "~/lib/hook/usePersistantCallbackRef";

type Props = {
  fileKey?: string;
  fileName?: string;
  previewTitle?: string;
  isProcessing?: boolean;
  onCancelProcess?: () => void;
  onDelete?: () => void;
  onChange?: (filekey: string, fileName: string, file: File) => void;
};

export const ExpenseAttachmentCell: React.FC<Props> = ({
  fileKey,
  fileName,
  previewTitle,
  isProcessing,
  onCancelProcess: cancelProcess,
  onDelete,
  onChange,
}) => {
  const lastPreviewURLRef = React.useRef<string>("");
  const xhrRef = React.useRef<XMLHttpRequest>();
  const [previewURL, setPreviewURL] = React.useState("");
  const [uploadPerc, setUploadPerc] = React.useState<number | undefined>(
    undefined
  );
  const [uploadError, setUploadError] = React.useState<
    ErrorEvent | Error | undefined
  >();

  const isFileAvailable = !!fileKey;
  const isPreviewVisible = !!previewURL;
  const isWorking = !!uploadPerc || !!isProcessing;

  const handlePreviewReq = React.useCallback(async () => {
    if (!fileKey || isWorking) {
      return;
    }
    if (lastPreviewURLRef.current) {
      setPreviewURL(lastPreviewURLRef.current);
      return;
    }
    const { ok, message, url } = await getSignedDownloadURL(fileKey!);
    if (!ok) {
      antMessage.error({ message });
    } else {
      const fUrl = fileKey!.endsWith(".pdf") ? url! + "#view=fitH" : url!;
      lastPreviewURLRef.current = fUrl;
      setPreviewURL(fUrl);
    }
  }, [fileKey, isWorking]);

  const handleModalCancel = React.useCallback(() => {
    setPreviewURL((cUrl) => {
      lastPreviewURLRef.current = cUrl;
      return "";
    });
  }, []);

  const handleCancel = usePersistantCallbackRef(() => {
    xhrRef.current?.abort();
    xhrRef.current = undefined;
    if (isProcessing) {
      cancelProcess?.();
    } else {
      onDelete?.();
    }
  });

  const handleAttach = usePersistantCallbackRef(() => {
    const $i = document.createElement("input") as HTMLInputElement;
    $i.type = "file";
    $i.accept = ".pdf,image/*";
    $i.onchange = async (evt) => {
      evt.preventDefault();
      const file = $i.files?.length ? $i.files[0] : null;
      if (!file) {
        return;
      }
      const { ok, message, data } = await getSignedUploadURL(file.name);
      if (!ok) {
        antMessage.warn({ message });
        return;
      }

      const { hashedFileName, url } = data!;
      onChange?.(hashedFileName, file.name, file);
      const xhr = new XMLHttpRequest();
      xhrRef.current = xhr;
      xhr.upload.onprogress = (e) => {
        if (e.total > 0) {
          setUploadPerc(Math.round((e.loaded / e.total) * 100));
        }
      };
      xhr.onerror = (_e) => {
        $i.remove();
        const err = (_e as any) as ErrorEvent;
        setUploadError(err);
      };
      xhr.onload = () => {
        xhrRef.current = undefined;
        $i.remove();
        if (xhr.status < 200 || xhr.status >= 300) {
          setUploadError(new Error(`API error: ${xhr.responseText}`));
        } else {
          console.info("Upload success:", xhr.responseText);
          // onChange?.(hashedFileName, file.name);
          setUploadError(undefined);
          setUploadPerc(undefined);
        }
      };
      xhr.open("PUT", url);
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.send(file);
    };
    document.body.appendChild($i);
    $i.click();
  });

  React.useEffect(() => {
    lastPreviewURLRef.current = "";
  }, [fileKey]);

  return (
    <div className={classes.root}>
      <FilePdfOutlined
        className={isFileAvailable ? classes.fileIcon : classes.vanish}
        role="button"
        onClick={handlePreviewReq}
      />
      <div className={isFileAvailable ? classes.labelBox : classes.vanish}>
        <label>{fileName}</label>
        <div className={classes.actionBox}>
          {uploadError ? (
            <i className={classes.uploadError}>Error!</i>
          ) : (
            <progress
              className={
                isWorking ? classes.progressBar : classes.hiddenProgress
              }
              max={100}
              value={uploadPerc}
            />
          )}
          <button
            className="ant-btn ant-btn-dangerous ant-btn-sm ant-btn-text"
            onClick={handleCancel}
          >
            {isWorking ? "Cancel" : "Delete"}
          </button>
        </div>
      </div>

      <Button
        className={isFileAvailable ? classes.vanish : classes.uploadBtn}
        icon={<UploadOutlined />}
        onClick={handleAttach}
      >
        Upload
      </Button>

      <DraggableModal
        className={classes.previewModal}
        visible={isPreviewVisible}
        onCancel={handleModalCancel}
        footer={null}
        title={previewTitle}
      >
        <iframe
          title="Attachment modal"
          className={classes.previewIframe}
          src={previewURL}
        />
      </DraggableModal>
    </div>
  );
};
