import React, { useEffect, useState } from "react";
import DataToShowText from "./data-to-show/dataToShowText";
import { Button } from "antd";
import styles from "~/component/JsonDownloadPreview.module.css";

type Props = {
  JSONToView: any;
  loading?: boolean;
  setLoading?: any;
  currentLayer: any;
  previousLayers?: any;
  setDataOfALayer: any;
};
function JSONViewerText({
  JSONToView,
  loading,
  setLoading,
  currentLayer,
  previousLayers,
  setDataOfALayer,
}: Props) {
  let lastLayer = currentLayer.split(",");
  lastLayer = lastLayer[lastLayer.length - 1];

  const [showContent, setShowContent] = useState(false);

  useEffect(
    () => {
      if (setLoading) setLoading(false);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSONToView]
  );

  if (!loading) {
    if (JSONToView === null || JSONToView === undefined) {
      return (
        <React.Fragment>
          <span
            className={`${styles.jsonViewInput} ${styles.jsonViewInputText}`}
          >
            {JSON.stringify(JSONToView, null, 2).split('"').join("")}
          </span>
        </React.Fragment>
      );
    }
    if (typeof JSONToView === "boolean") {
      return (
        <React.Fragment>
          <span
            className={`${styles.jsonViewInput} ${styles.jsonViewInputText}`}
          >
            {JSON.stringify(JSONToView, null, 2).split('"').join("")}
          </span>
        </React.Fragment>
      );
    }
    if (typeof JSONToView !== "object") {
      // not object && not array
      return (
        <React.Fragment>
          <span
            className={`${styles.jsonViewInput} ${styles.jsonViewInputText}`}
            style={{
              border: "none",
              marginBottom: 10,
              textAlign: "center",
              width: "50px",
            }}
          >
            {JSON.stringify(JSONToView, null, 2).split('"').join("")}
          </span>
        </React.Fragment>
      );
    }
    if (typeof JSONToView === "object") {
      return (
        <React.Fragment>
          {lastLayer ? (
            <Button
              className={styles.showHideDataBtn}
              // tslint:disable-next-line: jsx-no-lambda
              onClick={() => {
                setShowContent(!showContent);
              }}
            >
              {`[${showContent ? "-" : "+"}] ` +
                (lastLayer ? lastLayer : "Retrived Data") +
                " " +
                (Array.isArray(JSONToView)
                  ? `[${Object.keys(JSONToView).length}]`
                  : `{${Object.keys(JSONToView).length}}`)}
            </Button>
          ) : null}

          <table
            className={`${styles.jsonPreviewTableText} `}
            style={{ display: showContent || !lastLayer ? "block" : "none" }}
          >
            <DataToShowText
              JSONToView={JSONToView}
              currentLayer={currentLayer}
              previousLayers={previousLayers}
              setDataOfALayer={setDataOfALayer}
            />
          </table>
        </React.Fragment>
      );
    }
  }
  return <>{/* <img src={loader} alt="" /> */}</>;
  //   return "Loading";
}

export default JSONViewerText;
