import { MinusOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import React from "react";

interface Props {
  isLastRow?: boolean;
  readOnly?: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

/**
 * Component used in Line Item table's Column
 */
const LICRightButton = ({ readOnly, onClick }: Props) => {
  const isDisabled = readOnly; // or it is disabled
  return (
    // <Space>
    <Button
      size="small"
      disabled={isDisabled}
      danger
      title="Remove line item"
      aria-label="Remove lineitem"
      icon={<MinusOutlined />}
      onClick={onClick}
    />
    // {/* {validationInfo} */}
    // </Space>
  );
};

export default LICRightButton;
