import React from "react";
import { IMTIconProps, IMTIconElement, __colors } from "./interface";

const Employee: IMTIconElement = ({ highlighted, ...props }: IMTIconProps) => {
  const color = highlighted ? __colors.active : __colors.inactive;

  return (
    <svg x="0px" y="0px" viewBox="0 0 56 56" xmlSpace="preserve" {...props}>
      <path
        d="M28 3.3C14.4 3.3 3.3 14.4 3.3 28S14.4 52.7 28 52.7 52.7 41.6 52.7 28 41.6 3.3 28 3.3zm5.1 32.3c5.2 0 9.6 3.9 10.3 9v.1c-3 2.8-6.7 4.7-10.9 5.6l-1.8-11 1.5-3.7h.9zm-4 2.5h-1.8l-1-2.6h3.8l-1 2.6zm-5-2.5l1.5 3.7-2.1 11c-4.2-.8-7.9-2.8-10.9-5.6v-.1c.7-5.2 5.1-9 10.3-9h1.2zm1.4 15l2-10.4h1.4l1.7 10.4c-.8.1-1.7.1-2.5.1-1 0-1.8 0-2.6-.1zM45.1 43c-1.4-5.4-6.3-9.4-12-9.4H23c-5.7 0-10.6 3.9-12 9.4-3.5-4-5.6-9.2-5.6-15C5.3 15.5 15.5 5.3 28 5.3c12.5 0 22.7 10.2 22.7 22.7 0 5.7-2.1 11-5.6 15z"
        fill={color}
      />
      <path
        d="M35.4 45h3.9c.6 0 1-.4 1-1s-.4-1-1-1h-3.9c-.6 0-1 .4-1 1s.4 1 1 1zM38.2 19.6v-.2c0-5.6-4.6-10.2-10.2-10.2-5.5 0-10 4.3-10.2 9.7v.5c0 8.3 3.7 13.1 10.2 13.1 7.4 0 10.1-6.8 10.2-12.7v-.1c.1-.1 0-.1 0-.1zM28 11.2c4.2 0 7.7 3.2 8.2 7.3-.9-.2-1.8-.8-2.8-2.4-.2-.3-.5-.5-.9-.5s-.7.2-.9.5c-.7 1.2-3 2.6-9.8 2.6-.7 0-1.4-.1-2.1-.3.6-4.1 4.1-7.2 8.3-7.2zm0 19.3c-6.6 0-8-5.9-8.2-10.1.7.1 1.4.2 2.1.2 5.2 0 8.7-.8 10.6-2.4 1.2 1.5 2.5 2.1 3.7 2.3-.2 3.1-1.4 10-8.2 10z"
        fill={color}
      />
    </svg>
  );
};

export default Employee;
