import React, { Component } from "react";
import { RouteChildrenProps } from "react-router";
import { HourglassOutlined } from "@ant-design/icons";
import NonIdealState from "./NonIdealState";

import commonStyles from "~/component/common.module.css";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";

interface Props extends RouteChildrenProps {
  doNotUseMe?: never;
}

export default class WIPPage extends Component<Props, {}> {
  state = {};

  render() {
    return (
      <>
        <Helmet>
          <title>{titles.WIPPage}</title>
        </Helmet>

        <NonIdealState
          className={commonStyles["min-h-full"]}
          icon={<HourglassOutlined />}
          title="Coming Soon"
          description="This Feature is coming soon. Stay tuned."
        />
      </>
    );
  }
}
