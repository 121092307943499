import React, { ReactElement, SVGProps } from "react";

export interface Props extends SVGProps<SVGSVGElement> {}

function IllustrationGirlInfluencer(props: Props): ReactElement {
  return (
    <svg viewBox="0 0 700 609" fill="none" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M698.42 103.29c-3.493 21.995-11.442 43.039-14.856 65.041-2.723 17.574-2.544 35.715-7.625 52.736-5.131 17.213-15.409 32.466-20.425 49.693-.115.39-.23.788-.338 1.179a94.37 94.37 0 00-2.666 13.852 116.682 116.682 0 00-.467 4.657c-1.301 16.211.51 32.886 1.071 49.273.776 22.702-1.632 47.305-16.264 64.363-10.22 11.907-24.982 18.421-39.241 24.611-21.56 9.328-43.444 18.745-66.636 21.95-43.466 5.998-88.355-10.257-131.081-.147-35.805 8.474-65.401 34.425-100.379 45.986-35.41 11.709-73.881 7.884-110.405.663-67.067-13.263-135.623-40.239-177.235-95.789-5.49-7.332-10.6-15.474-11.65-24.655-.776-6.727.72-13.492 2.156-20.094 6.469-29.473 13.476-60.067 32.111-83.425 16.049-20.108 39.98-33.814 51.113-57.157 14.575-30.557 2.61-67.052-11.499-97.801-3.399-7.368-6.978-14.95-7.697-23.1-1.387-15.363 8-30.1 20.267-39.082 12.268-8.982 27.123-13.33 41.641-17.544 25.808-7.369 55.389-12.114 79.386-24.412C231.123 2.078 249.78-1.562 276.695.575a680.86 680.86 0 01117.433 19.799c40.821 10.647 82.994 25.2 124.196 16.048 16.429-3.684 31.867-10.957 48.109-15.393 13.583-3.684 27.619-5.386 41.684-6.204 28.438-1.643 60.664 2.02 78.552 24.75 13.612 17.346 15.251 41.735 11.751 63.715z"
          fill="#9B51E0"
        />
        <path
          d="M388.953 595.472s-19.548-68.085 3.665-118.197c9.745-21.037 12.987-44.646 8.481-67.466a184.406 184.406 0 00-9.343-31.684"
          stroke="#535461"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path
          d="M406.244 346.5c0 8.341-14.74 32.546-14.74 32.546s-14.733-24.234-14.733-32.546c.12-3.925 1.724-7.647 4.474-10.38 2.75-2.733 6.429-4.261 10.259-4.261s7.509 1.528 10.259 4.261 4.355 6.455 4.474 10.38h.007zM430.601 392.685c-4.42 7.008-29.625 19.107-29.625 19.107s.46-28.524 4.88-35.531a14.973 14.973 0 013.978-4.386 14.574 14.574 0 015.328-2.458 14.388 14.388 0 015.841-.142 14.526 14.526 0 015.435 2.195 14.923 14.923 0 014.177 4.187 15.318 15.318 0 012.263 5.522 15.5 15.5 0 01-.008 5.99 15.294 15.294 0 01-2.277 5.516h.008zM429.537 471.675c-7.266 3.772-34.979 1.164-34.979 1.164s14.439-24.39 21.705-28.14a14.41 14.41 0 0111.11-.655c3.645 1.279 6.657 3.969 8.391 7.496a15.46 15.46 0 01.887 11.374c-1.165 3.764-3.721 6.912-7.121 8.768l.007-.007zM415.602 531.248c-6.54 4.959-34.282 7.207-34.282 7.207s10.213-26.527 16.753-31.5a14.583 14.583 0 015.205-2.513 14.37 14.37 0 015.747-.28 14.51 14.51 0 015.414 1.996 14.894 14.894 0 014.257 3.969 15.297 15.297 0 012.451 5.336c.467 1.927.56 3.929.273 5.893a15.363 15.363 0 01-1.948 5.55 14.992 14.992 0 01-3.87 4.365v-.023zM370.037 413.405c5.836 5.814 33.059 11.841 33.059 11.841s-6.662-27.698-12.497-33.511a14.728 14.728 0 00-4.832-3.288 14.42 14.42 0 00-11.37.092 14.731 14.731 0 00-4.779 3.367 15.144 15.144 0 00-3.144 4.995 15.456 15.456 0 00.239 11.656 15.135 15.135 0 003.346 4.856l-.022-.008zM354.607 483.295c7.266 3.758 34.978 1.164 34.978 1.164s-14.431-24.39-21.711-28.17a14.475 14.475 0 00-5.589-1.71 14.393 14.393 0 00-5.798.645 14.637 14.637 0 00-5.1 2.9 15.065 15.065 0 00-3.604 4.702 15.452 15.452 0 00-.784 11.695 15.215 15.215 0 002.942 5.163 14.787 14.787 0 004.666 3.589v.022zM348.699 553.332c6.54 4.959 34.281 7.206 34.281 7.206s-10.212-26.526-16.752-31.5a14.472 14.472 0 00-10.952-2.793c-3.868.595-7.346 2.741-9.671 5.965a15.412 15.412 0 00-2.724 11.229c.58 3.965 2.673 7.532 5.818 9.915v-.022z"
          fill="#9B51E0"
        />
        <path
          opacity={0.25}
          d="M406.244 346.5c0 8.341-14.74 32.546-14.74 32.546s-14.733-24.234-14.733-32.546c.12-3.925 1.724-7.647 4.474-10.38 2.75-2.733 6.429-4.261 10.259-4.261s7.509 1.528 10.259 4.261 4.355 6.455 4.474 10.38h.007zM430.601 392.685c-4.42 7.008-29.625 19.107-29.625 19.107s.46-28.524 4.88-35.531a14.973 14.973 0 013.978-4.386 14.574 14.574 0 015.328-2.458 14.388 14.388 0 015.841-.142 14.526 14.526 0 015.435 2.195 14.923 14.923 0 014.177 4.187 15.318 15.318 0 012.263 5.522 15.5 15.5 0 01-.008 5.99 15.294 15.294 0 01-2.277 5.516h.008zM429.537 471.675c-7.266 3.772-34.979 1.164-34.979 1.164s14.439-24.39 21.705-28.14a14.41 14.41 0 0111.11-.655c3.645 1.279 6.657 3.969 8.391 7.496a15.46 15.46 0 01.887 11.374c-1.165 3.764-3.721 6.912-7.121 8.768l.007-.007zM415.602 531.248c-6.54 4.959-34.282 7.207-34.282 7.207s10.213-26.527 16.753-31.5a14.583 14.583 0 015.205-2.513 14.37 14.37 0 015.747-.28 14.51 14.51 0 015.414 1.996 14.894 14.894 0 014.257 3.969 15.297 15.297 0 012.451 5.336c.467 1.927.56 3.929.273 5.893a15.363 15.363 0 01-1.948 5.55 14.992 14.992 0 01-3.87 4.365v-.023zM370.037 413.405c5.836 5.814 33.059 11.841 33.059 11.841s-6.662-27.698-12.497-33.511a14.728 14.728 0 00-4.832-3.288 14.42 14.42 0 00-11.37.092 14.731 14.731 0 00-4.779 3.367 15.144 15.144 0 00-3.144 4.995 15.456 15.456 0 00.239 11.656 15.135 15.135 0 003.346 4.856l-.022-.008zM354.607 483.295c7.266 3.758 34.978 1.164 34.978 1.164s-14.431-24.39-21.711-28.17a14.475 14.475 0 00-5.589-1.71 14.393 14.393 0 00-5.798.645 14.637 14.637 0 00-5.1 2.9 15.065 15.065 0 00-3.604 4.702 15.452 15.452 0 00-.784 11.695 15.215 15.215 0 002.942 5.163 14.787 14.787 0 004.666 3.589v.022zM348.699 553.332c6.54 4.959 34.281 7.206 34.281 7.206s-10.212-26.526-16.752-31.5a14.472 14.472 0 00-10.952-2.793c-3.868.595-7.346 2.741-9.671 5.965a15.412 15.412 0 00-2.724 11.229c.58 3.965 2.673 7.532 5.818 9.915v-.022z"
          fill="#000"
        />
        <path
          d="M497.991 510s-15.217-50.12 2.853-87.011c7.586-15.486 10.109-32.866 6.601-49.665A130.188 130.188 0 00500.173 350"
          stroke="#535461"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path
          opacity={0.25}
          d="M511 326.859c0 6.187-11.503 24.141-11.503 24.141S488 333.024 488 326.859c.093-2.91 1.346-5.672 3.491-7.699a11.664 11.664 0 018.006-3.16c2.989 0 5.86 1.133 8.006 3.16a11.08 11.08 0 013.491 7.699H511zM530.007 360.698C526.575 365.943 507 375 507 375s.357-21.351 3.79-26.596a11.366 11.366 0 013.09-3.284 11.506 11.506 0 014.137-1.839 11.584 11.584 0 014.536-.107 11.54 11.54 0 014.222 1.643 11.387 11.387 0 013.244 3.135 11.239 11.239 0 011.757 4.133c.301 1.48.299 3.005-.006 4.484a11.252 11.252 0 01-1.768 4.129h.005zM528.98 418.526c-5.604 2.722-26.98.84-26.98.84s11.137-17.6 16.741-20.306a11.815 11.815 0 018.57-.473c2.811.923 5.135 2.865 6.472 5.41a10.502 10.502 0 01.684 8.207c-.898 2.717-2.87 4.988-5.493 6.327l.006-.005zM518.395 462.563C513.359 466.304 492 468 492 468s7.863-20.014 12.899-23.766a11.318 11.318 0 0115.878 2.393 11.433 11.433 0 012.097 8.472 11.409 11.409 0 01-4.479 7.481v-.017zM483.533 376.257C488.029 380.55 509 385 509 385s-5.132-20.45-9.627-24.743a11.378 11.378 0 00-3.722-2.427 11.528 11.528 0 00-8.759.068 11.374 11.374 0 00-3.682 2.485 11.157 11.157 0 00-2.421 3.689 10.975 10.975 0 00.184 8.605 11.17 11.17 0 002.577 3.586l-.017-.006zM471.391 427.48c5.528 2.805 26.609.869 26.609.869s-10.978-18.204-16.516-21.025a11.145 11.145 0 00-8.662-.796 11.207 11.207 0 00-3.88 2.165 11.297 11.297 0 00-3.916 7.813 11.347 11.347 0 00.578 4.426 11.29 11.29 0 002.238 3.853 11.237 11.237 0 003.549 2.679v.016zM466.605 478.772C471.641 482.37 493 484 493 484s-7.863-19.244-12.899-22.852a11.658 11.658 0 00-8.432-2.027c-2.978.432-5.656 1.989-7.446 4.328a10.683 10.683 0 00-2.097 8.146c.446 2.877 2.058 5.464 4.479 7.193v-.016z"
          fill="#BB6BD9"
        />
        <path
          d="M104.928 514.773s-19.563-68.092 3.665-118.205c9.746-21.036 12.987-44.645 8.481-67.465a184.29 184.29 0 00-9.343-31.684"
          stroke="#535461"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path
          d="M122.241 265.764c0 8.341-14.74 32.547-14.74 32.547s-14.74-24.198-14.74-32.547c.12-3.924 1.724-7.647 4.474-10.38 2.75-2.732 6.429-4.261 10.259-4.261s7.509 1.529 10.259 4.261c2.75 2.733 4.354 6.456 4.474 10.38h.014zM146.612 311.986c-4.42 7.008-29.624 19.107-29.624 19.107s.46-28.524 4.88-35.531a14.953 14.953 0 013.99-4.323 14.567 14.567 0 015.309-2.407 14.382 14.382 0 015.804-.12 14.525 14.525 0 015.398 2.188 14.897 14.897 0 014.157 4.155 15.31 15.31 0 012.269 5.478c.395 1.962.405 3.985.03 5.951a15.305 15.305 0 01-2.213 5.502zM145.541 390.968c-7.266 3.758-34.978 1.165-34.978 1.165s14.438-24.39 21.704-28.14a14.46 14.46 0 015.588-1.711 14.398 14.398 0 015.799.645 14.642 14.642 0 015.1 2.901 15.05 15.05 0 013.604 4.701 15.414 15.414 0 011.543 5.767 15.488 15.488 0 01-.759 5.928 15.2 15.2 0 01-2.943 5.163 14.772 14.772 0 01-4.665 3.589l.007-.008zM131.606 450.549c-6.54 4.959-34.281 7.207-34.281 7.207s10.212-26.527 16.752-31.5a14.472 14.472 0 0110.952-2.793c3.868.595 7.346 2.74 9.671 5.965a15.412 15.412 0 012.724 11.229c-.58 3.965-2.673 7.531-5.818 9.915v-.023zM86.041 332.706c5.836 5.814 33.06 11.841 33.06 11.841s-6.663-27.698-12.498-33.511c-2.816-2.732-6.567-4.216-10.442-4.133-3.875.084-7.562 1.729-10.263 4.579-2.7 2.85-4.198 6.677-4.167 10.65.03 3.974 1.587 7.776 4.332 10.582l-.022-.008zM70.61 402.596c7.267 3.758 34.979 1.164 34.979 1.164s-14.438-24.389-21.704-28.14a14.412 14.412 0 00-11.387-1.066 14.635 14.635 0 00-5.1 2.901 15.063 15.063 0 00-3.604 4.701 15.47 15.47 0 00-.784 11.696 15.212 15.212 0 002.943 5.163 14.793 14.793 0 004.665 3.588l-.007-.007zM64.682 472.633c6.54 4.959 34.281 7.206 34.281 7.206s-10.212-26.526-16.752-31.5a14.584 14.584 0 00-5.206-2.513 14.37 14.37 0 00-5.747-.28 14.53 14.53 0 00-5.414 1.996 14.887 14.887 0 00-4.256 3.969 15.294 15.294 0 00-2.452 5.337 15.488 15.488 0 00-.272 5.892 15.374 15.374 0 001.947 5.551 14.99 14.99 0 003.87 4.364v-.022z"
          fill="#9B51E0"
        />
        <path
          opacity={0.25}
          d="M122.241 265.764c0 8.341-14.74 32.547-14.74 32.547s-14.74-24.198-14.74-32.547c.12-3.924 1.724-7.647 4.474-10.38 2.75-2.732 6.429-4.261 10.259-4.261s7.509 1.529 10.259 4.261c2.75 2.733 4.354 6.456 4.474 10.38h.014zM146.612 311.986c-4.42 7.008-29.624 19.107-29.624 19.107s.46-28.524 4.88-35.531a14.953 14.953 0 013.99-4.323 14.567 14.567 0 015.309-2.407 14.382 14.382 0 015.804-.12 14.525 14.525 0 015.398 2.188 14.897 14.897 0 014.157 4.155 15.31 15.31 0 012.269 5.478c.395 1.962.405 3.985.03 5.951a15.305 15.305 0 01-2.213 5.502zM145.541 390.968c-7.266 3.758-34.978 1.165-34.978 1.165s14.438-24.39 21.704-28.14a14.46 14.46 0 015.588-1.711 14.398 14.398 0 015.799.645 14.642 14.642 0 015.1 2.901 15.05 15.05 0 013.604 4.701 15.414 15.414 0 011.543 5.767 15.488 15.488 0 01-.759 5.928 15.2 15.2 0 01-2.943 5.163 14.772 14.772 0 01-4.665 3.589l.007-.008zM131.606 450.549c-6.54 4.959-34.281 7.207-34.281 7.207s10.212-26.527 16.752-31.5a14.472 14.472 0 0110.952-2.793c3.868.595 7.346 2.74 9.671 5.965a15.412 15.412 0 012.724 11.229c-.58 3.965-2.673 7.531-5.818 9.915v-.023zM86.041 332.706c5.836 5.814 33.06 11.841 33.06 11.841s-6.663-27.698-12.498-33.511c-2.816-2.732-6.567-4.216-10.442-4.133-3.875.084-7.562 1.729-10.263 4.579-2.7 2.85-4.198 6.677-4.167 10.65.03 3.974 1.587 7.776 4.332 10.582l-.022-.008zM70.61 402.596c7.267 3.758 34.979 1.164 34.979 1.164s-14.438-24.389-21.704-28.14a14.412 14.412 0 00-11.387-1.066 14.635 14.635 0 00-5.1 2.901 15.063 15.063 0 00-3.604 4.701 15.47 15.47 0 00-.784 11.696 15.212 15.212 0 002.943 5.163 14.793 14.793 0 004.665 3.588l-.007-.007zM64.682 472.633c6.54 4.959 34.281 7.206 34.281 7.206s-10.212-26.526-16.752-31.5a14.584 14.584 0 00-5.206-2.513 14.37 14.37 0 00-5.747-.28 14.53 14.53 0 00-5.414 1.996 14.887 14.887 0 00-4.256 3.969 15.294 15.294 0 00-2.452 5.337 15.488 15.488 0 00-.272 5.892 15.374 15.374 0 001.947 5.551 14.99 14.99 0 003.87 4.364v-.022z"
          fill="#000"
        />
        <path
          d="M177.781 535.972s-10.586-36.843 1.984-63.995c5.346-11.345 6.957-24.162 4.592-36.525a99.652 99.652 0 00-5.031-17.147"
          stroke="#535461"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path
          d="M187.139 401.159c0 4.517-7.978 17.618-7.978 17.618s-7.977-13.101-7.977-17.618c0-2.169.84-4.25 2.336-5.783a7.88 7.88 0 015.641-2.396c2.116 0 4.145.862 5.641 2.396a8.284 8.284 0 012.337 5.783zM200.326 426.182c-2.393 3.795-16.033 10.316-16.033 10.316s.244-15.474 2.644-19.232a8.097 8.097 0 012.17-2.289 7.878 7.878 0 012.859-1.264 7.771 7.771 0 013.113-.046 7.863 7.863 0 012.894 1.179 8.094 8.094 0 012.233 2.224 8.283 8.283 0 011.233 2.932 8.396 8.396 0 01.045 3.191 8.321 8.321 0 01-1.15 2.967l-.008.022zM199.752 468.948c-3.932 2.034-18.938.627-18.938.627s7.82-13.205 11.751-15.238a7.799 7.799 0 016.091-.465 8.042 8.042 0 014.628 4.086 8.372 8.372 0 01.454 6.245 8.128 8.128 0 01-3.986 4.745zM192.213 501.237c-3.544 2.689-18.564 3.898-18.564 3.898s5.527-14.369 9.07-17.051a7.828 7.828 0 015.926-1.515 7.951 7.951 0 015.235 3.225 8.34 8.34 0 011.478 6.075 8.222 8.222 0 01-3.145 5.368zM167.54 437.404c3.162 3.146 17.888 6.411 17.888 6.411s-3.593-14.995-6.77-18.141a7.877 7.877 0 00-5.676-2.312 7.889 7.889 0 00-5.607 2.481 8.29 8.29 0 00-2.254 5.819 8.283 8.283 0 002.419 5.75v-.008zM159.189 475.241c3.931 2.034 18.937.626 18.937.626s-7.797-13.204-11.757-15.237a7.802 7.802 0 00-6.092-.466 7.916 7.916 0 00-2.71 1.572 8.161 8.161 0 00-1.918 2.515 8.36 8.36 0 00-.453 6.245 8.23 8.23 0 001.532 2.779 8.017 8.017 0 002.454 1.966h.007zM155.955 513.159c3.543 2.689 18.564 3.898 18.564 3.898s-5.527-14.369-9.07-17.051a7.9 7.9 0 00-2.816-1.362 7.802 7.802 0 00-6.041.926 8.04 8.04 0 00-2.304 2.146 8.262 8.262 0 00-1.329 2.887 8.388 8.388 0 00-.149 3.188 8.293 8.293 0 001.052 3.005 8.104 8.104 0 002.093 2.363z"
          fill="#9B51E0"
        />
        <path
          opacity={0.5}
          d="M187.139 401.159c0 4.517-7.978 17.618-7.978 17.618s-7.977-13.101-7.977-17.618c0-2.169.84-4.25 2.336-5.783a7.88 7.88 0 015.641-2.396c2.116 0 4.145.862 5.641 2.396a8.284 8.284 0 012.337 5.783zM200.326 426.182c-2.393 3.795-16.033 10.316-16.033 10.316s.244-15.474 2.644-19.232a8.097 8.097 0 012.17-2.289 7.878 7.878 0 012.859-1.264 7.771 7.771 0 013.113-.046 7.863 7.863 0 012.894 1.179 8.094 8.094 0 012.233 2.224 8.283 8.283 0 011.233 2.932 8.396 8.396 0 01.045 3.191 8.321 8.321 0 01-1.15 2.967l-.008.022zM199.752 468.948c-3.932 2.034-18.938.627-18.938.627s7.82-13.205 11.751-15.238a7.799 7.799 0 016.091-.465 8.042 8.042 0 014.628 4.086 8.372 8.372 0 01.454 6.245 8.128 8.128 0 01-3.986 4.745zM192.213 501.237c-3.544 2.689-18.564 3.898-18.564 3.898s5.527-14.369 9.07-17.051a7.828 7.828 0 015.926-1.515 7.951 7.951 0 015.235 3.225 8.34 8.34 0 011.478 6.075 8.222 8.222 0 01-3.145 5.368zM167.54 437.404c3.162 3.146 17.888 6.411 17.888 6.411s-3.593-14.995-6.77-18.141a7.877 7.877 0 00-5.676-2.312 7.889 7.889 0 00-5.607 2.481 8.29 8.29 0 00-2.254 5.819 8.283 8.283 0 002.419 5.75v-.008zM159.189 475.241c3.931 2.034 18.937.626 18.937.626s-7.797-13.204-11.757-15.237a7.802 7.802 0 00-6.092-.466 7.916 7.916 0 00-2.71 1.572 8.161 8.161 0 00-1.918 2.515 8.36 8.36 0 00-.453 6.245 8.23 8.23 0 001.532 2.779 8.017 8.017 0 002.454 1.966h.007zM155.955 513.159c3.543 2.689 18.564 3.898 18.564 3.898s-5.527-14.369-9.07-17.051a7.9 7.9 0 00-2.816-1.362 7.802 7.802 0 00-6.041.926 8.04 8.04 0 00-2.304 2.146 8.262 8.262 0 00-1.329 2.887 8.388 8.388 0 00-.149 3.188 8.293 8.293 0 001.052 3.005 8.104 8.104 0 002.093 2.363z"
          fill="#fff"
        />
        <path
          d="M57.61 319.767c.286 0 .56-.116.762-.323a1.122 1.122 0 000-1.563 1.062 1.062 0 00-1.525 0 1.122 1.122 0 000 1.563c.203.207.477.323.763.323z"
          fill="#72351C"
        />
        <path
          d="M256.98 81.974l7.856 15.348c1.638 3.198 3.298 6.47 3.593 9.94.403 4.281-1.25 8.555-.855 12.836.366 3.935 2.436 7.619 4.844 11.053 14.948 21.147 44.925 34.277 74.348 32.568-11.125 4.819-23.839 6.285-36.272 7.67-6.008.671-12.132 1.342-18.104.494-5.469-.737-10.565-2.778-15.423-5.003-9.616-4.421-18.758-9.852-25.326-17.168-6.569-7.317-10.306-16.741-8.423-25.738.661-3.161 1.99-6.212 2.529-9.388.812-4.804-.201-9.748.92-14.501.978-4.133 3.515-7.906 4.65-12.01 1.028-3.684-2.465-9.02.144-11.79 2.81.678 4.391 3.478 5.519 5.689z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M173.002 331.505l40.354-55.263-6.374-65.196 89.533-7.405-.33-13.138 19.821 43.223 60.176 58.808.417.611 13.791-7.066-52.464-65.085-24.751-43.231-.058-.199c-6.152-20.3-24.493-34.491-45.672-35.368-5.261-.214-9.782-.604-11.614-1.297-5.664-2.122-2.832-23.38-2.832-23.38l-38.227 4.244s3.558 8.695 6.181 17.95a24.135 24.135 0 01-.818 15.302c-1.926 4.866-5.382 8.932-9.819 11.556-11.657 7.044-35.136 19.238-31.996 31.367 4.959 19.158 10.263 64.105 10.263 64.105l-23.364 67.311"
          fill="url(#paint1_linear)"
        />
        <path
          d="M165.211 329.376s-31.859 30.468-18.405 30.468 26.196-28.339 26.196-28.339"
          fill="url(#paint2_linear)"
        />
        <path
          d="M391.044 286.867l-.654-.81-13.799 7.081c2.731 3.913 18.176 25.355 27.188 22.776 9.925-2.83-12.735-29.047-12.735-29.047z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M285.563 497.309s7.079 36.106-3.543 44.638c-10.623 8.533-16.99 6.374-16.99 6.374v-48.889l20.533-2.123z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M152.031 352.778s-13.016 15.577-8.057 19.121c4.959 3.544 16.293 1.407 17.012 9.233.718 7.825 25.484 5.666 25.484 5.666s26.901 17.006 49.59 12.755c22.688-4.252 64.351-9.948 64.351-9.948l22.653-.737s18.427-9.21 21.97-7.736c3.543 1.473 16.99-10.626 12.038-19.158-4.952-8.533-205.041-9.196-205.041-9.196z"
          fill="url(#paint5_linear)"
        />
        <path
          opacity={0.2}
          d="M152.031 352.778s-13.016 15.577-8.057 19.121c4.959 3.544 16.293 1.407 17.012 9.233.718 7.825 25.484 5.666 25.484 5.666s26.901 17.006 49.59 12.755c22.688-4.252 64.351-9.948 64.351-9.948l22.653-.737s18.427-9.21 21.97-7.736c3.543 1.473 16.99-10.626 12.038-19.158-4.952-8.533-205.041-9.196-205.041-9.196z"
          fill="url(#paint6_linear)"
        />
        <path
          d="M305.384 376.143l-9.911 64.481s7.791 17.007-7.79 42.516l-2.832 14.169-19.117 2.123-7.072-143.132 46.722 19.843z"
          fill="url(#paint7_linear)"
        />
        <path
          opacity={0.1}
          d="M215.476 366.933l19.822 51.041s3.543 21.258 2.156 25.509c-1.387 4.252-3.543 45.345 4.247 58.1 7.791 12.755 18.406 60.937 18.406 60.937l21.237-2.122s-7.819-13.477-7.819-21.995v-40.386s0-27.632-4.959-47.475l1.89-13.263c.622-4.35.501-8.779-.36-13.086l-12.857-64.349-41.763 7.089z"
          fill="url(#paint8_linear)"
        />
        <path
          d="M214.061 366.933l19.821 51.041s3.543 21.258 2.156 25.509c-1.387 4.252-3.543 45.345 4.247 58.1 7.791 12.755 18.406 60.937 18.406 60.937l21.237-2.122s-7.819-13.477-7.819-21.995v-40.386s0-27.632-4.959-47.475l1.89-13.263c.622-4.35.501-8.779-.359-13.086l-12.829-64.349-41.791 7.089z"
          fill="url(#paint9_linear)"
        />
        <path
          d="M258.662 562.498s4.248 12.755 0 16.299c-4.247 3.544-2.81 26.924-2.81 26.924s26.196 10.625 29.732-6.374c0 0-7.079-31.883-5.663-38.971l-21.259 2.122z"
          fill="url(#paint10_linear)"
        />
        <path
          opacity={0.1}
          d="M243.088 202.543s34.691-24.095 53.097 0c0 0 4.247 29.761 0 35.428 0 0-3.543 23.38 2.156 26.924 5.699 3.544 9.91 14.877 9.91 14.877s20.533 9.21 23.365 15.591c2.832 6.381 32.564 70.148 32.564 70.148s-39.643 21.257-53.801 19.157c-14.158-2.1-45.277 0-45.277 0s-16.99-18.421-47.434-9.917c-30.443 8.503-70.862-13.514-70.862-13.514l62.332-84.295s10.622-14.169 5.663-30.468-5.663-38.257-5.663-38.257 2.091-17.006 33.95-5.674z"
          fill="url(#paint11_linear)"
        />
        <path
          d="M243.088 201.128s34.691-24.094 53.097 0c0 0 4.247 29.761 0 35.428 0 0-3.543 23.38 2.156 26.924 5.699 3.544 9.911 14.877 9.911 14.877s20.532 9.21 23.364 15.591c2.832 6.381 32.564 70.148 32.564 70.148s-39.643 21.258-53.801 19.158c-14.158-2.1-45.277 0-45.277 0s-16.99-18.421-47.434-9.918c-30.443 8.503-70.797-13.462-70.797-13.462l62.267-84.347s10.622-14.169 5.663-30.468-5.663-38.264-5.663-38.264 2.091-17.007 33.95-5.667z"
          fill="url(#paint12_linear)"
        />
        <path
          opacity={0.1}
          d="M260.437 144.723s22.776-1.894 28.475 5.777l-28.475-5.777z"
          fill="url(#paint13_linear)"
        />
        <path
          opacity={0.1}
          d="M201.131 177.564s16.53-15.798 25.744-13.418l-25.744 13.418z"
          fill="url(#paint14_linear)"
        />
        <path
          opacity={0.1}
          d="M216.892 121.763s1.438 3.581 3.206 8.584c4.891 3.862 10.838 6.058 17.008 6.28a29.039 29.039 0 0017.395-5.042 87.11 87.11 0 01.618-14.066l-38.227 4.244z"
          fill="url(#paint15_linear)"
        />
        <path
          d="M236.009 136.647c16.032 0 29.028-13.007 29.028-29.053S252.041 78.54 236.009 78.54c-16.031 0-29.027 13.008-29.027 29.054s12.996 29.053 29.027 29.053z"
          fill="url(#paint16_linear)"
        />
        <path
          opacity={0.1}
          d="M265.914 84c-1.517-5.283-4.075-15.105-9.429-16.328a24.692 24.692 0 01-3.45-.789c-2.156-.84-3.673-2.822-5.469-4.31-3.996-3.301-10.845.862-15.998.331-4.837-.5-9.911-.84-14.374 1.017-9.005 3.684-12.433 14.479-18.901 21.766-4.844 5.453-11.435 8.953-17.558 12.903-6.123 3.949-12.167 8.805-14.79 15.606-1.855 4.811-1.812 10.139-1.366 15.282a106.756 106.756 0 0011.822 40.342c1.883 3.581 3.996 7.118 4.923 11.053.928 3.934.468 8.473-2.307 11.421-3.909 4.163-10.557 3.684-15.811 5.894 4.363 4.466 10.853 6.433 17.084 6.168 6.231-.265 12.217-2.594 17.629-5.703 13.942-8.01 24.5-21.369 30.257-36.378 2.673-6.978 4.369-14.634 2.738-21.936-1.402-6.263-5.11-11.716-8.229-17.323-3.119-5.608-5.75-11.937-4.779-18.281.905-5.71 4.757-10.662 9.536-13.92 3.953-2.696 10.953-6.373 15.934-6.16 5.512.222 24.025 4.635 22.538-.655z"
          fill="url(#paint17_linear)"
        />
        <path
          d="M265.195 83.293c-1.516-5.284-4.075-15.106-9.429-16.329a24.71 24.71 0 01-3.45-.788c-2.156-.84-3.672-2.822-5.469-4.31-3.996-3.302-10.845.861-15.998.33-4.837-.5-9.911-.84-14.374 1.018-9.005 3.684-12.433 14.479-18.901 21.766-4.844 5.453-11.434 8.953-17.558 12.902-6.123 3.95-12.167 8.805-14.79 15.606-1.854 4.812-1.811 10.139-1.366 15.283a106.78 106.78 0 0011.823 40.342c1.883 3.581 3.996 7.118 4.923 11.052.927 3.935.467 8.474-2.307 11.421-3.91 4.163-10.558 3.685-15.811 5.895 4.362 4.465 10.852 6.433 17.083 6.167 6.231-.265 12.218-2.593 17.629-5.703 13.943-8.009 24.5-21.368 30.257-36.378 2.673-6.978 4.369-14.633 2.738-21.935-1.401-6.264-5.11-11.716-8.229-17.324-3.119-5.607-5.749-11.936-4.779-18.281.905-5.71 4.758-10.662 9.537-13.919 3.953-2.696 10.953-6.373 15.933-6.16 5.52.236 24.04 4.657 22.538-.655z"
          fill="url(#paint18_linear)"
        />
        <path
          opacity={0.1}
          d="M294.41 263.126s-13.454 8.503-29.028 7.089l29.028-7.089z"
          fill="url(#paint19_linear)"
        />
        <path
          opacity={0.1}
          d="M308.568 276.588s-34.691 13.463-48.152 12.048l48.152-12.048z"
          fill="url(#paint20_linear)"
        />
        <path
          opacity={0.1}
          d="M216.54 241.868s37.523 3.545 43.89-3.544c0 0 20.533 6.374 33.98 0"
          fill="url(#paint21_linear)"
        />
        <path
          d="M257.872 88.362l7.46 14.921c1.552 3.11 3.133 6.293 3.442 9.668.388 4.163-1.186 8.318-.812 12.482.345 3.824 2.314 7.412 4.6 10.721 14.194 20.565 42.639 33.334 70.575 31.684-10.558 4.686-22.632 6.108-34.433 7.457-5.706.648-11.499 1.304-17.183.479-5.189-.737-10.062-2.705-14.64-4.863-9.127-4.282-17.809-9.579-24.04-16.697-6.231-7.118-9.81-16.211-8.028-24.979.625-3.073 1.89-6.042 2.401-9.13.776-4.671-.194-9.476.876-14.103.927-4.016 3.335-7.685 4.413-11.679.978-3.61-2.336-8.768.137-11.487 2.659.663 4.154 3.374 5.232 5.526z"
          fill="#252223"
        />
        <path
          d="M178.162 331.026l38.299-53.789-6.051-63.369 84.97-7.191-.316-12.777 18.815 42L371 293.086l.395.59 13.102-6.86-49.762-63.295-23.522-42-.058-.192c-2.854-9.633-8.56-18.117-16.328-24.276-7.768-6.159-17.212-9.688-27.023-10.097-5.031-.207-9.278-.59-11.025-1.26-5.376-2.071-2.688-22.739-2.688-22.739l-36.286 4.134s3.371 8.458 5.864 17.448a23.701 23.701 0 01-.746 14.806c-1.824 4.718-5.11 8.691-9.351 11.308-11.068 6.852-33.354 18.708-30.372 30.498 4.708 18.605 9.746 62.351 9.746 62.351l-22.179 65.454"
          fill="#F0898B"
        />
        <path
          d="M170.767 328.934s-30.242 29.628-17.471 29.628 24.866-27.536 24.866-27.536M385.129 287.597l-.632-.781-13.095 6.889c2.588 3.802 17.249 24.648 25.808 22.142 9.422-2.755-12.081-28.25-12.081-28.25z"
          fill="#FDB797"
        />
        <path
          d="M285.002 492.218s6.72 35.14-3.363 43.407c-10.084 8.268-16.128 6.204-16.128 6.204v-47.541l19.491-2.07z"
          fill="#333"
        />
        <path
          d="M158.255 351.658s-12.362 15.171-7.647 18.613c4.714 3.441 15.459 1.377 16.127 8.959.668 7.583 24.191 5.512 24.191 5.512s25.535 16.535 47.038 12.401c21.503-4.134 61.153-9.645 61.153-9.645l21.503-.693s17.471-8.96 20.842-7.582c3.371 1.378 16.127-10.316 11.427-18.605-4.7-8.29-194.634-8.96-194.634-8.96z"
          fill="#F0898B"
        />
        <path
          opacity={0.2}
          d="M158.255 351.658s-12.362 15.171-7.647 18.613c4.714 3.441 15.459 1.377 16.127 8.959.668 7.583 24.191 5.512 24.191 5.512s25.535 16.535 47.038 12.401c21.503-4.134 61.153-9.645 61.153-9.645l21.503-.693s17.471-8.96 20.842-7.582c3.371 1.378 16.127-10.316 11.427-18.605-4.7-8.29-194.634-8.96-194.634-8.96z"
          fill="#000"
        />
        <path
          d="M303.817 374.404l-9.407 62.728s7.395 16.534-7.396 41.336l-2.688 13.779-18.146 2.071-6.734-139.204 44.371 19.29z"
          fill="#FDB797"
        />
        <path
          opacity={0.1}
          d="M218.48 365.452l18.816 49.574s3.363 20.669 2.019 24.802c-1.344 4.134-3.363 44.093 4.032 56.494 7.395 12.401 17.471 59.25 17.471 59.25l20.188-2.019s-7.395-13.094-7.395-21.369v-39.273s0-26.873-4.708-46.164l1.797-12.902a38.624 38.624 0 00-.345-12.718l-12.217-62.565-39.658 6.89z"
          fill="#000"
        />
        <path
          d="M217.137 365.452l18.815 49.574s3.363 20.669 2.019 24.802c-1.344 4.134-3.363 44.093 4.032 56.494 7.395 12.401 17.443 59.279 17.443 59.279l20.18-2.048s-7.395-13.094-7.395-21.369v-39.273s0-26.873-4.707-46.164l1.796-12.902a38.597 38.597 0 00-.345-12.718l-12.217-62.565-39.621 6.89z"
          fill="#FDB797"
        />
        <path
          d="M259.446 555.601s4.031 12.401 0 15.85c-4.032 3.448-2.688 26.18-2.688 26.18s24.866 10.315 28.223-6.205c0 0-6.72-31.006-5.376-37.895l-20.159 2.07z"
          fill="#333"
        />
        <path
          opacity={0.1}
          d="M244.684 205.608s32.93-23.424 50.401 0c0 0 4.032 28.936 0 34.448 0 0-3.363 22.739 2.02 26.18 5.383 3.441 9.407 14.471 9.407 14.471s19.491 8.96 22.179 15.157c2.688 6.197 30.903 68.21 30.903 68.21s-37.623 20.668-51.069 18.598c-13.447-2.071-43.007 0-43.007 0s-16.134-17.913-45.025-9.646c-28.891 8.268-67.197-13.093-67.197-13.093l59.133-81.959s10.062-13.779 5.376-29.629c-4.686-15.849-5.376-37.203-5.376-37.203s2.02-16.557 32.255-5.534z"
          fill="#000"
        />
        <path
          d="M244.684 204.231s32.93-23.425 50.401 0c0 0 4.032 28.935 0 34.447 0 0-3.363 22.739 2.02 26.18 5.383 3.441 9.407 14.471 9.407 14.471s19.484 8.96 22.172 15.157c2.688 6.197 30.903 68.21 30.903 68.21s-37.616 20.646-51.062 18.598c-13.447-2.049-43.007 0-43.007 0s-16.134-17.913-45.025-9.646c-28.891 8.268-67.197-13.093-67.197-13.093l59.133-81.981s10.062-13.779 5.376-29.629c-4.686-15.849-5.376-37.203-5.376-37.203s2.02-16.505 32.255-5.511z"
          fill="#F0898B"
        />
        <path
          opacity={0.1}
          d="M261.156 149.387s21.618-1.842 27.008 5.6l-27.008-5.6zM204.861 181.315s15.682-15.363 24.436-13.042l-24.436 13.042zM219.824 127.068s1.388 3.478 3.041 8.341c4.604 3.75 10.259 5.889 16.135 6.105 5.877.217 11.667-1.502 16.522-4.903-.165-4.57.032-9.146.589-13.684l-36.287 4.141z"
          fill="#72351C"
        />
        <path
          d="M237.964 141.54c15.218 0 27.555-12.648 27.555-28.251 0-15.602-12.337-28.25-27.555-28.25s-27.554 12.648-27.554 28.25c0 15.603 12.336 28.251 27.554 28.251z"
          fill="#FDB797"
        />
        <path
          opacity={0.1}
          d="M266.331 90.344c-1.438-5.158-3.867-14.685-8.955-15.879a22.95 22.95 0 01-3.277-.737c-2.07-.817-3.486-2.748-5.197-4.192-3.787-3.206-10.298.84-15.185.324-4.593-.486-9.408-.818-13.655.987-8.546 3.618-11.801 14.074-17.968 21.163-4.592 5.305-10.852 8.702-16.666 12.526s-11.506 8.555-14.029 15.171c-1.76 4.679-1.717 9.859-1.3 14.862a105.695 105.695 0 0011.225 39.223c1.79 3.485 3.788 6.919 4.672 10.75.884 3.832.438 8.238-2.156 11.104-3.709 4.046-10.019 3.596-15.035 5.703 4.14 4.34 10.306 6.256 16.213 5.998 5.908-.258 11.6-2.52 16.739-5.541 13.231-7.788 23.256-20.764 28.747-35.368 2.537-6.786 4.147-14.236 2.602-21.324-1.33-6.094-4.851-11.392-7.812-16.845-2.961-5.452-5.491-11.612-4.542-17.78.855-5.555 4.513-10.367 9.048-13.535 3.751-2.616 10.399-6.197 15.092-5.983 5.297.22 22.876 4.516 21.439-.627z"
          fill="#72351C"
        />
        <path
          d="M265.655 89.659c-1.437-5.158-3.866-14.685-8.955-15.879a22.995 22.995 0 01-3.277-.737c-2.07-.818-3.486-2.748-5.196-4.193-3.788-3.205-10.299.84-15.186.325-4.592-.487-9.407-.818-13.655.987-8.545 3.618-11.801 14.074-17.967 21.162-4.592 5.305-10.852 8.702-16.666 12.527-5.815 3.824-11.55 8.554-14.029 15.171-1.761 4.679-1.718 9.859-1.301 14.862a105.705 105.705 0 0011.226 39.222c1.789 3.486 3.787 6.919 4.671 10.751.884 3.831.439 8.238-2.156 11.104-3.708 4.045-10.018 3.596-15.035 5.703 4.14 4.34 10.306 6.256 16.214 5.998 5.908-.258 11.6-2.52 16.738-5.541 13.231-7.788 23.257-20.764 28.748-35.368 2.537-6.787 4.146-14.236 2.601-21.325-1.329-6.093-4.851-11.391-7.812-16.844-2.961-5.452-5.491-11.612-4.542-17.78.855-5.556 4.513-10.367 9.048-13.536 3.752-2.615 10.4-6.196 15.093-5.983 5.304.221 22.875 4.51 21.438-.626z"
          fill="#252223"
        />
        <path
          opacity={0.1}
          d="M293.404 264.519s-12.772 8.267-27.555 6.889l27.555-6.889zM306.879 277.605s-32.93 13.094-45.694 11.716l45.694-11.716zM219.487 243.851s35.618 3.448 41.683-3.449c0 0 19.491 6.204 32.255 0M215.821 270.369l-36.207 47.652-1.452 7.442 37.659-55.094z"
          fill="#72351C"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={294.769}
          y1={172.237}
          x2={294.769}
          y2={76.2558}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1={112647}
          y1={104254}
          x2={112647}
          y2={42110.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1={9975.87}
          y1={16328.6}
          x2={9975.87}
          y2={15068.8}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1={19910.9}
          y1={14292.7}
          x2={19910.9}
          y2={13065.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1={11633.7}
          y1={40539.1}
          x2={11633.7}
          y2={36974.3}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1={99517.6}
          y1={27872.7}
          x2={99517.6}
          y2={24817.9}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1={99517.6}
          y1={27872.7}
          x2={99517.6}
          y2={24817.9}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1={23872.8}
          y1={102631}
          x2={23872.8}
          y2={74827.7}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1={30424.3}
          y1={162529}
          x2={30424.3}
          y2={106787}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1={30293.1}
          y1={162529}
          x2={30293.1}
          y2={106787}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1={14924.1}
          y1={42535.9}
          x2={14924.1}
          y2={39326.6}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1={101982}
          y1={108062}
          x2={101982}
          y2={57526.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1={101952}
          y1={107690}
          x2={101952}
          y2={57154.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1={14360.1}
          y1={1586.42}
          x2={14360.1}
          y2={1538.01}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1={10775.7}
          y1={3958.29}
          x2={10775.7}
          y2={3705}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1={17089.9}
          y1={4367.56}
          x2={17089.9}
          y2={3871.02}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint16_linear"
          x1={19271.8}
          y1={10854.6}
          x2={19271.8}
          y2={6272.79}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint17_linear"
          x1={42464.2}
          y1={45003.6}
          x2={42464.2}
          y2={17222.3}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint18_linear"
          x1={42363.4}
          y1={44865.5}
          x2={42363.4}
          y2={17084.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint19_linear"
          x1={14850.7}
          y1={3188.69}
          x2={14850.7}
          y2={3117.45}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint20_linear"
          x1={24763}
          y1={5484.86}
          x2={24763}
          y2={5284.49}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <linearGradient
          id="paint21_linear"
          x1={36696.9}
          y1={1860.69}
          x2={36696.9}
          y2={1834.06}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H700V609H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IllustrationGirlInfluencer;
