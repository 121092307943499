import { Skeleton, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useMemo, useState } from "react";
import { elitMakeFooterRenderer } from "./elitTableRenderers";
import { TaxTeritoryType, TAX_INCLUSION } from "~/lib/taxes";
import classes from "./ExpenseLineItem.module.css";
import { PaymentDetails } from "~/feature/expense/ExpenseModal";
import usePersistantCallbackRef from "~/lib/hook/usePersistantCallbackRef";
import { ExpenseLineItemRecord } from "./ExpenseLineItemTypes";
import {
  CurrencyInfo,
  LineItemError,
} from "~/fragment/line-items/LineItemTypes";
import {
  useCESSRateList,
  useGSTRateList,
} from "~/lib/hook/api-hook/picklistHooks";
import {
  litAddNewButton,
  litCESSRateInput,
  litCurrencyInput,
  litDeleteNewButton,
  litGSTRateInput,
  litInput,
  litTextArea,
  litUnitInput,
} from "~/fragment/line-items/litTableRenderers";
import { getGST, TaxInfo } from "~/lib/taxCalculation";
import { set } from "lodash";
import { GST_OUT_OF_SCOPE } from "~/lib/constants";
import { CESSRateItem, GSTRate } from "~/api/expense";
import usePrevProps from "~/lib/hook/usePrevProps";

// Set this as key of column def if you want to remove for a condition
const SPCL_REMOVED_COLUMN = "__spcl_removed";

const DEFAULT_GST_RATE_PERCENTAGE = 18;
const DEFAULT_GST_RATE_PERCENTAGE_ID = 9;

interface HistoryData {
  netTotal: string | number;
  // FIXME: fix spelling error
  tdsAmont: string | number;
  // FIXME: remove any, use properly defined type
  paymentDetails: any;
  expenseId: number | null;
  conversionRate: number | undefined;
  onRefresh: () => void;
}
interface Props {
  readOnly?: boolean;
  hasCess?: boolean;
  rowData?: Array<ExpenseLineItemRecord>;
  isLoading?: boolean;
  onRowDataChange?: (
    newRowData: Array<ExpenseLineItemRecord>,
    deletedRow?: ExpenseLineItemRecord
  ) => void;
  tax_inclusion?: TAX_INCLUSION;
  taxTeritoryType?: TaxTeritoryType;
  currency?: CurrencyInfo;
  paymentDetails?: PaymentDetails;
  paymentHistoryData?: HistoryData;
  tdsInfo: {
    tdsRate?: number;
    tdsAmount?: number;
  };
  vendorUnregFlag: boolean;
  totalPayAmount?: number;
  isCopy: boolean;
  sameState?: boolean;
  netTotal?: any;
  isDirtyDescription?: (dirty: boolean) => void;
}

export const SKELETON_ROW: ExpenseLineItemRecord = {
  description: "",
  hsn_no: "",
  amount: 0,
  cgst: 0,
  sgst: 0,
  igst: 0,
  gst_rate_id: DEFAULT_GST_RATE_PERCENTAGE_ID,
  cess_rate_id: 0,
  quantity: 0,
  rate: 0,
  unit_id: null,
  is_active: true,
  row_details_id: 0,
  row_docs_id: undefined,
  documents: undefined,
  document_name: undefined,
  net_total: undefined,

  extra: {
    errors: [],
    gstRate: undefined,
    cessRate: undefined,
  },
};

const fillTaxColumns = (
  row: ExpenseLineItemRecord,
  taxInclusion: TAX_INCLUSION,
  taxTeritoryType: TaxTeritoryType
) => {
  const taxInfo: TaxInfo = {
    cessPercentage: row.extra?.cessRate?.cessPercentage || 0,
    gstPercentage: row.extra?.gstRate?.rate || 0,
    taxInclusion,
    taxTeritoryType,
  };
  // let am=
  row.amount = (row.rate ?? 0) * (row.quantity ?? 0);
  const taxAmounts = getGST(row.amount, taxInfo);
  row.igst = taxAmounts.igst;
  row.sgst = taxAmounts.sgst;
  row.cgst = taxAmounts.cgst;
  return row;
};

/**
 * ExpenseLineItem validation logic
 * This function inspect row & updates its error
 * WARNING: this mutates the row. but replaces the errors array!
 */
export const validateColumns = (
  row: ExpenseLineItemRecord
  // allLiProps: Readonly<Props | Expense>
) => {
  const errors: Array<LineItemError> = [];

  if (!row.quantity) {
    errors.push({
      dataIndex: "quantity",
      message: "Please enter quantity",
    });
  }
  if (!row.rate) {
    errors.push({
      dataIndex: "rate",
      message: "Please enter rate",
    });
  }

  if (!row.amount) {
    errors.push({
      dataIndex: "amount",
      message: "Please enter a positive amount",
    });
  }
  if (!row.description?.trim()) {
    errors.push({
      dataIndex: "description",
      message: "Please enter a description",
    });
  }
  if (!row.extra) {
    row.extra = { errors };
  }
  row.extra.errors = errors;
  return row;
};

const ExpenseLineItem = (props: Props) => {
  const prevProps = usePrevProps(props);
  const { isGSTListLoading, gstRateList } = useGSTRateList();
  const { isCESSListLoading, cessRateList } = useCESSRateList();

  // Combined loading flag
  const isLoading = isGSTListLoading || isCESSListLoading || props.isLoading;
  // needed for showing plus & delete row buttons
  const lastRowIndex = (props.rowData?.length ?? 0) - 1;

  const handleRemoveRow = usePersistantCallbackRef((index: number) => {
    if (props.rowData) {
      const rowTobeDeleted = props.rowData[index];
      props.onRowDataChange?.(
        props.rowData.filter((_, indx) => indx !== index),
        rowTobeDeleted.row_details_id! >= 1 ? rowTobeDeleted : undefined
      );
    }
  });

  const handleAddRow = usePersistantCallbackRef(() => {
    const newRow = {
      ...SKELETON_ROW,
      extra: { ...SKELETON_ROW.extra! },
      row_details_id: Math.random(),
    };
    let defaultGst: GSTRate | undefined;
    if (props.tax_inclusion === "OutOfTax" && props.vendorUnregFlag) {
      defaultGst = gstRateList.find((it) => it.id === GST_OUT_OF_SCOPE);
    } else {
      defaultGst = gstRateList.find(
        (it) => it.rate === DEFAULT_GST_RATE_PERCENTAGE
      );
    }
    if (!defaultGst) {
      return;
    }
    newRow.gst_rate_id = defaultGst.id;
    newRow.extra!.gstRate = defaultGst;
    props.onRowDataChange?.([...(props.rowData ?? []), newRow]);
  });

  const handleTaxInfoChange = usePersistantCallbackRef(() => {
    if (props.rowData) {
      const newRowData = props.rowData.map((row) => {
        const newRow = { ...row };
        if (!newRow.extra) {
          newRow.extra = { errors: [] };
        }
        let gstRateRecord: GSTRate | undefined;
        let cessRateRecord: CESSRateItem | undefined;
        if (props.tax_inclusion === "OutOfTax" && props.vendorUnregFlag) {
          gstRateRecord = gstRateList.find((it) => it.id === GST_OUT_OF_SCOPE);
          newRow.gst_rate_id = gstRateRecord!.id;
        } else if (props.tax_inclusion === "OutOfTax") {
          gstRateRecord = gstRateList.find((it) => it.id === GST_OUT_OF_SCOPE);
          newRow.gst_rate_id = gstRateRecord!.id;
        } else {
          if (
            newRow.gst_rate_id === GST_OUT_OF_SCOPE &&
            prevProps.tax_inclusion === "OutOfTax"
          ) {
            gstRateRecord = gstRateList.find(
              (it) => it.rate === DEFAULT_GST_RATE_PERCENTAGE
            );
            newRow.gst_rate_id = gstRateRecord!.id;
          } else {
            gstRateRecord = gstRateList.find(
              (it) => it.id === newRow.gst_rate_id
            );
          }
        }
        if (
          props.tax_inclusion === "OutOfTax" ||
          !props.hasCess ||
          (props.vendorUnregFlag && newRow.cess_rate_id === 0)
        ) {
          cessRateRecord = cessRateList.find((it) => it.cessPercentage === 0);
          newRow.cess_rate_id = cessRateRecord?.id;
        } else {
          cessRateRecord = cessRateList.find(
            (it) => it.id === newRow.cess_rate_id
          );
        }
        newRow.extra!.gstRate = gstRateRecord;
        newRow.extra!.cessRate = cessRateRecord;
        return fillTaxColumns(
          newRow,
          props.tax_inclusion ?? "OutOfTax",
          props.taxTeritoryType ?? TaxTeritoryType.OTHER_TERITORY
        );
      });
      // console.log('handleTaxInfoChange...props.onRowDataChange', props.onRowDataChange, newRowData);
      props.onRowDataChange?.(newRowData);
    }
  });

  useEffect(() => {
    if (lastRowIndex < 0 && !isLoading) {
      handleAddRow();
    }
  }, [lastRowIndex, isLoading, handleAddRow]);

  useEffect(() => {
    if (!isLoading) {
      handleTaxInfoChange();
    }
    // these deps are handled in handleTaxInfoChange
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tax_inclusion, props.taxTeritoryType, props.hasCess, isLoading]);

  const handleCellChange = usePersistantCallbackRef(
    (propertyPath: string, index: number, value: unknown, record?: unknown) => {
      const rowToBeChanged = props.rowData![index];
      const firstHalf = props.rowData!.slice(0, index);
      const secondHalf = props.rowData!.slice(index + 1);

      const newRow = set({ ...rowToBeChanged }, propertyPath, value);
      if (record) {
        if (propertyPath === "gst_rate_id") {
          set(newRow, "extra.gstRate", record);
        } else if (propertyPath === "cess_rate_id") {
          set(newRow, "extra.cessRate", record);
        } else if (propertyPath === "unit_id") {
          set(newRow, "extra.unitItem", record);
        }
      }

      const updatedRow = fillTaxColumns(
        newRow,
        props.tax_inclusion ?? "OutOfTax",
        props.taxTeritoryType ?? TaxTeritoryType.OTHER_TERITORY
      );
      const newRowData = [...firstHalf, updatedRow, ...secondHalf];
      if (propertyPath === "description") {
        props.isDirtyDescription?.(true);
      }
      props.onRowDataChange?.(newRowData);
    }
  );

  const columnDef = useMemo(() => {
    const _calculatedColumnDef: ColumnsType<ExpenseLineItemRecord> = [
      {
        title: "",
        dataIndex: "row_details_id",
        width: 40,
        render: litAddNewButton({
          lastRowIndex,
          readOnly: props.readOnly,
          onNewRow: handleAddRow,
        }),
      },

      {
        title: (
          <span>
            <span style={{ color: "#EB5757" }}>*</span> Description
          </span>
        ),
        dataIndex: "description",
        render: litTextArea({
          fieldName: "description",
          readOnly: props.readOnly,
          onChange: handleCellChange,
        }),
      },

      {
        title: "HSN",
        dataIndex: "hsn_no",
        width: 110,
        render: litInput({
          fieldName: "hsn_no",
          readOnly: props.readOnly,
          onChange: handleCellChange,
        }),
      },
      {
        title: (
          <span>
            <span style={{ color: "#EB5757" }}>*</span> Qty
          </span>
        ),
        dataIndex: "quantity",
        align: "right",
        width: 120,
        render: litCurrencyInput({
          fieldName: "quantity",
          disabled: false,
          readOnly: props.readOnly,
          onChange: handleCellChange,
        }),
      },
      {
        title: "Unit",
        dataIndex: "unit_id",
        width: 100,

        render: litUnitInput({
          fieldName: "unit_id",
          readOnly: props.readOnly,
          onChange: handleCellChange,
        }),
      },
      {
        title: (
          <span>
            <span style={{ color: "#EB5757" }}>*</span> Rate
          </span>
        ),
        dataIndex: "rate",
        align: "right",
        width: 120,
        render: litCurrencyInput({
          fieldName: "rate",
          disabled: false,
          readOnly: props.readOnly,
          onChange: handleCellChange,
        }),
      },

      {
        title: "Amount",
        dataIndex: "amount",
        align: "right",
        width: 120,
        render: litCurrencyInput({
          fieldName: "amount",
          disabled: false,
          readOnly: props.readOnly,
          onChange: handleCellChange,
        }),
      },
      {
        title: "CGST/SGST",
        dataIndex: "cgst",
        align: "right",
        key: "cgst",
        width: 120,
        render: litCurrencyInput({
          fieldName: "cgst",
          disabled: false,
          readOnly: true,
          onChange: handleCellChange,
        }),
      },

      {
        title: "IGST",
        dataIndex: "igst",
        align: "right",
        key: "igst",
        width: 100,
        render: litCurrencyInput({
          fieldName: "igst",
          disabled: false,
          readOnly: true,
          onChange: handleCellChange,
        }),
      },
      {
        title: "Tax rate",
        dataIndex: "gst_rate_id",
        render: litGSTRateInput({
          fieldName: "gst_rate_id",
          readOnly: props.readOnly,
          disabled: props.tax_inclusion === "OutOfTax",
          onChange: handleCellChange,
        }),
      },
      {
        title: "CESS",
        dataIndex: "cess_rate_id",
        render: litCESSRateInput({
          fieldName: "cess_rate_id",
          readOnly: props.readOnly,
          onChange: handleCellChange,
        }),
        key:
          !props.hasCess || props.vendorUnregFlag
            ? SPCL_REMOVED_COLUMN
            : "cess_rate_id",
      },
      {
        title: "",
        // dataIndex: "id",
        key: "delete_action",
        width: 40,
        render: litDeleteNewButton({
          lastRowIndex,
          readOnly: props.readOnly,
          onDeleteRow: handleRemoveRow,
        }),
      },
    ];

    return _calculatedColumnDef.filter(
      (col) =>
        col.key !== SPCL_REMOVED_COLUMN &&
        (props.sameState ? col.key !== "igst" : col.key !== "cgst")
    );
  }, [
    lastRowIndex,
    props.readOnly,
    props.tax_inclusion,
    props.vendorUnregFlag,
    props.hasCess,
    props.sameState,
    handleAddRow,
    handleCellChange,
    handleRemoveRow,
  ]);
  return (
    <Skeleton
      loading={isLoading}
      paragraph={{
        rows: 3,
        width: "70%",
      }}
    >
      <Table
        rowKey="row_details_id"
        className={classes.table}
        columns={columnDef}
        dataSource={props.rowData}
        footer={elitMakeFooterRenderer({
          currency: props.currency,
          hasCess: props.hasCess,
          taxInclusion: props.tax_inclusion,
          taxTeritoryType: props.taxTeritoryType,
          paymentHistoryData: props.paymentHistoryData,
          tdsInfo: props.tdsInfo,
          totalPayAmount: props.totalPayAmount!,
          isCopy: props.isCopy,
          netTotal: props.netTotal,
        })}
        pagination={false}
      />
    </Skeleton>
  );
};

export default ExpenseLineItem;
