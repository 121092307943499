import { fetchUtil } from "./common";

type CreateSubscriptionBody = {
  name: string;
  mrp_amount: string | number;
  plan_id: string;
  license_details_id: number;
  currency: string;
  // id: string;
};

// subscription
export const createSubscription = async (body: CreateSubscriptionBody) => {
  return await fetchUtil("POST", "/create_subscription", body);
};

export const getPlanFeature = async () => {
  return await fetchUtil("GET", "/get_plan_features");
};
export const getExistingPlanFeature = async () => {
  return await fetchUtil("GET", "/get_existing_plan_details");
};
