// TODO: rename/merge(with AttachableField) this componet
import React, { PureComponent, ReactNode } from "react";
import { Row, Form, message as antMessage, Button, Spin } from "antd";
import { Rule } from "antd/lib/form";
import cx from "classnames";

import { FilePdfOutlined } from "@ant-design/icons";

import { getSignedUploadURL, getSignedDownloadURL } from "~/api/common";

import commonStyle from "~/component/common.module.css";
import styles from "./OnlyAttachField.module.css";
import Modal from "antd/lib/modal/Modal";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";
interface Props {
  className?: string;
  label: string;
  fieldName: string;
  hashFileName?: string;
  onHashFileChange: (newHashFile: string, documents_name: string) => void;
  rules: Array<Rule>;
  readonly?: boolean;
  children?: ReactNode;
  centered?: boolean;
  accept?: string;
}

interface State {
  previewURL?: string;
  documents_name?: string;
  loadDoc?: boolean;
}

class OnlyAttachField extends PureComponent<Props, State> {
  state: State = {
    previewURL: undefined,
    documents_name: "",
    loadDoc: false,
  };

  handleAttach = (_e: React.MouseEvent) => {
    // if (!this.props.readonly) {
    //   return;
    // }
    // registerEvent(
    //   ga.EVENT_CATEGORY_BUTTON_CLICK,
    //   ga.EVENT_CLICK,
    //   ga.events.onlyAttachField264
    // );

    const $i = document.createElement("input") as HTMLInputElement;
    $i.type = "file";
    if (this.props.accept) {
      $i.accept = this.props.accept;
    }
    $i.onchange = async (e) => {
      const file = $i.files?.length ? $i.files[0] : null;
      if (file) {
        this.setState({
          loadDoc: true,
        });
        const { ok, message, data } = await getSignedUploadURL(file.name);
        this.setState({
          documents_name: file.name,
        });
        if (!ok) {
          antMessage.warning({ message });
        } else {
          const { hashedFileName, url } = data!;
          // TODO: show progress
          const res = await fetch(url, {
            method: "PUT",
            headers: {
              "content-type": file.type,
            },
            body: file,
          });
          if (!res.ok) {
            antMessage.error({ message: "Failed to upload file" });
            this.setState({
              loadDoc: false,
            });
          } else {
            this.props.onHashFileChange(
              hashedFileName,
              this.state.documents_name!
            );
            this.setState({
              loadDoc: false,
            });
          }
        }
      }
    };
    $i.click();
  };

  handleDelete = async (_e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.onlyAttachField265
    );

    this.props.onHashFileChange("", "");
  };

  handlePreview = async (_e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.onlyAttachField266
    );

    const { ok, message, url } = await getSignedDownloadURL(
      this.props.hashFileName!
    );
    if (!ok) {
      antMessage.error({ message });
    } else {
      this.setState({
        previewURL: url,
      });
    }
  };

  handlePreviewCancel = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.onlyAttachField267
    );
    this.setState({
      previewURL: undefined,
    });
  };

  renderAttachFile() {
    return !this.props.hashFileName ? (
      this.props.centered ? (
        <div
          style={{
            margin: "auto",
          }}
        >
          <b className={styles.attachLabel} onClick={this.handleAttach}>
            + Attach file
          </b>
        </div>
      ) : (
        <b className={styles.attachLabel} onClick={this.handleAttach}>
          + Attach file
        </b>
      )
    ) : this.props.label === "Challan receipt" ? (
      "Challan receipt"
    ) : this.props.centered ? (
      <div style={{ margin: "auto" }}>Upload Document</div>
    ) : (
      "Upload Document"
    );
  }

  render() {
    return (
      <Spin spinning={this.state.loadDoc}>
        {" "}
        <Row align="bottom" justify="center" className={this.props.className}>
          <Form.Item
            className={cx(styles.attatchableField, this.props.className)}
            name={this.props.fieldName}
            label={
              <Row>
                {this.props.readonly === true ? null : this.props
                    .hashFileName ? (
                  <span>{this.props.label}</span>
                ) : (
                  <></>
                )}
                {this.props.readonly === true ? this.renderAttachFile() : null}
              </Row>
            }
            labelCol={{
              span: 24,
            }}
            rules={this.props.rules}
          />

          <FilePdfOutlined
            className={
              this.props.hashFileName ? styles.fileIconBox : commonStyle.hidden
            }
            role="button"
            onClick={this.handlePreview}
            style={
              this.props.label === "Uploaded document"
                ? !this.props.readonly
                  ? { color: "green" }
                  : { color: "black" }
                : { color: "black" }
            }
            // color={this.props.label === "Uploaded document" ? "green" : "black"}
          />
          {this.props.hashFileName && this.props.readonly === true ? (
            <b
              className={styles.deleteBtn}
              onClick={this.handleDelete}
              role="button"
            >
              Delete
            </b>
          ) : null}

          {!!this.state.previewURL ? (
            <Modal
              footer={<Button onClick={this.handlePreviewCancel}>Close</Button>}
              width={Math.floor(window.innerWidth * 0.9)}
              className={styles.previewModal}
              visible={true}
              onCancel={this.handlePreviewCancel}
              destroyOnClose
              zIndex={1200}
              centered
            >
              <iframe
                title="Attatchment modal"
                className={styles.previewFrame}
                src={this.state.previewURL}
              />
            </Modal>
          ) : null}
        </Row>
      </Spin>
    );
  }
}

export default OnlyAttachField;
