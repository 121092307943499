import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Prompt } from "react-router";
import { Tabs, notification } from "antd";

import {
  APIState,
  fetchUtil,
  updateAdminBank,
  addAdminBank,
} from "~/api/common";
import { AdminDetail, getStates } from "~/api/profile";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";

import PrimaryDetailsPane from "./DetailsTabPane/PrimaryDetailsPane";
import CompanyDetailsPane from "./DetailsTabPane/CompanyDetailsPane";
import OfficialDetailsPane from "./DetailsTabPane/OfficialDetailsPane";
import AdminDetailsPane from "./DetailsTabPane/AdminDetailsPane";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";
const { TabPane } = Tabs;

const TabKeys = ["primary", "company", "adminDetails", "official"] as const;
type TabTypes = typeof TabKeys[number];
interface State {
  tab: TabTypes;
}

interface HandleAddResponse {
  ok: boolean;
  message: string;
}

const mapStateToProps = (state: AuthState) => ({
  userDetail: state.mainAppSlice.user,
  steps: state.mainAppSlice.steps,
  activeRole: state.mainAppSlice.user.activeRole,
  userFirstChar: state.mainAppSlice.user.email.substr(0, 1).toUpperCase(),
  showSyncButtonTour: state.mainAppSlice.showSyncButtonTour,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  createCompanyDetails: (company_name: any) =>
    dispatch(action.auth.createCompanyDetails(company_name)),
  setPlaceOfSupply: (place_of_supply: any) =>
    dispatch(action.auth.setPlaceOfSupply(place_of_supply)),
  setUserName: (userName: any) => dispatch(action.auth.setUserName(userName)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

// const connector = connect(() => ({}), {
//   setPlaceOfSupply: action.auth.setPlaceOfSupply,
// });
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  history: any;
}
interface State {
  apiState: APIState;
  profileData: AdminDetail;
  isDirty: boolean;
  stateList: Array<STATEList> | undefined;
}

type STATEList = {
  id?: number;
  state_name?: string;
};

class ProfilePage extends Component<Props, State> {
  state: State = {
    apiState: "loading",
    profileData: {
      establishment_type: 0,
      gstin_registration_date: new Date(),
      bank_details: [],
    },
    tab: "primary",
    isDirty: true,
    stateList: [],
  };

  componentDidMount() {
    this.loadData();
    this.loadStates();
    const query = new URLSearchParams(window.location.search);
    const tab = query.get("tab");
    if (tab && TabKeys.includes(tab as TabTypes)) {
      this.setState({ tab: tab as TabTypes });
    }
    this.props.history.listen((location: any, _action: any) => {
      const query = new URLSearchParams(location.search);
      const newTab = query.get("tab");

      if (newTab && TabKeys.includes(newTab as TabTypes)) {
        this.setState({ tab: newTab as TabTypes });
      }
    });
  }

  loadData = async () => {
    const { ok, message, json } = await fetchUtil("GET", "/get_admin_details");
    if (!ok) {
      notification.error({ message });
      this.setState({
        apiState: "idle",
      });
    } else {
      const data = json[0];
      if (typeof data.company_pincode === "number") {
        data.company_pincode = "" + data.company_pincode;
      }
      for (const key in data) {
        if (key.includes("date") || key.includes("_time")) {
          if (data[key]) {
            data[key] = new Date(data[key]);
          }
        }
      }
      const profileData = data as AdminDetail;
      // console.log(profileData);
      this.setState({
        profileData,
        apiState: "idle",
        isDirty: false,
      });
    }
  };

  handleTanVerification = (isTanVerify: boolean, tan_no?: string) => {
    // console.log(isTanVerify);
    this.setState(
      {
        profileData: {
          ...this.state.profileData,
          tan_verified: isTanVerify,
        },
      },
      () => {
        // if (isTanVerify) {
        this.setState({
          profileData: {
            ...this.state.profileData,
            tan: tan_no,
          },
        });
        // }
      }
    );
  };

  loadStates = async () => {
    const [states] = await Promise.all([getStates()]);
    if (!states.ok) {
      notification.error({
        message: "Failed to load states",
        description: states.message,
      });
    } else {
      this.setState({
        stateList: states.data,
      });
    }
  };

  saveData = async () => {
    const { profileData } = this.state;
    const { place_of_supply } = profileData;
    // profileData.legal_name = profileData.company_name;
    if (!profileData.tan_verified) {
      this.setState(
        {
          profileData: {
            ...profileData,
            // tan: "",
          },
        },
        async () => {
          if (place_of_supply) this.props.setPlaceOfSupply(place_of_supply);
          this.setState({
            apiState: "loading",
          });

          profileData.name = this.state.profileData.company_name;
          const { ok, message } = await fetchUtil(
            "POST",
            "/update_company",
            profileData
          );
          if (!ok) {
            notification.error({
              message: "Failed to save changes",
              description: message,
            });
            this.setState({
              apiState: "error",
            });
          } else {
            notification.success({
              message: message || "Successfully saved changes",
            });
            this.setState({
              apiState: "success",
              isDirty: false,
            });
            let ud: any = {};
            const userData: any = localStorage.getItem("imt__user");
            if (userData !== null) {
              ud = JSON.parse(userData);
              ud.logo = profileData.company_logo;
              ud.gstin = profileData.gstin;
              ud.company_name = profileData.company_name;
              ud.company_id = ud.company_details[0].id;
              ud.address = profileData.address;
              ud.authorised_capital = profileData.authorised_capital;
              ud.paid_up_capital = profileData.paid_up_capital;
              ud.directors = profileData.directors;
              ud.company_status = profileData.company_status;
              ud.pending_charges = profileData.pending_charges;
              ud.place_of_supply = profileData.place_of_supply;
              ud.first_name = profileData.first_name;
              ud.last_name = profileData.last_name;
              ud.gst_user_name = profileData.gst_user_name;
              ud.udyam_regd_no = Number(profileData.udyam_regd_no);

              // ud = Object.assign({}, userData, ud);
              // console.log("[1] ud = ", ud);
              localStorage.setItem("imt__user", JSON.stringify(ud));
              const userName = {
                first_name: profileData.first_name,
                last_name: profileData.last_name,
                gstin: profileData.gstin,
              };
              this.props.createCompanyDetails(profileData.company_name);
              this.props.setUserName(userName);
            }
          }
        }
      );
    } else {
      if (place_of_supply) this.props.setPlaceOfSupply(place_of_supply);
      this.setState({
        apiState: "loading",
      });

      profileData.name = this.state.profileData.company_name;
      const { ok, message } = await fetchUtil(
        "POST",
        "/update_company",
        profileData
      );
      if (!ok) {
        notification.error({
          message: "Failed to save changes",
          description: message,
        });
        this.setState({
          apiState: "error",
        });
      } else {
        notification.success({
          message: message || "Successfully saved changes",
        });
        this.setState({
          apiState: "success",
          isDirty: false,
        });
        let ud: any = {};
        const userData: any = localStorage.getItem("imt__user");
        if (userData !== null) {
          ud = JSON.parse(userData);
          ud.logo = profileData.company_logo;
          ud.company_name = profileData.company_name;
          ud.address = profileData.address;
          ud.authorised_capital = profileData.authorised_capital;
          ud.paid_up_capital = profileData.paid_up_capital;
          ud.directors = profileData.directors;
          ud.company_status = profileData.company_status;
          ud.pending_charges = profileData.pending_charges;
          ud.place_of_supply = profileData.place_of_supply;
          ud.first_name = profileData.first_name;
          ud.last_name = profileData.last_name;
          ud.gst_user_name = profileData.gst_user_name;
          ud.udyam_regd_no = Number(profileData.udyam_regd_no);
          // ud = Object.assign({}, userData, ud);
          // localStorage.setItem("imt__user", JSON.stringify(ud));
          // console.log("[2] ud = ", ud);

          localStorage.setItem("imt__user", JSON.stringify(ud));
          const userName = {
            first_name: profileData.first_name,
            last_name: profileData.last_name,
          };
          this.props.createCompanyDetails(profileData.company_name);
          this.props.setUserName(userName);
        }
      }
    }
  };

  handleDataChange = (newData: AdminDetail) => {
    if (
      newData.gst_registration_type === 3 ||
      newData.gst_registration_type === 4 ||
      newData.gst_registration_type === 5
    ) {
      newData.gstin = "";
    }
    this.setState({
      profileData: {
        ...this.state.profileData,
        ...newData,
      },
    });
  };

  handlesubmit = (newData: AdminDetail) => {
    // console.log(newData)
    this.setState(
      {
        profileData: {
          ...this.state.profileData,
          ...newData,
        },
      },

      this.saveData
    );
  };
  handleUpdateAdminBank = async (val: any): Promise<HandleAddResponse> => {
    const { ok, message } = await updateAdminBank(val);
    if (ok) {
      this.loadData();
    }
    return { ok, message };
  };
  handleAddAdminBank = async (val: any): Promise<HandleAddResponse> => {
    const { ok, message } = await addAdminBank(val);
    if (ok) {
      this.loadData();
    }
    return { ok, message };
  };

  handleTabChange = (tab: string) => {
    // Check if the selected tab is a valid tab key before updating the state and URL
    if (TabKeys.includes(tab as TabTypes)) {
      // Update the URL with the new 'tab' parameter without triggering a page reload
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set("tab", tab);
      window.history.pushState({ path: newUrl.href }, "", newUrl.href);

      // Update the 'tab' state to reflect the selected tab
      this.setState({ tab: tab as TabTypes });
    }
  };
  handleDirty = () => {
    this.setState({
      isDirty: true,
    });
  };

  render() {
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get("tab");
    const activeKey =
      tab && TabKeys.includes(tab as TabTypes) ? tab : "primary";

    return (
      <IMTContent withoutMargin={true}>
        <Helmet>
          <title>{titles.ProfilePage}</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={["Profile"]}
          style={{ paddingLeft: "0" }}
        />
        <Prompt
          message="Do you want to leave this page?"
          when={this.state.isDirty}
        />
        <Tabs activeKey={activeKey} onChange={this.handleTabChange}>
          <TabPane tab="Basic details" key="primary">
            <PrimaryDetailsPane
              apiState={this.state.apiState}
              data={this.state.profileData}
              handlesubmit={this.handlesubmit}
              onDataChange={this.handleDataChange}
              stateList={this.state.stateList}
              onDirty={this.handleDirty}
            />
          </TabPane>
          <TabPane tab="Tax info" key="company">
            <CompanyDetailsPane
              apiState={this.state.apiState}
              data={this.state.profileData}
              onDataChange={this.handleDataChange}
              handlesubmit={this.handlesubmit}
              stateList={this.state.stateList}
              onDirty={this.handleDirty}
              handleTanVerification={this.handleTanVerification}
            />
          </TabPane>
          <TabPane tab="Admin details" key="adminDetails">
            <AdminDetailsPane
              apiState={this.state.apiState}
              data={this.state.profileData}
              handlesubmit={this.handlesubmit}
              onDataChange={this.handleDataChange}
              onDirty={this.handleDirty}
            />
          </TabPane>
          <TabPane tab="Bank details" key="official">
            <OfficialDetailsPane
              apiState={this.state.apiState}
              data={this.state.profileData.bank_details}
              handleUpdate={this.handleUpdateAdminBank}
              handleAdd={this.handleAddAdminBank}
              onDataChange={this.handleDataChange}
              onDirty={this.handleDirty}
            />
          </TabPane>
        </Tabs>
      </IMTContent>
    );
  }
}

export default connector(ProfilePage);
