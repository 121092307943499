import React from "react";
import { IMTIconProps, IMTIconElement, __colors } from "./interface";

const Reimbursement: IMTIconElement = ({
  highlighted,
  ...props
}: IMTIconProps) => {
  const color = highlighted ? __colors.active : __colors.inactive;
  return (
    <svg x="0px" y="0px" viewBox="0 0 56 56" xmlSpace="preserve" {...props}>
      <style>{".st3{fill:#93989e}"}</style>
      <path
        d="M31.3 3.6C17.8 3.6 6.9 14.6 6.9 28v10l-4.8-4.8c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l6.5 6.5c.2.2.5.3.7.3s.5-.1.7-.3l6.5-6.5c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L8.9 38V28c0-12.3 10-22.4 22.4-22.4s22.4 10 22.4 22.4-10 22.4-22.4 22.4c-.6 0-1 .4-1 1s.4 1 1 1c13.4 0 24.4-10.9 24.4-24.4S44.7 3.6 31.3 3.6z"
        fill={color}
      />
      <path
        d="M37.2 43.9c.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4L25.3 30.4h4.4c4.3 0 7.9-3.3 8.4-7.5h3.4c.6 0 1-.4 1-1s-.4-1-1-1h-3.4c-.3-2.1-1.4-4-2.9-5.3h6.3c.6 0 1-.4 1-1s-.4-1-1-1H22.8c-.6 0-1 .4-1 1s.4 1 1 1h6.9c3.2 0 5.8 2.3 6.3 5.3H22.8c-.6 0-1 .4-1 1s.4 1 1 1h13.3c-.4 3.1-3.1 5.5-6.4 5.5h-6.9c-.4 0-.8.3-.9.6s-.1.8.2 1.1l14.3 13.5c.3.2.5.3.8.3z"
        fill={color}
      />
    </svg>
  );
};

export default Reimbursement;
