import { AutoComplete, notification } from "antd";
import React, { useEffect } from "react";
import { ProductItem } from "~/api/invoice";
import { getProductList } from "~/api/vendor";
import { useProductList } from "~/lib/hook/api-hook/picklistHooks";
import usePersistantCallbackRef from "~/lib/hook/usePersistantCallbackRef";
import ReadOnlyableSelect from "./ReadOnlyableSelect";

interface Props {
  readOnly?: boolean;
  value?: number;
  onChange?: (productId: number, record?: ProductItem) => void;
}

// #region Hackity-hack #202109251137 [cross component signal without framework]
// FIXME: Clean our redux implementation & USE RTK + RTK-Query
const __refreshCallbackBag: Array<(force?: boolean) => void> = [];

export const _FORCE_PRODUCT_LIST_REFRESH = () => {
  __refreshCallbackBag.forEach((fn, i) => fn(i === 0));
};
//#endregion Hackity-hack

const ProductSelect = (props: Props) => {
  const {
    isProductListLoading,
    refreshProductList,
    productList,
  } = useProductList();
  const handleOnChange = usePersistantCallbackRef((productId: number) => {
    // const product = productList.find((it) => it.id === productId);
    // props.onChange?.(productId, product);
  });

  //#region Hackity-hack #202109251137
  useEffect(() => {
    __refreshCallbackBag.push(refreshProductList);
    return () => {
      const toRemoveIdx = __refreshCallbackBag.findIndex(
        (fn) => fn === refreshProductList
      );
      if (toRemoveIdx !== -1) {
        __refreshCallbackBag.splice(toRemoveIdx, 1);
      }
    };
  }, [refreshProductList]);
  //#endregion Hackity-hack

  const MAX_LENGTH = 25;
  const selectedProduct = productList.find((pl) => pl.id === props.value);

  return (
    <>
      <ReadOnlyableSelect
        loading={isProductListLoading}
        readonly={props.readOnly}
        style={{
          width: 180,
        }}
        value={props.value}
        onChange={handleOnChange}
        options={productList.map((it) => ({
          value: it.id,
          label: it.product_name,
        }))}
      />
      {selectedProduct && (
        <div style={{ fontSize: "10px", color: "var(--grey-2)" }}>
          {`${
            selectedProduct.product_name.length > MAX_LENGTH &&
            selectedProduct.description !== selectedProduct.product_name
              ? `${selectedProduct.product_name} | `
              : ""
          }${selectedProduct.description}`}
        </div>
      )}
    </>
  );
};

export default ProductSelect;
