import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Prompt, RouteChildrenProps } from "react-router";
import { Button, notification } from "antd";

import { AuthState } from "~/app/MainApp/store";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import { State as BasicVendorModalState } from "~/component/BasicVendorModal";

import { LoginRole } from "~/api/auth";
import texts from "~/contants/texts";

import usePersistantCallbackRef from "~/lib/hook/usePersistantCallbackRef";
import {
  BulkExpenseError,
  ExpenseValidationError,
  PartialBulkExpenseRecord,
} from "./bulkExpenseTypes";

import classes from "./ExpenseBulkCreatePage.module.less";
import BulkExpenseTable, { CellChangeHandler } from "./BulkExpenseTable";
import { get } from "lodash";
import { saveNewExpense } from "~/api/expense";
import { DraggableModalProvider } from "ant-design-draggable-modal";
import BasicVendorModal from "~/component/BasicVendorModal";
import {
  useCustomerList,
  useExpenseCategoryList,
} from "~/lib/hook/api-hook/picklistHooks";
import AddCategory from "~/component/AddCategory";
import { VendorItem } from "~/api/vendor";
import { sleep } from "~/utils";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";
import { getTotalGST } from "~/lib/taxCalculation";
import { TaxTeritoryType } from "~/lib/taxes";
import { OTHER_TERITORY_ID } from "~/lib/constants";

type VendorInit = Partial<BasicVendorModalState>;

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  myPlaceOfSupply: state.mainAppSlice.user.place_of_supply,
  myGSTIN: state.mainAppSlice.user.gstin,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PathParams = {};

type LocationState = { search?: string };

type Row = Array<PartialBulkExpenseRecord>;

interface IProps
  extends RouteChildrenProps<PathParams, LocationState>,
    PropsFromRedux {}

/**
 *
 */
const ExpenseBulkCreatePage: React.FC<IProps> = ({
  myPlaceOfSupply,
  activeRole,
}) => {
  const [isDirty, setDirty] = React.useState<boolean>(false);
  const [rows, setRows] = React.useState<Row>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<BulkExpenseError>(new Map());

  const [mVisVendor, setmVisVendor] = React.useState<boolean>(false);
  const [mVisCategory, setmVisCategory] = React.useState<boolean>(false);
  const [lastVendorInit, setLastVendorInit] = React.useState<VendorInit>();

  const { forceFetch: refreshCustomerList, customerList } = useCustomerList();
  const { forceFetch: refeshExpenseCategoryList } = useExpenseCategoryList();

  const myVendorModalQueue$ = React.useRef<any>();
  const latestCustomerList$ = React.useRef<Array<VendorItem>>();
  const cellChangeLogic = React.useRef<CellChangeHandler>();

  latestCustomerList$.current = customerList;

  React.useEffect(() => {
    // Object.assign(window as any, {
    //   $$$: {
    //     ...(window as any).$$$,
    //     $layoutRef,
    //   },
    // });

    myVendorModalQueue$.current = (() => {
      let initList: Array<any> = [];
      const gstinIndexes: any = {};

      // const interval = setInterval(() => {}, 1000);

      return {
        add(gstin: string, index: number) {
          const alreadyExists = !!latestCustomerList$.current?.find(
            (it) => it.gstin === gstin
          );
          const alreadyQueued = !!gstinIndexes[gstin];
          if (alreadyQueued) {
            gstinIndexes[gstin].push(index);
          }
          if (alreadyExists || alreadyQueued) {
            return;
          }
          gstinIndexes[gstin] = [index];
          initList.unshift({
            gstin,
            uid: Math.random(),
          });
        },
        handleSuccess(gstin: string) {
          const rowIndexes: Array<number> = gstinIndexes[gstin];
          const vendor = latestCustomerList$.current?.find(
            (it) => it.gstin === gstin
          );
          rowIndexes.forEach((rIdx) => {
            cellChangeLogic.current?.(
              "vendor",
              rIdx,
              vendor?.customer_idx,
              vendor
            );
          });
          // setRows((cRows) =>
          //   cRows.map((row, idx) =>
          //     !rowIndexes.includes(idx)
          //       ? row
          //       : {
          //           ...row,
          //           vendor,
          //         }
          //   )
          // );
        },
        dequeue() {
          if (initList.length === 0) {
            return false;
          }
          sleep(10).then(() => {
            setLastVendorInit(initList.pop());
            setmVisVendor(true);
          });
          return true;
        },
        stop() {
          initList = [];
        },
      };
    })();

    return () => {
      myVendorModalQueue$.current.stop();
    };
  }, []);

  const hasEditAccess = React.useMemo(() => {
    return [LoginRole.ADMIN, LoginRole.SUPERuSER].includes(activeRole);
  }, [activeRole]);

  const handleVendorAdd = usePersistantCallbackRef(
    async (customerIdx?: string, withGst?: boolean, gstinNo?: string) => {
      // console.log("handleVendorAdd: ", { customerIdx, withGst, gstinNo });

      myVendorModalQueue$.current.dequeue();
      await refreshCustomerList(true);
      if (gstinNo) {
        sleep(100).then(() => {
          myVendorModalQueue$.current.handleSuccess(gstinNo);
        });
      }
    }
  );

  const handleCategoryVisibility = usePersistantCallbackRef(
    async (visible: boolean, catagoryId?: string) => {
      await refeshExpenseCategoryList(true);
      setmVisCategory(visible);
    }
  );

  const handleVendorAddReq = React.useCallback(() => {
    setmVisVendor(true);
  }, []);

  const handleCategoryAddReq = React.useCallback(() => {
    setmVisCategory(true);
  }, []);

  const handleNewGSTIN = React.useCallback((gstin: string, index: number) => {
    myVendorModalQueue$.current?.add(gstin, index);
    myVendorModalQueue$.current?.dequeue();
  }, []);

  const handleRowChange = React.useCallback(
    (newRows: React.SetStateAction<Row>) => {
      setDirty(true);
      // try {
      // console.log("[1] newRows: ", newRows);
      setRows(newRows);
      // } catch (e) {
      //   console.error(e);
      // }
    },
    [setRows]
  );

  // React.useEffect(() => {
  //   console.log("[2] rows updated:", rows);
  // }, [rows]);

  const handleSave = usePersistantCallbackRef(async () => {
    // Validate & set errors
    try {
      const requiredPathsAndMessage = [
        ["invoiceNo", "Invoice no"],
        ["date", "Date"],
        ["description", "Narration"],
        ["placeOfSupplyId", "Place of supply(Supplied from)"],
        ["quantity", "Quantity"],
        ["rate", "Rate"],
        ["amountsAre", "Amounts are"],
        ["category", "Category"],
      ];
      const newErrors: BulkExpenseError = new Map();
      rows.forEach((row, rowIndex) => {
        // tslint:disable-next-line:no-unnecessary-initializer
        let rowError: ExpenseValidationError | undefined = undefined;
        //
        // Check required fields
        for (const [propertyPath, title] of requiredPathsAndMessage) {
          if (!get(row, propertyPath)) {
            rowError = newErrors.get(rowIndex);
            if (!rowError) {
              rowError = new Map();
              newErrors.set(rowIndex, rowError);
            }
            rowError.set(propertyPath, `Please enter ${title}.`);
          }
        }
        if (rowError) {
          row.extra = {
            errors: new Array(rowError.values()),
          };
        }
      });
      // end validation
      if (newErrors.size > 0) {
        notification.warn({
          message: "Please fill the requied fields & try again.",
        });
        setErrors(newErrors);
        return;
      }

      const failedRows: Row = [];

      const rowsSize = rows.length;
      let successCount = 0;
      for (let rowIndex = 0; rowIndex < rowsSize; rowIndex++) {
        const row = rows[rowIndex];
        try {
          const taxes = getTotalGST(
            [
              {
                amount: row.amount ?? 0,
                gstPercentage: row.taxRate?.rate ?? 0,
                cessPercentage: 0,
              },
            ],
            {
              taxInclusion: row.amountsAre ?? "OutOfTax",
              taxTeritoryType:
                !row.placeOfSupplyId ||
                row.placeOfSupplyId === OTHER_TERITORY_ID
                  ? TaxTeritoryType.OTHER_TERITORY
                  : row.placeOfSupplyId === myPlaceOfSupply
                  ? TaxTeritoryType.SAME_STATE
                  : TaxTeritoryType.INTRA_STATE,
            }
          );
          const payload = {
            tax_inclusion: row.amountsAre,
            row_data: [
              {
                description: row.description,
                hsn_no: row.hsnCode,
                amount: (row.rate ?? 0) * (row.quantity ?? 0),
                cgst: taxes.cgst,
                sgst: taxes.sgst,
                igst: taxes.igst,
                gst_rate_id: row.taxRate?.id,
                cess_rate_id: 0,
                quantity: row.quantity,
                rate: row.rate,
                unit_id: row.unit?.id,
                is_active: true,
              },
            ],
            invoice_no: row.invoiceNo,
            receipt_date: row.date,
            description: row.description,
            category_id: row.category?.value,
            place_of_supply: row.placeOfSupplyId,
            conversion_rate: "1",
            documents: row.attatchment?.fileKey,
            document_name: row.attatchment?.fileName,
            customer_idx: row.vendor?.customer_idx,
            customer_id: row.vendor?.customer_id,
            customer_currency: "₹",
            symbol_name: "INR",
            customer_gstin_id: row.vendor?.customer_gstin_id,
            cgst: taxes.cgst,
            sgst: taxes.sgst,
            igst: taxes.igst,
            subTotal: taxes.subTotal,
            amount: taxes.total,
            cess_amount: 0,
          };
          const { ok, message, data } = await saveNewExpense(payload as any);
          console.info(
            `Expense ${row.invoiceNo}, creation ${
              ok ? "successfull" : "failed"
            }.`,
            { message, data }
          );
          if (!ok) {
            failedRows.push(row);
            newErrors.set(rowIndex, new Map([["*", message]]));
          } else {
            successCount++;
          }
        } catch (apiError) {
          failedRows.push(row);
          console.info(
            `Expense ${row.invoiceNo}, creation failed due to code exception.`
          );
          console.error(apiError);
        }
      }
      setErrors(newErrors);
      const hasErrors = failedRows.length > 0;
      notification.open({
        type: hasErrors ? "error" : "success",
        message: hasErrors
          ? "Please edit the failed expenses & try again"
          : `Successfully created ${successCount} expenses`,
        description: (
          <ul>
            {successCount > 0 && (
              <li>{successCount} Expense created successfully</li>
            )}
            {hasErrors && (
              <li>{failedRows.length} Expense could not created</li>
            )}
          </ul>
        ),
      });

      setRows(failedRows);
      if (hasErrors) {
        // TODO: set error messages per row
      } else {
        setDirty(false);
      }
    } catch (error) {
      console.error(error);
      notification.warn({
        message: "Failed to save expenses",
      });
    }
  });

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => {
      // abort data fetching
    };
  }, []);

  // console.log("[3] render: rows", rows);

  return (
    <>
      <Helmet>
        <title>{titles.ExpenseBulkCreatePage}</title>
      </Helmet>
      <IMTContent fullwidth withoutMargin>
        <IMTPageHeader
          breadcumTexts={["Expense", "Bulk create"]}
          actions={
            hasEditAccess && (
              <Button type="primary" onClick={handleSave}>
                {texts.save}
              </Button>
            )
          }
        />

        <DraggableModalProvider>
          <Prompt
            message="You have unsaved details. Do you still want to leave this page?"
            when={isDirty}
          />
          <div className={classes.commonContent}>
            <BulkExpenseTable
              rows={rows}
              isLoading={isLoading}
              setChangeHandle={(fn) => (cellChangeLogic.current = fn)}
              onNewGstin={handleNewGSTIN}
              onChange={handleRowChange}
              onAddVendor={handleVendorAddReq}
              onAddCategory={handleCategoryAddReq}
              myPlaceOfSupplyId={myPlaceOfSupply}
              errors={errors}
            />
            {/* <code>
              <pre>{JSON.stringify(rows, null, 2)}</pre>
            </code> */}
          </div>
        </DraggableModalProvider>
      </IMTContent>
      <BasicVendorModal
        visible={mVisVendor}
        onVisibleChange={setmVisVendor}
        onAddVendor={handleVendorAdd}
        __init__={lastVendorInit}
      />
      <AddCategory
        visible={mVisCategory}
        selectedCatgoryDetails={null}
        onVisibleChange={handleCategoryVisibility}
      />
    </>
  );
};

export default connector(ExpenseBulkCreatePage);
