import React, { Component } from "react";
import {
  Drawer,
  Typography,
  notification,
  Button,
  Space,
  Modal,
  Input,
  Form,
  Row,
  Col,
  Select,
  AutoComplete,
  Switch,
  Spin,
} from "antd";
import { FormInstance } from "antd/lib/form";
import { LoginRole } from "~/api/auth";
import commonStyle from "~/component/common.module.css";
import FieldInfo from "~/component/FieldInfo";
import { PAN_VALIDATION_REGEX } from "~/lib/constants";
import { fetchUtil } from "~/api/common";
import {
  VerticalAlignTopOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { AuthState } from "~/app/MainApp/store";
import { withRouter, RouteComponentProps } from "react-router";
import commonPaneStyle from "./AdminPaneDrawer.module.css";
import { getCity } from "~/api/vendor";
import { connect, ConnectedProps } from "react-redux";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends RouteComponentProps, PropsFromRedux {
  isOpen: boolean;
  onClose: () => void;
  selectedExecutiveDetail: any;
}

type FormData = {
  aadhar_no: string;
  address_line1: string;
  address_line2: string;
  city: string;
  country: string;
  designation: string;
  din: string;
  email_id: string;
  first_name: null;
  id: number;
  is_gst_primary: boolean;
  is_tds_primary: boolean;
  last_name: null;
  pan_no: string;
  phone_number: string;
  pincode: number;
  state: any;
  user_company_mapping_id: number;
  executive_designations_role_id?: number;
};

type Designations = {
  id: number;
  role_name: string;
};

interface State {
  designationArray: Array<Designations>;
  cityList: any;
  country: string;
  stateName: string;
  selectedExecutiveFormData: any;
  loadData: boolean;
  loadSave: boolean;
  loadDel: boolean;
}

class AdminPaneDrawer extends Component<Props, State> {
  state: State = {
    designationArray: [],
    cityList: [],
    stateName: "",
    country: "",
    selectedExecutiveFormData: {},
    loadData: false,
    loadSave: false,
    loadDel: false,
  };
  _formRef = React.createRef<FormInstance<Partial<FormData>>>();

  componentDidMount() {
    this.getExecutiveDetail().then(() => this.getDesignation());
  }

  getExecutiveDetail = async () => {
    this.setState({
      loadData: true,
    });
    const { ok, message, json } = await fetchUtil(
      "POST",
      "/get_executive_details",
      { id: this.props.selectedExecutiveDetail.id }
    );
    if (!ok) {
      notification.error({ message });
      this.setState({
        loadData: false,
      });
    } else {
      this.setState(
        {
          selectedExecutiveFormData: json[0],
          country: json[0].country,
          loadData: false,
        },
        () => {
          this._formRef?.current?.setFieldsValue(
            this.state.selectedExecutiveFormData
          );
        }
      );
    }
  };

  getDesignation = async () => {
    const { ok, message, json } = await fetchUtil(
      "GET",
      "/get_executive_designations"
    );
    if (!ok) {
      notification.error({ message });
    } else {
      this.setState({
        designationArray: json,
        loadData: false,
      });
    }
  };

  handleValuesChange = (changedValues: FormData, values: FormData) => {
    if (changedValues) {
      this.setState(
        {
          selectedExecutiveFormData: values,
        },
        () => {
          const roleName = this.state.designationArray.find((it) =>
            typeof values.designation === "number"
              ? it.id === Number(values.designation)
              : it.role_name === values.designation
          )?.role_name;
          this.setState({
            selectedExecutiveFormData: {
              ...this.state.selectedExecutiveFormData!,
              designation: roleName,
            },
          });
        }
      );
    }

    if ((changedValues?.city?.length || 0) >= 3) {
      this.loadCityLists(changedValues.city);
    }

    if (changedValues.is_tds_primary) {
      this.setState(
        {
          selectedExecutiveFormData: {
            ...this.state.selectedExecutiveFormData!,
            is_tds_primary: changedValues.is_tds_primary,
          },
        },
        () => {
          this._formRef.current?.setFieldsValue({
            is_tds_primary: changedValues.is_tds_primary,
          });
        }
      );
    }

    if (changedValues.is_gst_primary) {
      this.setState(
        {
          selectedExecutiveFormData: {
            ...this.state.selectedExecutiveFormData!,
            is_gst_primary: changedValues.is_gst_primary,
          },
        },
        () => {
          this._formRef.current?.setFieldsValue({
            is_gst_primary: changedValues.is_gst_primary,
          });
        }
      );
    }

    if (changedValues.designation) {
      const roleName = this.state.designationArray.find(
        (it) => it.id === Number(changedValues.designation)
      )?.role_name;
      this.setState({
        selectedExecutiveFormData: {
          ...this.state.selectedExecutiveFormData!,
          designation: roleName,
        },
      });
    }
  };

  loadCityLists = async (value: string) => {
    const [cityListRes] = await Promise.all([
      getCity(value as string).then(({ ok, message, data }) => ({
        ok,
        message,
        data,
      })),
    ]);

    if (!cityListRes.ok) {
      notification.error({
        message: "Failed to load city list.",
        description: cityListRes.message,
      });
    } else {
      this.setState({
        cityList: cityListRes.data,
      });
    }
  };

  handleCitySelect = (_value: string, opt: any) => {
    const id = opt.key;
    // tslint:disable-next-line: triple-equals | Double equal is intentional, please don't correct it
    const cityData = this.state.cityList.find((it: any) => it.id === id)!;
    this.setState(
      {
        country: cityData.country,
        stateName: cityData.state,
      },
      () => {
        if (this._formRef.current) {
          this.setState({
            selectedExecutiveFormData: {
              ...this.state.selectedExecutiveFormData!,
              country: this.state.country,
              state: this.state.stateName,
              city: cityData.city,
            },
          });

          this._formRef.current.setFieldsValue({
            country: this.state.country,
            state: this.state.stateName,
          });
        }
      }
    );
    this._formRef.current?.setFieldsValue({
      city: `${cityData.city}`,
    });
  };

  saveExecutiveDetail = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.adminPaneDrawer31
    );
    const executiveData = (await this._formRef.current?.validateFields()) as FormData;
    if (executiveData) {
      const ExecutiveDesignationRole = this.state.designationArray.find(
        (it: any) =>
          it.role_name === this.state.selectedExecutiveFormData.designation
      )?.id;
      if (ExecutiveDesignationRole) {
        this.setState({
          selectedExecutiveFormData: {
            ...this.state.selectedExecutiveFormData!,
            executive_designations_role_id: ExecutiveDesignationRole,
          },
          loadSave: true,
        });
        const { ok, message } = await fetchUtil(
          "POST",
          "/update_executive_details",
          this.state.selectedExecutiveFormData
        );
        if (!ok) {
          notification.error({ message });
          this.setState({
            loadSave: false,
          });
        } else {
          notification.success({
            message: "Executive details update successfully !",
          });
          this.setState({
            loadSave: false,
          });
          this.props.onClose();
        }
      }
    }
  };

  deleteExecutiveDetail = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.adminPaneDrawer32
    );
    this.setState({
      loadDel: true,
    });
    const { ok, message } = await fetchUtil("POST", "/delete_executive", {
      id: this.state.selectedExecutiveFormData.id,
    });
    if (!ok) {
      notification.error({ message });
      this.setState({
        loadDel: false,
      });
    } else {
      notification.success({
        message,
      });
      this.setState({
        loadDel: false,
      });
      this.props.onClose();
    }
  };

  showConfirm = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.adminPaneDrawer33
    );
    Modal.confirm({
      title: "Are you sure to delete this executive ?",
      icon: <ExclamationCircleOutlined />,
      okButtonProps: {
        loading: this.state.loadDel,
      },
      onOk: (e: React.MouseEvent) => {
        this.deleteExecutiveDetail(e);
      },
    });
  };

  renderFormItems() {
    return (
      <Row style={{ width: "100%" }} gutter={{ md: 30, lg: 32, xl: 100 }}>
        <Col md={16} lg={14} xl={12} xxl={10}>
          <Row gutter={{ md: 8, lg: 16 }}>
            <Form.Item
              className={commonStyle["w-100"]}
              name="first_name"
              label="First Name"
              rules={[{ required: true, message: "Please enter First Name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className={commonStyle["w-100"]}
              name="aadhar_no"
              label="Aadhar No"
              rules={[
                {
                  type: "string",
                  required: true,
                  pattern: /^\d{4}\d{4}\d{4}$/,
                  message: "Please enter valid Adhaar number!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className={commonStyle["w-100"]}
              name="address_line1"
              label="Address Line 1"
              rules={[{ required: true, message: "Please enter Address" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className={commonStyle["w-100"]}
              name="address_line2"
              label="Address Line 2"
              rules={[{ required: false, message: "Please enter Address" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className={commonStyle["w-100"]}
              name="city"
              label="City"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Please enter valid City !",
                },
              ]}
            >
              <AutoComplete id="chrome-off" onSelect={this.handleCitySelect}>
                {this.state.cityList.map((v: any, idx: number) => {
                  return (
                    <AutoComplete.Option key={v.id} value={`${v.id}`}>
                      <div>
                        <span>{v.city}</span>{" "}
                        <span
                          style={{
                            fontSize: 10,
                            color: "var(--primary-color)",
                          }}
                        >
                          {v.state}, {v.country}
                        </span>
                      </div>
                    </AutoComplete.Option>
                  );
                })}
              </AutoComplete>
            </Form.Item>
            <div className={commonPaneStyle.fieldInfo}>
              <FieldInfo
                text="Type at lease 3 letters to see suggestions"
                subtle
                tip
              />
            </div>

            <Form.Item
              className={commonStyle["w-100"]}
              name="state"
              label="State"
              rules={[{ required: false, message: "" }]}
            >
              <Input readOnly />
            </Form.Item>

            <FieldInfo
              text={this.state.country ? `${this.state.country}` : null}
            />

            <Form.Item
              className={commonStyle["w-100"]}
              name="is_tds_primary"
              label="Tds primary"
              // valuePropName="is_tds_primary"
              // valuePropName="checked"
              rules={[{ required: false }]}
            >
              <Switch
                checked={this.state.selectedExecutiveFormData.is_tds_primary}
              />
            </Form.Item>
          </Row>
        </Col>
        <Col md={16} lg={14} xl={12} xxl={10}>
          <Row gutter={{ md: 8, lg: 16 }}>
            <Form.Item
              className={commonStyle["w-100"]}
              name="last_name"
              label="Last Name"
              rules={[{ required: true, message: "Please enter Last Name" }]}
            >
              <Input />
            </Form.Item>
            <Row gutter={{ md: 8, lg: 16 }}>
              <Col md={16} lg={14} xl={12} xxl={10}>
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="pan_no"
                  label="Pan no"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      pattern: PAN_VALIDATION_REGEX,
                      message: "Please enter valid PAN number!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={16} lg={14} xl={12} xxl={10}>
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="din"
                  label="DIN"
                  // style={{marginRight:'10px'}}
                  rules={[{ required: true, message: "Please enter din" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              className={commonStyle["w-100"]}
              name="pincode"
              label="Pincode"
              rules={[{ required: true, message: "Please enter pincode" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              className={commonStyle["w-100"]}
              name="phone_number"
              label="Phone number"
              rules={[
                {
                  type: "string",
                  required: true,
                  pattern: /^[1-9]{1}[0-9 ]{9}$/,
                  message: "Please enter valid phone number!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="designation"
              label="Designation"
              rules={[
                {
                  required: true,
                  message: "Please enter designation",
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ width: 273 }}
              >
                {this.state.designationArray.map((opt) => (
                  <Select.Option key={opt.id} value={opt.id}>
                    {opt.role_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={commonStyle["w-100"]}
              name="email_id"
              label="Email id"
              rules={[
                {
                  required: true,

                  type: "string",
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Please enter email id",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className={commonStyle["w-100"]}
              name="is_gst_primary"
              label="GST primary"
              rules={[{ required: false }]}
            >
              <Switch
                checked={this.state.selectedExecutiveFormData.is_gst_primary}
              />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    );
  }

  render() {
    const { selectedExecutiveFormData } = this.state.selectedExecutiveFormData;
    return (
      <Drawer
        width={"60%"}
        style={{ overflow: "scroll" }}
        placement="right"
        closable={true}
        closeIcon={
          <VerticalAlignTopOutlined
            style={{ fontSize: "var(--heading-3-size)" }}
            rotate={90}
          />
        }
        onClose={this.props.onClose}
        visible={this.props.isOpen}
        destroyOnClose={true}
      >
        <Spin spinning={this.state.loadSave}>
          <Space
            style={{ marginBottom: 5 }}
            className={commonPaneStyle.drawerHeaderAlign}
          >
            <Typography.Title level={3}>Executive Edit</Typography.Title>
            {this.props.activeRole !== LoginRole.SUPERuSER ? (
              <Typography.Title level={3}>
                <Button
                  type="primary"
                  onClick={this.saveExecutiveDetail}
                  style={{ marginRight: 20 }}
                  loading={this.state.loadData}
                >
                  Save
                </Button>
                {!this.state.selectedExecutiveFormData.is_admin ? (
                  <Button
                    type="ghost"
                    style={{ color: "red", marginRight: 30 }}
                    onClick={(e: React.MouseEvent) => this.showConfirm(e)}
                  >
                    Delete
                  </Button>
                ) : null}
              </Typography.Title>
            ) : null}
          </Space>
          <div>
            <Form
              ref={this._formRef}
              onValuesChange={this.handleValuesChange}
              // initialValues={selectedExecutiveFormData}
              defaultValue={selectedExecutiveFormData}
            >
              {this.renderFormItems()}
            </Form>
          </div>
        </Spin>
      </Drawer>
    );
  }
}
export default withRouter(connector(AdminPaneDrawer));
