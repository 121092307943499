import { fetchUtil } from "./common";

export const getStateList = async (): Promise<{
  ok: boolean;
  message: string;
  data: Array<any>;
}> => {
  const { ok, message, json } = await fetchUtil("GET", "/get_states");
  if (!ok) {
    return { ok, message, data: [] };
  } else {
    return { ok, message, data: json };
  }
};
