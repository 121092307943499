import React, { Component } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";

import NonIdealState from "~/component/NonIdealState";

// import commonPaneStyle from "./common-tab-pane.module.css";

interface Props {
  doNotUseMe?: never;
  title: string;
}
interface State {
  gstGroupList: Array<{
    icon: string;
    groupName: string;
    count: number;
    complete: number;
    overdue: number;
  }>;
}

export default class DataNotFound extends Component<Props, State> {
  state: State = {
    gstGroupList: [],
  };

  render() {
    return (
      <div style={{ display: "flex", height: "300px", width: "100%" }}>
        <NonIdealState icon={<InfoCircleOutlined />} title={this.props.title} />
      </div>
    );
  }
}
