import { fetchUtil, fetchBlob, fetchTableExportBlob } from "./common";
import { shallowParseDate, roundNumber } from "~/lib";
import { VendorItem } from "~/api/vendor";
import { TAX_INCLUSION } from "~/lib/taxes";
import { CreditNoteLineItemRecord } from "~/feature/invoice/creditnote/creditnote-line-item/CreditNoteLineItemTypes";
import { DebitNoteLineItemRecord } from "~/feature/invoice/debitnote/debitnote-line-item/DebitNoteLineItemTypes";

export interface Invoice {
  amended?: any;
  total_rows: number;
  conversion_rate: string;
  invoice_id: number;
  invoice_number: string;
  customer_id: number | undefined;
  customer_gstin_id: any;
  customer_idx: any;
  invoice_billing_address: string | undefined;
  term: number;
  invoice_date: any;
  due_date: any;
  state_code: number;
  state_name: string;
  // gst_rate_id: number | any;
  gst_rate: number;
  igst: null | string | any;
  cgst: null | string | any;
  sgst: null | string | any;
  documents: string;
  // cess_rate?: number;
  cess?: number;
  invoice_type?: any;
  // cess_id?: number;
  invoice_message: null;
  statement_message: null;
  balance_due: string;
  tax_inclusion: TAX_INCLUSION;
  payment_mode: number | null;
  paid_flag: number;
  payment_receipts_id: number | null;
  place_of_supply_id: number | undefined;
  place_of_supply?: string;
  crt_time: Date;
  update_time: Date;
  customer_name: string | undefined;
  customer_email: string | undefined;
  currency_id: number | undefined;
  customer_currency: string | undefined;
  symbol_name: string | undefined;
  customer_mobile: null | string;
  customer_company_name: string | undefined;
  customer_gstin: string | undefined;
  customer_gst_registration_type_id: number | undefined;
  company_name: string;
  company_address_1: string;
  company_address_2: string;
  company_city: string;
  company_state: string;
  company_pincode: number;
  company_email: string | undefined;
  company_gstin: null;
  company_pan_no: string;
  company_cin_no: string;
  bank_name: string;
  current_ac_no: string;
  bank_mobile_number: string;
  branch_name: string;
  bank_website: null;
  branch_code: null;
  branch_address: null;
  bank_city: null;
  bank_state: null;
  bank_pincode: null;
  ifsc_code: string;
  swift_code: string;
  row_data: Array<InvoiceRowData>;
  effective_date?: Date;
  source_link?: string;
  calAmount: number;
  calTax: number;
  net_total?: any;
  amount?: any;
  tds_rate?: number;
  status?: PaymentStatus;
  compliance_status?: string;
  tds_amount?: any;
  paid_amount?: any;
  document_name?: string;
  po_number?: number;
  dc_number?: number;
  export_type?: any;
  extra?: {
    apiData?: {
      state_code: number;
    };
    vendor?: VendorItem;
    convertionRate?: number;
    paymentDetails?: PaymentDetails;
    hasCess?: boolean;
  };
  paymentStatus: string;
}

export interface CreditNote {
  tcs_cgst_rate?: number | null;
  tcs_cgst_amt?: number | null;
  tcs_sgst_rate?: number | null;
  tcs_sgst_amt?: number | null;
  tcs_igst_rate?: number | null;
  tcs_igst_amt?: number | null;
  total_rows: number;
  conversion_rate: string;
  invoice_id: number | null;
  invoice_number: string;
  customer_id: number | undefined;
  customer_gstin_id: any;
  customer_idx: any;
  invoice_billing_address: string | undefined;
  term: number;
  invoice_date: any;
  due_date: any;
  state_code: number;
  state_name: string;
  credit_note_number: number | string;
  credit_note_date: number | string;
  credit_note_id?: number;
  credit_note_user_comment: string;
  gst_rate: number;
  igst: null | string | any;
  cgst: null | string | any;
  sgst: null | string | any;
  documents: string;
  // cess_rate?: number;
  cess?: number;
  // cess_id?: number;
  invoice_message: null;
  statement_message: null;
  balance_due: string;
  tax_inclusion: TAX_INCLUSION;
  payment_mode: number | null;
  paid_flag: number;
  payment_receipts_id: number | null;
  place_of_supply_id: number | undefined;
  place_of_supply?: string;
  crt_time: Date;
  update_time: Date;
  customer_name: string | undefined;
  customer_email: string | undefined;
  currency_id: number | undefined;
  customer_currency: string | undefined;
  symbol_name: string | undefined;
  customer_mobile: null | string;
  customer_company_name: string | undefined;
  customer_gstin: string | undefined;
  company_name: string;
  company_address_1: string;
  company_address_2: string;
  company_city: string;
  company_state: string;
  company_pincode: number;
  company_email: string | undefined;
  company_gstin: null;
  company_pan_no: string;
  company_cin_no: string;
  bank_name: string;
  current_ac_no: string;
  bank_mobile_number: string;
  branch_name: string;
  bank_website: null;
  branch_code: null;
  branch_address: null;
  bank_city: null;
  bank_state: null;
  bank_pincode: null;
  ifsc_code: string;
  swift_code: string;
  credit_note_row_data: Array<CreditNoteLineItemRecord>;
  credit_note_items: Array<CreditNoteLineItemRecord>;
  calAmount: number;
  calTax: number;
  net_total?: any;
  amount?: any;
  tds_rate?: number;
  status?: PaymentStatus;
  compliance_status?: string;
  tds_amount?: any;
  paid_amount?: any;
  document_name?: string;
  po_number?: number;
  dc_number?: number;
  extra?: {
    apiData?: {
      state_code: number;
    };
    vendor?: VendorItem;
    convertionRate?: number;
    paymentDetails?: PaymentDetails;
    hasCess?: boolean;
  };
  paymentStatus: string;
}

export interface DebitNote {
  total_rows: number;
  conversion_rate: string;
  invoice_id: number | null;
  invoice_number: string;
  customer_id: number | undefined;
  customer_gstin_id: any;
  customer_idx: any;
  invoice_billing_address: string | undefined;
  term: number;
  invoice_date: any;
  due_date: any;
  state_code: number;
  state_name: string;
  debit_note_number: number | string;
  debit_note_date: number | string;
  debit_note_id?: number;
  debit_note_user_comment: string;
  gst_rate: number;
  igst: null | string | any;
  cgst: null | string | any;
  sgst: null | string | any;
  documents: string;
  // cess_rate?: number;
  cess?: number;
  // cess_id?: number;
  invoice_message: null;
  statement_message: null;
  balance_due: string;
  tax_inclusion: TAX_INCLUSION;
  payment_mode: number | null;
  paid_flag: number;
  payment_receipts_id: number | null;
  place_of_supply_id: number | undefined;
  place_of_supply?: string;
  crt_time: Date;
  update_time: Date;
  customer_name: string | undefined;
  customer_email: string | undefined;
  currency_id: number | undefined;
  customer_currency: string | undefined;
  symbol_name: string | undefined;
  customer_mobile: null | string;
  customer_company_name: string | undefined;
  customer_gstin: string | undefined;
  company_name: string;
  company_address_1: string;
  company_address_2: string;
  company_city: string;
  company_state: string;
  company_pincode: number;
  company_email: string | undefined;
  company_gstin: null;
  company_pan_no: string;
  company_cin_no: string;
  bank_name: string;
  current_ac_no: string;
  bank_mobile_number: string;
  branch_name: string;
  bank_website: null;
  branch_code: null;
  branch_address: null;
  bank_city: null;
  bank_state: null;
  bank_pincode: null;
  ifsc_code: string;
  swift_code: string;
  debit_note_row_data: Array<DebitNoteLineItemRecord>;
  debit_note_items: Array<DebitNoteLineItemRecord>;
  calAmount: number;
  calTax: number;
  net_total?: any;
  amount?: any;
  tds_rate?: number;
  status?: PaymentStatus;
  compliance_status?: string;
  tds_amount?: any;
  paid_amount?: any;
  document_name?: string;
  po_number?: number;
  dc_number?: number;
  extra?: {
    apiData?: {
      state_code: number;
    };
    vendor?: VendorItem;
    convertionRate?: number;
    hasCess?: boolean;
  };
  tcs_cgst_rate?: number | null;
  tcs_cgst_amt?: number | null;
  tcs_sgst_rate?: number | null;
  tcs_sgst_amt?: number | null;
  tcs_igst_rate?: number | null;
  tcs_igst_amt?: number | null;
}

export type InvoiceAPIData = {
  credit_note_date?: Date;
  amended?: any;
  invoice_number: string;
  total_rows: number;
  conversion_rate: string;
  invoice_id: number;
  customer_id: number;
  customer_idx: string;
  customer_gstin_id: any;
  crId: number | null | Array<number>;
  drId: null | Array<number>;
  invoice_billing_address: string;
  term: number;
  invoice_date: Date;
  due_date: Date;
  state_code: number;
  state_name: string;
  gst_rate_id: number;
  gst_rate: number;
  igst: null | string;
  cgst: null | string;
  sgst: null | string;
  documents: string;
  invoice_message: null;
  statement_message: null;
  credit_note_id?: number;
  debit_note_id?: number;

  balance_due: string;
  tax_inclusion: string;
  payment_mode: number | null;
  paid_flag: number;
  payment_receipts_id: number | null;
  crt_time: Date;
  update_time: Date;
  customer_name: string | JSX.Element;
  customer_email: string;
  currency_id: number;
  customer_currency: string;
  symbol_name: string;
  customer_mobile: null | string;
  customer_company_name: string;
  customer_gstin: string;
  company_name: string;
  company_address_1: string;
  company_address_2: string;
  company_city: string;
  company_state: string;
  company_pincode: number;
  company_email: string;
  company_gstin: null;
  company_pan_no: string;
  company_cin_no: string;
  bank_name: string;
  current_ac_no: string;
  bank_mobile_number: string;
  branch_name: string;
  bank_website: null;
  branch_code: null;
  branch_address: null;
  bank_city: null;
  bank_state: null;
  bank_pincode: null;
  ifsc_code: string;
  swift_code: string;
  rowData: Array<InvoiceLineItem>;
  calAmount: number;
  calTax: number;
  compliance_status: string;
  gstin: any;
  paymentDue: number;
  creditNoteAmt: number;
  debitNoteAmt: number;
  tds_amount: number;
  paid_amount: number;
};

export type InvoiceLineItem = {
  row_id: number;
  product_id: number;
  product_name: string;
  unit_id: any;
  description: string;
  hsn_sac: string;
  quantity: number;
  rate: number;
  amount: number;
  gst_rate_id: number;
  cess_rate_id: any;
  is_active: boolean;
};
export interface PaymentDetails {
  payment_receipts_id: number;
  payment_history: Array<PaymentHistoryEntry>;
}
export interface PaymentHistoryEntry {
  paid_amount: number;
  payment_date: Date;
  payment_mode: PaymentMode;
  payment_notes: string;
  payment_status: string;
  transaction_id: string;
  payment_receipt_id: number;
}

export type CESSRate = {
  id: number;
  label: string;
  value: number;
};

export type GSTRate = {
  id: number;
  description: string;
  rate: number;
};

export type InvoiceRowData = {
  row_id: number;
  product_id: number | null;
  product_name: string;
  unit_id: any;
  description: string;
  cgst: number;
  sgst: number;
  igst: number;
  hsn_sac: string;
  quantity: number | null;
  rate: number;
  amount: number;
  cess_amount: any;
  gst_rate_id: any;
  cess_rate_id: any;
  is_active: boolean;
  product_unit_id?: number;
  discount_rate: any;
};

export type ProductItem = {
  description: string;
  hsn_sac: string;
  id: number;
  product_name: string;
  category_path: any;
  category_name: any;
  rate: number;
  product_service_type: string;
  category_id: any;
  category: any;
  supply_type: any;
  gst_rate_id: any;
  unit_id: any;
  is_taxable: boolean;
  is_non_gst_supply: boolean;
  is_reverse_charge_applicable: boolean;
};

export type partialInvoice = {
  balance_due: string | undefined;
  cgst: string | undefined;
  conversion_rate: string | undefined;
  currency_id: number | undefined;
  customer_currency: string | undefined;
  customer_id: number | undefined;
  customer_idx: any;
  customer_gstin_id: number | undefined;
  customer_name: string | undefined;
  igst: string | undefined;
  invoice_date: Date | undefined;
  invoice_id: number | undefined;
  invoice_number: string | undefined;
  paid_flag: number | undefined;
  sgst: string | undefined;
  symbol_name: string | undefined;
  total_rows: number | undefined;
  calAmount: number | undefined;
  calTax: number | undefined;
  debitNoteAmt?: number | undefined;
  creditNoteAmt?: number | undefined;
};

const _parseStringNumbers = (invoiceAPIData: Invoice): Invoice => {
  invoiceAPIData.amount = String(+invoiceAPIData.amount!);
  invoiceAPIData.tds_amount = String(invoiceAPIData.tds_amount)
    ? +invoiceAPIData.tds_amount
    : 0;
  invoiceAPIData.igst = String(invoiceAPIData.igst)
    ? String(+invoiceAPIData.igst)
    : String(0);
  invoiceAPIData.cgst = String(invoiceAPIData.cgst)
    ? String(+invoiceAPIData.cgst)
    : String(0);
  invoiceAPIData.sgst = invoiceAPIData.sgst ? +invoiceAPIData.sgst : 0;
  // invoiceAPIData.total_pay_amount = invoiceAPIData.total_pay_amount
  // ? +invoiceAPIData.total_pay_amount
  // : 0;
  invoiceAPIData.net_total = +invoiceAPIData.net_total;
  return invoiceAPIData;
};
export const getPaymentDetais = (
  invoice_id: number
): Promise<PaymentDetails> => {
  return fetchUtil("POST", "/get_payment_details", {
    invoice_id,
  }).then(({ json }) => {
    const data = json[0];
    if (Array.isArray(data.payment_history)) {
      for (const entry of data.payment_history) {
        entry.payment_date = new Date(entry.payment_date);
        entry.payment_notes = entry.payment_notes || "";
      }
      data.payment_history.sort(
        (a: any, b: any) => a.payment_date - b.payment_date
      );
    }
    return data;
  });
};
export const getInvoiceByID = async (invoice_id: number, isCopy?: boolean) => {
  const time_zone_offset = new Date().getTimezoneOffset();
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/get_invoice_details",
    {
      invoice_id,
      time_zone_offset,
    }
  );
  let data: Invoice | undefined;
  if (json.length > 0) {
    // console.log(json[0].row_data[0]);

    const invoice = shallowParseDate(_parseStringNumbers(json[0]));
    data = invoice;
    const { payment_receipts_id } = invoice;
    if (payment_receipts_id === null) {
      invoice.paymentStatus = "Pending";
    } else {
      if (!isCopy) {
        const detailsResponse = await getPaymentDetais(invoice_id);
        invoice.extra = { paymentDetails: detailsResponse };
        if (detailsResponse && Array.isArray(detailsResponse.payment_history)) {
          const paidAmount = detailsResponse?.payment_history.reduce(
            (accumulation: number, history) => {
              if (history.payment_status === "Cancelled") {
                return accumulation;
              } else {
                return accumulation + history.paid_amount;
              }
            },
            0
          );
          const totalPayNumber = roundNumber(
            +invoice.net_total * Number(invoice.conversion_rate!) -
              (invoice.tds_amount
                ? +invoice.tds_amount * Number(invoice.conversion_rate!)
                : 0)
          );
          if (
            totalPayNumber - paidAmount === 0 ||
            totalPayNumber - paidAmount < 0
          ) {
            invoice.paymentStatus = "Completed";
          } else if (paidAmount > 0) {
            invoice.paymentStatus = "Partialy paid/Cancelled";
          } else if (totalPayNumber < paidAmount) {
            invoice.paymentStatus = "Over paid";
          }
        }
      }
    }
  }
  return {
    ok: ok && json.length > 0,
    message: message || "No details found. Please check id",
    data: json.length > 0 ? shallowParseDate(json[0] as Invoice) : undefined,
  };
};

export const getCrediNoteByID = async (credit_note_id: number) => {
  const time_zone_offset = new Date().getTimezoneOffset();
  const { ok, message, json } = await fetchUtil("POST", "/get_credit_note", {
    credit_note_id,
    time_zone_offset,
  });

  return {
    ok: ok && json.length > 0,
    message: message || "No details found. Please check id",
    data: json.length > 0 ? shallowParseDate(json[0] as CreditNote) : undefined,
  };
};

export const getDebitNoteByID = async (debit_note_id: number) => {
  const time_zone_offset = new Date().getTimezoneOffset();
  const { ok, message, json } = await fetchUtil("POST", "/get_debit_note", {
    debit_note_id,
    time_zone_offset,
  });
  return {
    ok: ok && json.length > 0,
    message: message || "No details found. Please check id",
    data: json.length > 0 ? shallowParseDate(json[0] as DebitNote) : undefined,
  };
};

export const saveInvoiceDetails = async (payload: Invoice) => {
  const { ok, message } = await fetchUtil("POST", "/edit_invoice", payload);
  return { ok, message };
};

export const newInvoiceDetails = async (payload: Invoice) => {
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/save_invoice",
    payload
  );
  const data = json[0] as { invoice_id: number };
  return { ok, message, data };
};

export const newCreditNoteDetails = async (payload: Invoice) => {
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/save_credit_note",
    payload
  );
  const data = json[0] as { invoice_id: number };
  return { ok, message, data };
};

export const newDebitNoteDetails = async (payload: Invoice) => {
  const { ok, message, json } = await fetchUtil(
    "POST",
    "/save_debit_note",
    payload
  );
  const data = json[0] as { invoice_id: number };
  return { ok, message, data };
};

export const saveCreditNoteDetails = async (payload: CreditNote) => {
  const { ok, message } = await fetchUtil("POST", "/edit_credit_note", payload);
  return { ok, message };
};

export const saveDebitNoteDetails = async (payload: CreditNote) => {
  const { ok, message } = await fetchUtil("POST", "/edit_debit_note", payload);
  return { ok, message };
};

export const getProductList = async (): Promise<{
  ok: boolean;
  message: string;
  data: Array<ProductItem>;
}> => {
  const { ok, json, message } = await fetchUtil("GET", `/get_products`);
  if (!ok) {
    return { ok, message, data: [] };
  } else {
    json.forEach((it) => {
      it.rate = it.rate ? +it.rate : 0;
    });
    return { ok, message, data: json };
  }
};

export enum PaymentMode {
  CASH = 1,
  OTHER = 2,
}

export enum PaymentStatus {
  NOT_DONE = 0,
  DONE = 1,
}

export const payConfirm = (
  bank_id: number,
  invoice_id: number,
  notes: string,
  paid_amount: number,
  paid_by: number,
  pay_amount: number,
  payment_date: string,
  payment_mode: number,
  status: number,
  tds_amount: number,
  tds_rate: number,
  transaction_id: number,
  conversion_rate: string
) => {
  return fetchUtil("POST", "/update_invoice/receive_invoice_payment", {
    bank_id,
    invoice_id,
    notes,
    paid_amount,
    paid_by,
    pay_amount,
    payment_date,
    payment_mode,
    status,
    tds_amount,
    tds_rate,
    transaction_id,
    conversion_rate,
    payment_array: {},
  });
};

export const downloadPDFFile = (invoice_id: number) => {
  return fetchBlob("/download_invoice", {
    invoice_id,
  }).then(({ ok, message, blob }) => {
    return blob;
  });
};

export const getCessRates = async (): Promise<{
  ok: boolean;
  message: string;
  data?: Array<GSTRate>;
}> => {
  const { ok, message, json } = await fetchUtil("GET", "/get_cess_rates");
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message, data: json };
  }
};

export const handleEmail = (invoice_id: number) => {
  return fetchUtil("POST", "/send_invoice_slip", {
    invoice_id,
  }).then(({ ok, json, message }) => {
    return { ok, message };
  });
};

export type InvoiceDocumentType = {
  id: number;
  document_type_name: string;
};

export const getInvoiceDocumentType = async (): Promise<{
  ok: boolean;
  message: string;
  data: Array<InvoiceDocumentType>;
}> => {
  const { ok, json, message } = await fetchUtil(
    `GET`,
    `/get_invoice_document_types`
  );
  if (!ok) {
    return { ok, message, data: [] };
  } else {
    return { ok, message, data: json as Array<InvoiceDocumentType> };
  }
};

export const getInvoiceList = async (
  page_no: number,
  time_zone_offset: number,
  no_of_rows: number,
  month: string,
  fy: string,
  sort_param: string
): Promise<{
  ok: boolean;
  data?: Array<Invoice>;
  message: string;
  schema?: Array<any>;
}> => {
  const { ok, json, message, schema } = await fetchUtil(
    "POST",
    "/get_invoices",
    {
      fy,
      month,
      page_no,
      no_of_rows,
      time_zone_offset,
      sort_param,
    }
  );
  if (!ok) {
    return { ok, data: [], message };
  } else {
    const data = json as Array<Invoice>;
    const paymentDetailsResponses = await Promise.all(
      data
        .filter((it) => it.payment_receipts_id !== null)
        .map((it) => getPaymentDetais(it.invoice_id))
    );
    data.forEach((invoice) => {
      // parse dates
      _parseStringNumbers(invoice);
      // invoice.receipt_date = new Date(invoice.receipt_date as any);
      // invoice.paid_time = new Date(invoice.paid_time as any);
      invoice.crt_time = new Date(invoice.crt_time as any);
      invoice.update_time = new Date(invoice.update_time as any);

      const { payment_receipts_id } = invoice;
      if (payment_receipts_id === null) {
        invoice.paymentStatus = "Pending";
      } else {
        const detailsResponse = paymentDetailsResponses.find(
          (it) => it.payment_receipts_id === payment_receipts_id
        )!;
        invoice.extra = {
          paymentDetails: detailsResponse,
        };
        if (detailsResponse && Array.isArray(detailsResponse.payment_history)) {
          const paidAmount = detailsResponse.payment_history.reduce(
            (accumulation: number, history) => {
              if (history.payment_status === "Cancelled") {
                return accumulation;
              } else {
                return accumulation + history.paid_amount;
              }
            },
            0
          );
          const totalPayNumber = roundNumber(invoice.amount);

          if (totalPayNumber - paidAmount === 0) {
            invoice.paymentStatus = "Completed";
          } else if (paidAmount > 0) {
            invoice.paymentStatus = "Partialy paid/Cancelled";
          } else if (totalPayNumber < paidAmount) {
            invoice.paymentStatus = "Over paid";
          }
        }
      }
    });
    return { ok, message, data, schema };
  }
};

export const getTableExportBlob = async (
  page_no: number,
  time_zone_offset: number,
  no_of_rows: number,
  month: string,
  fy: string,
  sort_param: string,
  acceptType: string,
  selectedFields: string
): Promise<{
  ok: boolean;
  blob: Blob | null | undefined;
  message: string;
}> => {
  const { ok, blob, message } = await fetchTableExportBlob(
    "POST",
    "/get_invoices",
    {
      fy,
      month,
      page_no,
      no_of_rows,
      time_zone_offset,
      sort_param,
    },
    acceptType,
    selectedFields
  );
  if (!ok) {
    return { ok, blob: null, message };
  } else {
    return {
      ok,
      blob,
      message,
    };
  }
};
