import React, { Component } from "react";
import { Tag, Divider } from "antd";

const { CheckableTag } = Tag;
interface Props {
  filterOptions: any;
  onFilterChange: (updatedData: any) => void;
}

interface State {
  filterData: any;
}

export default class FilterComponent extends Component<Props, State> {
  state: State = {
    filterData: [],
  };

  componentDidMount() {
    this.setState({
      filterData: this.props.filterOptions,
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (this.state.filterData[0] !== this.props.filterOptions[0]) {
      this.setState(
        {
          filterData: this.props.filterOptions,
        },
        () => {
          this.props.onFilterChange(this.state.filterData);
        }
      );
    }
  }

  handleFilterChange = (tag: any, cheack: any) => {
    const updated = this.state.filterData.map((el: any) => {
      if (tag.title) {
        if (el.checked) {
          el.checked = false;
        } else {
          el.checked = true;
        }
      }
      return el;
    });

    this.setState(
      {
        filterData: updated,
      },
      () => {
        this.props.onFilterChange(updated);
      }
    );
  };

  renderFilterTag() {
    return (
      <>
        <div style={{ marginBottom: 20 }}>
          {this.state.filterData.map((tag: any, index: any) => (
            <>
              <CheckableTag
                key={tag?.title}
                checked={tag?.checked}
                onChange={(checked) => this.handleFilterChange(tag, checked)}
                style={{
                  padding: 4,
                  paddingLeft: 10,
                  paddingRight: 10,
                  fontSize: 12,
                  fontWeight: 600,
                }}
              >
                {tag?.title}
              </CheckableTag>
              {index === 2 || index === 5 || index === 8 ? (
                <Divider
                  type="vertical"
                  style={{ height: "25px", backgroundColor: "gray" }}
                />
              ) : null}
            </>
          ))}
        </div>
      </>
    );
  }

  render() {
    return <>{this.renderFilterTag()}</>;
  }
}
