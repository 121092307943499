export const OTHER_TERITORY_ID = 99;
export const REGULAR_GST_REG_TYPE = 1;
export const COMPOSITION_GST_REG_TYPE = 2;
export const UNREGISTERED_GST_REG_TYPE = 3;
export const CONSUMER_GST_REG_TYPE = 4;
export const OVERSEAS_GST_REG_TYPE = 5;
export const SEZ_GST_REG_TYPE = 6;
export const DEEMED_EXP_GST_REG_TYPE = 7;
export const EMBASSY_EXP_GST_REG_TYPE = 8;

export const CURRENCY_ID_INR = 1;

export const GST_OUT_OF_SCOPE = 1; // id of outOfTax gst rate
export const GST_RATE_ID_18_PERC = 9;
export const VENDOR_UNREGISTERED_TYPE_ID = 3;

// export const PASSWORD_VALIDATION_REGEX = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!#$%&?@ "])[a-zA-Z0-9!#$%&?@]{8,20}$/;
export const PASSWORD_VALIDATION_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{8,}$/;

export const EMAIL_VALIDATION_REGEX = /^([a-zA-Z0-9_.-]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
// export const PAN_VALIDATION_REGEX = /^[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/;
export const PAN_VALIDATION_REGEX = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

export const LS_KEY_EXPENSE_DRAFT = "imt__exp_draft";
export const SUPPORTED_EXT_LIST = ["pdf", "jpg", "jpeg", "png"];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "All",
];
export const syncMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "April-June",
  "July-September",
  "October-December",
  "January-March",
  "All",
];
export const monthFinancial = [
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "January",
  "February",
  "March",
];
export const monthFinancialMini = [
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
  "Jan",
  "Feb",
  "Mar",
];

export const monthNameToNumber = (monthName: string) => {
  const months: { [key: string]: string } = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };
  return months[monthName] || "";
};
