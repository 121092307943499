import React from "react";
import { Tooltip } from "antd";
import { CompliancePaymentStatus } from "~/api/compliance";
import CompliancePayIcon from "~/component/CompliancePayIcon";

export const renderPayIcon = (
  status: CompliancePaymentStatus,
  onClick?: (e: React.MouseEvent) => void
) => {
  return (
    <Tooltip
      placement="left"
      title={status === "complete" ? "Completed" : "Mark as complete"}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <CompliancePayIcon
          onClick={onClick}
          type={status === "complete" ? "done" : "pending"}
        />
      </div>
    </Tooltip>
  );
};
