import React from "react";
import ArrayViewText from "./arrayViewText";
import ObjectViewText from "./objectViewText";
import styles from "~/component/JsonDownloadPreview.module.css";
type Props = {
  JSONToView: any;
  currentLayer: any;
  previousLayers: any;
  setDataOfALayer: any;
};
function DataToShowText({
  JSONToView,
  currentLayer,
  previousLayers,
  setDataOfALayer,
}: Props) {
  if (Array.isArray(JSONToView) && JSONToView.length > 0) {
    return (
      <ArrayViewText
        arrayToView={JSONToView}
        currentLayer={currentLayer}
        previousLayers={previousLayers}
        setDataOfALayer={setDataOfALayer}
      />
    );
  }
  if (Array.isArray(JSONToView) && JSONToView.length === 0) {
    return (
      <tbody className={styles.tbody1}>
        <tr className={styles.trow1}>
          <td className={styles.trowD1} />
        </tr>
      </tbody>
    );
  }
  if (!Array.isArray(JSONToView) && typeof JSONToView === "object") {
    return (
      <ObjectViewText
        objectToView={JSONToView}
        currentLayer={currentLayer}
        previousLayers={previousLayers}
        setDataOfALayer={setDataOfALayer}
      />
    );
  } else {
    return <></>;
  }
}

export default DataToShowText;
