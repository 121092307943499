import React, { Component } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  notification,
  Table,
  Drawer,
  Switch,
  Tooltip,
} from "antd";
import { FormInstance } from "antd/lib/form";
import commonStyle from "~/component/common.module.css";
import { APIState, getBankList, BankNameList } from "~/api/common";
import { AdminDetail } from "~/api/profile";
import { LoginRole } from "~/api/auth";
import { AuthState } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import { string } from "random-js";
import { ColumnType } from "antd/lib/table";
const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface BankDetails {
  bank_name: string;
  account_number: string;
  ifsc_code: string;
  swift_code: string | null;
  is_primary: boolean;
  bank_id: number;
  branch_name: string;
  bank_type_id: number;
}
interface Props extends PropsFromRedux {
  apiState: APIState;
  data: Array<BankDetails>;
  onDataChange: (newData: AdminDetail) => void;
  handleUpdate: (newData: AdminDetail) => void;
  handleAdd: (newData: AdminDetail) => void;
  onDirty: () => void;
}
interface SelectedData {
  bank_name: string;
  account_number: string;
  ifsc_code: string;
  swift_code: string;
  is_primary: boolean;
}
interface State {
  bankList: Array<BankNameList>;
  open_drawer: boolean;
  tableData: Array<any>;
  selectedData: SelectedData;
  mode: string;
  saveLoading: boolean;
  enable_primary: boolean;
  loading: boolean;
}

class OfficialDetailsPane extends Component<Props, State> {
  state = {
    bankList: [],
    open_drawer: false,
    tableData: [],
    selectedData: {
      bank_name: "",
      account_number: "",
      ifsc_code: "",
      swift_code: "",
      is_primary: true,
      bank_type_id: "",
    },
    mode: "Add new",
    saveLoading: false,
    enable_primary: true,
    loading: true,
  };

  _formRef = React.createRef<FormInstance<any>>();
  componentDidMount() {
    this.loadBankList();
  }
  componentDidUpdate(prevProps: Props, _prevState: State) {
    if (prevProps.data !== this.props.data) {
      this._formRef.current?.setFieldsValue(this.props.data);
      this.loadBankList();
    }
  }

  loadBankList = async () => {
    this.setState({ loading: true });
    const response = await getBankList();
    if (!response.ok) {
      notification.error({
        message: "Failed to load Bank List",
        description: response.message,
      });
      this.setState({ loading: false });
    } else {
      const newData = [];
      if (this.props.data) {
        for (const data of this.props.data) {
          newData.push({
            bank_name: response?.data?.find(
              (res: BankNameList) => res?.id === data?.bank_type_id
            )?.name,
            account_number: data?.account_number,
            ifsc_code: data?.ifsc_code,
            swift_code: data?.swift_code,
            is_primary: data?.is_primary,
            bank_id: data?.bank_id,
            branch_name: data?.branch_name,
            bank_type_id: data?.bank_type_id,
          });
        }
      }
      this.setState({
        tableData: newData,
        bankList: response.data!,
        loading: false,
      });
    }
  };

  handleBankFilterOption = (input?: string, option?: any): any => {
    return input && option.children.toLowerCase().includes(input.toLowerCase());
  };

  handleDrawerClose = () => {
    this.setState({
      open_drawer: false,
      selectedData: {
        bank_name: "",
        account_number: "",
        ifsc_code: "",
        swift_code: "",
        is_primary: true,
      },
      enable_primary: true,
    });
    this._formRef.current?.resetFields();
  };

  handleEditClick = (data: SelectedData) => {
    this.setState(
      {
        open_drawer: true,
        mode: "edit",
        selectedData: data,
        enable_primary: false,
      },
      () => {
        this._formRef.current?.setFieldsValue(data);
      }
    );
  };
  handleAddClick = () => {
    this.setState(
      {
        open_drawer: true,
        mode: "Add new",
        selectedData: this.state.selectedData,
      },
      () => {
        this._formRef.current?.setFieldsValue(this.state.selectedData);
      }
    );
  };

  render() {
    const col: Array<ColumnType<any>> = [
      {
        title: "Bank name",
        dataIndex: "bank_name",
        key: "bank_name",
        align: "left",
      },
      {
        title: "Primary",
        dataIndex: "is_primary",
        key: "is_primary",
        align: "left",
        render: (text: boolean, data: SelectedData) => {
          return <>{`${text ? "Yes" : "No"}`}</>;
        },
      },
      {
        title: "Account number",
        dataIndex: "account_number",
        key: "account_number",
        align: "left",
      },
      {
        title: "IFSC code",
        dataIndex: "ifsc_code",
        key: "ifsc_code",
        align: "left",
      },
      {
        title: "SWIFT code",
        dataIndex: "swift_code",
        key: "swift_code",
        align: "left",
      },
      {
        title: "Edit",
        dataIndex: "",
        key: "",
        align: "left",
        render: (text: string, data: SelectedData) => {
          return (
            <>
              {this.props.activeRole === LoginRole.ADMIN ? (
                <EditOutlined onClick={this.handleEditClick.bind(this, data)} />
              ) : (
                <Tooltip title="Only Admin can edit" color={"red"} key={"red"}>
                  <EditOutlined />
                </Tooltip>
              )}
            </>
          );
        },
      },
    ];
    const updatedForm = async (val: any) => {
      this.setState({ saveLoading: true });
      const bankDetails: any = this.state.bankList.find(
        (data: BankNameList) => data?.name === val?.bank_name
      );
      const bank_id: number = bankDetails?.id;

      const newData = {
        ...this.state.selectedData,
        ...val,
        bank_type_id:
          typeof val?.bank_name === "string" ? bank_id : val?.bank_name,
      };

      const { ok, message }: any = await this.props.handleUpdate(newData);
      if (ok) {
        notification.success({
          message,
        });
        this.setState({ saveLoading: false });
        this.handleDrawerClose();
      } else {
        this.setState({ saveLoading: false });
        notification.error({
          message,
        });
      }
    };

    const newForm = async (val: any) => {
      const newData = { ...val, bank_type_id: val?.bank_name };
      this.setState({ saveLoading: true });
      const { ok, message }: any = await this.props.handleAdd(newData);
      if (ok) {
        notification.success({
          message,
        });
        this.setState({ saveLoading: false });
        this.handleDrawerClose();
      } else {
        notification.error({
          message,
        });
        this.setState({ saveLoading: false });
      }
    };
    return (
      <>
        <div style={{ display: "flex", width: "100%", justifyContent: "end" }}>
          {this.props.activeRole === LoginRole.ADMIN ? (
            <>
              <Button
                type="primary"
                onClick={() => {
                  this.handleAddClick();
                }}
              >
                + Add new
              </Button>
            </>
          ) : null}
        </div>
        <Table
          style={{ maxWidth: "100%", cursor: "pointer" }}
          loading={this.state.loading}
          columns={col}
          dataSource={this.state.tableData || []}
          size="large"
          pagination={false}
        />

        <Drawer
          title={
            this.state.mode === "Add new"
              ? "Add new bank details"
              : "Edit bank details"
          }
          onClose={this.handleDrawerClose}
          visible={this.state.open_drawer}
          width={600}
        >
          <Form
            onFinish={(e) => {
              this.state.mode === "edit" ? updatedForm(e) : newForm(e);
            }}
            ref={this._formRef}
          >
            <Form.Item
              className={commonStyle["w-100"]}
              name="bank_name"
              label="Bank name"
              rules={[{ required: true, message: "Enter bank name" }]}
            >
              <Select showSearch filterOption={this.handleBankFilterOption}>
                {this.state.bankList.map((bank: BankNameList, indx) => (
                  <Select.Option key={indx} value={bank.id}>
                    {bank.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              className={commonStyle["w-100"]}
              required={true}
              name="account_number"
              label="Account number"
              rules={[
                {
                  required: true,
                  message: "Enter account number",
                },
                {
                  type: "string",
                  required: false,
                  pattern: /^[0-9 ]+$/,
                  message: "Please enter valid account number!",
                },
              ]}
            >
              <Input readOnly={this.props.activeRole === LoginRole.SUPERuSER} />
            </Form.Item>

            <Form.Item
              className={commonStyle["w-100"]}
              required={true}
              name="ifsc_code"
              label="IFSC code"
              rules={[
                {
                  required: true,
                  message: "Enter IFSC code",
                },
                {
                  type: "string",
                  required: false,
                  pattern: /^[A-Z]{4}[0][A-Z0-9]{6,7}$/,
                  message: "Please enter valid IFSC code",
                },
              ]}
            >
              <Input readOnly={this.props.activeRole === LoginRole.SUPERuSER} />
            </Form.Item>

            <Form.Item
              name="swift_code"
              label="SWIFT code"
              rules={[
                {
                  type: "string",
                  pattern: /^[A-Z]{4}[A-Z0-9]{4,7}$/,
                  required: false,
                  message: "Please enter valid swift code",
                  whitespace: true,
                },
              ]}
            >
              <Input readOnly={this.props.activeRole === LoginRole.SUPERuSER} />
            </Form.Item>
            <Form.Item
              name="is_primary"
              label="Primary"
              valuePropName="checked"
              rules={[
                {
                  type: "boolean",
                  required: false,
                },
              ]}
            >
              <Switch
                disabled={
                  this.props.activeRole === LoginRole.SUPERuSER ||
                  this.state.enable_primary
                }
              />
            </Form.Item>
            {this.props.activeRole === LoginRole.SUPERuSER ? null : (
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  loading={this.state.saveLoading}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </div>
            )}
          </Form>
        </Drawer>
      </>
    );
  }
}

export default connector(OfficialDetailsPane);
