import React, { PureComponent } from "react";
import { Modal, Form, Input, Tooltip, notification } from "antd";
import { Required } from "~/lib/form_rules";
import { FormInstance } from "antd/lib/form";
import { APIState } from "~/api/common";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
} from "@ant-design/icons";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

interface Props {
  visible: boolean;
  changePass: (val: any) => void;
  hidePasswordModal: () => void;
  passLoader: boolean;
}

type PaswordData = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

interface State {
  passwordData?: PaswordData;
  apiState: APIState;
}

export default class ChangePasswordDialog extends PureComponent<Props, State> {
  state: State = {
    passwordData: undefined,
    apiState: "loading",
  };

  _formRef = React.createRef<FormInstance<PaswordData>>();

  componentDidMount() {
    this.setState({
      apiState: "idle",
    });
  }

  onValuesChange = async (
    changedValues: Partial<PaswordData>,
    values: PaswordData
  ) => {
    // if (changedValues.product_name) {
    //   this.setState({
    //     productData: {
    //       ...this.state.productData,
    //       product_name:changedValues.product_name
    //     }
    //   })
    // }
    // if (changedValues.rate) {
    //   this.setState({
    //     productData: {
    //       ...this.state.productData,
    //       rate:changedValues.rate
    //     }
    //   })
    // }
    // if (changedValues.product_description) {
    //   this.setState({
    //     productData: {
    //       ...this.state.productData,
    //       product_description:changedValues.product_description
    //     }
    //   })
    // }
    // if (changedValues.hsn_sac) {
    //   this.setState({
    //     productData: {
    //       ...this.state.productData,
    //       hsn_sac:changedValues.hsn_sac
    //     }
    //   })
    // }
  };

  handleOk = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.changePasswordModal316
    );

    if (!this._formRef.current) {
      return;
    }

    this._formRef.current?.validateFields().then(async (value) => {
      if (value.confirmPassword === value.newPassword) {
        this.props.changePass(value);
      } else {
        notification.error({
          message: "confirm password should be same as new password",
        });
      }
    });
  };

  handleCancel = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.changePasswordModal317
    );

    this.props.hidePasswordModal();
  };

  render() {
    const { visible } = this.props;

    return (
      <Modal
        title="Change password"
        visible={visible}
        confirmLoading={this.props.passLoader}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        centered
        destroyOnClose
        maskClosable={false}
      >
        <Form
          ref={this._formRef}
          initialValues={this.state.passwordData}
          onValuesChange={this.onValuesChange}
        >
          <Form.Item
            label="Current password"
            required={true}
            name="currentPassword"
            rules={Required("Current password")}
          >
            <Input.Password
              autoFocus
              type="password"
              placeholder="Enter current password"
              prefix={<LockOutlined />}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              size="large"
              autoComplete="off"
            />
          </Form.Item>
          <Tooltip
            title="
                  a).Minimum 8 characters,
                  b).Maximum 20 characters,           
                  c).At least one uppercase character,              
                  d).At least one lowercase character,           
                  e).At least one digit,           
                  f).At least one special character"
          >
            <Form.Item
              label="New password"
              required={true}
              name="newPassword"
              rules={Required("New password")}
            >
              <Input.Password
                type="password"
                placeholder="Enter new password"
                prefix={<LockOutlined />}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                size="large"
                autoComplete="off"
                // onFocus={this.onFocus}
                // suffix={

                // }
              />
            </Form.Item>
          </Tooltip>
          <Form.Item
            label="Confirm password"
            required={true}
            name="confirmPassword"
            rules={Required("Confirm password")}
          >
            <Input.Password
              type="password"
              placeholder="Confirm your new password"
              prefix={<LockOutlined />}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              size="large"
              autoComplete="off"
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
