import React, { Component } from "react";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import {
  notification,
  Button,
  Checkbox,
  Spin,
  Modal,
  Upload,
  Tooltip,
} from "antd";
import { RcFile, RcCustomRequestOptions } from "antd/lib/upload/interface";
import {
  UploadProgressEvent,
  UploadRequestError,
} from "rc-upload/lib/interface";
import { UploadOutlined } from "@ant-design/icons";
import { connect, ConnectedProps } from "react-redux";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { fetchUtil, fetchBlob, getSignedUploadURL } from "~/api/common";
import commonStyle from "~/component/common.module.css";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

function getError(
  option: { method: "PUT"; action: string },
  xhr: XMLHttpRequest
) {
  const msg = `cannot ${option.method} ${option.action} ${xhr.status}'`;
  const err = new Error(msg) as UploadRequestError;
  err.status = xhr.status;
  err.method = option.method;
  err.url = option.action;
  return err;
}

function getBody(xhr: XMLHttpRequest) {
  const text = xhr.responseText || xhr.response;
  if (!text) {
    return text;
  }

  try {
    return JSON.parse(text);
  } catch (e) {
    return text;
  }
}

const mapStateToProps = (
  state: AuthState,
  ownProps: { employeeID: number }
) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  termsAndCondAgree: state.mainAppSlice.user.terms_conditions_agree,
  employeeID: ownProps.employeeID,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  createCompanyDetails: (company_name: any) =>
    dispatch(action.auth.createCompanyDetails(company_name)),
  empDetailsSaved: (saveDetails: boolean) =>
    dispatch(action.auth.empDetailsSaved(saveDetails)),
  handleTermsModal: (agreeTerms: boolean) =>
    dispatch(action.auth.handleTermsModal(agreeTerms)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  employeeID: number;
  visible: boolean;
}

interface State {
  editorState: any;
  content: string;
  agreeTerms: boolean;
  checkForButton: boolean;
  loadData: boolean;
  loadUpdateTerm: boolean;
  loadSaveContent: boolean;
  hashedFileName: string;
}

class TermsModalView extends Component<Props, State> {
  state: State = {
    editorState: EditorState.createEmpty(),
    content: "<p>Write something here .... </p>",
    agreeTerms: false,
    checkForButton: false,
    loadData: false,
    loadUpdateTerm: false,
    loadSaveContent: false,
    hashedFileName: "",
  };

  componentDidMount() {
    this.getContent();
  }

  async getContent() {
    this.setState({
      loadData: true,
    });
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_employee_terms_conditions`,
      {
        emp_id: this.props.employeeID,
      }
    );

    if (ok) {
      if (json.length > 0) {
        this.setState(
          {
            content:
              json[0].last_published_terms_conditions === null
                ? "<p></p>"
                : json[0].last_published_terms_conditions,
            agreeTerms: json[0].terms_conditions_agree,
            checkForButton: json[0].terms_conditions_agree,
          },
          () => {
            const blocksFromHtml = htmlToDraft(this.state.content);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
              contentBlocks,
              entityMap
            );
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
              editorState,
              loadData: false,
            });
          }
        );
      } else {
        this.setState({
          loadData: false,
        });
      }
    } else {
      notification.error({ message });
      this.setState({
        loadData: false,
      });
    }
  }

  onCheckChange = (e: any) => {
    this.setState({
      agreeTerms: e.target.checked,
    });
  };

  updateTermsModalViewition = async () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.termsModalView324
    );
    this.setState({
      loadUpdateTerm: true,
    });

    const { ok, message } = await fetchUtil(
      "POST",
      `/update_terms_and_conditions`,
      {
        terms_conditions_agree: this.state.agreeTerms,
        emp_id: this.props.employeeID,
        signedFile: this.state.hashedFileName,
      }
    );
    if (ok) {
      this.setState({
        loadUpdateTerm: false,
      });
      notification.success({
        message,
      });
      this.props.handleTermsModal(this.state.agreeTerms);
      //   this.getContent();
    } else {
      this.setState({
        loadUpdateTerm: false,
      });
      notification.error({ message });
    }
  };

  downloadPdf = async () => {
    return fetchBlob("/download_terms_conditions", {}).then(
      ({ ok, message, blob }) => {
        return blob;
      }
    );
  };

  downloadTermsAndConditons = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.termsModalView325
    );
    this.downloadPdf()
      .then((res: any) => {
        const blob = new Blob([res], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const printWin: any = window.open(url);
        printWin.print();
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  handleAction = async (file: RcFile): Promise<string> => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.termsModalView326
    );
    const fileName = file.name;
    const { ok, message, data } = await getSignedUploadURL(fileName);
    if (!ok) {
      notification.error({ message });
      throw Error(message);
    } else {
      const { url, hashedFileName } = data!;
      this.setState({ hashedFileName });
      return url;
    }
  };

  overriddenRequest = (option: RcCustomRequestOptions) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.termsModalView327
    );

    const xhr = new XMLHttpRequest();
    xhr.upload.onprogress = function progress(e: any) {
      if (e.total > 0) {
        e.percent = (e.loaded / e.total) * 100;
      }
      option.onProgress(e as UploadProgressEvent, option.file);
    };
    xhr.onerror = function error(_e: ProgressEvent) {
      const e = (_e as any) as ErrorEvent;
      option.onError(e.error, xhr.response, option.file);
    };

    xhr.onload = function onload() {
      // allow success when 2xx status
      // see https://github.com/react-component/upload/issues/34
      if (xhr.status < 200 || xhr.status >= 300) {
        return option.onError(
          getError({ ...option, method: "PUT" }, xhr),
          getBody(xhr)
        );
      }

      return option.onSuccess(getBody(xhr), option.file);
    };

    xhr.open("PUT", option.action);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.setRequestHeader("Content-Type", option.file.type);
    xhr.send(option.file);
  };

  render() {
    const { editorState, hashedFileName } = this.state;
    return (
      <>
        <Modal
          title="Terms & Conditions"
          visible={!this.props.visible}
          centered
          width={800}
          destroyOnClose
          maskClosable={false}
          className={commonStyle.termsModal}
          footer={null}
          closable={false}
        >
          <Spin spinning={this.state.loadData}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 10,
              }}
            >
              <Button type="primary" onClick={this.downloadTermsAndConditons}>
                <Tooltip title="Download Terms and Conditions">
                  Download PDF
                </Tooltip>
              </Button>
            </div>
            <div>
              <Editor
                toolbarHidden={true}
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                readOnly={true}
              />
            </div>
            <>
              <div style={{ marginTop: "20px" }}>
                <p>
                  Download the pdf and upload the same after adding your
                  signature{" "}
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Upload
                    accept=".pdf"
                    action={this.handleAction}
                    customRequest={this.overriddenRequest}
                    disabled={hashedFileName.length > 0}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                  <Checkbox
                    style={{ marginLeft: 20 }}
                    onChange={this.onCheckChange}
                    checked={this.state.agreeTerms}
                    disabled={this.state.checkForButton}
                  >
                    <b> I accept all terms and conditions</b>
                  </Checkbox>
                </div>
                {!this.state.checkForButton ? (
                  <>
                    <Button
                      type="primary"
                      disabled={
                        !this.state.agreeTerms || hashedFileName.length === 0
                      }
                      onClick={this.updateTermsModalViewition}
                      style={{ marginTop: "20px" }}
                      loading={this.state.loadUpdateTerm}
                    >
                      Agree
                    </Button>
                  </>
                ) : null}
              </div>
            </>
          </Spin>
        </Modal>
      </>
    );
  }
}

export default connector(TermsModalView);
