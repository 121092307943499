import * as React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
  showRedDot?: boolean;
  type: "pending" | "partial" | "done";
}

const DONE_STROKE_COLOR = "#FFF";
const DONE_BORDER_STROKE_COLOR = "#9B51E0";
const DONE_FILL_COLOR = "#9B51E0";
const DONE_FILL_COLOR2 = "#821CE2";
const PARTIAL_STROKE_COLOR = "#FFF";
const PARTIAL_BORDER_STROKE_COLOR = "#F3B02B";
const PARTIAL_FILL_COLOR = "#FAAD14";
const PARTIAL_FILL_COLOR2 = "#FFAA0072";
const PENDING_STROKE_COLOR = "#82828266";
const PENDING_BORDER_STROKE_COLOR = "#82828266";
const PENDING_FILL_COLOR = "#82828233";

function CompliancePayIcon({
  showRedDot,
  type,
  onMouseEnter,
  onMouseLeave,
  style,
  ...props
}: Props) {
  const [isHover, setIsHover] = React.useState(false);

  let strokeColor: string;
  let borderStrokeColor: string;
  let fillColor: string;

  if (type === "done") {
    strokeColor = DONE_STROKE_COLOR;
    borderStrokeColor = DONE_BORDER_STROKE_COLOR;
    fillColor = DONE_FILL_COLOR;
  } else if (isHover) {
    strokeColor = type === "partial" ? PARTIAL_STROKE_COLOR : DONE_STROKE_COLOR;
    borderStrokeColor =
      type === "partial"
        ? PARTIAL_BORDER_STROKE_COLOR
        : DONE_BORDER_STROKE_COLOR;
    fillColor = type === "partial" ? PARTIAL_FILL_COLOR2 : DONE_FILL_COLOR2;
  } else {
    strokeColor =
      type === "partial" ? PARTIAL_STROKE_COLOR : PENDING_STROKE_COLOR;
    borderStrokeColor =
      type === "partial"
        ? PARTIAL_BORDER_STROKE_COLOR
        : PENDING_BORDER_STROKE_COLOR;
    fillColor = type === "partial" ? PARTIAL_FILL_COLOR : PENDING_FILL_COLOR;
  }

  return (
    <svg
      width={30}
      height={25}
      viewBox="0 0 46 36"
      fill="none"
      {...props}
      onMouseEnter={(e) => {
        onMouseEnter && onMouseEnter(e);
        setIsHover(true);
      }}
      onMouseLeave={(e) => {
        onMouseLeave && onMouseLeave(e);
        setIsHover(false);
      }}
      style={{
        ...style,
        cursor: "pointer",
        opacity: isHover ? 1 : 1,
        transition: "all .5s",
      }}
    >
      <circle
        cx={18}
        cy={18}
        r={17}
        fill={fillColor}
        stroke={borderStrokeColor}
        strokeWidth={2}
        style={{
          transition: "all .5s",
        }}
      />
      <path
        stroke={strokeColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.9895 17.9906L15.4395 22.5384"
        style={{
          transition: "all .5s",
        }}
      />
      <path
        stroke={strokeColor}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.6427 22.7373L24.8446 13.1672"
        style={{
          transition: "all .5s",
        }}
      />
      <path
        style={{ display: `${showRedDot ? "block" : "none"}` }}
        d="M36 8a5 5 0 1110 0 5 5 0 01-10 0z"
        fill="#EB5757"
      />
    </svg>
  );
}
export default CompliancePayIcon;
