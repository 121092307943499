import { useRef } from "react";

// tslint:disable-next-line: ban-types
export default function usePersistantCallbackRef<T extends Function>(
  callback: T
): any {
  const callbackRef = useRef<T>(callback);

  const fn = (...args: Array<unknown>) => {
    callbackRef.current?.(...args);
  };
  const singletonFuncRef = useRef<T>(fn as any);

  callbackRef.current = callback;

  return singletonFuncRef.current;
}
