import React, { Component, PureComponent } from "react";
import {
  Modal,
  Input,
  notification,
  Button,
  Row,
  Col,
  Form,
  Typography,
} from "antd";
import { FormInstance } from "antd/lib/form";
import commonStyle from "~/component/common.module.css";
import OtpInput from "react-otp-input";
import { registerEvent } from "~/analytics";
import { generateOTP, updateGstUsername, verifyOTP } from "~/api/compliance";
import GstIniconWithOutText from "./GstIniconWithOutText";
import VideoDrawer from "./VideoDrawer";
import VanillaButton from "./VanillaButton";
import { AdminDetail } from "~/api/profile";
import { AuthState, StoreDispatch, action } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";

import * as ga from "~/contants/gaConstants";

type FormValue = {
  gst_user_name: any;
  gstUserName: any;
  otpValue: string | undefined;
};

interface Props {
  visible: boolean;
  onModalVisibleChange: (val: boolean) => void;
  handleStartRecocile?: () => void;
  refereshLoading?: boolean;
  otpAlreadyVerified: boolean;
}

interface State {
  formValue: FormValue;
  otpSent: boolean;
  otpVerifyMsg: string;
  loadingVerify: boolean;
  loadingGenerate: boolean;
  openVideoPlayer: boolean;
  gstUserName: string;
}

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  userId: state.mainAppSlice.user.id,
  companyName: state.mainAppSlice.user.company_name,
  saveEmpDetails: state.mainAppSlice.user.save_emp_details,
  showSyncButtonTour: state.mainAppSlice.showSyncButtonTour,
  employeeName:
    state.mainAppSlice.user.first_name +
    " " +
    state.mainAppSlice.user.last_name,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  showSyncStatusModal: () => dispatch(action.modal.showSyncStatusModal()),
  hideStartSyncFromSourceModal: () =>
    dispatch(action.modal.hideStartSyncFromSourceModal()),
  updateGstUserName: (userName: string) =>
    dispatch(action.auth.updateGstUserName(userName)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
// tslint:disable-next-line: no-empty-interface
interface Props extends PropsFromRedux {}
const instrumentList = [
  { name: "B2B", ready: true },
  { name: "B2B Debit notes", ready: false },
  { name: "B2B Credit notes", ready: false },
  { name: "TCS", ready: false },
  { name: "IMPG", ready: false },
];
class OtpForm extends PureComponent<Props, State> {
  state: State = {
    formValue: {
      gst_user_name: "",
      gstUserName: "",
      otpValue: undefined,
      // gstUserName: "",
    },
    otpSent: false,
    otpVerifyMsg: "",
    loadingVerify: false,
    loadingGenerate: false,
    openVideoPlayer: false,
    gstUserName: "",
  };

  _formRef = React.createRef<FormInstance<any>>();

  componentDidMount() {
    const userDataJSON = localStorage.getItem("imt__user") ?? "";
    const userData: AdminDetail = JSON.parse(userDataJSON);
    this.setState({
      gstUserName: userData.gst_user_name,
      formValue: {
        ...this.state.formValue,
        gstUserName: userData.gst_user_name,
        gst_user_name: userData.gst_user_name,
      },
    });
    // setTimeout(() => {
    this._formRef.current?.setFieldsValue({
      gst_user_name: userData.gst_user_name ?? "",
    });
    // }, 300);
  }

  handleValuesChange = (
    values: FormValue,
    changedValues: Partial<FormValue>
  ) => {
    // console.log("%s::%s", __filename, "handleValuesChange", { changedValues });
    if (changedValues.gstUserName || changedValues.gst_user_name) {
      this.setState({
        gstUserName: changedValues.gst_user_name,
        formValue: {
          ...this.state.formValue,
          gstUserName: changedValues.gstUserName || changedValues.gst_user_name,
          gst_user_name: changedValues.gst_user_name,
        },
      });
    }

    if (changedValues.otpValue) {
      this.setState({
        formValue: {
          ...this.state.formValue,
          otpValue: changedValues.otpValue,
        },
      });
    }
  };

  generateOTP = async (e: React.MouseEvent) => {
    if (!this.state.formValue.gstUserName) {
      notification.error({
        message: "Username is required",
      });
      return;
    }
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.otpForm28
    );
    this.setState({ loadingGenerate: true });
    try {
      const res = await generateOTP(this.state.formValue.gstUserName);
      if (res.ok) {
        notification.success({ message: "OTP sent to your mobile number" });
        this.setState({
          otpSent: true,
        });
      } else {
        notification.error({ message: res.message });
        this.setState({
          otpSent: false,
        });
      }
      //   this.doCloseRefreshModal();
      this.setState({ loadingGenerate: false });
    } catch (err) {
      this.setState({ loadingGenerate: false });
      notification.error({ message: "Something went wrong" });
    }
  };

  verifyOTP = async (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.otpForm29
    );
    this.setState({ loadingVerify: true });
    try {
      // this._formRef.current?.validateFields();
      const otp = parseInt(this.state.formValue.otpValue!, 10);

      if (!isNaN(otp)) {
        const verifyOTPRes = await verifyOTP(
          this.state.formValue.gstUserName,
          this.state.formValue.otpValue!
        );
        if (verifyOTPRes.ok) {
          this.setState({
            loadingVerify: false,
            //  showGenerateOTP: true,
            // verifyOtpModalVisible:true,
            otpVerifyMsg: "OTP verified",
          });
          notification.success({ message: "OTP verified" });
        } else {
          this.setState({
            loadingVerify: false,
          });
          notification.error({ message: verifyOTPRes.message });
        }

        const updateUsername = await updateGstUsername(
          this.state.formValue.gstUserName
        );
        if (updateUsername.ok) {
          const userData = localStorage.getItem("imt__user");
          let updatedUserdata: any = {};
          if (userData) {
            this.props.updateGstUserName(this.state.formValue.gstUserName);
            updatedUserdata = JSON.parse(userData);
            updatedUserdata.gst_user_name = this.state.formValue.gstUserName;
            localStorage.setItem("imt__user", JSON.stringify(updatedUserdata));
          }
        } else {
          this.setState({ loadingVerify: false });
          notification.error({ message: updateUsername.message });
        }
      } else {
        this.setState({ loadingVerify: false });
        notification.error({ message: "OTP must be an integer" });
      }
    } catch (err) {
      this.setState({ loadingVerify: false });
      notification.error({ message: "Something went wrong" });
    }
  };

  handleCancel = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.otpForm30
    );

    this.props.onModalVisibleChange(false);
  };

  render() {
    const { visible } = this.props;
    const showFinalStage =
      this.props.otpAlreadyVerified || this.state.otpVerifyMsg.length > 0;
    return (
      <>
        <Modal
          maskClosable={false}
          // title={"Verify OTP"}
          width={400}
          visible={visible}
          onCancel={this.handleCancel}
          className="modal-headless"
          //   cancelButtonProps={{ style: { display: "none" } }}
          //   onOk={this.handleSave}
          //   confirmLoading={this.state.loadSave}
          //   okText="Save"

          footer={
            showFinalStage && (
              <>
                <Button onClick={this.handleCancel} type="ghost">
                  Cancel
                </Button>
                <Button
                  loading={this.props.refereshLoading}
                  onClick={this.props.handleStartRecocile}
                  type="primary"
                >
                  Start reconcile
                </Button>
              </>
            )
          }
          // closable={false}
          destroyOnClose={true}
          centered
        >
          <div style={{ textAlign: "center" }}>
            <GstIniconWithOutText />
            <Typography.Title
              level={4}
              style={{
                textAlign: "center",
              }}
            >
              <b style={{ color: " gray" }}>GSTIN</b>
            </Typography.Title>
          </div>
          <VideoDrawer
            url="https://youtu.be/amzSuvg_mPw"
            title="Enable API access in GST portal"
            onClose={() => this.setState({ openVideoPlayer: false })}
            openDrawer={this.state.openVideoPlayer}
          />
          <Typography.Title
            level={4}
            style={{
              textAlign: "center",
            }}
          >
            Make sure that you have enabled API access in GST portal. This is
            how you do it:{" "}
            <VanillaButton
              text="See video"
              onClick={() => this.setState({ openVideoPlayer: true })}
            />
          </Typography.Title>
          {showFinalStage ? (
            <>
              <>{this.state.otpVerifyMsg && <hr />}</>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <b style={{ textAlign: "center", color: "green" }}>
                  {this.state.otpVerifyMsg}
                </b>
              </div>
              <hr />
              <div>
                <div>
                  Following instruments are eligible for reconciliation.{" "}
                </div>
                <ul>
                  {instrumentList.map((i: any) => {
                    return (
                      <li key={i.name}>
                        <span>
                          <b>{i.name}</b>
                        </span>{" "}
                        {"  "}{" "}
                        {i.ready ? null : (
                          <span>
                            <i>Coming soon</i>
                          </span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          ) : (
            <>
              <Form
                ref={this._formRef}
                onValuesChange={this.handleValuesChange}
                initialValues={this.state.formValue}
              >
                <Row gutter={{ md: 8, lg: 16 }}>
                  <Col sm={24} lg={24}>
                    {!this.state.otpSent ? (
                      <>
                        <Form.Item
                          className={commonStyle["w-100"]}
                          name="gst_user_name"
                          label="GST username"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Username",
                            },
                          ]}
                        >
                          <Input
                            placeholder="GST username"
                            value={this.state.gstUserName}
                            autoFocus
                          />
                        </Form.Item>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 20,
                          }}
                        >
                          <Button
                            loading={this.state.loadingGenerate}
                            onClick={this.generateOTP}
                            type="primary"
                          >
                            Generate OTP
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // border:'1px solid red'
                            marginLeft: "-17px",
                          }}
                        >
                          <Form.Item
                            className={commonStyle["w-100"]}
                            name="otpValue"
                            label={
                              <span
                                style={{
                                  marginLeft: "79%",
                                  fontWeight: 800,
                                  width: "100%",
                                  fontSize: "17px",
                                }}
                              >
                                {" "}
                                Please Enter OTP
                              </span>
                            }
                            // rules={[
                            //   { required: true, message: "Please enter OTP" },
                            // ]}
                            style={{ width: "100%" }}
                          >
                            {/* <Input /> */}
                            {/* <div style={{width:'100%'}}> */}
                            <OtpInput
                              inputStyle={{
                                width: "2em",
                                height: "2em",
                                fontSize: "20px",
                                // margin: '4px 0.5rem',
                                // marginRight:'10px',
                                // marginLeft:'10px',
                                margin: "10px",
                                borderRadius: 4,
                                border: "0.5px solid #2F80ED",
                              }}
                              containerStyle={{
                                width: "100%",
                                justifyContent: "space-between",
                                marginRight: "5px",
                              }}
                              value={this.state.formValue.otpValue}
                              // onChange={this.handleChange}
                              shouldAutoFocus={true}
                              numInputs={6}
                              separator={<span>-</span>}
                            />
                            {/* </div> */}
                          </Form.Item>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 20,
                          }}
                        >
                          <Button
                            loading={this.state.loadingVerify}
                            onClick={this.verifyOTP}
                            type="primary"
                          >
                            Verify OTP
                          </Button>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Modal>
      </>
    );
  }
}

export default connector(OtpForm);
