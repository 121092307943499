import { Button, notification, Row, Spin, Typography } from "antd";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { registerEvent } from "~/analytics";
import { fetchUtil } from "~/api/common";
import { createSubscription, getPlanFeature } from "~/api/license";
import { action, AuthState, StoreDispatch } from "~/app/MainApp/store";
import { CHEQD_LOGO_URL } from "~/config";
import { titles } from "~/contants/titles";
import IMTContent from "~/layout/main-layout/IMTContent";
import styles from "../../../src/feature/settings-membership/SettingsMembership.module.css";

import * as ga from "~/contants/gaConstants";
// import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";
import { RenderPlanSingle } from "./PlanSingle";

const mapStateToProps = (state: AuthState) => {
  const user = state.mainAppSlice.user;
  const userNameOrEmail = user.first_name ?? user.email ?? "";
  const userFirstChar = userNameOrEmail.substr(0, 1).toUpperCase();
  const companyCount = user.added_clients ?? 0;
  const { showSyncButtonTour } = state.mainAppSlice;
  return {
    userDetail: user,
    steps: state.mainAppSlice.steps,
    activeRole: user.activeRole,
    userFirstChar,
    companyCount,
    showSyncButtonTour,
    isSyncing: state.modalSlice.isSyncing,
    syncInterval: state.modalSlice.syncInterval,
    currentTourStep: state.mainAppSlice.currentTourStep,
    currentStepKey: showSyncButtonTour
      ? state.mainAppSlice.steps[state.mainAppSlice.currentTourStep]?.key
      : "",
  };
};
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  empdetail: (a: any) => dispatch(action.auth.empDetailsSaved(a)),
  dochange: (company_id: any, role_id?: any) =>
    dispatch(action.auth.doChangeCompany(company_id)),
  setActiveRole: (role: any) => dispatch(action.auth.setActiveRole(role)),
  updateFirstLastName: (values: any) =>
    dispatch(action.auth.updateFirstLastName(values)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface State {
  activeTerm: string;
  planList: any;
  userData: any;
  executiveDetails: any;
  loadData: boolean;
  companyListView: boolean;
  updateList: boolean;
  admin_email_of_active_company: string | null;
  admin_company_name_of_active_company: string | null;
  showAllPlans: boolean;
}

type PlanData = {
  plan_id: string;
  license_details_id: number;
  mrp_amount: number;
  description: string;
  name: string;
};

class MembershipPlan extends Component<any, State> {
  state: State = {
    activeTerm: "MONTHLY",
    planList: [],
    userData: {},
    executiveDetails: {},
    loadData: true,
    companyListView: false,
    updateList: false,
    admin_email_of_active_company: null,
    admin_company_name_of_active_company: null,
    showAllPlans: false,
  };

  handeleMonthly = (e: any) => {
    this.setState({
      activeTerm: "MONTHLY",
    });
  };
  handeleAnnual = (e: any) => {
    this.setState({
      activeTerm: "ANNUAL",
    });
  };

  getAdminDetails = async () => {
    const { ok, /* message, */ json } = await fetchUtil(
      "GET",
      "/get_admin_details"
    );
    if (ok && json[0]) {
      const {
        admin_email: admin_email_of_active_company,
        admin_company_name: admin_company_name_of_active_company,
      } = json[0];
      this.setState({
        admin_email_of_active_company,
        admin_company_name_of_active_company,
      });
    }
  };

  handleLogout = () => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.membershipPlan158
    );

    localStorage.clear();
    window.location.pathname = "/";
  };

  handleCheckout = async (planData: PlanData) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_PLAN_CHECKOUT,
      ga.events.membershipPlan159,
      {
        plan_name: planData.name,
        plan_id: planData.plan_id,
        plan_amount: planData.mrp_amount,
      }
    );

    this.setState({
      loadData: true,
    });
    const { ok, message, json } = await createSubscription({
      name: `${this.state.userData.first_name} ${this.state.userData.last_name}`,
      mrp_amount: planData.mrp_amount,
      plan_id: planData.plan_id,
      license_details_id: planData.license_details_id,
      currency: "INR",
    });
    if (!ok) {
      notification.warning({
        message,
      });
    } else {
      this.setState({
        loadData: false,
      });
      const subsResult = json[0];
      const subsId = subsResult?.id;

      if (!subsId) {
        notification.warning({
          message: "Valid subscription id not received.",
        });
        return;
      }

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
        amount: String(planData.mrp_amount * 100), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "ThetaOne",
        description: planData.description,
        image: CHEQD_LOGO_URL,
        subscription_id: subsId,
        handler: async (response: any) => {
          registerEvent(
            ga.EVENT_CATEGORY_PURCHASE,
            ga.EVENT_PURCHASE,
            planData.name
          );
          // console.log("response =", response);
          /* console.info(response.razorpay_payment_id);
          console.info(response.razorpay_subscription_id);
          console.info(response.razorpay_signature); */
          const userData = { ...this.state.userData };

          userData.plan_purchased = true;
          userData.plan_name = planData.name;
          localStorage.setItem("imt__user", JSON.stringify(userData));

          window.location.pathname = "/app/dashboard";
          await fetchUtil("POST", "/rzp_payment_handler", {
            id: response.razorpay_subscription_id,
            payment_status: true,
            payment_time: new Date(),
          });
          // this.state.
        },
        prefill: {
          name: `${this.state.userData.first_name} ${this.state.userData.last_name}`,
          email: `${this.state.userData.email}`,
          mobile: `${this.state.executiveDetails.phone_number}`,
          //   contact: "9999999999",
        },
        // notes: {
        //   address: "Razorpay Corporate Office",
        // },
        theme: {
          color: "#3399cc",
        },
      };
      //   console.log("options =", options);
      const rzp1 = new (window as any).Razorpay(options);
      rzp1.open();
    }
  };

  handleActivate = (plan: any, periodBasedPlan: any) => async () => {
    const freeTrialDays = plan.free_trial_days;
    const amt = periodBasedPlan.discounted_amount;
    const onOk = async () => {
      const planData: PlanData = {
        plan_id: periodBasedPlan.plan_id,
        license_details_id: periodBasedPlan.license_details_id,
        mrp_amount: periodBasedPlan.mrp_amount,
        description: plan.description,
        name: plan.name,
      };
      await this.handleCheckout(planData);
    };

    if (freeTrialDays > 0) {
      confirm({
        title: "Just a moment !",
        icon: <ExclamationCircleOutlined />,
        content: (
          <>
            <br />
            <u>₹5 will be deducted momentarily</u> for account verification
            which will soon be refunded to the source account.
            <br />
            <br />
            At the end of {freeTrialDays} days of free trial, the subscription
            amount (₹{amt}) will be deducted at the beginning of each period.
            <br />
            <br />
            Don't worry.{" "}
            <u>You can cancel anytime to prevent any future deductions.</u>
          </>
        ),
        okText: "Proceed",
        onOk,
      });
    } else {
      await onOk();
    }
  };

  loadPlan = async () => {
    const { ok, message, json } = await getPlanFeature();
    if (!ok) {
      notification.warning({
        message,
      });
    } else {
      json.forEach((plan) => {
        plan.licenses.forEach((license: any) => {
          license.term = license.period === "30 days" ? "MONTHLY" : "ANNUAL";
        });
      });
      this.setState({
        planList: json,
        loadData: false,
      });
    }
  };

  loadExecutiveDetails = async () => {
    const { ok, message, json } = await fetchUtil(
      "POST",
      "/get_executive_details",
      {}
    );
    if (!ok) {
      notification.error({ message });
    } else {
      this.setState({
        executiveDetails: json[0],
      });
    }
  };

  componentDidMount() {
    this.loadPlan();
    this.loadExecutiveDetails();
    this.getAdminDetails();
    const userData = JSON.parse(localStorage.getItem("imt__user") as any);
    this.setState({
      userData,
    });
  }

  handleRestoreState = () => {
    this.setState({
      updateList: false,
    });
  };

  visibleAddClint = () => {
    this.setState({
      companyListView: false,
      updateList: this.state.companyListView ? true : false,
    });
  };

  handleCompanyPopup = (visible: boolean) => {
    this.setState({
      companyListView: visible,
    });
  };

  handleCompanyChange = async (companyId: any) => {
    const company = this.props.userDetail.company_details.find(
      (it: any) => it.id === companyId
    );
    if (company.name === this.props.userDetail.company_name) {
      notification.error({
        message: "Already login with this company",
      });
    } else {
      this.props.dochange({ company_id: companyId });
    }
  };

  handleSwitch = (comId: any, role: any) => {
    this.props.dochange({ company_id: comId, role_id: role });
  };

  toggleShowAllPlans = () => {
    this.setState({ showAllPlans: !this.state.showAllPlans });
  };

  render() {
    const userData = localStorage.getItem("imt__user");
    const userParseData = JSON.parse(userData!);
    // console.log(userParseData,"llllll")
    return (
      <IMTContent fullwidth withoutMargin={false}>
        <Helmet>
          <title>{titles.MembershipPlan}</title>
        </Helmet>
        <Spin spinning={this.state.loadData}>
          <div style={{ width: "100%", minHeight: "100%" }}>
            <Helmet>
              <script
                src="https://checkout.razorpay.com/v1/checkout.js"
                type="text/javascript"
              />
              <title>{titles.MembershipPage}</title>
            </Helmet>
            <div className={styles.membership}>
              <div className={`${styles.wrapPlan} ${styles.dashboardWrap}`}>
                <div className={styles.plan}>Choose membership plan</div>
                <div className={styles.logout}>
                  <Typography.Title level={5}>
                    Logged in as{" "}
                    <b className={styles.bold}>{this.state.userData.email}</b>{" "}
                    [&nbsp;
                    <Typography.Link onClick={this.handleLogout}>
                      Logout
                    </Typography.Link>
                    &nbsp;]
                  </Typography.Title>
                </div>

                {this.state.userData?.activeRole === 5 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "var(--grey-2)",
                      fontSize: 10,
                      fontWeight: 800,
                      maxWidth: 400,
                      margin: "0 auto",
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    Note: The plan will be purchased on behalf of the admin (
                    {this.state.admin_email_of_active_company}) of{" "}
                    {this.state.admin_company_name_of_active_company}
                  </div>
                )}

                {/* <div className={styles.logout}>
                  <Typography.Title level={5}>
                    Company Name
                    <b className={styles.bold}>
                      {" "}
                      &nbsp;{this.state.userData.company_name}
                    </b>
                  </Typography.Title>
                </div> */}
                <div className={styles.switch}>
                  <div className={styles.switchWrap}>
                    <input
                      id="toggle-on"
                      className={`${styles.toggle} ${styles.toggleleft}`}
                      name="toggle"
                      type="radio"
                      checked={this.state.activeTerm === "ANNUAL"}
                    />
                    <label
                      htmlFor="toggle-on"
                      className={`${styles.btn} ${styles.btnleft} `}
                      onClick={this.handeleAnnual}
                    >
                      Annual plan
                    </label>
                    <input
                      id="toggle-off"
                      className={`${styles.toggle} ${styles.toggleright}`}
                      name="toggle"
                      type="radio"
                      checked={this.state.activeTerm === "MONTHLY"}
                    />
                    <label
                      htmlFor="toggle-off"
                      className={`${styles.btn} ${styles.btnright}`}
                      onClick={this.handeleMonthly}
                    >
                      Monthly plan
                    </label>
                  </div>
                </div>

                <div className={`site-card-wrapper ${styles.wrap}`}>
                  <Row className={styles.planCardWrapper} gutter={46}>
                    {this.state.planList.map((plan: any, planIndex: number) => {
                      if (!this.state.showAllPlans && planIndex >= 3) {
                        // Only show 3 plans if collapsed
                        return false;
                      }
                      if (!plan.display_on_ui) {
                        return false;
                      }

                      const periodBasedPlan = plan.licenses.find(
                        (it: any) => it.term === this.state.activeTerm
                      );
                      if (!periodBasedPlan) {
                        return false;
                      }
                      return (
                        <RenderPlanSingle
                          key={`${Math.random()}`}
                          plan={plan}
                          license={periodBasedPlan}
                          activeTerm={this.state.activeTerm}
                          purchaseMode={true}
                          currentPlanStatus="Activate"
                          isPurchaseEnabled={
                            userParseData.added_clients > plan?.client_no
                          }
                          handleActivate={this.handleActivate}
                        />
                      );
                    })}
                  </Row>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button onClick={this.toggleShowAllPlans} type="link">
                    Show {this.state.showAllPlans ? "less" : "more"} plans
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Spin>
        <span>&nbsp;</span>
      </IMTContent>
    );
  }
}

export default connector(withRouter(MembershipPlan));
