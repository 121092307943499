import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Space,
  Table,
  Tooltip,
  notification,
  Pagination,
  Row,
  // Menu,
  Upload,
  Modal,
  // Dropdown,
} from "antd";
import { ColumnType } from "antd/lib/table/interface";
import {
  getExpenseList,
  // getExpenseSummary,
  getPurDebitList,
  getPurDebitSummary,
  getTableExportBlob,
} from "~/api/expense";
import {
  format,
  getMonth,
  subMonths,
  getYear,
  addYears,
  subYears,
} from "date-fns";
import {
  Expense,
  ExpenseSummary,
  PaymentDetails,
} from "~/feature/expense/ExpenseModal";
import Currency from "~/component/Currency";
import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import PaymentDialog from "./modal/PaymentDialog";
// import PaymentPending from "~/component/PaymentPending";
// import ReconcileFileUpload from "~/component/ReconcileUpload";
import { getVendorList } from "~/api/vendor";
import YearMonthDropdown from "~/fragment/year-month-dropdown/YearMonthDropdown";
import styles from "./ExpensePage.module.css";
import commonStyle from "~/component/common.module.css";
import SummaryTags from "~/component/SummaryTags";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
// import {mainAppSlice} from "~/app/MainApp/mainAppSlice"
import { LoginRole } from "~/api/auth";
import IMTMediaQueries from "~/component/IMTMediaQueries";
import { RcFile } from "antd/lib/upload";
import {
  HourglassOutlined,
  ExclamationCircleOutlined,
  // MoreOutlined,
  // IdcardTwoTone,
} from "@ant-design/icons";
import { fetchUtil } from "~/api/common";
import FieldInfo from "~/component/FieldInfo";
import { months, SUPPORTED_EXT_LIST } from "~/lib/constants";
import { registerEvent } from "~/analytics";
// import ExportButton from "~/fragment/table-export/ExportButton";
import { Helmet } from "react-helmet";
import { titles } from "~/contants/titles";

import * as ga from "~/contants/gaConstants";

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  sortOrderInfo: state.mainAppSlice.user.sort_order,
  sorterData: state.mainAppSlice.user.sorter,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  empdetail: (a: any) => dispatch(action.auth.empDetailsSaved(a)),
  dochange: (company_id: any) =>
    dispatch(action.auth.doChangeCompany(company_id)),
  setSortOrder: (sortOrderInfo: any) =>
    dispatch(action.auth.setSortOrder(sortOrderInfo)),
  setSorter: (sorter: any) => dispatch(action.auth.setSorter(sorter)),
});

// const connector = connect(mapStateToProps, {
//   empdetail: action.auth.empDetailsSaved,
// });

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends RouteComponentProps, PropsFromRedux {}
interface State {
  summary: Partial<ExpenseSummary>;
  expenseList: Array<Expense>;
  sortedInfo: any;
  filteredInfo: any;
  tableLoading: boolean;
  activePage: number;
  noOfRows: number;
  totalItemCount?: number;
  itemLength: number;
  timeZoneOfset: number;
  payModal: boolean;
  selectedExpense?: Expense;
  selectedPaymentDetails?: PaymentDetails;
  curYear: any;
  curMonth: number;
  sortOrder: string;
  vendorPickList: any;
}

// const { Dragger } = Upload;

class PurcheserDebitPage extends Component<Props, State> {
  state: State = {
    summary: {},
    expenseList: [],
    selectedExpense: undefined,
    itemLength: 1,
    activePage: 1,
    noOfRows: 12,
    totalItemCount: 0,
    sortedInfo: {},
    filteredInfo: {},
    tableLoading: true,
    timeZoneOfset: 330,
    payModal: false,
    curYear: -1,
    curMonth: -1,
    sortOrder: "",
    vendorPickList: [],
  };

  static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State
  ): Partial<State> | null {
    const query = new URLSearchParams(nextProps.location.search);
    let currentPage = 1;
    const today = new Date();
    const isBefore25th = today.getDate() < 25;
    const selectedDate = isBefore25th ? subMonths(today, 1) : today;
    // let year = selectedDate.getFullYear();
    // const current = new Date();
    // let year: any = `${getYear(current)}-${getYear(addYears(current, 1))}`;
    const current = new Date();
    const yearCurrent = new Date().getFullYear();
    const eligible =
      current.getTime() <= new Date(`${yearCurrent}-04-25`).getTime() &&
      current.getTime() > new Date(`${yearCurrent - 1}-04-25`).getTime();
    let year: any = eligible
      ? `${getYear(subYears(current, 1))}-${getYear(current)}`
      : `${getYear(current)}-${getYear(addYears(current, 1))}`;
    let month = getMonth(selectedDate);
    if (query.has("year")) {
      const _yearStr = query.get("year") + "";
      if (/\d{4}/.test(_yearStr)) {
        year = _yearStr;
      }
    }
    if (query.has("month")) {
      // const _monthNum = +(query.get("month") + "");
      const _monthNum = +months.indexOf(query.get("month") + "");
      if (Number.isFinite(_monthNum)) {
        month = _monthNum;
      }
    }
    if (query.has("page")) {
      const pageNo = +query.get("page")!;
      if (Number.isFinite(pageNo)) {
        currentPage = pageNo;
      }
    }
    const newState: Partial<State> = {
      curYear: year,
      curMonth: month,
      activePage: currentPage,
      sortOrder: query.get("sort_order") || "",
    };
    if (
      prevState.curYear !== newState.curYear ||
      prevState.curMonth !== newState.curMonth ||
      prevState.activePage !== newState.activePage ||
      prevState.sortOrder !== newState.sortOrder
    ) {
      return newState;
    }
    return null;
  }

  componentDidMount() {
    this.doRefreshData();
    this.loadPickupLists();
    if (this.props.sorterData) {
      this.setState({
        sortedInfo: this.props.sorterData,
      });
    }
  }

  loadPickupLists = async () => {
    const [vendorRes] = await Promise.all([
      getVendorList().then(({ ok, message, data }) => ({
        ok,
        message,
        data,
      })),
    ]);

    if (!vendorRes.ok) {
      notification.error({
        message: "Failed to load Vendors",
        description: vendorRes.message,
      });
    } else {
      this.setState({
        vendorPickList: vendorRes.data,
      });
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>) {
    if (prevProps.location.search !== this.props.location.search) {
      this.doRefreshData();
    }
    if (_prevState.expenseList !== this.state.expenseList) {
      if (this.state.expenseList.length) {
        this.placeButton();
      }
    }
  }

  placeButton = () => {
    const element = document.getElementsByClassName(
      "ant-pagination-item-link"
    )[0];
    if (element) {
      const { left, top } = element.getBoundingClientRect();
      const btn = document.getElementById("exportBtn");
      if (btn) {
        btn.style.position = "absolute";
        btn.style.left = left - 30 + "px";
        btn.style.top = top + "px";
        btn.style.margin = "0";
        btn.style.display = "inline";
      }
    }
    // }, 1000);
  };

  doRefreshData = async () => {
    this.setState({
      tableLoading: true,
    });
    if (
      this.props.activeRole === LoginRole.ADMIN ||
      this.props.activeRole === LoginRole.CA ||
      this.props.activeRole === LoginRole.SUPERuSER
    ) {
      this.fetchSummary();
    }
    const { ok, data } = await getPurDebitList(
      this.state.activePage,
      this.state.timeZoneOfset,
      this.state.noOfRows,
      this.state.curMonth + 1 + "" === "13"
        ? "ALL"
        : this.state.curMonth + 1 + "",
      this.state.curYear + "",
      this.props.sortOrderInfo || "DATE_DESC"
    );
    if (ok) {
      let totalArrayLength: number | undefined = 0;
      if (data && data.length > 0) {
        totalArrayLength = data[0].total_rows;
      }
      this.setState({
        expenseList: data as Array<Expense>,
        tableLoading: false,
        totalItemCount: totalArrayLength,
      });
    }
  };

  fetchSummary = async () => {
    const { ok, message, data } = await getPurDebitSummary(
      this.state.curMonth + 1 === 13 ? "ALL" : this.state.curMonth + 1,
      this.state.curYear
    );
    if (!ok) {
      notification.warn({
        message: "Failed to load summary",
        description: message,
      });
      this.setState({
        summary: {},
      });
    } else {
      this.setState({
        summary: data!,
      });
    }
  };

  handleAddNew = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.purcheserDebitPage78
    );
    this.props.history.push(`/app/expense/purchasedebitNote/new`);
  };

  handleTableChange = (_pagination: any, filters: any, sorter: any) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", _pagination.current + "");
    let sortOrder = "";

    /**
     * TODO: check if api support multiple sortOrder
     */
    if (sorter.columnKey === "id") {
      if (sorter.order === "descend") {
        sortOrder = "ID_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "ID_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "p_debit_note_date") {
      if (sorter.order === "descend") {
        sortOrder = "DATE_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "DATE_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
        this.props.setSortOrder(sortOrder);
      }
    } else if (sorter.columnKey === "net_total") {
      if (sorter.order === "descend") {
        sortOrder = "AMT_DESC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === "ascend") {
        sortOrder = "AMT_ASC";
        this.props.setSortOrder(sortOrder);
      }
      if (sorter.order === false || undefined) {
        sortOrder = "";
      }
    }

    query.set("sort_order", sortOrder);

    this.props.history.push("?" + query);
    this.props.setSorter(sorter);

    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter ? sorter : this.props.sorterData,
    });
  };

  handleCancel = () => {
    this.setState({
      payModal: false,
    });
  };

  handleDlgVisibleChange = (requestedVisibility: boolean) => {
    this.setState({
      payModal: requestedVisibility,
    });
  };

  handleFileDrop = (_file: RcFile, fileList: Array<RcFile>) => {
    console.info("handleFileDrop", fileList);
    if (fileList.length > 0) {
      const firstFile = fileList[0];
      const fileParts = firstFile.name.split(".");
      if (fileParts.length > 0) {
        const ext = fileParts[fileParts.length - 1].toLowerCase();
        if (!SUPPORTED_EXT_LIST.includes(ext)) {
          notification.warn({
            message: "Unsupported file",
            description: "Please upload a pdf or a image file",
          });
          return false;
        }
      }
    }
    this.props.history.push(`/app/expense/new`, { fileList });
    return false;
  };

  getRowProps = (record: Expense, _rowIndex?: number) => {
    return {
      onClick: (_event: React.MouseEvent) => {
        registerEvent(
          ga.EVENT_CATEGORY_BUTTON_CLICK,
          ga.EVENT_CLICK,
          ga.events.purcheserDebitPage79
        );

        if (record.expense_id) {
          const id = record.id;
          this.props.history.push(
            `/app/expense/${record.expense_id}/purchasedebitNote/${id}`,
            {
              search: this.props.location.search,
            }
          );
        } else {
          const id = record.id;
          this.props.history.push(`/app/expense/purchasedebitNote/${id}`, {
            search: this.props.location.search,
          });
        }
      },
    };
  };

  handlePageChange = (pageNumber: number) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", pageNumber + "");
    this.props.history.push("?" + query);
  };

  handleEmailCapture = (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.purcheserDebitPage80
    );
    notification.success({
      icon: <HourglassOutlined className={commonStyle["wip-icon"]} />,
      message: "This Feature is coming soon. Stay tuned.",
    });
  };

  handlePaymentBtnClick = (record: Expense) => (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.purcheserDebitPage81
    );
    e.stopPropagation();
    if (record.paymentStatus === "Completed") {
      notification.success({ message: "Already paid" });
      return;
    }
    if (
      this.props.activeRole === LoginRole.ADMIN ||
      this.props.activeRole === LoginRole.SUPERuSER
    ) {
      this.setState({
        payModal: true,
        selectedExpense: record,
      });
    } else {
      notification.warn({
        message: "Access denied",
        description: "You don't have access to payment action.",
      });
    }
  };

  handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.purcheserDebitPage82
    );
    e.stopPropagation();
    const _expense_id = e.currentTarget.dataset.id;
    if (_expense_id) {
      const expense_id = +_expense_id;
      const invoice = this.state.expenseList.find((it) => it.id === expense_id);
      this.props.history.push("/app/expense/copy?from=" + _expense_id, invoice);
    }
  };

  // tslint:disable-next-line: no-empty
  handleRipple = () => {};

  handlePurchesDebitNote = (
    e: React.MouseEvent<HTMLSpanElement>,
    expense: Expense
  ) => {
    e.stopPropagation();
    const keys = e.currentTarget.dataset.id;
    const _expense_id = keys?.split("/")[0];
    const _drId = keys?.split("/")[1];
    if (_expense_id) {
      const expense_id = +_expense_id;
      const drId = _drId ? +_drId : null;
      const expense = this.state.expenseList.find((it) => it.id === expense_id);
      this.props.history.push(
        drId
          ? `/app/expense/${_expense_id}/purchasedebitNote/${drId}`
          : `/app/expense/${_expense_id}/purchasedebitNote/new`,
        expense
      );
    }
  };

  handleDelete = (e: React.MouseEvent<HTMLSpanElement>, expense: Expense) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.purcheserDebitPage83
    );
    e.stopPropagation();
    const expenseId = e.currentTarget.dataset.id;
    Modal.confirm({
      title:
        expense.compliance_status === "Complete" &&
        expense.paymentStatus === "Completed" ? (
          <>
            <div>Do you really wish to delete this expense?</div>
            <div>
              Note: Payment for this expense is already logged and one or more
              compliances are completed.
            </div>{" "}
          </>
        ) : expense.paymentStatus === "Completed" ? (
          <>
            <div>Do you wish to delete this expense?</div>
            <div>Note: Payment for this expense is already logged</div>{" "}
          </>
        ) : (
          <>
            <div>Do you wish to delete this expense?</div>
          </>
        ),
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        const { ok, message } = await fetchUtil(
          "POST",
          "/update_expense/delete_expense",
          {
            receipt_id: expenseId,
          }
        );
        if (!ok) {
          notification.warn({ message });
        } else {
          notification.success({ message });
          this.doRefreshData();
          this.loadPickupLists();
        }
      },
    });
  };

  refreshAfterReconcile = (isSubmit: boolean) => {
    if (isSubmit) {
      this.doRefreshData();
    }
  };

  renderFooter = () => {
    return (
      <div className={styles.footerWrap}>
        <Pagination
          size="small"
          current={this.state.activePage}
          showSizeChanger={false}
          pageSize={this.state.noOfRows}
          onChange={this.handlePageChange}
          total={this.state.totalItemCount}
        />
      </div>
    );
  };

  handleFetchData = () => {
    return getExpenseList(
      1,
      this.state.timeZoneOfset,
      1,
      this.state.curMonth + 1 + "",
      this.state.curYear + "",
      this.props.sortOrderInfo || "DATE_DESC"
    );
  };

  handleDownload = async (acceptType: string, selectedFields: string) => {
    return getTableExportBlob(
      1,
      this.state.timeZoneOfset,
      999999,
      this.state.curMonth + 1 + "",
      this.state.curYear + "",
      this.props.sortOrderInfo || "DATE_DESC",
      acceptType,
      selectedFields
    );
  };

  render() {
    const { summary } = this.state;
    let { sortedInfo, filteredInfo } = this.state;

    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    const columns: Array<ColumnType<Expense>> = [
      {
        title: " Supplier credit note id",
        dataIndex: "id",
        key: "id",
        // width: 110,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "id" && sortedInfo.order,
        render: (id, row) => {
          return <div>{id}</div>;
        },
      },
      {
        title: " Exp. id",
        dataIndex: "expense_id",
        key: "expense_id",
        sorter: false,
        sortOrder: sortedInfo.columnKey === "expense_id" && sortedInfo.order,
        render: (expense_id, row) => {
          return <div>{expense_id ? expense_id : "--"}</div>;
        },
      },

      {
        title: "Vendor",
        dataIndex: "customer_idx",
        key: "customer_idx",
        ellipsis: true,
        render: (customer_idx, record) => {
          const customer_name = customer_idx
            ? this.state.vendorPickList.find(
                (it: any) => it.customer_idx === customer_idx
              )?.trade_name
            : "N/A";
          return (
            <span>
              <Tooltip title={customer_name}>
                <p className={styles.tableDataEllipsis}>{customer_name}</p>
              </Tooltip>
              <div
                style={{
                  marginTop: "10px",
                  marginLeft: "-5px",
                }}
              >
                {customer_name !== "N/A" ? (
                  <FieldInfo
                    style={{
                      maxWidth: "98px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    text={record.gstin}
                  />
                ) : null}
              </div>
            </span>
          );
        },
      },

      {
        title: "Supplier credit note date",
        dataIndex: "p_debit_note_date",
        key: "p_debit_note_date",

        sorter: true,
        sortOrder:
          sortedInfo.columnKey === "p_debit_note_date" && sortedInfo.order,
        render: (p_debit_note_date) => ({
          children: format(new Date(p_debit_note_date), "dd/MM/yyyy"),
        }),
      },

      {
        title: "Amount",
        dataIndex: "net_total",
        key: "net_total",
        align: "right",
        filteredValue: filteredInfo.net_total || null,
        sorter: true,
        sortOrder: sortedInfo.columnKey === "net_total" && sortedInfo.order,
        ellipsis: false,

        render: (balance_due, record) => {
          const { net_total, tds_amount, paid_amount } = record;
          return (
            <span>
              <div>
                <span style={{ whiteSpace: "nowrap" }}>
                  {record.symbol_name !== "INR" && record.symbol_name ? (
                    <>
                      <Currency currencySymbol={record.customer_currency}>
                        {balance_due}
                      </Currency>
                      <span>
                        {" "}
                        (
                        <Currency>
                          {+balance_due * +record.conversion_rate!}
                        </Currency>
                        )
                      </span>
                    </>
                  ) : (
                    <Currency currencySymbol={"₹"}>{balance_due}</Currency>
                  )}
                </span>
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <Tooltip
                  title={`Payment due breakup: ₹${
                    record.symbol_name !== "INR"
                      ? +net_total * +record.conversion_rate!
                      : net_total
                  } - ₹${tds_amount * +record.conversion_rate! ?? 0}(tds) - ₹${
                    paid_amount * +record.conversion_rate! ?? 0
                  }(paid)`}
                />
              </div>
            </span>
          );
        },
      },
    ];

    const { curMonth, curYear } = this.state;

    return (
      <IMTContent withoutMargin={true}>
        <Helmet>
          <title>{titles.PurchaserDebitNotePage}</title>
        </Helmet>
        <IMTPageHeader
          breadcumTexts={["Supplier credit note"]}
          style={{ paddingLeft: "0" }}
          actions={
            <Space>
              {this.props.activeRole === LoginRole.ADMIN ||
              this.props.activeRole === LoginRole.SUPERuSER ? (
                <>
                  <Button type="primary" onClick={this.handleAddNew}>
                    + Add new
                  </Button>
                </>
              ) : (
                ""
              )}
            </Space>
          }
        />

        <IMTMediaQueries>
          {(matches) => (
            <>
              <YearMonthDropdown
                month={curMonth}
                year={curYear}
                payrollFlag={false}
              />
              <Row className={styles.summaryDiv}>
                <SummaryTags
                  nameValuePairs={[
                    {
                      name: "Count",
                      value: summary.pur_deb_count
                        ? summary.pur_deb_count + ""
                        : "0",
                    },
                    {
                      name: "Amount",
                      value: summary.pur_deb_amount,
                    },
                  ]}
                />
              </Row>

              <div className={styles.tableWrap}>
                <Table
                  data-tour="my-first-expense-step"
                  rowKey="id"
                  className={styles.purTable}
                  columns={columns}
                  dataSource={this.state.expenseList}
                  onChange={this.handleTableChange}
                  onRow={this.getRowProps}
                  loading={this.state.tableLoading}
                  size={matches.xl ? "large" : "small"}
                  pagination={{
                    size: "small",
                    current: this.state.activePage,
                    pageSize: this.state.noOfRows,
                    showSizeChanger: false,
                    onChange: this.handlePageChange,
                    total: this.state.totalItemCount,
                    position: ["topRight"],
                    style: { margin: "0px" },
                  }}
                />
              </div>
            </>
          )}
        </IMTMediaQueries>
        {this.state.selectedExpense && (
          <PaymentDialog
            visible={this.state.payModal}
            onVisibleChange={this.handleDlgVisibleChange}
            expense={this.state.selectedExpense}
            fetchData={this.doRefreshData}
            vendorPickList={this.state.vendorPickList}
            handleRipple={this.handleRipple}
          />
        )}
      </IMTContent>
    );
  }
}

export default connector(PurcheserDebitPage);
