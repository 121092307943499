import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import { Provider } from "react-redux";
import { store } from "./store";
import MainApp from "./MainApp";

// tslint:disable-next-line: no-empty-interface
interface IProps extends RouteComponentProps {
  // bbbbb
}

const ConfiguredMainApp: FC<IProps> = (props: IProps) => {
  return (
    <Provider store={store}>
      <MainApp {...props} />
    </Provider>
  );
};

export default ConfiguredMainApp;
