import React from "react";
import { checkIfArrayItemsAreAllObjects } from "../../helpers/helpers";
import JSONViewerText from "../jsonViewerText";
import styles from "~/component/JsonDownloadPreview.module.css";

type Props = {
  arrayToView: any;
  currentLayer: any;
  setDataOfALayer: any;
  previousLayers?: any;
};
function ArrayViewText({ arrayToView, currentLayer, setDataOfALayer }: Props) {
  const result = checkIfArrayItemsAreAllObjects;

  if (result(arrayToView)[0]) {
    // Render Keys
    return (
      <>
        <thead>
          <tr>
            <td style={{ minWidth: "100px" }}>&nbsp;</td>
            {result(arrayToView)[1].map((oneKey) => {
              return (
                <td key={oneKey} style={{ minWidth: "100px" }}>
                  {oneKey.trim()}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles.tbodyText1}>
          {arrayToView.map((item: any, index: number) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return (
              <tr key={currentLayer + "_" + index} className={styles.arrayTr}>
                <td>&nbsp;&nbsp;{index + 1}&nbsp;&nbsp;</td>
                {result(arrayToView)[1].map((oneKey: any, ind: number) => {
                  return result(arrayToView)[1].length - 1 === ind ? (
                    <td key={index + oneKey} className={styles.tdata1}>
                      <div>
                        <JSONViewerText
                          JSONToView={item[oneKey]}
                          currentLayer={[currentLayer, index + "", oneKey]
                            .filter((a) => a)
                            .join(",")}
                          setDataOfALayer={setDataOfALayer}
                        />
                      </div>
                    </td>
                  ) : (
                    <td key={index + oneKey} className={styles.tdata1}>
                      <JSONViewerText
                        JSONToView={item[oneKey]}
                        currentLayer={[currentLayer, index + "", oneKey]
                          .filter((a) => a)
                          .join(",")}
                        setDataOfALayer={setDataOfALayer}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </>
    );
  } else {
    return arrayToView.map((row: any, index: any) => {
      return (
        <tbody key={index + "_key"} className={styles.tbody1}>
          <tr
            style={{ border: "1px solid black", display: "block" }}
            className={styles.trow1}
          >
            <td className={styles.trowD1}>{index}</td>
            <td style={{ textAlign: "left" }} className={styles.trowD1}>
              {
                <JSONViewerText
                  JSONToView={row}
                  currentLayer={[currentLayer, index + ""]
                    .filter((a) => a)
                    .join(",")}
                  previousLayers={currentLayer}
                  setDataOfALayer={setDataOfALayer}
                />
              }
            </td>
          </tr>
        </tbody>
      );
    });
  }
}

export default ArrayViewText;
