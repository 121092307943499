import React from "react";
import { Space, Button } from "antd";
import { LoginRole } from "~/api/auth";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import PaymentPending from "~/component/PaymentPending";
import styles from "~/feature/expense/expense-detail/ExpenseDetailsPage.module.css";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

interface Props {
  isNew?: boolean;
  salerDebitNoteId?: any;
  expenseId?: number;
  activeRole: LoginRole;
  isEditing: boolean;
  rippleEffect: boolean;
  paymentStatus: string;
  handlePaymentDialog: (e: React.MouseEvent) => void;
  apiState: string;
  slug: string;
  onCancel: (e: React.MouseEvent) => void;
  onDelete: (e: React.MouseEvent) => void;
  onSave: (e: React.MouseEvent) => void;
  onEdit: (e: React.MouseEvent) => void;
}
const SalesDebitNotePageHeader = (props: Props) => {
  return (
    <IMTPageHeader
      breadcumTexts={[
        "Expense",
        props.isNew
          ? `New supplier debit note`
          : `View supplier debit note (${props.salerDebitNoteId})`,
      ]}
      actions={
        <Space align="center">
          {props.activeRole === LoginRole.ADMIN ||
          props.activeRole === LoginRole.SUPERuSER ? (
            <>
              <Button
                type="primary"
                disabled={props.apiState === "loading" && props.slug !== "copy"}
                className={styles.save_edit_button}
                onClick={(e: any) => {
                  const buttonName = props.isEditing
                    ? "Save"
                    : props.apiState === "loading"
                    ? "loading"
                    : "Edit";
                  registerEvent(
                    ga.EVENT_CATEGORY_BUTTON_CLICK,
                    ga.EVENT_CLICK,
                    `SalesDebitNote>${buttonName}`
                  );
                  if (props.isEditing) {
                    props.onSave(e);
                  } else {
                    props.onEdit(e);
                  }
                }}
              >
                {props.isEditing
                  ? "Save"
                  : props.apiState === "loading"
                  ? "loading"
                  : "Edit"}
              </Button>
              {/* )} */}
              {props.isEditing ? (
                <Button
                  type="dashed"
                  onClick={(e: any) => {
                    registerEvent(
                      ga.EVENT_CATEGORY_BUTTON_CLICK,
                      ga.EVENT_CLICK,
                      `SalesDebitNote>Cancel`
                    );
                    props.onCancel(e);
                  }}
                >
                  Cancel
                </Button>
              ) : null}
              {props.isNew ? null : props.isEditing ? null : (
                <Button danger onClick={props.onDelete}>
                  Delete
                </Button>
              )}
            </>
          ) : (
            ""
          )}
        </Space>
      }
    />
  );
};

export default SalesDebitNotePageHeader;
