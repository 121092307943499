import React, { PureComponent } from "react";
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  notification,
  Drawer,
} from "antd";
import { FormInstance } from "antd/lib/form";
import FieldInfo from "~/component/FieldInfo";
import {
  formatDateHourMinSec,
  formatNumber,
  formatYear,
  getCommaSeparated,
  roundNumber,
} from "~/lib";
import { getTxnAmount, tdsPayConfirm } from "~/api/expense";
import { Expense } from "~/feature/expense/ExpenseModal";
import { APIState, fetchUtil } from "~/api/common";
import { required } from "~/lib/form_rules";
import commonStyle from "~/component/common.module.css";
import { registerEvent } from "~/analytics";
import * as ga from "~/contants/gaConstants";
import VendorTransactions from "~/feature/vendor/VendorTransactions";
import styles from "./TdsPayment.module.css";

const { Option } = Select;

interface Props {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  expense: Expense;
  fetchData: () => void;
  cheackFlag?: boolean;
  forEditSave?: (data: any) => void;
  taxApplicableType: string;
  conversionRate: any;
  symbol: any;
}

type PaymentCaptureData = {
  tds_rate?: number;
  tds_amount?: any;
  bill_amount?: number | string;
  expense_id?: number;
  total_pay_amount?: number | string;
};

interface State {
  paymentData?: PaymentCaptureData;
  expenseAmount: string;
  panNumber: string;
  selectedYear: string;
  apiState: APIState;
  showVendorTransactions: boolean;
}

export default class TdsPayment extends PureComponent<Props, State> {
  state: State = {
    paymentData: undefined,
    expenseAmount: "",
    panNumber: "",
    selectedYear: "",
    apiState: "idle",
    showVendorTransactions: false,
  };

  _formRef = React.createRef<FormInstance<PaymentCaptureData>>();

  componentDidMount() {
    this.updateFormUsingProps();
    if (this.props.expense.customer_id) {
      this.getVendorDetails();
      this.fetchTxnAmount(
        this.props.expense.customer_id,
        formatDateHourMinSec(this.props.expense.receipt_date)
      );
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.expense !== this.props.expense) {
      this.updateFormUsingProps();
    }
  }

  getVendorDetails = async () => {
    const { ok, json, message } = await fetchUtil(
      "POST",
      `/get_customer_details`,
      {
        customer_id: this.props.expense.customer_id,
        no_of_rows: 1,
        page_no: 1,
      }
    );

    if (ok) {
      const data = json[0];
      if (data) {
        this.setState({
          panNumber: data?.pan_number,
        });
      }
    } else {
      notification.error({ message });
    }
  };

  fetchTxnAmount = async (id: any, date: any) => {
    const { ok, message, data } = await getTxnAmount(id, date);
    if (!ok) {
      notification.warn({
        message: "Failed to load transaction amount",
        description: message,
      });
    } else {
      this.setState({
        expenseAmount: data?.expense[0]?.total_amount,
        selectedYear: data?.fy?.fy,
      });
    }
  };

  onValuesChange = (
    changedValues: Partial<PaymentCaptureData>,
    values: PaymentCaptureData
  ) => {
    let shouldUpdateFormData = false;
    const details = this.props.expense;
    const { igst, sgst, cgst } = details;
    if (changedValues.tds_rate) {
      let calNetTotal = 0;
      let calTotalGst = 0;
      let calTotalCess = 0;

      if (this.props.cheackFlag) {
        const totalAmount = this.props.expense.row_data
          .map((item) => (item.is_active ? +item.amount : 0))
          .reduce((prev, curr) => prev + curr, 0);

        calTotalCess = this.props.expense.row_data
          .map((item) => {
            const cess = item.is_active
              ? item.amount *
                (item.extra?.cessRate
                  ? item.extra?.cessRate?.cessPercentage
                  : 0 / 100)
              : 0;
            return cess;
          })
          .reduce((prev, curr) => prev + curr, 0);
        calTotalGst = this.props.expense.row_data
          .map((item) =>
            item.is_active ? item.igst + item.cgst + item.sgst : 0
          )
          .reduce((prev, curr) => prev + curr, 0);
        calNetTotal =
          this.props.taxApplicableType === "InclusiveOfTax"
            ? totalAmount - (calTotalGst + calTotalCess)
            : totalAmount;
        values.tds_amount = formatNumber(
          calNetTotal *
            (changedValues.tds_rate / 100) *
            this.props.conversionRate
        );
        values.total_pay_amount = formatNumber(
          (calNetTotal -
            calNetTotal * (changedValues.tds_rate / 100) +
            calTotalGst +
            calTotalCess) *
            this.props.conversionRate
        );
      } else {
        values.tds_amount = formatNumber(
          +details.amount! *
            (changedValues.tds_rate / 100) *
            details.conversion_rate!
        );

        const calculatedPayable = Number(
          +details.amount -
            +details.amount! * (changedValues.tds_rate / 100) +
            (Number(cgst) +
              Number(igst) +
              Number(sgst) +
              Number(details.cess_amount))
        );
        values.total_pay_amount = formatNumber(
          calculatedPayable * details.conversion_rate!
        );
      }

      shouldUpdateFormData = true;
    }

    this.setState(
      {
        paymentData: values,
      },
      () => {
        if (shouldUpdateFormData) {
          this._formRef?.current?.setFieldsValue(this.state.paymentData!);
        }
      }
    );
  };

  calculateTds = (details: Expense) => {
    let tdsAmount = 0;
    let totalPayAmount = 0;
    if (this.props.cheackFlag) {
      //         calNetTotal = this.props.expense.row_data
      //           .map((item) => item.amount + item.igst + item.cgst + item.sgst)
      //           .reduce((prev, curr) => prev + curr, 0);
      //           calTotalGst= this.props.expense.row_data
      //           .map((item) => item.igst + item.cgst + item.sgst)
      //           .reduce((prev, curr) => prev + curr, 0);
      //           tdsAmount= calNetTotal*(1/100);
      //           totalPayAmount=calNetTotal-tdsAmount+calTotalGst
      // console.log(tdsAmount,totalPayAmount)
    } else {
      const { igst, sgst, cgst } = details;
      tdsAmount = Number(
        formatNumber(+details.amount! * (details.tds_rate / 100))
      );
      totalPayAmount = Number(
        formatNumber(
          +details.amount -
            tdsAmount +
            (Number(cgst) +
              Number(igst) +
              Number(sgst) +
              Number(details.cess_amount))
        )
      );
    }

    return [tdsAmount, totalPayAmount];
  };

  updateFormUsingProps = async () => {
    const details = this.props.expense;
    if (
      (this.props.expense.tds_rate || this.props.expense.tds_amount) &&
      !this.props.cheackFlag
    ) {
      // ***********this part need for later **********
      // const [tdsAmount, total] = this.calculateTds(details as Expense);
    } else {
      let calNetTotal = 0;
      let calTotalCess = 0;
      if (this.props.cheackFlag !== undefined) {
        calTotalCess = this.props.expense.row_data
          .map((item) => {
            const cess =
              item.amount *
              (item.is_active
                ? item.extra?.cessRate
                  ? item.extra?.cessRate?.cessPercentage
                  : 0 / 100
                : 0);
            return cess;
          })
          .reduce((prev, curr) => prev + curr, 0);

        calNetTotal =
          this.props.taxApplicableType === "InclusiveOfTax"
            ? this.props.expense.row_data
                .map((item) => (item.is_active ? item.amount : 0))
                .reduce((prev, curr) => prev + curr, 0)
            : this.props.expense.row_data
                .map((item) =>
                  item.is_active
                    ? item.amount + item.igst + item.cgst + item.sgst
                    : 0
                )
                .reduce((prev, curr) => prev + curr, 0) + calTotalCess;
      }

      const paymentData: PaymentCaptureData = {
        bill_amount: !this.props.cheackFlag
          ? formatNumber(Number(details.net_total))
          : formatNumber(calNetTotal),
        total_pay_amount: !this.props.cheackFlag
          ? formatNumber(Number(details.net_total) * this.props.conversionRate)
          : formatNumber(calNetTotal * this.props.conversionRate),
        expense_id: details.id,
        tds_amount: 0,
      };

      this.setState(
        {
          paymentData,
        },
        () => {
          this._formRef.current?.setFieldsValue(this.state.paymentData!);
        }
      );
    }
  };

  handleCancel = (_e: React.MouseEvent<HTMLElement>) => {
    this.props.onVisibleChange(false);
  };

  handleOk = async (_e: React.MouseEvent<HTMLElement>) => {
    await this._formRef.current?.validateFields();
    if (!this.props.cheackFlag) {
      if (this.props.expense.tds_rate && this.props.expense.tds_amount) {
        // ********need for later ******* (( ## incase tds rate are already defined ## //
      } else {
        this.setState({
          apiState: "loading",
        });
        try {
          await this._formRef.current?.validateFields();
          const data = {
            expense_id: Number(this.state.paymentData?.expense_id),
            tds_amount: this.state.paymentData!.tds_amount!,
            tds_rate: this.state.paymentData!.tds_rate!,
          };
          const { ok, message } = await tdsPayConfirm(data);
          // this.props.fetchData();
          this.setState({
            apiState: "idle",
          });
          this.props.onVisibleChange(false);
          if (!ok) {
            notification.error({ message });
          } else {
            notification.success({ message: "TDS updated successfully" });
            // this.props.handleRipple();
          }
        } catch (err) {
          console.warn(err);
          this.setState({
            apiState: "idle",
          });
        }
      }
    } else {
      this.props.forEditSave!(this.state.paymentData!);
    }
  };

  showVendorsDrawer = (record: any) => (e: React.MouseEvent) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      `Expense details > Save expense > TDS update > Show transactions`
    );
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showVendorTransactions: true });
  };

  onVendorDrawerClose = () => {
    this.setState({
      showVendorTransactions: false,
    });
  };

  render() {
    const { visible, expense } = this.props;
    const { panNumber, expenseAmount, selectedYear } = this.state;
    let calNetTotal = 0;
    let calTotalGst = 0;
    let calTotalCess = 0;
    if (this.props.cheackFlag) {
      const total = this.props.expense.row_data
        .map((item) => (item.is_active ? +item.amount : 0))
        .reduce((prev, curr) => prev + curr, 0);
      calTotalGst = this.props.expense.row_data
        .map((item) => (item.is_active ? item.igst + item.cgst + item.sgst : 0))
        .reduce((prev, curr) => prev + curr, 0);
      calTotalCess = this.props.expense.row_data
        .map((item) => {
          const cess = item.is_active
            ? item.amount *
              (item.extra?.cessRate
                ? item.extra?.cessRate?.cessPercentage
                : 0 / 100)
            : 0;
          return cess;
        })
        .reduce((prev, curr) => prev + curr, 0);
      calNetTotal =
        this.props.taxApplicableType === "InclusiveOfTax"
          ? total - (calTotalGst + calTotalCess)
          : total;
    }

    return (
      <>
        <Modal
          title="TDS payment"
          visible={visible}
          confirmLoading={this.state.apiState === "loading"}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText="Confirm"
          centered
          destroyOnClose
          maskClosable={false}
          width={380}
        >
          <Form
            ref={this._formRef}
            initialValues={this.state.paymentData}
            onValuesChange={this.onValuesChange}
          >
            <Row gutter={{ xs: 40, sm: 100, md: 100, lg: 10, xl: 90 }}>
              <Col span={24}>
                <Form.Item name={"bill_amount"} label="Bill amount">
                  <Input readOnly />
                </Form.Item>
                <FieldInfo
                  text={
                    !this.props.cheackFlag
                      ? `= Taxable: ${expense.customer_currency} ${formatNumber(
                          expense.amount ? +expense.amount : 0
                        )} + GST: ${expense.customer_currency}${formatNumber(
                          Number(expense.cgst) +
                            Number(expense.igst) +
                            Number(expense.sgst)
                        )}+ Cess: ${expense.customer_currency}${formatNumber(
                          +expense.cess_amount!
                        )}`
                      : `= Taxable: ${this.props.symbol} ${formatNumber(
                          calNetTotal
                        )} + GST: ${this.props.symbol}${formatNumber(
                          calTotalGst
                        )}+ Cess: ${this.props.symbol}${formatNumber(
                          calTotalCess
                        )}`
                  }
                />
                {expenseAmount ? (
                  <Form.Item>
                    <div
                      className={`ant-tag ant-tag-warning ${styles.vendorTransaction}`}
                    >
                      {`${getCommaSeparated(
                        +expenseAmount,
                        expense.customer_currency
                      )}`}{" "}
                      ( Total transaction amount in FY {selectedYear} with{" "}
                      {expense?.customer_name
                        ? expense.customer_name
                        : expense.extra?.vendor?.trade_name}{" "}
                      (PAN {panNumber ? panNumber : `"N/A"`}) )<br />
                      <a
                        href="#"
                        style={{
                          color: "var(--antd-wave-shadow-color)",
                        }}
                        onClick={this.showVendorsDrawer(
                          expense.customer_id
                            ? expense.customer_id
                            : expense.extra?.vendor?.customer_id
                        )}
                      >
                        See all transactions
                      </a>
                    </div>
                  </Form.Item>
                ) : null}
                <Form.Item
                  className={commonStyle["w-100"]}
                  name="tds_rate"
                  label="TDS rate"
                  rules={[required("TDS rate")]}
                >
                  <Select showSearch>
                    <Option value={1}>1.0% TDS</Option>
                    <Option value={2}>2.0% TDS</Option>
                    <Option value={5}>5.0% TDS</Option>
                    <Option value={7.5}>7.5% TDS</Option>
                    <Option value={10}>10.0% TDS</Option>
                    <Option value={15}>15.0% TDS</Option>
                    <Option value={20}>20.0% TDS</Option>
                    <Option value={30}>30.0% TDS</Option>
                    <Option value={40}>40.0% TDS</Option>
                    <Option value={50}>50.0% TDS</Option>
                  </Select>
                </Form.Item>

                <Form.Item name="tds_amount" label="Tds amount">
                  <Input readOnly />
                </Form.Item>
                <Form.Item name="total_pay_amount" label="Payable">
                  <Input readOnly />
                </Form.Item>
                <FieldInfo
                  text={
                    !this.props.cheackFlag
                      ? `= Taxable: ${expense.customer_currency} ${formatNumber(
                          expense?.amount ? +expense?.amount : 0
                        )} - TDS: ${expense.customer_currency} ${formatNumber(
                          (parseFloat(
                            String(this.state.paymentData?.tds_amount).replace(
                              /[^\d\.\-]/g,
                              ""
                            )
                          ) || 0) / expense.conversion_rate!
                        )} + GST: ${expense.customer_currency} ${formatNumber(
                          Number(expense.cgst) +
                            Number(expense.igst) +
                            Number(expense.sgst)
                        )} + Cess: ${expense.customer_currency} ${formatNumber(
                          +expense.cess_amount!
                        )} `
                      : `= Taxable: ${this.props.symbol} ${formatNumber(
                          calNetTotal
                        )} - TDS: ${this.props.symbol} ${formatNumber(
                          (parseFloat(
                            String(this.state.paymentData?.tds_amount).replace(
                              /[^\d\.\-]/g,
                              ""
                            )
                          ) || 0) / this.props.conversionRate
                        )} + GST: ${this.props.symbol}${formatNumber(
                          calTotalGst
                        )} + Cess: ${this.props.symbol}${formatNumber(
                          calTotalCess
                        )}  `
                  }
                />
                <div style={{ marginTop: "10px" }}>
                  <FieldInfo
                    text={
                      this.props.symbol !== "₹"
                        ? `(1 ${this.props.symbol}= ${roundNumber(
                            +this.props.conversionRate
                          )})`
                        : expense.customer_currency !== "₹"
                        ? `(1 ${expense.customer_currency}= ${roundNumber(
                            +expense.conversion_rate!
                          )})`
                        : ``
                    }
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </Modal>
        {this.state.showVendorTransactions ? (
          <Drawer
            width={"55%"}
            placement="right"
            closable={false}
            onClose={this.onVendorDrawerClose}
            visible={this.state.showVendorTransactions}
            destroyOnClose={true}
          >
            <VendorTransactions
              customerid={expense.customer_id ? expense.customer_id : 0}
              customerName={
                expense.customer_name
                  ? expense.customer_name
                  : expense.extra?.vendor?.trade_name
              }
              vendorPageFy={
                this.state.selectedYear
                  ? this.state.selectedYear
                  : formatYear(expense.receipt_date)
              }
            />
          </Drawer>
        ) : (
          <></>
        )}
      </>
    );
  }
}
