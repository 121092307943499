import React, { Component } from "react";
import { Table, notification, Row, Tooltip, Input, Col, Button } from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { RouteChildrenProps } from "react-router";

import IMTContent from "~/layout/main-layout/IMTContent";
import IMTPageHeader from "~/layout/main-layout/IMTPageHeader";
import { AuthState, action, StoreDispatch } from "~/app/MainApp/store";
import { connect, ConnectedProps } from "react-redux";
import { fetchUtil } from "~/api/common";
import styles from "~/feature/productMaster/ProductMaster.module.css";
import { subMonths, getMonth, addYears, getYear, subYears } from "date-fns";
import { PaymentAPIData, productMasterData } from "~/api/payment";
import { months } from "~/lib/constants";

import * as ga from "~/contants/gaConstants";
import { debounce } from "lodash";
import { Helmet } from "react-helmet";

import AddProductModalDialog from "~/feature/invoice/modal/AddProductModal";
import { PaymentStatus, ProductItem } from "~/api/invoice";
import { getProductList } from "~/api/vendor";
import { date, string } from "random-js";
import Search from "antd/lib/input/Search";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { LoginRole } from "~/api/auth";
import Currency from "~/component/Currency";
const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
  sortOrderInfo: state.mainAppSlice.user.sort_order,
  sorterData: state.mainAppSlice.user.sorter,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  setSortOrder: (sortOrderInfo: any) =>
    dispatch(action.auth.setSortOrder(sortOrderInfo)),
  setSorter: (sorter: any) => dispatch(action.auth.setSorter(sorter)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends RouteChildrenProps, PropsFromRedux {}

interface State {
  rowData: Array<productMasterData>;
  pagination: {
    pageSize: number;
    pageNo: number;
    pageCount: number;
  };
  apiState: "idle" | "loading" | "error";
  apiSummaryState: "idle" | "loading" | "error";
  monthSelect: number;
  yearSelect: any;
  activePage: number;
  noOfRows: number;
  totalItemCount: number;
  sortedInfo: any;
  filteredInfo: any;
  sortOrder: string;
  search: string;
  highlightText: string;
  addNewBox: boolean;
}
class PaymentPage extends Component<Props, {}> {
  state: State = {
    addNewBox: false,
    rowData: [],
    pagination: {
      pageSize: 10,
      pageNo: 1,
      pageCount: 1,
    },
    apiState: "idle",
    apiSummaryState: "idle",
    yearSelect: "",
    monthSelect:
      new Date().getDate() < 25
        ? getMonth(subMonths(new Date(), 1))
        : getMonth(new Date()),
    activePage: 1,
    noOfRows: 12,
    totalItemCount: 0,
    sortedInfo: {},
    filteredInfo: {},
    sortOrder: "",
    search: "",
    highlightText: "",
  };

  static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State
  ): Partial<State> | null {
    const query = new URLSearchParams(nextProps.location.search);
    let currentPage = 1;
    const today = new Date();
    const isBefore25th = today.getDate() < 25;
    const selectedDate = isBefore25th ? subMonths(today, 1) : today;
    const current = new Date();
    const yearCurrent = new Date().getFullYear();
    const eligible =
      current.getTime() <= new Date(`${yearCurrent}-04-25`).getTime() &&
      current.getTime() > new Date(`${yearCurrent - 1}-04-25`).getTime();
    let year: any = eligible
      ? `${getYear(subYears(current, 1))}-${getYear(current)}`
      : `${getYear(current)}-${getYear(addYears(current, 1))}`;

    let month = getMonth(selectedDate);

    if (query.has("year")) {
      const _yearStr = query.get("year") + "";
      if (/\d{4}/.test(_yearStr)) {
        year = _yearStr;
      }
    }
    if (query.has("month")) {
      const _monthNum = +months.indexOf(query.get("month") + "");

      // console.log(_monthNum, "--------------------");
      if (Number.isFinite(_monthNum)) {
        month = _monthNum;
      }
    }
    if (query.has("page")) {
      const pageNo = +query.get("page")!;
      if (Number.isFinite(pageNo)) {
        currentPage = pageNo;
      }
    }

    let search = "";
    let highlightText = "";
    if (query.has("search_item") && query.get("search_item")) {
      const searchItem = query.get("search_item");
      if (searchItem) {
        search = searchItem;
        highlightText = searchItem;
      }
    }
    const newState: Partial<State> = {
      yearSelect: year,
      monthSelect: month,
      activePage: currentPage,
      sortOrder: query.get("sort_order") || "",
      search,
      highlightText,
    };
    if (
      prevState.yearSelect !== newState.yearSelect ||
      prevState.monthSelect !== newState.monthSelect ||
      prevState.activePage !== newState.activePage ||
      prevState.sortOrder !== newState.sortOrder ||
      prevState.search !== newState.search
    ) {
      return newState;
    }
    return null;
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    query.set("sort_order", "");
    this.props.history.push("?" + query);
    if (this.props.sorterData) {
      this.setState({
        sortedInfo: this.props.sorterData,
      });
    }
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>) {
    if (
      _prevState.yearSelect !== this.state.yearSelect ||
      _prevState.monthSelect !== this.state.monthSelect ||
      _prevState.activePage !== this.state.activePage
    ) {
      this.loadData();
    }
  }

  loadData = async () => {
    this.setState({
      apiState: "loading",
    });
    // /get_all_payment_details
    // /get_products
    const { ok, message, json } = await fetchUtil("POST", "/get_products", {
      search_str: this.state.search,
      page_no: this.state.activePage,
      no_of_rows: this.state.noOfRows,
      sort_param: this.props.sortOrderInfo || "PNAME_ASC",
    });
    if (!ok) {
      notification.error({ message });
      this.setState({
        apiState: "error",
      });
    } else {
      const data = json as Array<productMasterData>;
      this.setState({
        rowData: data,
        apiState: "idle",
      });
    }
  };

  handlePageChange = (pageNumber: number) => {
    const query = new URLSearchParams(this.props.location.search);
    const prevPage = query.get("page");
    if (prevPage === pageNumber + "") return;
    query.set("page", pageNumber + "");
    this.props.history.push("?" + query);
    this.setState(
      {
        activePage: pageNumber,
      },
      () => {
        this.loadData();
      }
    );
  };

  handleTableChange = (_pagination: any, filters: any, sorter: any) => {
    const query = new URLSearchParams(this.props.location.search);
    let sortOrder = "";

    if (sorter.columnKey === "product_name") {
      if (sorter.order === "descend") {
        sortOrder = "PNAME_DESC";
        this.props.setSortOrder(sortOrder);
      } else if (sorter.order === "ascend") {
        sortOrder = "PNAME_ASC";
        this.props.setSortOrder(sortOrder);
      }
    }
    // else if (sorter.columnKey === "customerEle") {
    //   if (sorter.order === "descend") {
    //     sortOrder = "CUST_DESC";
    //     this.props.setSortOrder(sortOrder);
    //   } else if (sorter.order === "ascend") {
    //     sortOrder = "CUST_ASC";
    //     this.props.setSortOrder(sortOrder);
    //   }
    // }
    if (!sorter.order) {
      sortOrder = "";
      this.props.setSortOrder(sortOrder);
    }

    const prev = query.get("sort_order");
    if (prev === sortOrder) return;

    query.set("sort_order", sortOrder);

    this.props.history.push("?" + query);
    this.props.setSorter(sorter);
    this.setState(
      {
        filteredInfo: filters,
        sortedInfo: sorter ? sorter : this.props.sorterData,
      },
      () => this.loadData()
    );
  };

  handleNewProductSubmit = async (val: ProductItem) => {
    const { ok, message } = await fetchUtil("POST", "/add_product", {
      product_description: val.description,
      product_name: val.product_name,
      hsn_sac: val.hsn_sac,
      rate: Number(val.rate),
      supply_type: val.supply_type,
      category_id: val.category_id,
      gst_rate_id: val.gst_rate_id,
      is_taxable: val.is_taxable,
      unit_id: val.unit_id,
      is_non_gst_supply: val.is_non_gst_supply,
      is_reverse_charge_applicable: val.is_reverse_charge_applicable,
    });
    if (!ok) {
      notification.warn({ message });
    } else {
      notification.success({ message });
      this.setState({ addNewBox: false });
      this.loadData();
    }
  };

  debouncedGetPaymentList = debounce(this.loadData, 500);

  handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchItem = e.target.value;
    const query = new URLSearchParams(this.props.location.search);
    query.set("page", 1 + "");
    query.set("search_item", searchItem);
    this.props.history.push("?" + query);
    this.setState(
      { search: e.target.value, highlightText: e.target.value },
      () => {
        this.debouncedGetPaymentList();
      }
    );
  };

  highlightText(text: string): JSX.Element {
    const { highlightText } = this.state;
    if (text) {
      const parts = text.split(new RegExp(`(${highlightText})`, "gi"));
      return (
        <>
          {parts.map((part: any, index: any) =>
            part.toLowerCase() === highlightText.toLowerCase() ? (
              <span key={index} className={styles["highlighted-text"]}>
                {part}
              </span>
            ) : (
              <span key={index}>{part}</span>
            )
          )}
        </>
      );
    } else {
      return <></>;
    }
  }

  render() {
    let { sortedInfo } = this.state;
    const { rowData } = this.state;
    sortedInfo = sortedInfo || {};
    const columnDef: Array<ColumnType<productMasterData>> = [
      {
        title: "Product name",
        dataIndex: "product_name",
        key: "product_name",
        sorter: true,
        sortOrder: sortedInfo.columnKey === "product_name" && sortedInfo.order,
        defaultSortOrder: "ascend",
        width: "10.5%",
        render: (product_name) => {
          return (
            <span>
              {product_name && (
                <Tooltip title={this.highlightText(product_name)}>
                  <p
                    className={styles.tableDataEllipsis}
                    style={{ textAlign: "left" }}
                  >
                    {this.highlightText(product_name)}
                  </p>
                </Tooltip>
              )}
            </span>
          );
        },
      },
      {
        title: "Descriptions",
        dataIndex: "description",
        key: "description",
        align: "center",
        width: "10%",
        ellipsis: true,
        render: (description) => {
          return (
            <span>
              <Tooltip title={description}>
                <p className={styles.tableDataEllipsis}>{description}</p>
              </Tooltip>
            </span>
          );
        },
      },
      {
        title: "HSN code",
        dataIndex: "hsn_sac",
        key: "hsn_sac",
        align: "center",
        ellipsis: true,
        width: "8%",
        render: (hsn_sac) => {
          return (
            <span>
              {hsn_sac && (
                <Tooltip title={hsn_sac}>
                  <p className={styles.tableDataEllipsis}>
                    {this.highlightText(hsn_sac)}
                  </p>
                </Tooltip>
              )}
            </span>
          );
        },
      },
      {
        title: "GST Rate",
        dataIndex: "gst_rate_id",
        key: "gst_rate_id",
        align: "center",
        width: "9%",
        render: (nature, row) => {
          return (
            <span style={{ textTransform: "capitalize" }}>
              {nature ? nature : 0}
            </span>
          );
        },
      },
      {
        title: "Supply type",
        dataIndex: "category_name",
        key: "category_name",
        align: "center",
        width: "9%",
        ellipsis: true,
        render: (category_name) => {
          return <span>{category_name}</span>;
        },
      },
      {
        title: "Category",
        dataIndex: "category_name",
        key: "category_name",
        align: "center",
        width: "9%",
        render: (category_name) => {
          return <span>{category_name}</span>;
        },
      },

      {
        title: "Selling Rate",
        dataIndex: "rate",
        key: "rate",
        align: "center",
        width: "11%",
        render: (rate) => {
          return (
            <span style={{ textTransform: "capitalize" }}>
              {" "}
              <Currency currencySymbol={"₹"}>{rate}</Currency>
            </span>
          );
        },
      },
      {
        title: "Unit",
        dataIndex: "unit",
        key: "unit",
        align: "center",
        width: "7%",
        render: (unit) => {
          return (
            <span>
              <div>
                <span style={{ whiteSpace: "nowrap" }}>
                  <span>{unit ? unit : "-"}</span>
                </span>
              </div>
            </span>
          );
        },
      },
      {
        title: "Reverse Charge",
        dataIndex: "is_reverse_charge_applicable",
        key: "is_reverse_charge_applicable",
        align: "center",
        width: "14%",
        render: (is_reverse_charge_applicable) => {
          return (
            <span style={{ textTransform: "capitalize" }}>
              {is_reverse_charge_applicable ? "Yes" : "No"}
            </span>
          );
        },
      },
      {
        title: "Non-GST Supply",
        dataIndex: "is_non_gst_supply",
        key: "is_non_gst_supply",
        align: "center",
        width: "14%",
        render: (is_non_gst_supply) => {
          return (
            <span style={{ textTransform: "capitalize" }}>
              {is_non_gst_supply ? "Yes" : "No"}
            </span>
          );
        },
      },
    ];

    return (
      <>
        <IMTContent withoutMargin={true}>
          <Helmet>
            <title>Cheqd - Product master</title>
          </Helmet>
          <div className="headerBox">
            <div className="pageNameBox">
              <IMTPageHeader
                breadcumTexts={["Product master"]}
                style={{ paddingLeft: "0" }}
                actions={
                  <>
                    {this.props.activeRole === 1 ||
                    this.props.activeRole === LoginRole.SUPERuSER ? (
                      <>
                        <Button
                          type="primary"
                          onClick={() => {
                            this.setState({ addNewBox: true });
                          }}
                        >
                          + Add new
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                }
              />
            </div>
          </div>
          {this.state.addNewBox ? (
            <AddProductModalDialog
              onProductSubmit={this.handleNewProductSubmit}
              visible={this.state.addNewBox}
              closeProductModal={() => {
                this.setState({ addNewBox: false });
              }}
              loadProduct={false}
            />
          ) : null}

          <Row>
            <Col>
              <Input
                placeholder="Search in Product name "
                onChange={this.handleInputChange}
                value={this.state.search}
                style={{
                  marginTop: "20px",
                  minWidth: "300px",
                  height: "32px",
                  marginBottom: "5px",
                  marginRight: "20px",
                }}
                allowClear
              />
            </Col>
          </Row>

          <Table
            rowKey="id"
            className={styles.paymentTable}
            columns={columnDef}
            dataSource={this.state.rowData}
            loading={
              this.state.apiState === "loading" ||
              this.state.apiSummaryState === "loading"
            }
            onChange={this.handleTableChange}
            size="large"
            pagination={{
              size: "small",
              current: this.state.activePage,
              pageSize: this.state.noOfRows,
              showSizeChanger: false,
              onChange: this.handlePageChange,
              total: rowData[0]?.total_rows,
              position: ["topRight"],
              style: { margin: "0px" },
            }}
          />
        </IMTContent>
      </>
    );
  }
}

export default connector(PaymentPage);
