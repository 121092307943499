import GeneralAPIError from "./api-errors/GeneralAPIError";

export const baseURL = process.env.REACT_APP_BASE_URL;

export type APIState = "idle" | "loading" | "success" | "error";

export type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE" | "HEAD";

export type FetchUtilReturnType = {
  ok: boolean;
  json: Array<any>;
  schema?: any;
  total?: any;
  message: string;
};

export const fetchUtil = async (
  method: HttpMethod = "GET",
  url: string,
  body?: any,
  withToken: boolean = true,
  headers: HeadersInit = {}
): Promise<FetchUtilReturnType> => {
  if (!url.startsWith("http")) {
    url = baseURL + url;
  }
  const _headers: any = { ...headers };
  if (body) {
    _headers["content-type"] = "application/json";
  }
  if (withToken) {
    _headers.Authorization = "Bearer " + localStorage.getItem("imt__token");
  }
  try {
    const res = await fetch(url, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: _headers,
      mode: "cors",
    });
    const ok = res.ok;
    const contentType = res.headers.get("content-type") + "";
    let json = null;
    let error: string | null = null;
    if (contentType.includes("application/json")) {
      json = await res.json();
    } else {
      const text = await res.text();
      try {
        json = JSON.parse(text);
      } catch (_ex) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(text, "text/xml");
        const title = xmlDoc.getElementsByTagName("title");
        error = title[0].childNodes[0].nodeValue;
        const p = xmlDoc.getElementsByTagName("p");
        error += ` (${p[0].childNodes[0].nodeValue})`;
      }
    }
    if (ok) {
      return {
        ok,
        json: json.results || json.result,
        schema: json.schema,
        total: json.total,
        message: json.message,
      };
    } else {
      // check for invalid token
      if (res.status === 401) {
        localStorage.clear();
        window.location.href = "/?reason=session_expired";
      }
      // check for unauthorized access
      if (res.status === 403) {
        window.location.pathname = "/app/unauthorized";
      }
      return {
        ok,
        json: (json && json.results) || [],
        message: (json && json.message) || error,
        schema: (json && json.schema) || [],
      };
    }
  } catch (err) {
    let message = (err as any).message;
    if (/childNodes/.test(message)) {
      message = "An error occurred while fetching data";
    }
    return {
      ok: false,
      json: [],
      message,
    };
  }
};

export const fetchTableExportBlob = async (
  method: HttpMethod = "GET",
  url: string,
  body?: any,
  acceptType?: string,
  selectedFields?: string
): Promise<{
  ok: boolean;
  blob?: Blob | null;
  message: string;
}> => {
  if (!url.startsWith("http")) {
    url = baseURL + url;
  }
  const headers: any = {};
  if (body) {
    headers["content-type"] = "application/json";
  }
  if (acceptType) {
    headers.accept = acceptType;
  }
  if (selectedFields) {
    headers["x-field-list"] = selectedFields;
  }
  headers.Authorization = "Bearer " + localStorage.getItem("imt__token");
  let ok = false;
  try {
    const res = await fetch(url, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers,
      mode: "cors",
    });
    ok = res.ok;
    const blob = await res.blob();

    return {
      ok: res.ok,
      blob,
      message: "success", // This will be changed
    };
  } catch (err) {
    return {
      ok,
      message: err.message,
    };
  }
};

/**
 * TODO:
 *    1. Why return Promise<Blob> instead of Blob
 *    2. Why always require body & assume its type as application/json?
 * @param url url relative to baseURL
 * @param body body tobe sent as json
 */
export const fetchBlob = async (
  url: string,
  body: any
): Promise<{
  ok: boolean;
  message: string;
  blob?: Promise<Blob>;
  header?: any;
}> => {
  const res = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("imt__token"),
    },
    body: JSON.stringify(body),
  });
  if (!res.ok) {
    return { ok: res.ok, message: "No data to export" };
  } else {
    return {
      ok: res.ok,
      message: "Success",
      blob: res.blob(),
      header: res.headers,
    };
  }
};

export const fetchBlobNg = async (
  method: HttpMethod = "GET",
  url: string,
  body?: any,
  withToken: boolean = true,
  headers: HeadersInit = {}
) => {
  if (!url.startsWith("http")) {
    url = baseURL + url;
  }
  const _headers: any = { ...headers };
  if (body) {
    _headers["content-type"] = "application/json";
  }
  if (withToken) {
    _headers.Authorization = "Bearer " + localStorage.getItem("imt__token");
  }
  try {
    const res = await fetch(url, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: _headers,
      mode: "cors",
    });
    const contentType = res.headers.get("content-type") + "";
    if (!res.ok) {
      let errorMessage = "An error occured.";
      let text;
      try {
        text = await res.text();
        const data = JSON.parse(text);
        errorMessage = data.message;
      } catch (error) {
        console.warn("Not a valid JSON", error, text);
      }
      throw new GeneralAPIError(errorMessage);
    } else {
      //
      const fileName =
        res.headers
          .get("content-disposition")
          ?.split(";")
          ?.find((n: any) => n.includes("filename="))
          ?.replace("filename=", "")
          ?.replace(/[" ]/g, "") ?? "download";
      const blob = await res.blob();
      // const file = new File([blob], filename ?? "download", {
      //   type: contentType,
      // });
      // return file;
      return {
        blob,
        fileName,
        contentType,
      };
    }
  } catch (error) {
    if (error instanceof GeneralAPIError) {
      throw error;
    } else {
      console.warn(error);
      if (error instanceof Error) {
        throw new GeneralAPIError(error.message);
      } else if (typeof error === "string") {
        throw new GeneralAPIError(error);
      } else {
        throw new GeneralAPIError("An error occured");
      }
    }
  }
};

export const getSignedDownloadURL = async (
  hashedFileName: string
): Promise<{ ok: boolean; message: string; url?: string }> => {
  const res = await fetch(
    baseURL + "/upload_picture/presigned_url_get_object",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("imt__token"),
      },
      body: JSON.stringify({
        hashedFileName,
      }),
    }
  );
  if (!res.ok) {
    return { ok: res.ok, message: "An Api error occured" };
  } else {
    const _jsonData = await res.json();
    return { ok: res.ok, message: "Got signed url", url: _jsonData.signedUrl };
  }
};

export const getSignedUploadURL = async (
  filename: string
): Promise<{
  ok: boolean;
  message: string;
  data?: { url: string; hashedFileName: string };
}> => {
  const res = await fetch(
    baseURL + "/upload_picture/presigned_url_put_object",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("imt__token"),
      },
      body: JSON.stringify({
        filename,
      }),
    }
  );
  if (!res.ok) {
    return { ok: res.ok, message: "An API error occured" };
  } else {
    const _jsonData = await res.json();
    return {
      ok: res.ok,
      message: "Got signed url",
      data: {
        url: _jsonData.preSignedUrl,
        hashedFileName: _jsonData.hashedFileName,
      },
    };
  }
};

export type BankList = {
  account_number: string | undefined;
  bank_name: string | undefined;
  id: number | undefined;
};

export type BankNameList = {
  id: number;
  name: string;
};

interface HandleAddResponse {
  ok: boolean;
  message: string;
}

export const getBank = async (): Promise<{
  ok: boolean;
  message: string;
  data?: Array<BankList>;
}> => {
  const { ok, message, json } = await fetchUtil("GET", "/get_bank_accounts");
  if (!ok) {
    return { ok, message };
  } else {
    if (Array.isArray(json)) {
      json.forEach((it) => {
        it.bank_name =
          it.bank_name || "RND-" + Math.random().toString(32).substr(2);
      });
    }
    return { ok, message, data: json };
  }
};

export const updateAdminBank = async (val: any) => {
  const { ok, message } = await fetchUtil(
    "POST",
    "/update_company/update_bank_details",
    val
  );
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message };
  }
};
export const addAdminBank = async (val: any): Promise<HandleAddResponse> => {
  const { ok, message } = await fetchUtil(
    "POST",
    "/update_company/add_bank_details",
    val
  );
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message };
  }
};
export const getBankList = async (): Promise<{
  ok: boolean;
  message: string;
  data?: Array<BankNameList>;
}> => {
  const { ok, message, json } = await fetchUtil("GET", "/get_banks");
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message, data: json };
  }
};

export const fetchDataWithFormData = async (
  url: string,
  formBody: FormData
): Promise<{ ok: boolean; message: string; result: any }> => {
  const res = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("imt__token"),
    },
    body: formBody,
  });
  let json = null;
  const text = await res.text();
  json = JSON.parse(text);
  if (!res.ok) {
    return { ok: res.ok, message: json.err, result: [] };
  } else {
    return {
      ok: res.ok,
      message: json,
      result: JSON.parse(text),
    };
  }
};

export const getSources = async (): Promise<{
  ok: boolean;
  message: string;
  data?: Array<any>;
}> => {
  const { ok, message, json } = await fetchUtil(
    "GET",
    "/get_ecom_source_for_generic"
  );
  if (!ok) {
    return { ok, message };
  } else {
    return { ok, message, data: json };
  }
};
