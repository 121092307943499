import React, { Component } from "react";
import { Typography, Steps } from "antd";
import {
  OnboardStoreState,
  stepPositionMap,
  action,
} from "~/app/OnboardCompany/store";
import { connect, ConnectedProps } from "react-redux";
import FetchWithGSTINModal from "./FetchWithGSTINModal";
import StepBasic from "./StepBasic";
import StepIdentity from "./StepIdentity";
import StepCommunication from "./StepCommunication";
import StepBank from "./StepBank";
import { fetchUtil } from "~/api/common";

const { Step } = Steps;

interface GSTData {
  target: any;
  natureOfBusinesses: Array<string>;
  regDate: string;
  regType: any;
  active: boolean;
  activeStr: string;
  addressLine: string;
  city: any;
  state: any;
  country: any;
  pinCode: string;
  entityType: any;
  tradeName: string;
  legalName: string;
  industryType: number;
  name: string;
  gstin: string;
  pan: string;
  cin: string;
}

const stepComponentMap = {
  basic: StepBasic,
  identity: StepIdentity,
  communication: StepCommunication,
  bank: StepBank,
};

const mapStateToProps = (state: OnboardStoreState) => ({
  loading: state.onboard.apiState === "loading",
  email: state?.onboard?.user?.email,
  currentStep: state?.onboard?.currentStep,
  currentStepPos: stepPositionMap[state?.onboard?.currentStep],
});

const mapDispatchToProps = {
  finishStep: action.finishStep,
  advanceStep: action.advanceStep,
  handleDirectorsList: action.handleDirectorsList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  doNotUseMe?: never;
}

class OnboardSteps extends Component<Props, {}> {
  state = {
    gstinModalVisible: true,
    directorsList: [],
  };

  handleLogout = () => {
    localStorage.clear();
    window.location.pathname = "/";
  };

  openGstinModal = () => {
    this.setState({ gstinModalVisible: true });
  };

  dismissGstinModal = () => {
    this.setState({ gstinModalVisible: false });
  };

  getDataFromCin = async (cin: any) => {
    await fetchUtil("POST", "/get_company_details_from_cin", {
      cin,
    }).then(({ ok, message, json }) => {
      if (!ok) {
        return { ok, message };
      } else {
        if (json?.length > 0) {
          this.setState({
            directorsList: json[0].directors_list,
          });
          // this.props.history("/app/reimbursement`")
        }
      }
    });
  };

  handleGSTDataReceived = (gstData: GSTData) => {
    this.dismissGstinModal();
    this.props.finishStep({
      step: "basic",
      data: {
        legal_name: gstData.legalName,
        name: gstData.tradeName,
        cin: gstData.cin,
        address: gstData.addressLine,
        industry: gstData.industryType || undefined,
        company_city: gstData.city.name,
        company_state: gstData.state.code,
        company_state_name: gstData.state.name,
        company_country: gstData.country.name,
        company_pincode: gstData.pinCode,
        establishment_type: gstData.entityType.id,
      },
    });

    this.getDataFromCin(gstData.cin);

    this.props.finishStep({
      step: "identity",
      data: {
        gst_registration_type: gstData.regType.id,
        gstin: gstData.gstin,
        gst_registration_state: gstData.state.code,
        place_of_supply: gstData.state.id,
        pan: gstData.pan,
        gstin_registration_date:
          gstData.regDate !== "" && gstData.regDate !== null
            ? new Date(gstData.regDate)
            : new Date(),
      },
    });
    if ([1, 2].indexOf(gstData.entityType.id) !== -1) {
      const nameParts = gstData.legalName.split(" ");
      if (nameParts.length >= 2) {
        this.props.finishStep({
          step: "communication",
          data: {
            first_name: nameParts[0],
            last_name: nameParts.splice(1).join(" "),
            role_in_company: 7,
          },
        });
      } else {
        this.props.finishStep({
          step: "communication",
          data: {
            role_in_company: 7,
          },
        });
      }
    }
  };

  render() {
    // tslint:disable-next-line: variable-name
    const StepComponent = stepComponentMap[this.props.currentStep];
    return (
      <div>
        <div style={{ textAlign: "center", marginBottom: 15 }}>
          <Typography.Title level={5}>
            Logged in as <b>{this.props.email}</b> [&nbsp;
            <Typography.Link onClick={this.handleLogout}>
              Logout
            </Typography.Link>
            &nbsp;]
          </Typography.Title>
        </div>

        <Steps size="small" current={this.props.currentStepPos}>
          <Step title="Basic" />
          <Step title="Tax" />
          <Step title="Admin" />
          <Step title="Bank" />
        </Steps>

        <div style={{ marginTop: "10px" }}>
          {this.props.currentStepPos === 0 ? (
            <>
              <Typography.Title level={5}>
                We need some basic info about your establishment. Some of these
                required on the invoice and also to compute compliance
                eligibility.
              </Typography.Title>
            </>
          ) : this.props.currentStepPos === 1 ? (
            <>
              <Typography.Title level={5}>
                Tax information will be required to compute your tax
                liabilities, compliance penalties and enable timely reminder of
                due dates.
              </Typography.Title>
            </>
          ) : this.props.currentStepPos === 2 ? (
            <>
              <Typography.Title
                level={5}
                style={{ textAlign: "left", marginLeft: "10px" }}
              >
                A brief intro about yourself will also be nice to have{" "}
              </Typography.Title>
            </>
          ) : this.props.currentStepPos === 3 ? (
            <>
              <Typography.Title level={5}>
                Bank details can serve as useful information on the invoices you
                raise. Also, when you log a payment, be it inward or outward,
                the transacting bank account can be tagged.{" "}
              </Typography.Title>
            </>
          ) : null}
        </div>
        <div
          style={{
            marginTop: "var(--margin-md)",
            marginBottom: "var(--margin-md)",
          }}
        >
          <StepComponent openGstinModal={this.openGstinModal} />
        </div>
        <FetchWithGSTINModal
          visible={this.state.gstinModalVisible}
          onGSTDataReceived={this.handleGSTDataReceived}
          onCancel={this.dismissGstinModal}
        />
      </div>
    );
  }
}

export default connector(OnboardSteps);
