import React, { Component } from "react";
import {
  List,
  Collapse,
  Avatar,
  Space,
  Table,
  notification,
  Spin,
  Tooltip,
  Badge,
  Divider,
  Modal,
} from "antd";
import Currency from "~/component/Currency";
import { APIState, fetchUtil } from "~/api/common";
import { format, isPast, differenceInDays } from "date-fns";
import { FORMAT_DATE } from "~/lib";
import ComplianceDrawer from "./drawer/ComplianceDrawer";
import { renderPayIcon } from "./common-renderer";
import DataNotFound from "./dataNotFound";

import commonPaneStyle from "./common-tab-pane.module.css";
import CompliancePaymentModal, {
  CompleteCompliance,
} from "./modal/CompliancePaymentModal";
import { ColumnsType } from "antd/lib/table";
import { registerEvent } from "~/analytics";
import { RouteComponentProps, withRouter } from "react-router";

import * as ga from "~/contants/gaConstants";
import IndicatorTag from "~/component/IndicatorTag";

const { Panel } = Collapse;

type GSTGroupItem = {
  icon: string;
  group_name: string;
  code_name: string;
  count: number;
  complete: number;
  overdue: number;
  children: Array<any>;
};

// interface Props {
//   doNotUseMe?: never;
//   selectedFy?: any;
// }
interface State {
  gstGroupList: Array<GSTGroupItem>;
  drawerVisible: boolean;
  payModalVisible: boolean;
  clickedRow: [number, number];
  apiState: APIState;
}

type PathParams = {
  fyYear?: string;
  activeList?: string;
  compianceName?: string;
  month?: string;
};

interface Props extends RouteComponentProps<PathParams> {
  doNotUseMe?: never;
  selectedFy?: any;
}

function getLogoURL(taxName: string) {
  return `/tax-logo/${taxName.toLowerCase()}.png`;
}

class CurrentTabPane extends Component<Props, State> {
  state: State = {
    gstGroupList: [],
    drawerVisible: false,
    payModalVisible: false,
    clickedRow: [-1, -1],
    apiState: "loading",
  };

  columnsDef: ColumnsType<any> = [
    {
      title: "",
      // dataIndex: "total_amount",

      align: "right",
      render(data: any) {
        return (data.compliance_name === "GSTR 3B" ||
          // data.compliance_name === "GSTR 1") &&
          data.compliance_name === "GSTR 1/IFF") &&
          !data.is_reconciled ? (
          <>
            <Badge color="#f50" style={{ marginRight: "-16px" }} />
          </>
        ) : null;
      },
    },
    {
      title: "Name",
      dataIndex: "compliance_name",
      onCell: (data) => {
        return {
          className:
            data.group_name === "TDS"
              ? commonPaneStyle.tdsNameColumn
              : commonPaneStyle.otherNameColumn,
        };
      },
      // className: commonPaneStyle.complianceNameColumn,
    },
    {
      title: "Type",
      dataIndex: "tax_type",
      className: commonPaneStyle.cmpTypeClm,
    },

    {
      title: "Due Date",
      dataIndex: "due_date",
      className: commonPaneStyle.cmpDueDateClm,
      render: (due_date: string, row: any) => {
        const dueDate = new Date(due_date);
        dueDate.setMinutes(dueDate.getMinutes() + 29);
        dueDate.setHours(dueDate.getHours() + 18);
        dueDate.setSeconds(dueDate.getSeconds() + 59);
        const status = row.payment_status;
        const isComplete = status === "complete";
        const completedDate = row.completed_date
          ? new Date(row.completed_date)
          : null;
        const isOverdue = !isComplete && isPast(dueDate);
        const completedAfterXDays =
          completedDate && row.is_late_file
            ? differenceInDays(completedDate, dueDate)
            : null;

        let color = "var(--grey-1)";
        if (isOverdue) {
          color = "var(--error-color)";
        } else if (isComplete) {
          color = "var(--grey-2)";
        }

        const dateBlockStyle = isOverdue
          ? { color }
          : {
              color,
              minHeight: "37px",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap" as "wrap",
            };

        const subTextStyle = {
          flex: "1 100%",
          fontSize: 10,
          fontWeight: 700,
          color: "var(--error-color)",
        };
        return (
          <div style={dateBlockStyle}>
            {dueDate && format(dueDate, FORMAT_DATE)}
            {isOverdue ? (
              <div style={subTextStyle}>Overdue</div>
            ) : (
              row.is_late_file && (
                <Tooltip
                  placement="topLeft"
                  title={`Filed ${
                    completedAfterXDays || "few"
                  } days after usual due date`}
                >
                  <div style={subTextStyle}>Late completion</div>
                </Tooltip>
              )
            )}
          </div>
        );
      },
    },
    {
      title: "For period",
      dataIndex: "for_period",
      // align: 'right',
      className: commonPaneStyle.cmpForPeriodClm,
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      className: commonPaneStyle.complianceTotalAmountColumn,
      align: "right",
      render(total_amount: number | string) {
        return <Currency>{total_amount}</Currency>;
      },
    },
    {
      title: "Filing fee",
      dataIndex: "filing_fee",
      className: commonPaneStyle.cmpFilingFeeClm,
      align: "right",
      render(filing_fee: number | string) {
        return <Currency>{filing_fee}</Currency>;
      },
    },
    {
      title: "Late fine",
      dataIndex: "late_fine",
      className: commonPaneStyle.cmpLateFineClm,
      align: "right",
      render(late_fine: number | string) {
        return <Currency>{late_fine}</Currency>;
      },
    },
    {
      title: "",
      dataIndex: "log_id",
      align: "right",

      render: (_: number, row: any) => {
        return renderPayIcon(row.payment_status, (e) => {
          e.stopPropagation();
          // if (row.payment_status !== "complete") {
          this.doOpenPayModal(row);
          // }
        });
      },
    },
  ];

  componentDidMount() {
    this.loadLevelOneTwoData();
  }

  static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State
  ): Partial<State> | null {
    let rowData: any = null;
    if (
      nextProps.match.params.compianceName &&
      prevState.gstGroupList.length > 0
    ) {
      const {
        activeList,
        compianceName: _compianceName,
        month,
      } = nextProps.match.params;
      const { gstGroupList } = prevState;
      const complianceList = activeList?.split(",") ?? [];
      const compianceName = decodeURIComponent(_compianceName);

      gstGroupList.find((group) => {
        if (complianceList.includes(group.group_name)) {
          rowData = group.children.find((row) => {
            const { compliance_name, for_period } = row;
            return compliance_name === compianceName && for_period === month;
          });
          return !!rowData;
        }
      });

      if (rowData) {
        const currentClickedRow = CurrentTabPane.findClickedRowInx(
          prevState.gstGroupList,
          rowData
        );
        // if (currentClickedRow.join(", ") !== prevState.clickedRow?.join(", ")) {
        return {
          drawerVisible: true,
          clickedRow: CurrentTabPane.findClickedRowInx(
            prevState.gstGroupList,
            rowData
          ),
        };
        // }
      }
    }

    if (prevState.drawerVisible) {
      return {
        drawerVisible: false,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps: Props, _prevState: {}) {
    if (prevProps.selectedFy !== this.props.selectedFy) {
      this.loadLevelOneTwoData();
    }
  }

  loadLevelOneTwoData = async () => {
    const { ok, message, json } = await fetchUtil(
      "POST",
      "/fetch_compliances_level_onetwo",
      {
        // start_year: Number(this.props.selectedFy.slice(0, 4)),
        // end_year: Number(this.props.selectedFy.slice(5)),
        financial_year:
          `${this.props.selectedFy.slice(0, 4)}` +
          "-" +
          `${this.props.selectedFy.slice(5)}`,
      }
    );
    if (!ok) {
      notification.error({ message });
      this.setState({
        apiState: "error",
        gstGroupList: [],
      });
    } else {
      const data = json;
      let x = 0;
      for (const groupRow of data) {
        groupRow._xId = x++ + "";
        if (groupRow.children) {
          for (const cmpRow of groupRow.children) {
            cmpRow._xId = x++ + "";
          }
        }
      }
      // data.map((e) => {
      //   e.children.map((el: any) => {
      //     // el.dfdfdfdf = true;
      //     return (
      //       (el.expense_reconciliation = false),
      //       (el.invoice_reconciliation = true)
      //     );
      //   });
      // });
      this.setState({
        gstGroupList: data,
        apiState: "success",
        payModalVisible: false,
      });
    }
  };

  static findClickedRowInx = (
    gstGroupList: any,
    rowData: any
  ): [number, number] => {
    const this_xId = rowData._xId;
    let grpIndx = -1;
    let cmpIndx = -1;
    const grpList = gstGroupList;
    for (let iG = 0; iG < grpList.length; iG++) {
      const cmpList = grpList[iG].children;
      for (let iC = 0; iC < cmpList.length; iC++) {
        if (this_xId === cmpList[iC]._xId) {
          grpIndx = iG;
          cmpIndx = iC;
          break;
        }
      }
      if (grpIndx !== -1) {
        break;
      }
    }
    return [grpIndx, cmpIndx];
  };

  // doOpenDrawer = (rowData: any) => {
  //   this.setState({
  //     drawerVisible: true,
  //     clickedRow: this.findClickedRowInx(rowData),
  //   });
  // };

  doCloseDrawer = () => {
    this.loadLevelOneTwoData();
    const { fyYear, activeList } = this.props.match.params;
    this.props.history.push(`/app/compliance-new/${fyYear}/${activeList}`);
  };

  doOpenPayModal = (rowData: any) => {
    this.setState({
      payModalVisible: true,
      clickedRow: CurrentTabPane.findClickedRowInx(
        this.state.gstGroupList,
        rowData
      ),
    });
  };

  doClosePayModal = () => {
    this.setState({
      payModalVisible: false,
    });
  };
  // doUntickPayModal = (values?: boolean) => {
  //   this.setState({
  //     payModalVisible: false,
  //   });
  //   if (values) {
  //     this.loadLevelOneTwoData();
  //   }
  // };
  doUntickPayModal = async (values: any) => {
    Modal.confirm({
      content: (
        <span>
          This action will remove the existing data.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Are you
          sure that you want to proceed?
        </span>
      ),
      okText: "Yes",
      cancelText: "No",
      centered: true,
      onOk: async () => {
        const { ok, json, message } = await fetchUtil(
          "POST",
          `/cancel_compliance_completion`,
          {
            log_id: values,
          }
        );
        if (!ok) {
          notification.error({ message });
        } else {
          if (message) {
            notification.success({ message });
          }
          this.setState({
            payModalVisible: false,
          });
          this.loadLevelOneTwoData();
        }
      },
    });
  };

  doSavePayModal = async (values: CompleteCompliance) => {
    const [gstIdx, cmIdx] = this.state.clickedRow;
    const levelTwoClickedData = this.state.gstGroupList[gstIdx].children[cmIdx];
    const { log_id, due_date } = levelTwoClickedData;
    const { ok, message } = await fetchUtil("POST", "/update_compliances", {
      log_id,
      due_date,
      ...values,
    });
    if (!ok) {
      notification.error({ message });
    } else {
      if (message) {
        notification.success({ message });
      }
      this.setState({
        payModalVisible: false,
      });
      this.loadLevelOneTwoData();
    }
  };

  renderRowAttrs = (row: any, index?: number) => {
    const status = row.payment_status;
    const isComplete = status === "complete";
    const handleClick = (e: React.SyntheticEvent) => {
      registerEvent(
        ga.EVENT_CATEGORY_BUTTON_CLICK,
        ga.EVENT_CLICK,
        ga.events.currentTabPane160
      );
      e.stopPropagation();
      // this.doOpenDrawer(row);
      const { fyYear, activeList } = this.props.match.params;
      const { compliance_name, for_period } = row;
      this.props.history.push(
        `/app/compliance-new/${fyYear}/${activeList}/${encodeURIComponent(
          compliance_name
        )}/${for_period}`
      );
    };
    return {
      onClick: handleClick,
      className: isComplete
        ? commonPaneStyle.completedRow
        : commonPaneStyle.levelOneRow,
    };
  };

  renderItemStat = (item: GSTGroupItem) => {
    return (
      <div>
        {item.complete === item.count ? (
          null && (
            <span style={{ color: "var(--success-color)" }}>✔︎ Complete </span>
          )
        ) : (
          <Space size="small">
            <span
              style={{
                color: "var(--error-color)",
                display: item.overdue === 0 ? "none" : "inline-block",
              }}
            >
              {item.overdue} Overdue
            </span>
          </Space>
        )}
      </div>
    );
  };

  renderDescription = (item: GSTGroupItem) => {
    return `Completed ${item.complete} of ${item.count}`;
  };

  renderCollapseItem = () => {
    // level 1
    if (this.state.gstGroupList.length > 0) {
      return this.state.gstGroupList.map((it) => {
        return (
          <>
            <Panel
              key={it.group_name}
              style={{
                borderBottom: "1px solid var(--grey-3)",
              }}
              header={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <List.Item.Meta
                    avatar={<Avatar src={getLogoURL(it.code_name)} />}
                    title={<a href="#wip">{it.group_name}</a>}
                    description={
                      <Space size="middle">
                        <span style={{ whiteSpace: "nowrap" }}>
                          {this.renderItemStat(it)}
                        </span>
                        <span style={{ whiteSpace: "nowrap" }}>
                          {this.renderDescription(it)}
                        </span>
                      </Space>
                    }
                  />
                </div>
              }
              showArrow={false}
            >
              {/* level 2 */}
              {it.children.length > 0 ? (
                <Table
                  rowKey="_xId"
                  bordered={false}
                  onRow={this.renderRowAttrs}
                  columns={this.columnsDef}
                  dataSource={it.children}
                  pagination={false}
                />
              ) : (
                <DataNotFound title={"Data not found"} />
              )}
            </Panel>
          </>
        );
      });
    } else {
      return (
        <>
          <DataNotFound
            title={`No compliance data yet.
          (It might be the case that the list is being built. Please come back after sometime.)`}
          />
        </>
      );
    }
  };

  render() {
    const { apiState } = this.state;
    const [gstIdx, cmIdx] = this.state.clickedRow;
    const activeList = this.props.match.params.activeList?.split(",") ?? [];
    const isLoading = apiState === "loading";
    const levelTwoClickedData = this.state.gstGroupList[gstIdx]?.children[
      cmIdx
    ];
    return (
      <div>
        <Spin style={{ minHeight: "300px" }} spinning={isLoading} delay={500}>
          {/* level1 & 2 */}

          <Collapse
            className={commonPaneStyle.lvl1List}
            ghost
            activeKey={activeList}
            onChange={(itemSet: any) => {
              this.props.history.replace(
                `/app/compliance-new/${this.props.selectedFy}/${itemSet}`
              );
            }}
          >
            <Divider />
            {this.renderCollapseItem()}
          </Collapse>
        </Spin>
        {/* level 3 */}
        {this.state.drawerVisible && (
          <ComplianceDrawer
            isOpen={this.state.drawerVisible}
            onClose={this.doCloseDrawer}
            levelTwoClickedData={levelTwoClickedData}
            onPayOpen={this.doOpenPayModal}
          />
        )}
        {this.state.payModalVisible && (
          <CompliancePaymentModal
            isOpen={this.state.payModalVisible}
            onUntick={this.doUntickPayModal}
            onClose={this.doClosePayModal}
            onSave={this.doSavePayModal}
            levelTwoClickedData={levelTwoClickedData}
          />
        )}
      </div>
    );
  }
}
export default withRouter(CurrentTabPane);
