import React, { Component } from "react";
import { Menu, Dropdown, Space, notification, Table, Spin, Modal } from "antd";
import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import { APIState } from "~/api/common";
import { AdminDetail } from "~/api/profile";
import { LoginRole } from "~/api/auth";
import { AuthState } from "~/app/MainApp/store";
import { fetchUtil } from "~/api/common";
import { connect, ConnectedProps } from "react-redux";
import AdminPaneDrawer from "~/feature/Profile/AdminPaneDrawer";
import { registerEvent } from "~/analytics";

import * as ga from "~/contants/gaConstants";

const mapStateToProps = (state: AuthState) => ({
  activeRole: state.mainAppSlice.user.activeRole,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type Designations = {
  id: number;
  role_name: string;
};

interface Props extends PropsFromRedux {
  apiState: APIState;
  data: AdminDetail;
  onDataChange: (newData: AdminDetail) => void;
  handlesubmit: (newData: AdminDetail) => void;
  onDirty: () => void;
}
interface State {
  designationArray: Array<Designations>;
  executiveDetails: Array<any>;
  drawerVisible: boolean;
  selectedExecutiveDetail: any;
  loadTable: boolean;
  loadDel: boolean;
}

class AdminDetailsPane extends Component<Props, State> {
  state: State = {
    designationArray: [],
    executiveDetails: [],
    drawerVisible: false,
    selectedExecutiveDetail: {},
    loadTable: false,
    loadDel: false,
  };

  _formRef = React.createRef<FormInstance<any>>();

  componentDidMount() {
    this.getDesignation();
    this.getExecutiveDetail();
  }
  getDesignation = async () => {
    this.setState({
      loadTable: true,
    });
    const { ok, message, json } = await fetchUtil(
      "GET",
      "/get_executive_designations"
    );
    if (!ok) {
      notification.error({ message });
      this.setState({
        loadTable: false,
      });
    } else {
      this.setState({
        designationArray: json,
        loadTable: false,
      });
    }
  };

  getExecutiveDetail = async () => {
    this.setState({
      loadTable: true,
    });
    const { ok, message, json } = await fetchUtil(
      "POST",
      "/get_executive_details",
      {}
    );
    if (!ok) {
      notification.error({ message });
      this.setState({
        loadTable: false,
      });
    } else {
      this.setState({
        executiveDetails: json,
        loadTable: false,
      });
    }
  };

  componentDidUpdate(prevProps: Props, _prevState: State) {
    if (prevProps.data !== this.props.data) {
      this._formRef.current?.setFieldsValue(this.props.data);
    }
  }
  getNumericInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let number = "";
    const N = value.length;
    for (let i = 0; i < N; i++) {
      const charCode = value.charCodeAt(i);
      if (charCode >= "0".charCodeAt(0) && charCode <= "9".charCodeAt(0)) {
        number += value.charAt(i);
      }
    }
    if (number.length > 10) {
      return number.substr(0, 10);
    }
    return number;
  };

  handleDrawerVisible = (selectedExecutive: any) => {
    this.setState({
      drawerVisible: true,
      selectedExecutiveDetail: selectedExecutive,
    });
  };

  doCloseDrawer = () => {
    this.setState(
      {
        drawerVisible: false,
      },
      () => {
        this.getExecutiveDetail();
      }
    );
  };

  deleteExecutiveDetail = async (record: any) => {
    this.setState({
      loadDel: true,
    });
    const { ok, message } = await fetchUtil("POST", "/delete_executive", {
      id: record.id,
    });
    if (!ok) {
      notification.error({ message });
      this.setState({
        loadDel: false,
      });
    } else {
      notification.success({
        message,
      });
      this.setState({
        loadDel: false,
      });
      this.getExecutiveDetail();
    }
  };

  showConfirm = (id: number, values: FormData) => {
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.adminDetailsPane34
    );

    Modal.confirm({
      title: "Are you sure to delete this executive ?",
      icon: <ExclamationCircleOutlined />,

      okButtonProps: {
        loading: this.state.loadDel,
      },
      onOk: () => {
        this.deleteExecutiveDetail(values);
      },
    });
  };

  render() {
    const columnDef: Array<any> = [
      {
        title: "First name",
        dataIndex: "first_name",
        key: "first_name",
        align: "left",
      },
      {
        title: "Last name",
        dataIndex: "last_name",
        key: "last_name",
        align: "left",
      },
      {
        title: "Designation",
        dataIndex: "designation",
        key: "designation",
        align: "left",
        render: (designation: string, record: any) => {
          return <div style={{ whiteSpace: "nowrap" }}>{designation}</div>;
        },
      },
      {
        title: "Email",
        dataIndex: "email_id",
        key: "email_id",
        align: "left",
      },
      {
        title: <div style={{ whiteSpace: "nowrap" }}> TDS primary</div>,
        dataIndex: "is_tds_primary",
        key: "is_tds_primary",
        align: "left",
        render: (is_tds_primary: any, record: any) => {
          const isTds = is_tds_primary ? "Yes" : "No";
          return <span>{isTds}</span>;
        },
      },
      {
        title: <div style={{ whiteSpace: "nowrap" }}> GST primary</div>,
        dataIndex: "is_gst_primary",
        key: "is_gst_primary",
        align: "left",
        render: (is_gst_primary: any, record: any) => {
          const isGst = is_gst_primary ? "Yes" : "No";
          return <span>{isGst}</span>;
        },
      },
      {
        title: "Admin",
        dataIndex: "is_admin",
        key: "is_admin",
        align: "left",
        render: (is_admin: any, record: any) => {
          const isAdmin = is_admin ? "Yes" : "No";
          return <span>{isAdmin}</span>;
        },
      },
      {
        title: this.props.activeRole !== LoginRole.SUPERuSER ? "Action" : null,
        dataIndex: "id",
        key: "id",
        align: "center",
        render: (id: any, record: any) => {
          const menu = (
            <Menu>
              <Menu.Item key="0">
                <span onClick={() => this.handleDrawerVisible(record)}>
                  Edit
                </span>
              </Menu.Item>
              {!record.is_admin ? (
                <Menu.Item key="1">
                  <span onClick={() => this.showConfirm(id, record)}>
                    Delete
                  </span>
                </Menu.Item>
              ) : null}
            </Menu>
          );
          return this.props.activeRole !== LoginRole.SUPERuSER ? (
            <div>
              <Space align="center">
                <Dropdown overlay={menu} trigger={["click"]}>
                  <span
                    className="ant-dropdown-link"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <MoreOutlined />
                  </span>
                </Dropdown>
              </Space>
            </div>
          ) : null;
        },
      },
    ];
    return (
      <Spin spinning={this.state.loadTable}>
        <Table
          style={{ maxWidth: "80%", cursor: "pointer" }}
          columns={columnDef}
          dataSource={this.state.executiveDetails}
          size="large"
          pagination={false}
        />
        {this.state.drawerVisible ? (
          <AdminPaneDrawer
            isOpen={this.state.drawerVisible}
            onClose={this.doCloseDrawer}
            selectedExecutiveDetail={this.state.selectedExecutiveDetail}
          />
        ) : null}
      </Spin>
    );
  }
}
export default connector(AdminDetailsPane);
